/**
 * パーセンテージを計算する
 * @param numerator 分子
 * @param denominator 分母
 * @returns 四捨五入したパーセンテージ
 */
const calculatePercentage = (numerator: number, denominator: number): number => {
  if (denominator === 0) {
    // ゼロ除算を回避するため0を返却
    return 0;
  }

  const result = Math.round((numerator / denominator) * 100);
  return result;
};

// eslint-disable-next-line import/prefer-default-export
export { calculatePercentage };
