import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { ColumnFilterItem, ColumnProps } from 'antd/lib/table';

import AlertSettingModal from './AlertSettingModal';
import {
  FilterIcon,
  TextFilter,
  FilterDropdown,
  TableFilter,
} from './FilterComponent';
import { ERROR_NO_AUTH_MESSAGE } from '../../../utils/messages';
import AlertMappingInfo from '../../../utils/DynamoDB/AlertMappingInfo';
import EmailGroup from '../../../utils/DynamoDB/EmailGroup';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../../utils/common/Authentication';

interface AlertEmailTableProps {
  loading: boolean;
  selectedAlertList: AlertMappingInfo[];
  tableRefresh: () => void;
}
const NAME_LENGTH_LIMIT = 15;

/**
 * 検索済みイベント一覧テーブル
 * @param {AlertEmailTableProps} props プロパティ
 * @returns 検索済みイベント一覧テーブル
 */
const AlertEmailTable: React.FC<AlertEmailTableProps> = (
  props: AlertEmailTableProps,
) => {
  const { loading, selectedAlertList, tableRefresh } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAlertInfo, setSelectedAlertInfo] = useState<AlertMappingInfo>();
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState<boolean>(false);
  const [emailGroupsList, setEmailGroupsList] = useState<EmailGroup[]>([]);

  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        SOLAR_MENU: {
          EMAIL_NOTIFICATION_SETTINGS: {
            SOLAR_EMAIL_NOTIFICATION_SETTINGS_UPDATE: EMAIL_NOTIFICATION_GROUP_UPDATE,
          },
        },
      } = AUTHENTICATION_TYPE_MATRIX;
      const emailGroups = await EmailGroup.getAllGroups();
      if (!canceled) {
        setEmailGroupsList(emailGroups);
        setIsUpdateButtonDisabled(!await containsUIAuthType(EMAIL_NOTIFICATION_GROUP_UPDATE));
      }
    })();

    return () => { canceled = true; };
  }, [selectedAlertList]);

  /**
   * 文字列項目のソート判定を実施する
   * @param {string} a 文字列項目A
   * @param {string} b 文字列項目B
   * @returns {number} ソートの判定結果
   */
  const getSortedItems = (a: string, b: string): number => {
    const first = [a, b].sort()[0];
    return first === a ? -1 : 1;
  };

  /**
   * 子画面の表示処理
   * @param {AlertMappingInfo} item 選択したサイトのメール設定情報
   */
  const handleOpenModal = (item: AlertMappingInfo) => {
    setSelectedAlertInfo(item);
    setIsModalVisible(true);
  };

  /**
 * 子画面を閉じる処理
 */
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const columns: ColumnProps<AlertMappingInfo>[] = [
    {
      title: '発電所名',
      dataIndex: 'areaNameJp',
      width: 170,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string>(selectedAlertList.map(({ areaNameJp }) => areaNameJp));
        return TableFilter(uniqueData);
      })(),
      filterDropdown: !!selectedAlertList && FilterDropdown,
      onFilter: (value, result) => result.areaNameJp === value,
      sorter: (a, b) => getSortedItems(a.areaNameJp, b.areaNameJp),
      render: (_, item: AlertMappingInfo) => item.areaNameJp,
    },
    {
      title: 'GMU-ID',
      dataIndex: 'gmuId',
      width: 170,
      filterIcon: FilterIcon,
      filterDropdown: !!selectedAlertList && TextFilter,
      onFilter: (value, result) => (result.gmuId.toLowerCase().includes((value as string).toLowerCase())),
      sorter: (a, b) => getSortedItems(a.gmuId, b.gmuId),
      render: (_, item: AlertMappingInfo) => item.gmuId,
    },
    {
      title: '計測ポイント名称',
      dataIndex: 'signalName',
      width: 170,
      filterIcon: FilterIcon,
      filterDropdown: !!selectedAlertList && TextFilter,
      onFilter: (value, result) => (result.signalName.toLowerCase().includes((value as string).toLowerCase())),
      sorter: (a, b) => getSortedItems(a.signalName, b.signalName),
      render: (_, item: AlertMappingInfo) => item.signalName,
    },
    {
      title: '重要度',
      dataIndex: 'severity',
      width: 170,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string>(selectedAlertList.map(({ severity }) => severity.toString()));
        return TableFilter(uniqueData);
      })(),
      filterDropdown: !!selectedAlertList && FilterDropdown,
      onFilter: (value, result) => result.severity.toString() === value,
      sorter: (a, b) => Number(a.severity) - Number(b.severity),
      render: (_, item: AlertMappingInfo) => item.severity,
    },
    {
      title: '通知先グループ',
      dataIndex: 'targetGroups',
      width: 170,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string>(selectedAlertList.flatMap(({ targetGroupNames }) => targetGroupNames));
        return TableFilter(uniqueData);
      })(),
      filterDropdown: !!selectedAlertList && FilterDropdown,
      onFilter: (value, result) => result.targetGroupNames.some((targetName) => targetName === value),
      render: (_, item: AlertMappingInfo) => item.targetGroupNames.map((targetGroupName) => (
        <Tooltip title={targetGroupName} key={targetGroupName}>
          <Tag>
            {targetGroupName.length > NAME_LENGTH_LIMIT ? `${targetGroupName.substring(0, NAME_LENGTH_LIMIT)}...` : targetGroupName}
          </Tag>
        </Tooltip>
      )),

    },
    {
      dataIndex: 'edit',
      width: 70,
      render: (_, item: AlertMappingInfo) => (
        <div>
          <Tooltip title={isUpdateButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
            <Button
              type="primary"
              onClick={() => handleOpenModal(item)}
              disabled={isUpdateButtonDisabled}
            >
              編集
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      {selectedAlertInfo && (
        <AlertSettingModal
          selectedAlertInfo={selectedAlertInfo}
          emailGroupInfo={emailGroupsList}
          visible={isModalVisible}
          onClose={handleCloseModal}
          tableRefresh={tableRefresh}
        />
      )}
      <Table<AlertMappingInfo>
        loading={loading}
        dataSource={selectedAlertList}
        pagination={{
          style: { marginRight: '5px !important' },
          position: 'bottom',
          pageSize: 100,
        }}
        columns={columns}
        rowKey="id"
        scroll={{ y: 616 }}
      />
    </>
  );
};

export default AlertEmailTable;
