/* eslint-disable no-unsafe-optional-chaining */
// eslint警告未対応
import {
  CogniteEvent,
  EventChangeById,
  EventFilter,
  ExternalEvent,
  ItemsResponse,
  ItemsWrapper,
  Metadata,
  MetadataPatch,
} from '@cognite/sdk';
import Decimal from 'decimal.js';

import { MapItemWithData, MILLION } from './registerPlannedFinance';
import SolarSite from '../../../../utils/Asset/SolarSite';
import { postApiGateway, putApiGateway } from '../../../../utils/AWS/ApiGateway';
import { EP_PATH_SOLAR_EVENTS, EP_PATH_SOLAR_EVENTS_LIST } from '../../../../utils/AWS/EndpointPath';

type PlannedFinanceMetadata = {
  year: string,
  month: string,
  EBITDA_計画: string,
  EBITDA_計画_累積: string,
  EBITDA_前年実績: string,
  営業費用_計画: string,
  営業費用_計画_累積: string,
  営業費用_前年実績: string,
  営業利益_計画: string,
  営業利益_計画_累積: string,
  営業利益_前年実績: string,
  売上原価_計画: string,
  売上原価_計画_累積: string,
  売上原価_前年実績: string,
  販管費_計画: string,
  販管費_計画_累積: string,
  販管費_前年実績: string,
  売電収入額_計画: string,
  売電収入額_計画_累積: string,
  売電収入額_前年実績: string,
  営業外収益_計画: string,
  営業外収益_計画_累積: string,
  営業外収益_前年実績: string,
  営業外費用_計画: string,
  営業外費用_計画_累積: string,
  営業外費用_前年実績: string,
  経常利益_計画: string,
  経常利益_計画_累積: string,
  経常利益_前年実績: string,
  当期純利益_計画: string,
  当期純利益_計画_累積: string,
  当期純利益_前年実績: string,
  減価償却費_計画: string,
  減価償却費_計画_累積: string,
  減価償却費_前年実績: string,
  営業権償却費_計画: string,
  営業権償却費_計画_累積: string,
  営業権償却費_前年実績: string,
  修繕費_計画: string,
  修繕費_計画_累積: string,
  修繕費_前年実績: string,
  受取利息_計画: string,
  受取利息_計画_累積: string,
  受取利息_前年実績: string,
  支払利息_計画: string,
  支払利息_計画_累積: string,
  支払利息_前年実績: string,
  事業税_償却資産税_計画: string,
  事業税_償却資産税_計画_累積: string,
  事業税_償却資産税_前年実績: string,
  法人税等_計画: string,
  法人税等_計画_累積: string,
  法人税等_前年実績: string,
};

/**
 * 指定したサイトと年月の単月データを取得するためのフィルター条件を成形
 * @param {number} year 指定の年
 * @param {number} month 指定の月
 * @param {SolarSite} siteAsset 取得対象のサイト情報
 * @returns {EventFilter} 指定の単月データを取得するためのフィルター条件
 */
const monthlyFinanceFilter = (
  year: number,
  month: number,
  siteAsset: SolarSite,
): { filter: EventFilter } => ({
  filter: {
    type: 'finance',
    assetIds: [siteAsset.id],
    metadata: {
      year: year.toString(),
      month: month.toString(),
    },
  },
});

/**
 * 指定したサイトと年月の単月データを取得
 * @param {number} year 指定の年
 * @param {number} month 指定の月
 * @param {SolarSite} siteAsset 取得対象のサイト情報
 * @returns {Promise<CogniteEvent | undefined>} 指定の単月データ
 */
const retrieveFinanceEvent = async (
  year: number,
  month: number,
  siteAsset: SolarSite,
): Promise<CogniteEvent | undefined> => {
  const filter = monthlyFinanceFilter(year, month, siteAsset);
  const event = await postApiGateway<typeof filter, ItemsWrapper<CogniteEvent[]>>(
    EP_PATH_SOLAR_EVENTS_LIST,
    filter,
  ).then((res) => (res.items ? res.items[0] : undefined));
  return event;
};

/**
 * 指定年月の単月データの登録・更新に使用するメタデータ項目をマッピング情報から抽出
 * @param {MapItemWithData[]} plannedDataMap 項目ごとの和名と登録データを紐づけたマッピング情報
 * @param {number} year 指定の年
 * @param {number} month 指定の月
 * @param {CogniteEvent | undefined} event 対象年月の単月データイベント
 * @returns {PlannedFinanceMetadata} 指定年月の単月データ登録更新用メタデータ項目
 */
const extractMonthlyDataFromMap = (
  plannedDataMap: MapItemWithData[],
  year: number,
  month: number,
  event: CogniteEvent | undefined,
): PlannedFinanceMetadata => {
  const keysAndValues: [string, string][] = plannedDataMap.map((item: MapItemWithData) => {
    const target = item.plannedData.find((entry) => {
      const date = new Date(entry[0]);
      return date.getMonth() + 1 === month && date.getFullYear() === year;
    });
    const value = target ? (target[1] / MILLION).toString() : '';
    return [item.jpName, value];
  });
  /* 上記の状態でメタデータを作成した場合、CDF側に指定月の単月データが存在しているものの登録データシートに当該月・項目のデータが未入力だと、
      既存の単月データの該当項目が空文字で上書きされてしまうので、そのようなデータを弾いておく */
  const filteredKeysAndValues: [string, string][] = [];
  keysAndValues.forEach((keyValue) => {
    if (keyValue[1] !== '') {
      filteredKeysAndValues.push(keyValue);
    } else if (event?.metadata && keyValue[0] in event?.metadata) {
      filteredKeysAndValues.push([keyValue[0], event.metadata[keyValue[0]]]);
    }
  });

  const metadata = {
    year: year.toString(),
    month: month.toString(),
    ...Object.fromEntries(filteredKeysAndValues),
  } as PlannedFinanceMetadata;
  return metadata;
};

/**
 * 対象単月データ更新用のリクエストボディ項目を成形
 * @param {CogniteEvent} event 更新対象単月データイベント
 * @param {PlannedFinanceMetadata} updateMetadata 更新用のメタデータ項目
 * @returns {EventChangeById} 対象イベント更新用リクエストボディ項目
 */
const formatUpdateItem = (
  event: CogniteEvent,
  updateMetadata: PlannedFinanceMetadata,
): EventChangeById => ({
  update: {
    metadata: {
      add: updateMetadata,
      remove: [],
    },
  },
  id: event.id,
});

/**
 * 対象サイト・年月の単月データ登録用のリクエストボディ項目を成形
 * @param {SolarSite} siteAsset 対象サイト情報
 * @param {PlannedFinanceMetadata} metadata 更新用のメタデータ項目
 * @param {string} fiscalYear 年度
 * @returns {ExternalEvent} 対象イベント登録用リクエストボディ項目
 */
const formatCreateItem = (
  siteAsset: SolarSite,
  metadata: PlannedFinanceMetadata,
  fiscalYear: string,
): ExternalEvent => ({
  assetIds: [siteAsset.id],
  dataSetId: siteAsset.dataSetId,
  type: 'finance',
  metadata: {
    year: metadata.year,
    month: metadata.month,
    fiscal_year: fiscalYear,
    power_supply_type: 'solar',
    EBITDA_計画: metadata['EBITDA_計画'] ?? '',
    EBITDA_計画_累積: metadata['EBITDA_計画_累積'] ?? '',
    EBITDA_実績: '',
    EBITDA_実績_累積: '',
    EBITDA_前年実績: metadata['EBITDA_前年実績'] ?? '',
    営業費用_計画: metadata['営業費用_計画'] ?? '',
    営業費用_計画_累積: metadata['営業費用_計画_累積'] ?? '',
    営業費用_実績: '',
    営業費用_実績_累積: '',
    営業費用_前年実績: metadata['営業費用_前年実績'] ?? '',
    営業利益_計画: metadata['営業利益_計画'] ?? '',
    営業利益_計画_累積: metadata['営業利益_計画_累積'] ?? '',
    営業利益_実績: '',
    営業利益_実績_累積: '',
    営業利益_前年実績: metadata['営業利益_前年実績'] ?? '',
    売上原価_計画: metadata['売上原価_計画'] ?? '',
    売上原価_計画_累積: metadata['売上原価_計画_累積'] ?? '',
    売上原価_実績: '',
    売上原価_実績_累積: '',
    売上原価_前年実績: metadata['売上原価_前年実績'] ?? '',
    販管費_計画: metadata['販管費_計画'] ?? '',
    販管費_計画_累積: metadata['販管費_計画_累積'] ?? '',
    販管費_実績: '',
    販管費_実績_累積: '',
    販管費_前年実績: metadata['販管費_前年実績'] ?? '',
    売電収入額_計画: metadata['売電収入額_計画'] ?? '',
    売電収入額_計画_累積: metadata['売電収入額_計画_累積'] ?? '',
    売電収入額_実績: '',
    売電収入額_実績_累積: '',
    売電収入額_前年実績: metadata['売電収入額_前年実績'] ?? '',
    営業外収益_計画: metadata['営業外収益_計画'] ?? '',
    営業外収益_計画_累積: metadata['営業外収益_計画_累積'] ?? '',
    営業外収益_実績: '',
    営業外収益_実績_累積: '',
    営業外収益_前年実績: metadata['営業外収益_前年実績'] ?? '',
    営業外費用_計画: metadata['営業外費用_計画'] ?? '',
    営業外費用_計画_累積: metadata['営業外費用_計画_累積'] ?? '',
    営業外費用_実績: '',
    営業外費用_実績_累積: '',
    営業外費用_前年実績: metadata['営業外費用_前年実績'] ?? '',
    経常利益_計画: metadata['経常利益_計画'] ?? '',
    経常利益_計画_累積: metadata['経常利益_計画_累積'] ?? '',
    経常利益_実績: '',
    経常利益_実績_累積: '',
    経常利益_前年実績: metadata['経常利益_前年実績'] ?? '',
    当期純利益_計画: metadata['当期純利益_計画'] ?? '',
    当期純利益_計画_累積: metadata['当期純利益_計画_累積'] ?? '',
    当期純利益_実績: '',
    当期純利益_実績_累積: '',
    当期純利益_前年実績: metadata['当期純利益_前年実績'] ?? '',
    減価償却費_計画: metadata['減価償却費_計画'] ?? '',
    減価償却費_計画_累積: metadata['減価償却費_計画_累積'] ?? '',
    減価償却費_実績: '',
    減価償却費_実績_累積: '',
    減価償却費_前年実績: metadata['減価償却費_前年実績'] ?? '',
    営業権償却費_計画: metadata['営業権償却費_計画'] ?? '',
    営業権償却費_計画_累積: metadata['営業権償却費_計画_累積'] ?? '',
    営業権償却費_実績: '',
    営業権償却費_実績_累積: '',
    営業権償却費_前年実績: metadata['営業権償却費_前年実績'] ?? '',
    修繕費_計画: metadata['修繕費_計画'] ?? '',
    修繕費_計画_累積: metadata['修繕費_計画_累積'] ?? '',
    修繕費_実績: '',
    修繕費_実績_累積: '',
    修繕費_前年実績: metadata['修繕費_前年実績'] ?? '',
    受取利息_計画: metadata['受取利息_計画'] ?? '',
    受取利息_計画_累積: metadata['受取利息_計画_累積'] ?? '',
    受取利息_実績: '',
    受取利息_実績_累積: '',
    受取利息_前年実績: metadata['受取利息_前年実績'] ?? '',
    支払利息_計画: metadata['支払利息_計画'] ?? '',
    支払利息_計画_累積: metadata['支払利息_計画_累積'] ?? '',
    支払利息_実績: '',
    支払利息_実績_累積: '',
    支払利息_前年実績: metadata['支払利息_前年実績'] ?? '',
    事業税_償却資産税_計画: metadata['事業税_償却資産税_計画'] ?? '',
    事業税_償却資産税_計画_累積: metadata['事業税_償却資産税_計画_累積'] ?? '',
    事業税_償却資産税_実績: '',
    事業税_償却資産税_実績_累積: '',
    事業税_償却資産税_前年実績: metadata['事業税_償却資産税_前年実績'] ?? '',
    法人税等_計画: metadata['法人税等_計画'] ?? '',
    法人税等_計画_累積: metadata['法人税等_計画_累積'] ?? '',
    法人税等_実績: '',
    法人税等_実績_累積: '',
    法人税等_前年実績: metadata['法人税等_前年実績'] ?? '',
  },
});

/**
 * 累積計算を行いメタデータに登録する
 * @param {EventChangeById | ExternalEvent} itemMap リクエストボディの項目をまとめて格納した配列
 * @param {number} accumulatedValue 累積値
 * @param {string} addMetadataKey Metadataに格納されている加算値のキー文字列
 * @param {string} accumulatedMetadataKey Metadataに格納されている累積値のキー文字列
 * @returns {number} 累積計算結果
 */
const accumulatedValueWithAddMetadata = (
  itemMap: EventChangeById | ExternalEvent,
  accumulatedValue: number,
  addMetadataKey: string,
  accumulatedMetadataKey: string,
): number => {
  let returnValue = accumulatedValue;
  if ('update' in itemMap) {
    // update
    const { metadata } = (itemMap as EventChangeById).update;
    if (!metadata) return returnValue;

    const castMetadataPatch = metadata as MetadataPatch;
    if ('add' in castMetadataPatch) {
      const addValue = castMetadataPatch.add[addMetadataKey];
      if (addValue) {
        // 小数演算誤差が生じるためライブラリを使用して演算
        returnValue = new Decimal(accumulatedValue).add(Number(addValue)).toNumber();
      }
      castMetadataPatch.add[accumulatedMetadataKey] = String(returnValue);
    }
  } else {
    // create
    const { metadata } = (itemMap as ExternalEvent);
    if (!metadata) return returnValue;

    const castMetadata = metadata as Metadata;
    const addValue = castMetadata[addMetadataKey];
    if (addValue) {
      // 小数演算誤差が生じるためライブラリを使用して演算
      returnValue = new Decimal(accumulatedValue).add(Number(addValue)).toNumber();
    }
    castMetadata[accumulatedMetadataKey] = String(returnValue);
  }

  return returnValue;
};

/**
 * 財務値の累積処理を行う
 * @param {(EventChangeById | ExternalEvent)[]} itemsMap リクエストボディの項目をまとめて格納した配列
 */
const accumulateDataPlannedFinance = (itemsMap: (EventChangeById | ExternalEvent)[]): void => {
  let accumulatedEbitda = 0; // EBITDA_計画_累積
  let accumulatedExpenses = 0; // 営業費用_計画_累積
  let accumulatedOperatingIncome = 0; // 営業利益_計画_累積
  let accumulatedSalesCost = 0; // 売上原価_計画_累積
  let accumulatedIncome = 0; // 売電収入額_計画_累積
  let accumulatedSga = 0; // 販管費_計画_累積
  let accumulatedNonOperatingIncome = 0; // 営業外収益_計画_累積
  let accumulatedNonOperatingExpenses = 0; // 営業外費用_計画_累積
  let accumulatedOrdinaryIncome = 0; // 経常利益_計画_累積
  let accumulatedNetIncome = 0; // 当期純利益_計画_累積
  let accumulatedDepreciation = 0; // 減価償却費_計画_累積
  let accumulatedGoodwillAmortization = 0; // 営業権償却費_計画_累積
  let accumulatedRepairExpense = 0; // 修繕費_計画_累積
  let accumulatedInterestIncome = 0; // 受取利息_計画_累積
  let accumulatedInterestExpense = 0; // 支払利息_計画_累積
  let accumulatedBusinessDepreciableTax = 0; // 事業税_償却資産税_計画_累積
  let accumulatedIncomeTaxes = 0; // 法人税等_計画_累積

  itemsMap.forEach((itemMap) => {
    accumulatedEbitda = accumulatedValueWithAddMetadata(
      itemMap, accumulatedEbitda, 'EBITDA_計画', 'EBITDA_計画_累積',
    );

    accumulatedExpenses = accumulatedValueWithAddMetadata(
      itemMap, accumulatedExpenses, '営業費用_計画', '営業費用_計画_累積',
    );

    accumulatedOperatingIncome = accumulatedValueWithAddMetadata(
      itemMap, accumulatedOperatingIncome, '営業利益_計画', '営業利益_計画_累積',
    );

    accumulatedSalesCost = accumulatedValueWithAddMetadata(
      itemMap, accumulatedSalesCost, '売上原価_計画', '売上原価_計画_累積',
    );

    accumulatedIncome = accumulatedValueWithAddMetadata(
      itemMap, accumulatedIncome, '売電収入額_計画', '売電収入額_計画_累積',
    );

    accumulatedSga = accumulatedValueWithAddMetadata(
      itemMap, accumulatedSga, '販管費_計画', '販管費_計画_累積',
    );

    accumulatedNonOperatingIncome = accumulatedValueWithAddMetadata(
      itemMap, accumulatedNonOperatingIncome, '営業外収益_計画', '営業外収益_計画_累積',
    );

    accumulatedNonOperatingExpenses = accumulatedValueWithAddMetadata(
      itemMap, accumulatedNonOperatingExpenses, '営業外費用_計画', '営業外費用_計画_累積',
    );

    accumulatedOrdinaryIncome = accumulatedValueWithAddMetadata(
      itemMap, accumulatedOrdinaryIncome, '経常利益_計画', '経常利益_計画_累積',
    );

    accumulatedNetIncome = accumulatedValueWithAddMetadata(
      itemMap, accumulatedNetIncome, '当期純利益_計画', '当期純利益_計画_累積',
    );

    accumulatedDepreciation = accumulatedValueWithAddMetadata(
      itemMap, accumulatedDepreciation, '減価償却費_計画', '減価償却費_計画_累積',
    );

    accumulatedGoodwillAmortization = accumulatedValueWithAddMetadata(
      itemMap, accumulatedGoodwillAmortization, '営業権償却費_計画', '営業権償却費_計画_累積',
    );

    accumulatedRepairExpense = accumulatedValueWithAddMetadata(
      itemMap, accumulatedRepairExpense, '修繕費_計画', '修繕費_計画_累積',
    );

    accumulatedInterestIncome = accumulatedValueWithAddMetadata(
      itemMap, accumulatedInterestIncome, '受取利息_計画', '受取利息_計画_累積',
    );

    accumulatedInterestExpense = accumulatedValueWithAddMetadata(
      itemMap, accumulatedInterestExpense, '支払利息_計画', '支払利息_計画_累積',
    );

    accumulatedBusinessDepreciableTax = accumulatedValueWithAddMetadata(
      itemMap, accumulatedBusinessDepreciableTax, '事業税_償却資産税_計画', '事業税_償却資産税_計画_累積',
    );

    accumulatedIncomeTaxes = accumulatedValueWithAddMetadata(
      itemMap, accumulatedIncomeTaxes, '法人税等_計画', '法人税等_計画_累積',
    );
  });
};

/**
 * 前年の単月データを元に、前年実績値を登録する
 * @param {number} year 指定の年
 * @param {number} month 指定の月
 * @param {PlannedFinanceMetadata} metadata メタデータ
 * @param {SolarSite} siteAsset 取得対象のサイト情報
 */
const addFinancialPriorYearDataToMetadata = async (
  year: number,
  month: number,
  metadata: PlannedFinanceMetadata,
  siteAsset: SolarSite,
): Promise<void> => {
  const priorYearFinanceData = await retrieveFinanceEvent(year - 1, month, siteAsset);
  if (!priorYearFinanceData) return;

  // 登録済みの実績値を前年実績として登録
  const priorMetadata = priorYearFinanceData.metadata;
  if (priorMetadata) {
    metadata['EBITDA_前年実績'] = priorMetadata['EBITDA_実績']; // eslint-disable-line no-param-reassign
    metadata['営業費用_前年実績'] = priorMetadata['営業費用_実績']; // eslint-disable-line no-param-reassign
    metadata['営業利益_前年実績'] = priorMetadata['営業利益_実績']; // eslint-disable-line no-param-reassign
    metadata['売上原価_前年実績'] = priorMetadata['売上原価_実績']; // eslint-disable-line no-param-reassign
    metadata['売電収入額_前年実績'] = priorMetadata['売電収入額_実績']; // eslint-disable-line no-param-reassign
    metadata['販管費_前年実績'] = priorMetadata['販管費_実績']; // eslint-disable-line no-param-reassign
    metadata['営業外収益_前年実績'] = priorMetadata['営業外収益_実績']; // eslint-disable-line no-param-reassign
    metadata['営業外費用_前年実績'] = priorMetadata['営業外費用_実績']; // eslint-disable-line no-param-reassign
    metadata['経常利益_前年実績'] = priorMetadata['経常利益_実績']; // eslint-disable-line no-param-reassign
    metadata['当期純利益_前年実績'] = priorMetadata['当期純利益_実績']; // eslint-disable-line no-param-reassign
    metadata['減価償却費_前年実績'] = priorMetadata['減価償却費_実績']; // eslint-disable-line no-param-reassign
    metadata['営業権償却費_前年実績'] = priorMetadata['営業権償却費_実績']; // eslint-disable-line no-param-reassign
    metadata['修繕費_前年実績'] = priorMetadata['修繕費_実績']; // eslint-disable-line no-param-reassign
    metadata['受取利息_前年実績'] = priorMetadata['受取利息_実績']; // eslint-disable-line no-param-reassign
    metadata['支払利息_前年実績'] = priorMetadata['支払利息_実績']; // eslint-disable-line no-param-reassign
    metadata['事業税_償却資産税_前年実績'] = priorMetadata['事業税_償却資産税_実績']; // eslint-disable-line no-param-reassign
    metadata['法人税等_前年実績'] = priorMetadata['法人税等_実績']; // eslint-disable-line no-param-reassign
  }
};

/**
 * リクエストボディの項目のデータ型がExternalEvent型かどうかを判定する
 * @param {EventChangeById | ExternalEvent} item 登録ないし更新用のリクエストボディの項目
 * @returns {item is ExternalEvent} 項目がExternalEventであるかの判定結果
 */
const itemIsExternalEvent = (item: EventChangeById | ExternalEvent): item is ExternalEvent => {
  if ((item as ExternalEvent).type) return true;
  return false;
};

/**
 * リクエストボディの項目をまとめて格納した配列のうち、登録用のもののみを抽出
 * 引数と戻り値の型は同じだが、呼び出し側で正しくタイプキャストするので後続処理への影響はない
 * @param {(EventChangeById | ExternalEvent)[]} items リクエストボディの項目をまとめて格納した配列
 * @returns {(EventChangeById | ExternalEvent)[]} 登録用のリクエストボディ項目のみを抽出した配列
 */
const filterCreateItems = (
  items: (EventChangeById | ExternalEvent)[],
): (
  EventChangeById | ExternalEvent
)[] => (
  items.filter((item) => {
    if (!itemIsExternalEvent(item)) return false;
    const { metadata } = (item as ExternalEvent);
    if (!metadata) return false;
    const keys = Object.keys(metadata).filter((key) => (key !== 'year' && key !== 'month'));
    return keys.length && keys.some((key) => (!!metadata[key]));
  })
);

/**
 * リクエストボディの項目をまとめて格納した配列のうち、更新用のもののみを抽出
 * 引数と戻り値の型は同じだが、呼び出し側で正しくタイプキャストするので後続処理への影響はない
 * @param {(EventChangeById | ExternalEvent)[]} items リクエストボディの項目をまとめて格納した配列
 * @returns {(EventChangeById | ExternalEvent)[]} 更新用のリクエストボディ項目のみを抽出した配列
 */
const filterUpdateItems = (
  items: (EventChangeById | ExternalEvent)[],
): (
  EventChangeById | ExternalEvent
)[] => (
  items.filter((item) => {
    if (itemIsExternalEvent(item)) return false;
    const { metadata } = (item as EventChangeById).update;
    if (!metadata || !('add' in metadata)) return false;
    const keys = Object.keys(metadata.add).filter((key) => (key !== 'year' && key !== 'month'));
    return keys.length && keys.some((key) => (!!metadata.add[key]));
  })
);

/**
 * 対象サイト、年度について、各月の単月データを取得し、存在するものは計画値を更新、しないものは新規作成する
 * @param {string[]} dates 対象年度の各月月初の日付一覧
 * @param {SolarSite} siteAsset 対象サイト情報
 * @param {MapItemWithData[]} plannedDataMap 項目ごとの和名と登録データを紐づけたマッピング情報
 * @param {string} fiscalYear 年度
 */
export const upsertPlannedFinanceEvents = async (
  dates: string[],
  siteAsset: SolarSite,
  plannedDataMap: MapItemWithData[],
  fiscalYear: string,
): Promise<void> => {
  const itemsMap: (EventChangeById | ExternalEvent)[] = await Promise.all(
    dates.map(async (date) => {
      const stringToDate = new Date(date);
      const year = stringToDate.getFullYear();
      const month = stringToDate.getMonth() + 1;
      const oldEvent = await retrieveFinanceEvent(year, month, siteAsset);
      const metadata = extractMonthlyDataFromMap(plannedDataMap, year, month, oldEvent);
      await addFinancialPriorYearDataToMetadata(year, month, metadata, siteAsset);
      return oldEvent
        ? formatUpdateItem(oldEvent, metadata) : formatCreateItem(siteAsset, metadata, fiscalYear);
    }),
  );

  // 累計値計算
  accumulateDataPlannedFinance(itemsMap);

  // データの型がExternalEventかどうかの判定を定義し、判定結果によって登録用・更新用のitemsに振り分ける
  const updateItems = filterUpdateItems(itemsMap) as EventChangeById[];
  const createItems = filterCreateItems(itemsMap) as ExternalEvent[];

  if (updateItems.length) {
    const updateBody = { items: updateItems };
    await putApiGateway<ItemsWrapper<EventChangeById[]>, ItemsResponse<CogniteEvent[]>>(
      EP_PATH_SOLAR_EVENTS, updateBody,
    );
  }
  if (createItems.length) {
    const createBody = { items: createItems };
    await postApiGateway<ItemsWrapper<ExternalEvent[]>, ItemsResponse<CogniteEvent[]>>(
      EP_PATH_SOLAR_EVENTS, createBody,
    );
  }
};
