import React, { useState, useEffect } from 'react';
import {
  Typography, Divider, Row, Col, Descriptions, Button, Tooltip, message,
} from 'antd';
import moment from 'moment';

import OperationalStatusTable, { PcsOperationalStatus } from './OperationalStatusTable';
import ElectricEnergyOccurrenceDates from './parts/ElectricEnergyOccurrenceDates';
import SiteCascader, { SiteCascaderSelect } from './parts/SiteCascader';
import SolarSite from '../../utils/Asset/SolarSite';
import OperationalStatus from '../../utils/Event/OperationalStatus';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import {
  INFORMATION_NO_SEARCH_RESULTS,
  ERROR_FILE_DOWNLOAD,
  ERROR_NO_AUTH_MESSAGE,
  ERROR_SEARCH_RESULT_OVER_LIMIT,
  ERROR_SITE_NOT_SELECTED,
  VALIDATE_ERROR_UNSELECTED_OPERATION_STATUS,
} from '../../utils/messages';
import './OperationalStatusList.css';
import { generateCsvData } from '../../utils/File/CsvFile';

const { Title } = Typography;

interface OperationalStatusListProps {
  display: '削除' | '一覧';
}

/**
 * 運転状況一覧画面 操作活性状態
 */
type OperationalStatusListOperations = {
  isSearchButtonDisabled: boolean;
  isDownloadButtonDisabled: boolean;
};

/**
 * 運転状況一覧画面
 */
export default function OperationalStatusList(props: OperationalStatusListProps): JSX.Element {
  const [occurrenceDates, setOccurrenceDates] = useState<[moment.Moment, moment.Moment] | []>([]);
  const [solarSite, setSolarSite] = useState<SolarSite | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [operationalStatuses, setOperationalStatuses] = useState<PcsOperationalStatus[]>([]);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [operationalDisabled, setOperationalDisabled] = useState<OperationalStatusListOperations>({
    isSearchButtonDisabled: true,
    isDownloadButtonDisabled: true,
  });
  const { display } = props;
  /**
   * ボタン権限制御
   */
  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        SOLAR_MENU: {
          OPERATIONAL_STATUS_LIST: {
            OPERATIONAL_STATUS_SEARCH, OPERATIONAL_STATUS_DOWNLOAD,
          },
        },
      } = AUTHENTICATION_TYPE_MATRIX;

      const isSearchButtonDisabled = !await containsUIAuthType(OPERATIONAL_STATUS_SEARCH);
      const isDownloadButtonDisabled = !await containsUIAuthType(OPERATIONAL_STATUS_DOWNLOAD);

      if (!canceled) {
        setOperationalDisabled({ isSearchButtonDisabled, isDownloadButtonDisabled });
      }
    })();

    return () => { canceled = true; };
  }, []);

  /**
   * 発生日時選択時のイベントハンドラ
   * @param {[] | [moment.Moment, moment.Moment]} dates 選択した発生日時
   */
  const handleChangeOccurrenceDates = (dates: [] | [moment.Moment, moment.Moment]): void => {
    setOccurrenceDates(dates);
  };

  /**
   * サイト選択時のイベントハンドラ
   * @param {SiteCascaderSelect | undefined} selectedValues 選択したサイト情報
   */
  const handleChangeSite = (selectedValues?: SiteCascaderSelect): void => {
    if (!selectedValues) {
      setSolarSite(selectedValues);
    } else {
      const value = new SolarSite(selectedValues.site);
      setSolarSite(value);
    }
  };

  /**
   * 検索ボタンクリック時のイベントハンドラ
   */
  const handleClickSearch = async (): Promise<void> => {
    setIsLoading(true);

    if (!solarSite) {
      message.error(ERROR_SITE_NOT_SELECTED);
      setIsLoading(false);
      return;
    }

    const pcsAssets = await solarSite.loadChildrenFromCDF();
    const pcsIds = pcsAssets.map((pcsAsset) => pcsAsset.id);

    const loadedOperationalStatuses = await OperationalStatus.loadOperationalStatus(pcsIds, occurrenceDates);
    if (loadedOperationalStatuses.items.length === 0) {
      message.info(INFORMATION_NO_SEARCH_RESULTS);
      setIsLoading(false);
      setOperationalStatuses([]);
      return;
    }

    if (loadedOperationalStatuses.nextCursor) {
      // 検索上限を超えた場合、検索条件を絞り込むようメッセージを表示
      message.error(ERROR_SEARCH_RESULT_OVER_LIMIT);
      setIsLoading(false);
      return;
    }

    const displayData: PcsOperationalStatus[] = loadedOperationalStatuses.items
      .map((loadOperationalStatus) => {
        // assetIdsで検索をかけているためassetIdsがundefinedになることはない
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const pcsAsset = pcsAssets.find((pcs) => pcs.id === loadOperationalStatus.assetIds![0]);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const pcsOperationalStatus: PcsOperationalStatus = Object.assign(loadOperationalStatus, { pcsName: pcsAsset!.name });
        return pcsOperationalStatus;
      })
      .sort((a, b) => {
        // 第1ソート: PCS名昇順
        if (a.pcsName > b.pcsName) return 1;
        if (a.pcsName < b.pcsName) return -1;
        // 第2ソート: 開始年月日降順
        if (!a.startTime) return -1;
        if (!b.startTime) return 1;
        if (a.startTime > b.startTime) return -1;
        if (a.startTime < b.startTime) return 1;

        return 0;
      });

    setOperationalStatuses(displayData);
    setIsLoading(false);
  };

  /**
   * ダウンロードボタンクリック時のイベントハンドラ
   */
  const handleClickDownload = async (): Promise<void> => {
    setDownloading(true);

    if (!selectedRowKeys.length) {
      message.error(VALIDATE_ERROR_UNSELECTED_OPERATION_STATUS);
      setDownloading(false);
      return;
    }

    const selectedOperationalStatuses = operationalStatuses.filter((operationalStatus) => selectedRowKeys.includes(operationalStatus.id));
    const header = [
      { key: 'pcsName', headerString: 'PCS' },
      { key: 'status', headerString: '逸失原因' },
      { key: 'startDate', headerString: '開始年月日' },
      { key: 'startTimeToDisplay', headerString: '開始時刻' },
      { key: 'endDate', headerString: '終了年月日' },
      { key: 'endTimeToDisplay', headerString: '終了時刻' },
      { key: 'lostEnergy', headerString: '逸失発電量（kWh）' },
    ];
    const csvData = generateCsvData(header, selectedOperationalStatuses);

    const aTagForDownload = document.createElement('a');
    let url = '';
    try {
      url = URL.createObjectURL(csvData);
      document.body.appendChild(aTagForDownload);
      aTagForDownload.href = url;
      aTagForDownload.download = `SDF_運転状況一覧_${moment().format('YYYYMMDDHHmmss')}.csv`;
      aTagForDownload.click();
    } catch (e) {
      message.error(ERROR_FILE_DOWNLOAD);
    } finally {
      aTagForDownload.remove();
      URL.revokeObjectURL(url);
    }

    setDownloading(false);
  };

  const { isSearchButtonDisabled, isDownloadButtonDisabled } = operationalDisabled;
  return (
    <div className={display === '一覧' ? 'operational-status-list-container' : 'operational-status-delete-container'}>
      {display === '一覧' && (
        <div>
          <div className="operational-status-list-title">
            <div className="solar-operational-status-input-margin">
              <Title level={4}>運転状況一覧</Title>
            </div>
          </div>
          <Divider className="operational-status-list-divider" />
        </div>
      )}
      {display === '削除' && (
        <div className="operational-status-list-title">
          <div className="solar-operational-status-input-margin">
            <Title level={4}>逸失発電量検索</Title>
          </div>
          <Divider className="operational-status-list-divider" />
        </div>
      )}
      <Row>
        <Col span={20} offset={1}>
          <Descriptions bordered>
            <Descriptions.Item label="発生日時" span={3}>
              <ElectricEnergyOccurrenceDates onChange={handleChangeOccurrenceDates} />
            </Descriptions.Item>
            <Descriptions.Item
              label={(
                <>
                  サイト選択
                  <span className="operational-status-list-annotation">※</span>
                </>
              )}
              span={3}
            >
              <SiteCascader
                onChange={handleChangeSite}
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={17} offset={1}>
          <span className="operational-status-list-annotation">※</span>
          必須項目
        </Col>
        <Col span={4}>
          <Tooltip title={isSearchButtonDisabled ? ERROR_NO_AUTH_MESSAGE : '検索'}>
            <Button
              type="primary"
              onClick={handleClickSearch}
              style={{ marginTop: 16, marginBottom: 16 }}
              loading={isLoading}
              disabled={isSearchButtonDisabled}
            >
              検索
            </Button>
          </Tooltip>
          {display === '一覧' && (
            <Tooltip title={isDownloadButtonDisabled ? ERROR_NO_AUTH_MESSAGE : 'ダウンロード'}>
              <Button
                type="primary"
                onClick={handleClickDownload}
                style={{ marginLeft: 15 }}
                loading={downloading}
                disabled={downloading || isDownloadButtonDisabled}
              >
                ダウンロード
              </Button>
            </Tooltip>
          )}
        </Col>
      </Row>
      {
        operationalStatuses.length > 0 && (
          <Row>
            <OperationalStatusTable
              loading={isLoading}
              operationalStatuses={operationalStatuses}
              handleChangeRowSelect={setSelectedRowKeys}
              display={display}
            />
          </Row>
        )
      }
    </div>
  );
}
