import React from 'react';
import { Tabs } from 'antd';
import SolarOperationalStatusInput from './SolarOperationalStatusInput';
import './SolarOperationalStatusTabs.css';
import OperationalStatusList from './OperationalStatusList';

const { TabPane } = Tabs;

/** タブ表示文字列 */
const TAB_KEYS = {
  SOLAR_OPERATIONAL_STATUS_INPUT: '逸失原因登録',
  SOLAR_OPERATIONAL_STATUS_DELETE: '逸失発電量削除',
};

/**
 * 運転状況画面タブ
 */
const SolarOperationalStatusTabs: React.FC = () => (
  <div className="solar-operational-status-tabs-container">
    <Tabs
      className="solar-operational-status-tabs"
      type="card"
    >
      <TabPane tab={TAB_KEYS.SOLAR_OPERATIONAL_STATUS_INPUT} key={TAB_KEYS.SOLAR_OPERATIONAL_STATUS_INPUT}>
        <SolarOperationalStatusInput />
      </TabPane>
      <TabPane tab={TAB_KEYS.SOLAR_OPERATIONAL_STATUS_DELETE} key={TAB_KEYS.SOLAR_OPERATIONAL_STATUS_DELETE}>
        <OperationalStatusList display="削除" />
      </TabPane>
    </Tabs>
  </div>
);

export default SolarOperationalStatusTabs;
