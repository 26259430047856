import React from 'react';

import { Cascader, message } from 'antd';
import { CascaderOptionType } from 'antd/lib/cascader';

import Facility from '../../../utils/Asset/Facility';
import DetectionResult from '../../../utils/Asset/DetectionResult';
import { ResourceType } from '../../../utils/common/SDFDataType';
import { ERROR_LOAD_INSPECTION_RESULTS_LIST } from '../../../utils/messages';

const CascaderOption = {
  UploadedFiles: {
    label: 'アップロードされたファイル',
    value: '0',
  },
  DetectionResults: {
    label: 'AI検出結果',
    value: '-1',
  },
} as const;

export const FacilityAndDetectionResultCascader: React.FC<{
  facility?: Facility;
  detectionResultId?: number;
  isFileListDisabled?: boolean;
  onChange: (detectionResult?: DetectionResult) => void;
  onSelectResource: (resourceType: ResourceType) => void;
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [options, setOptions] = React.useState<CascaderOptionType[]>([]);
  const [value, setValue] = React.useState<string[]>([CascaderOption.UploadedFiles.value]);
  const [detectionResults, setDetectionResults] = React.useState<DetectionResult[]>([]);

  const {
    facility, detectionResultId, isFileListDisabled, onChange, onSelectResource,
  } = props;

  /*
   * 副作用フック
   */
  React.useEffect(() => {
    const newOptions: CascaderOptionType[] = [
      {
        label: CascaderOption.UploadedFiles.label,
        value: CascaderOption.UploadedFiles.value,
        isLeaf: true,
      },
    ];

    if (!facility) {
      setOptions(newOptions);
      return () => { /* 何もしない */ };
    }

    let canceled = false;
    (async () => {
      let newDetectionResults: DetectionResult[] = [];

      try {
        newDetectionResults = await facility.loadDetectionResultsFromCDF();
      } catch (exception) {
        message.error(ERROR_LOAD_INSPECTION_RESULTS_LIST);
      }

      if (newDetectionResults.length > 0) {
        newOptions.push({
          label: CascaderOption.DetectionResults.label,
          value: CascaderOption.DetectionResults.value,
          isLeaf: false,
          children: newDetectionResults.map((detectionResult) => ({
            label: detectionResult.name,
            value: String(detectionResult.id),
            isLeaf: true,
          })),
        });
      }

      if (!canceled) {
        setOptions(newOptions);
        setDetectionResults(newDetectionResults);
      }
    })();

    return () => { canceled = true; };
  }, [facility]);

  React.useEffect(() => {
    if (!detectionResultId) {
      setValue([CascaderOption.UploadedFiles.value]);
      onSelectResource(ResourceType.Facility);
      return;
    }

    const targetDetectionResult = detectionResults.find(
      (detectionResult) => detectionResult.id === detectionResultId,
    );
    if (targetDetectionResult) {
      setValue([
        CascaderOption.DetectionResults.value,
        String(targetDetectionResult.id),
      ]);
      onSelectResource(ResourceType.AIDetectResult);
    } else {
      onChange();
    }
  }, [detectionResultId, detectionResults, onChange, onSelectResource]);

  /*
   * 画面描画
   */
  return (
    <Cascader
      value={value}
      options={options}
      changeOnSelect
      onChange={(_value) => {
        if (_value[0] === CascaderOption.UploadedFiles.value) {
          onSelectResource(ResourceType.Facility);
          onChange();
        } else {
          const targetDetectionResult = detectionResults.find(
            (detectionResult) => detectionResult.id === Number(_value[1]),
          );
          if (targetDetectionResult) {
            onSelectResource(ResourceType.AIDetectResult);
            onChange(targetDetectionResult);
          } else {
            onChange();
          }
        }
      }}
      size="small"
      allowClear={false}
      disabled={!facility || isFileListDisabled}
    />
  );
};
