/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isEmpty } from '../common';
import {
  VALIDATE_ERROR_LEARNING_PROJECT_WITH_MODEL_REQUIRE,
  VALIDATE_ERROR_CLASSES_REQUIRE,
  VALIDATE_ERROR_CLASSES_OVER,
  VALIDATE_ERROR_DETECT_TASK_NAME_REQUIRE,
  VALIDATE_ERROR_DETECT_TASK_NAME_SPECIFY_NON_TEXT,
} from '../messages';

/** クラス数上限 */
const CLASSES_LIMIT = 5;
/** 指定不可文字(「<」「> 」「:」「"」「/」「\」「|」「?」「*」) */
const SPECIFY_NON_TEXT = ['<', '>', ':', '"', '/', '\\', '|', '?', '*'];

/**
 * 通常モデルの検出パラメータを検証する。下記の場合、エラーと判断する。
 * - 学習モデルが未選択の場合
 * - クラスが未選択の場合
 * - クラスが6個以上選択している場合
 * - 検出タスク名が未入力の場合
 * @param {number} modelId 学習モデルID
 * @param {array} classes クラス
 * @param {string} detectTaskName 検出タスク名
 * @returns {object} { hasError: boolean, errorField: string, errorValue: any, message: string }
 */
function validateDetectParametersForStandard({ modelId, classes, detectTaskName }) {
  // 学習モデル必須チェック
  if (!modelId) {
    return {
      hasError: true,
      errorField: 'standardModel',
      errorValue: modelId,
      message: VALIDATE_ERROR_LEARNING_PROJECT_WITH_MODEL_REQUIRE,
    };
  }

  // クラス必須チェック
  if (classes.length === 0) {
    return {
      hasError: true,
      errorField: 'standardModelClasses',
      errorValue: classes,
      message: VALIDATE_ERROR_CLASSES_REQUIRE,
    };
  }

  // クラス選択数超過チェック
  if (classes.length > CLASSES_LIMIT) {
    return {
      hasError: true,
      errorField: 'standardModelClasses',
      errorValue: classes,
      message: VALIDATE_ERROR_CLASSES_OVER,
    };
  }

  // 検出タスク名入力チェック
  const isEmptyDetectTaskName = isEmpty(detectTaskName);
  if (isEmptyDetectTaskName) {
    return {
      hasError: true,
      errorField: 'standardModelDetectTaskName',
      errorValue: detectTaskName,
      message: VALIDATE_ERROR_DETECT_TASK_NAME_REQUIRE,
    };
  }

  // 検出タスク名指定不可文字チェック
  const isIncludesSpecifyNonText = SPECIFY_NON_TEXT.some((text) => detectTaskName.includes(text));
  if (isIncludesSpecifyNonText) {
    return {
      hasError: true,
      errorField: 'standardModelDetectTaskName',
      errorValue: detectTaskName,
      message: VALIDATE_ERROR_DETECT_TASK_NAME_SPECIFY_NON_TEXT,
    };
  }

  return { hasError: false };
}

/**
 * アンサンブルモデルの検出パラメータを検証する。下記の場合、エラーと判断する。
 * - 学習モデルが未選択の場合
 * - 検出タスク名が未入力の場合
 * @param {number} modelId 学習モデルID
 * @param {string} detectTaskName 検出タスク名
 * @returns {object} { hasError: boolean, errorField: string, errorValue: any, message: string }
 */
function validateDetectParametersForEnsemble({ modelId, detectTaskName }) {
  // 学習モデル必須チェック
  if (!modelId) {
    return {
      hasError: true,
      errorField: 'ensembleModel',
      errorValue: modelId,
      message: VALIDATE_ERROR_LEARNING_PROJECT_WITH_MODEL_REQUIRE,
    };
  }

  // 検出タスク名入力チェック
  const isEmptyDetectTaskName = isEmpty(detectTaskName);
  if (isEmptyDetectTaskName) {
    return {
      hasError: true,
      errorField: 'ensembleModelDetectTaskName',
      errorValue: detectTaskName,
      message: VALIDATE_ERROR_DETECT_TASK_NAME_REQUIRE,
    };
  }

  // 検出タスク名指定不可文字チェック
  const isIncludesSpecifyNonText = SPECIFY_NON_TEXT.some((text) => detectTaskName.includes(text));
  if (isIncludesSpecifyNonText) {
    return {
      hasError: true,
      errorField: 'ensembleModelDetectTaskName',
      errorValue: detectTaskName,
      message: VALIDATE_ERROR_DETECT_TASK_NAME_SPECIFY_NON_TEXT,
    };
  }

  return { hasError: false };
}

export { validateDetectParametersForStandard, validateDetectParametersForEnsemble };
