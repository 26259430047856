import React from 'react';
import {
  AssetListScope,
} from '@cognite/sdk';

import AssetTree from '../Common/Asset/AssetTree';
import { ResourceType } from '../../utils/common/SDFDataType';
import { SolarClientGroup, getDashboardUrlMetadataKey, getSiteSelectScreenTitle } from '../../utils/common/SolarClientGroup';
import Facility from '../../utils/Asset/Facility';
import DetectionResult from '../../utils/Asset/DetectionResult';
import SolarSite from '../../utils/Asset/SolarSite';
import { EP_PATH_SOLAR_EVENTS_LIST } from '../../utils/AWS/EndpointPath';
import { getAllEvents } from '../../utils/dataAccess';
import { loadAllSolarSiteFromCDFByScope } from '../../utils/Asset/SolarSiteAsset';

interface SolarDashboardSiteSelectProps {
  clientGroup: SolarClientGroup,
  onClickDashboardMenu: (url: string) => void,
}

const SDF_ASSET_TYPE = process.env.REACT_APP_SDF_ASSET ?? 'sdfasset';

/**
 * 運用ダッシュボードサイト選択コンポーネント
 */
const SolarDashboardSiteSelect: React.FC<SolarDashboardSiteSelectProps> = (props) => {
  const {
    clientGroup,
    onClickDashboardMenu,
  } = props;

  const [parentAssetsId, setParentAssetsId] = React.useState<number>();

  /*
   * イベントハンドラ
   */
  React.useEffect(() => {
    (async () => {
      const scope: AssetListScope = {
        filter: {
          metadata: {
            assetType: SDF_ASSET_TYPE,
          },
        },
        limit: 1000,
      };
      const siteInfoAsset = await loadAllSolarSiteFromCDFByScope(scope);
      setParentAssetsId(siteInfoAsset[0].id);
    })();
  }, []);

  /**
   * 太陽光サイトアセット選択時のイベントハンドラ
   * @param {Facility | DetectionResult | SolarSite | undefined} asset 選択したAsset情報
   */
  const onSelectSiteAsset = async (
    asset: Facility | DetectionResult | SolarSite | undefined,
  ): Promise<void> => {
    if (!asset) return;

    const dashboardUrlEventEventFilter = {
      type: 'dashboard_url',
      assetIds: [asset.id],
    };
    const dashboardUrlEvents = await getAllEvents(
      EP_PATH_SOLAR_EVENTS_LIST,
      dashboardUrlEventEventFilter,
    );

    if (dashboardUrlEvents.length === 0) return;

    const urlMetadataKey = getDashboardUrlMetadataKey(clientGroup);

    onClickDashboardMenu(dashboardUrlEvents[0].metadata[urlMetadataKey]);
  };

  /**
   * サイト運転状況選択画面をレンダリングする
   */
  return (
    <AssetTree
      parentAssetsId={parentAssetsId}
      title={getSiteSelectScreenTitle(clientGroup)}
      onSelect={(facility) => {
        onSelectSiteAsset(facility);
      }}
      rootResourceType={ResourceType.SolarSite}
      isExpandedRoot
    />
  );
};

export default SolarDashboardSiteSelect;
