import React from 'react';

import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import ActivityCheckHistory from '../../utils/Event/ActivityCheckHistory';

import './ActivityCheckHistoryTable.css';

/**
 * 文字列項目のソート判定を実施する
 * @param {string} a 文字列項目A
 * @param {string} b 文字列項目B
 * @returns {number} ソートの判定結果
 */
const getSortedItems = (a: string, b: string) => {
  const first = [a, b].sort()[0];
  return first === a ? -1 : 1;
};

interface ActivityCheckHistoryTableProps {
  loading: boolean;
  historyEvents: ActivityCheckHistory[];
}

/**
 * 検索済み死活監視結果イベント一覧テーブル
 * @param {ActivityCheckHistoryTableProps} props プロパティ
 * @returns 検索済み死活監視結果イベント一覧テーブル
 */
const ActivityCheckHistoryTable: React.FC<ActivityCheckHistoryTableProps> = (
  props: ActivityCheckHistoryTableProps,
) => {
  const { loading, historyEvents } = props;

  const columns: ColumnProps<ActivityCheckHistory>[] = [
    {
      title: '発生日時',
      dataIndex: 'startDate',
      width: 200,
      sorter: (a, b) => getSortedItems(a.dateForDisplay, b.dateForDisplay),
      render: (_, item: ActivityCheckHistory) => item.dateForDisplay,
    },
    {
      title: 'GMU-ID',
      dataIndex: 'gmuId',
      width: 200,
      sorter: (a, b) => getSortedItems(a.gmuId, b.gmuId),
      render: (_, item: ActivityCheckHistory) => item.gmuId,
    },
    {
      title: 'サイト名',
      dataIndex: 'message',
      width: 200,
      sorter: (a, b) => getSortedItems(a.message, b.message),
      render: (_, item: ActivityCheckHistory) => item.message,
    },
  ];

  return (
    <div>
      <Table<ActivityCheckHistory>
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={historyEvents}
        pagination={{
          className: 'activity-check-history-table-pagination',
          position: 'bottom',
          pageSize: 100,
        }}
        scroll={{ y: 616 }}
      />
    </div>
  );
};

export default ActivityCheckHistoryTable;
