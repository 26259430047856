import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { FilterIcon, TextFilter } from './parts/FilterComponent';
import IntervalSettingModal from './parts/IntervalSettingModal';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import ActivityCheckSetting from '../../utils/DynamoDB/ActivityCheckSetting';
import { ERROR_NO_AUTH_MESSAGE } from '../../utils/messages';

import './ActivityCheckIntervalSetting.css';

const { Title } = Typography;

/**
  * 文字列項目のソート判定を実施する
  * @param {string} a 文字列項目A
  * @param {string} b 文字列項目B
  * @returns {number} ソートの判定結果
  */
const getSortedItems = (a: string, b: string): number => {
  const first = [a, b].sort()[0];
  return first === a ? -1 : 1;
};

/**
 * 死活監視判定間隔画面
 * @returns 死活監視判定間隔画面コンポーネント
 */
const ActivityCheckIntervalSetting: React.FC = () => {
  const [activityCheckSiteList, setActivityCheckSetting] = useState<ActivityCheckSetting[]>([]); // 死活監視間隔の一覧
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState<boolean>(true); // 編集ボタンの権限
  const [isModalVisible, setIsModalVisible] = useState(false); // モーダルの可視
  const [selectedRowData, setSelectedRowData] = useState<ActivityCheckSetting | null>(null); // 編集ボタンをクリックした時の行データ
  const [loading, setLoading] = useState<boolean>(false); // 表のロード
  const [tableRefresh, setTableRefresh] = useState<number>(0); // 更新後のテーブルのリフレッシュ

  useEffect(() => {
    let canceled = false;

    (async () => {
      setLoading(true);
      const allGroups = await ActivityCheckSetting.getAllActivityCheckSettings()
        .then((allSettings) => (
          [...allSettings].sort((a, b) => {
            if (a.managementNo === b.managementNo) {
              return getSortedItems(a.gmuId, b.gmuId);
            }
            return getSortedItems(a.managementNo, b.managementNo);
          })
        ));
      const {
        SOLAR_MENU: {
          ACTIVITY_CHECK_INTERVAL_SETTING: { ACTIVITY_CHECK_INTERVAL_SETTING_UPDATE_BUTTON },
        },
      } = AUTHENTICATION_TYPE_MATRIX;
      if (!canceled) {
        setActivityCheckSetting(allGroups);
        setIsEditButtonDisabled(!await containsUIAuthType(ACTIVITY_CHECK_INTERVAL_SETTING_UPDATE_BUTTON));
      }
      setLoading(false);
    })();

    return () => { canceled = true; };
  }, [tableRefresh]);

  /**
   * 編集ボタンをクリックし、モーダルを開くときのイベントハンドラ
   * @param {ActivityCheckSetting} item 選択した行のデータ
   */
  const handleOpenModal = (item: ActivityCheckSetting) => {
    setSelectedRowData(item);
    setIsModalVisible(true);
  };

  /**
   * モーダルを閉じる時のイベントハンドラ
   */
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  /**
   * 親画面の一覧リフレッシュ処理
   */
  const handleTableRefresh = () => {
    setTableRefresh(tableRefresh + 1);
  };

  const columns: ColumnProps<ActivityCheckSetting>[] = [
    {
      title: '会社名',
      dataIndex: 'company',
      width: 170,
      sorter: (a, b) => getSortedItems(a.companyNameJp, b.companyNameJp),
      filterIcon: FilterIcon,
      filterDropdown: !!activityCheckSiteList && TextFilter,
      onFilter: (value, result) => (
        result.companyNameJp
          ? result.companyNameJp.toLowerCase().includes((value as string).toLowerCase())
          : false
      ),
      render: (_, item: ActivityCheckSetting) => item.companyNameJp,
    },
    {
      title: 'サイト名',
      dataIndex: 'siteName',
      width: 170,
      sorter: (a, b) => getSortedItems(a.message, b.message),
      filterIcon: FilterIcon,
      filterDropdown: !!activityCheckSiteList && TextFilter,
      onFilter: (value, result) => (
        result.message
          ? result.message.toLowerCase().includes((value as string).toLowerCase())
          : false
      ),
      render: (_, item: ActivityCheckSetting) => item.message,
    },
    {
      title: 'GMU-ID',
      dataIndex: 'gmuId',
      width: 170,
      sorter: (a, b) => getSortedItems(a.gmuId, b.gmuId),
      filterIcon: FilterIcon,
      filterDropdown: !!activityCheckSiteList && TextFilter,
      onFilter: (value, result) => (
        result.gmuId
          ? result.gmuId.toLowerCase().includes((value as string).toLowerCase())
          : false
      ),
      render: (_, item: ActivityCheckSetting) => item.gmuId,
    },
    {
      title: '判定間隔',
      dataIndex: 'interval',
      width: 100,
      render: (_, item: ActivityCheckSetting) => (item.checkIntervalTime ? (`${item.checkIntervalTime}時間`) : '判定なし'),
    },
    {
      dataIndex: 'edit',
      width: 70,
      render: (_, item: ActivityCheckSetting) => (
        <div>
          <Tooltip title={isEditButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
            <Button
              type="primary"
              disabled={isEditButtonDisabled}
              onClick={() => handleOpenModal(item)}
            >
              編集
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="activity-check-interval-setting-container">
      <Spin spinning={loading}>
        <div className="activity-check-interval-setting-title">
          <Title level={4}>死活監視判定間隔設定</Title>
        </div>
        <Divider className="activity-check-interval-setting-divider" />

        {selectedRowData && (
          <IntervalSettingModal
            modalRenderData={selectedRowData}
            visible={isModalVisible}
            closeModal={handleCloseModal}
            refreshParentTable={handleTableRefresh}
          />
        )}
        <Table<ActivityCheckSetting>
          className="activity-check-interval-setting-table"
          dataSource={activityCheckSiteList}
          pagination={false}
          columns={columns}
          rowKey="gmuId"
          scroll={{ y: 616 }}
        />
      </Spin>
    </div>
  );
};

export default ActivityCheckIntervalSetting;
