/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Table, Tooltip } from 'antd';
import { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table';

import OMAlertEvent, { SeverityCode } from '../../../../utils/Event/OMAlertEvent';

import './AlertEventTable.css';

// テーブル表示用の警報種別名称
const DISPLAY_SEVERITY_CODES: Record<SeverityCode, string> = {
  A: 'A：重故障',
  B: 'B：軽故障',
  C: 'C：状態',
  '': '',
};

const defaultPagination: PaginationConfig = {
  position: 'bottom',
  defaultCurrent: 1,
  defaultPageSize: 100,
  showTotal: (total, range) => `${total}件中${range[0]}から${range[1]}まで表示`,
};

interface AlertEventTableProps {
  loading?: boolean;
  showPagination?: boolean;
  currentPage?: number;
  pageSize?: number;
  scrollY?: number;
  alertEvents: OMAlertEvent[];
  sortInfo: SorterResult<OMAlertEvent> | null;
  setCurrentPage?: (page: number) => void;
  handleSortChange: (sorter: SorterResult<OMAlertEvent> | null) => void;
}

/**
 * ソートして返却
 * @param {string} a
 * @param {string} b
 * @returns {number} ソートの判定結果
 */
const getSortedItems = (a: string, b: string) => {
  const first = [a, b].sort()[0];
  return first === a ? -1 : 1;
};

/**
 * 警報情報一覧テーブルのコンポーネント
 * @param {AlertEventTableProps} props プロパティ
 * @returns 対象アセットに紐付く警報情報一覧のテーブル
 */
const AlertEventTable: React.FC<AlertEventTableProps> = (props: AlertEventTableProps) => {
  const {
    loading,
    showPagination,
    currentPage,
    pageSize,
    scrollY,
    alertEvents,
    sortInfo,
    setCurrentPage,
    handleSortChange,
  } = props;
  // 初期表示時を日時の降順とする
  alertEvents.sort((a: OMAlertEvent, b: OMAlertEvent) => getSortedItems(b.dateForDisplay, a.dateForDisplay));

  const [pagination, setPagination] = useState<PaginationConfig>({
    ...defaultPagination,
    current: currentPage,
    onChange: (page) => setCurrentPage && setCurrentPage(page),
  });

  useEffect(() => {
    const newPagination = {
      ...defaultPagination,
      pageSize,
    };
    setPagination(newPagination);
    if (setCurrentPage) setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, setCurrentPage]);

  useEffect(() => {
    const newPagination = {
      ...pagination,
      current: currentPage,
    };
    setPagination(newPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const columns: ColumnProps<OMAlertEvent>[] = [
    {
      title: '日時',
      dataIndex: 'date',
      width: 200,
      sorter: (a, b) => getSortedItems(a.dateForDisplay, b.dateForDisplay),
      sortOrder: sortInfo?.columnKey === 'date' && sortInfo.order,
      render: (_, item: OMAlertEvent) => {
        const value = item.dateForDisplay;
        const uniqueKey = `date_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '警報種別',
      dataIndex: 'alertType',
      width: 200,
      sorter: (a, b) => a.severity - b.severity,
      sortOrder: sortInfo?.columnKey === 'alertType' && sortInfo.order,
      render: (_, item: OMAlertEvent) => {
        const value = DISPLAY_SEVERITY_CODES[item.severityCode];
        const uniqueKey = `alertType_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: 'フロア名',
      dataIndex: 'floorName',
      width: 200,
      sorter: (a, b) => getSortedItems(a.floorName, b.floorName),
      sortOrder: sortInfo?.columnKey === 'floorName' && sortInfo.order,
      render: (_, item: OMAlertEvent) => {
        const value = item.floorName;
        const uniqueKey = `floorName_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '警報内容',
      dataIndex: 'alertName',
      width: 200,
      sorter: (a, b) => getSortedItems(a.signalName, b.signalName),
      sortOrder: sortInfo?.columnKey === 'alertName' && sortInfo.order,
      render: (_, item: OMAlertEvent) => {
        const value = item.signalName;
        const uniqueKey = `alertName_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '発生',
      dataIndex: 'alertState',
      width: 200,
      sorter: (a, b) => getSortedItems(String(a.happening), String(b.happening)),
      sortOrder: sortInfo?.columnKey === 'alertState' && sortInfo.order,
      render: (_, item: OMAlertEvent) => {
        const value = item.alertState;
        const uniqueKey = `alertState_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
  ];

  return (
    <div>
      <Table<OMAlertEvent>
        loading={loading ?? false}
        rowKey="id"
        columns={columns}
        dataSource={alertEvents}
        onChange={(_, __, sorter) => handleSortChange(sorter)}
        pagination={!!showPagination && pagination}
        rowClassName={(item, _) => `om-dashboard-alert-table-row-${item.severity}`}
        scroll={{ y: scrollY ?? 570 }}
      />
    </div>
  );
};

export default AlertEventTable;
