/* eslint-disable react/no-unused-class-component-methods */
// eslint警告未対応
import React from 'react';
import { Select } from 'antd';

/**
 * OperatorSelect component
 */
class OperatorSelect extends React.Component {
  /**
   * component render後の処理
   */
  componentDidMount() {
    const { defaultValue } = this.props;
    this.selectedValue = defaultValue;
  }

  /**
   * OperatorSelectのパラメータを取得する
   */
  getListCardValue = () => ({ operator: this.selectedValue });

  /**
   * ParamCardのパラメータを取得する
   */
  getParamCardValue = () => ({ operator: this.selectedValue });

  render() {
    const { defaultValue, style, children } = this.props;
    return (
      <Select
        defaultValue={defaultValue}
        style={style}
        onChange={(value) => { this.selectedValue = value; }}
      >
        {children}
      </Select>
    );
  }
}

export default OperatorSelect;
