import { CogniteEvent, CursorResponse, EventFilterRequest } from '@cognite/sdk';
import moment from 'moment';

import BaseEvent from './BaseEvent';
import GenerationStatus from '../Asset/GenerationStatus';
import { postApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_SOLAR_EVENTS_LIST, EP_PATH_SOLAR_EGS_EVENTS_LIST } from '../AWS/EndpointPath';
import { getAllEvents } from '../dataAccess';

/** 日付フォーマット */
const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

/**
 * 発電ステータス
 */
class ElectricEnergyComparisonStatus extends BaseEvent {
  /**
   * メンバ変数
   */
  get siteName(): string {
    if (!this.metadata || !this.metadata.site_name_jp) return '';
    return this.metadata.site_name_jp;
  }

  get pcsNo(): string {
    if (!this.metadata || !this.metadata.pcs_no) return '';
    return this.metadata.pcs_no;
  }

  get status(): string {
    if (!this.metadata || !this.metadata.status) return '';
    return this.metadata.status;
  }

  get dateForDisplay(): string {
    if (!this.startTime || !this.startTime) return '';
    return moment(this.startTime).format(DATE_FORMAT);
  }

  /**
   * 検索条件に該当する発電ステータス履歴情報を取得する。
   * ※この関数ではnextCursorを用いた再帰処理を行わないため注意
   * @param {EventFilterRequest} eventFilter 検索条件
   * @returns {Promise<CursorResponse<ElectricEnergyComparisonStatus[]>>} 発電ステータス履歴情報
   */
  static async loadElectricEnergyByFilter(
    eventFilter: EventFilterRequest,
    period: string,
  ): Promise<CursorResponse<ElectricEnergyComparisonStatus[]>> {
    const generationStatus = await GenerationStatus.loadOneFromCDF();

    const customEventFilter: EventFilterRequest = {
      ...eventFilter,
      filter: {
        ...eventFilter.filter,
        ...(period === 'hourly' ? { assetIds: [generationStatus.id] } : {}), // 一時間判定の場合はassetIdsに保持アセットのIDを入れる必要がある。一日判定の場合は不要
      },
    };
    const endpointPath = period === 'hourly' ? EP_PATH_SOLAR_EVENTS_LIST : EP_PATH_SOLAR_EGS_EVENTS_LIST; // 一時間判定と一日判定とで使うエンドポイントは異なる
    const loadElectricEnergyStatuses = await postApiGateway<
      EventFilterRequest, CursorResponse<CogniteEvent[]>
    >(endpointPath, customEventFilter);

    const electricEnergyStatuses = loadElectricEnergyStatuses.items.map(
      (loadElectricEnergyStatus) => new ElectricEnergyComparisonStatus(loadElectricEnergyStatus),
    );

    return {
      ...loadElectricEnergyStatuses,
      items: electricEnergyStatuses,
    };
  }

  /**
   * 検索条件に該当するすべての発電ステータス履歴情報を取得する。
   * @param {EventFilterRequest} eventFilter 検索条件
   * @returns {Promise<ElectricEnergyComparisonStatus[]>} 発電ステータス履歴情報
   */
  static async loadAllElectricEnergyByFilter(
    eventFilter: EventFilterRequest,
    period: string,
  ): Promise<ElectricEnergyComparisonStatus[]> {
    const generationStatus = await GenerationStatus.loadOneFromCDF();

    const customEventFilter = {
      ...eventFilter.filter,
      ...(period === 'hourly' ? { assetIds: [generationStatus.id] } : {}), // 一時間判定の場合はassetIdsに保持アセットのIDを入れる必要がある。一日判定の場合は不要
    };

    const endpointPath = period === 'hourly' ? EP_PATH_SOLAR_EVENTS_LIST : EP_PATH_SOLAR_EGS_EVENTS_LIST; // 一時間判定と一日判定とで使うエンドポイントは異なる
    const loadElectricEnergyStatuses = await getAllEvents(endpointPath, customEventFilter);
    return loadElectricEnergyStatuses.map((event) => new ElectricEnergyComparisonStatus(event));
  }
}

export default ElectricEnergyComparisonStatus;
