import React, { forwardRef, useImperativeHandle } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import {
  Alert,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Select,
  Tooltip,
  message,
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import moment from 'moment';

import { CogniteClient } from '@cognite/sdk';

import RootAsset from '../../utils/Asset/RootAsset';
import ManagedFacility from '../../utils/Asset/ManagedFacility';
import InspectionResult from '../../utils/Event/InspectionResult';
import Model3DView from '../Common/Model3D/Model3DView';
import {
  ERROR_LOAD_MANAGEMENT_FACILITIES,
  SUCCESS_SAVE_INSPECTION_RESULT,
  ERROR_SAVE_INSPECTION_RESULT,
  VALIDATE_ERROR_NAME_REQUIRE,
  VALIDATE_ERROR_START_DATE_REQUIRE,
  VALIDATE_ERROR_END_DATE_REQUIRE,
  VALIDATE_ERROR_INSPECTOR_NAME_REQUIRE,
  VALIDATE_ERROR_FACILITY_REQUIRE,
  ERROR_NO_AUTH_MESSAGE,
  ERROR_NO_ADD_SCANS_AUTH_MESSAGE,
} from '../../utils/messages';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';

import './InspectionResultEdit.css';

const { Option } = Select;

interface OptionItem {
  label: string,
  value: number,
}

const FormItem = {
  Name: { name: 'name', label: '名前' },
  StartTime: { name: 'startTime', label: '開始年月日' },
  EndTime: { name: 'endTime', label: '終了年月日' },
  InspectorName: { name: 'inspectorName', label: '点検者名' },
  TargetManagedFacilityId: { name: 'targetManagedFacilityId', label: '対象管理設備' },
  Message: { name: 'message', label: '申し送り事項' },
} as const;

interface InspectionResultEditProps extends RouteComponentProps, FormComponentProps {
  target?: InspectionResult,
  client: CogniteClient,
  rootAsset: RootAsset,
}

type Ref = FormComponentProps;

/**
 * 点検結果登録・更新画面コンポーネント
 */
// eslint-disable-next-line max-len
const InspectionResultEdit: React.FC<InspectionResultEditProps> = forwardRef<Ref, InspectionResultEditProps>(
  ({
    form,
    target,
    history,
    client,
    rootAsset,
  }: InspectionResultEditProps, ref) => {
    useImperativeHandle(ref, () => ({ form }));

    const {
      validateFields,
      getFieldValue,
    } = form;

    /*
     * 変数/定数定義
     */
    const [saving, setSaving] = React.useState<boolean>(false);
    const [targetManagedFacility, setTargetManagedFacility] = React.useState<ManagedFacility>();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [options, setOptions] = React.useState<OptionItem[]>([]);
    const [facilityDisabled, setFacilityDisabled] = React.useState<boolean>(false);
    const [startDate, setStartDate] = React.useState<moment.Moment | null>();
    const [endDate, setEndDate] = React.useState<moment.Moment | null>();
    const [isCancelButtonDisabled, setIsCancelButtonDisabled] = React.useState<boolean>(true);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = React.useState<boolean>(true);
    const [isShow3dDisabled, setIsShow3dDisabled] = React.useState<boolean>(true);
    const [isReadScanDisabled, setIsReadScanDisabled] = React.useState<boolean>(true);
    const [isUpdateScanDisabled, setIsUpdateScanDisabled] = React.useState<boolean>(true);
    const [
      isUpdateInspectionDetailDisabled, setIsUpdateInspectionDetailDisabled,
    ] = React.useState<boolean>(true);

    type Model3DViewHandle = React.ElementRef<typeof Model3DView>;
    const model3DViewRef = React.useRef<Model3DViewHandle>(null);

    /*
     * イベントハンドラ
     */
    React.useEffect(
      () => {
        /* 一覧から点検結果を選択した場合の処理 */
        if (target) {
          form.setFieldsValue({
            name: target.name,
            startTime: moment(target.startTime),
            endTime: moment(target.endTime),
            inspectorName: target.inspectorName,
            targetManagedFacilityId: target.targetManagedFacilityId,
            message: target.message,
          });

          let canceled = false;
          (async () => {
            if (!target.targetManagedFacilityId) return;

            let managedFacility = null;

            try {
              managedFacility = await ManagedFacility.loadOneByIdFromCDF(
                target.targetManagedFacilityId,
              );
            } catch (exception) {
              message.error(ERROR_LOAD_MANAGEMENT_FACILITIES);
            }

            if (!canceled) {
              if (managedFacility) {
                setTargetManagedFacility(managedFacility);
              }
            }
            setFacilityDisabled(true);
            setStartDate(moment(target.startTime));
            setEndDate(moment(target.endTime));
          })();

          return () => { canceled = true; };
        }

        form.resetFields();

        return () => { /* 何もしない */ };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    React.useEffect(() => {
      let isCancelButtonAuthType;
      let isSaveButtonAuthType;
      let isShow3dAuthType;
      let isReadScanAuthType;
      let isUpdateScanAuthType;
      let isRegistrationItemsAuthType;
      const { INSPECTION_RESULTS_LIST } = AUTHENTICATION_TYPE_MATRIX;
      if (target) {
        // 参照・変更
        const {
          INSPECTION_RESULTS_DETAILS_RC_CANCEL,
          INSPECTION_RESULTS_DETAILS_RC_SAVE,
          INSPECTION_RESULTS_DETAILS_RC_THREE_D_VIEW_REFERENCE,
          INSPECTION_RESULTS_DETAILS_RC_REFER_TO_INSPECTION_DETAILS,
          INSPECTION_RESULTS_DETAILS_RC_INSPECTION_CONTENT_REGISTRATION,
          INSPECTION_RESULTS_DETAILS_RC_REGISTRATION_ITEMS,
        } = INSPECTION_RESULTS_LIST;
        isCancelButtonAuthType = INSPECTION_RESULTS_DETAILS_RC_CANCEL;
        isSaveButtonAuthType = INSPECTION_RESULTS_DETAILS_RC_SAVE;
        isShow3dAuthType = INSPECTION_RESULTS_DETAILS_RC_THREE_D_VIEW_REFERENCE;
        isReadScanAuthType = INSPECTION_RESULTS_DETAILS_RC_REFER_TO_INSPECTION_DETAILS;
        isUpdateScanAuthType = INSPECTION_RESULTS_DETAILS_RC_INSPECTION_CONTENT_REGISTRATION;
        isRegistrationItemsAuthType = INSPECTION_RESULTS_DETAILS_RC_REGISTRATION_ITEMS;
      } else {
        // 新規追加
        const {
          INSPECTION_RESULTS_DETAILS_NA_CANCEL,
          INSPECTION_RESULTS_DETAILS_NA_SAVE,
          INSPECTION_RESULTS_DETAILS_NA_THREE_D_VIEW_REFERENCE,
          INSPECTION_RESULTS_DETAILS_NA_REFER_TO_INSPECTION_DETAILS,
          INSPECTION_RESULTS_DETAILS_NA_INSPECTION_CONTENT_REGISTRATION,
          INSPECTION_RESULTS_DETAILS_NA_REGISTRATION_ITEMS,
        } = INSPECTION_RESULTS_LIST;
        isCancelButtonAuthType = INSPECTION_RESULTS_DETAILS_NA_CANCEL;
        isSaveButtonAuthType = INSPECTION_RESULTS_DETAILS_NA_SAVE;
        isShow3dAuthType = INSPECTION_RESULTS_DETAILS_NA_THREE_D_VIEW_REFERENCE;
        isReadScanAuthType = INSPECTION_RESULTS_DETAILS_NA_REFER_TO_INSPECTION_DETAILS;
        isUpdateScanAuthType = INSPECTION_RESULTS_DETAILS_NA_INSPECTION_CONTENT_REGISTRATION;
        isRegistrationItemsAuthType = INSPECTION_RESULTS_DETAILS_NA_REGISTRATION_ITEMS;
      }

      (async () => {
        setIsCancelButtonDisabled(!await containsUIAuthType(isCancelButtonAuthType));
        setIsSaveButtonDisabled(!await containsUIAuthType(isSaveButtonAuthType));
        setIsShow3dDisabled(!await containsUIAuthType(isShow3dAuthType));
        setIsReadScanDisabled(!await containsUIAuthType(isReadScanAuthType));
        setIsUpdateScanDisabled(!await containsUIAuthType(isUpdateScanAuthType));
        setIsUpdateInspectionDetailDisabled(!await containsUIAuthType(isRegistrationItemsAuthType));
      })();
    }, [target]);

    React.useEffect(
      () => {
        if (!loading) return () => { /* 何もしない */ };

        if (!rootAsset) return () => { /* 何もしない */ };

        let canceled = false;
        (async () => {
          let loadOptions: OptionItem[] = [];
          try {
            const assets = await rootAsset.loadChildrenFromCDF();

            loadOptions = assets.map((asset) => ({
              label: asset.name,
              value: asset.id,
            }));
          } catch (exception) {
            message.error(ERROR_LOAD_MANAGEMENT_FACILITIES);
          }

          if (canceled) return;

          setOptions(loadOptions);
          setLoading(false);
        })();

        return () => { canceled = true; };
      },
      [rootAsset, loading],
    );

    /**
     * 対象設備情報を読み込む
     * @param facilityId 対象設備ID
     */
    const onSelectFacility = async (facilityId: number) => {
      let managedFacility;
      try {
        managedFacility = await ManagedFacility.loadOneByIdFromCDF(
          facilityId,
        );

        if (managedFacility) {
          setTargetManagedFacility(managedFacility);
        }
      } catch (exception) {
        // TODO: 定数化
        message.error('対象設備の読み込みに失敗しました。');
      }
    };

    /** 保存ボタン押下処理 */
    React.useEffect(
      () => {
        if (!saving) return () => { /* 何もしない */ };

        let canceled = false;
        (async () => {
          try {
            validateFields();

            const name: string = getFieldValue(FormItem.Name.name);
            const startTime: Date = getFieldValue(FormItem.StartTime.name).valueOf();
            const endTime: Date = getFieldValue(FormItem.EndTime.name).valueOf();
            const inspectorName: string = getFieldValue(FormItem.InspectorName.name);
            const targetManagedFacilityId: string = getFieldValue(
              FormItem.TargetManagedFacilityId.name,
            );
            const messageValue: string = getFieldValue(FormItem.Message.name);

            const inspectionResult = target
              ? new InspectionResult(target)
              : new InspectionResult();

            inspectionResult.name = name;
            inspectionResult.startTime = startTime;
            inspectionResult.endTime = endTime;
            inspectionResult.inspectorName = inspectorName;
            inspectionResult.targetManagedFacilityId = Number(targetManagedFacilityId);
            if (model3DViewRef && model3DViewRef.current) {
              inspectionResult.scans = model3DViewRef.current.getScans();
            }
            inspectionResult.dataSetId = rootAsset?.dataSetId;
            inspectionResult.message = messageValue;

            await inspectionResult.saveSelfToCDF();

            message.success(SUCCESS_SAVE_INSPECTION_RESULT);

            history.goBack();
          } catch (exception) {
            message.error(ERROR_SAVE_INSPECTION_RESULT);
          }

          if (!canceled) {
            setSaving(false);
          }
        })();

        return () => { canceled = true; };
      },
      [getFieldValue, history, rootAsset.dataSetId, saving, target, validateFields],
    );

    /*
     * メソッド
     */
    /**
     * 開始年月日変更イベント
     * @param {moment.Moment | null}value 変更後の日付
     */
    const onChangeStart = (value: moment.Moment | null) => setStartDate(value);

    /**
     * 終了年月日変更イベント
     * @param {moment.Moment | null}value 変更後の日付
     */
    const onChangeEnd = (value: moment.Moment | null) => setEndDate(value);

    /**
     * 選択した開始日と終了日を比較し、入力不可とする日付を判定する。
     * @param startValue 選択した開始日
     * @returns 判定結果(開始日/終了日が選択されている状態、かつ開始日が終了日より大きい場合true)
     */
    const disabledStartDate = (startValue: moment.Moment | null) => {
      if (!startValue || !endDate) {
        return false;
      }
      return startValue.valueOf() > endDate.valueOf();
    };

    /**
     * 開始日と選択した終了日を比較し、入力不可とする日付を判定する。
     * @param startValue 選択した終了日
     * @returns 判定結果(開始日/終了日が選択されている状態、かつ開始日が終了日より大きい場合true)
     */
    const disabledEndDate = (endValue: moment.Moment | null) => {
      if (!endValue || !startDate) {
        return false;
      }
      return endValue.valueOf() < startDate.valueOf();
    };

    /*
     * 画面描画
     */
    return (
      <Row className="inspection-result-list-container">
        <Col span={18}>
          <Row gutter={16}>
            <Col span={8}>
              <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                <Tooltip title={isUpdateInspectionDetailDisabled && ERROR_NO_AUTH_MESSAGE}>
                  <Form.Item label={FormItem.Name.label}>
                    {form.getFieldDecorator(FormItem.Name.name, {
                      rules: [
                        { required: true, message: VALIDATE_ERROR_NAME_REQUIRE },
                      ],
                    })(<Input disabled={isUpdateInspectionDetailDisabled} />)}
                  </Form.Item>
                </Tooltip>

                <Tooltip title={isUpdateInspectionDetailDisabled && ERROR_NO_AUTH_MESSAGE}>
                  <Form.Item
                    label={FormItem.StartTime.label}
                  >
                    {form.getFieldDecorator(FormItem.StartTime.name, {
                      rules: [
                        { required: true, message: VALIDATE_ERROR_START_DATE_REQUIRE },
                      ],
                    })(
                      <DatePicker
                        disabledDate={disabledStartDate}
                        onChange={onChangeStart}
                        format="YYYY年MM月DD日"
                        disabled={isUpdateInspectionDetailDisabled}
                      />,
                    )}
                  </Form.Item>
                </Tooltip>

                <Tooltip title={isUpdateInspectionDetailDisabled && ERROR_NO_AUTH_MESSAGE}>
                  <Form.Item
                    label={FormItem.EndTime.label}
                  >
                    {form.getFieldDecorator(FormItem.EndTime.name, {
                      rules: [
                        { required: true, message: VALIDATE_ERROR_END_DATE_REQUIRE },
                      ],
                    })(
                      <DatePicker
                        disabledDate={disabledEndDate}
                        onChange={onChangeEnd}
                        format="YYYY年MM月DD日"
                        disabled={isUpdateInspectionDetailDisabled}
                      />,
                    )}
                  </Form.Item>
                </Tooltip>

                <Tooltip title={isUpdateInspectionDetailDisabled && ERROR_NO_AUTH_MESSAGE}>
                  <Form.Item
                    label={FormItem.InspectorName.label}
                  >
                    {form.getFieldDecorator(FormItem.InspectorName.name, {
                      rules: [
                        { required: true, message: VALIDATE_ERROR_INSPECTOR_NAME_REQUIRE },
                      ],
                    })(<Input disabled={isUpdateInspectionDetailDisabled} />)}
                  </Form.Item>
                </Tooltip>

                <Tooltip title={isUpdateInspectionDetailDisabled && ERROR_NO_AUTH_MESSAGE}>
                  <Form.Item
                    label={FormItem.TargetManagedFacilityId.label}
                  >
                    {form.getFieldDecorator(FormItem.TargetManagedFacilityId.name, {
                      rules: [
                        { required: true, message: VALIDATE_ERROR_FACILITY_REQUIRE },
                      ],
                    })(
                      <Select
                        loading={loading}
                        showSearch
                        style={{ width: '100%' }}
                        disabled={facilityDisabled || isUpdateInspectionDetailDisabled}
                        onSelect={(value: number) => onSelectFacility(value)}
                      >
                        {options.map((optionItem) => (
                          <Option key={optionItem.value} value={optionItem.value}>
                            {optionItem.label}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Tooltip>

                <Tooltip title={isUpdateInspectionDetailDisabled && ERROR_NO_AUTH_MESSAGE}>
                  <Form.Item
                    label={FormItem.Message.label}
                  >
                    {form.getFieldDecorator(FormItem.Message.name)(
                      <Input.TextArea disabled={isUpdateInspectionDetailDisabled} />,
                    )}
                  </Form.Item>
                </Tooltip>
              </Form>
            </Col>
            <Col span={16}>
              <Model3DView
                ref={model3DViewRef}
                managedFacility={targetManagedFacility}
                inspectionResult={target}
                inspectable
                height={700}
                client={client}
                isShow3dDisabled={isShow3dDisabled}
                isReadScanDisabled={isReadScanDisabled}
                isUpdateScanDisabled={isUpdateScanDisabled}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8} />
            <Col span={16}>
              <Col span={16}>
                {!loading && isUpdateInspectionDetailDisabled && (
                  <div className="inspection-result-warning-message">
                    <Alert message={ERROR_NO_ADD_SCANS_AUTH_MESSAGE} type="error" showIcon />
                  </div>
                )}
              </Col>
              <Col span={8}>
                <div className="inspection-result-edit-button-group">
                  <Tooltip title={isCancelButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                    <Button
                      onClick={() => history.goBack()}
                      disabled={saving || isCancelButtonDisabled}
                    >
                      キャンセル
                    </Button>
                  </Tooltip>

                  <Tooltip title={isSaveButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                    <Button
                      type="primary"
                      onClick={() => setSaving(true)}
                      loading={saving}
                      style={{ marginLeft: '10px' }}
                      disabled={isSaveButtonDisabled}
                    >
                      保存
                    </Button>
                  </Tooltip>
                </div>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  },
);

const EnhancedInspectionResultEdit = Form.create<InspectionResultEditProps>()(InspectionResultEdit);

export default withRouter(EnhancedInspectionResultEdit);
