import {
  Asset,
  CogniteInternalId,
  AssetListScope,
  CursorAndAsyncIterator,
} from '@cognite/sdk';

import BaseAsset from './BaseAsset';
import DetectionResult from './DetectionResult';
import TreeNode from '../common/TreeNode';
import { getApiGateway, postApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_ROOT_ASSET_AI_DETECT_RESULT, EP_PATH_FACILITY } from '../AWS/EndpointPath';
import { ResourceType } from '../common/SDFDataType';

import { implementSubtreeAssets } from '../common/CogniteApi';

class Facility extends BaseAsset {
  /*
   * クラスメソッド
   */

  /**
   * 設備情報取得
   * @param {CogniteInternalId} id
   * @returns 設備情報
   */
  static async loadOneByIdFromCDF(id: CogniteInternalId): Promise<Facility | null> {
    const getUrl = `${EP_PATH_FACILITY}/${id}`;
    const asset = await getApiGateway<Asset>(getUrl, { ignoreUnknownIds: true });

    if (!asset) return null;

    return new Facility(asset);
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      super(asset);
    } else {
      super();
    }
  }

  /*
   * メソッド
   */

  /**
   * クローンオブジェクト生成
   * @returns 設備情報
   */
  cloneSelf(): Facility {
    const clone = new Facility();
    Object.assign(clone, this);

    return clone;
  }

  // 未使用だが、BaseAsset継承で抽象メソッド定義があるため実体定義
  // eslint-disable-next-line class-methods-use-this
  async loadChildrenFromCDF(): Promise<Facility[]> {
    return [];
  }

  /**
   * 設備ツリー情報取得
   * @returns 設備サブツリー情報
   */
  async loadFacilityTreeFromCDF(): Promise<TreeNode<Facility>> {
    const assets = await implementSubtreeAssets(ResourceType.ManagedFacility, [{ id: this.id }]);

    const map: { [key: string]: TreeNode<Facility>[] } = {};
    for (let i = 0; i < assets.items.length; i++) {
      if (assets.items[i].parentId) {
        const key = String(assets.items[i].parentId);
        if (!map[key]) {
          map[key] = [];
        }
        map[key].push(new TreeNode(
          String(assets.items[i].id), new Facility(assets.items[i]),
        ));
      }
    }

    const node = new TreeNode<Facility>(String(this.id), this);
    this.addChildrenToNodeFromMap(node, map);

    return node;
  }

  /**
   * 設備検出結果情報取得
   * @returns 設備に紐づく検出結果情報
   */
  async loadDetectionResultsFromCDF(): Promise<DetectionResult[]> {
    const scope: AssetListScope = {
      filter: { metadata: { assetType: 'detection', baseAsset: `${this.id}` } },
      limit: Infinity,
    };

    const assets = await postApiGateway<AssetListScope, CursorAndAsyncIterator<Asset>>(
      EP_PATH_ROOT_ASSET_AI_DETECT_RESULT, scope,
    );
    return assets.items.map((asset) => {
      const detectionResult = new DetectionResult();
      Object.assign(detectionResult, asset);
      return detectionResult;
    });
  }

  /**
   * 設備サブツリー情報生成
   * @param {TreeNode<Facility>} node
   * @param {{ [key: string]: TreeNode<Facility>[] }} map
   */
  private addChildrenToNodeFromMap(
    node: TreeNode<Facility>,
    map: { [key: string]: TreeNode<Facility>[] },
  ): void {
    const children = map[node.key];
    if (children) {
      node.addChildren(children);
      // eslint-disable-next-line no-param-reassign
      delete map[node.key];
      for (let i = 0; i < node.children.length; i++) {
        this.addChildrenToNodeFromMap(node.children[i], map);
      }
    }
  }
}

export default Facility;
