import {
  CogniteInternalId,
  Timeseries as CogniteTimeseries,
  DoubleDatapoint,
  TimeseriesUnit,
} from '@cognite/sdk';

import { TimeInterval } from './TimeInterval';
import {
  EP_PATH_SOLAR_OM_TIMESERIES_LIST,
  EP_PATH_SOLAR_OM_TIMESERIES_DATAPOINTS_LIST,
  EP_PATH_SOLAR_OM_TIMESERIES_DATAPOINTS_LATEST,
} from '../../AWS/EndpointPath';
import { getAllTimeSeries, getAllDataPoints } from '../../dataAccess';

export const isValidTimeInterval = (range: unknown): range is TimeInterval => (
  typeof range === 'string'
  && ['instant', 'hourly', 'daily', 'monthly'].includes(range)
);

export const MeasurementType = {
  AcCurrent: 'ac_current',
  AcPower: 'ac_power',
  DcCurrent: 'dc_current',
  DcVoltage: 'dc_voltage',
  Other: 'other',
} as const;
export type MeasurementType = typeof MeasurementType[keyof typeof MeasurementType];
export const isMeasurementType = (measurementType: unknown): measurementType is MeasurementType => (
  typeof measurementType === 'string'
  && ['ac_current', 'ac_power', 'dc_current', 'dc_voltage', 'other'].includes(measurementType)
);

export type TransformedDatapoint = Record<string, number | Date>;

export const isTransformedDatapoint = (arg: unknown): arg is TransformedDatapoint => (
  typeof arg === 'object'
  && arg !== null
  && Object.values(arg as TransformedDatapoint).every((value) => typeof value === 'number' || value instanceof Date)
);

/**
 * 計測ポイントデータ（O&M用）
 */
class OMMeasurement {
  /*
   * クラスメソッド
   */

  /**
   * 対象アセットに紐付く計測値を保持するタイムシリーズを一覧で取得する。
   * @param assetIds {CogniteInternalId[]} 取得対象の親アセットID一覧
   * @param range {TimeInterval} データポイントの期間種別
   * @returns {Promise<CogniteTimeseries[]>} 取得対象計測値タイムシリーズ一覧
   */
  private static async getTargetTimeseries(
    assetIds: CogniteInternalId[],
    range: TimeInterval,
  ): Promise<CogniteTimeseries[]> {
    if (!assetIds.length) return [];

    // 100件ごとで分割する
    const subtreeListSize = 100;
    const subtreeLists = [];
    for (let i = 0; i < assetIds.length; i += subtreeListSize) {
      subtreeLists.push(assetIds.slice(i, i + subtreeListSize));
    }

    // 対象のタイムシリーズを取得する
    return Promise.all(subtreeLists.map(async (subtreeList) => {
      const query = {
        assetSubtreeIds: subtreeList.map((assetId) => ({ id: assetId })),
        metadata: {
          range,
        },
      };
      return getAllTimeSeries(EP_PATH_SOLAR_OM_TIMESERIES_LIST, query) as Promise<CogniteTimeseries[]>;
    }))
      .then((allResults) => allResults.flat());
  }

  /**
   * 計測ポイントデータをソートキーに基づいてソートする。
   * ソートキーが同一の場合は画面表示用の名称でソートする。
   * @param a {OMMeasurement} 計測ポイントデータA
   * @param b {OMMeasurement} 計測ポイントデータB
   * @returns {number} ソート結果（数値）
   */
  static sortMeasurementsBySortKey(a: OMMeasurement, b: OMMeasurement): number {
    if (!a.sortKey) return -1;
    if (!b.sortKey) return 1;

    if (a.sortKey === b.sortKey) return a.displayName.localeCompare(b.displayName);

    return a.sortKey - b.sortKey;
  }

  /**
   * 渡された一覧のIDを持つ保持アセットをルートとする計測ポイントデータを読み込む
   * @param {CogniteInternalId[]} assetIds 取得対象の親アセットID一覧
   * @param {Date} fromDate 開始日時
   * @param {Date} toDate 終了日時
   * @param {TimeInterval} range データポイントの期間種別
   * @returns {Promise<OMMeasurement[]>} 計測ポイントデータ一覧
   */
  static async loadAllSelectedMeasurements(
    assetIds: CogniteInternalId[],
    fromDate: Date,
    toDate: Date,
    range: TimeInterval,
  ): Promise<OMMeasurement[]> {
    const timeseriesList = await OMMeasurement.getTargetTimeseries(assetIds, range);
    const measurementList = await Promise.all(
      timeseriesList.map(async (timeseries) => {
        const query = {
          items: [{
            id: timeseries.id,
            start: fromDate.valueOf(),
            end: toDate.valueOf(),
          }],
          limit: 1000,
          ignoreUnknownIds: true,
        };
        const datapoints = await getAllDataPoints(
          EP_PATH_SOLAR_OM_TIMESERIES_DATAPOINTS_LIST,
          query,
        ) as DoubleDatapoint[];
        return new OMMeasurement(timeseries, datapoints);
      }),
    );
    return [...measurementList].sort(OMMeasurement.sortMeasurementsBySortKey);
  }

  /**
   * 渡された一覧のIDを持つ保持アセットをルートとする計測ポイントデータ（最新値）を読み込む
   * @param {CogniteInternalId[]} assetIds 取得対象の親アセットID一覧
   * @returns {Promise<OMMeasurement[]>} 計測ポイントデータ一覧
   */
  static async loadAllSelectedLatestMeasurements(
    assetIds: CogniteInternalId[],
  ): Promise<OMMeasurement[]> {
    const timeseriesList = await OMMeasurement.getTargetTimeseries(assetIds, 'instant');
    const measurementList = await Promise.all(
      timeseriesList.map(async (timeseries) => {
        const query = {
          items: [{
            id: timeseries.id,
            before: 'now',
          }],
          ignoreUnknownIds: true,
        };
        const datapoints = await getAllDataPoints(
          EP_PATH_SOLAR_OM_TIMESERIES_DATAPOINTS_LATEST,
          query,
        ) as DoubleDatapoint[];
        return new OMMeasurement(timeseries, datapoints);
      }),
    );
    return [...measurementList].sort(OMMeasurement.sortMeasurementsBySortKey);
  }

  /*
   * メンバ変数
   */
  timeseries: CogniteTimeseries;

  datapoints: DoubleDatapoint[];

  transformedDatapoints: TransformedDatapoint[];

  get name(): string {
    return this.timeseries?.name ?? '';
  }

  get unit(): TimeseriesUnit {
    return this.timeseries?.unit ?? '';
  }

  get displayName(): string {
    const name = this.timeseries?.name ?? '-';
    const unit = this.timeseries?.unit ?? '-';
    return `${name}[${unit}]`;
  }

  get range(): TimeInterval {
    const range = this.timeseries?.metadata?.range;
    if (!range || !isValidTimeInterval(range)) return 'none';
    return range as TimeInterval;
  }

  get sortKey(): number | undefined {
    const sortKey = this.timeseries?.metadata?.sort_key;
    return sortKey && Number.isSafeInteger(Number(sortKey)) ? Number(sortKey) : undefined;
  }

  get floorName(): string {
    return this.timeseries?.metadata?.floor_name ?? '';
  }

  get pcsName(): string {
    return this.timeseries?.metadata?.pcs_name ?? '';
  }

  get measurementType(): MeasurementType {
    const measurementType = this.timeseries?.metadata?.measurement_type;
    if (!measurementType || !isMeasurementType(measurementType)) return 'other';
    return measurementType as MeasurementType;
  }

  /*
   * コンストラクタ
   */
  constructor(timeseries: CogniteTimeseries, datapoints: DoubleDatapoint[]) {
    this.timeseries = timeseries;
    this.datapoints = datapoints;
    this.transformedDatapoints = this.setTransformedDatapoints();
  }

  /*
   * メソッド
   */

  /**
   * 対象計測値のデータポイントをテーブル表示・CSV出力用に形式を変換する。
   * @returns {TransformedDatapoint[]} テーブル表示・CSV出力用に形式を変換したデータポイント一覧
   */
  private setTransformedDatapoints(): TransformedDatapoint[] {
    const transformedDatapoints = this.datapoints.map(({ timestamp, value }) => ({ timestamp, [this.displayName]: value }));
    return transformedDatapoints;
  }
}

export default OMMeasurement;
