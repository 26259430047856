import {
  Asset, AssetIdEither, AssetListScope, InternalId, ItemsWrapper,
} from '@cognite/sdk';

import { postApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_MANAGED_FACILITY_LIST_BY_IDS, EP_PATH_MANAGED_FACILITY_LIST,
} from '../AWS/EndpointPath';
import { getAllAssets } from '../dataAccess';

// TODO: ManagedFacility.tsのクラスメソッドとして定義するとコンパイルエラーが発生するため暫定対応

/**
 * IDに紐づく管理設備一覧の取得
 * @param ids IDリスト
 * @returns 管理設備一覧
 */
const loadAllManagedFacilityFromCDFByIds = async (
  ids: InternalId[],
): Promise<ItemsWrapper<Asset[]>> => {
  const assets = await postApiGateway<
    ItemsWrapper<AssetIdEither[]>,
    ItemsWrapper<Asset[]>
  >(EP_PATH_MANAGED_FACILITY_LIST_BY_IDS, { items: ids });

  return assets;
};

/**
 * 検索条件に一致する管理設備一覧の取得
 * @param scope 検索条件
 * @returns 管理設備一覧
 */
const loadAllManagedFacilityFromCDFByScope = async (scope: AssetListScope): Promise<Asset[]> => {
  const assets = await getAllAssets(EP_PATH_MANAGED_FACILITY_LIST, scope.filter);
  return assets;
};

export {
  loadAllManagedFacilityFromCDFByIds, loadAllManagedFacilityFromCDFByScope,
};
