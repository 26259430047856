import {
  FileInfo,
  FileAggregateQuery,
  ItemsResponse,
  FileAggregate,
} from '@cognite/sdk';
import { JsonFile } from './BaseFile';

import { getApiGateway, postApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_LEARNING_MODEL_MODEL_AGGREGATE,
  EP_PATH_LEARNING_MODEL_AI_MODEL,
} from '../AWS/EndpointPath';

class LearningModel extends JsonFile {
  /*
   * クラスメソッド
   */

  /**
   * 学習モデルファイル数カウント
   * @returns ファイル数
   */
  static async countModelFiles(): Promise<number> {
    const filter: FileAggregateQuery = {
      filter: { metadata: { type: 'model' } },
    };

    const modelAggregates = (
      await postApiGateway<FileAggregateQuery, ItemsResponse<FileAggregate>>(
        EP_PATH_LEARNING_MODEL_MODEL_AGGREGATE, filter,
      )
    );

    return modelAggregates.items[0].count;
  }

  /**
   * IDに紐づく学習モデルを取得する(ID指定)
   * @param id 学習モデルID
   * @returns 学習モデル
   */
  static async retrieveLearningModel(id: number): Promise<FileInfo | undefined> {
    const endpoint = `${EP_PATH_LEARNING_MODEL_AI_MODEL}/${id}`;
    const learningModel = await getApiGateway<FileInfo>(endpoint, { ignoreUnknownIds: true });

    return learningModel;
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(fileInfo?: FileInfo) {
    if (fileInfo) {
      super(fileInfo);
    } else {
      super();
    }
  }

  /*
   * メンバメソッド
   */
}

export default LearningModel;
