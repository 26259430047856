import React from 'react';
import { Card, Col, Row } from 'antd';
import './ClassCard.css';

export interface Variables {
  index: string,
  model: string,
  className: string,
  confidence: number,
}

interface ParameterHead {
  key: string,
  value: string,
}

const parameterHeads: ParameterHead[] = [
  { key: 'index', value: ' ' },
  { key: 'model', value: '学習モデル' },
  { key: 'class', value: 'クラス名' },
  { key: 'confidence', value: '確信度' },
];

/**
 * ClassCard component
 * @property {object} client CogniteClient
 */
const ClassCard: React.FC<{
  ensembleClassNo: number,
  ensembleClass: string,
  formula: string,
  variables: Variables[]
}> = (props) => {
  const {
    ensembleClassNo, ensembleClass, formula, variables,
  } = props;

  /*
   * 変数/定数定義
   */
  const descriptionsItem: JSX.Element[] = [];

  variables.forEach((param, index) => {
    const keyNo = index + 1;
    descriptionsItem.push(
      <tr key={`tr${keyNo}`}>
        <td key={`index${keyNo}`} className="index">{param.index}</td>
        <td key={`model${keyNo}`} className="model">{param.model}</td>
        <td key={`class${keyNo}`} className="class">{param.className}</td>
        <td key={`confidence${keyNo}`} className="confidence">{param.confidence}</td>
      </tr>,
    );
  });

  const ensembleClassName = `クラス${ensembleClassNo}：${ensembleClass}`;
  const ensembleFormula = `クラス式：${formula}`;

  return (
    <Card bodyStyle={{ textAlign: 'left', padding: '10px 10px 10px 10px', overflow: 'auto' }}>
      <Row>
        <div>{ensembleClassName}</div>
      </Row>
      <Row>
        <Col span={22} offset={2}>
          <div>{ensembleFormula}</div>
          <table className="class-card-table">
            <thead>
              <tr>
                {parameterHeads.map((head: ParameterHead) => (
                  <th key={head.key} className={head.key}>{head.value}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {descriptionsItem}
            </tbody>
          </table>
        </Col>
      </Row>
    </Card>
  );
};

export default ClassCard;
