import React, { useCallback, useEffect, useState } from 'react';
import {
  CogniteClient, FileInfo, Metadata, FileChangeUpdate, FileFilterProps, ItemsResponse,
} from '@cognite/sdk';
import {
  Button, Col, Empty, message, Modal, Row, Tooltip,
} from 'antd';

import {
  ERROR_NO_AUTH_MESSAGE,
  ERROR_FILES_RETRIEVE,
  ERROR_FILES_UPDATE,
  SUCCESS_FILES_UPDATE,
} from '../../../utils/messages';
import { UnknownFile, MimeType, DETECTION_RESULT } from '../../../utils/File/BaseFile';
import { getAllFiles } from '../../../utils/dataAccess';
import { sortName, sortDateTime } from '../../../utils/sort';
import { generateFileMetaData } from '../Metadata/MetadataInfo';
import MetadataTable from '../Metadata/MetadataTable';
import { fileReservedKeyList } from '../Metadata/SystemReservedKey';
import { getFileEndpoint, getFileListEndpoint, ResourceType } from '../../../utils/common/SDFDataType';

import { DownloadButton } from './parts/DownloadButton';
import { ImageGallery } from './parts/ImageGallery';
import { getApiGateway, putApiGateway } from '../../../utils/AWS/ApiGateway';
import { EmptyImageGallery } from './parts/EmptyImageGallery';

const MODAL_WIDTH = 1648;
const MODAL_HEIGHT = 948;
const MODAL_HEIGHT_WITH_DL = 992;

export const ImageGalleryView: React.FC<{
  visible: boolean,
  client: CogniteClient;
  assetId: number | null,
  resourceType: ResourceType | null,
  fileId: number | null,
  isAiDetect?: boolean,
  isModalEditButtonDisabled: boolean,
  isModalDownloadButtonDisabled: boolean,
  displayTarget: number,
  onClose: (fileId?: number) => void
}> = (props) => {
  /*
   * 変数/定数宣言
   */
  const [detailsList, setDetailsList] = useState<JSX.Element[] | null>(null);
  const [selectedFile, setSelectedFile] = useState<FileInfo | null>(null);
  const [downloadFileId, setDownloadFileId] = useState<number | null>(null);
  const [isEditingData, setIsEditingData] = useState<boolean>(false);
  const [filesList, setFilesList] = useState<FileInfo[]>([]);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(true);

  type MetadataTableHandle = React.ElementRef<typeof MetadataTable>;
  const metadataTableRef = React.useRef<MetadataTableHandle>(null);

  const {
    visible,
    client,
    assetId,
    resourceType,
    fileId,
    isAiDetect,
    isModalEditButtonDisabled,
    isModalDownloadButtonDisabled,
    displayTarget,
    onClose,
  } = props;

  /**
   * ソート済画像リスト取得
   * @param {number} targetAssetId 対象アセットID
   * @returns {Promise<FileInfo[] | undefined>} ソート済画像リスト
   */
  const getSortAllFiles = useCallback(async (
    targetAssetId: number,
  ): Promise<FileInfo[] | undefined> => {
    // 画像リスト作成
    let jpgFilter: FileFilterProps = {
      assetIds: [targetAssetId], mimeType: MimeType.Jpeg, uploaded: true,
    };
    let pngFilter: FileFilterProps = {
      assetIds: [targetAssetId], mimeType: MimeType.Png, uploaded: true,
    };
    if (displayTarget === DETECTION_RESULT) {
      // 検出結果ファイル
      jpgFilter = { ...jpgFilter, metadata: { detectFlag: 'true' } };
      pngFilter = { ...pngFilter, metadata: { detectFlag: 'true' } };
    }

    let allFiles;
    try {
      const endpoint = getFileListEndpoint(resourceType as ResourceType);
      const jpgFiles = await getAllFiles(endpoint, jpgFilter);
      // AI検出画面から拡大モーダルを開く場合はPNGファイルを表示しない
      if (isAiDetect) {
        allFiles = jpgFiles;
      } else {
        const pngFiles = await getAllFiles(endpoint, pngFilter);
        allFiles = jpgFiles.concat(pngFiles);
      }
    } catch (ex) {
      return undefined;
    }

    // 撮影日時があるリスト、撮影日時がないリストを作成
    const dateTimeSortList: FileInfo[] = [];
    const nameSortList: FileInfo[] = [];
    allFiles.forEach((value: FileInfo) => {
      if (value.metadata && value.metadata['撮影日時']) {
        dateTimeSortList.push(value);
      } else {
        nameSortList.push(value);
      }
    });
    // 撮影日時順のリストの後に、名前順のリストを追加
    sortDateTime(dateTimeSortList);
    sortName(nameSortList);

    const sortAllFiles = dateTimeSortList.concat(nameSortList);

    return sortAllFiles;
  }, [displayTarget, resourceType, isAiDetect]);

  /*
   * イベントハンドラ
   */
  useEffect(() => {
    if (!visible) return () => { /* 何もしない */ };

    let canceled = false;
    setDownloadFileId(null);
    setIsImageLoaded(true);

    (async () => {
      if (!fileId) return;
      if (!assetId) return;

      let file = null;
      try {
        const endpoint = getFileEndpoint(resourceType as ResourceType);
        const url = `${endpoint}/${fileId}`;
        file = await getApiGateway<FileInfo>(url);
      } catch (e) {
        message.error(ERROR_FILES_RETRIEVE);
        setIsImageLoaded(false);
        setSelectedFile(UnknownFile.getInstance());
        setFilesList([]);
        setDetailsList(null);
        return;
      }
      // ファイル情報にerrorを含む場合はファイル情報取得エラー
      if (!file || Object.keys(file).includes('error')) {
        message.error(ERROR_FILES_RETRIEVE);
        setIsImageLoaded(false);
        setSelectedFile(UnknownFile.getInstance());
        setFilesList([]);
        setDetailsList(null);
        return;
      }

      // 詳細情報
      const metaInfo = generateFileMetaData(file);

      // ファイル読み込み用
      const newFile = UnknownFile.getInstance();
      newFile.mimeType = file.mimeType;
      newFile.id = fileId;

      const sortAllFiles = await getSortAllFiles(assetId);

      if (!canceled) {
        setDownloadFileId(fileId);
        setDetailsList(metaInfo.descriptionsItem);
        setSelectedFile(file);
        if (sortAllFiles) {
          setFilesList(sortAllFiles);
        }
      }
    })();

    return () => { canceled = true; };
  }, [fileId, visible, assetId, resourceType, getSortAllFiles]);

  /**
   * 保存ボタンクリック時のイベントハンドラ
   * @param {Metadata} metadata 更新するファイルの詳細情報
   */
  const handleClickSave = async (metadata: Metadata) => {
    if (!selectedFile) { return; }

    if (selectedFile.metadata) {
      // 詳細情報に変更があるか判定し、ない場合はupdate処理を行わない。

      // オブジェクトをソート済み配列に変換する
      const objToSortedArray = (obj: Metadata) => Object.entries(obj).sort();
      // ソート済み配列を文字列に変換して比較する
      const isEqualSortArray = (obj1: Metadata, obj2: Metadata) => (
        JSON.stringify(objToSortedArray(obj1)) === JSON.stringify(objToSortedArray(obj2))
      );

      if (isEqualSortArray(selectedFile.metadata, metadata)) {
        setIsEditingData(false);
        return;
      }
    }

    try {
      const endpoint = getFileEndpoint(resourceType as ResourceType);
      const files = await putApiGateway(
        endpoint,
        {
          items: [{
            id: selectedFile.id as number,
            update: {
              metadata: {
                set: metadata,
              },
            },
          } as FileChangeUpdate],
        },
      ) as ItemsResponse<FileInfo>;
      message.success(SUCCESS_FILES_UPDATE);

      if (!filesList) { return; }
      const updateFileIndex = filesList.findIndex((file) => file.id === files.items[0].id);
      filesList.splice(updateFileIndex, 1, files.items[0]);

      const metaInfo = generateFileMetaData(files.items[0]);
      setDetailsList(metaInfo.descriptionsItem);
      setSelectedFile(files.items[0]);
      setIsEditingData(false);
    } catch (e) {
      message.error(ERROR_FILES_UPDATE);
    }
  };

  /**
   * ImageGalleryで画像選択時のイベントハンドラ
   * @param {FileInfo} fileInfo ImageGalleryで選択したファイル情報
   */
  const handleChangeImageGallery = async (fileInfo: FileInfo) => {
    if (!filesList) { return; }
    const targetFile = filesList.find((file) => file.id === fileInfo.id);
    if (!targetFile) return;

    const metaInfo = generateFileMetaData(targetFile);
    setDetailsList(metaInfo.descriptionsItem);
    setSelectedFile(targetFile);
    setDownloadFileId(fileInfo.id);
  };

  /*
 * メソッド
 */

  /*
   * 画面描画
   */
  const modalHeight = isImageLoaded ? MODAL_HEIGHT_WITH_DL : MODAL_HEIGHT;
  return (
    <>
      {
        visible && (
          <Modal
            title={selectedFile?.name}
            visible={visible}
            onCancel={() => {
              onClose(selectedFile?.id);
              setIsEditingData(false);
              setFilesList([]);
            }}
            centered
            width={MODAL_WIDTH}
            footer={null}
            style={{ margin: '0', padding: '0', textAlign: 'center' }}
            bodyStyle={{ width: `${MODAL_WIDTH}px`, height: `${modalHeight}px` }}
          >
            <Col span={16}>
              {isImageLoaded
                ? (
                  <ImageGallery
                    client={client}
                    fileId={fileId === null ? undefined : fileId}
                    resourceType={resourceType}
                    onChangeImage={handleChangeImageGallery}
                    sortAllFiles={filesList}
                    enlargeStyle={{
                      height: '966px',
                      width: '1600px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      display: 'grid',
                      placeItems: 'center',
                    }}
                    zoomStyle={{ width: '900px', height: '900px' }}
                  />
                )
                : (
                  <EmptyImageGallery isCompare={false} />
                )}
              {
                isImageLoaded && (
                  <>
                    <DownloadButton
                      fileId={downloadFileId}
                      resourceType={resourceType}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                    />
                  </>
                )
              }
            </Col>
            <Col span={8}>
              <h1 style={{ margin: '10px', borderBottom: '1px solid darkgray', fontSize: '16px' }}>
                詳細情報
              </h1>
              {
                isEditingData ? (
                  <>
                    {
                      selectedFile && (
                        <MetadataTable
                          ref={metadataTableRef}
                          value={selectedFile.metadata}
                          editable
                          onSave={handleClickSave}
                          onCancel={() => { setIsEditingData(false); }}
                          height={815}
                          systemReservedKeyList={fileReservedKeyList}
                          tableStyle={{ width: '513px', marginLeft: '10px' }}
                          plusButtonStyle={{ float: 'left', marginLeft: '8px' }}
                        />
                      )
                    }
                  </>
                ) : (
                  <>
                    {
                      detailsList?.length ? (
                        <Row style={{ maxHeight: 855, overflow: 'auto' }}>
                          {detailsList}
                        </Row>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    <Tooltip title={isModalEditButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                      <Button
                        type="primary"
                        disabled={isModalEditButtonDisabled}
                        onClick={() => { setIsEditingData(true); }}
                        style={{ float: 'right', marginTop: '10px' }}
                      >
                        編集
                      </Button>
                    </Tooltip>
                  </>
                )
              }
            </Col>
          </Modal>
        )
      }
    </>
  );
};
