import React, { useState, useEffect } from 'react';
import { EventFilter, EventFilterRequest } from '@cognite/sdk';
import {
  Typography,
  Divider,
  Row,
  Col,
  Descriptions,
  Button,
  message,
  Tooltip,
} from 'antd';
import moment from 'moment';

import SearchedElectricEnergyComparisonEventTable from './SearchedElectricEnergyComparisonEventTable';
import ElectricEnergyOccurrenceDates from './parts/ElectricEnergyOccurrenceDates';
import ElectricEnergyStatusSelect, { ELECTRIC_ENERGY_STATUS } from './parts/ElectricEnergyStatusSelect';
import OMStatusSelect, { OM_STATUS } from './parts/OMStatusSelect';
import SiteCascader, { SiteCascaderSelect } from './parts/SiteCascader';
import SolarSite from '../../utils/Asset/SolarSite';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import ElectricEnergyComparisonStatus from '../../utils/Event/ElectricEnergyComparisonStatus';
import { generateCsvData } from '../../utils/File/CsvFile';
import {
  ERROR_NOT_ENTERED_SEARCH_CONDITION,
  ERROR_SEARCH_RESULT_OVER_LIMIT,
  ERROR_NO_AUTH_MESSAGE,
  ERROR_FILE_DOWNLOAD,
  INFORMATION_NO_SEARCH_RESULTS,
} from '../../utils/messages';
import './ElectricEnergyComparisonHistory.css';

const { Title } = Typography;

// propsの型を定義
interface ElectricEnergyComparisonHistoryProps {
  period: 'hourly' | 'daily';
}

interface PeriodChangeDetail {
  titleName: string;
  label: string;
  downloadFileName: string;
  filterType: string;
}

interface PeriodChange {
  hourly: PeriodChangeDetail;
  daily: PeriodChangeDetail;
}

/**
 * 1時間ごとおよび１日ごとに使用する変数のまとめ
 */
const periodChange: PeriodChange = {
  hourly: {
    titleName: '発電量診断(1時間判定)',
    label: 'ステータス',
    downloadFileName: 'SDF_発電量量診断結果(1時間)',
    filterType: 'electric_energy_status_history',
  },
  daily: {
    titleName: '発電量診断(1日判定)',
    label: '診断結果',
    downloadFileName: 'SDF_発電量量診断結果(1日)',
    filterType: 'electric_energy_status_daily_history',
  },
};

/**
 * 発電量診断結果一覧のタブ切り替え後のオブジェクト
 * * @param {ElectricEnergyComparisonHistoryProps} props プロパティ
 * @returns {React.FC<ElectricEnergyComparisonHistoryProps>} 発電量診断結果一オブジェクト
 */
const ElectricEnergyComparisonHistory: React.FC<ElectricEnergyComparisonHistoryProps> = (props: ElectricEnergyComparisonHistoryProps) => {
  const [occurrenceDates, setOccurrenceDates] = useState<[moment.Moment, moment.Moment] | []>([]);
  const [solarSite, setSolarSite] = useState<SolarSite | undefined>();
  const [status, setStatus] = useState<number | undefined>();
  const [
    electricEnergyComparisonStatuses, setElectricEnergyComparisonStatuses,
  ] = useState<ElectricEnergyComparisonStatus[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState<boolean>(true);
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        ELECTRIC_ENERGY_COMPARISON_HISTORY: { ELECTRIC_ENERGY_COMPARISON_HISTORY_SEARCH_BUTTON, ELECTRIC_ENERGY_COMPARISON_HISTORY_DOWNLOAD_BUTTON },
      } = AUTHENTICATION_TYPE_MATRIX;

      if (!canceled) {
        setIsSearchButtonDisabled(!await containsUIAuthType(ELECTRIC_ENERGY_COMPARISON_HISTORY_SEARCH_BUTTON));
        setIsDownloadButtonDisabled(!await containsUIAuthType(ELECTRIC_ENERGY_COMPARISON_HISTORY_DOWNLOAD_BUTTON));
      }
    })();

    return () => { canceled = true; };
  }, []);

  // 'hourly'か'daily'で変える変数を定義
  const {
    titleName,
    label,
    downloadFileName,
    filterType,
  } = periodChange[props.period];

  /**
   * ステータスの表示名変換
   * @param {string} statusNo ステータス番号
   * @param {string} period 'hourly'または'daily'
   * @returns {string} ステータス名
   */
  const statusChange = (
    statusNo: string,
    period: string,
  ): string => {
    const statusTypes = period === 'hourly' ? ELECTRIC_ENERGY_STATUS : OM_STATUS;
    const result = statusTypes.find((el) => String(el.value) === statusNo);
    return result ? result.displayName : '';
  };

  /**
   * 診断日時選択時のイベントハンドラ
   * @param {[] | [moment.Moment, moment.Moment]} dates 選択した診断日時
   */
  const handleChangeOccurrenceDates = (dates: [] | [moment.Moment, moment.Moment]): void => {
    setOccurrenceDates(dates);
  };

  /**
   * サイト選択時のイベントハンドラ
   * @param {SiteCascaderSelect | undefined} selectedValues 選択したサイト情報
   */
  const handleChangeSite = (selectedValues?: SiteCascaderSelect): void => {
    if (!selectedValues) {
      setSolarSite(selectedValues);
    } else {
      const value = new SolarSite(selectedValues.site);
      setSolarSite(value);
    }
  };

  /**
   * ステータス選択時のイベントハンドラ
   * @param {number | undefined} value 選択したステータス
   */
  const handleChangeStatus = (value?: number): void => {
    setStatus(value);
  };

  /**
   * 発電ステータス検索オブジェクト作成
   * @returns {EventFilterRequest} 発電ステータス検索オブジェクト
   */
  const createEventFilterRequest = (period: string): EventFilterRequest => {
    const filter: EventFilter = {};
    if (occurrenceDates.length !== 0) {
      const [startTime, endTime] = occurrenceDates;
      filter.startTime = {
        min: startTime?.valueOf(),
        max: endTime?.valueOf(),
      };
    }
    if (period === 'daily') { // 一日の場合、常にfilter.typeは'electric_energy_status_daily_history'
      filter.type = filterType;
    }

    if (solarSite) {
      if (period === 'hourly') { // 一時間の場合、`${solarSite?.externalId}_electric_energy_status_history`のイベントを保持アセットに入れて出力
        filter.type = `${solarSite.externalId}_${filterType}`;
      } else {
        filter.assetIds = [solarSite.id]; // 一日の場合、選択された太陽光サイトのアセットIDをfilterに追加
      }
    }

    if (status) {
      filter.metadata = { status: String(status) };
    }

    // CogniteSDKとAPIで差分があるためasを用いてキャスト
    const eventFilterRequest = {
      filter,
      sort: [
        // CDFの仕様上、第二ソートまでしか設定できない
        { property: ['startTime'], order: 'desc' },
        { property: ['metadata', 'site_name_jp'], order: 'asc' },
      ],
      limit: 1000,
    } as EventFilterRequest;

    return eventFilterRequest;
  };

  /**
   * 文字列項目のソート判定を実施する
   * @param {string | undefined} a 文字列項目A
   * @param {string | undefined} b 文字列項目B
   * @returns {number} ソートの判定結果
   */
  const sortStringItems = (a?: string, b?: string) => {
    const first = [a, b].sort()[0];
    return first === a ? -1 : 1;
  };

  /**
   * 発電ステータスをソートする。
   * 1. 診断日時: 降順
   * 2. サイト名: 昇順
   * 3. PCS番号: 昇順
   * 4. ステータス: 昇順
   * @param {ElectricEnergyComparisonStatus[]} sortTargetElectricEnergyComparisonStatuses ソート対象の発電ステータスリスト
   * @returns {ElectricEnergyComparisonStatus[]}ソート後の発電ステータスリスト
   */
  const sortElectricEnergyComparisonStatus = (
    sortTargetElectricEnergyComparisonStatuses: ElectricEnergyComparisonStatus[],
  ): ElectricEnergyComparisonStatus[] => {
    const sortedElectricEnergyComparisonStatuses = [...sortTargetElectricEnergyComparisonStatuses];
    sortedElectricEnergyComparisonStatuses.sort((a, b) => {
      const startDateA = a.dateForDisplay;
      const startDateB = b.dateForDisplay;

      if (!startDateA) {
        return 1;
      }
      if (!startDateB) {
        return -1;
      }
      if (startDateA !== startDateB) {
        return sortStringItems(startDateB, startDateA);
      }

      if (a.siteName !== b.siteName) {
        return a.siteName.localeCompare(b.siteName);
      }

      if (a.pcsNo !== b.pcsNo) {
        return Number(a.pcsNo) - Number(b.pcsNo);
      }

      return Number(a.status) - Number(b.status);
    });

    return sortedElectricEnergyComparisonStatuses;
  };

  /**
   * 検索ボタンクリック時のイベントハンドラ
   */
  const handleClickSearch = async (): Promise<void> => {
    setLoading(true);

    if (occurrenceDates.length === 0 && !solarSite && !status) {
      message.error(ERROR_NOT_ENTERED_SEARCH_CONDITION);
      setLoading(false);
      return;
    }

    const eventFilterRequest = createEventFilterRequest(props.period);
    const loadElectricEnergyComparisonStatuses = await ElectricEnergyComparisonStatus.loadElectricEnergyByFilter(
      eventFilterRequest, props.period,
    );

    // 検索結果がない場合はメッセージを表示
    if (loadElectricEnergyComparisonStatuses.items.length === 0) {
      message.info(INFORMATION_NO_SEARCH_RESULTS);
      setElectricEnergyComparisonStatuses(undefined);
      setLoading(false);
      return;
    }

    if (loadElectricEnergyComparisonStatuses.nextCursor) {
      message.error(ERROR_SEARCH_RESULT_OVER_LIMIT);
      setLoading(false);
      return;
    }
    const sortedElectricEnergyComparisonStatuses = sortElectricEnergyComparisonStatus(loadElectricEnergyComparisonStatuses.items);

    setElectricEnergyComparisonStatuses(sortedElectricEnergyComparisonStatuses);
    setLoading(false);
  };

  /**
   * ダウンロードボタンクリック時のイベントハンドラ
   */
  const handleClickDownload = async (): Promise<void> => {
    setDownloading(true);

    // 検索条件不備の場合はエラーメッセージを表示しダウンロードしない
    if (occurrenceDates.length === 0 && !solarSite && !status) {
      message.error(ERROR_NOT_ENTERED_SEARCH_CONDITION);
      setDownloading(false);
      return;
    }

    const eventFilterRequest = createEventFilterRequest(props.period);
    const loadElectricEnergyComparisonStatuses = await ElectricEnergyComparisonStatus.loadAllElectricEnergyByFilter(
      eventFilterRequest, props.period,
    );

    // 検索結果がない場合はメッセージを表示しダウンロードしない
    if (loadElectricEnergyComparisonStatuses.length === 0) {
      message.info(INFORMATION_NO_SEARCH_RESULTS);
      setDownloading(false);
      return;
    }

    const sortedElectricEnergyComparisonStatuses = sortElectricEnergyComparisonStatus(loadElectricEnergyComparisonStatuses);
    const electricEnergyComparisonStatusesBody = sortedElectricEnergyComparisonStatuses.map((item) => {
      const statusArr = {
        ...item,
        metadata: {
          ...item.metadata,
          status: item.metadata ? statusChange(item.metadata.status, props.period) : '',
        },
      };
      return new ElectricEnergyComparisonStatus(statusArr);
    });

    const header = [
      { key: 'dateForDisplay', headerString: '診断日時' },
      { key: 'siteName', headerString: 'サイト名' },
      { key: 'pcsNo', headerString: 'PCS番号' },
      { key: 'status', headerString: label },
    ];

    const csvData = generateCsvData(header, electricEnergyComparisonStatusesBody);
    const aTagForDownload = document.createElement('a');
    let url = '';
    try {
      url = URL.createObjectURL(csvData);
      document.body.appendChild(aTagForDownload);
      aTagForDownload.href = url;
      aTagForDownload.download = `${downloadFileName}_${moment().format('YYYYMMDDHHmmss')}.csv`;
      aTagForDownload.click();
    } catch (e) {
      message.error(ERROR_FILE_DOWNLOAD);
    } finally {
      aTagForDownload.remove();
      URL.revokeObjectURL(url);
    }
    setDownloading(false);
  };

  return (
    <div className="electric-energy-history-container">
      <div className="electric-energy-history-title">
        <Title level={4}>
          {titleName}
        </Title>
      </div>

      <Divider className="electric-energy-history-divider" />

      <Row>
        <Col span={20} offset={1}>
          <Descriptions bordered>
            <Descriptions.Item label="診断日時" span={3}>
              <ElectricEnergyOccurrenceDates onChange={handleChangeOccurrenceDates} />
            </Descriptions.Item>
            <Descriptions.Item label="サイト選択" span={3}>
              <SiteCascader
                onChange={handleChangeSite}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={label}
              span={3}
            >
              {props.period === 'hourly' ? (
                <ElectricEnergyStatusSelect onChange={handleChangeStatus} />
              ) : (
                <OMStatusSelect onChange={handleChangeStatus} />
              )}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={17} offset={1}>
          <span className="electric-energy-history-annotation">*</span>
          検索条件は1つ以上選択してください。
        </Col>
        <Col span={4}>
          <Tooltip title={isSearchButtonDisabled ? ERROR_NO_AUTH_MESSAGE : '検索'}>
            <Button
              type="primary"
              id="electric-energy-list-search-button"
              onClick={handleClickSearch}
              style={{ marginTop: 16, marginBottom: 16 }}
              loading={loading}
              disabled={isSearchButtonDisabled}
            >
              検索
            </Button>
          </Tooltip>
          <Tooltip title={isDownloadButtonDisabled ? ERROR_NO_AUTH_MESSAGE : 'ダウンロード'}>
            <Button
              type="primary"
              id="electric-energy-list-download-button"
              onClick={handleClickDownload}
              style={{ marginLeft: 15 }}
              loading={downloading}
              disabled={downloading || isDownloadButtonDisabled}
            >
              ダウンロード
            </Button>
          </Tooltip>
        </Col>
      </Row>
      {electricEnergyComparisonStatuses && (
        <Row>
          <SearchedElectricEnergyComparisonEventTable
            period={props.period}
            loading={loading}
            searchedEventList={electricEnergyComparisonStatuses}
          />
        </Row>
      )}
    </div>
  );
};

export default ElectricEnergyComparisonHistory;
