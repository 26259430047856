import React, { forwardRef } from 'react';
import { TreeSelect, message } from 'antd';

import TreeNode from '../../../utils/common/TreeNode';
import Facility from '../../../utils/Asset/Facility';

export interface FacilityTreeSelectProps {
  root?: Facility,
  value?: number,
  isTreeSelectDisabled?: boolean,
  onChange: (value?: number) => void,
  onSelect: (facility?: Facility) => void,
}

const FacilityTreeSelect: React.FC<
  FacilityTreeSelectProps
// eslint-disable-next-line @typescript-eslint/no-unused-vars
> = forwardRef((props, ref) => {
  /*
   * 変数/定数定義
   */
  const [loading, setLoading] = React.useState<boolean>(false);
  const [treeNodes, setTreeNodes] = React.useState<TreeNode<Facility>[]>([]);
  const [selectedKey, setSelectedKey] = React.useState<string>();

  const {
    root, value, isTreeSelectDisabled, onSelect,
  } = props;

  /*
   * イベントハンドラ
   */
  React.useEffect(
    () => {
      setTreeNodes([]);
      setSelectedKey(undefined);

      if (!root) return () => { /* 何もしない */ };

      setLoading(true);

      let canceled = false;
      (async () => {
        let newTreeNodes: TreeNode<Facility>[] = [];

        try {
          const tree = await root.loadFacilityTreeFromCDF();
          newTreeNodes = tree.children;
        } catch (exception) {
          message.error('配下の設備が読み込めませんでした。');
        }

        if (!canceled) {
          setTreeNodes(newTreeNodes);
          setLoading(false);
        }
      })();

      return () => { canceled = true; };
    },
    [root],
  );

  React.useEffect(
    () => {
      if (!value) return;

      let targetTreeNode: TreeNode<Facility> | null = null;
      const key = String(value);
      for (let i = 0; i < treeNodes.length; i++) {
        const treeNode = treeNodes[i].findNodeByKey(key);
        if (treeNode) {
          targetTreeNode = treeNode;
          break;
        }
      }

      if (targetTreeNode) {
        setSelectedKey(key);
        onSelect(targetTreeNode.data);
      } else {
        setSelectedKey(undefined);
        onSelect();
      }
    },
    [value, treeNodes, onSelect],
  );

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  const renderTreeNodes = (newTreeNodes: TreeNode<Facility>[]) => (
    newTreeNodes.map((treeNode) => (
      treeNode.isLeaf
        ? (
          <TreeSelect.TreeNode
            key={treeNode.key}
            value={treeNode.key}
            title={treeNode.data.name}
          />
        )
        : (
          <TreeSelect.TreeNode
            key={treeNode.key}
            value={treeNode.key}
            title={treeNode.data.name}
          >
            {renderTreeNodes(treeNode.children)}
          </TreeSelect.TreeNode>
        )
    ))
  );

  return (
    <TreeSelect
      loading={loading}
      value={selectedKey}
      treeDefaultExpandAll
      disabled={!root || isTreeSelectDisabled}
      onSelect={(_value) => {
        if (_value === selectedKey) {
          setSelectedKey(undefined);
          props.onSelect(undefined);
          props.onChange(undefined);
        } else {
          let node;
          for (let i = 0; i < treeNodes.length; i++) {
            node = treeNodes[i].findNodeByKey(_value);
            if (node) break;
          }

          setSelectedKey(node?.key);
          props.onSelect(node?.data);
          props.onChange(Number(node?.key));
        }
      }}
      size="small"
      style={{ width: 200 }}
    >
      {renderTreeNodes(treeNodes)}
    </TreeSelect>
  );
});

export default FacilityTreeSelect;
