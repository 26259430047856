/**
 * ファイルチェック
 * @param {File} file ファイル
 * @returns {Promise<string | false>} ファイルタイプ
 */
const CSV = 'text/csv';
const XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const fileCheck = async (file: File): Promise<string | false> => {
  const checkType = file.type;
  if (checkType === CSV || checkType === XLSX) {
    return checkType;
  }
  return false;
};

export default fileCheck;
