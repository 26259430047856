import React from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Modal,
  message,
} from 'antd';
import { Metadata } from '@cognite/sdk';
import { FormComponentProps } from 'antd/lib/form';
import 'moment/locale/ja';

import SearchMetadataTable from './SearchMetadataTable';
import './SearchMetadataModal.css';
import BaseFile from '../../../utils/File/BaseFile';

import { ERROR_SAVE_SEARCH_METADATA_TABLE } from '../../../utils/messages';

const formItem = {
  metadata: { name: 'metadata', label: '詳細情報' },
};

/** 詳細情報検索モーダル選択値 */
export interface SearchMetadataInputValues {
  metadata: Metadata,
}

/** 詳細情報検索モーダルプロパティ */
interface SearchMetadataModalProps extends FormComponentProps {
  visible: boolean,
  targetFiles: BaseFile[],
  onSave: (advancedSearchInputValues: SearchMetadataInputValues) => void,
  onClickMask: () => void,
}

type Ref = FormComponentProps;

// 設備切り替えフラグのステータス
export const FacilitySwitchFlgStatus = {
  RESET_BUTTON: 0,
  SWITCH_FACILITY: 1,
} as const;

export type FacilitySwitchFlgStatusType
  = typeof FacilitySwitchFlgStatus[keyof typeof FacilitySwitchFlgStatus];

/** 詳細情報検索モーダルコンポーネント */
const BaseMetadataSearchModal = React.forwardRef<Ref, SearchMetadataModalProps>(
  (props: SearchMetadataModalProps, ref) => {
    type MetadataTableHandle = React.ElementRef<typeof SearchMetadataTable>;
    const metadataTableRef = React.useRef<MetadataTableHandle>(null);

    const {
      visible,
      targetFiles,
      onSave,
      onClickMask,
      form,
    } = props;

    React.useImperativeHandle(ref, () => ({ form }));

    /*
     * イベントハンドラ
     */
    React.useEffect(() => {
      // 設備切り替えのタイミングで初期化
      if (metadataTableRef.current) {
        metadataTableRef.current.resetValues(FacilitySwitchFlgStatus.SWITCH_FACILITY);
      }

      onSave({ metadata: {} });
    }, [targetFiles, onSave]);

    /**
     * 「リセット」ボタンイベントハンドラ
     */
    const handleClickReset = () => {
      if (metadataTableRef.current) {
        metadataTableRef.current.resetValues(FacilitySwitchFlgStatus.RESET_BUTTON);
      }
    };

    /**
     * 「保存」ボタンイベントハンドラ
     */
    const handleClickSave = () => {
      if (!metadataTableRef.current) {
        return;
      }

      if (metadataTableRef.current.validateRequireError()) {
        message.error(ERROR_SAVE_SEARCH_METADATA_TABLE);
        return;
      }

      metadataTableRef.current.saveValues();
      const metadataValues = metadataTableRef.current.getValue();
      const inputValues: SearchMetadataInputValues = {
        metadata: metadataValues as Metadata,
      };

      onSave(inputValues);
    };

    /**
     * 「マスク」エリアクリックイベントハンドラ
     */
    const handleClickCancel = () => {
      if (metadataTableRef.current) {
        metadataTableRef.current.restoreValues();
      }

      onClickMask();
    };

    return (
      <Modal
        centered
        closable={false}
        visible={visible}
        onCancel={handleClickCancel}
        bodyStyle={{ height: 400 }}
        width={680}
        footer={(
          <div className="footerButtonContainer">
            <Row>
              <Col span={12}>
                <Button className="footerButtons" onClick={handleClickReset} type="danger">リセット</Button>
              </Col>
              <Col span={12}>
                <Button className="footerButtons" onClick={handleClickSave} type="primary">保存</Button>
              </Col>
            </Row>
          </div>
        )}
      >
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
          <Form.Item label={formItem.metadata.label}>
            <SearchMetadataTable
              ref={metadataTableRef}
              height={284}
              initialRecordCount={5}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);

const SearchMetadataModal = Form.create<SearchMetadataModalProps>()(BaseMetadataSearchModal);

export default React.memo(SearchMetadataModal);
