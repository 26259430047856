import {
  Asset,
  AssetListScope,
  CursorAndAsyncIterator,
} from '@cognite/sdk';

import BaseAsset from './BaseAsset';

import { postApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_ROOT_ASSET_RESULT_LIST } from '../AWS/EndpointPath';

const ASSET_TYPE = 'result';

class Result extends BaseAsset {
  /*
   * クラスメソッド
   */
  static async loadAllFromCDF(): Promise<Result[]> {
    const scope: AssetListScope = {
      filter: { metadata: { assetType: ASSET_TYPE } },
      limit: Infinity,
    };

    const assets = await postApiGateway<AssetListScope, CursorAndAsyncIterator<Asset>>(
      EP_PATH_ROOT_ASSET_RESULT_LIST, scope,
    );

    return assets.items.map((asset) => new Result(asset));
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      super(asset);
    } else {
      super();
    }
  }

  /*
   * メンバメソッド
   */
  cloneSelf(): Result {
    const clone = new Result();
    Object.assign(clone, this);

    return clone;
  }

  // eslint-disable-next-line class-methods-use-this
  async loadChildrenFromCDF(): Promise<BaseAsset[]> {
    return [];
  }
}

export default Result;
