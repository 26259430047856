import React, { useState, useEffect } from 'react';
import { AssetListScope } from '@cognite/sdk';
import { Select } from 'antd';

import { loadAllSolarSiteFromCDFByScope, loadAllAssetsByParentIds } from '../../../utils/Asset/SolarSiteAsset';

const { Option } = Select;

const O_M_ASSET_TYPE = process.env.REACT_APP_O_M_ROOT_ASSET ?? 'o_m_root_asset';

interface AlertSiteSelectProps {
  selectedSite?: string;
  onChange: (value: string) => void;
}

/**
 * 警報対象サイトセレクトボックス取得
 * @param {AlertSiteSelectProps} props サイト選択情報およびセット関数
 * @returns セレクトボックス（警報情報）
 */
const AlertSiteSelect: React.FC<AlertSiteSelectProps> = (props: AlertSiteSelectProps) => {
  const { onChange, selectedSite } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [alertTargetSites, setAlertTargetSites] = useState<string[]>([]);

  useEffect(() => {
    let canceled = false;
    (async () => {
      // metadata.assetTypeが「o_m_root_asset」を持つデータは必ず1件しか存在しないためlimitは不要
      const scope: AssetListScope = {
        filter: {
          metadata: { assetType: O_M_ASSET_TYPE },
        },
      };
      const [sdfAsset] = await loadAllSolarSiteFromCDFByScope(scope);

      // 警報対象サイト
      const alertTargetSite = (await loadAllAssetsByParentIds([sdfAsset.id]))
        .filter((site) => site.metadata?.o_m_asset_level === 'area').map(({ name }) => name)
        .sort((a, b) => a.localeCompare(b, 'jp', { numeric: true }));

      if (!canceled) {
        setAlertTargetSites(alertTargetSite);
        setLoading(false);
      }
    })();

    return () => { canceled = true; };
  }, []);

  return (
    <Select
      id="alert-site-select"
      allowClear
      loading={loading}
      style={{ width: 200 }}
      value={selectedSite}
      onChange={(selectedValue) => onChange(selectedValue)}
    >
      {
        alertTargetSites.map((site) => (
          <Option key={site}>{site}</Option>
        ))
      }
    </Select>
  );
};

export default AlertSiteSelect;
