import {
  CogniteInternalId,
  DoubleDatapoint,
  Metadata,
  StringDatapoint,
  Timeseries as CogniteTimeseries,
  TimeseriesUnit,
} from '@cognite/sdk';

import Facility from '../Asset/Facility';
import ManagedFacility from '../Asset/ManagedFacility';
import { EP_PATH_MANAGED_FACILITY_TIMESERIES_DATAPOINTS_LIST, EP_PATH_MANAGED_FACILITY_TIMESERIES_LIST } from '../AWS/EndpointPath';
import TreeNode from '../common/TreeNode';
import { getAllTimeSeries, getAllDataPoints } from '../dataAccess';

/** タイムシリーズ種別 */
export const Type = {
  Temperature: 'temperature',
  Moisture: 'moisture',
  CO2: 'co2',
  Illuminance: 'illuminance',
} as const;

/**
 * タイムシリーズクラス
 */
class Timeseries implements CogniteTimeseries {
  /*
   * クラスメソッド
   */

  /**
   * 設備リストのいずれかをルートとするタイムシリーズを読み込む
   * @param {Facility[]} facilities 設備リスト
   * @returns {Promise<Timeseries[]>} タイムシリーズリスト
   */
  static async loadAllInFacilitiesFromCDF(facilities: Facility[]): Promise<Timeseries[]> {
    let query = {};
    if (facilities && facilities.length > 0) {
      query = { assetSubtreeIds: facilities.map((facility) => ({ id: facility.id })) };
    }

    const timeseriesList = await getAllTimeSeries(EP_PATH_MANAGED_FACILITY_TIMESERIES_LIST, query);
    return timeseriesList.map((timeseries) => new Timeseries(timeseries));
  }

  /**
   * 管理設備をルートとするノードリストを読み込む
   * @param {ManagedFacility} managedFacility 管理設備
   * @returns {Promise<TreeNode<Facility | Timeseries>>} ノードリスト
   */
  static async loadAllTreeInManagedFacilityFromCDF(
    managedFacility: ManagedFacility,
  ): Promise<TreeNode<Facility | Timeseries>> {
    const treeNode: TreeNode<Facility | Timeseries> = (
      await managedFacility.loadFacilityTreeFromCDF()
    );

    const timeseriesList = await Timeseries.loadAllInFacilitiesFromCDF(
      [treeNode.data as Facility],
    );

    timeseriesList.forEach((timeseries) => {
      if (timeseries.assetId) {
        const node = treeNode.findNodeByKey(String(timeseries.assetId));
        if (node) {
          node.addChild(
            new TreeNode<Facility | Timeseries>(String(timeseries.id), timeseries),
          );
        }
      }
    });

    return treeNode;
  }

  /*
   * メンバ変数
   */
  id: CogniteInternalId;

  externalId?: string;

  name?: string;

  description: string;

  isStep: boolean;

  isString: boolean;

  metadata?: Metadata;

  assetId?: CogniteInternalId;

  dataSetId?: CogniteInternalId;

  securityCategories?: number[];

  unit?: TimeseriesUnit;

  createdTime: Date;

  lastUpdatedTime: Date;

  /*
   * コンストラクタ
   */
  constructor(timeseries?: CogniteTimeseries) {
    if (timeseries) {
      this.id = timeseries.id;
      this.externalId = timeseries.externalId;
      this.name = timeseries.name;
      this.description = timeseries.description;
      this.isStep = timeseries.isStep;
      this.isString = timeseries.isString;
      this.metadata = timeseries.metadata;
      this.assetId = timeseries.assetId;
      this.dataSetId = timeseries.dataSetId;
      this.securityCategories = timeseries.securityCategories;
      this.unit = timeseries.unit;
      this.createdTime = timeseries.createdTime;
      this.lastUpdatedTime = timeseries.lastUpdatedTime;
    } else {
      this.id = 0;
      this.description = '';
      this.isStep = false;
      this.isString = false;
      this.createdTime = new Date();
      this.lastUpdatedTime = new Date();
    }
  }

  /*
   * メソッド
   */

  /**
   * 自身の値をコピーしたインスタンスを生成する
   * @returns {Timeseries} タイムシリーズ
   */
  cloneSelf(): Timeseries {
    const clone = new Timeseries();
    Object.assign(clone, this);
    return clone;
  }

  /**
   * 自身に紐づくデータポイント(Double | String)を読み込む
   * 開始日時 >= 終了日時の場合、例外をスローする
   * @param {Date} fromDate 開始日時
   * @param {Date} toDate 終了日時
   * @param {number} limit 上限
   * @returns {Promise<DoubleDatapoint[] | StringDatapoint[]>} データポイントリスト
   */
  async loadDatapoints(
    fromDate: Date, toDate: Date, limit?: number,
  ): Promise<DoubleDatapoint[] | StringDatapoint[]> {
    if (fromDate.getTime() >= toDate.getTime()) {
      throw new Error('開始日時には終了日時よりも過去を設定してください。');
    }

    const query = {
      items: [{
        id: this.id,
        start: fromDate.valueOf(),
        end: toDate.valueOf(),
      }],
      limit: limit || 1000,
      ignoreUnknownIds: true,
    };
    const datapointsList = await getAllDataPoints(
      EP_PATH_MANAGED_FACILITY_TIMESERIES_DATAPOINTS_LIST,
      query,
    );
    if (datapointsList.length === 0) return [];

    return this.isString
      ? datapointsList as StringDatapoint[]
      : datapointsList as DoubleDatapoint[];
  }
}

export default Timeseries;
