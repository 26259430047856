import { Metadata } from '@cognite/sdk';
import moment from 'moment';

/**
 * exif情報をmetadata格納情報に変換
 * @param exifInfo Exif情報
 * @returns Exif情報を格納したMetadata型データ
 */
// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const convertExifInfoFrom = (exifInfo: any): Metadata => {
  const metadata: Metadata = {};
  const convertToDecimalFromSexagesimal = (
    degree: number, minute: number, second: number,
  ): number => degree + (((minute * 60) + second) / 3600);

  const latitude = exifInfo.GPSLatitude;
  const longitude = exifInfo.GPSLongitude;
  const altitude = exifInfo.GPSAltitude as number;
  const imageWidth = exifInfo.ExifImageWidth;
  const imageHeight = exifInfo.ExifImageHeight;
  const dateTimeOriginal = exifInfo.DateTimeOriginal;

  if (dateTimeOriginal) {
    const date = moment(dateTimeOriginal);
    metadata['撮影日時'] = date.format('YYYY/MM/DD HH:mm:ss');
  }
  if (imageWidth && imageHeight) {
    metadata['画像サイズ'] = `${imageWidth} x ${imageHeight}`;
  }
  if (latitude && longitude && altitude) {
    metadata['緯度'] = String(convertToDecimalFromSexagesimal(
      latitude[0], latitude[1], latitude[2],
    ));
    metadata['経度'] = String(convertToDecimalFromSexagesimal(
      longitude[0], longitude[1], longitude[2],
    ));
    metadata['高度'] = String(altitude);
  }
  return metadata;
};
