import { EventTouch } from './EventTouch';
import { Point } from './Point';
import { Timer } from './Timer';

/**
 * Timerクラス
 */
class Zoom {
  /*
   * メンバ変数
   */
  zoom: HTMLElement;

  zoomElm: HTMLElement;

  zoomInfo: HTMLElement;

  zoomArea: HTMLElement;

  isMouseEnter: boolean;

  baseSize: number;

  areaSize: number;

  currentScale: number;

  scaleMin: number;

  scaleMax: number;

  scaleAdd: number;

  mouseWheelEvent: keyof WindowEventMap;

  originTimer: Timer;

  areaScale: number;

  factorNum: number;

  fractionNum: number;

  pointerPoint: Point;

  downPoint: Point;

  currentPoint: Point;

  targetPoint: Point;

  originPoint: Point;

  elmRect?: DOMRect;

  requestAnimationId?: number;

  areaLeftMin = 0;

  areaRightMax = 0;

  areaTopMin = 0;

  areaBottomMax = 0;

  eventTouchInstance;

  /*
   * コンストラクタ
   */
  constructor(baseClassName: string, eventTouch: EventTouch) {
    const domZoom = document.querySelector(`.${baseClassName} .zoom`);
    const domZoomElm = document.querySelector(`.${baseClassName} .zoom__elm`);
    const domZoomInfo = document.querySelector(`.${baseClassName} .zoom__info`);
    const domZoomArea = document.querySelector(`.${baseClassName} .zoom__area`);
    if (!domZoom || !domZoomElm || !domZoomInfo || !domZoomArea) {
      // 各DOMは必須のため存在しない場合、エラーとする
      throw new Error();
    }

    this.zoom = domZoom as HTMLElement;
    this.zoomElm = domZoomElm as HTMLElement;
    this.zoomInfo = domZoomInfo as HTMLElement;
    this.zoomArea = domZoomArea as HTMLElement;

    this.isMouseEnter = false; // エリア内フォーカスの判定用

    this.baseSize = 900; // 拡大用画像のサイズとりあえず1:1のサイズのみ
    this.areaSize = this.baseSize / 5; // 拡大エリア表示のサイズ

    this.currentScale = 1.5; // スケール初期値・現在値
    this.scaleMin = 1.0; // スケール最小値
    this.scaleMax = 10.0; // スケール最大値
    this.scaleAdd = 0.2; // マウススクロールでのスケール変化値

    this.mouseWheelEvent = 'wheel';
    this.originTimer = new Timer(100); // マウスホイールのタイマー

    this.areaScale = 1.0; // 表示エリアのスケール
    this.factorNum = 0.10; // イージング 係数
    this.fractionNum = 0.001; // 繰り上げ・切り下げ 端数

    this.pointerPoint = new Point(); // マウス位置 スケール基準点を決める用
    this.downPoint = new Point(); // ダウン時の要素位置
    this.currentPoint = new Point(); // 現在位置
    this.targetPoint = new Point(); // 目標位置
    this.originPoint = new Point(0.5, 0.5); // 拡大縮小の基準点

    this.eventTouchInstance = eventTouch;

    this.resize();
    this.events();
    this.update();
  }

  normalizePoint(_x: number, _y: number): Point {
    if (!this.elmRect) {
      throw new Error();
    }

    // elmRectとbaseSizeに合わせてマウス位置を0.0 ~ 1.0に正規化
    return new Point(
      this.fraction((_x - this.elmRect.left) / this.baseSize),
      this.fraction((_y - this.elmRect.top) / this.baseSize),
    );
  }

  normalizeScale(): void {
    // スケール 値最小/最大値以内に
    if (this.currentScale < this.scaleMin) this.currentScale = this.scaleMin;
    if (this.currentScale > this.scaleMax) this.currentScale = this.scaleMax;
  }

  fraction(_v: number): number {
    // 位置端数値 繰り上げ繰り下げ
    if (_v < 0.0 + this.fractionNum) _v = 0.0; // eslint-disable-line no-param-reassign
    if (_v > 1.0 - this.fractionNum) _v = 1.0; // eslint-disable-line no-param-reassign
    return _v;
  }

  resetScale(): void {
    this.currentScale = 1.0;
    this.areaScale = 1.0 / this.currentScale;
  }

  events(): void {
    window.addEventListener('resize', this.resize.bind(this), false);

    // PCイベント
    this.zoom.addEventListener('mouseenter', () => { this.isMouseEnter = true; }, false);
    this.zoom.addEventListener('mouseleave', () => { this.isMouseEnter = false; }, false);

    document.addEventListener('mousedown', this.mousedown.bind(this), false);
    document.addEventListener('mousemove', this.mousemove.bind(this), false);
    window.addEventListener(this.mouseWheelEvent, this.mousewheel.bind(this), false);
  }

  resize(): void {
    this.elmRect = this.zoomElm.getBoundingClientRect();
  }

  mousedown(): void {
    this.downPoint = new Point(this.currentPoint.x, this.currentPoint.y);
  }

  mousemove(e: MouseEvent): void {
    if (!this.isMouseEnter) return;

    this.pointerPoint = this.normalizePoint(e.clientX, e.clientY);

    // マウスドラッグ時の処理
    if (this.eventTouchInstance.isDown) {
      this.addDiffPoint();
    }
  }

  mousewheel(e: Event | undefined): void {
    // PCはマウススクロールでスケール変更
    if (!this.isMouseEnter) return;

    let { event } = window;
    if (e) {
      event = e;
    }

    if (!event) return;

    if (EventTouch.mouseDelta(event as WheelEvent) < 0) {
      this.currentScale -= this.scaleAdd;
    } else {
      this.currentScale += this.scaleAdd;
    }

    this.normalizeScale();
    this.areaScale = 1.0 / this.currentScale;

    if (this.originTimer.isTimeOut) this.updateOrigin();

    this.originTimer.reset();
  }

  addDiffPoint(): void {
    const addPoint = new Point(
      this.eventTouchInstance.diffPoint.x / this.baseSize,
      this.eventTouchInstance.diffPoint.y / this.baseSize,
    );
    this.targetPoint = new Point(this.downPoint.x - addPoint.x, this.downPoint.y - addPoint.y);
  }

  updateOrigin(): void {
    // 拡大縮小の基準点設定
    const tempOriginPoint = new Point(this.originPoint.x, this.originPoint.y);

    const areaLeft = (this.originPoint.x * (this.currentScale - 1.0)) / this.currentScale + (this.currentPoint.x * this.areaScale); // eslint-disable-line max-len
    const areaTop = (this.originPoint.y * (this.currentScale - 1.0)) / this.currentScale + (this.currentPoint.y * this.areaScale); // eslint-disable-line max-len

    const originX = areaLeft + this.pointerPoint.x * this.areaScale;
    const originY = areaTop + this.pointerPoint.y * this.areaScale;
    this.originPoint = new Point(originX, originY);

    const diffPoint = (
      new Point(this.originPoint.x - tempOriginPoint.x, this.originPoint.y - tempOriginPoint.y)
    );

    diffPoint.x -= (diffPoint.x * this.currentScale);
    diffPoint.y -= (diffPoint.y * this.currentScale);

    this.currentPoint.x += diffPoint.x;
    this.targetPoint.x = this.currentPoint.x;
    this.currentPoint.y += diffPoint.y;
    this.targetPoint.y = this.currentPoint.y;
  }

  update(): void {
    this.requestAnimationId = window.requestAnimationFrame(this.update.bind(this));
    this.moveArea();
    this.easing();
    this.addStyle();
  }

  cancel(): void {
    if (this.requestAnimationId) {
      window.cancelAnimationFrame(this.requestAnimationId);
    }
  }

  moveArea(): void {
    // originPointに合わせてドラッグ/スワイプでの移動範囲を決める
    this.areaLeftMin = this.originPoint.x - (this.originPoint.x * this.currentScale);
    this.areaRightMax = -((1.0 - this.originPoint.x) - (1.0 - this.originPoint.x) * this.currentScale); // eslint-disable-line max-len
    this.areaTopMin = this.originPoint.y - (this.originPoint.y * this.currentScale);
    this.areaBottomMax = -((1.0 - this.originPoint.y) - (1.0 - this.originPoint.y) * this.currentScale); // eslint-disable-line max-len
  }

  easing(): void {
    // 位置のイージング処理
    if (this.targetPoint.x <= this.areaLeftMin) {
      this.targetPoint.x += (this.areaLeftMin - this.targetPoint.x) * this.factorNum;
    }

    if (this.targetPoint.x >= this.areaRightMax) {
      this.targetPoint.x += (this.areaRightMax - this.targetPoint.x) * this.factorNum;
    }

    if (this.targetPoint.y <= this.areaTopMin) {
      this.targetPoint.y += (this.areaTopMin - this.targetPoint.y) * this.factorNum;
    }

    if (this.targetPoint.y >= this.areaBottomMax) {
      this.targetPoint.y += (this.areaBottomMax - this.targetPoint.y) * this.factorNum;
    }

    this.currentPoint.x += (this.targetPoint.x - this.currentPoint.x) * this.factorNum;
    this.currentPoint.y += (this.targetPoint.y - this.currentPoint.y) * this.factorNum;

    if (Math.abs(this.currentPoint.x - this.targetPoint.x) < this.fractionNum) {
      this.currentPoint.x = this.targetPoint.x;
    }

    if (Math.abs(this.currentPoint.y - this.targetPoint.y) < this.fractionNum) {
      this.currentPoint.y = this.targetPoint.y;
    }
  }

  addStyle(): void {
    // 要素にスタイルやスケール等を反映
    Object.assign(this.zoomElm.style, {
      transform: `translate(
        ${-this.currentPoint.x * this.baseSize}px,
        ${-this.currentPoint.y * this.baseSize}px
      )
      scale(${this.currentScale})`,
      'transform-origin': `${this.originPoint.x * this.baseSize}px ${this.originPoint.y * this.baseSize}px`,
    });

    this.areaScale = 1.0 / this.currentScale;
    Object.assign(this.zoomArea.style, {
      transform: `translate(
        ${this.currentPoint.x * this.areaSize * this.areaScale}px,
        ${this.currentPoint.y * this.areaSize * this.areaScale}px
    )
    scale(${this.areaScale})`,
      'transform-origin': `${this.originPoint.x * this.areaSize}px ${this.originPoint.y * this.areaSize}px`,
    });
  }
}

export { Zoom };
