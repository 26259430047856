import React from 'react';
import { Tabs } from 'antd';
import FinancialReportRegistration from './FinancialReportRegistration';
import FinancialReportSearch from './FinancialReportSearch';
import './FinancialReportTabs.css';

interface FinancialReportTabsProps {
  setLoadingFlag: (flag: boolean) => void;
  display: '登録' | '検索';
}

const { TabPane } = Tabs;

/** タブ表示文字列 */
const TAB_KEYS = {
  FINANCIAL_REPORT_REGISTRATION: '財務帳票登録',
  FINANCIAL_REPORT_SEARCH: '財務情報閲覧',
};

/**
 * 財務帳票画面タブ
 */
const FinancialReportTabs: React.FC<FinancialReportTabsProps> = ({ setLoadingFlag, display }) => (
  <div className="financial-report-tabs-container">
    <Tabs
      className="financial-report-tabs"
      type="card"
    >
      {display === '登録' && (
        <TabPane tab={TAB_KEYS.FINANCIAL_REPORT_REGISTRATION} key={TAB_KEYS.FINANCIAL_REPORT_REGISTRATION}>
          <FinancialReportRegistration setLoadingFlag={setLoadingFlag} />
        </TabPane>
      )}
      {display === '検索' && (
        <TabPane tab={TAB_KEYS.FINANCIAL_REPORT_SEARCH} key={TAB_KEYS.FINANCIAL_REPORT_SEARCH}>
          <FinancialReportSearch />
        </TabPane>
      )}
    </Tabs>
  </div>
);

export default FinancialReportTabs;
