/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unused-state */
// eslint警告未対応
import React from 'react';
import {
  Layout, Card, Row, Col,
} from 'antd';
import { withRouter } from 'react-router';
import { ImagesListViewer, LIST_TYPE } from '../../util/ImagesListViewer';
import { FileDetailView } from '../../Common/File/FileDetailView';
import { nextItemId } from '../../../utils/common';
import editor from '../../../utils/cocoJsonEditor';
import da from '../../../utils/dataAccess';
import { getStorageDataSetId } from '../../../utils/storageCommon';
import { MimeType } from '../../../utils/File/BaseFile';
import LearningClass from './LearningClass';
import ProjectList from '../Common/ProjectList';
import ProjectTitle from '../Common/ProjectTitle';
import { ResourceType } from '../../../utils/common/SDFDataType';
import { EP_PATH_LEARNING_PJ_FILES, EP_PATH_LEARNING_PJ_FILES_LIST } from '../../../utils/AWS/EndpointPath';
import { ANNOTATION } from '../../Common/File/FileTable';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../../utils/common/Authentication';

const { Content } = Layout;
const SUPER_CATEGORY = 'class';

/**
 * アノテーション対象確認画面component
 * @property {object} client CogniteClient
 * @property {function} setLoadingFlag Load Flag設定関数
 */
class Annotation extends React.Component {
  constructor(props) {
    super(props);
    /** 学習プロジェクト一覧へのref */
    this.projectListRef = React.createRef();
  }

  state = {
    selectedAssetID: null,
    selectedAssetName: null,
    selectedResourceType: null,
    buttonStatusStart: false,
    /** ファイル一覧で選択したファイルID */
    selectedFileId: null,
    /** jsonファイルから読み込んだクラス情報 */
    classes: [],
    isModalEditButtonDisabled: true,
    isModalDownloadButtonDisabled: true,
    isLearningPjEditDisabled: true,
  };

  /**
   * component render前の処理
   */
  async componentDidMount() {
    const { EXPANDED_MODAL_EDIT_BUTTON } = AUTHENTICATION_TYPE_MATRIX.EQUIPMENT_DETAILS_EQ;
    const { EXPANDED_MODAL_DOWNLOAD_BUTTON, LEARNING_PROJECTS_NAME_EDIT_BUTTON } = AUTHENTICATION_TYPE_MATRIX.ANNOTATION;

    this.setState({
      isModalEditButtonDisabled: !await containsUIAuthType(EXPANDED_MODAL_EDIT_BUTTON),
      isModalDownloadButtonDisabled: !await containsUIAuthType(EXPANDED_MODAL_DOWNLOAD_BUTTON),
      isLearningPjEditDisabled: !await containsUIAuthType(LEARNING_PROJECTS_NAME_EDIT_BUTTON),
    });
  }

  /**
   * 学習プロジェクト名を更新した際の後処理
   * 学習プロジェクト一覧を再読み込みする。
   */
  handleUpdatedProjectTitle = () => {
    // プロジェクトリスト再読み込み
    this.projectListRef.current && this.projectListRef.current.createProjectComponents();
  };

  /**
   * Asset選択時のイベントハンドラ
   * @param {object} asset 選択されたAsset
   */
  onSelectAsset = async (asset) => {
    this.setState({
      selectedAssetID: asset.key,
      selectedAssetName: asset.title,
      selectedResourceType: ResourceType.LearningProject,
      selectedFileId: null,
    });

    this.jsonExternalId = editor.getCocoJsonExternalId(asset.key, asset.title);
    this.jsonFileName = editor.getCocoJsonName(asset.title);
    await this.loadJsonFile();
    this.generateClassTag();
  };

  /**
   * 学習プロジェクト削除時のイベントハンドラ
   */
  onDeleted = () => {
    this.setState({ selectedAssetID: null });
  };

  /**
   * Imageクリック時のイベントハンドラ
   * @param {number} fileId 選択されたImageのID
   */
  onClickImage = (fileId) => {
    this.setState({ selectedFileId: fileId });
  };

  /**
   * Start Annotationボタンクリック時のイベントハンドラ
   */
  onClickStartAnnotation = async () => {
    this.setState({
      buttonStatusStart: true,
    });

    if (!this.hasJsonFile) {
      this.jsonFile.info.year = new Date().getFullYear();
      this.jsonFile.info.date_created = new Date().toString();
    }

    await this.addImages();
    await this.saveCache();

    this.setState({
      buttonStatusStart: false,
    });

    this.props.history.push('/AnnotationTool');
  };

  /**
   * cocojsonへの画像追加処理
   */
  addImages = async () => {
    const copyImages = [...this.jsonFile.images];
    let imageId = nextItemId(copyImages);
    const { Jpeg } = MimeType;
    const fileFilter = { assetIds: [this.state.selectedAssetID], mimeType: Jpeg, uploaded: true };
    const allFiles = await da.getAllFiles(EP_PATH_LEARNING_PJ_FILES_LIST, fileFilter);

    let addImageFlag = false;
    allFiles.forEach((file) => {
      if (!this.hasImageData(copyImages, file.id)) {
        const fileName = String(file.id);
        this.jsonFile.images.push({ id: imageId, file_name: fileName, displayName: file.name });
        imageId++;
        addImageFlag = true;
      }
    });

    if (addImageFlag) {
      await editor.saveCocoJsonFile(this.jsonExternalId, this.jsonFileName, this.state.selectedAssetID, this.jsonFile);
    }
  };

  /**
   * 学習プロジェクトから画像を削除
   * @param {Array} checkFileList 画像のチェックボックスリスト
   */
  deleteImageFromPj = async (checkFileList) => {
    await Promise.all(
      checkFileList.map(async (fileId) => {
        await da.removeAssetId(EP_PATH_LEARNING_PJ_FILES, fileId, this.state.selectedAssetID);
        const imageId = editor.deleteImages(this.jsonFile.images, fileId);
        if (imageId) {
          editor.deleteAnnotations(this.jsonFile.annotations, 'image_id', imageId);
        }
      }),
    );

    await editor.saveCocoJsonFile(this.jsonExternalId, this.jsonFileName, this.state.selectedAssetID, this.jsonFile);
  };

  /**
   * 対象の画像が既に存在しているか判定
   * @param {Array} images 画像の配列
   * @param {number} fileId 対象のfileID
   * @returns {boolean} 判定結果 (true: 存在している / false: 存在していない)
   */
  hasImageData = (images, fileId) => {
    for (let i = 0; i < images.length; ++i) {
      const fileName = images[i].file_name;
      if (fileName.indexOf(String(fileId)) !== -1) {
        return true;
      }
    }
    return false;
  };

  /**
   * jsonFileの読み込み
   */
  loadJsonFile = async () => {
    this.jsonFile = await editor.getCocoJsonFile(this.jsonExternalId);
    this.hasJsonFile = true;
    if (!this.jsonFile) {
      const res = await fetch('via_template_coco.json');
      this.jsonFile = await res.json();
      this.hasJsonFile = false;
    }
  };

  /**
   * クラスタグの生成
   */
  generateClassTag = () => {
    const { categories } = this.jsonFile;

    this.classes = categories.map((category) => category.name);

    this.setState({
      classes: this.classes,
    });
  };

  /**
   * 学習クラス名をjsonファイルから削除する。
   * @param {string} deleteClassName 学習クラス名
   */
  deleteClass = async (deleteClassName) => {
    // coco.jsonファイルの'categories'から対象の学習クラス名を削除
    const categoryId = editor.deleteCategories(this.jsonFile.categories, deleteClassName);
    if (categoryId && this.jsonFile.annotations.length > 0) {
      // 'categories'から削除した場合、coco.jsonファイルの'annotations'からも削除
      editor.deleteAnnotations(this.jsonFile.annotations, 'category_id', categoryId);
    }

    await editor.saveCocoJsonFile(this.jsonExternalId, this.jsonFileName, this.state.selectedAssetID, this.jsonFile);

    // 削除後、学習クラスタグを再描画
    this.generateClassTag();
  };

  /**
   * 学習クラス名をjsonファイルに保存する。
   * @param {string} className 学習クラス名
   */
  saveClass = async (className) => {
    this.classes = [...this.classes, className];
    this.setCategories(className);

    await editor.saveCocoJsonFile(this.jsonExternalId, this.jsonFileName, this.state.selectedAssetID, this.jsonFile);
    this.setState({ classes: this.classes });
  };

  /**
   * カテゴリの設定
   * @param {String} category カテゴリ名
   */
  setCategories = (category) => {
    const data = {
      supercategory: SUPER_CATEGORY,
      id: nextItemId(this.jsonFile.categories),
      name: category,
    };
    this.jsonFile.categories.push(data);
  };

  /**
   * CacheDataの保存
   */
  async saveCache() {
    const cache = await caches.open('annotation-data');
    const cacheData = {
      externalId: this.jsonExternalId,
      assetId: this.state.selectedAssetID,
      fileId: this.state.selectedFileId,
      jsonFileName: this.jsonFileName,
      dataSetId: getStorageDataSetId(),
    };
    cache.put('/annotation_cache', new Response(JSON.stringify(cacheData)));
  }

  render() {
    const {
      classes,
      selectedAssetID,
      selectedAssetName,
      selectedFileId,
      selectedResourceType,
      isModalEditButtonDisabled,
      isModalDownloadButtonDisabled,
      isLearningPjEditDisabled,
    } = this.state;

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <ProjectList
          title="学習プロジェクト"
          onSelect={this.onSelectAsset}
          onDeleted={this.onDeleted}
          ref={this.projectListRef}
        />

        <Content>

          {
            selectedAssetID && (
              <>
                <ProjectTitle
                  client={this.props.client.client}
                  assetId={selectedAssetID}
                  updatedFunction={this.handleUpdatedProjectTitle}
                  isLearningPjEditDisabled={isLearningPjEditDisabled}
                />

                <Row>
                  <Col span={17}>
                    <ImagesListViewer
                      client={this.props.client}
                      listType={LIST_TYPE.JPEG_ONLY}
                      assetId={selectedAssetID}
                      resourceType={selectedResourceType}
                      assetName={selectedAssetName}
                      showAddButton={false}
                      showImageDisplaySelection={false}
                      onClickImage={this.onClickImage}
                      customStyle={{ cardStyle: { height: '790px', marginLeft: '10px' }, fileTableHeight: '640px', fileListHeight: '580px' }}
                      showDelButton={false}
                      showDelFromPjButton
                      deleteImageFromPj={this.deleteImageFromPj}
                      showAddPjButton={false}
                      showAnnotation
                      onClickAnnotation={this.onClickStartAnnotation}
                      showSettingLearningParameterButton={false}
                      onClickSettingLearningParameter={null}
                      showAnnotationColumns
                      fileNameStatus={ANNOTATION}
                      isModalEditButtonDisabled={isModalEditButtonDisabled}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                    />
                  </Col>
                  <Col span={7}>
                    <FileDetailView
                      resourceType={selectedResourceType}
                      fileId={selectedFileId}
                      isModalEditButtonDisabled={isModalEditButtonDisabled}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                      customStyle={{ cardStyle: { height: '790px', marginRight: '10px' }, detailsListHeight: '550px' }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Card style={{ margin: '10px', height: '19vh' }}>
                    <LearningClass
                      classes={classes}
                      jsonExternalId={this.jsonExternalId}
                      onSaveClass={this.saveClass}
                      onDeleteClass={this.deleteClass}
                    />
                  </Card>
                </Row>
              </>
            )
          }
        </Content>
      </Layout>
    );
  }
}

export default withRouter(Annotation);
