import { JsonFile } from './BaseFile';

interface PointCloudJson {
  modelId: number,
  revisionId: number,
  position?: [number, number, number],
  scale?: [number, number, number],
  pointSize?: number,
}

interface CadModelsJson {
  modelId: number,
  revisionId: number,
  position?: [number, number, number],
  scale?: [number, number, number],
  rotation?: [number, number, number]
}

export interface ConfigJson {
  pointClouds: PointCloudJson[],
  cadModels: CadModelsJson[],
  defaults: {
    position: [number, number, number],
    scale: [number, number, number],
    rotation: [number, number, number]
  },
  camera: {
    position: [number, number, number],
    target: [number, number, number]
  },
  scanObjects: {
    radius: number,
    widthSegments: number,
    heightSegments: number
  }
}

class ConfigJsonFile extends JsonFile {

  /*
   * コンストラクタ
   */

  /*
   * プロパティ
   */

  /*
   * メソッド
   */

}

export default ConfigJsonFile;
