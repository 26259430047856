import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import ActivityCheckSetting, { SendParameter } from '../../../utils/DynamoDB/ActivityCheckSetting';
import { SUCCESS_UPDATE_ACTIVITY_CHECK_INTERVAL, ERROR_UPDATE_ACTIVITY_CHECK_INTERVAL } from '../../../utils/messages';

import './IntervalSettingModal.css';

const { Title } = Typography;
const { Option } = Select;

interface GroupSettingModalProps {
  modalRenderData: ActivityCheckSetting;
  visible: boolean;
  closeModal: () => void;
  refreshParentTable: () => void;
}

/**
 * 死活監視判定間隔設定モーダル
 * @param {GroupSettingModalProps} props プロパティ
 * @returns 死活監視判定間隔設定モーダルコンポーネント
 */
const IntervalSettingModal: React.FC<GroupSettingModalProps> = (props: GroupSettingModalProps) => {
  const {
    modalRenderData,
    visible,
    closeModal,
    refreshParentTable,
  } = props;

  const [selectedIntervalValue, setSelectedIntervalValue] = useState<number>(modalRenderData.checkIntervalTime); // プルダウンでユーザーが選択した値
  const [loading, setLoading] = useState<boolean>(false); // モーダルのloading

  useEffect(() => {
    if (!visible) return;
    setSelectedIntervalValue(modalRenderData.checkIntervalTime);
  }, [modalRenderData, visible]); // modalRenderData又はvisibleが更新されるたびにstateを更新し、モーダルにレンダーされる情報を変える

  /**
   * 判定間隔プルダウンを押した時に呼び出されるイベントハンドラ
   * @param {number} value 選択した判定間隔
   */
  const handleChange = (value: number) => {
    setSelectedIntervalValue(value);
  };

  /**
   * 更新ボタンをクリックした時のイベントハンドラ
   */
  const handleClickUpdate = async (): Promise<void> => {
    setLoading(true);
    const parameter: SendParameter = {
      siteName: modalRenderData.siteName,
      index: modalRenderData.index,
      checkIntervalTime: selectedIntervalValue,
      oldCheckIntervalTime: modalRenderData.checkIntervalTime,
    };

    try {
      await ActivityCheckSetting.updateActivityCheckSettings(parameter);
      message.success(SUCCESS_UPDATE_ACTIVITY_CHECK_INTERVAL);
      refreshParentTable();
      closeModal();
    } catch {
      message.error(ERROR_UPDATE_ACTIVITY_CHECK_INTERVAL);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      centered
      footer={null}
    >
      <div>
        <Spin spinning={loading}>
          <div>
            <Title level={4}>死活監視判定間隔編集</Title>
          </div>
          <Row>
            <h4>会社名</h4>
            <Input
              id="company"
              value={modalRenderData.companyNameJp}
              disabled
              className="input-spacing"
            />
          </Row>
          <Row>
            <h4>サイト名</h4>
            <Input
              id="siteName"
              value={modalRenderData.message}
              disabled
              className="input-spacing"
            />
          </Row>
          <Row>
            <h4>GMU-ID</h4>
            <Input
              id="gmuId"
              value={modalRenderData.gmuId}
              disabled
              className="input-spacing"
            />
          </Row>
          <Row>
            <h4>判定間隔</h4>
            <Select
              value={selectedIntervalValue}
              onChange={handleChange}
              className="interval-pulldown"
            >
              {[...Array(25)].map((_, index) => (
                <Option key={`${index + 1}`} value={index}>
                  {index === 0 ? '判定なし' : `${index}時間`}
                </Option>
              ))}
            </Select>
          </Row>
          <div className="update-button">
            <Button
              type="primary"
              onClick={handleClickUpdate}
            >
              更新
            </Button>
          </div>
        </Spin>
      </div>
    </Modal>
  );
};
export default IntervalSettingModal;
