import React from 'react';

import TimeseriesTree from '../Common/Timeseries/TimeseriesTree';
import TimeseriesChart from '../Common/Timeseries/TimeseriesChart';
import Timeseries from '../../utils/Timeseries/Timeseries';

import './Monitor.css';

/**
 * データ監視コンポーネント
 */
const Monitor: React.FC = () => {
  /*
   * 変数/定数定義
   */
  const [checkedTimeseriesList, setCheckedTimeseriesList] = React.useState<Timeseries[]>([]);

  /*
   * イベントハンドラ
   */

  /**
   * タイムシリーズ選択時のイベントハンドラ
   * @param timeseriesList タイムシリーズリスト
   */
  const onTimeseriesCheck = (timeseriesList: Timeseries[]) => {
    setCheckedTimeseriesList(timeseriesList);
  };

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  return (
    <div className="dashboard-monitor">
      <div className="timeseries-tree">
        <TimeseriesTree onCheck={onTimeseriesCheck} height={691} />
      </div>
      <div className="chart-container" style={{ width: 1240 }}>
        <TimeseriesChart timeseriesList={checkedTimeseriesList} height={650} />
      </div>
    </div>
  );
};

export default Monitor;
