import React from 'react';
import { DatePicker } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import locale from 'antd/es/date-picker/locale/ja_JP';
import moment, { Moment } from 'moment';

interface AlertListOccurrenceDatesProps {
  onChange: (dates: [] | [Moment, Moment]) => void;
  occurrenceDates: [Moment, Moment] | [];
}

const { RangePicker } = DatePicker;

/**
 * 警報履歴画面 発生日時RangePicker
 * @param {AlertListOccurrenceDatesProps} props プロパティ
 * @returns 発生日時RangePicker
 */
const AlertListOccurrenceDates: React.FC<AlertListOccurrenceDatesProps> = (
  props: AlertListOccurrenceDatesProps,
) => {
  const { onChange, occurrenceDates } = props;
  /**
   * 発生日時変更イベントハンドラ
   * @param {RangePickerValue} dates 選択した発生日時
   */
  const handleChangeOccurrenceDates = (dates: RangePickerValue): void => {
    if (dates.length === 0) {
      onChange([]);
      return;
    }

    // UIで制御されているため必ずMomentが渡される
    const [startDate, endDate] = dates;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    onChange([startDate!, endDate!]);
  };

  return (
    <RangePicker
      showTime={{
        format: 'HH:mm',
        defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
      }}
      format="YYYY/MM/DD HH:mm"
      placeholder={['開始日付', '終了日付']}
      locale={locale}
      onChange={handleChangeOccurrenceDates}
      value={occurrenceDates}
    />
  );
};

export default AlertListOccurrenceDates;
