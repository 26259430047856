import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import { SelectValue } from 'antd/lib/select';

import {
  SUCCESS_UPDATE_ALERT_EMAIL_SETTING,
  ERROR_UPDATE_ALERT_EMAIL_SETTING,
  ERROR_UPDATE_ALERT_EMAIL_SETTING_NOT_EXISTS,
} from '../../../utils/messages';
import AlertMappingInfo from '../../../utils/DynamoDB/AlertMappingInfo';
import EmailGroup from '../../../utils/DynamoDB/EmailGroup';

import './AlertSettingModal.css';

const { Title } = Typography;
const SelectOption = Select.Option;

interface AlertSettingModalProps {
  visible: boolean;
  onClose: () => void;
  selectedAlertInfo: AlertMappingInfo;
  emailGroupInfo: EmailGroup[];
  tableRefresh: () => void;
}

/**
 * 警報メール設定モーダル画面（更新）
 * @param {AlertSettingModalProps} props プロパティ
 * @returns 警報メール設定モーダル画面（更新）
 */
const AlertSettingModal: React.FC<AlertSettingModalProps> = (props: AlertSettingModalProps) => {
  const {
    visible,
    onClose,
    selectedAlertInfo,
    emailGroupInfo,
    tableRefresh,
  } = props;
  const [editTargetGroupIds, setEditTargetGroupIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const targetGroupOptions = emailGroupInfo.map(({ id, groupName }) => (
    <SelectOption key={id}>{groupName}</SelectOption>
  ));
  const {
    areaNameJp,
    gmuId,
    signalName,
    severity,
    targetGroupIds,
  } = selectedAlertInfo;

  useEffect(() => {
    if (!visible) return;
    const filteredTargetGroupIds = targetGroupIds.filter((target) => emailGroupInfo.some((emailGroup) => emailGroup.id === target));
    setEditTargetGroupIds(filteredTargetGroupIds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  /**
   * 通知グループ選択時のイベントハンドラ
   * @param {SelectValue} selectedEmailGroups 選択項目
   */
  const handleChangeTargetGroupIds = (selectedEmailGroups: SelectValue): void => {
    setEditTargetGroupIds(selectedEmailGroups as string[]);
  };

  /**
   * 更新ボタンクリック時のイベントハンドラ
   */
  const handleUpdateAlertSetting = async () => {
    setLoading(true);
    const sendParameter = {
      ...selectedAlertInfo,
      targetGroupIds: editTargetGroupIds,
    };

    try {
      await AlertMappingInfo.updateTargetGroupIds(sendParameter);
      message.success(SUCCESS_UPDATE_ALERT_EMAIL_SETTING);
      onClose();
      tableRefresh();
      setLoading(false);
    } catch (exception) {
      if (String(exception) === 'Error: 404') {
        message.error(ERROR_UPDATE_ALERT_EMAIL_SETTING_NOT_EXISTS);
      } else {
        message.error(ERROR_UPDATE_ALERT_EMAIL_SETTING);
      }
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
    >
      <div>
        <Spin spinning={loading}>
          <div>
            <Title level={4}>警報メール編集</Title>
            <Divider className="alert-setting-modal-input-divider" />
          </div>
          <Row>
            <h4>発電所名</h4>
            <Input
              id="areaNameJp"
              value={areaNameJp}
              disabled
              className="alert-setting-modal-input-spacing"
            />
          </Row>
          <Row>
            <h4>GMU-ID</h4>
            <Input
              id="gmuId"
              value={gmuId}
              disabled
              className="alert-setting-modal-input-spacing"
            />
          </Row>
          <Row>
            <h4>計測ポイント名称</h4>
            <Input
              id="signalName"
              value={signalName}
              disabled
              className="alert-setting-modal-input-spacing"
            />
          </Row>
          <Row>
            <h4>重要度</h4>
            <Input
              id="severity"
              value={severity}
              disabled
              className="alert-setting-modal-input-spacing"
            />
          </Row>
          <Row>
            <h4>通知先グループ</h4>
            <div className="alert-setting-modal-group-pulldown">
              <Select
                id="targetGroupIds"
                mode="multiple"
                value={editTargetGroupIds}
                onChange={handleChangeTargetGroupIds}
              >
                {targetGroupOptions}
              </Select>
            </div>
          </Row>
          <div className="alert-setting-modal-update-button">
            <Button
              type="primary"
              onClick={handleUpdateAlertSetting}
              disabled={loading}
            >
              更新
            </Button>
          </div>
        </Spin>
      </div>
    </Modal>
  );
};

export default AlertSettingModal;
