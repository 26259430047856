import React, {
  useState,
  useRef,
} from 'react';
import {
  Button,
  Checkbox,
  Menu,
  Table,
  Modal,
  message,
} from 'antd';
import { ColumnFilterItem, ColumnProps, FilterDropdownProps } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';
import { deleteEvent } from '../../utils/dataAccess';
import { EP_PATH_SOLAR_PROCESSES_OPERATIONAL_STATUS } from '../../utils/AWS/EndpointPath';
import {
  WARNING_DELETE_FILE_TITLE,
  CONFIRM_DELETE_OPERATIONAL_STATUS,
  SUCCESS_DELETE_OPERATIONAL_STATUS,
  ERROR_DELETE_OPERATIONAL_STATUS,
} from '../../utils/messages';
import OperationalStatus from '../../utils/Event/OperationalStatus';

/**
 * 運転状況一覧画面 表示データインタフェース
 */
export interface PcsOperationalStatus extends OperationalStatus {
  /** PCSカラムに表示する名前 */
  pcsName: string;
}

/** ダイアログのボタンラベル */
const MODAL_BUTTON_TEXT = {
  DELETE: '削除',
  CANCEL: 'キャンセル',
};

interface OperationalStatusTableProps {
  loading: boolean;
  operationalStatuses: PcsOperationalStatus[];
  handleChangeRowSelect: (selectedRowKey: number[]) => void;
  display: '削除' | '一覧';
}

/**
 * ソートして返却
 * @param {string} a
 * @param {string} b
 * @returns ソートの判定結果
 */
const getSortedItems = (a: string, b: string) => {
  const first = [a, b].sort()[0];
  return first === a ? -1 : 1;
};

/**
 * ソートして返却(数値)
 * @param {string} a
 * @param {string} b
 * @returns ソートの判定結果
 */
const getSortedNumberItems = (a: string, b: string) => {
  // 値が空の場合は昇順の最上部に配置
  if (a === '') {
    return -1;
  }
  if (b === '') {
    return 1;
  }
  return Number(a) - Number(b);
};

/**
 * フィルタアイテムリスト作成
 * @param {Set<string | undefined>} uniqueData 一意の設備情報
 * @returns フィルタアイテムリスト
 */
const createTableFilter = (uniqueData: Set<string | undefined>): ColumnFilterItem[] => {
  const filterItem: ColumnFilterItem[] = [];
  let text;
  uniqueData.forEach((elm) => {
    if (elm) {
      text = elm.replaceAll('<br>', ' ');
      filterItem.push(
        { text, value: String(elm) },
      );
    }
  });

  // ドロップダウン選択肢をソート
  return filterItem.sort((a, b) => {
    // 29行目のif分にてtextがnullになることはないためESLintの警告回避とする
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (a.text! > b.text!) {
      return 1;
    }
    return -1;
  });
};

/**
 * フィルタドロップダウン作成
 * @param {FilterDropdownProps} ドロップダウンのproperty
 * @returns フィルタドロップダウン
 */
const createFilterDropdown = ({
  setSelectedKeys, selectedKeys, filters, confirm, clearFilters,
}: FilterDropdownProps): JSX.Element => (
  <>
    <Menu
      className="ant-dropdown-menu-without-submenu file-list-table"
      onClick={({ key }) => {
        let newSelectedKeys;
        if (selectedKeys && selectedKeys.indexOf(key) >= 0) {
          // 選択済みのデータはチェックを外す
          newSelectedKeys = selectedKeys.filter((selectedKey) => selectedKey !== key);
        } else if (selectedKeys) {
          newSelectedKeys = selectedKeys.concat([key]);
        } else {
          newSelectedKeys = [key];
        }
        const stringSelectedKeys = newSelectedKeys
          .map((newSelectedKey) => String(newSelectedKey));
        if (setSelectedKeys) setSelectedKeys(stringSelectedKeys);
      }}
    >
      {filters && filters.map((filter) => {
        const { text, value } = filter;
        const isSelected = selectedKeys && selectedKeys.indexOf(value) >= 0;
        return (
          <Menu.Item key={value} className="file-list-item">
            <Checkbox checked={isSelected} />
            <span>{text}</span>
          </Menu.Item>
        );
      })}
    </Menu>
    <div className="ant-table-filter-dropdown-btns">
      <Button
        className="filter-list-button"
        type="link"
        onClick={confirm}
        size="small"
      >
        OK
      </Button>
      <Button
        className="filter-list-button clear"
        type="link"
        onClick={clearFilters}
        size="small"
      >
        Reset
      </Button>
    </div>
  </>
);

/**
 * 運転状況一覧テーブルコンポーネント
 */
export default function OperationalStatusTable(props: OperationalStatusTableProps): JSX.Element {
  const deleteEventId = useRef<number>();
  const [deleteEventDialogVisible, setDeleteEventDialogVisible] = useState(false);
  const [deleteEventDialogLoading, setDeleteEventDialogLoading] = useState(false);
  const {
    loading,
    operationalStatuses,
    handleChangeRowSelect,
    display,
  } = props;

  /**
   * 逸失原因削除ボタン押下イベント
   * @param {number} id 選択したイベントのID
   */
  const handleClickEventDelete = (id: number) => {
    deleteEventId.current = id;
    setDeleteEventDialogVisible(true);
  };

  /**
   * 削除確認ダイアログ 削除ボタン押下イベント
   */
  const handleClickDialogDelete = async () => {
    if (!deleteEventId.current) return;
    const requestItems = [{ id: deleteEventId.current }];
    setDeleteEventDialogLoading(true);

    try {
      await deleteEvent(EP_PATH_SOLAR_PROCESSES_OPERATIONAL_STATUS, requestItems);
      message.success(SUCCESS_DELETE_OPERATIONAL_STATUS);
    } catch (error) {
      message.error(ERROR_DELETE_OPERATIONAL_STATUS);
    } finally {
      setDeleteEventDialogLoading(false);
      setDeleteEventDialogVisible(false);
    }
  };

  /**
   * 削除確認ダイアログ キャンセルボタン押下イベント
   */
  const handleClickDialogCancel = () => {
    deleteEventId.current = undefined;
    setDeleteEventDialogVisible(false);
  };

  const isInformationExisted = operationalStatuses.length > 0;
  const columns: ColumnProps<PcsOperationalStatus>[] = [
    {
      title: 'PCS',
      dataIndex: 'pcsName',
      width: 270,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        operationalStatuses.forEach((elm) => uniqueData.add(elm?.pcsName));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => (
        result.pcsName ? result.pcsName.toLowerCase().includes((value as string).toLowerCase()) : false
      ),
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.pcsName, b.pcsName),
      render: (_, item: PcsOperationalStatus) => item.pcsName,
    },
    {
      title: '逸失原因',
      dataIndex: 'status',
      width: 250,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        operationalStatuses.forEach((elm) => uniqueData.add(elm?.status));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => (
        result.status ? result.status.toLowerCase().includes((value as string).toLowerCase()) : false
      ),
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.status, b.status),
      render: (_, item: PcsOperationalStatus) => item.status,
    },
    {
      title: '開始年月日',
      dataIndex: 'startDate',
      width: 150,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        operationalStatuses.forEach((elm) => uniqueData.add(elm?.startDate));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => (
        result.startDate ? result.startDate.toLowerCase().includes((value as string).toLowerCase()) : false
      ),
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.startDate, b.startDate),
      render: (_, item: PcsOperationalStatus) => item.startDate,
    },
    {
      title: '開始時刻',
      dataIndex: 'startTimeToDisplay',
      width: 150,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        operationalStatuses.forEach((elm) => uniqueData.add(elm?.startTimeToDisplay));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => (
        result.startTimeToDisplay ? result.startTimeToDisplay.toLowerCase().includes((value as string).toLowerCase()) : false
      ),
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.startTimeToDisplay, b.startTimeToDisplay),
      render: (_, item: PcsOperationalStatus) => item.startTimeToDisplay,
    },
    {
      title: '終了年月日',
      dataIndex: 'endDate',
      width: 150,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        operationalStatuses.forEach((elm) => uniqueData.add(elm?.endDate));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => (
        result.endDate ? result.endDate.toLowerCase().includes((value as string).toLowerCase()) : false
      ),
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.endDate, b.endDate),
      render: (_, item: PcsOperationalStatus) => item.endDate,
    },
    {
      title: '終了時刻',
      dataIndex: 'endTimeToDisplay',
      width: 150,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        operationalStatuses.forEach((elm) => uniqueData.add(elm?.endTimeToDisplay));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => (
        result.endTimeToDisplay ? result.endTimeToDisplay.toLowerCase().includes((value as string).toLowerCase()) : false
      ),
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.endTimeToDisplay, b.endTimeToDisplay),
      render: (_, item: PcsOperationalStatus) => item.endTimeToDisplay,
    },
    {
      title: '逸失発電量（kWh）',
      dataIndex: 'lostEnergy',
      width: 180,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        operationalStatuses.forEach((elm) => uniqueData.add(elm?.lostEnergy));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => (
        result.lostEnergy ? result.lostEnergy.toLowerCase().includes((value as string).toLowerCase()) : false
      ),
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedNumberItems(a.lostEnergy, b.lostEnergy),
      render: (_, item: PcsOperationalStatus) => item.lostEnergy,
    },
  ];

  // 削除タブ表示の場合はゴミ箱アイコンを画面に追加する
  if (display === '削除') {
    columns.push(
      {
        title: '',
        dataIndex: 'id',
        width: 70,
        fixed: 'right',
        render: (_, item: PcsOperationalStatus) => {
          const { id } = item;
          return (
            <Button
              type="link"
              onClick={() => handleClickEventDelete(id)}
            >
              <DeleteOutlined />
            </Button>
          );
        },
      },
    );
  }

  const rowSelection = {
    /**
     * チェックボックスクリック時のイベントハンドラ
     */
    onChange: (selectedRowKey: React.Key[]) => {
      // AntDesign.TableコンポーネントのrowKeyにidを指定しているためnumber固定
      const selectedIds = selectedRowKey.map((rowKey) => Number(rowKey));
      handleChangeRowSelect(selectedIds);
    },
  };

  return (
    <>
      <div style={{ margin: '10px' }}>
        <div>
          {display === '一覧' && (
            <Table<PcsOperationalStatus>
              loading={loading}
              rowKey="id"
              columns={columns}
              dataSource={operationalStatuses}
              pagination={false}
              scroll={{ y: 725 }}
              rowSelection={rowSelection}
            />
          )}
          {display === '削除' && (
            <Table<PcsOperationalStatus>
              loading={loading}
              rowKey="id"
              columns={columns}
              dataSource={operationalStatuses}
              pagination={false}
              scroll={{ y: 640 }}
            />
          )}
        </div>
      </div>
      <Modal
        title={WARNING_DELETE_FILE_TITLE}
        visible={deleteEventDialogVisible}
        confirmLoading={deleteEventDialogLoading}
        centered
        onOk={handleClickDialogDelete}
        okText={MODAL_BUTTON_TEXT.DELETE}
        okType="danger"
        onCancel={handleClickDialogCancel}
        cancelText={MODAL_BUTTON_TEXT.CANCEL}
      >
        {CONFIRM_DELETE_OPERATIONAL_STATUS}
      </Modal>
    </>
  );
}
