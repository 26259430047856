import { CogniteEvent, CursorResponse, EventFilterRequest } from '@cognite/sdk';

import BaseEvent from './BaseEvent';
import { postApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_SOLAR_EVENTS_LIST } from '../AWS/EndpointPath';

/**
 * 発電単価
 */
class SolarUnitPrice extends BaseEvent {
  /**
   * メンバ変数
   */
  get value(): string {
    if (!this.metadata || !this.metadata.value) return '';
    return this.metadata.value;
  }

  /**
   * 指定アセットの発電単価情報を取得する。
   * @param {number} assetId アセットID
   * @returns {Promise<SolarUnitPrice | undefined>} 発電単価情報
   */
  static async loadUnitPriceByAssetId(
    assetId: number,
  ): Promise<SolarUnitPrice | undefined> {
    const customEventFilter: EventFilterRequest = {
      filter: {
        type: 'unit_price',
        assetIds: [assetId],
      },
    };
    const loadUnitPrices = await postApiGateway<
      EventFilterRequest, CursorResponse<CogniteEvent[]>
    >(EP_PATH_SOLAR_EVENTS_LIST, customEventFilter);

    let loadUnitPrice;
    if (loadUnitPrices.items.length) {
      loadUnitPrice = new SolarUnitPrice(loadUnitPrices.items[0]);
    }

    return loadUnitPrice;
  }
}

export default SolarUnitPrice;
