/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-template */
/* eslint-disable react/sort-comp */
// eslint警告未対応
import React from 'react';
import {
  Typography, Button, Input, Form, message, Tooltip,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { getDlAssetId } from '../../../utils/storageCommon';
import { assetRetrieve, updateAsset } from '../../../utils/dataAccess';
import { validateLearningProjectName } from '../../../utils/learningModelManagement/learningModelValidator';
import { EP_PATH_LEARNING_PJ } from '../../../utils/AWS/EndpointPath';
import { ERROR_NO_AUTH_MESSAGE } from '../../../utils/messages';

const MAX_DISPLAY_NAME_LENGTH = 50;
const { Title } = Typography;

/** 学習プロジェクト名エリアコンテナ */
const ProjectTitleContainer = styled.div`
  margin: 10px;
  height: 40px;
  display: flex;
  align-items: center;
`;

/** ボタンラベル */
const BUTTON_TEXT = {
  OK: '変更',
  CANCEL: 'キャンセル',
};

/**
 * 学習プロジェクト名コンポーネントクラス
 */
const ProjectTitle = Form.create({ name: 'project_title_form' })(
  class extends React.Component {
    state = {
      /** 学習プロジェクト名 */
      projectTitle: null,
      /** 表示させる学習プロジェクト名 */
      projectTitleDisplay: null,
      /** 編集状態かを表すフラグ(true: 編集状態 / false: 編集中以外) */
      isEditing: false,
      saving: false,
    };

    /**
     * プロジェクト名を取得する。
     */
    async loadProjectTitle() {
      const { assetId } = this.props;
      const asset = await assetRetrieve({ endpoint: EP_PATH_LEARNING_PJ, id: assetId });
      const displayName = asset.name.length > MAX_DISPLAY_NAME_LENGTH
        ? asset.name.substr(0, MAX_DISPLAY_NAME_LENGTH - 1) + '...'
        : asset.name;
      return { full: asset.name, display: displayName };
    }

    /**
     * プロジェクトタイトルを作成する。
     */
    async createProjectTitle() {
      const loadProjectTitle = await this.loadProjectTitle();
      this.setState({
        projectTitle: loadProjectTitle.full,
        projectTitleDisplay: loadProjectTitle.display,
      });
    }

    /**
     * 学習プロジェクト名を更新する。
     * @param {number} assetId AssetID
     * @param {string} projectTitle 学習プロジェクト名
     * @returns {number} 更新後のAssetID
     */
    async updateProjectTitle(assetId, projectTitle) {
      const assetChange = {
        id: assetId,
        update: {
          name: { set: projectTitle },
        },
      };

      const updatedAssetId = await updateAsset(EP_PATH_LEARNING_PJ, EP_PATH_LEARNING_PJ, { items: [assetChange] });
      return updatedAssetId;
    }

    /**
     * 「編集」ボタン押下イベント
     * 学習プロジェクト名を編集可能状態にする。
     */
    handleClickEdit = () => {
      this.setState({ isEditing: true });
    };

    /**
     * 「変更」ボタン押下イベント
     * 入力チェックを行い、エラーが存在する場合はエラーメッセージを表示する。
     * - 必須チェック
     * - 桁数チェック(140文字以内)
     * - 存在チェック(同名のプロジェクトが存在する場合はエラー)
     * エラーが存在しない場合、学習プロジェクト名を更新する。
     * 更新後、学習プロジェクトリスト・学習プロジェクト名を再描画する。
     */
    handleClickSave = async () => {
      this.setState({ saving: true });
      const { form } = this.props;
      form.validateFields(async (_, values) => {
        const { projectTitle } = values;

        // 入力チェック
        const dlAssetId = await getDlAssetId();
        const validateResult = await validateLearningProjectName(projectTitle, dlAssetId);
        if (validateResult.hasError) {
          // 入力チェックエラーの場合、メッセージを表示
          message.error(validateResult.message);
          this.setState({ saving: false });
          return;
        }

        // Asset更新
        const { assetId } = this.props;
        await this.updateProjectTitle(assetId, projectTitle);

        // ProjectTitle再読み込み
        await this.createProjectTitle();
        // 親画面更新
        this.props.updatedFunction();

        this.setState({ isEditing: false, saving: false });
      });
    };

    /**
     * 「キャンセル」ボタン押下イベント
     * 学習プロジェクト名編集可能状態を解除する。
     */
    handleCliCkCancel = () => {
      this.setState({ isEditing: false });
    };

    /**
     * レンダリング直後に一度だけ呼ばれる。
     * 学習プロジェクト名を表示する。
     */
    async componentDidMount() {
      await this.createProjectTitle();
    }

    /**
     * コンポーネントが更新された直後に呼び出される。
     * 変更前後でAssetIDが更新された場合、学習プロジェクト名を再表示する。
     * @param {Object} prevProps 変更前のProps
     */
    async componentDidUpdate(prevProps) {
      const { assetId } = this.props;
      if (prevProps.assetId !== assetId) {
        // AssetIDが変更された場合、プロジェクトタイトル再読み込み
        await this.createProjectTitle();
        this.setState({ isEditing: false });
      }
    }

    /**
     * 学習プロジェクト名をレンダリングする。
     */
    render() {
      const {
        projectTitle, projectTitleDisplay, isEditing, saving,
      } = this.state;
      const { form: { getFieldDecorator }, isLearningPjEditDisabled } = this.props;

      return (
        <ProjectTitleContainer>
          {
            isEditing
              ? (
                <Form layout="inline">
                  <Form.Item>
                    {getFieldDecorator('projectTitle', { initialValue: projectTitle })(<Input style={{ width: 1100 }} />)}
                  </Form.Item>
                  <Form.Item>
                    <Button onClick={this.handleCliCkCancel}>{BUTTON_TEXT.CANCEL}</Button>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" onClick={this.handleClickSave} loading={saving}>{BUTTON_TEXT.OK}</Button>
                  </Form.Item>
                </Form>
              )
              : (
                <>
                  <Title level={3} style={{ marginBottom: '0px' }}>{projectTitleDisplay}</Title>
                  <Tooltip title={isLearningPjEditDisabled && ERROR_NO_AUTH_MESSAGE}>
                    <Button type="link" onClick={this.handleClickEdit} disabled={isLearningPjEditDisabled}>
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                </>
              )
          }
        </ProjectTitleContainer>
      );
    }
  },
);

export default ProjectTitle;
