/* eslint-disable react-hooks/exhaustive-deps */
// eslint警告未対応
import React, { useEffect } from 'react';
import { message } from 'antd';
import Queue from 'promise-queue';
import { ColumnChart, CountData, CountsDataMap } from './ColumnChart';
import ManagedFacility from '../../../utils/Asset/ManagedFacility';
import { ERROR_LOAD_MANAGED_FACILITY_TIMESERIES } from '../../../utils/messages';

const SYSTEM_DATA = {
  timeseries: 'タイムシリーズ',
} as const;

const DEFAULT_COUNT_DATA = [
  { legend: SYSTEM_DATA.timeseries },
] as CountData[];

/**
 * タイムシリーズ数積み上げ棒グラフコンポーネント
 */
export const TimeseriesColumnChart: React.FC<{
  managedFacilities: ManagedFacility[]
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [loading, setLoading] = React.useState<boolean>(false);
  const [countsData, setCountsData] = React.useState<CountData[]>(DEFAULT_COUNT_DATA);
  const [countsDataMap, setCountsDataMap] = React.useState<CountsDataMap>({});

  const { managedFacilities } = props;

  const maxConcurrent = 16; // 並列実行するタスク数
  const queue = new Queue(maxConcurrent);

  /*
   * イベントハンドラ
   */
  useEffect(
    () => {
      if (managedFacilities.length === 0) {
        setCountsData(DEFAULT_COUNT_DATA);
        return;
      }
      setLoading(true);
    },
    [managedFacilities],
  );

  useEffect(
    () => {
      if (!loading) return undefined;

      let canceled = false;
      (async () => {
        /*
         * メソッド
         */

        /**
         * 管理設備に紐づくタイムシリーズを集計する
         * @param {ManagedFacility} managedFacility 管理設備
         * @returns {CountsDataMap} タイムシリーズ数
         */
        const countTimeseriesOf = async (
          managedFacility: ManagedFacility,
        ): Promise<CountsDataMap> => {
          let countsTimeseriesData: CountData[] = [];

          try {
            const timeseriesAggregates = await managedFacility.countTimeseries();

            countsTimeseriesData.push({
              name: managedFacility.name,
              legend: 'タイムシリーズ',
              value: timeseriesAggregates,
            });
          } catch (exception) {
            countsTimeseriesData = [];
            if (!canceled) {
              setLoading(false);
              message.error(ERROR_LOAD_MANAGED_FACILITY_TIMESERIES);
            }
          }

          const result: CountsDataMap = {};
          const key = String(managedFacility.id);
          result[key] = countsTimeseriesData;

          return result;
        };

        /*
         * メイン処理
         */
        const promises: Promise<CountsDataMap>[] = [];
        managedFacilities.forEach((managedFacility) => {
          const key = String(managedFacility.id);
          if (!countsDataMap[key]) {
            // 未集計の場合
            promises.push(queue.add(() => countTimeseriesOf(managedFacility)));
          }
        });
        const results = await Promise.all(promises);

        // countsDataMapに対し、新たに集計した情報を追加したオブジェクト生成
        const newCountsDataMap = { ...countsDataMap };
        results.forEach((result) => {
          const keys = Object.keys(result);
          keys.forEach((key) => {
            newCountsDataMap[key] = result[key];
          });
        });

        const keys = managedFacilities.map((managedFacility) => String(managedFacility.id));
        const newCountsData: CountData[] = [];
        keys.sort().forEach((key) => {
          newCountsData.push(...newCountsDataMap[key]);
        });

        // 管理設備名に重複がある場合は、名前を変更する
        for (let i = 0; i < newCountsData.length - 1; i++) {
          for (let j = i + 1; j < newCountsData.length; j++) {
            if (newCountsData[i].name === newCountsData[j].name
              && newCountsData[i].legend === newCountsData[j].legend) {
              newCountsData[j].name = newCountsData[j].name.concat(' ');
            }
          }
        }
        if (!canceled) {
          setLoading(false);
          setCountsDataMap(newCountsDataMap);
          setCountsData(newCountsData);
        }
      })();

      return () => { canceled = true; };
    },
    [managedFacilities, countsDataMap, loading, queue],
  );

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  return (
    <ColumnChart loading={loading} countsData={countsData} />
  );
};
