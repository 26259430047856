import React, { forwardRef, useImperativeHandle } from 'react';
import * as THREE from 'three';
import {
  Modal,
  Input,
  Button,
  Popconfirm,
  Row,
  Col,
  message,
  Radio,
  Tooltip,
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';

import Facility from '../../../utils/Asset/Facility';
import BaseFile from '../../../utils/File/BaseFile';
import { Scan } from '../../../utils/File/ScansJsonFile';
import ManagedFacility from '../../../utils/Asset/ManagedFacility';

import FacilityTreeSelect from '../Asset/FacilityTreeSelect';
import FileList from '../File/FileList';
import FileContent from '../File/FileContent';
import { ResourceType } from '../../../utils/common/SDFDataType';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../../utils/common/Authentication';
import { ERROR_NO_AUTH_MESSAGE, ERROR_SAVE_INSPECTION_DETAILS } from '../../../utils/messages';

import './ScanEditForm.css';

const FormItem = {
  TargetFacility: { name: 'targetFacility', label: '対象設備' },
  TargetFile: { name: 'targetFile', label: '対象ファイル' },
  DeficientPresence: { name: 'deficientPresence', label: '不具合有無' },
  RepairIsNeeded: { name: 'repairIsNeeded', label: '措置要否' },
  Repaired: { name: 'repaired', label: '措置状況' },
  Comment: { name: 'comment', label: 'コメント' },
} as const;

/** Props */
interface ScanEditFormProps extends FormComponentProps {
  visible: boolean,
  target?: Scan, // 点検内容のオブジェクト(登録済みの点検内容を選択した場合、格納されている)
  managedFacility?: ManagedFacility,
  targetFacility?: Facility,
  targetFile?: BaseFile,
  point?: THREE.Vector3,
  editable?: boolean,
  onAdd: (scan: Scan) => void,
  onUpdate: (scan: Scan) => void,
  onDelete: (scan: Scan) => void,
  onCancel: () => void,
}

type Ref = FormComponentProps;

const ScanEditForm: React.FC<ScanEditFormProps> = forwardRef<Ref, ScanEditFormProps>(
  (props: ScanEditFormProps, ref) => {
    const { form } = props;
    const {
      getFieldValue,
      setFields,
      resetFields,
      validateFields,
      setFieldsValue,
    } = form;
    useImperativeHandle(ref, () => ({ form }));
    /*
     * 変数/定数定義
     */
    const [saving, setSaving] = React.useState<boolean>(false);
    const [selectedFacility, setSelectedFacility] = React.useState<Facility>();
    const [selectedFile, setSelectedFile] = React.useState<BaseFile>();
    const [targetFacilityId, setTargetFacilityId] = React.useState<number>();
    const [targetFileId, setTargetFileId] = React.useState<number>();
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = React.useState<boolean>(true);
    const [isCancelButtonDisabled, setIsCancelButtonDisabled] = React.useState<boolean>(true);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = React.useState<boolean>(true);
    const [isUpdateScanDisabled, setIsUpdateScanDisabled] = React.useState<boolean>(true);
    const [resourceType, setResourceType] = React.useState<ResourceType>(ResourceType.Facility);

    const {
      visible,
      target,
      managedFacility,
      targetFacility,
      targetFile,
      point,
      editable,
      onAdd: onCreating,
      onUpdate: onUpdating,
    } = props;

    /*
     * イベントハンドラ
     */
    React.useEffect(() => {
      const {
        INSPECTION_RESULTS_LIST: {
          INSPECTION_CONTENT_REGISTRATION_MODAL_RC_DELETE_BUTTON,
          INSPECTION_CONTENT_REGISTRATION_MODAL_RC_CANCEL,
          INSPECTION_CONTENT_REGISTRATION_MODAL_RC_SAVE,
          INSPECTION_CONTENT_REGISTRATION_MODAL_RC_REGISTRATION_ITEMS,
        },
      } = AUTHENTICATION_TYPE_MATRIX;

      (async () => {
        setIsDeleteButtonDisabled(
          !await containsUIAuthType(INSPECTION_CONTENT_REGISTRATION_MODAL_RC_DELETE_BUTTON),
        );
        setIsCancelButtonDisabled(
          !await containsUIAuthType(INSPECTION_CONTENT_REGISTRATION_MODAL_RC_CANCEL),
        );
        setIsSaveButtonDisabled(
          !await containsUIAuthType(INSPECTION_CONTENT_REGISTRATION_MODAL_RC_SAVE),
        );
        setIsUpdateScanDisabled(
          !await containsUIAuthType(INSPECTION_CONTENT_REGISTRATION_MODAL_RC_REGISTRATION_ITEMS),
        );
      })();
    }, []);

    // 対象の点検内容が変更されたときの処理
    // 対象の点検内容があれば、その値をフォームに設定する
    // なければ、フォームをリセットする
    React.useEffect(
      () => {
        if (!target) {
          resetFields();
        }

        setTargetFacilityId(target?.targetFacilityId);
        setTargetFileId(target?.targetFileId);
      },
      [resetFields, target],
    );

    // 外部から指定された設備が変更されたときの処理
    // 設備が指定されていなければ何もしない
    // 対象の点検内容が指定されていれば何もしない
    // 上記以外であれば、指定された設備のIDをフォームに設定する
    React.useEffect(
      () => {
        if (!targetFacility) return;

        if (target) return;

        if (targetFacility) {
          setFields([{
            name: FormItem.TargetFacility.name,
            value: targetFacility.id,
          }]);
        }
      },
      [setFields, target, targetFacility],
    );

    // 外部から指定されたファイルが変更されたときの処理
    // ファイルが指定されていなければ何もしない
    // 対象の点検内容が指定されていれば何もしない
    // 上記以外であれば、指定されたファイルのIDをフォームに設定する
    React.useEffect(
      () => {
        if (!targetFile) return;

        if (target) return;

        setFields([{
          name: FormItem.TargetFile.name,
          value: targetFile.id,
        }]);
      },
      [setFields, target, targetFile],
    );

    /** 保存ボタン押下時に動作 */
    React.useEffect(
      () => {
        if (!saving) return () => { /* 何もしない */ };

        let canceled = false;
        (async () => {
          let newScan: Scan | null = null;

          try {
            await validateFields();

            const gotTargetFacilityId = getFieldValue(FormItem.TargetFacility.name);
            const gotTargetFileId = getFieldValue(FormItem.TargetFile.name);
            const deficientPresence = getFieldValue(FormItem.DeficientPresence.name);
            let repairIsNeeded = false;
            let repaired = false;
            if (deficientPresence) {
              repairIsNeeded = getFieldValue(FormItem.RepairIsNeeded.name);
              if (repairIsNeeded) {
                repaired = getFieldValue(FormItem.Repaired.name);
              }
            }
            const comment = getFieldValue(FormItem.Comment.name);

            const scan: Scan = {
              id: target ? target.id : 0,
              targetFacilityId: gotTargetFacilityId,
              targetFileId: gotTargetFileId,
              damaged: deficientPresence,
              repairIsNeeded,
              repaired,
              comment,
              point: target ? target.point : point || new THREE.Vector3(0, 0, 0),
            };

            newScan = scan;
          } catch (exception) {
            if (!canceled) {
              message.error(ERROR_SAVE_INSPECTION_DETAILS);
            }
          }

          if (!canceled) {
            setSaving(false);

            if (newScan) {
              if (target) {
                onUpdating(newScan);
              } else {
                onCreating(newScan);
              }
            }
          }
        })();

        return () => { canceled = true; };
      },
      [target, point, saving, onCreating, onUpdating, getFieldValue, validateFields],
    );

    // 対象設備変更時に対象ファイルを初期化する
    React.useEffect(
      () => {
        // 登録済み点検内容を初回表示する場合も通るため、FileListのselectedFile更新後に初期化
        if (target?.targetFacilityId !== selectedFacility?.id && selectedFile) {
          setResourceType(ResourceType.Facility);
          setTargetFileId(undefined);
          setSelectedFile(undefined);
          setFieldsValue({ [FormItem.TargetFile.name]: undefined });
        }
      },
      // selectedFacilityの変化だけ検出する
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selectedFacility],
    );

    // 対象設備変更時に対象ファイルをresetFields、setFieldsValueで初期化を行うと、
    // ファイル選択してもFieldValueが更新されないためFieldValueを設定する
    React.useEffect(
      () => {
        if (getFieldValue(FormItem.TargetFile.name) !== selectedFile?.id) {
          setFieldsValue({ [FormItem.TargetFile.name]: selectedFile?.id });
        }
      },
      // selectedFileの変化だけ検出する
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selectedFile],
    );

    /*
     * メソッド
     */
    /*
     * 画面描画
     */
    const footer = (
      <div className="scan-edit-form-footer">
        {
          target && (
            <div className="delete-button">
              <Popconfirm
                title="本当に削除してもよろしいですか？"
                onConfirm={() => target && props.onDelete(target)}
                okText="はい"
                cancelText="いいえ"
                disabled={isDeleteButtonDisabled}
              >
                <Tooltip title={isDeleteButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                  <Button type="danger" disabled={saving || isDeleteButtonDisabled}>
                    削除
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
          )
        }
        <div>
          <Tooltip title={isCancelButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
            <Button
              type="default"
              disabled={isCancelButtonDisabled}
              onClick={() => {
                form.resetFields();
                props.onCancel();
              }}
            >
              キャンセル
            </Button>
          </Tooltip>
          <Tooltip title={isSaveButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
            <Button
              type="primary"
              onClick={() => setSaving(true)}
              loading={saving}
              disabled={isSaveButtonDisabled}
            >
              保存
            </Button>
          </Tooltip>
        </div>
      </div>
    );

    return (
      <Modal
        visible={visible}
        title="点検内容"
        footer={editable ? footer : <></>}
        closable={!editable}
        onCancel={editable ? undefined : props.onCancel}
        width={1300}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <Tooltip title={isUpdateScanDisabled && ERROR_NO_AUTH_MESSAGE}>
                <Form.Item
                  label={FormItem.TargetFacility.label}
                  required
                >
                  {form.getFieldDecorator(FormItem.TargetFacility.name, {
                    rules: [
                      { required: true, message: '対象設備を選択してください。' },
                    ],
                    initialValue: targetFacilityId,
                  })(
                    <FacilityTreeSelect
                      root={managedFacility}
                      isTreeSelectDisabled={isUpdateScanDisabled}
                      onChange={(value) => setTargetFacilityId(value)}
                      onSelect={(facility) => setSelectedFacility(facility)}
                    />,
                  )}
                </Form.Item>
              </Tooltip>

              <Tooltip title={isUpdateScanDisabled && ERROR_NO_AUTH_MESSAGE}>
                <Form.Item
                  label={FormItem.TargetFile.label}
                  required
                >
                  {form.getFieldDecorator(FormItem.TargetFile.name, {
                    rules: [
                      { required: true, message: '対象ファイルを選択してください。' },
                    ],
                    initialValue: targetFileId,
                  })(
                    <FileList
                      facility={selectedFacility}
                      isFileListDisabled={isUpdateScanDisabled}
                      onSelectFile={(type, file) => {
                        setTargetFileId(file?.id);
                        setResourceType(type);
                        setSelectedFile(file);
                      }}
                    />,
                  )}
                </Form.Item>
              </Tooltip>

              <Tooltip title={isUpdateScanDisabled && ERROR_NO_AUTH_MESSAGE}>
                <Form.Item
                  label={FormItem.DeficientPresence.label}
                >
                  {form.getFieldDecorator(FormItem.DeficientPresence.name, {
                    rules: [
                      { required: true, message: '不具合有無を選択してください。' },
                    ],
                    initialValue: target ? target.damaged : undefined,
                  })(
                    <Radio.Group disabled={isUpdateScanDisabled}>
                      <Radio value={false}>なし</Radio>
                      <Radio value>あり</Radio>
                    </Radio.Group>,
                  )}
                </Form.Item>
              </Tooltip>

              <Tooltip title={isUpdateScanDisabled && ERROR_NO_AUTH_MESSAGE}>
                <Form.Item
                  label={FormItem.RepairIsNeeded.label}
                >
                  {form.getFieldDecorator(FormItem.RepairIsNeeded.name, {
                    rules: [
                      {
                        required: form.getFieldValue(FormItem.DeficientPresence.name),
                        message: '措置要否を選択してください。',
                      },
                    ],
                    initialValue: target ? target.repairIsNeeded : undefined,
                  })(
                    <Radio.Group
                      disabled={
                        !form.getFieldValue(FormItem.DeficientPresence.name) || isUpdateScanDisabled
                      }
                    >
                      <Radio value={false}>不要</Radio>
                      <Radio value>必要</Radio>
                    </Radio.Group>,
                  )}
                </Form.Item>
              </Tooltip>

              <Tooltip title={isUpdateScanDisabled && ERROR_NO_AUTH_MESSAGE}>
                <Form.Item
                  label={FormItem.Repaired.label}
                >
                  {form.getFieldDecorator(FormItem.Repaired.name, {
                    rules: [
                      {
                        required: form.getFieldValue(FormItem.RepairIsNeeded.name),
                        message: '措置状況を選択してください。',
                      },
                    ],
                    initialValue: target ? target.repaired : undefined,
                  })(
                    <Radio.Group
                      disabled={
                        (
                          !form.getFieldValue(FormItem.RepairIsNeeded.name)
                          || !form.getFieldValue(FormItem.DeficientPresence.name)
                        ) || isUpdateScanDisabled
                      }
                    >
                      <Radio value={false}>未完</Radio>
                      <Radio value>完了</Radio>
                    </Radio.Group>,
                  )}
                </Form.Item>
              </Tooltip>

              <Tooltip title={isUpdateScanDisabled && ERROR_NO_AUTH_MESSAGE}>
                <Form.Item
                  label={FormItem.Comment.label}
                >
                  {form.getFieldDecorator(FormItem.Comment.name, {
                    initialValue: target ? target.comment : undefined,
                  })(<Input.TextArea disabled={isUpdateScanDisabled} />)}
                </Form.Item>
              </Tooltip>
            </Form>
          </Col>
          <Col span={16}>
            {
              selectedFile
                ? <FileContent target={selectedFile} resourceType={resourceType} />
                : <div>ファイルを選択してください。</div>
            }
          </Col>
        </Row>
      </Modal>
    );
  },
);

const EnhancedScanEditForm = Form.create<ScanEditFormProps>()(ScanEditForm);

export default EnhancedScanEditForm;
