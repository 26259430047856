/* eslint-disable class-methods-use-this */
// eslint警告未対応
import React from 'react';
import { Modal, Form, Input } from 'antd';

import { getDlAssetId, getStorageDataSetId } from '../../../utils/storageCommon';
import { getAllAssets, createAsset } from '../../../utils/dataAccess';
import { validateLearningProjectName } from '../../../utils/learningModelManagement/learningModelValidator';
import { nextOrder } from '../../../utils/common';
import { EP_PATH_LEARNING_PJ, EP_PATH_LEARNING_PJ_LIST } from '../../../utils/AWS/EndpointPath';

/** ダイアログのボタンラベル */
const MODAL_BUTTON_TEXT = {
  OK: '作成',
  CANCEL: 'キャンセル',
};

/** フォーム項目のラベル */
const FORM_ITEM_LABELS = {
  PROJECT: '学習プロジェクト名',
};

/**
 * プロジェクト作成ダイアログコンポーネントクラス
 */
const CreateProjectDialog = Form.create({ name: 'create_project_modal' })(

  class extends React.Component {
    state = {
      /** プロジェクト追加処理フラグ true: 追加処理中 / false: 処理中以外 */
      confirmLoading: false,
    };

    /**
     * プロジェクトを作成する。
     * @param {string} name プロジェクト名
     * @param {number} parentId 親AssetID
     * @param {number} dataSetId データセットID
     * @param {number} order 表示順序
     */
    createProject = async (name, parentId, dataSetId, order) => {
      const asset = {
        name, parentId, dataSetId, metadata: { order },
      };
      await createAsset(EP_PATH_LEARNING_PJ, { items: [asset] });
    };

    /**
     * 「作成」ボタン押下イベント
     * 入力チェックを行い、エラーが存在する場合はエラーメッセージを表示する。
     * - 必須チェック
     * - 桁数チェック(140文字以内)
     * - 存在チェック(同名のプロジェクトが存在する場合はエラー)
     * エラーが存在しない場合、プロジェクトを登録する。
     */
    handleClickCreateProjectOk = async () => {
      this.setState({ confirmLoading: true });

      const { form } = this.props;
      await form.validateFields(async (_, values) => {
        const { projectName } = values;

        // 入力チェック
        const dlAssetId = await getDlAssetId();
        const validateResult = await validateLearningProjectName(projectName, dlAssetId);
        if (validateResult.hasError) {
          // 入力チェックエラーの場合、メッセージを表示
          form.setFields({
            projectName: { value: projectName, errors: [new Error(validateResult.message)] },
          });
          this.setState({ confirmLoading: false });
          return;
        }

        // プロジェクト登録
        const sameParentProjects = await getAllAssets(EP_PATH_LEARNING_PJ_LIST, { parentIds: [dlAssetId] });
        await this.createProject(projectName, dlAssetId, getStorageDataSetId(), nextOrder(sameParentProjects));

        this.props.modalOk();
      });
    };

    /**
     * 「キャンセル」ボタン押下イベント
     */
    handleClickCreateProjectCancel = () => {
      this.props.modalCancel();
    };

    /**
     * プロジェクト作成ダイアログをレンダリングする。
     */
    render() {
      const { confirmLoading } = this.state;
      const { visible, title, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          title={title}
          visible={visible}
          onCancel={this.handleClickCreateProjectCancel}
          onOk={this.handleClickCreateProjectOk}
          centered
          confirmLoading={confirmLoading}
          okText={MODAL_BUTTON_TEXT.OK}
          cancelText={MODAL_BUTTON_TEXT.CANCEL}
        >
          <Form layout="vertical">
            <Form.Item label={FORM_ITEM_LABELS.PROJECT}>
              {getFieldDecorator('projectName')(<Input />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);

export default CreateProjectDialog;
