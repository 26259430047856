import {
  Asset,
  AssetListScope,
  CogniteInternalId,
} from '@cognite/sdk';

import { loadAllOMEquipmentFromCDFByScope } from './OMEquipmentAsset';
import OMAlertEvent from '../Event/OMAlertEvent';
import OMMeasurement, { MeasurementType } from '../Timeseries/OMMeasurement/OMMeasurement';

class OMPcsMeasurement {
  /**
   * クラスメソッド
   */

  /**
   * PCS一覧で必要な最新の計測値を取得する
   * @param {OMMeasurement[]} latestMeasurements エリア配下の全ての計測値（最新値）
   * @param {string} assetName PCSアセットの名称
   * @param {string} type 取得する計測値の種別
   * @returns {number} 計測値（最新値）
   */
  static getPcsMeasurementValue(latestMeasurements: OMMeasurement[], assetName: string, type: string): number {
    const measurementValues = latestMeasurements.filter(({ pcsName, measurementType }) => assetName === pcsName && measurementType === type)
      .map(({ datapoints }) => (
        datapoints.length ? datapoints[0].value : 0
      ));

    // 合計値を取得
    const pcsMeasurementValue = measurementValues.reduce((total, currentValue) => total + currentValue, 0);
    const measurementCount = measurementValues.length;
    return MeasurementType.DcVoltage === type && measurementCount > 1 ? pcsMeasurementValue / measurementCount : pcsMeasurementValue;
  }

  /**
   * PCSごとの警報発生情報、計測値を取得する
   * @param {CogniteInternalId} areaAssetId エリアアセットID
   * @returns {Promise<OMPcsMeasurement[]>} PCS一覧画面用データリスト
   */
  static async loadPcsMeasurements(areaAssetId: CogniteInternalId): Promise<OMPcsMeasurement[]> {
    // エリア配下のPCSアセットを全件取得
    const pcsAssetScope: AssetListScope = {
      filter: {
        assetSubtreeIds: [{ id: areaAssetId }],
        metadata: { o_m_asset_level: 'pcs' },
      },
    };
    const pcsAssets = await loadAllOMEquipmentFromCDFByScope(pcsAssetScope);
    // エリア配下の警報（現行）イベントを全件取得
    const allAlertEvents = await OMAlertEvent.loadAllLatestAlertEvents(areaAssetId);
    // エリア配下の計測値（最新値）を全件取得
    const timeseriesHolderAssetScope: AssetListScope = {
      filter: {
        assetSubtreeIds: [{ id: areaAssetId }],
        metadata: { o_m_asset_level: 'ts_holder' },
      },
    };
    const allTimeseriesHolderAssetIds = await loadAllOMEquipmentFromCDFByScope(timeseriesHolderAssetScope)
      .then((timeseries) => timeseries.map(({ id }) => id));
    const allLatestMeasurements = await OMMeasurement.loadAllSelectedLatestMeasurements(allTimeseriesHolderAssetIds);

    const pcsMeasurementList = pcsAssets.map((pcsAsset) => {
      // PCS配下の警報情報に発生中のものが存在するか判定
      const pcsHappening = allAlertEvents.some(({ assetIds, happening }) => assetIds?.includes(pcsAsset.id) && happening);

      // PCSに属する計測値を取得（最新値の場合はdatapointが1つのみのため、最初の要素を取得）
      const acPower = OMPcsMeasurement.getPcsMeasurementValue(allLatestMeasurements, pcsAsset.name, MeasurementType.AcPower);
      const acCurrent = OMPcsMeasurement.getPcsMeasurementValue(allLatestMeasurements, pcsAsset.name, MeasurementType.AcCurrent);
      const dcVoltage = OMPcsMeasurement.getPcsMeasurementValue(allLatestMeasurements, pcsAsset.name, MeasurementType.DcVoltage);
      const dcCurrent = OMPcsMeasurement.getPcsMeasurementValue(allLatestMeasurements, pcsAsset.name, MeasurementType.DcCurrent);

      return new OMPcsMeasurement(
        pcsAsset,
        pcsHappening,
        acPower,
        acCurrent,
        dcVoltage,
        dcCurrent,
      );
    });

    return pcsMeasurementList;
  }

  pcsAsset: Asset;

  happening: boolean;

  acPower: number;

  acCurrent: number;

  dcVoltage: number;

  dcCurrent: number;

  get id(): number {
    return this.pcsAsset.id ?? 0;
  }

  get pcsName(): string {
    return this.pcsAsset.name ?? '';
  }

  get sortKey(): string {
    if (!this.pcsAsset.metadata || !this.pcsAsset.metadata.sort_key) return '';
    return this.pcsAsset.metadata.sort_key;
  }

  get feeder(): number {
    if (!this.pcsAsset.metadata || !this.pcsAsset.metadata.feeder) return 0;
    return Number(this.pcsAsset.metadata.feeder);
  }

  constructor(
    pcsAsset: Asset,
    happening: boolean,
    acPower: number,
    acCurrent: number,
    dcVoltage: number,
    dcCurrent: number,
  ) {
    this.pcsAsset = pcsAsset;
    this.happening = happening;
    this.acPower = acPower ?? 0;
    this.acCurrent = acCurrent ?? 0;
    this.dcVoltage = dcVoltage ?? 0;
    this.dcCurrent = dcCurrent ?? 0;
  }
}

export default OMPcsMeasurement;
