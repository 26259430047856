import React from 'react';

import { Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table';

import BaseAsset from '../../../utils/Asset/BaseAsset';

interface AssetTableProps<T> {
  loading: boolean,
  assets: T[],
  checkedIds?: number[],
  height?: number,
  onCheckAll?: (selected: boolean) => void,
  onCheck?: (asset: T, selected: boolean, selectedAssets: T[]) => void,
}

function AssetTable<T extends BaseAsset>(props: AssetTableProps<T>): JSX.Element {
  /*
   * 変数/定数定義
   */

  const {
    loading,
    assets,
    checkedIds,
    height,
  } = props;

  /*
   * イベントハンドラ
   */

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  const checkedIdsLength = checkedIds && checkedIds.length > 0 ? `${checkedIds.length} / ` : '0 / ';
  const count = `${checkedIdsLength}${assets.length}`;

  const columns = [
    {
      title: `名前（${count}）`,
      dataIndex: 'name',
    },
  ];

  const rowSelection: TableRowSelection<T> = {
    selectedRowKeys: checkedIds,
    onSelectAll: (selected: boolean) => {
      if (props.onCheckAll) props.onCheckAll(selected);
    },
    onSelect: (asset, selected, selectedAssets) => {
      if (props.onCheck) props.onCheck(asset, selected, selectedAssets as T[]);
    },
  };

  return (
    <>
      <Table<T>
        loading={loading}
        columns={columns}
        rowKey="id"
        dataSource={assets}
        rowSelection={checkedIds ? rowSelection : undefined}
        pagination={false}
        scroll={{ y: height || 200 }}
        size="small"
      />
    </>
  );
}

export default AssetTable;
