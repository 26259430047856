/**
 * Timerクラス
 */
class Timer {
  /*
   * メンバ変数
   */
  /** 処理実行待ち時間(ミリ秒) */
  time: number;

  /** タイムアウトかを表すフラグ */
  isTimeOut: boolean;

  /** タイマー識別子 */
  timeoutID: number;

  /*
   * コンストラクタ
   */
  constructor(time: number) {
    this.time = time;
    this.isTimeOut = false;

    this.timeoutID = window.setTimeout(() => {
      this.isTimeOut = true;
    }, this.time);
  }

  /**
   * タイマーをリセットする
   */
  reset(): void {
    this.isTimeOut = false;
    this.clear();
    this.timeoutID = window.setTimeout(() => {
      this.isTimeOut = true;
    }, this.time);
  }

  /**
   * タイマーを解除する
   */
  clear(): void {
    window.clearTimeout(this.timeoutID);
  }
}

export { Timer };
