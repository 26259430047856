import React from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Icon,
  Input,
  Menu,
  Table,
} from 'antd';
import { ColumnFilterItem, ColumnProps, FilterDropdownProps } from 'antd/lib/table';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import locale from 'antd/es/date-picker/locale/ja_JP';
import moment from 'moment';

import './SearchedEventTable.css';
import ElectricEnergyStatus from '../../utils/Event/ElectricEnergyStatus';
import { ELECTRIC_ENERGY_STATUS } from './parts/ElectricEnergyStatusSelect';

interface SearchedEventListProps {
  loading: boolean;
  searchedEventList: ElectricEnergyStatus[];
}

const { RangePicker } = DatePicker;

/** 日付フォーマット */
const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

/**
 * ステータスの表示名変換
 * @param {string | undefined} status ステータス番号
 */
const statusChange = (status: string | undefined) => {
  const result = ELECTRIC_ENERGY_STATUS.find((el) => String(el.value) === status);
  return result?.displayName;
};

/**
 * 検索済みイベント一覧テーブル
 * @param {SearchedEventListProps} props プロパティ
 * @returns 検索済みイベント一覧テーブル
 */
const SearchedEventTable: React.FC<SearchedEventListProps> = (
  props: SearchedEventListProps,
) => {
  const {
    loading,
    searchedEventList,
  } = props;

  /**
   * フィルタアイテムリスト作成
   * @param {Set<string | undefined>} uniqueEventData 一意のイベント情報
   * @returns フィルタアイテムリスト
   */
  const createEventTableFilter = (uniqueEventData: Set<string | undefined>): ColumnFilterItem[] => {
    const eventDataTypeFilter: ColumnFilterItem[] = [];
    // ELECTRIC_ENERGY_STATUSの定義順にフィルタを作成
    ELECTRIC_ENERGY_STATUS.forEach((el) => {
      uniqueEventData.forEach((data) => {
        if (el.displayName === data) {
          eventDataTypeFilter.push({ text: el.displayName, value: el.displayName });
        }
      });
    });
    return eventDataTypeFilter;
  };

  /**
   * フィルタドロップダウン作成
   * @param {FilterDropdownProps} ドロップダウンのproperty
   * @returns フィルタドロップダウン
   */
  const createEventFilterDropdown = ({
    setSelectedKeys, selectedKeys, filters, confirm, clearFilters,
  }: FilterDropdownProps): JSX.Element => (
    <>
      <Menu
        className="ant-dropdown-menu-without-submenu data-list-table"
        onClick={({ key }) => {
          let newSelectedKeys;
          if (selectedKeys && selectedKeys.indexOf(key) >= 0) {
            // 選択済みのデータはチェックを外す
            newSelectedKeys = selectedKeys.filter((selectedKey) => selectedKey !== key);
          } else if (selectedKeys) {
            newSelectedKeys = selectedKeys.concat([key]);
          } else {
            newSelectedKeys = [key];
          }
          const stringSelectedKeys = newSelectedKeys
            .map((newSelectedKey) => String(newSelectedKey));
          if (setSelectedKeys) setSelectedKeys(stringSelectedKeys);
        }}
      >
        {filters && filters.map((filter) => {
          const { text, value } = filter;
          const isSelected = selectedKeys && selectedKeys.indexOf(value) >= 0;
          return (
            <Menu.Item key={value} className="data-list-item">
              <Checkbox checked={isSelected} />
              <span>{text}</span>
            </Menu.Item>
          );
        })}
      </Menu>
      <div className="ant-table-filter-dropdown-btns">
        <Button
          className="filter-list-button"
          type="link"
          onClick={confirm}
          size="small"
        >
          OK
        </Button>
        <Button
          className="filter-list-button clear"
          type="link"
          onClick={clearFilters}
          size="small"
        >
          Reset
        </Button>
      </div>
    </>
  );

  /**
   * 虫眼鏡アイコン作成
   * @param {boolean} filtered フィルタON/OFF
   * @returns {JSX.Element} フィルタアイコン
   */
  const filterSearchIcon = (filtered: boolean): JSX.Element => (
    <Icon type="search" style={{ color: filtered ? '1890ff' : undefined }} />
  );

  const isSearchedEventListExisted = searchedEventList.length > 0;
  const columns: ColumnProps<ElectricEnergyStatus>[] = [
    {
      title: '発生日時',
      dataIndex: 'startTime',
      width: 170,
      filterIcon: filterSearchIcon,
      filterDropdown: isSearchedEventListExisted
        ? ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => {
          let rangePickerValue: RangePickerValue = [];
          if (selectedKeys && selectedKeys[0]) {
            const selectedKey = JSON.parse(String(selectedKeys[0]));
            const { startDate, endDate } = selectedKey;
            rangePickerValue = [moment(startDate), moment(endDate)];
          }

          return (
            <div className="searched-event-table-filter-dropdown">
              <RangePicker
                className="searched-event-table-upload-date-range-picker"
                value={rangePickerValue}
                locale={locale}
                onChange={(dates) => {
                  const [startDate, endDate] = dates;
                  if (setSelectedKeys) setSelectedKeys([JSON.stringify({ startDate, endDate })]);
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                }}
                format={DATE_FORMAT}
                size="small"
              />
              <Button
                className="searched-event-table-search-button"
                type="primary"
                onClick={() => {
                  if (confirm) confirm();
                }}
                icon="search"
                size="small"
              >
                検索
              </Button>
              <Button
                className="searched-event-table-reset-button"
                onClick={() => {
                  if (clearFilters) clearFilters();
                }}
                size="small"
              >
                リセット
              </Button>
            </div>
          );
        } : null,
      onFilter: (value, result) => {
        if (!result.startTime) { return false; }
        const { startDate, endDate } = JSON.parse(value);
        const resultStartDate = new Date(result.startTime);

        const inRange = moment(resultStartDate, DATE_FORMAT)
          .isBetween(moment(startDate), moment(endDate), 'seconds', '[]');
        return inRange;
      },
      render: (_, searchedEventListRender: ElectricEnergyStatus) => {
        const { startTime } = searchedEventListRender;
        return moment(startTime).format(DATE_FORMAT);
      },
    },
    {
      title: 'サイト名',
      dataIndex: 'site_name_jp',
      width: 170,
      filterIcon: filterSearchIcon,
      filterDropdown: isSearchedEventListExisted
        ? ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
          <div className="searched-event-table-filter-dropdown">
            <Input
              className="searched-event-table-site-name-input"
              value={selectedKeys ? selectedKeys[0] : undefined}
              onChange={(event) => {
                if (setSelectedKeys) {
                  setSelectedKeys(event.target.value ? [event.target.value] : []);
                }
              }}
              onPressEnter={() => {
                if (confirm) {
                  confirm();
                }
              }}
            />
            <Button
              className="searched-event-table-search-button"
              type="primary"
              onClick={() => {
                if (confirm) {
                  confirm();
                }
              }}
              icon="search"
              size="small"
            >
              検索
            </Button>
            <Button
              className="searched-event-table-reset-button"
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }
              }}
              size="small"
            >
              リセット
            </Button>
          </div>
        ) : null,
      onFilter: (value, result) => (
        result.metadata?.site_name_jp
          ? result.metadata.site_name_jp.toLowerCase().includes((value as string).toLowerCase())
          : false
      ),
      render: (_, searchedEventListRender: ElectricEnergyStatus) => {
        const { metadata } = searchedEventListRender;
        return metadata?.site_name_jp;
      },
    },
    {
      title: 'PCS番号',
      dataIndex: 'pcs_no',
      width: 170,
      filterIcon: filterSearchIcon,
      filterDropdown: isSearchedEventListExisted
        ? ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
          <div className="searched-event-table-filter-dropdown">
            <Input
              className="searched-event-table-pcs-number-input"
              value={selectedKeys ? selectedKeys[0] : undefined}
              onChange={(event) => {
                if (setSelectedKeys) {
                  setSelectedKeys(event.target.value ? [event.target.value] : []);
                }
              }}
              onPressEnter={() => {
                if (confirm) {
                  confirm();
                }
              }}
            />
            <Button
              className="searched-event-table-search-button"
              type="primary"
              onClick={() => {
                if (confirm) {
                  confirm();
                }
              }}
              icon="search"
              size="small"
            >
              検索
            </Button>
            <Button
              className="searched-event-table-reset-button"
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }
              }}
              size="small"
            >
              リセット
            </Button>
          </div>
        ) : null,
      onFilter: (value, result) => (
        result.metadata?.pcs_no
          ? result.metadata.pcs_no.toLowerCase().includes((value as string).toLowerCase())
          : false
      ),
      render: (_, searchedEventListRender: ElectricEnergyStatus) => {
        const { metadata } = searchedEventListRender;
        return metadata?.pcs_no;
      },
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      width: 170,
      filters: ((): ColumnFilterItem[] => {
        // 全イベントのステータスを一意にする
        const uniqueStatusData = new Set<string | undefined>();
        searchedEventList.forEach(
          ({ metadata }) => uniqueStatusData.add(statusChange(metadata?.status)),
        );

        return createEventTableFilter(uniqueStatusData);
      })(),
      onFilter: (value, result) => String(
        statusChange(result.metadata?.status),
      )?.indexOf(value) === 0,
      filterDropdown: isSearchedEventListExisted
        ? createEventFilterDropdown
        : null,
      render: (_, searchedEventListRender: ElectricEnergyStatus) => {
        const { metadata } = searchedEventListRender;
        const metadataStatusName = statusChange(metadata?.status);
        return metadataStatusName;
      },
    },
  ];

  return (
    <>
      <Table<ElectricEnergyStatus>
        loading={loading}
        dataSource={searchedEventList}
        pagination={{
          className: 'searched-event-table-pagination',
          position: 'bottom',
          pageSize: 100,
        }}
        columns={columns}
        rowKey="id"
        scroll={{ y: 616 }}
      />
    </>
  );
};

export default SearchedEventTable;
