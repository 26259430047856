import {
  CogniteEvent,
  CogniteExternalId,
  CogniteInternalId,
  EventChange,
  ExternalEvent,
  InternalId,
  ItemsResponse,
  ItemsWrapper,
  Metadata,
  Timestamp,
} from '@cognite/sdk';
import { postApiGateway, putApiGateway, deleteApiGateway } from '../AWS/ApiGateway';

abstract class BaseEvent implements CogniteEvent {
  /*
   * メンバ変数
   */
  id: CogniteInternalId;

  externalId?: CogniteExternalId;

  type?: string;

  subtype?: string;

  startTime?: Timestamp;

  endTime?: Timestamp;

  description?: string;

  metadata?: Metadata;

  assetIds?: CogniteInternalId[];

  dataSetId?: CogniteInternalId;

  source?: string;

  createdTime: Date;

  lastUpdatedTime: Date;

  /*
   * コンストラクタ
   */
  constructor(other?: CogniteEvent) {
    if (other) {
      this.id = other.id;
      this.externalId = other.externalId;
      this.type = other.type;
      this.subtype = other.subtype;
      this.startTime = other.startTime;
      this.endTime = other.endTime;
      this.description = other.description;
      this.metadata = other.metadata;
      this.assetIds = other.assetIds;
      this.dataSetId = other.dataSetId;
      this.source = other.source;
      this.createdTime = other.createdTime;
      this.lastUpdatedTime = other.lastUpdatedTime;
    } else {
      this.id = 0;
      this.createdTime = new Date();
      this.lastUpdatedTime = new Date();
    }
  }

  /*
   * メンバメソッド
   */

  /**
   * イベント情報保存
   * @param {string} endpoint エンドポイント
   */
  async saveSelfToCDF(endpoint: string): Promise<void> {
    if (this.id <= 0) {
      const item: ExternalEvent = {
        externalId: this.externalId,
        type: this.type,
        subtype: this.subtype,
        startTime: this.startTime,
        endTime: this.endTime,
        description: this.description,
        metadata: this.metadata,
        assetIds: this.assetIds,
        dataSetId: this.dataSetId,
        source: this.source,
      };

      const events = await postApiGateway<{ items: ExternalEvent[] }, ItemsResponse<Event>>(
        endpoint, { items: [item] },
      );

      Object.assign(this, events.items[0]);
    } else {
      const change: EventChange = {
        id: this.id,
        update: {},
      };

      // eslint-disable-next-line no-unused-expressions
      this.externalId && (change.update.externalId = { set: this.externalId });
      // eslint-disable-next-line no-unused-expressions
      this.type && (change.update.type = { set: this.type });
      // eslint-disable-next-line no-unused-expressions
      this.subtype && (change.update.subtype = { set: this.subtype });
      // eslint-disable-next-line no-unused-expressions
      this.startTime && (change.update.startTime = { set: this.startTime });
      // eslint-disable-next-line no-unused-expressions
      this.endTime && (change.update.endTime = { set: this.endTime });
      // eslint-disable-next-line no-unused-expressions
      this.description && (change.update.description = { set: this.description });
      // eslint-disable-next-line no-unused-expressions
      this.metadata && (change.update.metadata = { set: this.metadata });
      // eslint-disable-next-line no-unused-expressions
      this.assetIds && (change.update.assetIds = { set: this.assetIds });
      // eslint-disable-next-line no-unused-expressions
      this.dataSetId && (change.update.dataSetId = { set: this.dataSetId });
      // eslint-disable-next-line no-unused-expressions
      this.source && (change.update.source = { set: this.source });

      const events = await putApiGateway<{ items: EventChange[] }, ItemsResponse<Event>>(
        endpoint, { items: [change] },
      );

      Object.assign(this, events.items[0]);
    }
  }

  /**
   * イベントを削除する。
   * @param endpoint エンドポイント
   */
  async removeSelfFromCDF(endpoint: string): Promise<void> {
    await deleteApiGateway<ItemsWrapper<InternalId[]>>(endpoint, { items: [{ id: this.id }] });
  }
}

export default BaseEvent;
