import React from 'react';
import { Spin, message } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { ResourceType, getFileDownloadEndpoint } from '../../../utils/common/SDFDataType';

import BaseFile, { MimeType } from '../../../utils/File/BaseFile';

import './FileContent.css';

const CONTENT_WIDTH = 800;
const CONTENT_HEIGHT = 600;

const NUMBERS_OF_BLANK_IN_JSON = 2;

const FileContent: React.FC<{
  target: BaseFile,
  width?: number,
  height?: number,
  downloadable?: boolean,
  resourceType: ResourceType | null,
}> = (props) => {
  /*
   * 変数/定数宣言
   */
  const [loading, setLoading] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<React.ReactNode>();
  const [url, setUrl] = React.useState<string>();
  const [fileId, setFileId] = React.useState<number>();

  const {
    target,
    width,
    height,
    downloadable,
    resourceType,
  } = props;

  /*
   * イベントハンドラ
   */
  React.useEffect(
    () => {
      if (fileId === target.id) return () => { /* 何もしない */ };

      setFileId(target.id);
      setContent(undefined);
      setUrl(undefined);

      setLoading(true);

      let canceled = false;
      (async () => {
        try {
          const endpoint = getFileDownloadEndpoint(resourceType as ResourceType);
          const downloadUrl = await target.loadDownloadURLFromCDF(
            endpoint,
          );
          if (!canceled) {
            setUrl(downloadUrl);
          }

          let contentByMimeType = <></>;
          switch (target.mimeType) {
            case MimeType.Jpeg:
              contentByMimeType = <img alt="画像" src={downloadUrl} />;
              break;
            case MimeType.Json: {
              const json = await target.loadContentFromCDF(endpoint);
              contentByMimeType = (
                <pre>
                  {JSON.stringify(json, null, NUMBERS_OF_BLANK_IN_JSON)}
                </pre>
              );
              break;
            }
            case MimeType.Text: {
              const text = await target.loadContentFromCDF(endpoint);
              contentByMimeType = <pre>{text}</pre>;
              break;
            }
            default:
              contentByMimeType = <div className="message">未対応のMIMEタイプです。</div>;
              break;
          }
          if (!canceled) {
            setContent(contentByMimeType);
          }
        } catch (exception) {
          message.error('ファイルの内容が読み込めませんでした。');
          if (!canceled) {
            setContent(<div className="message">ファイルの内容が読み込めませんでした。</div>);
          }
        } finally {
          if (!canceled) {
            setLoading(false);
          }
        }
      })();
      return () => { canceled = true; };
    },
    // fileIdの変更では動作させない
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [target, resourceType],
  );

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  return (
    <Spin spinning={loading}>
      <div
        className="common-file-content"
        style={{
          width: width || CONTENT_WIDTH,
          height: height || CONTENT_HEIGHT,
        }}
      >
        {content}
        {
          downloadable && url
            ? (
              <div className="link">
                <a href={url}>
                  <CloudDownloadOutlined style={{ fontSize: 24 }} />
                </a>
              </div>
            )
            : <></>
        }
      </div>
    </Spin>
  );
};

export default FileContent;
