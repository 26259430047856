import React from 'react';
import { Col, Row } from 'antd';
import { FileInfo } from '@cognite/sdk';

export const FILE_META_DEFECTED = 'defected';

/**
 * ファイルの詳細情報の生成
 * @param {FileInfo} file ファイル情報
 */
export function generateFileMetaData(
  file: FileInfo,
): { descriptionsItem: JSX.Element[] | null, defectedValue: string } {
  if (!file.metadata) return { descriptionsItem: null, defectedValue: '' };

  const meta = file.metadata;
  const setDefaultDefected = Object.prototype.hasOwnProperty.call(meta, FILE_META_DEFECTED)
    ? meta[FILE_META_DEFECTED]
    : '';

  const descriptionsItem = Object.keys(meta).map((key) => (
    <Row key={key} type="flex" align="middle" style={{ margin: '10px', borderBottom: '1px solid gainsboro' }}>
      <Col span={12} style={{ overflowWrap: 'break-word' }}>
        {key}
      </Col>
      <Col span={12}>
        {meta[key]}
      </Col>
    </Row>
  ));

  return { descriptionsItem, defectedValue: setDefaultDefected };
}

export default generateFileMetaData;
