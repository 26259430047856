import {
  Asset,
  AssetIdEither,
  AssetListScope,
  CogniteInternalId,
  InternalId,
  ItemsWrapper,
} from '@cognite/sdk';

import { postApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_SOLAR_ASSETS_LIST,
  EP_PATH_SOLAR_ASSETS_BY_IDS,
} from '../AWS/EndpointPath';
import { getAllAssets } from '../dataAccess';
import SolarSite from './SolarSite';

// TODO: SolarSite.tsのクラスメソッドとして定義するとコンパイルエラーが発生するため暫定対応

/**
 * IDに紐づく太陽光サイト一覧の取得
 * @param {InternalId[]} ids IDリスト
 * @returns {Promise<ItemsWrapper<Asset[]>>} 太陽光サイト一覧
 */
const loadAllSolarSiteFromCDFByIds = async (
  ids: InternalId[],
): Promise<ItemsWrapper<Asset[]>> => {
  const assets = await postApiGateway<
    ItemsWrapper<AssetIdEither[]>,
    ItemsWrapper<Asset[]>
  >(EP_PATH_SOLAR_ASSETS_BY_IDS, { items: ids });

  return assets;
};

/**
 * 検索条件に一致する太陽光サイト一覧の取得
 * @param {AssetListScope} scope 検索条件
 * @returns {Promise<Asset[]>} 太陽光サイト一覧
 */
const loadAllSolarSiteFromCDFByScope = async (scope: AssetListScope): Promise<Asset[]> => {
  const assets = await getAllAssets(EP_PATH_SOLAR_ASSETS_LIST, scope.filter);

  return assets;
};

/**
 * 指定されたIDに紐づく子Asset一覧の取得
 * @param {CogniteInternalId[]} ids IDリスト
 * @returns 子Asset一覧
 */
const loadAllAssetsByParentIds = async (ids: CogniteInternalId[]): Promise<Asset[]> => {
  const tmpIds = [...ids];
  const loadAllData = [];
  while (tmpIds.length) {
    // parentIdsの上限が100のため、分割してリクエスト
    const spliceIds = tmpIds.splice(0, 100);
    // eslint-disable-next-line no-await-in-loop
    const loadSpliceData = await getAllAssets(EP_PATH_SOLAR_ASSETS_LIST, { parentIds: spliceIds });
    loadAllData.push(...loadSpliceData);
  }

  return loadAllData;
};

/**
 * 財務帳票保持アセット>太陽光の取得
 * @returns 財務帳票保持アセット>太陽光アセット
 */
const loadFinanceReportKeepAsset = async (): Promise<Asset> => {
  // 運用上metadata.assetType = 'financereport'のデータは1件のみ
  const [financeReportKeepAsset] = await getAllAssets(EP_PATH_SOLAR_ASSETS_LIST, { metadata: { assetType: 'financereport' } });
  return financeReportKeepAsset;
};

/**
 * 太陽光サイトの所属事業アセット取得
 * @param {SolarSite[]} solarSites 太陽光サイトアセットリスト
 * @returns {Promise<Asset[]>} 指定された太陽光サイトの所属事業アセットリスト
 */
const getParentBusinessAssetsOfSolarSite = async (solarSites: SolarSite[]): Promise<Asset[]> => {
  /**
   * IDリストの取得
   * @param {Asset[]} assets アセットリスト
   * @returns 指定されたアセットリストの親IDの重複を除去したリスト
   */
  const getInternalIds = (assets: Asset[]): InternalId[] => {
    const parentIds = Array.from(new Set(assets.map((asset) => asset.parentId)));
    const internalIds = parentIds.map((id) => ({ id })) as InternalId[];
    return internalIds;
  };

  /**
   * 指定したIDリストのアセットを取得
   * @param {InternalId[]} ids アセットIDリスト
   * @returns アセットリスト
   */
  const loadSolarAssetsByIds = async (ids: InternalId[]): Promise<Asset[]> => {
    const solarParentAssets = await loadAllSolarSiteFromCDFByIds(ids);
    return solarParentAssets.items.map((asset) => asset);
  };

  const siteParentIds = getInternalIds(solarSites);
  const category2Assets = await loadSolarAssetsByIds(siteParentIds);
  const category2ParentIds = getInternalIds(category2Assets);
  const category1Assets = await loadSolarAssetsByIds(category2ParentIds);
  const category1ParentIds = getInternalIds(category1Assets);
  const businessClassificationAssets = await loadSolarAssetsByIds(category1ParentIds);

  return businessClassificationAssets;
};

export {
  loadAllSolarSiteFromCDFByIds,
  loadAllSolarSiteFromCDFByScope,
  loadAllAssetsByParentIds,
  loadFinanceReportKeepAsset,
  getParentBusinessAssetsOfSolarSite,
};
