import React, { forwardRef } from 'react';
import {
  Spin, List, Checkbox, message,
} from 'antd';

import Facility from '../../../utils/Asset/Facility';
import DetectionResult from '../../../utils/Asset/DetectionResult';
import BaseFile from '../../../utils/File/BaseFile';
import { ResourceType } from '../../../utils/common/SDFDataType';
import { FacilityAndDetectionResultCascader } from './FacilityAndDetectionResultCascader';
import { ERROR_LOAD_FACILITIES_INFO, ERROR_LOAD_FILE } from '../../../utils/messages';

import './FileList.css';

export interface FileListProps {
  facility?: Facility;
  value?: number;
  isFileListDisabled?: boolean;
  onSelectFile: (resourceType: ResourceType, file?: BaseFile) => void;
}

const FileList: React.FC<
  FileListProps
// eslint-disable-next-line @typescript-eslint/no-unused-vars
> = forwardRef((props, ref) => {
  /*
   * 変数/定数定義
   */
  const [loading, setLoading] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<BaseFile[]>([]);
  const [selectedFile, setSelectedFile] = React.useState<BaseFile | undefined>(undefined);
  const [targetDetectionResult, setTargetDetectionResult] = React.useState<DetectionResult>();
  const [onlyDetected, setOnlyDetected] = React.useState<boolean>(true);
  const [fileResourceType, setFileResourceType] = React.useState<ResourceType>(ResourceType.Facility);

  const {
    facility, value, isFileListDisabled, onSelectFile,
  } = props;

  /*
   * イベントハンドラ
   */
  React.useEffect(() => {
    if (!facility) return () => { /* 何もしない */ };

    const baseAssetId = Number(targetDetectionResult?.metadata?.baseAsset);
    if (targetDetectionResult && baseAssetId !== facility.id) {
      // 対象設備変更時の各ステータス初期化
      setFileResourceType(ResourceType.Facility);
      setSelectedFile(undefined);
      setTargetDetectionResult(undefined);
      return () => { /* 何もしない */ };
    }

    setLoading(true);

    let canceled = false;
    (async () => {
      let newFiles: BaseFile[] = [];

      try {
        if (targetDetectionResult) {
          newFiles = await targetDetectionResult.loadDetectionImageFilesFromCDF(
            onlyDetected,
          );
        } else {
          newFiles = await facility.loadLinkedFilesFromCDF();
        }
      } catch (exception) {
        message.error(ERROR_LOAD_FACILITIES_INFO);
      }

      if (!canceled) {
        setFiles(newFiles);
        setLoading(false);
      }
    })();

    return () => {
      canceled = true;
    };
  }, [facility, targetDetectionResult, onlyDetected]);

  React.useEffect(() => {
    if (!value) {
      // 対象設備切替時に選択ファイル情報を初期化
      setFileResourceType(ResourceType.Facility);
      setSelectedFile(undefined);
      return () => { /* 何もしない */ };
    }

    // 指定されたファイルID（=value）がファイル一覧にあればそのファイルを選択状態にする
    const targetFile = files.find((file) => file.id === value);
    if (targetFile) {
      setSelectedFile(targetFile);
      return () => { /* 何もしない */ };
    }

    setLoading(true);

    let canceled = false;
    (async () => {
      let file: BaseFile | undefined;
      let relatedDetectionResult: DetectionResult | undefined;

      try {
        file = await BaseFile.loadOneByIdFromCDF(value);

        if (file && file.assetIds && file.assetIds.length > 0) {
          const detectionResult = await DetectionResult.loadDetectionFromCDF(
            file.assetIds[0],
          );
          if (detectionResult) relatedDetectionResult = detectionResult;
        }
      } catch (exception) {
        message.error(ERROR_LOAD_FILE);
      }

      if (!canceled) {
        setTargetDetectionResult((current) => {
          if (current || selectedFile) return current;

          setOnlyDetected(false);
          return relatedDetectionResult;
        });

        setLoading(false);
      }
    })();

    return () => {
      canceled = true;
    };
    // selectedFileの変化で動作させない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, files]);

  React.useEffect(() => {
    // 保存済み点検内容を初期表示する場合、ファイル情報が更新されるまで結果を返さない
    // 対象設備変更時のファイル指定(value)がない場合は、未選択結果を返す
    if (selectedFile || !value) {
      onSelectFile(fileResourceType, selectedFile);
    }
    // 選択ファイル切替時のみ動作させるためselectedFile以外を除外
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  const commonFileListClassName = isFileListDisabled ? 'common-file-list disabled' : 'common-file-list';
  return (
    <>
      <Spin spinning={loading}>
        <FacilityAndDetectionResultCascader
          facility={facility}
          detectionResultId={targetDetectionResult?.id}
          onChange={(detectionResult) => setTargetDetectionResult(detectionResult)}
          onSelectResource={setFileResourceType}
          isFileListDisabled={isFileListDisabled}
        />
        <div className={commonFileListClassName}>
          <List
            dataSource={files}
            rowKey="id"
            renderItem={(file) => {
              let listItemClassName = isFileListDisabled ? 'disabled' : 'not-selected';
              if (file.id === selectedFile?.id) {
                listItemClassName = 'selected';
              }

              return (
                <List.Item
                  className={listItemClassName}
                  onClick={() => {
                    if (isFileListDisabled) return;

                    // 選択中のファイルを選択した場合は解除する
                    if (file.id === selectedFile?.id) {
                      // ファイル情報を初期状態に戻す
                      setFileResourceType(ResourceType.Facility);
                      setSelectedFile(undefined);
                      // useEffectの(selectedFile || !value)条件では通知できないためここで通知
                      onSelectFile(fileResourceType, undefined);
                    } else {
                      setSelectedFile(file);
                    }
                  }}
                >
                  <span>{file.name}</span>
                </List.Item>
              );
            }}
            pagination={false}
            size="small"
          />
        </div>
        <Checkbox
          checked={onlyDetected}
          disabled={!targetDetectionResult || isFileListDisabled}
          onChange={(event) => setOnlyDetected(event.target.checked)}
        >
          AI検出された写真のみ表示
        </Checkbox>
      </Spin>
    </>
  );
});

export default FileList;
