import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

interface MonthSelectProps {
  value?: string;
  onChange: (value: string) => void;
}

/**
 * 月セレクトボックス
 */
const MonthSelect: React.FC<MonthSelectProps> = (props) => {
  const { onChange, value } = props;
  return (
    <Select
      allowClear
      style={{ width: 150 }}
      value={value}
      onChange={(selectedValue) => onChange(selectedValue)}
    >
      <Option key="01">1</Option>
      <Option key="02">2</Option>
      <Option key="03">3</Option>
      <Option key="04">4</Option>
      <Option key="05">5</Option>
      <Option key="06">6</Option>
      <Option key="07">7</Option>
      <Option key="08">8</Option>
      <Option key="09">9</Option>
      <Option key="10">10</Option>
      <Option key="11">11</Option>
      <Option key="12">12</Option>
    </Select>
  );
};

export default MonthSelect;
