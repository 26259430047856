import React, { useEffect, useState } from 'react';
import {
  FileInfo, Metadata, FileChangeUpdate,
} from '@cognite/sdk';
import {
  Button, Col, Empty, message, Modal, Row, Tooltip,
} from 'antd';

import {
  ERROR_NO_AUTH_MESSAGE,
  ERROR_FILES_RETRIEVE,
  ERROR_FILES_GET_DL_URL,
  ERROR_FILE_PREVIEW,
  ERROR_FILES_UPDATE,
  SUCCESS_FILES_UPDATE,
} from '../../../utils/messages';
import { UnknownFile, MimeType } from '../../../utils/File/BaseFile';
import { generateFileMetaData } from '../Metadata/MetadataInfo';
import './FileDetailViewModal.css';
import MetadataTable from '../Metadata/MetadataTable';
import { fileReservedKeyList } from '../Metadata/SystemReservedKey';
import { ResourceType, getFileDownloadEndpoint } from '../../../utils/common/SDFDataType';
import { retrieveFile, updateFile } from '../../../utils/AWS/AWSRequest';

import { DownloadButton } from './parts/DownloadButton';

const MODAL_WIDTH = 1648;
const MODAL_HEIGHT = 948;
const MODAL_HEIGHT_WITH_DL = 992;
const NUMBERS_OF_BLANK_IN_JSON = 2;

export const FileDetailViewModal: React.FC<{
  visible: boolean,
  resourceType: ResourceType | null,
  fileId: number | null,
  title?: string,
  isModalEditButtonDisabled: boolean,
  isModalDownloadButtonDisabled: boolean,
  onClose: () => void
}> = (props) => {
  /*
   * 変数/定数宣言
   */
  const [content, setContent] = useState<React.ReactNode>(undefined);
  const [detailsList, setDetailsList] = useState<JSX.Element[] | null>(null);
  const [selectedFile, setSelectedFile] = useState<FileInfo | null>(null);
  const [downloadFileId, setDownloadFileId] = useState<number | null>(null);
  const [isEditingData, setIsEditingData] = useState<boolean>(false);
  const [isFileLoaded, setIsFileLoaded] = useState<boolean>(true);

  type MetadataTableHandle = React.ElementRef<typeof MetadataTable>;
  const metadataTableRef = React.useRef<MetadataTableHandle>(null);

  const {
    visible,
    resourceType,
    fileId,
    title,
    isModalEditButtonDisabled,
    isModalDownloadButtonDisabled,
    onClose,
  } = props;

  /*
   * イベントハンドラ
   */
  useEffect(() => {
    if (!visible) return () => { /* 何もしない */ };

    let canceled = false;
    setContent(undefined);
    setDownloadFileId(null);
    setIsFileLoaded(true);

    (async () => {
      if (fileId === null) {
        return;
      }

      let file = null;
      try {
        file = await retrieveFile(resourceType as ResourceType, fileId);
      } catch (e) {
        message.error(ERROR_FILES_RETRIEVE);
        setIsFileLoaded(false);
        return;
      }
      // ファイル情報が空の場合はファイル情報取得エラー
      if (!file) {
        message.error(ERROR_FILES_RETRIEVE);
        setIsFileLoaded(false);
        return;
      }

      // 詳細情報
      const metaInfo = generateFileMetaData(file);
      setDetailsList(metaInfo.descriptionsItem);
      setSelectedFile(file);

      // ファイル読み込み用
      const newFile = UnknownFile.getInstance();
      newFile.mimeType = file.mimeType;
      newFile.id = fileId;

      let downloadUrl;
      let fileContent;
      let errorFileFlag = false;
      const {
        Json,
        Pdf,
        Bmp,
        Gif,
        Jpeg,
        Png,
        Text,
        Mp4,
      } = MimeType;
      try {
        // ダウンロード用(表示直後にダウンロードする場合は、URL付きaタグを準備しておく必要がある)

        const endpoint = getFileDownloadEndpoint(resourceType as ResourceType);
        downloadUrl = await newFile.loadDownloadURLFromCDF(endpoint);
        switch (file.mimeType) {
          case Json: {
            const json = await newFile.loadContentFromURL(downloadUrl);
            fileContent = (<pre className="contain-modal-json">{JSON.stringify(json, null, NUMBERS_OF_BLANK_IN_JSON)}</pre>);
            break;
          }
          case Pdf: {
            if (!downloadUrl) return;
            const response = await fetch(downloadUrl);
            if (!response.ok) {
              errorFileFlag = true;
            } else {
              const contents = await response.blob();
              const createUrl = URL.createObjectURL(contents);
              fileContent = (<object data={createUrl} aria-label={file.name} className="contain-modal-pdf" />);
            }
            break;
          }
          case Bmp:
          case Gif:
          case Jpeg:
          case Png: {
            fileContent = (<img src={downloadUrl} alt="画像" className="contain-modal-image" />);
            break;
          }
          case Text: {
            const text = await newFile.loadContentFromURL(downloadUrl);
            fileContent = (<pre className="contain-modal-text">{text}</pre>);
            break;
          }
          case Mp4: {
            fileContent = (
              <video src={downloadUrl} className="contain-modal-video" controls>
                <track kind="captions" />
              </video>
            );
            break;
          }
          default: {
            fileContent = (<p className="contain-modal-unknown">{ERROR_FILE_PREVIEW}</p>);
            break;
          }
        }
      } catch (e) {
        errorFileFlag = true;
      } finally {
        if (!canceled) {
          if (errorFileFlag) {
            // downloadURL取得失敗時はエラーメッセージを表示
            message.error(ERROR_FILES_GET_DL_URL);
          } else {
            setDownloadFileId(fileId);
          }

          setContent(fileContent);
        }
      }
    })();

    return () => { canceled = true; };
  }, [fileId, resourceType, visible]);

  /**
   * 保存ボタンクリック時のイベントハンドラ
   * @param {Metadata} metadata 更新するファイルの詳細情報
   */
  const handleClickSave = async (metadata: Metadata) => {
    if (!selectedFile) { return; }

    if (selectedFile.metadata) {
      // 詳細情報に変更があるか判定し、ない場合はupdate処理を行わない。

      // オブジェクトをソート済み配列に変換する
      const objToSortedArray = (obj: Metadata) => Object.entries(obj).sort();
      // ソート済み配列を文字列に変換して比較する
      const isEqualSortArray = (obj1: Metadata, obj2: Metadata) => (
        JSON.stringify(objToSortedArray(obj1)) === JSON.stringify(objToSortedArray(obj2))
      );

      if (isEqualSortArray(selectedFile.metadata, metadata)) {
        setIsEditingData(false);
        return;
      }
    }

    try {
      const changes: FileChangeUpdate = {
        id: fileId as number,
        update: {
          metadata: { set: metadata },
        },
      };

      const files = await updateFile(resourceType as ResourceType, changes);

      message.success(SUCCESS_FILES_UPDATE);

      const metaInfo = generateFileMetaData(files.items[0]);
      setDetailsList(metaInfo.descriptionsItem);
      setSelectedFile(files.items[0]);
      setIsEditingData(false);
    } catch (e) {
      message.error(ERROR_FILES_UPDATE);
    }
  };

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  const modalHeight = isFileLoaded ? MODAL_HEIGHT_WITH_DL : MODAL_HEIGHT;
  return (
    <>
      {
        visible && (
          <Modal
            title={title}
            visible={visible}
            onCancel={() => {
              onClose();
              setIsEditingData(false);
            }}
            centered
            width={MODAL_WIDTH}
            footer={null}
            style={{ margin: '0', padding: '0', textAlign: 'center' }}
            bodyStyle={{ width: `${MODAL_WIDTH}px`, height: `${modalHeight}px` }}
          >
            <Col span={16}>
              {content}
              {
                isFileLoaded && (
                  <>
                    <DownloadButton
                      fileId={downloadFileId}
                      resourceType={resourceType}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                    />
                  </>
                )
              }
            </Col>
            <Col span={8}>
              <h1 style={{ margin: '10px', borderBottom: '1px solid darkgray', fontSize: '16px' }}>
                詳細情報
              </h1>
              {
                isEditingData ? (
                  <>
                    {
                      selectedFile && (
                        <MetadataTable
                          ref={metadataTableRef}
                          value={selectedFile.metadata}
                          editable
                          onSave={handleClickSave}
                          onCancel={() => { setIsEditingData(false); }}
                          height={815}
                          systemReservedKeyList={fileReservedKeyList}
                          tableStyle={{ width: '513px', marginLeft: '10px' }}
                          plusButtonStyle={{ float: 'left', marginLeft: '8px' }}
                        />
                      )
                    }
                  </>
                ) : (
                  <>
                    {
                      detailsList?.length ? (
                        <Row style={{ maxHeight: 855, overflow: 'auto' }}>
                          {detailsList}
                        </Row>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    <Tooltip title={isModalEditButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                      <Button
                        type="primary"
                        disabled={isModalEditButtonDisabled}
                        onClick={() => { setIsEditingData(true); }}
                        style={{ float: 'right', marginTop: '10px' }}
                      >
                        編集
                      </Button>
                    </Tooltip>
                  </>
                )
              }
            </Col>
          </Modal>
        )
      }
    </>
  );
};
