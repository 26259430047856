import {
  CogniteInternalId,
  CursorAndAsyncIterator,
  FileInfo,
  FileRequestFilter,
  FileChangeUpdate,
} from '@cognite/sdk';
import {
  getApiGateway,
  postApiGateway,
  getApiGatewayForImage,
  putApiGateway,
  RequestFileChangeUpdate,
  RequestSpecifyID,
  ResponseCogniteOrError,
  ResponseCogniteError,
  ResponseCogniteItems,
  deleteApiGateway,
  ResponseDownloadURL,
} from '../AWS/ApiGateway';
import {
  EP_PATH_MANAGED_FACILITY_FILES,
  EP_PATH_MANAGED_FACILITY_FILES_LIST,
  EP_PATH_MANAGED_FACILITY_FILES_ICON,
  EP_PATH_MANAGED_FACILITY_FILES_DOWNLOAD,
} from '../AWS/EndpointPath';

/**
 * 管理設備に紐づくファイルを取得する(ID指定)
 * @param id ファイルID
 * @returns ファイル情報
 */
const retrieveManagedFacilityFile = async (id: CogniteInternalId): Promise<FileInfo> => {
  const endpoint = `${EP_PATH_MANAGED_FACILITY_FILES}/${id}`;
  const managedFacilityFile = await getApiGateway<FileInfo | ResponseCogniteError>(endpoint);

  if ((managedFacilityFile as ResponseCogniteError).error) {
    throw managedFacilityFile;
  }

  return managedFacilityFile as FileInfo;
};

/**
 * 管理設備に紐づくファイル一覧を取得する。
 * @param scope 検索条件
 * @returns ファイル一覧情報
 */
const getManagedFacilityFiles = async (
  scope: FileRequestFilter,
): Promise<CursorAndAsyncIterator<FileInfo>> => {
  const managedFacilityFiles = await postApiGateway<
    FileRequestFilter, CursorAndAsyncIterator<FileInfo>
  >(
    EP_PATH_MANAGED_FACILITY_FILES_LIST, scope,
  );

  return managedFacilityFiles;
};

/**
 * 管理設備に紐づくファイルのアイコンを取得する
 * @param fileId ファイルID
 * @returns アイコンデータ(base64形式の文字列)
 */
const getManagedFacilityFilesIcon = async (fileId: CogniteInternalId): Promise<string> => {
  const getUrl = `${EP_PATH_MANAGED_FACILITY_FILES_ICON}/${fileId}`;
  const managedFacilityFilesIcon = await getApiGatewayForImage(getUrl);
  return managedFacilityFilesIcon;
};

/**
 * 管理設備に紐づくファイルのダウンロードURLを取得する
 * @param fileId ファイルID
 * @returns ダウンロードURL
 */
const getManagedFacilityFileDownloadUrl = async (fileId: CogniteInternalId): Promise<string> => {
  const scope = { items: [{ id: fileId }] };
  const downloadInfos = await postApiGateway<
    RequestSpecifyID,
    ResponseDownloadURL | ResponseCogniteError
  >(
    EP_PATH_MANAGED_FACILITY_FILES_DOWNLOAD,
    scope,
  );

  if ((downloadInfos as ResponseCogniteError).error) {
    throw downloadInfos;
  }

  const downloadInfo = (downloadInfos as ResponseDownloadURL).items[0];
  return downloadInfo.downloadUrl;
};

/**
 * 管理設備に紐づくファイル情報を更新する。
 * @param change ファイル更新情報
 * @returns 更新後のファイル情報
 */
const updateManagedFacilityFile = async (
  change: FileChangeUpdate,
): Promise<ResponseCogniteItems<FileInfo[]>> => {
  const updatedFiles = (
    await putApiGateway<RequestFileChangeUpdate, ResponseCogniteOrError<FileInfo[]>>(
      EP_PATH_MANAGED_FACILITY_FILES,
      { items: [change] },
    )
  );

  if ((updatedFiles as ResponseCogniteError).error) {
    throw updatedFiles;
  }

  return updatedFiles as ResponseCogniteItems<FileInfo[]>;
};

/**
 * 管理設備に紐づくファイルを削除する。
 * @param id ファイルID
 */
const deleteManagedFacilityFile = async (id: CogniteInternalId): Promise<void> => {
  await deleteApiGateway<RequestSpecifyID>(EP_PATH_MANAGED_FACILITY_FILES, { items: [{ id }] });
};

export {
  retrieveManagedFacilityFile,
  getManagedFacilityFiles,
  getManagedFacilityFilesIcon,
  getManagedFacilityFileDownloadUrl,
  updateManagedFacilityFile,
  deleteManagedFacilityFile,
};
