import React from 'react';
import { List, Spin } from 'antd';

import OMAlertEvent from '../../../../utils/Event/OMAlertEvent';

import './OccurringMeasurementPoint.css';

interface OccurringMeasurementPointProps {
  occurringMeasurementPoints: OMAlertEvent[];
  loading: boolean;
}

/**
 * 警報発生中の計測ポイントコンポーネント
 * @param {OccurringMeasurementPointProps} props
 * @returns 警報発生中の計測ポイント
 */
const OccurringMeasurementPoint: React.FC<OccurringMeasurementPointProps> = (props: OccurringMeasurementPointProps) => {
  const { occurringMeasurementPoints, loading } = props;

  return (
    <div>
      <div className="om-dashboard-alert-occurrence-bar">
        <b className="om-dashboard-alert-occurrence-text">警報発生中！</b>
      </div>
      <div className="om-dashboard-alert-occurring-items">
        <Spin spinning={loading}>
          <List
            grid={{ column: 4 }}
            dataSource={occurringMeasurementPoints}
            renderItem={(item) => (
              <List.Item style={{ textAlign: 'center', margin: '3px 0' }}>
                <span
                  className={`om-dashboard-alert-occurrence-${item.severity === 1 ? 'red' : 'yellow'}-style-circle`}
                  style={{ marginRight: '3px', fontSize: '17px', fontFamily: 'メイリオ' }}
                >
                  ●
                </span>
                {item.monitorName}
              </List.Item>
            )}
            style={{ maxHeight: '400px', overflowY: 'auto' }}
          />
        </Spin>
      </div>
    </div>
  );
};

export default OccurringMeasurementPoint;
