import React, { useEffect, useState } from 'react';
import {
  Table, Input, Icon, Button, DatePicker, Menu, Checkbox, Tooltip, Spin,
} from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import 'moment/locale/ja';
import { ImageGalleryView } from './ImageGalleryView';
import { FileDetailViewModal } from './FileDetailViewModal';
import { MimeType, CAPTURED_IMAGE, DETECTION_RESULT } from '../../../utils/File/BaseFile';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../../utils/common/Authentication';
import { ERROR_NO_AUTH_MESSAGE } from '../../../utils/messages';

import './FileTable.css';

const { RangePicker } = DatePicker;

/** 日付フォーマット */
const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';
/** ワイルドカード検索用文字列 */
const WILDCARD_SEARCH_STRING = '*';
/** データが無い場合に表示する文字列 */
export const NO_DATA_STRING = '-';

/**
 * 表示する画面
 * (0 : 設備詳細
 *  1 : アノテーション
 *  2 : 機械学習
 *  3 : AI検出
 *  4 : AI検出結果)
 */
export const DIGITAL_TWIN_APP = 0; // 設備詳細
export const ANNOTATION = 1; // アノテーション
export const LEARNING = 2; // 機械学習
export const DETECT = 3; // AI検出
export const DETECT_RESULT = 4; // AI検出結果

/**
 * 文字列を表示用文字列JSXに変換する
 * @param {string} str 'true' | 'false' | '-'
 * @returns あり | なし
 */
function convertJSXForString(str) {
  if (str === 'true') {
    return <>あり</>;
  }

  return <>なし</>;
}

/**
 * 文字列を表示用文字列JSXに変換する
 * undefinedが指定された場合、'-'に変換する
 * @param {string} str 'true' | 'false' | '-'
 * @returns あり | なし
 */
function convertJSXForStringWithNoData(str) {
  if (str === 'true') {
    return <>あり</>;
  }

  if (str === 'false') {
    return <>なし</>;
  }

  return <>{NO_DATA_STRING}</>;
}

/**
 * 文字列をJSXに変換する
 * @param {string} str str 'true' | 'false'
 * @returns 'true'の場合アノテーション済みアイコン、'false'の場合ハイフン
 */
function convertJSXForIcon(str) {
  if (str === 'true') {
    return <img src="./icon/Checked.png" alt="annotated" style={{ width: '15px' }} />;
  }

  return <>{NO_DATA_STRING}</>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function FileTable(props) {
  const [filteredInfo, setFilteredInfo] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [fileName, setFileName] = useState(undefined);
  const [galleryVisible, setGalleryVisible] = useState(false);
  const [isFileLinkDisabled, setIsFileLinkDisabled] = useState(true);

  const {
    client,
    imageLoad,
    files,
    onClickImage,
    handleRowChange,
    checkFileList,
    needsDisabledCheckbox,
    showDetectColumns,
    showAnnotationColumns,
    height,
    isModalEditButtonDisabled,
    isModalDownloadButtonDisabled,
    metadataFilter,
    facilityAssetId,
    detectResultAssetId,
    displayTarget,
    fileNameStatus,
    resourceType,
    thumbnailInfoList,
  } = props;

  useEffect(() => {
    // 設備・検出結果リスト・表示画像の種類の変更時、フィルタをクリア
    setFilteredInfo(undefined);
  }, [facilityAssetId, detectResultAssetId, displayTarget]);

  useEffect(() => {
    switch (fileNameStatus) {
      case DIGITAL_TWIN_APP: {
        const { EQUIPMENT_DETAILS_EQ, EQUIPMENT_DETAILS_TD } = AUTHENTICATION_TYPE_MATRIX;
        const { FILE_TAB_UPLOAD_FILE_LINK } = resourceType === 'equipment' ? EQUIPMENT_DETAILS_EQ : EQUIPMENT_DETAILS_TD;
        (async () => {
          setIsFileLinkDisabled(!await containsUIAuthType(FILE_TAB_UPLOAD_FILE_LINK));
        })();
        break;
      }
      case ANNOTATION: {
        const annotationMatrix = AUTHENTICATION_TYPE_MATRIX.ANNOTATION;
        (async () => {
          setIsFileLinkDisabled(
            !await containsUIAuthType(annotationMatrix.LEARNING_PROJECTS_DETAILS_FILE_LINK),
          );
        })();
        break;
      }
      case LEARNING: {
        const { MACHINE_LEARNING } = AUTHENTICATION_TYPE_MATRIX;
        (async () => {
          setIsFileLinkDisabled(
            !await containsUIAuthType(MACHINE_LEARNING.LEARNING_PROJECTS_DETAILS_FILE_LINK),
          );
        })();
        break;
      }
      case DETECT: {
        const { AI_DETECT } = AUTHENTICATION_TYPE_MATRIX;
        const { EQUIPMENT_LIST_FILE_LINK } = AI_DETECT;
        (async () => {
          setIsFileLinkDisabled(!await containsUIAuthType(EQUIPMENT_LIST_FILE_LINK));
        })();
        break;
      }
      case DETECT_RESULT: {
        const { AI_DETECT_RESULT } = AUTHENTICATION_TYPE_MATRIX;
        const { DETECTION_RESULT_DETAILS_FILE_LINK } = AI_DETECT_RESULT;
        (async () => {
          setIsFileLinkDisabled(!await containsUIAuthType(DETECTION_RESULT_DETAILS_FILE_LINK));
        })();
        break;
      }
      default:
    }
  }, [fileNameStatus, resourceType]);

  // ファイル一覧テーブルのチェンジイベントハンドラ
  const handleChange = (_, filters) => {
    setFilteredInfo(filters);
  };

  /**
   * ファイルのリンククリック時のイベントハンドラ
   * @param {number} selectFileId クリックしたファイルのファイルID
   * @param {string} selectFileName クリックしたファイルのファイル名
   * @param {string} mimeType クリックしたファイルのmimetype
   */
  const handleClickLink = (selectFileId, selectFileName, mimeType) => {
    setFileId(selectFileId);
    setFileName(selectFileName);
    if (mimeType === MimeType.Jpeg || mimeType === MimeType.Png) {
      setGalleryVisible(true);
    } else {
      setModalVisible(true);
    }
  };

  /**
   * モーダルを閉じた時のイベントハンドラ
   */
  const handleCloseModal = () => {
    setFileId(null);
    setFileName(undefined);
    setModalVisible(false);
  };

  /**
   * ImageGalleryViewを閉じた時のイベントハンドラ
   */
  const handleCloseImageGalleryView = (selectFileId) => {
    setFileId(null);
    setFileName(undefined);
    setGalleryVisible(false);
    if (selectFileId) {
      onClickImage(selectFileId);
    }
  };

  const createFileExtensionFilter = () => {
    // 全ファイルの拡張子を一意にする
    const uniqueFileExtension = new Set();
    files.forEach((f) => uniqueFileExtension.add(f.fileExtension));

    const fileExtensionFilter = [];
    uniqueFileExtension.forEach((fileExtension) => (
      fileExtensionFilter.push({ text: fileExtension, value: fileExtension })
    ));

    // 拡張子名の昇順でソート
    fileExtensionFilter.sort((fileA, fileB) => {
      if (fileA.text < fileB.text) {
        return -1;
      }

      // 同名の拡張子は存在しないため1固定で返却
      return 1;
    });

    return fileExtensionFilter;
  };

  const createAnnotatedFilter = () => {
    const uniqueAnnotated = new Set();
    files.forEach((f) => {
      if (f.annotated === 'false') {
        uniqueAnnotated.add(NO_DATA_STRING);
        return;
      }
      uniqueAnnotated.add(f.annotated);
    });

    const annotatedFilter = [];
    uniqueAnnotated.forEach((annotated) => (
      annotatedFilter.push({ text: convertJSXForIcon(annotated), value: annotated })
    ));

    return annotatedFilter;
  };

  const createDetectFlagFilter = () => {
    const uniqueDetectFlag = new Set();
    files.forEach((f) => {
      if (!f.metadata) {
        uniqueDetectFlag.add(NO_DATA_STRING);
        return;
      }

      const { detectFlag } = f.metadata;
      if (!detectFlag || detectFlag === 'false') {
        uniqueDetectFlag.add(NO_DATA_STRING);
        return;
      }
      uniqueDetectFlag.add(detectFlag);
    });

    const detectFlagFilter = [];
    uniqueDetectFlag.forEach((detectFlag) => (
      detectFlagFilter.push({ text: convertJSXForString(detectFlag), value: detectFlag })
    ));

    return detectFlagFilter;
  };

  const createDefectedFilter = () => {
    const uniqueDefected = new Set();
    files.forEach((f) => {
      if (!f.metadata) {
        uniqueDefected.add(NO_DATA_STRING);
        return;
      }

      const { defected } = f.metadata;
      if (!defected) {
        uniqueDefected.add(NO_DATA_STRING);
        return;
      }

      uniqueDefected.add(defected);
    });

    // 表示順を「あり / なし / '-'」に固定
    const sortedDefected = new Set();
    if (uniqueDefected.has('true')) { sortedDefected.add('true'); }
    if (uniqueDefected.has('false')) { sortedDefected.add('false'); }
    if (uniqueDefected.has(NO_DATA_STRING)) { sortedDefected.add(NO_DATA_STRING); }

    const defectedFilter = [];
    sortedDefected.forEach((defected) => (
      defectedFilter.push({ text: convertJSXForStringWithNoData(defected), value: defected })
    ));

    return defectedFilter;
  };

  // 拡張子フィルタドロップダウン作成
  const createFileExtensionFilterDropdown = ({
    setSelectedKeys, selectedKeys, filters, confirm, clearFilters,
  }) => (
    <>
      <Menu
        className="ant-dropdown-menu-without-submenu file-list-table"
        onClick={({ key }) => {
          let newSelectedKeys;
          if (selectedKeys && selectedKeys.indexOf(key) >= 0) {
            // 選択済みの拡張子はチェックを外す
            newSelectedKeys = selectedKeys.filter((selectedKey) => selectedKey !== key);
          } else if (selectedKeys) {
            newSelectedKeys = selectedKeys.concat([key]);
          } else {
            newSelectedKeys = [key];
          }

          setSelectedKeys(newSelectedKeys);
        }}
      >
        {filters.map((filter) => {
          const { text, value } = filter;
          const isSelected = selectedKeys && selectedKeys.indexOf(value) >= 0;
          return (
            <Menu.Item key={value} className="file-list-item">
              <Checkbox checked={isSelected} />
              <span>{text}</span>
            </Menu.Item>
          );
        })}
      </Menu>
      <div className="ant-table-filter-dropdown-btns">
        <Button
          className="filter-list-button"
          type="link"
          onClick={confirm}
          size="small"
        >
          OK
        </Button>
        <Button
          className="filter-list-button clear"
          type="link"
          onClick={clearFilters}
          size="small"
        >
          Reset
        </Button>
      </div>
    </>
  );

  // ファイル名フィルタドロップダウン作成
  const createFileNameFilterDropdown = ({
    setSelectedKeys, selectedKeys, confirm, clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        value={selectedKeys ? selectedKeys[0] : undefined}
        onChange={(event) => {
          if (setSelectedKeys) {
            setSelectedKeys(event.target.value ? [event.target.value] : []);
          }
        }}
        onPressEnter={() => {
          if (confirm) {
            confirm();
          }

          setFilteredInfo({ ...filteredInfo, fileName: selectedKeys || undefined });
        }}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => {
          if (confirm) {
            confirm();
          }
          setFilteredInfo({ ...filteredInfo, fileName: selectedKeys || undefined });
        }}
        icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        検索
      </Button>
      <Button
        onClick={() => {
          if (clearFilters) {
            clearFilters();
          }
          setFilteredInfo({ ...filteredInfo, fileName: undefined });
        }}
        size="small"
        style={{ width: 90 }}
      >
        リセット
      </Button>
    </div>
  );

  // フィルタアイコン作成
  const createFilterIcon = (filtered) => (
    <Icon type="search" style={{ color: filtered ? '1890ff' : undefined }} />
  );

  // 撮影日時フィルタドロップダウン作成
  const createDateTimeFilterDropdown = ({
    setSelectedKeys, selectedKeys, confirm, clearFilters,
  }) => {
    let rangePickerValue = [];
    if (selectedKeys && selectedKeys[0]) {
      const { startDate, endDate } = selectedKeys[0];
      rangePickerValue = [startDate, endDate];
    }

    return (
      <div style={{ padding: 8 }}>
        <RangePicker
          value={rangePickerValue}
          locale={locale}
          onChange={(dates) => {
            const [startDate, endDate] = dates;
            if (setSelectedKeys) {
              setSelectedKeys([{ startDate, endDate }]);
            }
          }}
          size="small"
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => {
            if (confirm) {
              confirm();
            }

            if (setSelectedKeys) {
              setFilteredInfo({ ...filteredInfo, dateTimeOriginal: selectedKeys });
            }
          }}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          検索
        </Button>
        <Button
          onClick={() => {
            if (clearFilters) {
              clearFilters();
            }

            if (setSelectedKeys) {
              setFilteredInfo({ ...filteredInfo, dateTimeOriginal: [] });
            }
          }}
          size="small"
          style={{ width: 90 }}
        >
          リセット
        </Button>
      </div>
    );
  };

  // テーブル行選択処理
  const rowSelection = {
    selectedRowKeys: checkFileList,
    onChange: (event) => handleRowChange(event),
    getCheckboxProps: (record) => {
      if (!needsDisabledCheckbox) {
        return { disabled: false };
      }

      return {
        disabled: needsDisabledCheckbox(record),
      };
    },
  };

  /**
   * 詳細情報検索実行時にキーが'*'の場合のフィルタリング条件
   * @param {object} filesMetadata ファイル詳細情報
   * @return {boolean} ファイルフィルタリングフラグ
   */
  const metadataKeyWildcardSearch = ((filesMetadata) => {
    const fileMetadataValues = Object.values(filesMetadata);
    return fileMetadataValues.includes(metadataFilter[WILDCARD_SEARCH_STRING]);
  });

  /**
   * 詳細情報検索実行時にバリューが'*'の場合のフィルタリング条件
   * @param {object} filesMetadata ファイル詳細情報
   * @param {string} metadataKey 詳細情報検索時のキー
   * @return {boolean} ファイルフィルタリングフラグ
   */
  const metadataValueWildcardSearch = (filesMetadata, metadataKey) => Object.prototype
    .hasOwnProperty.call(filesMetadata, metadataKey);

  /**
   * 詳細情報検索実行時にキー、バリューともに'*'ではない場合のフィルタリング条件
   * @param {object} filesMetadata ファイル詳細情報
   * @param {string} metadataKey 詳細情報検索時のキー
   * @return {boolean} ファイルフィルタリングフラグ
   */
  const metadataHasNotWildcardSearch = (filesMetadata, metadataKey) => filesMetadata[metadataKey]
    === metadataFilter[metadataKey];

  /**
   * 詳細情報でフィルタする
   * @returns フィルタしたファイルリスト
   */
  const filterFilesByMetadata = () => {
    const metadataKeys = Object.keys(metadataFilter);
    if (metadataKeys.length === 0) { return files; }

    let filteredFiles = [...files];
    metadataKeys.forEach((metadataKey) => {
      // 指定の詳細情報の条件でファイルをフィルタリングする。
      filteredFiles = filteredFiles.filter((file) => {
        const filesMetadata = file.metadata;
        let filteringFlag = true;
        if (!filesMetadata) {
          // metadataが存在しない場合
          return false;
        }

        if (metadataKey === WILDCARD_SEARCH_STRING
          && metadataFilter[metadataKey] !== WILDCARD_SEARCH_STRING) {
          // keyが'*'かつvalueが'*'ではない場合
          filteringFlag = metadataKeyWildcardSearch(filesMetadata);
        } else if (metadataFilter[metadataKey] === WILDCARD_SEARCH_STRING
          && metadataKey !== WILDCARD_SEARCH_STRING) {
          // valueが'*'かつkeyが'*'ではない場合
          filteringFlag = metadataValueWildcardSearch(filesMetadata, metadataKey);
        } else if (metadataFilter[metadataKey] !== WILDCARD_SEARCH_STRING
          && metadataKey !== WILDCARD_SEARCH_STRING) {
          // valueが'*'ではないかつkeyが'*'ではない場合
          filteringFlag = metadataHasNotWildcardSearch(filesMetadata, metadataKey);
        }

        return filteringFlag;
      });
    });
    return filteredFiles;
  };

  // ファイル名の要素を画面によって表示出し分けする
  const createFileNameClass = () => {
    // 画面ごとに表示出し分け
    switch (fileNameStatus) {
      // 設備詳細の場合
      case DIGITAL_TWIN_APP:
        // 設備詳細にて選択したタブで表示出し分け
        switch (displayTarget) {
          // アップロードファイルタブの場合
          case CAPTURED_IMAGE:
            return 'file-table-file-name-button digital-twin-app-upload-file-name';
          // AI検出結果タブの場合
          case DETECTION_RESULT:
            return 'file-table-file-name-button digital-twin-app-detection-result-file-name';
          // デフォルト
          default:
            return 'file-table-file-name-button';
        }
      // アノテーションの場合
      case ANNOTATION:
        return 'file-table-file-name-button annotation-file-name';
      // 機械学習の場合
      case LEARNING:
        return 'file-table-file-name-button learning-file-name';
      // AI検出の場合
      case DETECT:
        return 'file-table-file-name-button detect-file-name';
      // AI検出結果の場合
      case DETECT_RESULT:
        return 'file-table-file-name-button detect-result-file-name';
      // デフォルト
      default:
        return 'file-table-file-name-button';
    }
  };

  // 各フィルタ情報
  let fileNameFilteredInfo = null; // ファイル名
  if (filteredInfo && filteredInfo.fileName) {
    fileNameFilteredInfo = filteredInfo.fileName;
  }

  let fileExtensionFilteredInfo = null; // 拡張子
  if (filteredInfo && filteredInfo.fileExtension) {
    fileExtensionFilteredInfo = filteredInfo.fileExtension;
  }

  let annotatedFilteredInfo = null; // アノテーション
  if (filteredInfo && filteredInfo.annotated) {
    annotatedFilteredInfo = filteredInfo.annotated;
  }

  let detectFlagFilteredInfo = null; // AI検出
  if (filteredInfo && filteredInfo.detectFlag) {
    detectFlagFilteredInfo = filteredInfo.detectFlag;
  }

  let defectedFilteredInfo = null; // 不具合
  if (filteredInfo && filteredInfo.defected) {
    defectedFilteredInfo = filteredInfo.defected;
  }

  let dateTimeOriginalFilteredInfo = null; // 撮影日時
  if (filteredInfo && filteredInfo.dateTimeOriginal) {
    dateTimeOriginalFilteredInfo = filteredInfo.dateTimeOriginal;
  }

  // 表示するカラム定義
  const columns = [
    {
      title: 'ファイル名',
      dataIndex: 'fileName',
      key: 'fileName',
      filterIcon: createFilterIcon,
      filteredValue: fileNameFilteredInfo,
      filterDropdown: createFileNameFilterDropdown,
      onFilter: (value, file) => (
        file.name.toLowerCase().includes((value).toLowerCase())
      ),
      render: (text, file) => {
        const thumbnailInfo = thumbnailInfoList?.find((info) => info.id === file.id);
        const thumbnailId = thumbnailInfo?.id;
        const thumbnailElement = thumbnailInfo?.element;
        return (
          <>
            <Tooltip title={isFileLinkDisabled && ERROR_NO_AUTH_MESSAGE} placement="topRight">
              <Button
                type="link"
                onClick={() => handleClickLink(file.id, file.name, file.mimeType)}
                style={{ padding: 0, marginBottom: '32px' }}
                disabled={isFileLinkDisabled}
              >
                <div className="file-table-file-name-button">
                  <Spin spinning={!thumbnailId} style={{ height: '60px', paddingTop: '20px', width: '80px' }}>
                    {thumbnailId && thumbnailElement}
                  </Spin>
                </div>
                <div className={createFileNameClass()}>
                  <Tooltip title={(!isFileLinkDisabled && text)} placement="bottomLeft">
                    <Highlighter
                      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                      searchWords={fileNameFilteredInfo || []}
                      autoEscape
                      textToHighlight={text}
                    />
                  </Tooltip>
                </div>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: '拡張子',
      dataIndex: 'fileExtension',
      key: 'fileExtension',
      width: 120,
      filters: createFileExtensionFilter(),
      filteredValue: fileExtensionFilteredInfo,
      onFilter: (value, file) => file.fileExtension?.indexOf(value) === 0,
      filterDropdown: files.length === 0 ? null : createFileExtensionFilterDropdown,
    },
  ];

  if (showAnnotationColumns) {
    columns.push({
      title: 'アノテーション',
      dataIndex: 'annotated',
      key: 'annotated',
      width: 150,
      filters: createAnnotatedFilter(),
      filteredValue: annotatedFilteredInfo,
      onFilter: (value, file) => {
        if (file.annotated === 'false') {
          // 未アノテーションの場合、表示を「-」にしているため一致するか判定
          return value === NO_DATA_STRING;
        }

        return file.annotated === value;
      },
      render: (text) => convertJSXForIcon(text),
    });
  }

  if (showDetectColumns) {
    columns.push({
      title: 'AI検出',
      dataIndex: 'metadata.detectFlag',
      key: 'detectFlag',
      width: 120,
      filters: createDetectFlagFilter(),
      filteredValue: detectFlagFilteredInfo,
      onFilter: (value, file) => {
        if (!file.metadata) {
          // metadataの定義が存在しない場合
          return value === NO_DATA_STRING;
        }

        const { detectFlag } = file.metadata;
        const result = detectFlag === 'true'
          ? detectFlag === String(value)
          : value === NO_DATA_STRING;

        return result;
      },
      render: (text) => convertJSXForString(text),
    });

    columns.push({
      title: '不具合',
      dataIndex: 'metadata.defected',
      key: 'defected',
      width: 120,
      filters: createDefectedFilter(),
      filteredValue: defectedFilteredInfo,
      onFilter: (value, file) => {
        if (!file.metadata) {
          // metadataの定義が存在しない場合、表示を「-」にしているため一致するか判定
          return value === NO_DATA_STRING;
        }

        const { defected } = file.metadata;
        if (!defected) {
          // metadataの定義が存在しない場合、表示を「-」にしているため一致するか判定
          return value === NO_DATA_STRING;
        }

        const result = defected === value;
        return result;
      },
      render: (text) => convertJSXForStringWithNoData(text),
    });
  }

  columns.push({
    title: '撮影日時',
    dataIndex: 'metadata.撮影日時',
    key: 'dateTimeOriginal',
    width: 200,
    filterIcon: createFilterIcon,
    filterDropdown: createDateTimeFilterDropdown,
    filteredValue: dateTimeOriginalFilteredInfo,
    onFilter: (value, file) => {
      // 撮影期間は開始日~終了日が指定されるためmetadataの定義が存在しない場合は必ず対象外となる
      if (!file.metadata) return false;

      const { startDate, endDate } = value;
      const photographDate = file.metadata['撮影日時'];

      const inRange = moment(photographDate, DATE_FORMAT).isBetween(startDate, endDate, 'days', '[]');
      return inRange;
    },
    render: (text) => {
      if (!text) {
        // 撮影日時が存在しない場合
        return <>{NO_DATA_STRING}</>;
      }

      return <>{text}</>;
    },
  });

  const tableDataSource = filterFilesByMetadata();
  const targetAssetId = displayTarget === CAPTURED_IMAGE ? facilityAssetId : detectResultAssetId;

  return (
    <>
      <Table
        loading={imageLoad}
        rowKey="id"
        columns={columns}
        dataSource={tableDataSource}
        onChange={handleChange}
        onRow={(file) => ({
          onClick: () => onClickImage(file.id),
        })}
        rowSelection={rowSelection}
        pagination={false}
        scroll={{ y: height }}
      />
      <FileDetailViewModal
        visible={modalVisible}
        resourceType={resourceType}
        fileId={fileId}
        title={fileName}
        isModalEditButtonDisabled={isModalEditButtonDisabled}
        isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
        onClose={handleCloseModal}
      />
      <ImageGalleryView
        visible={galleryVisible}
        client={client}
        assetId={targetAssetId}
        resourceType={resourceType}
        fileId={fileId}
        isModalEditButtonDisabled={isModalEditButtonDisabled}
        isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
        displayTarget={displayTarget}
        isAiDetect={fileNameStatus === DETECT}
        onClose={handleCloseImageGalleryView}
      />
    </>
  );
}

export default FileTable;
