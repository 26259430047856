import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Spin,
  Tooltip,
  Typography,
  message,
} from 'antd';

import AlertEmailTable from './parts/AlertEmailTable';
import AlertSiteSelect from './parts/AlertSiteSelect';
import {
  ERROR_NO_AUTH_MESSAGE,
  ERROR_SITE_NOT_SELECTED,
  INFORMATION_NO_SEARCH_RESULTS,
} from '../../utils/messages';
import AlertMappingInfo from '../../utils/DynamoDB/AlertMappingInfo';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';

import './AlertEmailSetting.css';

const { Title } = Typography;

const AlertEmailSetting: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAlertList, setSelectedAlertList] = useState<AlertMappingInfo[]>([]);
  const [selectedAlertSite, setSelectedAlertSite] = useState<string>('');
  const [tableRefresh, setTableRefresh] = useState<number>(0);
  const [isReadButtonDisabled, setIsReadButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    let canceled = false;
    (async () => {
      const extractedAlertMappingList = await AlertMappingInfo.getAllAlertMappings()
        .then((result) => (result).filter((alertSite) => alertSite.areaNameJp === selectedAlertSite));
      const {
        SOLAR_MENU: {
          ALERT_EMAIL_SETTINGS: { SOLAR_ALERT_EMAIL_NOTIFICATION_SETTINGS_READ },
        },
      } = AUTHENTICATION_TYPE_MATRIX;

      if (!canceled) {
        setIsReadButtonDisabled(!await containsUIAuthType(SOLAR_ALERT_EMAIL_NOTIFICATION_SETTINGS_READ));
        setSelectedAlertList(extractedAlertMappingList);
      }
    })();

    return () => { canceled = true; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRefresh]);

  /**
   * 親画面の一覧リフレッシュ処理
   */
  const handleRefresh = () => {
    setTableRefresh(tableRefresh + 1);
  };

  /**
   * 検索ボタンクリック時のイベントハンドラ
   */
  const handleTableLoad = async (): Promise<void> => {
    setLoading(true);
    setSelectedAlertList([]);

    if (!selectedAlertSite) {
      message.error(ERROR_SITE_NOT_SELECTED);
      setLoading(false);
      return;
    }

    const extractedAlertMappingList = await AlertMappingInfo.getAllAlertMappings()
      .then((result) => result.filter((alertSite) => alertSite.areaNameJp === selectedAlertSite));

    if (!extractedAlertMappingList.length) {
      message.info(INFORMATION_NO_SEARCH_RESULTS);
    }

    setSelectedAlertList(extractedAlertMappingList);
    setLoading(false);
  };

  return (
    <div className="alert-email-container">
      <Spin spinning={loading}>
        <div className="alert-email-title">
          <Title level={4}>警報メール設定</Title>
        </div>
        <Divider className="alert-email-divider" />
        <Row>
          <Col span={20} offset={1}>
            <Descriptions bordered>
              <Descriptions.Item label="サイト選択">
                <AlertSiteSelect
                  selectedSite={selectedAlertSite}
                  onChange={(value) => setSelectedAlertSite(value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row>
          <Col span={4} offset={20}>
            <Tooltip title={isReadButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
              <Button
                type="primary"
                id="alert-email-site-search-button"
                onClick={handleTableLoad}
                style={{ marginTop: 16, marginBottom: 16 }}
                loading={loading}
                disabled={isReadButtonDisabled}
              >
                検索
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <br />
        {!!selectedAlertList.length && (
          <Row>
            <AlertEmailTable
              loading={loading}
              selectedAlertList={selectedAlertList}
              tableRefresh={handleRefresh}
            />
          </Row>
        )}
      </Spin>
    </div>
  );
};

export default AlertEmailSetting;
