import React, { useEffect, useState, CSSProperties } from 'react';
import { CogniteClient } from '@cognite/sdk';
import { Empty, Spin, message } from 'antd';

import { EventTouch } from '../../../../utils/Enlarge/EventTouch';
import { Zoom } from '../../../../utils/Enlarge/Zoom';
import { ImageFile } from '../../../../utils/File/BaseFile';
import './EnlargeContent.css';
import { ResourceType, getFileDownloadEndpoint } from '../../../../utils/common/SDFDataType';

import { ERROR_LOAD_IMAGE_FILE } from '../../../../utils/messages';

interface EnlargeContentProps {
  client: CogniteClient;
  target: ImageFile,
  targetClassName?: string,
  parentStyle?: CSSProperties,
  zoomStyle?: CSSProperties,
  resourceType: ResourceType | null,
}

type EnlargeImageClass = 'portrait' | 'landscape' | 'square';

const EnlargeContent: React.FC<EnlargeContentProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [eventTouchInstance, setEventTouchInstance] = useState<EventTouch>();
  const [zoomInstance, setZoomInstance] = useState<Zoom>();
  const [enlargeTarget, setEnlargeTarget] = useState<JSX.Element>();

  const {
    client,
    target,
    targetClassName,
    parentStyle,
    zoomStyle,
    children,
    resourceType,
  } = props;

  const baseClassName = targetClassName || 'enlarge-content-wrapper';
  const zoomTagStyle = zoomStyle || { width: '768px', height: '768px' };

  /**
   * 画像サイズを元に付与するクラス名を取得する。
   * @param height 高さ
   * @param width 幅
   * @returns |portrait|landscape|square|
   */
  const getImageClassName = (height: number, width: number): EnlargeImageClass => {
    if (height > width) { return 'portrait'; }
    if (height < width) { return 'landscape'; }

    return 'square';
  };

  useEffect(() => {
    let canceled = false;

    (async () => {
      try {
        const endpoint = getFileDownloadEndpoint(resourceType as ResourceType);
        const imageDataBase64 = await target.loadContentByBase64FromCDF(
          endpoint,
        );
        if (!imageDataBase64) throw new Error();

        const imageData = new Image();
        imageData.onload = () => {
          const { height, width } = imageData;
          const enlargeImageClassName = getImageClassName(height, width);

          const loadEnlargeContent = (
            <img src={imageDataBase64} alt={target.name} className={enlargeImageClassName} />
          );

          if (!canceled) {
            setLoading(false);
            setEnlargeTarget(loadEnlargeContent);
          }
        };
        imageData.src = imageDataBase64;
      } catch (ex) {
        message.error(ERROR_LOAD_IMAGE_FILE);
        setLoading(false);
        setIsError(true);
      }
    })();

    return () => { canceled = true; };
  }, [client, target, resourceType]);

  useEffect(() => {
    if (!eventTouchInstance) {
      const eventTouch = new EventTouch();
      setEventTouchInstance(eventTouch);
    }

    if (!zoomInstance && eventTouchInstance) {
      const zoom = new Zoom(baseClassName, eventTouchInstance);
      setZoomInstance(zoom);
    }

    return function clean() {
      if (eventTouchInstance) {
        eventTouchInstance.removeEvent();
      }

      if (zoomInstance) {
        zoomInstance.cancel();
      }
    };
  }, [baseClassName, eventTouchInstance, zoomInstance]);

  const zoomClassName = enlargeTarget ? 'zoom active' : 'zoom';
  return (
    <div className={baseClassName}>
      <div className="enlarge-content-container" style={parentStyle}>
        {loading && <Spin size="large" tip="Loading..." />}
        {isError && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        <div className={zoomClassName} style={zoomTagStyle}>
          <div className="zoom__info">
            {enlargeTarget}
            <div className="zoom__area" />
          </div>
          <div className="zoom__elm">
            {enlargeTarget}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export { EnlargeContent };
