/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-await-in-loop */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-empty */
// eslint警告未対応
import {
  getApiGateway,
  postApiGateway,
  putApiGateway,
  deleteApiGateway,
} from '../AWS/ApiGateway';
import { EP_PATH_LEARNING_PJ, EP_PATH_LEARNING_PJ_FILES } from '../AWS/EndpointPath';
import { getDlAssetId } from '../storageCommon';
import { sortName } from '../sort';
import { sleep } from '../common';

/**
 * Asset取得（ID指定）
 * @param {object} 検索条件(endpoint, id, externalId)
 * @returns {object} Asset(※Assetが存在しない場合、undefined)
 */
export async function assetRetrieve({ endpoint, id, externalId }) {
  let asset;
  try {
    if (id) {
      const url = `${endpoint}/${id}`;
      asset = await getApiGateway(url);
    } else if (externalId) {
      const assets = await postApiGateway(endpoint, { filter: { externalIdPrefix: externalId } });
      asset = assets[0];
    }
  } catch (e) {
  }
  return asset;
}

/**
 * 全てのAssets取得
 * @param {string} endpoint エンドポイント
 * @param {object} filter cogniteSDK assets.listのfiles Object
 * @returns {Promise<Array>} All Assets
 */
export async function getAllAssets(endpoint, filter = {}) {
  const allAssets = [];
  let assets = await postApiGateway(endpoint, { filter });
  allAssets.push(...assets.items);

  let nextCursor = assets.nextCursor;
  while (nextCursor) {
    assets = await postApiGateway(endpoint, { filter, cursor: nextCursor });
    allAssets.push(...assets.items);
    nextCursor = assets.nextCursor;
  }
  return allAssets;
}

/**
 * Asset登録
 * @param {string} endpoint エンドポイント
 * @param {object} asset 登録するAsset
 * @returns {number} id AssetID
 */
export async function createAsset(endpoint, asset) {
  const createdAsset = await postApiGateway(endpoint, asset);

  // 作成完了まで処理を止める
  await sleep(1000);

  return createdAsset.items[0].id;
}

/**
 * Asset更新
 * @param {string} getEndpoint データ取得エンドポイント
 * @param {string} endpoint データ更新エンドポイント
 * @param {object} assetChange 更新するAsset情報
 * @returns {number} AssetID
 */
export async function updateAsset(getEndpoint, endpoint, assetChange) {
  // 変更前の状態を保持
  const beforeAsset = await assetRetrieve({ endpoint: getEndpoint, id: assetChange.items[0].id });

  const updateAssetResult = await putApiGateway(endpoint, assetChange);

  // 更新情報が反映されるまで待ち受ける
  let isUpdated = false;
  while (!isUpdated) {
    // 1秒間隔で更新されたか確認
    await sleep(1000);

    const checkAsset = await assetRetrieve({ endpoint: getEndpoint, id: assetChange.items[0].id });
    if (beforeAsset.lastUpdatedTime < checkAsset.lastUpdatedTime) {
      // 最終更新日時が変更された場合、更新されたと判断
      isUpdated = true;
    }
  }

  return updateAssetResult.items[0].id;
}

/**
 * アセットを削除する。
 * @param {string} endpoint エンドポイント
 * @param {object} requestBody Delete assetsのリクエストBody
 */
export async function deleteAsset(endpoint, requestBody) {
  await deleteApiGateway(endpoint, requestBody);
}

/**
 * File取得（ID指定）
 * @param {string} endpoint エンドポイント
 * @param {number} id FileID
 * @param {string} externalId ExternalId
 * @returns {object} File(※Fileが存在しない場合、undefined)
 */
export async function fileRetrieve({ endpoint, id, externalId }) {
  let file;
  try {
    if (id) {
      const url = `${endpoint}/${id}`;
      const files = await getApiGateway(url);
      file = files;
    } else if (externalId) {
      // externalIdの場合、files.listで取得
      const files = await postApiGateway(endpoint, { filter: { externalIdPrefix: externalId } });
      file = files.items[0];
    }
  } catch (e) {
  }
  return file;
}

/**
 * 全てのFile取得
 * @param {string} endpoint エンドポイント
 * @param {object} filter cogniteSDK files.listのfiles Object
 */
export async function getAllFiles(endpoint, filter) {
  const allFiles = [];
  let files = await postApiGateway(endpoint, { filter });
  allFiles.push(...files.items);

  let nextCursor = files.nextCursor;
  while (nextCursor) {
    files = await postApiGateway(endpoint, { filter, cursor: nextCursor });
    allFiles.push(...files.items);
    nextCursor = files.nextCursor;
  }
  return allFiles;
}

/**
 * ファイルを削除する。
 * @param {string} endpoint エンドポイント
 * @param {Array} deleteFileList 削除するファイル情報リスト
 */
export async function deleteFiles(endpoint, deleteFileList) {
  await deleteApiGateway(endpoint, { items: deleteFileList });
}

/**
 * ファイルのダウンロード用URLを取得する。
 * @param {string} endpoint エンドポイント
 * @param {number} id ファイルD
 * @returns 取得できた場合、ダウンロード用URLを返却する。取得できなかった場合、undefinedを返却する。
 */
export async function getDownloadUrl(endpoint, id) {
  const downloadUrls = await postApiGateway(endpoint, { items: [{ id }] });
  return downloadUrls.items.length > 0 ? downloadUrls.items[0].downloadUrl : undefined;
}

/**
 * Event取得（ID指定）
 * @param {object} object
 * @param {string} object.endpoint エンドポイント
 * @param {number | undefined} object.id EventID
 * @param {string | undefined} object.externalId ExternalId
 * @returns {Promise<object>} Event(※Eventが存在しない場合、undefined)
 */
export async function eventRetrieve({ endpoint, id, externalId }) {
  let event;
  try {
    if (id) {
      const url = `${endpoint}/${id}`;
      event = await getApiGateway(url);
    } else if (externalId) {
      const events = await postApiGateway(endpoint, { filter: { externalIdPrefix: externalId } });
      event = events.items[0];
    }
  } catch (e) {
  }
  return event;
}

/**
 * 全てのEvent取得
 * @param {string} endpoint エンドポイント
 * @param {object} filter cogniteSDK events.listのfiles Object
 * @param {object} sort cogniteSDK events.listのsort Object
 */
export async function getAllEvents(endpoint, filter, sort = undefined) {
  const allEvents = [];
  let events;
  if (sort) {
    events = await postApiGateway(endpoint, { filter, sort: [sort] });
  } else {
    events = await postApiGateway(endpoint, { filter });
  }
  allEvents.push(...events.items);

  let nextCursor = events.nextCursor;
  while (nextCursor) {
    if (sort) {
      events = await postApiGateway(endpoint, { filter, sort: [sort], cursor: nextCursor });
    } else {
      events = await postApiGateway(endpoint, { filter, cursor: nextCursor });
    }
    allEvents.push(...events.items);
    nextCursor = events.nextCursor;
  }
  return allEvents;
}

/**
 * イベントを更新する。
 * @param {string} endpoint エンドポイント
 * @param {Array} deleteEventId 更新するイベントのID
 */
export async function deleteEvent(endpoint, deleteEventId) {
  await deleteApiGateway(endpoint, deleteEventId);
}

/**
 * fileに紐づくprojectListを取得する
 * @param {Array} fileIdList
 * @returns {object} projectList
 */
export async function getProjectList(fileIdList) {
  const projectsList = [];
  await Promise.all(
    fileIdList.map(async (fileId) => {
      projectsList.push(await getProjectData(fileId));
    }),
  );
  sortName(projectsList);
  return projectsList;
}

/**
 * fileに紐づくprojectDataを取得する
 * @param {number} fileId
 * @returns {object} projectData
 */
async function getProjectData(fileId) {
  const dlAssetId = await getDlAssetId();
  const list = [];
  const file = await fileRetrieve({ endpoint: EP_PATH_LEARNING_PJ_FILES, id: fileId });
  if (file) {
    await Promise.all(
      file.assetIds.map(async (id) => {
        const asset = await assetRetrieve({ endpoint: EP_PATH_LEARNING_PJ, id });
        if (asset && asset.parentId === dlAssetId) {
          list.push(asset.name);
        }
      }),
    );
    return { id: file.id, name: file.name, projects: list };
  }
  return {};
}

/**
 * assetIDの削除
 * @param {string} endpoint エンドポイント
 * @param {number} fileId FileID
 * @param {number} assetId 削除するassetID
 */
export async function removeAssetId(endpoint, fileId, assetId) {
  await putApiGateway(
    endpoint,
    {
      items: [{
        id: fileId,
        update: { assetIds: { remove: [assetId] } },
      }],
    },
  );
}

/**
 * ファイル情報をアップロードする
 * @param {string} endpoint エンドポイント
 * @param {Object} fileInfo ファイル情報
 * @param {string} fileContent ファイルコンテンツ
 * @returns アップロードしたファイルのID
 */
export async function uploadFile(endpoint, fileInfo, fileContent) {
  const uploadFileResult = await postApiGateway(endpoint, { bodyData: fileInfo, fileContent });
  return uploadFileResult.id;
}

/**
 * イベントを作成する。
 * @param {string} endpoint エンドポイント
 * @param {object} event イベント
 * @returns 作成したイベントのID
 */
export async function createEvent(endpoint, event) {
  const createEventResult = await postApiGateway(endpoint, { items: [event] });
  return createEventResult.items[0].id;
}

/**
 * 全てのTimeSeriesの取得
 * @param {string} endpoint エンドポイント
 * @param {object} filter cogniteSDK timeseries.listのfiles Object
 * @returns {Promise<Array>} AllTimeSeries
 */
export async function getAllTimeSeries(endpoint, filter) {
  const allTimeSeries = [];
  let timeSeries = await postApiGateway(endpoint, { filter });
  allTimeSeries.push(...timeSeries.items);

  let nextCursor = timeSeries.nextCursor;
  while (nextCursor) {
    timeSeries = await postApiGateway(endpoint, { filter, cursor: nextCursor });
    allTimeSeries.push(...timeSeries.items);
    nextCursor = timeSeries.nextCursor;
  }
  return allTimeSeries;
}

/**
 * 全てのDataPointの取得
 * 1つのタイムシリーズに対してのデータポイントの取得処理
 * 複数のタイムシリーズに対しての全件取得（ネクストカーソルを使用しての取得処理)は非対応
 * @param {string} endpoint エンドポイント
 * @param {object} request cogniteSDK timeseries.data.listのRequestBody Object
 * @returns {Promise<Array>} AllDataPoint
 */
export async function getAllDataPoints(endpoint, request) {
  const allDataPoints = [];
  let timeSeries = await postApiGateway(endpoint, request);
  allDataPoints.push(...timeSeries.items[0].datapoints);

  let nextCursor = timeSeries.items[0].nextCursor;
  while (nextCursor) {
    request.items[0].cursor = nextCursor;
    timeSeries = await postApiGateway(endpoint, request);
    allDataPoints.push(...timeSeries.items[0].datapoints);
    nextCursor = timeSeries.items[0].nextCursor;
  }
  return allDataPoints;
}

export default {
  assetRetrieve,
  getAllAssets,
  updateAsset,
  deleteAsset,
  fileRetrieve,
  getAllFiles,
  deleteFiles,
  getDownloadUrl,
  eventRetrieve,
  getAllEvents,
  deleteEvent,
  getProjectList,
  removeAssetId,
  uploadFile,
  createEvent,
  getAllTimeSeries,
  getAllDataPoints,
};
