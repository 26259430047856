/**
 * Pointクラス
 */
class Point {
  /*
   * クラスメソッド
   */

  /**
   * 2点の差分を計算する
   * @param a PointA
   * @param b PointB
   * @returns 差分
   */
  static distance(a: Point, b: Point): number {
    const dx = a.x - b.x;
    const dy = a.y - b.y;

    return Math.sqrt(dx ** 2 + dy ** 2);
  }

  /*
   * メンバ変数
   */

  /** X座標 */
  x: number;

  /** Y座標 */
  y: number;

  /*
   * コンストラクタ
   */
  constructor(x = 0, y = 0) {
    this.x = x || 0;
    this.y = y || 0;
  }

  /*
   * メンバメソッド
   */

  /**
   * 加算
   * @param p Point
   * @returns 加算したPointオブジェクト
   */
  add(p: Point): Point {
    this.x += p.x;
    this.y += p.y;
    return this;
  }

  /**
   * 減算
   * @param p Point
   * @returns 減算したPointオブジェクト
   */
  subtract(p: Point): Point {
    this.x -= p.x;
    this.y -= p.y;
    return this;
  }

  /**
   * ディープコピーしたオブジェクト取得
   * @returns ディープコピーしたオブジェクト
   */
  clone(): Point {
    return new Point(this.x, this.y);
  }
}

export { Point };
