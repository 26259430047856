/* eslint-disable react/prefer-stateless-function */
// eslint警告未対応
import React from 'react';
import { MimeType } from '../../utils/File/BaseFile';

const { Html } = MimeType;

class AnnotationTool extends React.Component {
  render() {
    return (
      <div>
        <object aria-labelledby="annotationTool" data="./via.html" type={Html} width="100%" height="900px" className="via" />
      </div>
    );
  }
}

export default AnnotationTool;
