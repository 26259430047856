import React, {
  useState, useEffect, useImperativeHandle, forwardRef,
} from 'react';
import { Progress, Statistic } from 'antd';

import { calculatePercentage } from '../../../utils/common/calculation';

/** ファイルアップロードプログレスコンポーネント公開メソッド */
interface FileUploadProgressHandle {
  countUp: () => void,
  reset: () => void,
}

/**
 * ファイルアップロードプログレスコンポーネントプロパティ
 */
interface FileUploadProgressProps {
  totalCount: number; // アップロード対象ファイル数
}

/**
 * ファイルアップロードプログレスコンポーネント
 */
const FileUploadProgress: React.ForwardRefRenderFunction<
  FileUploadProgressHandle,
  FileUploadProgressProps
> = (props, ref) => {
  const [uploadedCount, setUploadedCount] = useState<number>(0);
  const [progressRate, setProgressRate] = useState<number>(0);

  const { totalCount } = props;

  useImperativeHandle(ref, () => ({
    // アップロード完了数は呼び出し元から受け取る
    countUp() {
      setUploadedCount((prev) => prev + 1);
    },

    reset() {
      setUploadedCount(0);
    },
  }));

  // アップロード完了ファイル数、アップロード対象ファイル数が変更された時の処理
  useEffect(() => {
    // パーセンテージを計算
    const newProgressRate = calculatePercentage(uploadedCount, totalCount);
    setProgressRate(newProgressRate);
  }, [uploadedCount, totalCount]);

  return (
    <>
      <Progress type="circle" status="active" percent={progressRate} />
      <Statistic value={uploadedCount} suffix={`/ ${totalCount}`} />
    </>
  );
};

export default forwardRef(FileUploadProgress);
