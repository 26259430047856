/* eslint-disable class-methods-use-this */
import React from 'react';
import {
  Layout, Col, Row, Typography,
} from 'antd';
import { ImagesListViewer, LIST_TYPE } from '../util/ImagesListViewer';
import { FileDetailView } from '../Common/File/FileDetailView';
import AssetTree from '../Common/Asset/AssetTree';
import { getResultAssetId } from '../../utils/storageCommon';
import { ResourceType } from '../../utils/common/SDFDataType';
import { DETECT_RESULT } from '../Common/File/FileTable';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';

const { Content } = Layout;
const { Title } = Typography;

/**
 * 検出結果確認画面component
 * @property {object} client CogniteClient
 * @property {function} setLoadingFlag Load Flag設定関数
 */
class DetectResult extends React.Component {
  constructor(props) {
    super(props);
    this.imagesListViewerRef = React.createRef();
  }

  state = {
    resultAssetId: null,
    selectedAssetID: null,
    selectedAssetTitle: null,
    selectedResourceType: null,
    selectedFileId: null,
    isModalEditButtonDisabled: true,
    isModalDownloadButtonDisabled: true,
    isReadDetectResultDisabled: true,
    isReloadDetectResultDisabled: true,
    isDefectChangeDisabled: true,
    isImageChangeDisabled: true,
  };

  /**
   * component render前の処理
   */
  async componentDidMount() {
    this.props.setLoadingFlag(true);

    const resultAssetId = await getResultAssetId();

    const { AI_DETECT_RESULT } = AUTHENTICATION_TYPE_MATRIX;
    const {
      EXPANDED_MODAL_EDIT_BUTTON,
      EXPANDED_MODAL_DOWNLOAD_BUTTON,
      DETECTION_RESULTS_LIST_DETECT_RESULT_LINK,
      DETECTION_RESULT_LIST_UPDATE_BUTTON,
      DETECTION_RESULT_DETAILS_PRESENCE_OR_ABSENCE_OF_DEFECTS,
      DETECTION_RESULT_DETAILS_PREVIOUS_OR_NEXT_IMAGE,
    } = AI_DETECT_RESULT;

    this.setState({
      resultAssetId,
      isModalEditButtonDisabled: !await containsUIAuthType(EXPANDED_MODAL_EDIT_BUTTON),
      isModalDownloadButtonDisabled: !await containsUIAuthType(EXPANDED_MODAL_DOWNLOAD_BUTTON),
      isReadDetectResultDisabled: !await containsUIAuthType(DETECTION_RESULTS_LIST_DETECT_RESULT_LINK),
      isReloadDetectResultDisabled: !await containsUIAuthType(DETECTION_RESULT_LIST_UPDATE_BUTTON),
      isDefectChangeDisabled: !await containsUIAuthType(DETECTION_RESULT_DETAILS_PRESENCE_OR_ABSENCE_OF_DEFECTS),
      isImageChangeDisabled: !await containsUIAuthType(DETECTION_RESULT_DETAILS_PREVIOUS_OR_NEXT_IMAGE),
    });
    this.props.setLoadingFlag(false);
  }

  /**
   * 前画像送りボタンクリック時のイベントハンドラ
   * @param {number} fileId 選択されたImageのID
   */
  onClickPrevImage = async (fileId) => {
    const prevImageId = this.imagesListViewerRef.current.prevImageId(fileId);
    this.setState({ selectedFileId: prevImageId });
  };

  /**
   * 次画像送りボタンクリック時のイベントハンドラ
   * @param {number} fileId 選択されたImageのID
   */
  onClickNextImage = async (fileId) => {
    const nextImageId = this.imagesListViewerRef.current.nextImageId(fileId);
    this.setState({ selectedFileId: nextImageId });
  };

  /**
   * Asset選択時のイベントハンドラ
   * @param {object}} detectionResult 選択されたAI検出結果
   */
  onSelectAsset = async (detectionResult) => {
    const { isReadDetectResultDisabled } = this.state;
    if (isReadDetectResultDisabled) return;

    const { id, name } = detectionResult;

    this.setState({
      selectedAssetID: id,
      selectedAssetTitle: name,
      selectedResourceType: ResourceType.AIDetectResult,
      selectedFileId: null,
    });
  };

  /**
   * Imageクリック時のイベントハンドラ
   * @param {number} fileId 選択されたImageのID
   */
  onClickImage = async (fileId) => {
    this.setState({
      selectedFileId: fileId,
    });
  };

  /**
   * ファイル一覧チェックボックスの活性状態判定処理
   * AI検出結果画面では常にチェックボックスを非活性にする。
   * @returns 判定結果(true: 非活性 false: 活性)
   */
  needsDisabledCheckbox = () => true;

  render() {
    const {
      resultAssetId,
      selectedAssetID,
      selectedAssetTitle,
      selectedFileId,
      selectedResourceType,
      isModalEditButtonDisabled,
      isModalDownloadButtonDisabled,
      isReadDetectResultDisabled,
      isReloadDetectResultDisabled,
      isDefectChangeDisabled,
      isImageChangeDisabled,
    } = this.state;

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <div className="facility-tree">
          <AssetTree
            client={this.props.client.client}
            parentAssetsId={resultAssetId}
            title="検出結果"
            onSelect={(detectionResult) => this.onSelectAsset(detectionResult)}
            collapsed
            menuCollapsed={this.props.menuCollapsed}
            rootResourceType={ResourceType.AIDetectResult}
            isExpandedRoot
            isReloadDisabled={isReloadDetectResultDisabled}
            isReadDisabled={isReadDetectResultDisabled}
          />
        </div>

        <Content>
          {
            selectedAssetID && (
              <>
                <Row>
                  <Title level={4} style={{ margin: '10px' }}>{selectedAssetTitle}</Title>
                </Row>
                <Row>
                  <Col span={17}>
                    <ImagesListViewer
                      client={this.props.client}
                      listType={LIST_TYPE.JPEG_ONLY}
                      assetId={selectedAssetID}
                      resourceType={selectedResourceType}
                      showDelButton={false}
                      inActiveCheckbox={this.needsDisabledCheckbox}
                      onClickImage={this.onClickImage}
                      customStyle={{ cardStyle: { height: '1030px', marginLeft: '10px' }, fileTableHeight: '845px', fileListHeight: '780px' }}
                      ref={this.imagesListViewerRef}
                      fileNameStatus={DETECT_RESULT}
                      isModalEditButtonDisabled={isModalEditButtonDisabled}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                      showDetectColumns
                      showSearchMetadata
                    />
                  </Col>
                  <Col span={7}>
                    <FileDetailView
                      resourceType={selectedResourceType}
                      fileId={selectedFileId}
                      isSetDefect
                      customStyle={{ cardStyle: { height: '1030px', marginRight: '10px' }, detailsListHeight: '770px' }}
                      isModalEditButtonDisabled={isModalEditButtonDisabled}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                      isDefectChangeDisabled={isDefectChangeDisabled}
                      isImageChangeDisabled={isImageChangeDisabled}
                      onClickPrevImage={this.onClickPrevImage}
                      onClickNextImage={this.onClickNextImage}
                    />
                  </Col>
                </Row>
              </>
            )
          }
        </Content>
      </Layout>
    );
  }
}

export default DetectResult;
