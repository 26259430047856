import {
  Asset,
  AssetChange,
  AssetChangeById,
  AssetDescription,
  AssetName,
  AssetSource,
  CogniteExternalId,
  CogniteInternalId,
  ExternalAssetItem,
  ItemsResponse,
  Label,
  Metadata,
} from '@cognite/sdk';

import BaseFile from '../File/BaseFile';
import { sleep } from '../common';
import { postApiGateway, putApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_MANAGED_FACILITY,
  EP_PATH_FACILITY,
  EP_PATH_TEACHER_DATA,
} from '../AWS/EndpointPath';
import { ResourceType } from '../common/SDFDataType';

abstract class BaseAsset implements Asset {
  static TOP_FLAG = 'true';

  /*
   * メンバ変数
   */
  id: CogniteInternalId;

  externalId?: CogniteExternalId;

  name: AssetName;

  rootId: CogniteInternalId;

  parentId?: CogniteInternalId;

  parentExternalId?: CogniteExternalId;

  description?: AssetDescription;

  metadata?: Metadata;

  source?: AssetSource;

  dataSetId?: CogniteInternalId;

  labels?: Label[];

  createdTime: Date;

  lastUpdatedTime: Date;

  parentResourceType?: ResourceType;

  get type(): string {
    return this.metadata && this.metadata.assetType
      ? this.metadata.assetType
      : 'unknown';
  }

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      this.id = asset.id;
      this.externalId = asset.externalId;
      this.name = asset.name;
      this.rootId = asset.rootId;
      this.parentId = asset.parentId;
      this.parentExternalId = asset.parentExternalId;
      this.description = asset.description;
      this.metadata = asset.metadata;
      this.source = asset.source;
      this.dataSetId = asset.dataSetId;
      this.labels = asset.labels;
      this.createdTime = asset.createdTime;
      this.lastUpdatedTime = asset.lastUpdatedTime;
    } else {
      this.id = 0;
      this.name = '';
      this.rootId = 0;
      this.createdTime = new Date();
      this.lastUpdatedTime = new Date();
    }
  }

  /*
   * メンバメソッド
   */
  abstract cloneSelf(): BaseAsset;

  abstract loadChildrenFromCDF(): Promise<BaseAsset[]>;

  async loadLinkedFilesFromCDF(): Promise<BaseFile[]> {
    return BaseFile.loadFilesLinkedToFacilityId(this.id);
  }

  /**
   * アセット情報保存
   */
  async saveSelfToCDF(): Promise<void> {
    let endpoint: string;
    if (this.metadata?.assetType === 'equipment') {
      // managed-facility
      endpoint = EP_PATH_MANAGED_FACILITY;
    } else if ((this.metadata?.assetType === 'training')
      || (this.parentResourceType && this.parentResourceType === 'training')) {
      // teacher-data
      endpoint = EP_PATH_TEACHER_DATA;
    } else {
      // facility
      endpoint = EP_PATH_FACILITY;
    }

    if (this.id > 0) {
      const change: AssetChange = {
        id: this.id,
        update: {
          name: { set: this.name },
        },
      };

      if (this.externalId) {
        change.update.externalId = { set: this.externalId };
      } else {
        change.update.externalId = { setNull: true };
      }

      if (this.description) {
        change.update.description = { set: this.description };
      } else {
        change.update.description = { setNull: true };
      }

      // [TODO] 詳細情報のクリア（空にする）
      if (this.metadata) {
        change.update.metadata = { set: this.metadata };
      }

      if (this.source) {
        change.update.source = { set: this.source };
      } else {
        change.update.source = { setNull: true };
      }

      if (this.dataSetId) {
        change.update.dataSetId = { set: this.dataSetId };
      } else {
        change.update.dataSetId = { setNull: true };
      }

      // [TODO] ラベルの変更

      const assets = await putApiGateway<{ items: AssetChangeById[] }, ItemsResponse<Asset>>(
        endpoint, { items: [change] },
      );

      Object.assign(this, assets.items[0]);
    } else {
      const item: ExternalAssetItem = {
        externalId: this.externalId,
        name: this.name,
        parentId: this.parentId,
        dataSetId: this.dataSetId,
        labels: this.labels,
        description: this.description,
        metadata: this.metadata,
        source: this.source,
      };

      const assets = await postApiGateway<{ items: ExternalAssetItem[] }, ItemsResponse<Asset>>(
        endpoint, { items: [item] },
      );
      Object.assign(this, assets.items[0]);
    }

    await sleep(2000);
  }
}

export default BaseAsset;
