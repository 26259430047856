import React, { useCallback, useState, useEffect } from 'react';
import { CogniteClient, FileInfo } from '@cognite/sdk';
import { Col, Modal, message } from 'antd';

import { sortName, sortDateTime } from '../../../utils/sort';
import { getAllFiles } from '../../../utils/dataAccess';
import { MimeType } from '../../../utils/File/BaseFile';
import { ImageGallery } from './parts/ImageGallery';
import { getFileListEndpoint, ResourceType, getFileEndpoint } from '../../../utils/common/SDFDataType';
import {
  ERROR_FILES_RETRIEVE,
} from '../../../utils/messages';
import {
  getApiGateway,
} from '../../../utils/AWS/ApiGateway';

const MODAL_WIDTH = 1900;
const MODAL_HEIGHT = 980;

export const ImageCompareView: React.FC<{
  visible: boolean;
  client: CogniteClient;
  assetId: number | null;
  resourceType: ResourceType | null;
  fileIdList?: number[];
  onClose: () => void;
}> = (props) => {
  /*
   * 変数/定数宣言
   */
  const [compareFileId1, setCompareFileId1] = useState<number>();
  const [compareFileId2, setCompareFileId2] = useState<number>();
  const [isSetting, setIsSetting] = useState<boolean>(false);
  const [filesList, setFilesList] = useState<FileInfo[]>([]);

  const {
    visible,
    client,
    assetId,
    resourceType,
    fileIdList,
    onClose,
  } = props;

  /**
   * ソート済画像リスト取得
   * @param {number} targetAssetId 対象アセットID
   * @returns {Promise<FileInfo[] | undefined>} ソート済画像リスト
   */
  const getSortAllFiles = useCallback(async (
    targetAssetId: number,
  ): Promise<FileInfo[] | undefined> => {
    // 画像リスト作成
    const jpgFilter = { assetIds: [targetAssetId], mimeType: MimeType.Jpeg, uploaded: true };
    const pngFilter = { assetIds: [targetAssetId], mimeType: MimeType.Png, uploaded: true };
    let jpgFiles;
    let pngFiles;
    try {
      const endpoint = getFileListEndpoint(resourceType as ResourceType);
      jpgFiles = await getAllFiles(endpoint, jpgFilter);
      pngFiles = await getAllFiles(endpoint, pngFilter);
    } catch (ex) {
      return undefined;
    }
    const allFiles = jpgFiles.concat(pngFiles);

    // 撮影日時があるリスト、撮影日時がないリストを作成
    const dateTimeSortList: FileInfo[] = [];
    const nameSortList: FileInfo[] = [];
    allFiles.forEach((value: FileInfo) => {
      if (value.metadata && value.metadata['撮影日時']) {
        dateTimeSortList.push(value);
      } else {
        nameSortList.push(value);
      }
    });
    // 撮影日時順のリストの後に、名前順のリストを追加
    sortDateTime(dateTimeSortList);
    sortName(nameSortList);

    const sortAllFiles = dateTimeSortList.concat(nameSortList);

    return sortAllFiles;
  }, [resourceType]);

  /*
   * イベントハンドラ
   */
  useEffect(() => {
    if (!visible) return () => { /* 何もしない */ };

    let canceled = false;
    setIsSetting(false);

    (async () => {
      const compareFileId: FileInfo[] = [];
      if (fileIdList) {
        await Promise.all(
          fileIdList.map(async (fileId) => {
            try {
              const endpoint = getFileEndpoint(resourceType as ResourceType);
              const url = `${endpoint}/${fileId}`;
              const checkFile = await getApiGateway<FileInfo>(url);
              if (!checkFile) { throw new Error(); }
              if (
                checkFile.mimeType === MimeType.Jpeg || checkFile.mimeType === MimeType.Png
              ) {
                compareFileId.push(checkFile);
              }
            } catch (e) {
              message.error(ERROR_FILES_RETRIEVE);
            }
          }),
        );
      }

      // 撮影日時があるリスト、撮影日時がないリストを作成
      const dateTimeSortList = compareFileId.filter(
        (value: FileInfo) => (value.metadata && value.metadata['撮影日時']),
      );
      const nameSortList = compareFileId.filter(
        (value: FileInfo) => (!value.metadata || !value.metadata['撮影日時']),
      );

      // 撮影日時順のリストの後に、名前順のリストを追加
      sortDateTime(dateTimeSortList);
      sortName(nameSortList);
      const sortAllFiles = dateTimeSortList.concat(nameSortList);

      if (!assetId) return;
      const sortAllFilesList = await getSortAllFiles(assetId);

      // 初期表示写真
      const firstFileId1 = sortAllFiles[0] ? sortAllFiles[0].id : undefined;
      const firstFileId2 = sortAllFiles[1] ? sortAllFiles[1].id : firstFileId1;

      if (!canceled) {
        setCompareFileId1(firstFileId1);
        setCompareFileId2(firstFileId2);
        setIsSetting(true);
        if (sortAllFilesList) {
          setFilesList(sortAllFilesList);
        }
      }
    })();

    return () => { canceled = true; };
  }, [fileIdList, visible, assetId, getSortAllFiles, resourceType]);

  /*
  * メソッド
  */

  /*
   * 画面描画
   */
  return (
    <>
      {
        isSetting && (
          <Modal
            visible={visible}
            onCancel={() => { onClose(); }}
            centered
            width={MODAL_WIDTH}
            footer={null}
            style={{ margin: '0', padding: '0', textAlign: 'center' }}
            bodyStyle={{ width: `${MODAL_WIDTH}px`, height: `${MODAL_HEIGHT}px` }}
          >
            <Col span={12}>
              <ImageGallery
                client={client}
                fileId={compareFileId1}
                resourceType={resourceType}
                isDisplayFilename
                isCompare
                disableKeyboard
                sortAllFiles={filesList}
                enlargeClassName="enlarge-left"
                enlargeStyle={{
                  height: '850px',
                  width: '926px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  top: '30px',
                  display: 'grid',
                  placeItems: 'center',
                }}
                zoomStyle={{ width: '912px', height: '912px' }}
              />
            </Col>
            <Col span={12}>
              <ImageGallery
                client={client}
                fileId={compareFileId2}
                resourceType={resourceType}
                isDisplayFilename
                isCompare
                sortAllFiles={filesList}
                enlargeClassName="enlarge-right"
                enlargeStyle={{
                  height: '850px',
                  width: '926px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  top: '30px',
                  display: 'grid',
                  placeItems: 'center',
                }}
                zoomStyle={{ width: '912px', height: '912px' }}
              />
            </Col>
          </Modal>
        )
      }
    </>
  );
};
