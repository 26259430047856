/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-class-component-methods */
// eslint警告未対応
import React from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Select,
  Spin,
} from 'antd';
import { LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  EP_PATH_LEARNING_MODEL_AI_MODEL_FILES,
  EP_PATH_LEARNING_MODEL_AI_MODEL_LIST,
  EP_PATH_LEARNING_MODEL_FILES_CONFIG_DOWNLOAD,
} from '../../../../utils/AWS/EndpointPath';
import { getStorageDataSetId } from '../../../../utils/storageCommon';
import { sortCreatedTime } from '../../../../utils/sort';
import { getAllFiles, fileRetrieve } from '../../../../utils/dataAccess';
import { ENGINE_VALUES, ENGINE_VALUES_SEG_A } from '../../../../utils/common/SDFDataType';
import { postApiGateway } from '../../../../utils/AWS/ApiGateway';

const { Option } = Select;

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const CONFIDENCE_VALUES = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9];

const SelectSection = ({ data, mode = null, disabled = false }) => (
  <Row>
    <Col span={12} style={{ width: '30%', textAlign: 'right', paddingTop: '15px' }}>
      {data.title}
      :
    </Col>
    <Col span={12} style={{ width: '70%', textAlign: 'left' }}>
      <Select
        ref={data.ref}
        mode={mode}
        defaultValue={data.default}
        onChange={data.onChange}
        disabled={disabled}
        style={{ width: '95%', paddingTop: '10px', marginLeft: '10px' }}
      >
        {data.options}
      </Select>
    </Col>
  </Row>
);
/**
 * ListCard component
 * @property { string } id ListCard id
 * @property { object } onClickDeleteListCard ListCard 削除関数
 */
class ListCard extends React.Component {
  constructor(props) {
    super(props);
    this.modelInput = React.createRef();
    this.classInput = React.createRef();
    this.confidenceInput = React.createRef();

    this.canceled = false;

    this.state = {
      modelLoad: false,
      classLoad: false,
      confidenceLoad: false,
    };
  }

  /**
   * component render前の処理
   */
  componentDidMount() {
    this.generateSelectBox();
  }

  componentWillUnmount() {
    this.canceled = true;
  }

  /**
   * 値の取得
   */
  getListCardValue = () => {
    const res = {
      engine: this.selectedEngine,
      model: this.selectedModel,
      class: this.selectedClass,
      confidence: this.selectedConfidence,
    };
    return res;
  };

  /**
   * SelectBox生成
   */
  generateSelectBox = async () => {
    this.generateEngine();
    await this.generateModel(this.selectedEngine);
    await this.generateClass(this.selectedModel);
    this.generateConfidence(this.selectedModel);
  };

  /**
   * EngineSelectBox生成
   */
  generateEngine = () => {
    const defaultValue = ENGINE_VALUES[ENGINE_VALUES_SEG_A].value;
    const options = ENGINE_VALUES
      .map((engine, index) => (
        <Option key={String(index)} value={engine.value}>{engine.value}</Option>
      ));

    if (this.canceled) return;

    this.setState({
      engine: {
        title: 'エンジン',
        default: defaultValue,
        onChange: this.onChangeEngine,
        options,
      },
    });
    this.selectedEngine = defaultValue;
  };

  /**
   * ModelSelectBox生成
   * @param {string} engine engineName
   * @param {boolean} change 変更Flag(default:false)
   */
  generateModel = async (engine, change = false) => {
    if (this.canceled) return;

    this.setState({
      modelLoad: true,
    });

    const type = 'model';
    const files = await this.getModelList(engine, type);
    let defaultValue = null;
    if (files.length > 0) {
      defaultValue = files[0].id;
    }

    if (change) {
      this.modelInput.current.rcSelect.state.value = [defaultValue];
    }

    const options = files.map((value, index) => (
      <Option key={String(index)} value={value.id}>{value.metadata.modelName}</Option>
    ));

    if (this.canceled) return;

    this.setState({
      model: {
        title: 'モデル',
        ref: this.modelInput,
        default: defaultValue,
        onChange: this.onChangeModel,
        options,
      },
      modelLoad: false,
    });
    this.selectedModel = defaultValue;
  };

  /**
   * ModelListの取得
   * @param {string} engine engineName
   * @param {string} type file type
   * @returns {object[]} ModelFileList
   */
  getModelList = async (engine, type) => {
    const dataSetId = getStorageDataSetId();
    const fileFilter = {
      metadata: { type, engine },
      dataSetIds: [{ id: dataSetId }],
    };
    const allFiles = await getAllFiles(EP_PATH_LEARNING_MODEL_AI_MODEL_LIST, fileFilter);
    sortCreatedTime(allFiles);
    return allFiles;
  };

  /**
   * ClassSelectBox生成
   * @param {number} modelId ModelId
   * @param {boolean} change 変更Flag(default:false)
   */
  generateClass = async (modelId, change = false) => {
    if (this.canceled) return;

    this.setState({
      classLoad: true,
    });

    const classes = await this.getClassList(modelId);

    const defaultValue = classes[0];

    if (change) {
      this.classInput.current.rcSelect.state.value = [defaultValue];
    }

    const options = classes
      .map((value, index) => <Option key={String(index)} value={value}>{value}</Option>);

    if (this.canceled) return;

    this.setState({
      stateClass: {
        title: 'クラス',
        ref: this.classInput,
        default: defaultValue,
        onChange: this.onChangeClass,
        options,
      },
      classLoad: false,
    });
    this.selectedClass = defaultValue;
  };

  /**
   * ClassListの取得
   * @param {number} modelId ModelId
   * @returns {string[]} ClassList
   */
  getClassList = async (modelId) => {
    let classList = [];
    if (modelId) {
      const file = await fileRetrieve({
        endpoint: EP_PATH_LEARNING_MODEL_AI_MODEL_FILES,
        id: modelId,
      });
      if (file) {
        const dlFiles = await postApiGateway(
          EP_PATH_LEARNING_MODEL_FILES_CONFIG_DOWNLOAD,
          { items: [{ id: Number(file.metadata.configId) }] },
        );
        const res = await fetch(dlFiles.items[0].downloadUrl);
        const configJson = await res.json();
        classList = configJson.coco.categories.map((category) => category.name);
      }
    }
    return classList;
  };

  /**
   * ConfidenceSelectBox生成
   * @param {number} modelId ModelId
   * @param {boolean} change 変更Flag(default:false)
   */
  generateConfidence = (modelId, change = false) => {
    if (this.canceled) return;

    this.setState({
      confidenceLoad: true,
    });

    let defaultValue = null;
    if (modelId) {
      [defaultValue] = CONFIDENCE_VALUES;
    }

    if (change) {
      this.confidenceInput.current.rcSelect.state.value = [defaultValue];
    }

    let options = [];
    if (defaultValue) {
      options = CONFIDENCE_VALUES
        .map((value, index) => <Option key={String(index)} value={value}>{value}</Option>);
    }

    if (this.canceled) return;

    this.setState({
      confidence: {
        title: '確信度',
        ref: this.confidenceInput,
        default: defaultValue,
        onChange: this.onChangeConfidence,
        options,
      },
      confidenceLoad: false,
    });
    this.selectedConfidence = defaultValue;
  };

  /**
 * EngineSelectBox変更時のイベントハンドラ
 * @param {string} value 選択されたEngine名
 */
  onChangeEngine = async (value) => {
    this.selectedEngine = value;
    await this.generateModel(value, true);
    await this.generateClass(this.selectedModel, true);
    this.generateConfidence(this.selectedModel, true);
  };

  /**
   * ModelSelectBox変更時のイベントハンドラ
   * @param {number} value 選択されたModelId
   */
  onChangeModel = async (value) => {
    this.selectedModel = value;
    await this.generateClass(value, true);
    this.generateConfidence(value, true);
  };

  /**
   * ClassSelectBox変更時のイベントハンドラ
   * @param {string} value 選択されたClass name
   */
  onChangeClass = (value) => {
    this.selectedClass = value;
  };

  /**
   * ConfidenceSelectBox変更時のイベントハンドラ
   * @param {number} value 選択されているConfidence
   */
  onChangeConfidence = (value) => {
    this.selectedConfidence = value;
  };

  render() {
    const {
      id,
      onClickDeleteListCard,
    } = this.props;

    const {
      engine,
      model,
      modelLoad,
      stateClass,
      classLoad,
      confidence,
      confidenceLoad,
    } = this.state;

    return (
      <Row>
        <Card bordered bodyStyle={{ paddingTop: '5px' }}>
          <Row style={{ textAlign: 'right' }}>
            <Col>
              <Button id={id} type="link" onClick={onClickDeleteListCard} style={{ padding: '0px' }}>
                <DeleteOutlined />
              </Button>
            </Col>
          </Row>
          {engine && (
            <SelectSection data={engine} />
          )}
          {model && (
            <Spin spinning={modelLoad} indicator={loadingIcon}>
              <SelectSection data={model} />
            </Spin>
          )}
          {stateClass && (
            <Spin spinning={classLoad} indicator={loadingIcon}>
              <SelectSection data={stateClass} />
            </Spin>
          )}
          {confidence && (
            <Spin spinning={confidenceLoad} indicator={loadingIcon}>
              <SelectSection data={confidence} />
            </Spin>
          )}
        </Card>
      </Row>
    );
  }
}

export default ListCard;
