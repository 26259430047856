/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unknown-property */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable object-curly-newline */
// eslint警告未対応
import React from 'react';
import {
  Layout, Tabs, Button, Modal, Spin, Tooltip, Row, message, Alert,
} from 'antd';
import {
  DeleteOutlined, CheckOutlined, CheckCircleOutlined, StopOutlined, LoadingOutlined,
} from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import TableFilter from 'react-table-filter';
import Queue from 'promise-queue';
import { sleep } from '../../utils/common';
import { getTrainTaskAssetId, getDetectTaskAssetId } from '../../utils/storageCommon';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import { LEANING_MODEL_ENGINE } from '../../utils/common/SDFDataType';
import { postApiGateway, putApiGateway, deleteApiGateway } from '../../utils/AWS/ApiGateway';
import {
  EP_PATH_ROOT_ASSET_DETECT_TASK,
  EP_PATH_LEARNING_MODEL_PARAMETER_FILES,
  EP_PATH_DETECT_TASK_AI_MODEL_FILES,
  EP_PATH_DETECT_TASK_ENSEMBLE_MODEL_FILES,
  EP_PATH_ROOT_ASSET_TRAIN_TASK,
  EP_PATH_TRAIN_TASK,
  EP_PATH_DETECT_TASK,
  EP_PATH_TRAIN_TASK_LIST,
  EP_PATH_DETECT_TASK_LIST,
  EP_PATH_TRAIN_TASK_FILES,
  EP_PATH_DETECT_TASK_FILES,
  EP_PATH_TRAIN_TASK_FILES_DOWNLOAD,
  EP_PATH_DETECT_TASK_FILES_DOWNLOAD,
  EP_PATH_LEARNING_MODEL_FILES_PARAMETER_DOWNLOAD,
} from '../../utils/AWS/EndpointPath';
import {
  VALIDATE_ERROR_TASK_STOP,
  VALIDATE_ERROR_TASK_START,
  ERROR_TASK_DELETE,
  WARNING_DELETE_TASK_DURING_LEARNING,
  WARNING_DELETE_TASK_DURING_DETECTION,
  ERROR_NO_AUTH_MESSAGE,
  ERROR_NO_TASK_PRIORITY_CHANGE_AUTH_MESSAGE,
  ERROR_LOAD_PARAMETER_FILE,
} from '../../utils/messages';
import da from '../../utils/dataAccess';
import './taskStyle.css';
import 'react-table-filter/lib/styles.css';

const { TabPane } = Tabs;
// アンサンブルモデルの場合、パラメータごとに確信度を設定しているため'-'固定
const CONFIDENCE_FOR_ENSEMBLE = '-';

/**
 * Task Status
 */
const TASK_STATUS = {
  UNTREATED: 'untreated',
  RUNNING: 'running',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

/**
 * Viewer Task Status(画面上でのタスクステータス)
 */
const VIEWER_TASK_STATUS = {
  UNTREATED: '実行待ち',
  RUNNING: '実行中',
  SUCCESS: '成功',
  FAILURE: '失敗',
};

/**
 * TAB LIST
 */
const TAB_LIST = {
  TRAIN_TASK: '学習タスク',
  DETECT_TASK: '検出タスク',
};

/**
 * ダイアログのボタンラベル
 */
const MODAL_BUTTON_TEXT = {
  DELETE: '削除',
  CANCEL: 'キャンセル',
};

/**
 * SERVER_STATUS
 */
const SERVER_STATUS = {
  UNKNOWN: 'unknown',
  STARTUP: 'startup',
  RUNNING: 'running',
  STOPPING: 'stopping',
  STOPPED: 'stopped',
};

/**
 * Viewer Server Status(画面上でのタスク制御モーダルのステータス)
 */
const VIEWER_SERVER_STATUS = {
  STARTUP: '起動中',
  RUNNING: '実行中',
  STOPPING: '実行停止中',
  STOPPED: '実行停止',
};

/**
 * COMMAND_TYPE
 */
const COMMAND_TYPE = {
  NONE: 'none',
  START: 'start',
  STOP: 'stop',
};

/**
 * Train Task Heads
 * key: 値を取得するkey value: ヘッダに表示する値  filterkey: filter設定時に指定
 * ※delete設定で削除ボタンの表示を行う
 */
const TRAIN_TASK_HEADS = [
  { key: 'priority', value: '優先度', filterkey: undefined, edit: false },
  { key: 'task', value: 'タスク', filterkey: undefined, edit: false },
  { key: 'created', value: '開始日時', filterkey: undefined, edit: false },
  { key: 'end', value: '終了日時', filterkey: undefined, edit: false },
  { key: 'engine', value: 'エンジン', filterkey: undefined, edit: false },
  { key: 'status', value: 'ステータス', filterkey: 'status', edit: false },
  { key: 'delete' },
];

/**
 * Detect Task Heads
 * key: 値を取得するkey value: ヘッダに表示する値  filterkey: filter設定時に指定
 * ※delete設定で削除ボタンの表示を行う
 */
const DETECT_TASK_HEADS = [
  { key: 'priority', value: '優先度', filterkey: undefined, edit: false },
  { key: 'task', value: 'タスク', filterkey: undefined, edit: false },
  { key: 'created', value: '開始日時', filterkey: undefined, edit: false },
  { key: 'end', value: '終了日時', filterkey: undefined, edit: false },
  { key: 'model', value: '学習モデル', filterkey: undefined, edit: false },
  { key: 'engine', value: 'エンジン', filterkey: undefined, edit: false },
  { key: 'class', value: 'クラス', filterkey: undefined, edit: false },
  { key: 'confidence', value: '確信度', filterkey: undefined, edit: false },
  { key: 'param', value: 'パラメータ', filterkey: undefined, edit: false },
  { key: 'status', value: 'ステータス', filterkey: 'status', edit: false },
  { key: 'delete' },
];

/**
 * ソート可能なリスト
 * @param {Array} rows 全てのTrainTask or DetectTask
 * @param {Array} heads 全てのヘッダー情報
 * @param {function} onClickDelete 削除ボタンクリック時のfunction
 * @param {function} onClickLink Linkクリック時のfunction
 * @param {boolean} isDeleteButtonDisabled 削除ボタンの非活性フラグ
 * @param {boolean} isParamDisabled パラメータリンクの非活性フラグ
 * @param {boolean} isPriorityChangeDisabled 優先度変更の非活性フラグ
 */
const SortableList = SortableContainer(({ rows, heads, onClickDelete, onClickLink, isDeleteButtonDisabled, isParamDisabled, isPriorityChangeDisabled }) => {
  return (
    <tbody>
      {rows.map((row, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          row={row}
          heads={heads}
          onClickDelete={onClickDelete}
          onClickLink={onClickLink}
          isDeleteButtonDisabled={isDeleteButtonDisabled}
          isParamDisabled={isParamDisabled}
          disabled={isPriorityChangeDisabled}
        />
      ))}
    </tbody>
  );
});

/**
 * ソート可能なアイテム
 * @param {object} row TrainTask or DetectTask
 * @param {object} head ヘッダー情報
 * @param {function} onClickDelete 削除ボタンクリック時のfunction
 * @param {function} onClickLink Linkクリック時のfunction
 * @param {boolean} isDeleteButtonDisabled 削除ボタンの非活性フラグ
 * @param {boolean} isParamDisabled パラメータリンクの非活性フラグ
 */
const SortableItem = SortableElement(({ row, heads, onClickDelete, onClickLink, isDeleteButtonDisabled, isParamDisabled }) =>
  <tr>
    {heads.map((head) =>
      <TdItem
        key={head.key}
        row={row}
        head={head}
        onClickDelete={onClickDelete}
        onClickLink={onClickLink}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
        isParamDisabled={isParamDisabled}
      />,
    )}
  </tr>,
);

/**
 * 固定表示のリスト
 * @param {Array} rows 全てのTrainTask or DetectTask
 * @param {Array} heads 全てのヘッダー情報
 * @param {function} onClickLink Linkクリック時のfunction
 * @param {function} onClickDelete 削除ボタンクリック時のfunction
 * @param {boolean} isDeleteButtonDisabled 削除ボタンの非活性フラグ
 * @param {boolean} isParamDisabled パラメータリンクの非活性フラグ
 */
const FixedList = ({ rows, heads, onClickLink, onClickDelete, isDeleteButtonDisabled, isParamDisabled }) => {
  return (
    <tbody>
      {rows.map((row, index) => (
        <tr key={index}>
          {heads.map((head) =>
            <TdItem
              key={head.key}
              row={row}
              head={head}
              onClickDelete={onClickDelete}
              onClickLink={onClickLink}
              isDeleteButtonDisabled={isDeleteButtonDisabled}
              isParamDisabled={isParamDisabled}
            />,
          )}
        </tr>
      ))}
    </tbody>
  );
};

/**
 * TD アイテム
 * @param {object} row TrainTask or DetectTask
 * @param {object} head ヘッダー情報
 * @param {function} onClickDelete 削除ボタンクリック時のfunction
 * @param {function} onClickLink Linkクリック時のfunction
 * @param {boolean} isDeleteButtonDisabled 削除ボタンの非活性フラグ
 * @param {boolean} isParamDisabled パラメータリンクの非活性フラグ
 */
const TdItem = ({ row, head, onClickDelete, onClickLink, isDeleteButtonDisabled, isParamDisabled }) => {
  const { key } = head;
  switch (key) {
    case 'delete':
      return <DeleteButton row={row} headKey={key} onClickDelete={onClickDelete} isDeleteButtonDisabled={isDeleteButtonDisabled} />;
    case 'status':
      return <StatusItem row={row} headKey={key} />;
    case 'param':
      return <ParamItem row={row} headKey={key} onClickLink={onClickLink} isParamDisabled={isParamDisabled} />;
    case 'config':
      return <ConfigItem row={row} headKey={key} onClickLink={onClickLink} />;
    case 'class':
      return <ClassItem row={row} headKey={key} />;
    default:
      return <DefaultItem row={row} headKey={key} />;
  }
};

/**
 * 削除ボタン
 * @param {object} row TrainTask or DetectTask
 * @param {object} headKey ヘッダのkey
 * @param {function} onClickDelete 削除ボタンクリック時のfunction
 * @param {boolean} isDeleteButtonDisabled 削除ボタンの非活性フラグ
 */
const DeleteButton = ({ row, headKey, onClickDelete, isDeleteButtonDisabled }) => {
  if (!row.nonDelete) {
    return (
      <td key={headKey} className="borderNone">
        <Tooltip title={isDeleteButtonDisabled ? ERROR_NO_AUTH_MESSAGE : '削除'}>
          <Button type="link" id={row.id} onClick={onClickDelete.bind(this, row.id)} disabled={isDeleteButtonDisabled}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      </td>
    );
  } else {
    return (
      <td key={headKey} className="borderNone"></td>
    );
  }
};

/**
 * ステータスアイテム
 * @param {object} row TrainTask or DetectTask
 * @param {object} headKey ヘッダのkey
 */
const StatusItem = ({ row, headKey }) => {
  if (row.logId !== 'None' && row.logId) {
    return (
      <td key={headKey} className="status">
        {row[headKey]}
      </td>
    );
  } else {
    return (
      <td key={headKey} className="status">{row[headKey]}</td>
    );
  }
};

/**
 * パラメータアイテム
 * @param {object} row TrainTask or DetectTask
 * @param {object} headKey ヘッダのkey
 * @param {function} onClickLink Linkクリック時のfunction
 * @param {boolean} isParamDisabled パラメータリンクの非活性フラグ
 */
const ParamItem = ({ row, headKey, onClickLink, isParamDisabled }) => {
  return (
    <td key={headKey} className="param">
      <Tooltip title={isParamDisabled && ERROR_NO_AUTH_MESSAGE}>
        <Button
          type="link"
          onClick={onClickLink.bind(this, 'param', row.parameterId)}
          style={{
            paddingLeft: '0px',
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            textAlign: 'left',
          }}
          disabled={isParamDisabled}
        >
          {row[headKey]}
        </Button>
      </Tooltip>
    </td>
  );
};

/**
 * コンフィグアイテム
 * @param {object} row TrainTask or DetectTask
 * @param {object} headKey ヘッダのkey
 * @param {function} onClickLink Linkクリック時のfunction
 */
const ConfigItem = ({ row, headKey, onClickLink }) => {
  return (
    <td key={headKey} className="config">
      <Button type="link" onClick={onClickLink.bind(this, 'config', row.configId)} style={{ paddingLeft: '0px' }}>
        {row[headKey]}
      </Button>
    </td>
  );
};

/**
 * クラスアイテム
 * @param {object} row TrainTask or DetectTask
 * @param {object} headKey ヘッダのkey
 */
const ClassItem = ({ row, headKey }) => {
  return (
    <td className="class">
      <ul key={`class-ui-${headKey}`}>
        {row[headKey].map((c) =>
          <li key={`${row.id}-${c}`}>{c}</li>,
        )}
      </ul>
    </td>
  );
};

/**
 * デフォルトアイテム
 * @param {object} row TrainTask or DetectTask
 * @param {object} headKey ヘッダのkey
 */
const DefaultItem = ({ row, headKey }) => {
  return <td key={headKey} className={headKey}>{row[headKey]}</td>;
};

/**
 * ヘッダ
 * @param {Array} rows 全てのTrainTask or DetectTask
 * @param {Array} heads 全てのヘッダー情報
 * @param {function} onFilterUpdate フィルター更新時のfunction
 */
const TableHead = ({ rows, heads, onFilterUpdate }) => {
  return (
    <thead>
      <TableFilter rows={rows} onFilterUpdate={onFilterUpdate}>
        {heads.map((head) =>
          head.key === 'delete' ?
            <th key={head.key} className="borderNone"></th>
            :
            <th key={head.key} filterkey={head.filterkey} className={head.key}>{head.value}</th>,
        )}
      </TableFilter>
    </thead>
  );
};

/**
 * DL Statusボタン
 * @param {boolean} loading 読み込み中Flag(default:false)
 * @param {function} onClick DL Statusボタンクリック時のfunction
 * @param {boolean} isDLStatusButtonDisabled DL Statusボタンの非活性フラグ
 */
const DlStatusButton = ({ loading, onClick, isDLStatusButtonDisabled }) => {
  return (
    <Tooltip title={isDLStatusButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
      <Button
        type="ghost"
        loading={loading}
        onClick={onClick}
        style={{ width: '200px', margin: '5px' }}
        disabled={isDLStatusButtonDisabled}
      >
        <CheckOutlined style={{ backgroundColor: '#1890ff', borderRadius: '50%' }} />
        タスク制御
      </Button>
    </Tooltip>
  );
};

/**
 * Status Icon
 * @param {string} status サーバのステータス
 */
const StatusIcon = ({ status }) => {
  if (SERVER_STATUS.RUNNING === status) {
    return <CheckCircleOutlined style={{ fontSize: '80px', color: 'green' }} />;
  } else if (SERVER_STATUS.STOPPED === status) {
    return <StopOutlined style={{ fontSize: '80px', color: 'blue' }} />;
  } else {
    return <LoadingOutlined style={{ fontSize: '80px' }} />;
  }
};

/**
 * タスク優先度変更アラート
 * @returns {JSX.Element} タスク優先度変更アラート
 */
const TaskPriorityChangeAlert = () => (
  <div className="task-priority-change-warning-message">
    <Alert message={ERROR_NO_TASK_PRIORITY_CHANGE_AUTH_MESSAGE} type="error" showIcon />
  </div>
);

/**
 * Task確認画面component
 * @property {function} setLoadingFlag Load Flag設定関数
 */
class Task extends React.Component {
  constructor(props) {
    super(props);
    this.onCancelModal = this.onCancelModal.bind(this);
  }

  state = {
    allTrainTasks: [],
    sortTrainTasks: [],
    fixedTrainTasks: [],
    allDetectTasks: [],
    sortDetectTasks: [],
    fixedDetectTasks: [],
    linkModalVisible: false,
    deleteModalVisible: false,
    linkTitle: '',
    linkText: '',
    itemName: '',
    deleteConfirmLoading: false,
    defaultTabKey: undefined,
    selectedTab: undefined,
    dlStatusLoad: false,
    trainTaskLoad: false,
    detectTaskLoad: false,
    statusModalVisible: false,
    assetType: undefined,
    status: undefined,
    statusModalFooter: [],
    taskModalStatus: undefined,
    isLearningTaskTabDisabled: true,
    isDetectTaskTabDisabled: true,
    isLearningTaskControlDisabled: true,
    isLearningTaskDeleteButtonDisabled: true,
    isLearningTaskPriorityChangeDisabled: true,
    isDetectTaskControlDisabled: true,
    isDetectTaskParameterDisabled: true,
    isDetectTaskDeleteButtonDisabled: true,
    isDetectTaskPriorityChangeDisabled: true,
  };

  /**
   * Task Statusを画面表示用に変換する。
   * @param {string} subtype サブタイプ(ステータス)
   * @returns 画面表示用ステータス
   */
  convertStatusForDisplay(subtype) {
    let displayStatus;
    switch (subtype) {
      case TASK_STATUS.UNTREATED:
        displayStatus = VIEWER_TASK_STATUS.UNTREATED;
        break;
      case TASK_STATUS.RUNNING:
        displayStatus = VIEWER_TASK_STATUS.RUNNING;
        break;
      case TASK_STATUS.SUCCESS:
        displayStatus = VIEWER_TASK_STATUS.SUCCESS;
        break;
      default: // TASK_STATUS.FAILURE
        displayStatus = VIEWER_TASK_STATUS.FAILURE;
        break;
    }

    return displayStatus;
  }

  /**
   * 優先度を取得する。
   * @param {string} subtype サブタイプ(ステータス)
   * @param {number} startTime 開始時間
   * @returns 優先度
   */
  getPriority(subtype, startTime) {
    return (TASK_STATUS.UNTREATED === subtype || TASK_STATUS.RUNNING === subtype) ? startTime : '-';
  }

  /**
   * TrainTaskソート時のイベントハンドラ
   * @param {number} oldIndex
   * @param {number} newIndex
   */
  onSortEndTrain = ({ oldIndex, newIndex }) => {
    this.setState(({ sortTrainTasks }) => ({
      sortTrainTasks: arrayMove(sortTrainTasks, oldIndex, newIndex),
    }));

    let priority = this.updatePriorityRunningTask([...this.state.fixedTrainTasks]);
    const list = [...this.state.sortTrainTasks];
    list.forEach((item) => {
      item.priority = priority;
      priority++;
    });

    this.updatePriority(list);

    this.setState({
      sortTrainTasks: list,
    });
  };

  /**
   * DetectTaskソート時のイベントハンドラ
   * @param {number} oldIndex
   * @param {number} newIndex
   */
  onSortEndDetect = ({ oldIndex, newIndex }) => {
    this.setState(({ sortDetectTasks }) => ({
      sortDetectTasks: arrayMove(sortDetectTasks, oldIndex, newIndex),
    }));

    let priority = this.updatePriorityRunningTask([...this.state.fixedDetectTasks]);
    const list = [...this.state.sortDetectTasks];
    list.forEach((item) => {
      item.priority = priority;
      priority++;
    });

    this.updatePriority(list);

    this.setState({
      sortDetectTasks: list,
    });
  };

  /**
   * 実行中タスクの優先度を更新する
   * @param {array} tasks 対象のタスク
   * @returns {number} 優先度
   */
  updatePriorityRunningTask(tasks) {
    const runningTask = tasks.filter((task) => task.status === VIEWER_TASK_STATUS.RUNNING);

    let priority = 1;
    runningTask.forEach((task) => {
      task.priority = priority;
      priority++;
    });

    this.updatePriority(runningTask);

    return priority;
  }

  /**
   * 優先度の更新
   * @param {object} tasks 更新対象のTasks
   */
  updatePriority = (tasks) => {
    const endpoint = this.tabKey === TAB_LIST.TRAIN_TASK ? EP_PATH_TRAIN_TASK : EP_PATH_DETECT_TASK;
    tasks.forEach(async (task) => {
      await putApiGateway(
        endpoint,
        { items: [{ id: task.id, update: { startTime: { set: task.priority } } }] },
      );
    });
  };

  /**
   * Linkクリック時のイベントハンドラ
   * @param {string} mode log or param
   * @param {string} id downloadFileId
   */
  onClickLink = async (mode, id) => {
    const endpoint = this.tabKey === TAB_LIST.TRAIN_TASK ? EP_PATH_TRAIN_TASK_FILES_DOWNLOAD : EP_PATH_DETECT_TASK_FILES_DOWNLOAD;
    const files = await postApiGateway(endpoint, { items: [{ id: Number(id) }] });
    const res = await fetch(files.items[0].downloadUrl);
    const linkText = await res.text();
    this.setState({
      linkModalVisible: true,
      linkTitle: mode,
      linkText,
    });
  };

  /**
   * Modalキャンセル時のイベントハンドラ
   */
  onCancelModal() {
    this.setState({
      linkModalVisible: false,
      deleteModalVisible: false,
      statusModalVisible: false,
    });
  }

  /**
   * 削除ボタンクリック時のイベントハンドラ
   */
  onClickDelete = async (id) => {
    let itemName = '';
    const endpoint = this.tabKey === TAB_LIST.TRAIN_TASK
      ? EP_PATH_TRAIN_TASK
      : EP_PATH_DETECT_TASK;
    const event = await da.eventRetrieve({ endpoint, id: Number(id) });

    this.delItem = event;
    itemName = event.description;

    const taskAsset = await this.getTaskAsset(this.tabKey);
    const status = this.getStatus(taskAsset.metadata.status, taskAsset.metadata.command);
    if (!this.isDeleteStatus(this.delItem.subtype, status)) {
      if (this.tabKey === TAB_LIST.TRAIN_TASK) {
        message.error(WARNING_DELETE_TASK_DURING_LEARNING);
      } else if (this.tabKey === TAB_LIST.DETECT_TASK) {
        message.error(WARNING_DELETE_TASK_DURING_DETECTION);
      }
      return;
    }
    this.setState({
      deleteModalVisible: true,
      itemName,
    });
  };

  /**
   * 削除可能か判定
   * @param {string} subtype
   * @param {string} status
   * @returns {boolean} true: 削除可能 / false: 削除不可
   */
  isDeleteStatus = (subtype, status) => {
    if (TASK_STATUS.SUCCESS === subtype || TASK_STATUS.FAILURE === subtype) {
      return true;
    }
    return SERVER_STATUS.STOPPED === status;
  };

  /**
   * 削除ＯＫボタンクリック時のイベントハンドラ
   */
  onOkDelete = async () => {
    this.setState({ deleteConfirmLoading: true });

    try {
      if (this.tabKey === TAB_LIST.TRAIN_TASK) {
        await this.deleteTrainTask();
        await sleep(1000);
        await this.setTrainTaskData();
      } else if (this.tabKey === TAB_LIST.DETECT_TASK) {
        await this.deleteDetectTask();
        await sleep(1000);
        await this.setDetectTaskData();
      }
    } catch (e) {
      if (e.message === 'Failed to fetch') {
        message.error(ERROR_TASK_DELETE + '-');
      } else {
        message.error(ERROR_TASK_DELETE + e.message);
      }
    }

    this.setState({
      deleteConfirmLoading: false,
      deleteModalVisible: false,
    });
  };

  /**
   * Train Taskの削除処理
   */
  deleteTrainTask = async () => {
    const taskId = this.delItem.id;
    await deleteApiGateway(EP_PATH_TRAIN_TASK, { items: [{ id: taskId }] });

    const logId = Number(this.delItem.metadata.logId);
    if (logId !== 'None' && logId) {
      await deleteApiGateway(EP_PATH_TRAIN_TASK_FILES, { items: [{ id: logId }] });
    }
  };

  /**
   * Detect Taskの削除処理
   */
  deleteDetectTask = async () => {
    const taskId = this.delItem.id;
    await deleteApiGateway(EP_PATH_DETECT_TASK, { items: [{ id: taskId }] });

    const parameterId = Number(this.delItem.metadata.parameterId);
    await deleteApiGateway(EP_PATH_DETECT_TASK_FILES, { items: [{ id: parameterId }] });

    const logId = Number(this.delItem.metadata.logId);
    if (logId !== 'None' && logId) {
      await deleteApiGateway(EP_PATH_DETECT_TASK_FILES, { items: [{ id: logId }] });
    }
  };

  /**
   * フィルター更新時のイベントハンドラ
   * @param {array} newData
   */
  onFilterUpdate = (newData) => {
    if (this.tabKey === TAB_LIST.TRAIN_TASK) {
      this.setState({
        allTrainTasks: newData,
        fixedTrainTasks: this.getFixedTasks(newData),
        sortTrainTasks: this.getSortTasks(newData),
      });
    } else if (this.tabKey === TAB_LIST.DETECT_TASK) {
      this.setState({
        allDetectTasks: newData,
        fixedDetectTasks: this.getFixedTasks(newData),
        sortDetectTasks: this.getSortTasks(newData),
      });
    }
  };

  /**
   * Tabクリック時のイベントハンドラ
   * @param {string} key
   */
  onTabClick = async (key) => {
    this.tabKey = key;
    await this.cachePutTabKey(key);
    this.setState({ selectedTab: key });
  };

  /**
   * Tableデータの設定
   */
  setTableData = async () => {
    const { isLearningTaskTabDisabled, isDetectTaskTabDisabled } = this.state;
    !isLearningTaskTabDisabled && await this.setTrainTaskData();
    !isDetectTaskTabDisabled && await this.setDetectTaskData();
  };

  /**
   * Train Taskデータの設定
   */
  setTrainTaskData = async () => {
    const allTrainTask = await this.getAllTrainTasks();

    this.setState({
      allTrainTasks: allTrainTask,
      fixedTrainTasks: this.getFixedTasks(allTrainTask),
      sortTrainTasks: this.getSortTasks(allTrainTask),
    });
  };

  /**
   * Detect Taskデータの設定
   */
  setDetectTaskData = async () => {
    const allDetectTasks = await this.getAllDetectTasks();

    this.setState({
      allDetectTasks,
      fixedDetectTasks: this.getFixedTasks(allDetectTasks),
      sortDetectTasks: this.getSortTasks(allDetectTasks),
    });
  };

  /**
   * 全てのTrainTaskを取得
   * @returns {Array} taskList
   */
  getAllTrainTasks = async () => {
    const id = await getTrainTaskAssetId();
    const eventFilter = { assetIds: [id] };
    const sort = 'createdTime:asc';
    const events = await da.getAllEvents(EP_PATH_TRAIN_TASK_LIST, eventFilter, sort);
    const trainTasks = [];
    events.forEach((item) => {
      // 画面に表示されるTask Statusを日本語表記に変換
      const convertSubtype = this.convertStatusForDisplay(item.subtype);

      trainTasks.push({
        id: item.id,
        priority: this.getPriority(item.subtype, item.startTime),
        task: item.description,
        created: this.getDateTime(item.createdTime),
        end: this.getDateTime(item.endTime),
        engine: item.metadata.engine,
        status: convertSubtype,
        logId: item.metadata.logId,
      });
    });

    return trainTasks;
  };

  /**
   * 全てのDetectTaskを取得
   * @returns {Array} taskList
   */
  getAllDetectTasks = async () => {
    const id = await getDetectTaskAssetId();
    const eventFilter = { assetIds: [id] };
    const sort = 'createdTime:asc';
    const events = await da.getAllEvents(EP_PATH_DETECT_TASK_LIST, eventFilter, sort);
    const detectTasks = [];
    events.forEach((item) => {
      // 画面に表示されるTask Statusを日本語表記に変換
      const convertSubtype = this.convertStatusForDisplay(item.subtype);

      detectTasks.push({
        id: item.id,
        priority: this.getPriority(item.subtype, item.startTime),
        task: item.description,
        created: this.getDateTime(item.createdTime),
        end: this.getDateTime(item.endTime),
        engine: item.metadata.engine,
        status: convertSubtype,
        logId: item.metadata.logId,
        parameterId: item.metadata.parameterId,
        ensembleModelFileId: item.metadata.ensembleModelFileId,
      });
    });

    const maxConcurrent = 14; // 並列実行するタスク数
    const queue = new Queue(maxConcurrent);
    let isFailedLoadParameterFile = false;
    const promises = detectTasks.map((task) => (
      queue.add(async () => {
        let modelName = '削除済み';
        let classList = [];
        let confidence = '';
        let parameterFileName = '';
        try {
          const parameterFile = await da.fileRetrieve({ endpoint: EP_PATH_LEARNING_MODEL_PARAMETER_FILES, id: task.parameterId });
          const downloadUrl = await postApiGateway(EP_PATH_LEARNING_MODEL_FILES_PARAMETER_DOWNLOAD, { items: [{ id: parameterFile.id }] });
          const res = await fetch(downloadUrl.items[0].downloadUrl);
          const param = await res.json();

          if (task.engine === LEANING_MODEL_ENGINE.ENSEMBLE) {
            const ensembleModelFile = await da.fileRetrieve({ endpoint: EP_PATH_DETECT_TASK_ENSEMBLE_MODEL_FILES, id: task.ensembleModelFileId });
            if (!ensembleModelFile.error) {
              modelName = ensembleModelFile.metadata.modelName;
            }
          } else {
            const modelFile = await da.fileRetrieve({ endpoint: EP_PATH_DETECT_TASK_AI_MODEL_FILES, id: param.modelId });
            if (!modelFile.error) {
              modelName = modelFile.name;
            }
          }
          classList = param.classList;
          confidence = task.engine === LEANING_MODEL_ENGINE.ENSEMBLE
            ? CONFIDENCE_FOR_ENSEMBLE
            : param.confidence;
          parameterFileName = parameterFile.name;
        } catch (e) {
          isFailedLoadParameterFile = true;
        }

        task.model = modelName;
        task.class = classList;
        task.confidence = confidence;
        task.param = parameterFileName;
      })
    ));

    await Promise.all(promises);

    if (isFailedLoadParameterFile) {
      message.error(ERROR_LOAD_PARAMETER_FILE);
    }

    return detectTasks;
  };

  /**
   * 日時の取得（unixTimeをdateに変換）
   * @param {number} unixTime
   * @returns {String} dateTime
   */
  getDateTime(unixTime) {
    if (unixTime) {
      const date = new Date(unixTime);
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }
    return '-';
  }

  /**
   * 並び替え可能（untreated）のタスクを取得
   * @param {Array} allTasks 全てのタスク
   * @returns {Array} sortTasks
   */
  getSortTasks = (allTasks) => {
    const sortTasks = [];
    allTasks.forEach((task) => {
      if (task.status === VIEWER_TASK_STATUS.UNTREATED) {
        sortTasks.push(task);
      }
    });
    sortTasks.sort((a, b) => { if (a.priority > b.priority) return 1; else return -1; });
    return sortTasks;
  };

  /**
   * 固定表示（untreated以外）のタスクを取得
   * @param {Array} allTasks 全てのタスク
   * @returns {Array} fixedTasks
   */
  getFixedTasks = (allTasks) => {
    const fixedTasks = [];
    allTasks.forEach((task) => {
      if (task.status !== VIEWER_TASK_STATUS.UNTREATED) {
        fixedTasks.push(task);
      }
    });
    return fixedTasks;
  };

  /**
   * TAB KEYの取得
   * @returns {string} TAB KEY
   */
  async getTabKey() {
    let tabkey = TAB_LIST.TRAIN_TASK;
    const cache = await caches.open('cache-data');
    const cacheData = await cache.match('/task_cache');
    if (cacheData) {
      const data = await cacheData.json();
      tabkey = data.tabKey;
    } else {
      await this.cachePutTabKey(tabkey);
    }
    return tabkey;
  }

  /**
   * TAB KEYのキャッシュへの追加
   * @param {string} tabkey
   */
  async cachePutTabKey(tabkey) {
    const cache = await caches.open('cache-data');
    const cacheData = {
      tabKey: tabkey,
    };
    cache.put('/task_cache', new Response(JSON.stringify(cacheData)));
  }

  /**
   * DL Statusボタンクリック時のイベントハンドラ
   */
  onClickStatus = async () => {
    this.setState({
      dlStatusLoad: true,
    });

    this.taskAsset = await this.getTaskAsset(this.tabKey);

    if (!this.taskAsset) {
      this.setState({
        dlStatusLoad: false,
      });
      return;
    }

    const { assetType } = this.taskAsset.metadata;
    const { status } = this.taskAsset.metadata;
    const { command } = this.taskAsset.metadata;
    const displayStatus = this.getStatus(status, command);
    let taskModalStatus;
    if (displayStatus === SERVER_STATUS.STARTUP) {
      taskModalStatus = VIEWER_SERVER_STATUS.STARTUP;
    } else if (displayStatus === SERVER_STATUS.RUNNING) {
      taskModalStatus = VIEWER_SERVER_STATUS.RUNNING;
    } else if (displayStatus === SERVER_STATUS.STOPPING) {
      taskModalStatus = VIEWER_SERVER_STATUS.STOPPING;
    } else if (displayStatus === SERVER_STATUS.STOPPED) {
      taskModalStatus = VIEWER_SERVER_STATUS.STOPPED;
    }

    const footer = [];
    footer.push(<Button key="stop-button" type="default" onClick={this.onClickStop} style={{ width: '200px', margin: '10px' }} disabled={this.isStopDisabled(displayStatus)}>停止</Button>);
    footer.push(<Button key="start-button" type="primary" onClick={this.onClickStart} style={{ width: '200px', margin: '10px' }} disabled={this.isStartDisabled(displayStatus)}>開始</Button>);

    this.setState({
      dlStatusLoad: false,
      statusModalVisible: true,
      assetType,
      status: displayStatus,
      statusModalFooter: footer,
      taskModalStatus,
    });
  };

  /**
   * Task Assetの取得
   * @param {string} tab
   * @returns {object} asset
   */
  async getTaskAsset(tab) {
    let id;
    let endpoint;
    if (TAB_LIST.TRAIN_TASK === tab) {
      id = await getTrainTaskAssetId();
      endpoint = EP_PATH_ROOT_ASSET_TRAIN_TASK;
    } else if (TAB_LIST.DETECT_TASK === tab) {
      id = await getDetectTaskAssetId();
      endpoint = EP_PATH_ROOT_ASSET_DETECT_TASK;
    } else {
      return undefined;
    }

    const asset = await da.assetRetrieve({ endpoint, id });
    return asset;
  }

  /**
   * Statusの取得
   * @param {string} status
   * @param {string} command
   * @returns {string} status
   */
  getStatus = (status, command) => {
    if (status === SERVER_STATUS.UNKNOWN) {
      return (command === COMMAND_TYPE.START) ? SERVER_STATUS.STARTUP : SERVER_STATUS.STOPPED;
    } else if (status === SERVER_STATUS.RUNNING) {
      return (command === COMMAND_TYPE.STOP) ? SERVER_STATUS.STOPPING : SERVER_STATUS.RUNNING;
    } else if (status === SERVER_STATUS.STOPPED) {
      return (command === COMMAND_TYPE.START) ? SERVER_STATUS.STARTUP : SERVER_STATUS.STOPPED;
    } else {
      return undefined;
    }
  };

  /**
   * Stopボタン無効判定
   * @param {string} status Status(startup/running/stopping/stopped)
   * @returns {boolean} true: ボタン無効 / false: ボタン有効
   */
  isStopDisabled = (status) => {
    return status === SERVER_STATUS.STOPPING || status === SERVER_STATUS.STOPPED;
  };

  /**
   * Startボタン無効判定
   * @param {string} status Status(startup/running/stopping/stopped)
   * @returns {boolean} true: ボタン無効 / false: ボタン有効
   */
  isStartDisabled = (status) => {
    return status === SERVER_STATUS.STARTUP || status === SERVER_STATUS.RUNNING;
  };

  /**
   * Stopボタンクリック時のイベントハンドラ
   */
  onClickStop = async () => {
    const metadata = JSON.parse(JSON.stringify(this.taskAsset.metadata));
    metadata.command = COMMAND_TYPE.STOP;

    try {
      const { id } = this.taskAsset;
      const endpoint = this.tabKey === TAB_LIST.TRAIN_TASK ? EP_PATH_ROOT_ASSET_TRAIN_TASK : EP_PATH_ROOT_ASSET_DETECT_TASK;
      await putApiGateway(endpoint, { items: [{ id, update: { metadata: { set: metadata } } }] });
    } catch (e) {
      if (e.message === 'Failed to fetch') {
        message.error(VALIDATE_ERROR_TASK_STOP + '-');
      } else {
        message.error(VALIDATE_ERROR_TASK_STOP + e.message);
      }
    }

    this.setState({
      statusModalVisible: false,
    });
  };

  /**
   * startボタンクリック時のイベントハンドラ
   */
  onClickStart = async () => {
    const metadata = JSON.parse(JSON.stringify(this.taskAsset.metadata));
    metadata.command = COMMAND_TYPE.START;

    try {
      const { id } = this.taskAsset;
      const endpoint = this.tabKey === TAB_LIST.TRAIN_TASK ? EP_PATH_ROOT_ASSET_TRAIN_TASK : EP_PATH_ROOT_ASSET_DETECT_TASK;
      await putApiGateway(endpoint, { items: [{ id, update: { metadata: { set: metadata } } }] });
    } catch (e) {
      if (e.message === 'Failed to fetch') {
        message.error(VALIDATE_ERROR_TASK_START + '-');
      } else {
        message.error(VALIDATE_ERROR_TASK_START + e.message);
      }
    }

    this.setState({
      statusModalVisible: false,
    });
  };

  /**
   * component render前の処理
   */
  async componentDidMount() {
    this.props.setLoadingFlag(true);
    this.tabKey = await this.getTabKey();

    const { TASK_LIST } = AUTHENTICATION_TYPE_MATRIX;
    const {
      LEARNING_TASK_DETECT_TASK,
      LEARNING_TASK_TASK_CONTROL,
      LEARNING_TASK_DELETE_BUTTON,
      LEARNING_TASK_PRIORITY_CHANGE,
      DETECT_TASK_LEARNING_TASK,
      DETECT_TASK_TASK_CONTROL,
      DETECT_TASK_PARAMETER,
      DETECT_TASK_DELETE_BUTTON,
      DETECT_TASK_PRIORITY_CHANGE,
    } = TASK_LIST;

    this.setState({
      defaultTabKey: this.tabKey,
      selectedTab: this.tabKey,
      trainTaskLoad: true,
      detectTaskLoad: true,
      isLearningTaskTabDisabled: !await containsUIAuthType(DETECT_TASK_LEARNING_TASK),
      isDetectTaskTabDisabled: !await containsUIAuthType(LEARNING_TASK_DETECT_TASK),
      isLearningTaskControlDisabled: !await containsUIAuthType(LEARNING_TASK_TASK_CONTROL),
      isLearningTaskDeleteButtonDisabled: !await containsUIAuthType(LEARNING_TASK_DELETE_BUTTON),
      isLearningTaskPriorityChangeDisabled: !await containsUIAuthType(LEARNING_TASK_PRIORITY_CHANGE),
      isDetectTaskControlDisabled: !await containsUIAuthType(DETECT_TASK_TASK_CONTROL),
      isDetectTaskParameterDisabled: !await containsUIAuthType(DETECT_TASK_PARAMETER),
      isDetectTaskDeleteButtonDisabled: !await containsUIAuthType(DETECT_TASK_DELETE_BUTTON),
      isDetectTaskPriorityChangeDisabled: !await containsUIAuthType(DETECT_TASK_PRIORITY_CHANGE),
    });

    // 表示データの読込完了を待たずにローディングを解除するためデータ読込処理は非同期で行う
    this.setTableData();
    this.props.setLoadingFlag(false);
  }

  /**
   * コンポーネントが更新された直後に呼び出される。
   * 変更前後で検出タスク一覧、学習タスク一覧の読み込みが完了した場合、
   * それぞれのロードインジケータを解除する。
   * @param {Object} prevState 変更前のstate
   */
  componentDidUpdate(_, prevState) {
    const { allTrainTasks, allDetectTasks } = this.state;
    if (allTrainTasks.length !== prevState.allTrainTasks.length) {
      this.setState({
        trainTaskLoad: false,
      });
    }
    if (allDetectTasks.length !== prevState.allDetectTasks.length) {
      this.setState({
        detectTaskLoad: false,
      });
    }
  }

  render() {
    const {
      defaultTabKey,
      dlStatusLoad,
      allTrainTasks,
      fixedTrainTasks,
      sortTrainTasks,
      trainTaskLoad,
      allDetectTasks,
      fixedDetectTasks,
      sortDetectTasks,
      detectTaskLoad,
      isLearningTaskTabDisabled,
      isDetectTaskTabDisabled,
      isLearningTaskControlDisabled,
      isLearningTaskDeleteButtonDisabled,
      isLearningTaskPriorityChangeDisabled,
      isDetectTaskControlDisabled,
      isDetectTaskParameterDisabled,
      isDetectTaskDeleteButtonDisabled,
      isDetectTaskPriorityChangeDisabled,
    } = this.state;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <div style={{ margin: '1%', backgroundColor: 'white' }}>
          {defaultTabKey &&
            <Tabs defaultActiveKey={defaultTabKey} type="card" onTabClick={this.onTabClick} style={{ height: '100%', margin: '3%', minHeight: '895px' }}>
              <TabPane
                tab={
                  <Tooltip title={isLearningTaskTabDisabled && ERROR_NO_AUTH_MESSAGE}>
                    {TAB_LIST.TRAIN_TASK}
                  </Tooltip>
                }
                key={TAB_LIST.TRAIN_TASK}
                disabled={isLearningTaskTabDisabled}
              >
                {!isLearningTaskTabDisabled &&
                  <>
                    <DlStatusButton loading={dlStatusLoad} onClick={this.onClickStatus} isDLStatusButtonDisabled={isLearningTaskControlDisabled} />
                    <Spin spinning={trainTaskLoad} tip="Loading..." style={{ marginTop: '10%' }}>
                      {!trainTaskLoad &&
                        <table className="table">
                          <TableHead
                            rows={allTrainTasks}
                            heads={TRAIN_TASK_HEADS}
                            onFilterUpdate={this.onFilterUpdate}
                          />
                          <FixedList
                            rows={fixedTrainTasks}
                            heads={TRAIN_TASK_HEADS}
                            onClickLink={this.onClickLink}
                            onClickDelete={this.onClickDelete}
                            isDeleteButtonDisabled={isLearningTaskDeleteButtonDisabled}
                          />
                          <SortableList
                            rows={sortTrainTasks}
                            heads={TRAIN_TASK_HEADS}
                            onSortEnd={this.onSortEndTrain}
                            onClickLink={this.onClickLink}
                            onClickDelete={this.onClickDelete}
                            isDeleteButtonDisabled={isLearningTaskDeleteButtonDisabled}
                            isPriorityChangeDisabled={isLearningTaskPriorityChangeDisabled}
                          />
                        </table>
                      }
                      {isLearningTaskPriorityChangeDisabled && <TaskPriorityChangeAlert />}
                    </Spin>
                  </>
                }
              </TabPane>
              <TabPane
                tab={
                  <Tooltip title={isDetectTaskTabDisabled && ERROR_NO_AUTH_MESSAGE}>
                    {TAB_LIST.DETECT_TASK}
                  </Tooltip>
                }
                key={TAB_LIST.DETECT_TASK}
                disabled={isDetectTaskTabDisabled}
              >
                {!isDetectTaskTabDisabled &&
                  <>
                    <DlStatusButton loading={dlStatusLoad} onClick={this.onClickStatus} isDLStatusButtonDisabled={isDetectTaskControlDisabled} />
                    <Spin spinning={detectTaskLoad} tip="Loading..." style={{ marginTop: '10%' }}>
                      {!detectTaskLoad &&
                        <table className="table">
                          <TableHead
                            rows={allDetectTasks}
                            heads={DETECT_TASK_HEADS}
                            onFilterUpdate={this.onFilterUpdate}
                          />
                          <FixedList
                            rows={fixedDetectTasks}
                            heads={DETECT_TASK_HEADS}
                            onClickLink={this.onClickLink}
                            onClickDelete={this.onClickDelete}
                            isDeleteButtonDisabled={isDetectTaskDeleteButtonDisabled}
                            isParamDisabled={isDetectTaskParameterDisabled}
                          />
                          <SortableList
                            rows={sortDetectTasks}
                            heads={DETECT_TASK_HEADS}
                            onSortEnd={this.onSortEndDetect}
                            onClickLink={this.onClickLink}
                            onClickDelete={this.onClickDelete}
                            isDeleteButtonDisabled={isDetectTaskDeleteButtonDisabled}
                            isParamDisabled={isDetectTaskParameterDisabled}
                            isPriorityChangeDisabled={isDetectTaskPriorityChangeDisabled}
                          />
                        </table>
                      }
                      {isDetectTaskPriorityChangeDisabled && <TaskPriorityChangeAlert />}
                    </Spin>
                  </>
                }
              </TabPane>
            </Tabs>
          }
        </div>
        <Modal
          title={this.state.linkTitle}
          visible={this.state.linkModalVisible}
          onCancel={this.onCancelModal}
          centered
          width={1500}
          footer={null}
        >
          <pre style={{ whiteSpace: 'pre-wrap' }}>{this.state.linkText}</pre>
        </Modal>
        <Modal
          title="タスクの削除"
          visible={this.state.deleteModalVisible}
          centered
          onCancel={this.onCancelModal}
          cancelText={MODAL_BUTTON_TEXT.CANCEL}
          okText={MODAL_BUTTON_TEXT.DELETE}
          onOk={this.onOkDelete}
          confirmLoading={this.state.deleteConfirmLoading}
          okType="danger"
        >
          {this.state.itemName}を削除しますか？
        </Modal>
        <Modal
          title={this.state.assetType}
          visible={this.state.statusModalVisible}
          centered
          onCancel={this.onCancelModal}
          footer={this.state.statusModalFooter}
        >
          <Row style={{ textAlign: 'center' }}><StatusIcon status={this.state.status} /></Row>
          <Row style={{ textAlign: 'center' }}><span style={{ fontSize: '30px', fontWeight: 'bold' }}>{this.state.taskModalStatus}</span></Row>
        </Modal>
      </Layout>
    );
  }
}

export default Task;
