import { CogniteEvent, AssetListScope } from '@cognite/sdk';

import BaseEvent from './BaseEvent';
import { EP_PATH_SOLAR_EVENTS_LIST } from '../AWS/EndpointPath';
import { loadAllSolarSiteFromCDFByScope } from '../Asset/SolarSiteAsset';
import { getAllEvents } from '../dataAccess';

const SOLAR_INFORMATION_TYPE = process.env.REACT_APP_SOLAR_INFORMATION ?? 'solarinformation';

/**
 * サイト機器情報
 */
class SolarEquipmentInformation extends BaseEvent {
  /**
   * メンバ変数
   */
  get name(): string {
    if (!this.metadata || !this.metadata.site_name_jp) return '';
    return this.metadata.site_name_jp;
  }

  get address(): string {
    if (!this.metadata || !this.metadata.address) return '';
    return this.metadata.address;
  }

  get drivingStartDate(): string {
    if (!this.metadata || !this.metadata['運転開始年月']) return '';
    return this.metadata['運転開始年月'];
  }

  get amBusinessOperator(): string {
    if (!this.metadata || !this.metadata['AM事業者']) return '';
    return this.metadata['AM事業者'];
  }

  get omBusinessOperator(): string {
    if (!this.metadata || !this.metadata['O_M事業者']) return '';
    return this.metadata['O_M事業者'];
  }

  get continuousCapacity(): string {
    if (!this.metadata || !this.metadata['連系容量（kW）']) return '';
    return this.metadata['連系容量（kW）'];
  }

  get panelCapacity(): string {
    if (!this.metadata || !this.metadata['パネル容量（kW）']) return '';
    return this.metadata['パネル容量（kW）'];
  }

  get pcsCompanyName(): string {
    if (!this.metadata || !this.metadata['PCS会社名']) return '';
    return this.metadata['PCS会社名'];
  }

  get pcsModelNumber(): string {
    if (!this.metadata || !this.metadata['PCS型番']) return '';
    return this.metadata['PCS型番'];
  }

  get pcsCapacity(): string {
    if (!this.metadata || !this.metadata['PCS容量（kW）']) return '';
    return this.metadata['PCS容量（kW）'];
  }

  get pcsCount(): string {
    if (!this.metadata || !this.metadata['PCS台数']) return '';
    return this.metadata['PCS台数'];
  }

  get panelCompanyName(): string {
    if (!this.metadata || !this.metadata['パネル会社名']) return '';
    return this.metadata['パネル会社名'];
  }

  get panelModelNumber(): string {
    if (!this.metadata || !this.metadata['パネル型番']) return '';
    return this.metadata['パネル型番'];
  }

  get panelSingleCapacity(): string {
    if (!this.metadata || !this.metadata['パネル単一容量（W）']) return '';
    return this.metadata['パネル単一容量（W）'];
  }

  get panelNumber(): string {
    if (!this.metadata || !this.metadata['パネル枚数']) return '';
    return this.metadata['パネル枚数'];
  }

  get frame(): string {
    if (!this.metadata || !this.metadata['架台']) return '';
    return this.metadata['架台'];
  }

  get supply(): string {
    if (!this.metadata || !this.metadata['供給先']) return '';
    return this.metadata['供給先'];
  }

  get electricPrice(): string {
    if (!this.metadata || !this.metadata['売電単価（円）']) return '';
    return this.metadata['売電単価（円）'];
  }

  get investmentUnit(): string {
    if (!this.metadata || !this.metadata['投資意思決定単位']) return '';
    return this.metadata['投資意思決定単位'];
  }

  /**
   * 設備情報保持アセット（太陽光）に紐づくサイト機器情報一覧を取得する。
   * @returns サイト機器情報一覧
   */
  static async loadEquipmentInformationByFilter(): Promise<SolarEquipmentInformation[]> {
    // 設備情報保持アセット（太陽光）を特定
    const scope: AssetListScope = {
      filter: {
        metadata: {
          assetType: SOLAR_INFORMATION_TYPE,
        },
      },
    };
    const [siteInfoAsset] = await loadAllSolarSiteFromCDFByScope(scope);

    // 設備情報保持アセット（太陽光）に紐づくinformationイベント取得
    const eventFilter = {
      type: 'information',
      subtype: 'site',
      assetIds: [siteInfoAsset.id],
    };
    const eventSort = {
      property: ['metadata', 'site_name_jp'],
      order: 'asc',
    };
    const loadEquipments = await getAllEvents(EP_PATH_SOLAR_EVENTS_LIST, eventFilter, eventSort) as CogniteEvent[];
    const equipments = loadEquipments.map(
      (loadEquipment) => new SolarEquipmentInformation(loadEquipment),
    );
    return equipments;
  }

  /**
   * 指定サイトのサイト機器情報を取得する。
   * @param id サイトのAssetId
   * @returns 指定サイトのサイト機器情報
   */
  static async loadEquipmentInformationByAssetId(id: number): Promise<SolarEquipmentInformation | undefined> {
    // 指定サイトに紐づくinformationイベント取得
    const eventFilter = {
      type: 'information',
      subtype: 'site',
      assetIds: [id],
    };
    const eventSort = {
      property: ['metadata', 'site_name_jp'],
      order: 'asc',
    };
    const loadEquipments = await getAllEvents(EP_PATH_SOLAR_EVENTS_LIST, eventFilter, eventSort) as CogniteEvent[];
    let result;
    if (loadEquipments.length) {
      result = new SolarEquipmentInformation(loadEquipments[0]);
    }
    return result;
  }
}

export default SolarEquipmentInformation;
