/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import React from 'react';
import {
  Layout, Button, Modal, Card, Spin, Col, Tooltip,
} from 'antd';
import TableFilter from 'react-table-filter';
import EnsembleModel from './EnsembleModel/EnsembleModel';
import { sortCreatedTime } from '../../../utils/sort';
import { getStorageDataSetId } from '../../../utils/storageCommon';
import da, { getDownloadUrl } from '../../../utils/dataAccess';
import DisplayEnsembleConfig from './DisplayEnsembleConfig/DisplayEnsembleConfig';
import {
  EP_PATH_LEARNING_PJ_FILES_LIST,
  EP_PATH_LEARNING_MODEL_AI_MODEL_FILES,
  EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES,
  EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES_LIST,
  EP_PATH_LEARNING_MODEL_FILES_CONFIG_DOWNLOAD,
} from '../../../utils/AWS/EndpointPath';
import { ERROR_NO_AUTH_MESSAGE } from '../../../utils/messages';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../../utils/common/Authentication';
import './learningModelListStyle.css';
import 'react-table-filter/lib/styles.css';

/**
 * Model Task Heads
 * key: 値を取得するkey value: ヘッダに表示する値
 * ※delete設定で削除ボタンの表示を行う
 */
const MODEL_FILE_HEADS = [
  { key: 'model', value: '学習モデル名', edit: true },
  { key: 'engine', value: 'エンジン', edit: false },
  { key: 'created', value: '作成日時', edit: false },
  { key: 'config', value: '設定ファイル', edit: false },
];

/**
 * 固定表示のリスト
 * @param {Array} rows 全てのModelFile
 * @param {Array} heads 全てのヘッダー情報
 * @param {function} onClickLink Linkクリック時のfunction
 * @param {boolean} isConfigFileLinkDisabled 設定ファイルリンクの非活性フラグ
 */
const FixedList = ({
  rows, heads, onClickLink, isConfigFileLinkDisabled,
}) => (
  <tbody>
    {rows.map((row, index) => (
      <tr key={index}>
        {heads.map((head) => (
          <TdItem
            key={head.key}
            row={row}
            head={head}
            onClickLink={onClickLink}
            isConfigFileLinkDisabled={isConfigFileLinkDisabled}
          />
        ))}
      </tr>
    ))}
  </tbody>
);

/**
 * TD アイテム
 * @param {object} row ModelFile
 * @param {object} head ヘッダー情報
 * @param {function} onClickLink Linkクリック時のfunction
 * @param {boolean} isConfigFileLinkDisabled 設定ファイルリンクの非活性フラグ
 */
const TdItem = ({
  row, head, onClickLink, isConfigFileLinkDisabled,
}) => {
  const { key } = head;
  switch (key) {
    case 'config':
      return <ConfigItem row={row} headKey={key} onClickLink={onClickLink} isConfigFileLinkDisabled={isConfigFileLinkDisabled} />;
    default:
      return <DefaultItem row={row} headKey={key} />;
  }
};

/**
 * コンフィグアイテム
 * @param {object} row ModelFile
 * @param {string} headKey ヘッダのkey
 * @param {function} onClickLink Linkクリック時のfunction
 * @param {boolean} isConfigFileLinkDisabled 設定ファイルリンクの非活性フラグ
 */
const ConfigItem = ({
  row, headKey, onClickLink, isConfigFileLinkDisabled,
}) => (
  <td key={headKey} className="configLearningModel">
    <Tooltip title={isConfigFileLinkDisabled && ERROR_NO_AUTH_MESSAGE}>
      <Button
        type="link"
        onClick={onClickLink.bind(this, 'config', row.configId)}
        style={{ paddingLeft: '0px' }}
        disabled={isConfigFileLinkDisabled}
      >
        {row[headKey]}
      </Button>
    </Tooltip>
  </td>
);

/**
 * デフォルトアイテム
 * @param {object} row ModelFile
 * @param {string} headKey ヘッダのkey
 */
const DefaultItem = ({ row, headKey }) => (
  <td key={headKey} className={headKey + 'LearningModel'}>{row[headKey]}</td>
);

/**
 * ヘッダ
 * @param {Array} rows 全てのModelFile
 * @param {Array} heads 全てのヘッダー情報
 */
const TableHead = ({ rows, heads }) => (
  <thead>
    <TableFilter rows={rows}>
      {heads.map((head) => (<th key={head.key} className={head.key + 'LearningModel'}>{head.value}</th>))}
    </TableFilter>
  </thead>
);

/**
 * 学習モデル一覧確認画面component
 * @property {object} client CogniteClient
 * @property {number} assetId 学習モデル一覧を取得するAssetID
 */
export class LearningModelList extends React.Component {
  constructor(props) {
    super(props);
    this.EnsembleModelRef = React.createRef();
    this.onClickCloseEnsembleModal = this.onClickCloseEnsembleModal.bind(this);
  }

  state = {
    allModelFiles: undefined,
    linkModalVisible: false,
    linkTitle: '',
    linkText: '',
    statusModalVisible: false,
    editEnsembleModalVisible: false,
    linkJson: {},
    isEnsembleModelCreateButtonDisabled: true,
    isConfigFileLinkDisabled: true,
  };

  /**
   * Linkクリック時のイベントハンドラ
   * @param {string} mode log or param
   * @param {string} id downloadFileId
   */
  onClickLink = async (mode, id) => {
    const { allModelFiles } = this.state;
    const [clickedFile] = allModelFiles.filter((modelFile) => modelFile.configId === id);
    const endpoint = clickedFile.engine === 'アンサンブル'
      ? EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES
      : EP_PATH_LEARNING_MODEL_AI_MODEL_FILES;

    const file = await da.fileRetrieve({ endpoint, id: Number(id) });
    const downloadUrl = await getDownloadUrl(EP_PATH_LEARNING_MODEL_FILES_CONFIG_DOWNLOAD, Number(id));
    const res = await fetch(downloadUrl);
    if (file.metadata.engine === 'ensemble') {
      const linkJson = await res.json();
      this.setState({ statusModalVisible: true, linkTitle: file.metadata.modelName, linkJson });
    } else {
      const linkText = await res.text();

      this.setState({
        linkModalVisible: true,
        linkTitle: mode,
        linkText,
      });
    }
  };

  /**
   * Modalキャンセル時のイベントハンドラ
   */
  onCancelModal = () => {
    this.setState({
      linkModalVisible: false,
      statusModalVisible: false,
    });
  };

  /**
   * 閉じるボタン押下時のイベントハンドラ
   */
  onClickCloseEnsembleModal = () => {
    this.EnsembleModelRef.current.onClickClose();
  };

  /**
   * Tableデータの設定
   */
  setTableData = async () => {
    this.setState({ imageLoad: true });
    await this.setModelFileData();
    this.setState({ imageLoad: false });
  };

  /**
   * Model Fileデータの設定
   */
  setModelFileData = async () => {
    const allModelFiles = await this.getAllModelFiles();

    this.setState({
      allModelFiles,
    });
  };

  /**
   * 全てのModelFileを取得
   * @returns {Array} modelFiles
   */
  async getAllModelFiles() {
    const dataSetId = getStorageDataSetId();
    const modelFilter = { metadata: { type: 'model' }, dataSetIds: [{ id: dataSetId }], assetIds: [this.props.assetId] };
    const modelFiles = await da.getAllFiles(EP_PATH_LEARNING_PJ_FILES_LIST, modelFilter);
    const ensembleFilter = { metadata: { type: 'parameter', engine: 'ensemble' }, dataSetIds: [{ id: dataSetId }], assetIds: [this.props.assetId] };
    const ensembleFiles = await da.getAllFiles(EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES_LIST, ensembleFilter);
    const files = modelFiles.concat(ensembleFiles);
    sortCreatedTime(files);

    const modelFilesList = [];
    files.forEach((item) => {
      if (item.metadata.engine === 'ensemble') {
        modelFilesList.push({
          id: item.id,
          model: item.metadata.modelName,
          created: this.getDateTime(item.createdTime),
          engine: 'アンサンブル',
          configId: item.id,
        });
      } else {
        modelFilesList.push({
          id: item.id,
          model: item.metadata.modelName,
          created: this.getDateTime(item.createdTime),
          engine: item.metadata.engine,
          configId: item.metadata.configId,
        });
      }
    });

    await Promise.all(
      modelFilesList.map(async (model) => {
        const endpoint = model.engine === 'アンサンブル'
          ? EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES
          : EP_PATH_LEARNING_MODEL_AI_MODEL_FILES;
        const configFile = await da.fileRetrieve({ endpoint, id: model.configId });
        model.config = configFile.name;
      }),
    );
    return modelFilesList;
  }

  /**
   * 日時の取得（unixTimeをdateに変換）
   * @param {number} unixTime
   * @returns {String} dateTime
   */
  getDateTime(unixTime) {
    if (unixTime) {
      const date = new Date(unixTime);
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }
    return '-';
  }

  /**
   * EnsembleModal表示識別の設定
   * @param {boolean} visible true:表示 /false:非表示
   */
  setEditEnsembleModalVisible = (visible) => {
    this.setState({
      editEnsembleModalVisible: visible,
    });
  };

  /**
   * アンサンブルモデル作成ボタン押下時のイベントハンドラ
   */
  onClickCreateEnsembleModel = () => {
    this.setState({
      editEnsembleModalVisible: true,
    });
  };

  /**
   * component render前の処理
   */
  async componentDidMount() {
    const id = this.props.assetId;
    if (id !== null) {
      await this.setTableData();
    }

    const { MACHINE_LEARNING } = AUTHENTICATION_TYPE_MATRIX;
    const {
      LEARNING_PROJECTS_DETAILS_ENSEMBLE_MODEL_CREATION,
      CREATED_LEARNING_MODEL_CONFIGURATION_FILE_LINK,
    } = MACHINE_LEARNING;

    this.setState({
      isEnsembleModelCreateButtonDisabled: !await containsUIAuthType(LEARNING_PROJECTS_DETAILS_ENSEMBLE_MODEL_CREATION),
      isConfigFileLinkDisabled: !await containsUIAuthType(CREATED_LEARNING_MODEL_CONFIGURATION_FILE_LINK),
    });
  }

  /**
   * props 更新時の処理
   * @param {Array} prevProps 前回表示時のプロパティ
   */
  async componentDidUpdate(prevProps) {
    const id = this.props.assetId;
    if (prevProps.assetId !== id) {
      await this.setTableData();
    }
  }

  render() {
    const { customStyle, client } = this.props;
    const cardStyle = (customStyle && customStyle.cardStyle)
      ? customStyle.cardStyle
      : { height: '410px', margin: '0px' };
    const {
      isEnsembleModelCreateButtonDisabled,
      isConfigFileLinkDisabled,
    } = this.state;

    return (
      <Layout>
        <Card title="作成済み学習モデル" style={cardStyle}>
          <Spin spinning={this.state.imageLoad}>
            {this.state.allModelFiles &&
              <table className="table">
                <TableHead
                  rows={this.state.allModelFiles}
                  heads={MODEL_FILE_HEADS}
                />
                <FixedList
                  rows={this.state.allModelFiles}
                  heads={MODEL_FILE_HEADS}
                  onClickLink={this.onClickLink}
                  isConfigFileLinkDisabled={isConfigFileLinkDisabled}
                />
              </table>}
          </Spin>
          <Col span={24}>
            <Tooltip title={isEnsembleModelCreateButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
              <Button
                type="primary"
                onClick={this.onClickCreateEnsembleModel}
                style={{ fontWeight: 'bold' }}
                disabled={isEnsembleModelCreateButtonDisabled}
              >
                アンサンブルモデル作成
              </Button>
            </Tooltip>
          </Col>
        </Card>
        <Modal
          title={this.state.linkTitle}
          visible={this.state.linkModalVisible}
          onCancel={this.onCancelModal}
          centered
          width={1500}
          footer={null}
        >
          <pre style={{ whiteSpace: 'pre-wrap' }}>{this.state.linkText}</pre>
        </Modal>
        <Modal
          title={this.state.linkTitle}
          visible={this.state.statusModalVisible}
          centered
          onCancel={this.onCancelModal}
          width={900}
          footer={null}
        >
          <DisplayEnsembleConfig
            client={this.props.client.client}
            json={this.state.linkJson}
          />
        </Modal>
        <Modal
          width={1100}
          title="アンサンブルモデル作成"
          visible={this.state.editEnsembleModalVisible}
          centered
          onCancel={this.onClickCloseEnsembleModal}
          footer={null}
        >
          <EnsembleModel
            client={client.client}
            setModalVisible={this.setEditEnsembleModalVisible}
            setTableData={this.setTableData}
            learningProjectsId={this.props.assetId}
            ref={this.EnsembleModelRef}
          />
        </Modal>
      </Layout>
    );
  }
}
