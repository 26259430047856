import * as XLSX from 'xlsx';

/** セル項目名接頭辞リスト */
const ITEM_PREFIXES = [
  { cell: 'A6', label: '売電収入額' },
  { cell: 'A7', label: '営業費用（売上原価）' },
  { cell: 'A8', label: '営業費用（販管費）' },
  { cell: 'A9', label: '営業利益' },
  { cell: 'A10', label: '営業外収益' },
  { cell: 'A11', label: '営業外費用' },
  { cell: 'A12', label: '経常利益' },
  { cell: 'A13', label: '当期純利益' },
  { cell: 'A14', label: 'EBITDA' },
  { cell: 'A15', label: '個別項目' },
  { cell: 'A16', label: '減価償却費' },
  { cell: 'A17', label: '営業権償却費' },
  { cell: 'A18', label: '修繕費' },
  { cell: 'A19', label: '受取利息' },
  { cell: 'A20', label: '支払利息' },
  { cell: 'A21', label: '事業税/償却資産税' },
  { cell: 'A22', label: '法人税等' },
];

/**
 * 財務計画値・実績値のファイルフォーマットを確認する。
 * A6~A22セルの文字列を確認し、想定どおりの単語始まりとなっているかで判断する。
 * @param {XLSX.WorkSheet} sheet 確認対象ワークシート
 * @returns 確認結果
 */
const fileFormatCheck = (sheet: XLSX.WorkSheet): boolean => {
  const isFormatCorrect = ITEM_PREFIXES.every(({ cell, label }) => {
    const fileItem: XLSX.CellObject | undefined = sheet[cell];
    return fileItem?.v?.toString().startsWith(label);
  });

  return isFormatCorrect;
};

export { fileFormatCheck };
