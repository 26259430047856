import { Asset, AssetListScope, CursorResponse } from '@cognite/sdk';
import { postApiGateway } from '../AWS/ApiGateway';

import BaseAsset from './BaseAsset';
import { EP_PATH_SOLAR_ASSETS_LIST } from '../AWS/EndpointPath';

// metadata.assetType
const ASSET_TYPE = process.env.REACT_APP_GENERATION_STATUS ?? 'generationstatus';

/**
 * ステータス履歴保持アセット
 */
class GenerationStatus extends BaseAsset {
  /**
   * ステータス履歴保持アセットを取得する。<br>
   * 運用上、必ず1件のみ存在する前提のため0件/複数件を考慮しない。
   * @returns {Promise<GenerationStatus>} ステータス履歴保持アセット
   */
  static async loadOneFromCDF(): Promise<GenerationStatus> {
    const assetListScope: AssetListScope = {
      filter: {
        metadata: {
          assetType: ASSET_TYPE,
        },
      },
    };

    const response = await postApiGateway<AssetListScope, CursorResponse<Asset[]>>(
      EP_PATH_SOLAR_ASSETS_LIST, assetListScope,
    );

    const generationStatus = new GenerationStatus(response.items[0]);
    return generationStatus;
  }

  /*
   * メンバメソッド
   */

  /**
   * クローンオブジェクト取得
   * @returns クローンオブジェクト
   */
  cloneSelf(): GenerationStatus {
    const clone = new GenerationStatus();
    Object.assign(clone, this);

    return clone;
  }

  // eslint-disable-next-line class-methods-use-this
  async loadChildrenFromCDF(): Promise<BaseAsset[]> {
    return [];
  }
}

export default GenerationStatus;
