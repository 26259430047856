import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Typography,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';

import {
  ERROR_UPDATE_EMAIL_GROUP,
  SUCCESS_CREATE_EMAIL_GROUP,
  SUCCESS_UPDATE_EMAIL_GROUP,
  VALIDATE_ERROR_EMAIL_GROUP_ADDRESS_ALREADY_EXISTS,
  VALIDATE_ERROR_EMAIL_GROUP_ADDRESS_CHARACTER_TYPE,
  VALIDATE_ERROR_EMAIL_GROUP_ADDRESS_REQUIRED,
  VALIDATE_ERROR_EMAIL_GROUP_NAME_REQUIRED,
} from '../../../utils/messages';
import EmailGroup, { SendParameter } from '../../../utils/DynamoDB/EmailGroup';

import './GroupSettingModal.css';

const { Title } = Typography;

interface GroupSettingModalProps {
  visible: boolean;
  onClose: () => void;
  targetEmailGroup: EmailGroup;
  refreshParentTable: () => void;
}

/**
 * グループ設定モーダル
 * @param {GroupSettingModalProps} props プロパティ
 * @returns グループ設定モーダルコンポーネント
 */
const GroupSettingModal: React.FC<GroupSettingModalProps> = (props: GroupSettingModalProps) => {
  const {
    visible,
    onClose,
    targetEmailGroup,
    refreshParentTable,
  } = props;

  const [editGroupName, setEditGroupName] = useState<string>('');
  const [editEmailAddress, setEditEmailAddress] = useState<string>('');
  const [emailAddressList, setEmailAddressList] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!visible) return;
    setEditGroupName(targetEmailGroup.groupName);
    setEditEmailAddress('');
    setEmailAddressList(targetEmailGroup.addressList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  /**
   * グループ名入力時のイベントハンドラ
   * @param {ChangeEvent<HTMLInputElement>} e 入力イベント
   */
  const handleChangeGroupName = (e: ChangeEvent<HTMLInputElement>): void => {
    setEditGroupName(e.currentTarget.value);
  };

  /**
   * メールアドレス入力時のイベントハンドラ
   * @param {ChangeEvent<HTMLInputElement>} e 入力イベント
   */
  const handleChangeEmailMailAddress = (e: ChangeEvent<HTMLInputElement>): void => {
    setEditEmailAddress(e.currentTarget.value);
  };

  /**
   * 追加ボタンクリック時のイベントハンドラ
   */
  const handleAddEmailAddress = (): void => {
    // メールアドレスの形式チェック
    const emailAddressFormatCheck = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9_.-]{1,}$/;
    if (!editEmailAddress.match(emailAddressFormatCheck)) {
      message.error(VALIDATE_ERROR_EMAIL_GROUP_ADDRESS_CHARACTER_TYPE);
      return;
    }
    // 同一メールアドレスチェック
    if (emailAddressList.includes(editEmailAddress)) {
      message.error(VALIDATE_ERROR_EMAIL_GROUP_ADDRESS_ALREADY_EXISTS);
      return;
    }

    const newEmailAddressList = editEmailAddress ? [...emailAddressList, editEmailAddress] : emailAddressList;
    setEmailAddressList(newEmailAddressList);
    setEditEmailAddress('');
  };

  /**
   * 削除ボタンクリック時のイベントハンドラ
   * @param {string} deleteAddress 削除対象のメールアドレス
   */
  const handleDeleteEmailAddress = (deleteAddress: string) => {
    const addressList = emailAddressList.filter((address) => address !== deleteAddress);
    setEmailAddressList(addressList);
  };

  /**
   * 登録・更新ボタンクリック時のイベントハンドラ
   */
  const handleUpdateGroup = async () => {
    // 必須項目のチェック
    if (!editGroupName.length) {
      message.error(VALIDATE_ERROR_EMAIL_GROUP_NAME_REQUIRED);
      return;
    }

    if (!emailAddressList.length) {
      message.error(VALIDATE_ERROR_EMAIL_GROUP_ADDRESS_REQUIRED);
      return;
    }

    // 多重で処理を実行されないようにボタンを非活性化
    setLoading(true);

    const parameter: SendParameter = {
      ...targetEmailGroup,
      groupName: editGroupName,
      addressList: emailAddressList,
      oldGroupName: targetEmailGroup.groupName,
    };

    try {
      if (targetEmailGroup.groupName) {
        await EmailGroup.updateGroup(parameter);
        message.success(SUCCESS_UPDATE_EMAIL_GROUP);
      } else {
        await EmailGroup.createGroup(parameter);
        message.success(SUCCESS_CREATE_EMAIL_GROUP);
      }
      refreshParentTable();
      onClose();
    } catch {
      message.error(ERROR_UPDATE_EMAIL_GROUP);
    }
    setLoading(false);
  };

  const columns: ColumnProps<string>[] = [
    {
      title: 'メールアドレス',
      dataIndex: 'emailAddress',
      width: 135,
      render: (_, item: string) => item,
    },
    {
      title: '',
      dataIndex: 'button',
      width: 40,
      render: (_, item: string) => (
        <div>
          <Popconfirm
            title="本当に削除しますか？"
            okText="はい"
            cancelText="いいえ"
            onConfirm={() => handleDeleteEmailAddress(item)}
          >
            <Button>
              削除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Modal
      className="om-dashboard-group-setting-modal"
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
    >
      <div>
        <Spin spinning={loading}>
          <Title level={4}>
            グループ
            {targetEmailGroup.groupName ? '編集' : '追加'}
          </Title>
          <Divider className="group-setting-modal-divider" />

          <Row className="group-setting-modal-group-name-input">
            <h4>グループ名</h4>
            <Input
              id="group-name-text"
              value={editGroupName}
              maxLength={40}
              placeholder="40文字以内"
              onChange={handleChangeGroupName}
            />
          </Row>
          <Row className="group-setting-modal-email-address-input">
            <h4>メールアドレス</h4>
            <Col span={20}>
              <Input
                id="mail-address-text"
                value={editEmailAddress}
                maxLength={50}
                placeholder="50文字以内"
                onChange={handleChangeEmailMailAddress}
              />
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                style={{ marginLeft: 25 }}
                onClick={handleAddEmailAddress}
              >
                追加
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table<string>
                className="group-setting-modal-table"
                rowKey={(item) => item}
                dataSource={emailAddressList}
                pagination={false}
                columns={columns}
                scroll={{ y: 400 }}
              />
            </Col>
          </Row>
          <Row>
            <div className="group-setting-modal-update-button">
              <Button
                type="primary"
                onClick={handleUpdateGroup}
              >
                {targetEmailGroup.groupName ? '更新' : '登録'}
              </Button>
            </div>
          </Row>
        </Spin>
      </div>
    </Modal>
  );
};
export default GroupSettingModal;
