import React, { useEffect, useState } from 'react';

import {
  Button,
  Divider,
  Descriptions,
  Col,
  message,
  Row,
  Select,
} from 'antd';
import { SorterResult } from 'antd/lib/table';

import moment, { Moment } from 'moment';

import AlertEventTable from './parts/AlertEventTable';
import AlertListOccurrenceDates from './parts/AlertListOccurrenceDates';
import OMAlertEvent from '../../../utils/Event/OMAlertEvent';
import { generateCsvData } from '../../../utils/File/OMCsvFile';

import './AlertHistoryList.css';

const { Option } = Select;

// CSV出力時のカラム名と出力項目名
const CSV_HEADERS = [
  {
    key: 'dateForCsv',
    headerString: '日時',
  },
  {
    key: 'severityCode',
    headerString: '警報種別',
  },
  {
    key: 'floorName',
    headerString: 'フロア名',
  },
  {
    key: 'signalName',
    headerString: '警報内容',
  },
  {
    key: 'alertState',
    headerString: '発生',
  },
];

// デフォルトの1ページあたりの表示件数
const DEFAULT_PAGE_SIZE = 100;

interface AlertHistoryListProps {
  areaAssetId: number;
  displayReload: number;
}

/**
 * 警報一覧画面のコンポーネント
 * @param {AlertHistoryListProps} props プロパティ
 * @returns 警報一覧画面
 */
const AlertHistoryList: React.FC<AlertHistoryListProps> = (props: AlertHistoryListProps) => {
  const { areaAssetId, displayReload } = props;

  const [occurrenceDates, setOccurrenceDates] = useState<[Moment, Moment] | []>([]);
  const [severity, setSeverity] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [allHistoryEvents, setAllHistoryEvents] = useState<OMAlertEvent[]>([]);
  const [displayHistoryEvents, setDisplayHistoryEvents] = useState<OMAlertEvent[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [sortInfo, setSortInfo] = useState<SorterResult<OMAlertEvent> | null>(null);

  /**
   * 対象期間変更時のイベントハンドラ
   * @param {RangePickerValue} dates 選択された日付範囲
   */
  const handleChangeOccurrenceDates = (dates: [] | [Moment, Moment]): void => {
    setOccurrenceDates(dates);
  };

  /**
   * 「表示」ボタンをクリックした時のイベントハンドラ
   */
  const handleClickShowButton = () => {
    setLoading(true);

    if (!occurrenceDates.length && severity === 0) {
      message.error('対象期間か警報種別のいずれか、もしくは両方を指定してください。');
      setLoading(false);
      return;
    }

    const targetAlertEvents = allHistoryEvents.filter((event) => {
      const startTime = event.startTime as number ?? 0;
      const hasStartAndEnd = occurrenceDates.length === 2;
      const [start, end] = occurrenceDates.map((date) => moment(date));
      if (hasStartAndEnd && severity === 0) {
        return startTime >= start.valueOf() && startTime < end.valueOf();
      }

      if (!hasStartAndEnd && severity !== 0) {
        return event.severity === severity;
      }

      return startTime >= start.valueOf() && startTime < end.valueOf() && event.severity === severity;
    });

    if (!targetAlertEvents.length) {
      message.info('該当する条件に合致する検索結果が1件も存在しませんでした。');
      setLoading(false);
      return;
    }

    setDisplayHistoryEvents(targetAlertEvents);
    setPageNumber(1);
    setLoading(false);
  };

  /**
   * 「ファイル出力」ボタンをクリックした時のイベントハンドラ
   */
  const handleClickDownloadButton = () => {
    setDownloading(true);

    if (!displayHistoryEvents.length) {
      message.error('出力対象の履歴が存在しません。');
      setDownloading(false);
      return;
    }

    const csvData = generateCsvData(CSV_HEADERS, displayHistoryEvents);
    const aTagForDownload = document.createElement('a');
    let url = '';
    try {
      url = URL.createObjectURL(csvData);
      document.body.appendChild(aTagForDownload);
      aTagForDownload.href = url;
      aTagForDownload.download = `AlertHistory_${moment().format('YYYYMMDDHHmmss')}.csv`;
      aTagForDownload.click();
    } catch (e) {
      message.error('ダウンロードできませんでした。');
    } finally {
      aTagForDownload.remove();
      URL.revokeObjectURL(url);
    }
    setDownloading(false);
  };

  /**
   * 「条件クリア」ボタンをクリックした時のイベントハンドラ
   * @param {React.MouseEvent} e ボタンのクリックイベント
   */
  const handleClickClearCondition = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.currentTarget.blur();
    setOccurrenceDates([]);
    setSeverity(0);
    setDisplayHistoryEvents(allHistoryEvents);
    setPageNumber(1);
    setPageSize(DEFAULT_PAGE_SIZE);
    setSortInfo(null);
  };

  /**
   * 「ソート解除」ボタンをクリックした時のイベントハンドラ
   * @param {React.MouseEvent} e ボタンのクリックイベント
   */
  const handleClickSortReset = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.currentTarget.blur();
    setSortInfo(null);
  };

  useEffect(
    () => {
      if (!areaAssetId) return () => { /* 何もしない */ };

      setLoading(true);
      let canceled = false;
      (async () => {
        const latestHistoryEvents = await OMAlertEvent.loadTargetAlertHistoryEvents(areaAssetId);

        if (!canceled) {
          setAllHistoryEvents(latestHistoryEvents);
          setDisplayHistoryEvents(latestHistoryEvents);
          setLoading(false);
        }
      })();

      return () => { canceled = true; };
    },
    [areaAssetId, displayReload],
  );

  return (
    <>
      <Row style={{ paddingTop: '3.5%' }}>
        <Col span={24}>
          <Descriptions
            className="om-dashboard-alert-history-search-box"
            bordered
          >
            <Descriptions.Item label="対象期間" span={3}>
              <AlertListOccurrenceDates
                onChange={handleChangeOccurrenceDates}
                occurrenceDates={occurrenceDates}
              />
            </Descriptions.Item>
            <Descriptions.Item label="警報種別" span={3}>
              <Select<number>
                id="om-alert-select"
                style={{ width: 70 }}
                value={severity}
                onChange={setSeverity}
              >
                <Option key="no-severity-selected" value={0}>{'　'}</Option>
                <Option key="severity-a" value={1}>A</Option>
                <Option key="severity-b" value={2}>B</Option>
                <Option key="severity-c" value={3}>C</Option>
              </Select>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '1%' }}>
        <Col span={16}>
          <span style={{ color: 'red' }}>*</span>
          表示条件は1つ以上選択してください。
        </Col>
        <Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            type="primary"
            style={{ marginRight: 10, marginTop: 10 }}
            onClick={handleClickShowButton}
            disabled={loading}
          >
            表示
          </Button>
          <Button
            type="primary"
            style={{ marginRight: 10, marginTop: 10 }}
            loading={downloading}
            disabled={loading}
            onClick={handleClickDownloadButton}
          >
            CSV出力
          </Button>
          <Button
            style={{ marginTop: 10 }}
            onClick={handleClickClearCondition}
          >
            条件クリア
          </Button>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10, marginBottom: 20 }} />

      <Row type="flex" justify="start" style={{ paddingBottom: '1%' }}>
        <Col span={6} style={{ textAlign: 'left' }}>
          <Select<number>
            id="om-alert-pagesize-select"
            value={pageSize}
            onChange={setPageSize}
          >
            <Option key="page-size-15" value={15}>15</Option>
            <Option key="page-size-50" value={50}>50</Option>
            <Option key="page-size-100" value={100}>100</Option>
          </Select>
          <span> 件表示</span>
        </Col>
        <Col span={6} offset={12} style={{ textAlign: 'right' }}>
          <Button key="history_reset_sort" title="ソート解除" onClick={handleClickSortReset}>ソート解除</Button>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '1%' }}>
        <AlertEventTable
          loading={loading}
          showPagination
          currentPage={pageNumber}
          pageSize={pageSize}
          scrollY={360}
          alertEvents={displayHistoryEvents}
          sortInfo={sortInfo}
          handleSortChange={setSortInfo}
          setCurrentPage={setPageNumber}
        />
      </Row>
    </>
  );
};

export default AlertHistoryList;
