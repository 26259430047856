import React from 'react';
import { Modal } from 'antd';

import { DuplicateOperationTable } from './DuplicateOperationTable';

/**
 * 運転状況表示データ
 */
export interface OperationData {
  pcs: string;
  status: string;
  startTime: number;
  endTime: number;
}

/**
 * 重複運転状況表示データ
 * 必ず1件以上、運転状況表示データを保持する。
 */
export type DuplicateData = [OperationData, ...OperationData[]];

/**
 * 重複データと登録データのソート処理
 * 第1ソート：PCS
 * 第2ソート：status
 * 第3ソート：startTime
 * @param {DuplicateData} duplicateData 運転状況表示データの変数
 * @returns ソートの判定結果
*/
const sortOperationalStatus = (duplicateData: DuplicateData): DuplicateData => duplicateData.sort((a, b) => {
  const pcsComparison = a.pcs.localeCompare(b.pcs);
  if (pcsComparison !== 0) {
    return pcsComparison;
  }
  const statusComparison = a.status.localeCompare(b.status);
  if (statusComparison !== 0) {
    return statusComparison;
  }
  return a.startTime - b.startTime;
});

/**
 * 運転状況重複確認モーダルを表示する。
 * @param {DuplicateData} registeredData 登録されている運転状況データ
 * @param {DuplicateData} unRegisteredData 登録する運転状況データ
 */
export function showDuplicateWarningModal(registeredData: DuplicateData, unRegisteredData: DuplicateData): void {
  sortOperationalStatus(registeredData);
  sortOperationalStatus(unRegisteredData);

  const displayContent = (
    <>
      同一要因の運転状況が同一時間に既に登録されています。
      <br />
      重複しないように登録内容を修正の上、再度登録を実施してください。
      <br />
      <br />
      <b>■登録されている運転状況</b>
      <DuplicateOperationTable operationData={registeredData} />
      <br />
      <b>■登録する運転状況</b>
      <DuplicateOperationTable operationData={unRegisteredData} />
    </>
  );

  Modal.error({
    title: '登録内容を確認してください。',
    centered: true,
    width: 900,
    okText: '修正',
    mask: true,
    maskClosable: true,
    content: displayContent,
  });
}
