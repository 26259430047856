/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import React, { useState, useEffect } from 'react';
import { AssetListScope, FileFilterProps, FileRequestFilter } from '@cognite/sdk';
import {
  Typography, Divider, Row, Col, Descriptions, Button, message, Tooltip, DatePicker,
} from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import moment from 'moment';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

import SolarSite from '../../utils/Asset/SolarSite';
import { loadAllSolarSiteFromCDFByScope } from '../../utils/Asset/SolarSiteAsset';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import { ERROR_NOT_ENTERED_SEARCH_CONDITION, ERROR_NO_AUTH_MESSAGE, ERROR_SEARCH_RESULT_OVER_300 } from '../../utils/messages';
import FiscalYearSelect from './parts/FiscalYearSelect';
import MonthSelect from './parts/MonthSelect';
import FinancialReportSearchTable from './FinancialReportSearchTable';
import SolarRegisteredFile from '../../utils/File/SolarRegisteredFile';
import SiteCascader, { SiteCascaderSelect } from './parts/SiteCascader';
import './FinancialReportSearch.css';

const { Title } = Typography;
const { RangePicker } = DatePicker;

/** 日付フォーマット */
const DATE_FORMAT = 'YYYY/MM/DD';

/**
 * 財務帳票検索画面
 */
const FinancialReportSearch: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDates, setSelectedDates] = useState<[moment.Moment, moment.Moment] | []>([]);
  const [fiscalYear, setFiscalYear] = useState<string | undefined>();
  const [month, setMonth] = useState<string | undefined>();
  const [siteInfo, setSiteInfo] = useState<[string, string | undefined, string | undefined, string | undefined] | undefined>();
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState<boolean>(true);

  const [
    financialReportFiles, setFinancialReportFiles,
  ] = useState<SolarRegisteredFile[] | undefined>();

  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        SOLAR_MENU: {
          FINANCIAL_REPORT_SEARCH: {
            FINANCIAL_REPORT_FILE_DOWNLOAD,
          },
        },
      } = AUTHENTICATION_TYPE_MATRIX;

      if (!canceled) {
        setIsSearchButtonDisabled(!await containsUIAuthType(FINANCIAL_REPORT_FILE_DOWNLOAD));
      }
    })();
    return () => { canceled = true; };
  }, []);

  /**
   * 登録年月日選択時のイベントハンドラ
   * @param {[] | [moment.Moment, moment.Moment]} dates 選択した登録年月日
   */
  const handleChangeDates = (dates: [] | RangePickerValue): void => {
    if (dates.length === 0) {
      setSelectedDates([]);
      return;
    }
    const [startTime, endTime] = dates;
    setSelectedDates([moment(startTime?.startOf('day')), moment(endTime?.endOf('day'))]);
  };

  /**
   * サイト選択Cascaderチェンジイベントハンドラ
   * @param {SiteCascaderSelect | undefined} selectedValues 選択したサイト情報
   */
  const handleChangeSite = (selectedValues?: SiteCascaderSelect) => {
    if (!selectedValues) {
      setSiteInfo(undefined);
      return;
    }
    const {
      businessClassification, firstCategory, secondCategory, site,
    } = selectedValues;
    setSiteInfo([businessClassification.name, firstCategory ? firstCategory.name : undefined, secondCategory ? secondCategory.name : undefined, site ? site.name : undefined]);
  };

  /**
   * 検索ボタンクリック時のイベントハンドラ
   */
  const handleClickSearch = async (): Promise<void> => {
    setLoading(true);

    if (selectedDates.length === 0 && !siteInfo && !fiscalYear && !month) {
      message.error(ERROR_NOT_ENTERED_SEARCH_CONDITION);
      setLoading(false);
      return;
    }
    const FINANCE_REPORT_TYPE = process.env.REACT_APP_FINANCE_REPORT ?? 'financereport';
    const scope: AssetListScope = {
      filter: {
        metadata: {
          assetType: FINANCE_REPORT_TYPE,
        },
      },
    };
    const [siteInfoAsset] = await loadAllSolarSiteFromCDFByScope(scope);
    const eventFilterRequest = createEventFilterRequest();
    const solarSite = new SolarSite(siteInfoAsset);
    const loadSearchedFinancialReportFiles = await solarSite.loadFinancialReportFilesByFilter(
      eventFilterRequest,
    );
    if (loadSearchedFinancialReportFiles.length > 300) {
      message.error(ERROR_SEARCH_RESULT_OVER_300);
      setLoading(false);
      return;
    }
    const sortedSearchedFinancialReportFiles = sortSearchedFinancialReportFiles(loadSearchedFinancialReportFiles);
    setFinancialReportFiles(sortedSearchedFinancialReportFiles);
    setLoading(false);
  };

  /**
   * 財務帳票検索オブジェクト作成
   * @returns {FileRequestFilter} 検索オブジェクト
   */
  const createEventFilterRequest = (): FileRequestFilter => {
    const filter: FileFilterProps = {};
    if (selectedDates.length !== 0) {
      const [startTime, endTime] = selectedDates;
      filter.lastUpdatedTime = {
        min: startTime?.valueOf(),
        max: endTime?.valueOf(),
      };
    }
    if (fiscalYear || month || siteInfo) {
      filter.metadata = {};
      if (fiscalYear) {
        filter.metadata.fiscalYear = String(fiscalYear);
      }
      if (month) {
        filter.metadata.month = String(month);
      }
      if (siteInfo) {
        filter.metadata.businessClassification = String(siteInfo[0]);
        if (siteInfo[1]) {
          filter.metadata.category1 = String(siteInfo[1]);
        }
        if (siteInfo[2]) {
          filter.metadata.businessName = String(siteInfo[2]);
        }
        if (siteInfo[3]) {
          filter.metadata.site = String(siteInfo[3]);
        }
      }
    }

    // CogniteSDKとAPIで差分があるためasを用いてキャスト
    const fileFilterRequest = {
      filter,
    } as FileRequestFilter;

    return fileFilterRequest;
  };

  /**
   * 財務帳票をソートする。
   * 1. 登録年月日: 降順
   * @param {SolarRegisteredFile[]} sortTargetFinancialReportFiles ソート対象の財務帳票リスト
   * @returns ソート後の財務帳票リスト
   */
  const sortSearchedFinancialReportFiles = (
    sortTargetFinancialReportFiles: SolarRegisteredFile[],
  ): SolarRegisteredFile[] => {
    const sortedFinancialReportFiles = [...sortTargetFinancialReportFiles];
    sortedFinancialReportFiles.sort((a, b) => {
      const startTimeA = Number(a.uploadedTime);
      const startTimeB = Number(b.uploadedTime);
      if (startTimeA < startTimeB) {
        return 1;
      }
      return -1;
    });

    return sortedFinancialReportFiles;
  };

  return (
    <div className="financial-report-search">
      <div className="financial-report-search-title">
        <div className="financial-report-search-margin">
          <Title level={4}>財務情報閲覧</Title>
        </div>
        <Divider className="financial-report-search-divider" />
      </div>
      <Row>
        <Col span={20} offset={1}>
          <Descriptions bordered>
            <Descriptions.Item label="登録年月日" span={3}>
              <RangePicker
                format={DATE_FORMAT}
                placeholder={['開始日付', '終了日付']}
                locale={locale}
                onChange={handleChangeDates}
              />
            </Descriptions.Item>
            <Descriptions.Item label="サイト選択" span={3}>
              <SiteCascader
                onChange={handleChangeSite}
                changeOnSelect
              />
            </Descriptions.Item>
            <Descriptions.Item label="年度" span={3}>
              <FiscalYearSelect
                value={fiscalYear}
                onChange={(value) => setFiscalYear(value)}
              />
            </Descriptions.Item>
            <Descriptions.Item label="月" span={3}>
              <MonthSelect
                value={month}
                onChange={(value) => setMonth(value)}
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={17} offset={1}>
          <span className="financial-report-search-annotation">*</span>
          検索条件は1つ以上選択してください。
        </Col>
        <Col span={4}>
          <Tooltip title={isSearchButtonDisabled ? ERROR_NO_AUTH_MESSAGE : '検索'}>
            <Button
              type="primary"
              onClick={handleClickSearch}
              style={{ marginTop: 16, marginBottom: 16 }}
              loading={loading}
              disabled={isSearchButtonDisabled}
            >
              検索
            </Button>
          </Tooltip>
        </Col>
      </Row>
      {financialReportFiles && (
        <Row>
          <FinancialReportSearchTable loading={loading} searchedFileList={financialReportFiles} />
        </Row>
      )}
    </div>
  );
};

export default FinancialReportSearch;
