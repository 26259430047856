/* eslint-disable react/no-unstable-nested-components */
// eslint警告未対応
import React, { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  Input,
  Modal,
  message,
  Table,
  Tooltip,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

import SolarRegisteredFile from '../../utils/File/SolarRegisteredFile';
import FinancialReportFileDetailViewModal from '../Common/File/FinancialReportFileDetailViewModal';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import { EP_PATH_SOLAR_FILES } from '../../utils/AWS/EndpointPath';
import { deleteFiles } from '../../utils/dataAccess';
import {
  CONFIRM_DELETE_FINANCIAL_REPORT_FILE,
  ERROR_NO_AUTH_MESSAGE,
  SUCCESS_DELETE_FINANCIAL_REPORT_FILE,
  WARNING_DELETE_FILE_TITLE,
} from '../../utils/messages';
import './FinancialReportSearchTable.css';

interface FinancialReportListProps {
  loading: boolean;
  searchedFileList: SolarRegisteredFile[];
}

/** 日付フォーマット */
const DATE_FORMAT = 'YYYY年MM月DD日';

/** ダイアログのボタンラベル */
const MODAL_BUTTON_TEXT = {
  DELETE: '削除',
  CANCEL: 'キャンセル',
};

/**
 * 検索済みファイル一覧テーブル
 * @param {FinancialReportListProps} props プロパティ
 * @returns 検索済みファイル一覧テーブル
 */
const FinancialReportSearchTable: React.FC<FinancialReportListProps> = (
  props: FinancialReportListProps,
) => {
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [fileId, setFileId] = useState<number | undefined>();
  const [deleteFileId, setDeleteFileId] = useState<number | undefined>();
  const [fileName, setFileName] = useState<string | undefined>();
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
  const [deleteFileDialogVisible, setDeleteFileDialogVisible] = useState(false);
  const [deleteFileDialogLoading, setDeleteFileDialogLoading] = useState(false);
  const [isFileNameDisabled, setIsFileNameDisabled] = useState<boolean>(true);
  const {
    loading,
    searchedFileList,
  } = props;

  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        SOLAR_MENU: {
          FINANCIAL_REPORT_SEARCH: {
            FINANCIAL_REPORT_FILE_DELETE,
            FINANCIAL_REPORT_FILE_DOWNLOAD,
          },
        },
      } = AUTHENTICATION_TYPE_MATRIX;

      if (!canceled) {
        setIsFileNameDisabled(!await containsUIAuthType(FINANCIAL_REPORT_FILE_DOWNLOAD));
        setIsDeleteButtonDisabled(!await containsUIAuthType(FINANCIAL_REPORT_FILE_DELETE));
      }
    })();

    return () => { canceled = true; };
  }, []);

  /**
   * ファイルのリンククリック時のイベントハンドラ
   * @param {number} selectFileId クリックしたファイルのファイルID
   * @param {string} selectFileName クリックしたファイルのファイル名
   */
  const handleClickLink = (selectFileId: number, selectFileName: string) => {
    setFileId(selectFileId);
    setFileName(selectFileName);
    setPreviewModalVisible(true);
  };

  /**
   * モーダルを閉じた時のイベントハンドラ
   */
  const handleCloseModal = () => {
    setFileId(undefined);
    setFileName(undefined);
    setPreviewModalVisible(false);
  };

  /**
   * ファイル削除ボタン押下イベント
   * @param {number} id 選択したファイルのID
   */
  const handleClickFileDelete = (id: number) => {
    setDeleteFileId(id);
    setDeleteFileDialogVisible(true);
  };

  /**
   * 削除確認ダイアログ 削除ボタン押下イベント
   */
  const handleClickDialogDelete = async () => {
    const deleteFileList = [{ id: deleteFileId }];
    setDeleteFileDialogLoading(true);
    await deleteFiles(EP_PATH_SOLAR_FILES, deleteFileList);
    message.success(SUCCESS_DELETE_FINANCIAL_REPORT_FILE);
    setDeleteFileDialogVisible(false);
    setDeleteFileDialogLoading(false);
  };

  /**
   * 削除確認ダイアログ キャンセルボタン押下イベント
   */
  const handleClickDialogCancel = () => {
    setDeleteFileDialogVisible(false);
  };

  /**
   * ファイル名表示領域の作成
   * @param {number} id ファイルID
   * @param {string} name ファイル名
   */
  const renderFileNameByAuth = (id: number, name: string) => {
    if (isFileNameDisabled) {
      return (
        <Tooltip title={isFileNameDisabled && ERROR_NO_AUTH_MESSAGE}>
          <Button
            type="link"
            disabled={isFileNameDisabled}
          >
            {name}
          </Button>
        </Tooltip>
      );
    }

    // AntDesign.Button type="link"だと三点リーダが表示されないため<span>で定義
    return (
      <span
        className="registered-files-table-link-button"
        role="button"
        onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
          handleClickLink(id, name);
          event.currentTarget.blur(); // フォーカスが残ったままだとキーボード操作で同じファイルがダウンロードされてしまうためフォーカスを外す
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLSpanElement>) => {
          handleClickLink(id, name);
          event.currentTarget.blur(); // フォーカスが残ったままだとキーボード操作で同じファイルがダウンロードされてしまうためフォーカスを外す
        }}
        tabIndex={-1}
      >
        {name}
      </span>
    );
  };

  const isSearchedFileListExisted = searchedFileList.length > 0;
  const columns: ColumnProps<SolarRegisteredFile>[] = [
    {
      title: '登録年月日',
      dataIndex: 'lastUpdatedTime',
      width: 170,
      render: (_, searchedFileListRender: SolarRegisteredFile) => {
        const { lastUpdatedTime } = searchedFileListRender;
        return moment(lastUpdatedTime).format(DATE_FORMAT);
      },
    },
    {
      title: 'ファイル名',
      dataIndex: 'name',
      ellipsis: true,
      filterIcon: (filtered) => (
        <Icon type="search" style={{ color: filtered ? '1890ff' : undefined }} />
      ),
      filterDropdown: isSearchedFileListExisted
        ? ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
          <div className="searched-financial-report-table-filter-dropdown">
            <Input
              className="searched-financial-report-table-site-name-input"
              value={selectedKeys ? selectedKeys[0] : undefined}
              onChange={(event) => {
                if (setSelectedKeys) {
                  setSelectedKeys(event.target.value ? [event.target.value] : []);
                }
              }}
              onPressEnter={() => {
                if (confirm) {
                  confirm();
                }
              }}
            />
            <Button
              className="searched-financial-report-table-search-button"
              type="primary"
              onClick={() => {
                if (confirm) {
                  confirm();
                }
              }}
              icon="search"
              size="small"
            >
              検索
            </Button>
            <Button
              className="searched-financial-report-table-reset-button"
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }
              }}
              size="small"
            >
              リセット
            </Button>
          </div>
        ) : null,
      onFilter: (value, result) => (
        result.name.toLowerCase().includes((value as string).toLowerCase())
      ),
      render: (_, searchedFileListRender: SolarRegisteredFile) => {
        const { name, id } = searchedFileListRender;
        return renderFileNameByAuth(id, name);
      },
    },
    {
      title: '',
      dataIndex: 'id',
      width: 130,
      render: (_, searchedFileListRender: SolarRegisteredFile) => {
        const { id } = searchedFileListRender;
        return (
          <Tooltip title={isDeleteButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
            <Button
              type="link"
              onClick={() => handleClickFileDelete(id)}
              disabled={isDeleteButtonDisabled}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Table<SolarRegisteredFile>
        loading={loading}
        dataSource={searchedFileList}
        pagination={false}
        columns={columns}
        rowKey="id"
        scroll={{ y: 530 }}
      />
      <FinancialReportFileDetailViewModal
        visible={previewModalVisible}
        fileId={fileId}
        title={fileName}
        onClose={handleCloseModal}
      />
      <Modal
        title={WARNING_DELETE_FILE_TITLE}
        visible={deleteFileDialogVisible}
        confirmLoading={deleteFileDialogLoading}
        centered
        onOk={handleClickDialogDelete}
        okText={MODAL_BUTTON_TEXT.DELETE}
        okType="danger"
        onCancel={handleClickDialogCancel}
        cancelText={MODAL_BUTTON_TEXT.CANCEL}
      >
        {CONFIRM_DELETE_FINANCIAL_REPORT_FILE}
      </Modal>
    </>
  );
};

export default FinancialReportSearchTable;
