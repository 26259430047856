import React from 'react';
import { CogniteClient } from '@cognite/sdk';
import { Tabs } from 'antd';
import DataTotalNumber from './DataTotalNumber';
import DataCollection from './DataCollection';
import Monitor from './Monitor';
import './Main.css';

/**
 * タブ情報
 */
const TabPane = {
  DataCollection: {
    key: '1',
    tab: 'データ集計',
  },
  Monitor: {
    key: '2',
    tab: 'データ監視',
  },
} as const;

/**
 * ダッシュボードコンポーネント
 */
const Dashboard: React.FC<{ client: CogniteClient }> = (props) => {
  const { client } = props;

  /*
   * 変数/定数定義
   */

  /*
   * イベントハンドラ
   */

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  return (
    <div className="dashboard-container">
      <div className="statistic">
        <DataTotalNumber />
      </div>
      <Tabs defaultActiveKey={TabPane.DataCollection.key}>
        <Tabs.TabPane key={TabPane.DataCollection.key} tab={TabPane.DataCollection.tab}>
          <DataCollection client={client} />
        </Tabs.TabPane>
        <Tabs.TabPane key={TabPane.Monitor.key} tab={TabPane.Monitor.tab}>
          <Monitor />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Dashboard;
