/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import React, { useRef, useState } from 'react';
import {
  AssetListScope,
} from '@cognite/sdk';
import {
  message,
  Button,
  Typography,
  Divider,
  Descriptions,
  Row,
  Col,
  Select,
  Upload,
  Tooltip,
} from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import {
  WorkBook,
  // WorkSheet,
} from 'xlsx';
import SiteCascader, { SiteCascaderSelect } from './parts/SiteCascader';
import SolarSite from '../../utils/Asset/SolarSite';
import { loadAllSolarSiteFromCDFByScope } from '../../utils/Asset/SolarSiteAsset';
import {
  INSUFFICIENT_INPUT_OF_INFORMATION,
  REGISTRATION_FORMAT_ERROR,
  VALIDATIONS_CHECK_ERROR,
  FILE_TYPE_CHECK_ERROR,
  ERROR_FILES_UPLOAD,
  SUCCESS_FILES_UPLOAD,
  ERROR_NO_AUTH_MESSAGE,
  ERROR_FILES_GET_DL_URL,
} from '../../utils/messages';
import './DataRegistration.css';
import DataCheck from './DataCheck';
import FileCheck from './FileCheck';
// import { registerFailureDataEvents } from './Registration/registerFailureDataEvents';
import { registerPresumedElectricEnergy } from './Registration/registerPresumedElectricEnergy';
import { registerPredictedInsolation } from './Registration/registerPredictedInsolation';
import { registerActualFinance } from './Registration/Finance/registerActualFinance';
import { registerPlannedFinance } from './Registration/Finance/registerPlannedFinance';
import uploadRegistrationFile from './UploadRegistrationFile';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';

interface DataRegistrationProps {
  setLoadingFlag: (flag: boolean) => void;
}

const { Title } = Typography;
const { Option } = Select;
/** 管理番号表示上限値 */
const MANAGEMENT_NO_DISPLAY_LIMIT_FOR_ERROR_MESSAGES = 30;
const SDF_ASSET_TYPE = process.env.REACT_APP_SDF_ASSET ?? 'sdfasset';

const DataRegistration: React.FC<DataRegistrationProps> = (props) => {
  const [siteInfoAssetState, setSiteInfoAsset] = React.useState<SolarSite>();
  const [siteInfoFile, setSiteInfoFile] = React.useState<File>();
  const siteInfoValue = useRef<SolarSite | undefined>();
  const siteInfoData = useRef<string>();
  const fileListRef = useRef<UploadFile[]>([]);
  //  const resultRef = useRef<boolean>();
  const [fileList] = useState<UploadFile[]>([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(true);
  const [isDisplayTreeSelect, setIsDisplayTreeSelect] = useState<boolean>(false);

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      fileListRef.current = (newFileList);
      setSiteInfoFile(undefined);
    },
    beforeUpload: (file) => {
      fileListRef.current = [...fileList, file];
      setSiteInfoFile(file);
      return false;
    },
    fileList: fileListRef.current,
  };

  const OptionList = {
    //  機能一時削除につき一部コメントアウト
    // data1: {
    //  label: '故障データ',
    //  value: '故障データ',
    // },
    data2: {
      label: '財務実績値',
      value: '財務実績値',
    },
    data3: {
      label: '計画発電量',
      value: '計画発電量',
    },
    data4: {
      label: '計画日射量',
      value: '計画日射量',
    },
    data5: {
      label: '財務計画値',
      value: '財務計画値',
    },
  } as const;

  /*
   * イベントハンドラ
   */
  React.useEffect(() => {
    (async () => {
      const {
        SOLAR_MENU_DATA_REGISTER_UPDATE,
      } = AUTHENTICATION_TYPE_MATRIX.SOLAR_MENU.DATA_REGISTER;
      setIsUploadDisabled(!await containsUIAuthType(SOLAR_MENU_DATA_REGISTER_UPDATE));
    })();
  }, []);

  React.useEffect(() => {
    // loadSite();
    (async () => {
      if (siteInfoAssetState !== undefined) {
        return;
      }
      const scope: AssetListScope = {
        filter: {
          metadata: {
            assetType: SDF_ASSET_TYPE,
          },
        },
        limit: 1000,
      };

      const siteInfoAsset = await loadAllSolarSiteFromCDFByScope(scope);
      const getSiteInfoAssetID = siteInfoAsset[0].id;

      const SiteAsset = new SolarSite(siteInfoAsset[0]);
      Object.assign(SiteAsset, getSiteInfoAssetID);

      setSiteInfoAsset(SiteAsset);
    })();
  }, [siteInfoAssetState]);

  /**
   * 登録データ種別選択時のイベントハンドラ
   * @param {string} value ファイル
   */
  const handleChange = (value: string) => {
    siteInfoData.current = (value);

    const currentIsSelectedFinance = isSelectedFinance(value);
    if (!currentIsSelectedFinance) {
      // 財務値以外の場合、選択しているサイトをクリア
      siteInfoValue.current = undefined;
    }
    // 財務値の場合、サイト選択を表示
    setIsDisplayTreeSelect(currentIsSelectedFinance);
  };

  /**
   * データ登録サイト選択時のイベントハンドラ
   * @param {SiteCascaderSelect | undefined} selectedValues 選択したサイト情報
   */
  const handleChangeSite = (selectedValues?: SiteCascaderSelect) => {
    if (!selectedValues) {
      siteInfoValue.current = (selectedValues);
    } else {
      const {
        site,
      } = selectedValues;
      siteInfoValue.current = new SolarSite(site);
    }
  };

  // Selectの中身のOptionリスト
  const SelectOptionList: JSX.Element[] = [];
  const OptionVal = Object.values(OptionList);
  OptionVal.forEach((data) => (
    SelectOptionList.push(
      <Option
        key={`aggregate-${data.value}`}
        value={data.value}
      >
        {data.label}
      </Option>,
    )
  ));

  /**
   * 登録ボタン押下時のイベントハンドラ
   */
  const handleUpload = async () => {
    const { setLoadingFlag } = props;
    setLoadingFlag(true);

    if (isExistsUnselectedItem()) {
      setLoadingFlag(false);
      message.error(INSUFFICIENT_INPUT_OF_INFORMATION);
      return;
    }

    const dataType: string = siteInfoData.current!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const solarSiteAssets: SolarSite = siteInfoValue.current!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const selectedSiteInfoFile: File = siteInfoFile!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    const fileCheckResult = await FileCheck(selectedSiteInfoFile);
    if (!fileCheckResult) {
      setLoadingFlag(false);
      message.error(FILE_TYPE_CHECK_ERROR);
      return;
    }

    const dataCheckResult = await DataCheck(dataType, selectedSiteInfoFile, fileCheckResult);

    if (!dataCheckResult) {
      setLoadingFlag(false);
      message.error(REGISTRATION_FORMAT_ERROR);
      return;
    }

    //  各登録処理
    const registerResult = await registerDatapointsWithFileUpload(
      dataType, dataCheckResult as WorkBook, solarSiteAssets, selectedSiteInfoFile,
    );

    setLoadingFlag(false);
    if (registerResult) {
      if ('waitTime' in registerResult) {
        const { errorMessage, waitTime } = registerResult;
        message.error(errorMessage, waitTime);
      } else {
        const { errorMessage } = registerResult;
        message.error(errorMessage);
      }

      return;
    }

    message.info(SUCCESS_FILES_UPLOAD);
  };

  /**
   * アップロードファイルフォーマットボタン押下時のイベントハンドラ
   * データ登録用のフォーマットをダウンロードする
   */
  const handleClickDownload = async () => {
    const aTagForDownload = document.createElement('a');
    let url = '';
    try {
      const formatFile = await fetch('/data_registration_file_format.zip');
      const formatFileBlobData = await formatFile.blob();
      url = URL.createObjectURL(formatFileBlobData);
      document.body.appendChild(aTagForDownload);
      aTagForDownload.href = url;
      aTagForDownload.download = 'SDF_太陽光データ登録ファイルフォーマット';
      aTagForDownload.click();
    } catch (e) {
      message.error(ERROR_FILES_GET_DL_URL);
    } finally {
      aTagForDownload.remove();
      URL.revokeObjectURL(url);
    }
  };

  /**
   * 選択値が財務計画値か財務実績値かを判定
   * @param value 選択値
   * @returns 判定結果（財務計画値or財務実績値の場合、trueを返却）
   */
  const isSelectedFinance = (value: string): boolean => {
    const {
      data2: { label: actualFinance },
      data5: { label: plannedFinance },
    } = OptionList;

    return value === actualFinance || value === plannedFinance;
  };

  /**
   * 未選択の項目があるか判定
   * 財務値の場合、サイト選択も必須項目とする。
   * @returns 判定結果（未選択の項目が存在する場合、trueを返却）
   */
  const isExistsUnselectedItem = (): boolean => {
    // 登録データ種別、基準となる太陽光アセット、登録ファイルが未選択の場合
    if (!siteInfoAssetState || !siteInfoData.current || !siteInfoFile) return true;
    if (isSelectedFinance(siteInfoData.current)) {
      // 財務計画値、財務実績値の場合、サイト選択も必須
      if (!siteInfoValue.current) return true;
    }

    return false;
  };

  /**
   * 管理No上限数を超えた場合のエラーメッセージを生成
   * 上限を超えた場合、上限までの管理Noと「その他N件」を末尾に追加する
   * @param {string[]} messageWithManagementNos エラーメッセージ、管理Noを持つ配列
   * @returns エラーメッセージ
   */
  const generateManagementNoLimitOverNotify = (messageWithManagementNos: string[]): React.ReactNode => {
    // 配列をエラーメッセージのタイトルと管理Noに分割
    const [messageTitle, managementNos] = [messageWithManagementNos.shift(), [...messageWithManagementNos]];

    const messageDetail = managementNos
      .slice(0, MANAGEMENT_NO_DISPLAY_LIMIT_FOR_ERROR_MESSAGES)
      .map((managementNo) => managementNo);
    if (managementNos.length > MANAGEMENT_NO_DISPLAY_LIMIT_FOR_ERROR_MESSAGES) {
      // 上限以上は「その他N件」として表示
      const limitOverMessage = `その他${managementNos.length - MANAGEMENT_NO_DISPLAY_LIMIT_FOR_ERROR_MESSAGES}件`;
      messageDetail.push(
        limitOverMessage,
      );
    }
    const errorMessage = (
      <>
        {messageTitle}
        <br />
        {messageDetail.map((detail) => (
          <React.Fragment key={detail}>
            {detail}
            <br />
          </React.Fragment>
        ))}
        <Button type="primary" onClick={() => message.destroy()}>閉じる</Button>
      </>
    );

    return errorMessage;
  };

  /**
   * データ登録種別に対応した登録処理を行う。
   * 登録が正常に行われた場合、登録データファイルをCDFに登録する。
   * @param {string} dataType 登録データ種別
   * @param {WorkBook} workBook ワークブック
   * @param {SolarSite} solarSiteAssets 太陽光アセット
   * @param {File} selectedSiteInfoFile 登録データファイル
   * @returns 正常終了した場合undefined、失敗した場合表示するエラー情報と表示時間を返却
   */
  const registerDatapointsWithFileUpload = async (
    dataType: string,
    workBook: WorkBook,
    solarSiteAssets: SolarSite,
    selectedSiteInfoFile: File,
  ): Promise<undefined | { errorMessage: string | React.ReactNode, waitTime?: number }> => {
    let result;

    //  機能一時削除につき故障データ処理をコメントアウト
    // if (dataType === OptionList.data1.value) {
    //  const result: boolean = await registerFailureDataEvents(
    //    dataCheckResult as WorkSheet, solarSiteAssets,
    //  );
    //  resultRef.current = (result);
    // }

    if (dataType === OptionList.data2.value) {
      result = await registerActualFinance(workBook, solarSiteAssets);
    }

    if (dataType === OptionList.data3.value) {
      result = await registerPresumedElectricEnergy(workBook);
    }

    if (dataType === OptionList.data4.value) {
      result = await registerPredictedInsolation(workBook);
    }

    if (dataType === OptionList.data5.value) {
      result = await registerPlannedFinance(workBook, solarSiteAssets);
    }

    if (typeof result === 'boolean' && !result) {
      // 財務実績値・財務計画値の処理失敗時
      return { errorMessage: VALIDATIONS_CHECK_ERROR };
    }

    if (typeof result === 'object' && result.error) {
      // 計画発電量・計画日射量の処理失敗時
      let errorResult;
      if (Array.isArray(result.message)) {
        const errorMessage = generateManagementNoLimitOverNotify(result.message);
        errorResult = { errorMessage, waitTime: 0 }; // 管理番号を表示するメッセージは表示時間を無制限にする
      } else {
        const errorMessage = result.message;
        errorResult = { errorMessage };
      }

      return errorResult;
    }

    // 登録データファイルをCDFにアップロード
    const uploadResponse = await uploadRegistrationFile(
      dataType,
      siteInfoAssetState!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      solarSiteAssets,
      selectedSiteInfoFile,
    );

    if (uploadResponse?.result === 'error') {
      return { errorMessage: ERROR_FILES_UPLOAD };
    }

    // 正常終了
    return undefined;
  };

  /**
   * データ登録画面をレンダリングする
   */
  return (
    <>
      <div
        style={{
          backgroundColor: 'white',
        }}
      >
        <div
          className="common-registration-title"
          style={{
            backgroundColor: 'white',
          }}
        >
          <div
            className="common-registration-margin"
          >
            <Title level={4}>ファイルアップロード</Title>
          </div>
          <Divider className="common-registration-divider" />
        </div>
        <Row>
          <Col span={20} offset={1}>
            <Descriptions bordered>
              <Descriptions.Item label="登録データ種別" span={3}>
                <Select
                  defaultValue=""
                  style={{ width: 600 }}
                  onChange={handleChange}
                >
                  {SelectOptionList}
                </Select>
              </Descriptions.Item>
              {
                isDisplayTreeSelect && (
                  <Descriptions.Item label="サイト選択" span={3}>
                    <SiteCascader
                      onChange={handleChangeSite}
                    />
                  </Descriptions.Item>
                )
              }
              <Descriptions.Item label="ファイルアップロード" span={3}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Upload {...uploadProps}>
                  <Button disabled={siteInfoFile !== undefined}>参照</Button>
                </Upload>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row>
          <Col span={1} offset={1}>
            <div className="format-files-download-button">
              <Button
                type="link"
                onClick={handleClickDownload}
                style={{ marginTop: 8 }}
              >
                アップロード用ファイルフォーマット
              </Button>
            </div>
          </Col>
          <Col offset={17}>
            <div className="register-button">
              <Tooltip title={isUploadDisabled && ERROR_NO_AUTH_MESSAGE}>
                <Button
                  type="primary"
                  onClick={handleUpload}
                  disabled={isUploadDisabled}
                  style={{ marginTop: 16 }}
                >
                  登録
                </Button>
              </Tooltip>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DataRegistration;
