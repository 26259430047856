import React from 'react';
import { Asset, CogniteClient } from '@cognite/sdk';
import {
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { message } from 'antd';

import RootAsset from '../../utils/Asset/RootAsset';
import InspectionResult from '../../utils/Event/InspectionResult';

import InspectionResultList from './InspectionResultList';
import InspectionResultEdit from './InspectionResultEdit';
import { getStorageParentAssetId } from '../../utils/storageCommon';
import { assetRetrieve } from '../../utils/dataAccess';
import { EP_PATH_MANAGED_FACILITY } from '../../utils/AWS/EndpointPath';

interface InspectionManagementProps extends RouteComponentProps {
  client: CogniteClient
  setLoadingFlag: (loadingFlag: boolean) => void,
}

const InspectionManagement: React.FC<InspectionManagementProps> = (props) => {
  /*
   * 変数/定数定義
   */
  const [targetResult, setTargetResult] = React.useState<InspectionResult | undefined>();

  const [rootAsset, setRootAsset] = React.useState<RootAsset | undefined>();

  const {
    history, location, client, setLoadingFlag,
  } = props;

  /*
   * イベントハンドラ
   */
  React.useEffect(() => {
    // componentDidMount
    setLoadingFlag(true);
    (async () => {
      try {
        // 設備一覧画面で選択した管理設備からルートアセットを特定
        const parentAssetId = getStorageParentAssetId();
        const parentAsset = await assetRetrieve({
          endpoint: EP_PATH_MANAGED_FACILITY, id: parentAssetId, externalId: undefined,
        });

        // ルートアセットの中から選択した管理設備に紐づくルートアセットを特定
        const rootAssetId = (parentAsset as Asset).rootId;
        const retrieveRootAsset = await RootAsset.retrieveFromCDF(rootAssetId);
        setRootAsset(retrieveRootAsset);
      } catch (exception) {
        message.error('ルートアセットの読み込みに失敗しました。');
      } finally {
        setLoadingFlag(false);
      }
    })();
  }, [setLoadingFlag]);

  const onResultSelect = (result?: InspectionResult) => {
    setTargetResult(result);
    const resultId = result ? result.id : 0;
    history.push(`${location.pathname}/${resultId}`);
  };

  /*
   * 画面描画
   */
  if (!rootAsset) return <></>;

  const basePath = '/inspection';

  return (
    <Switch>
      <Route
        exact
        path={`${basePath}/results`}
        render={() => (
          <InspectionResultList
            onSelect={onResultSelect}
            rootAsset={rootAsset}
            editable
          />
        )}
      />
      <Route
        exact
        path={`${basePath}/results/:result_id`}
        render={
          () => (
            <InspectionResultEdit
              target={targetResult}
              client={client}
              rootAsset={rootAsset}
            />
          )
        }
      />
    </Switch>
  );
};

export default withRouter(InspectionManagement);
