import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import moment from 'moment';

const { Option } = Select;

interface FiscalYearSelectProps {
  value?: string;
  onChange: (value: string) => void;
}

/** 表示する年度の範囲 */
const FISCAL_YEAR_RANGE = 10;

/**
 * 年度セレクトボックス
 */
const FiscalYearSelect: React.FC<FiscalYearSelectProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fiscalYears, setFiscalYears] = useState<{ key: number; text: string }[]>([]);

  useEffect(() => {
    // 処理日の年度から前後の表示範囲年度を算出
    const baseFiscalYear = moment().subtract(3, 'months').subtract(FISCAL_YEAR_RANGE, 'year').year();
    const displayFiscalYears = [...Array(21)]
      .map((_, i) => baseFiscalYear + i)
      .map((year) => ({ key: year, text: year.toString() }));

    setFiscalYears(displayFiscalYears);
    setIsLoading(false);
  }, []);

  const { onChange, value } = props;
  return (
    <Select
      allowClear
      loading={isLoading}
      style={{ width: 150 }}
      value={value}
      onChange={(selectedValue) => onChange(selectedValue)}
    >
      {
        fiscalYears.map(({ key, text }) => (
          <Option key={key}>{text}</Option>
        ))
      }
    </Select>
  );
};

export default FiscalYearSelect;
