/* eslint-disable react/no-array-index-key */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
/* eslint-disable no-return-await */
/* eslint-disable react/sort-comp */
// eslint警告未対応
import React from 'react';
import {
  Layout, Row, Col, Typography, Button, Menu, Divider, Modal, message, Spin, Tooltip,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import {
  EP_PATH_LEARNING_PJ,
  EP_PATH_LEARNING_PJ_LIST,
  EP_PATH_LEARNING_PJ_FILES,
  EP_PATH_LEARNING_PJ_FILES_LIST,
} from '../../../utils/AWS/EndpointPath';
import {
  WARNING_DELETE_LEARNING_PROJECT_TITLE,
  CONFIRM_DELETE_LEARNING_PROJECT_MESSAGES,
  SUCCESS_DELETE_LEARNING_PROJECT,
  ERROR_NO_AUTH_MESSAGE,
} from '../../../utils/messages';
import { getDlAssetId, getStorageMenuKey } from '../../../utils/storageCommon';
import {
  getAllAssets, getAllFiles, deleteAsset, deleteFiles, removeAssetId,
} from '../../../utils/dataAccess';
import { sortOrder } from '../../../utils/sort';
import { MimeType } from '../../../utils/File/BaseFile';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../../utils/common/Authentication';
import CreateProjectDialog from './CreateProjectDialog';

const { Sider } = Layout;
const { Title } = Typography;
const { Jpeg } = MimeType;

/** 学習プロジェクトエリアコンテナ */
const ProjectListContainer = styled(Sider)`
  background-color: white;
  overflow: auto;
  height: 100vh;
`;

/** 学習プロジェクトタイトルコンテナ */
const ProjectTitleContainer = styled.div`
  padding-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
`;

/**
 * プロジェクト名
 * 表示枠を超える文字数の場合、末尾を三点リーダに変換する。
 */
const ProjectName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/** ダイアログのボタンラベル */
const MODAL_BUTTON_TEXT = {
  DELETE: '削除',
  CANCEL: 'キャンセル',
};

/**
 * 学習プロジェクトコンポーネントクラス
 */
class ProjectList extends React.Component {
  state = {
    /** プロジェクト情報 */
    projectComponents: [],
    /** プロジェクト追加ダイアログの表示状態 true: 表示 / false: 非表示 */
    createProjectDialogVisible: false,
    deleteProjectDialogVisible: false,
    deleteProjectDialogLoading: false,
    /** プロジェクト一覧読み込み状態 true: 読み込み中 / false: 読み込み完了 */
    loading: false,
    isAddButtonDisabled: true,
    isReloadButtonDisabled: true,
  };

  /**
   * 選択しているAssetのIDを元にプロジェクト情報を取得する。
   * 取得したAssetを定義された順序(metadata.order)の昇順でソートし、返却する。
   * @returns {Array<object>} プロジェクト情報
   */
  async loadProjects() {
    const dlAssetId = await getDlAssetId();
    const filter = { parentIds: [dlAssetId] };

    const projects = await getAllAssets(EP_PATH_LEARNING_PJ_LIST, filter);
    sortOrder(projects);

    return projects;
  }

  /**
   * 学習プロジェクトに紐づいている画像ファイルの紐づけを解除する。
   * @param {number} projectId 学習プロジェクトID
   */
  async disconnectImages(projectId) {
    const fileFilter = { assetIds: [projectId], mimeType: Jpeg, uploaded: true };
    const allImages = await getAllFiles(EP_PATH_LEARNING_PJ_FILES_LIST, fileFilter);

    await Promise.all(
      allImages.map(async (image) => await removeAssetId(EP_PATH_LEARNING_PJ_FILES, image.id, projectId)),
    );
  }

  /**
  * 学習プロジェクトに紐づいているファイルを削除する。
  * ログファイルはタスク一覧画面で表示するため、対象外とする。
  * @param {number} projectId 学習プロジェクトID
  */
  async deleteFiles(projectId) {
    const fileFilter = { assetIds: [projectId], uploaded: true };
    const allFiles = await getAllFiles(EP_PATH_LEARNING_PJ_FILES_LIST, fileFilter);

    let deleteFileList = [];
    for (let index = 0; index < allFiles.length; index++) {
      const file = allFiles[index];
      if (file.metadata && file.metadata.type === 'log') {
        // ログファイルは削除対象外
        continue;
      }

      if (file.mimeType === Jpeg) {
        // 前処理で画像ファイルは紐づけを解除しているがタイミングによって取得できてしまうためここで制御する。
        continue;
      }

      deleteFileList.push({ id: file.id });

      if (deleteFileList.length >= 1000) {
        // 1000件づつ削除する必要がある(https://docs.cognite.com/api/v1/#operation/deleteFiles)
        await deleteFiles(EP_PATH_LEARNING_PJ_FILES, deleteFileList);
        deleteFileList = []; // 配列初期化
      }
    }

    if (deleteFileList.length > 0) {
      await deleteFiles(EP_PATH_LEARNING_PJ_FILES, deleteFileList);
    }
  }

  /**
   * 学習プロジェクトを削除する。
   * @param {number} projectId 学習プロジェクトID
   */
  async deleteLearningProject(projectId) {
    await deleteAsset(EP_PATH_LEARNING_PJ, { items: [{ id: projectId }] });
  }

  /**
   * 学習プロジェクトと紐づく情報を削除する。紐づく情報は下記のとおり
   * - 写真情報(学習プロジェクトとの紐づけを解除)
   * - アノテーション情報(クラス)
   * - 設定ファイル
   * - 学習モデル
   * - ログファイル
   * @param {number} projectId 削除する学習プロジェクトID
   */
  async deleteProjects(projectId) {
    // 学習プロジェクトに紐づけられている写真データの紐づけ解除
    await this.disconnectImages(projectId);

    // 学習プロジェクトに紐づけられているファイルの削除
    await this.deleteFiles(projectId);

    // 学習プロジェクト自体の削除(asset)
    await this.deleteLearningProject(projectId);
  }

  /**
   * プロジェクト一覧を作成する。
   * @param {boolean} isLearningProjectLinkDisabled 学習プロジェクトリンクのUI権限判定結果
   * @param {boolean} isDeleteButtonDisabled 削除ボタンのUI権限判定結果
   */
  async createProjectComponents() {
    const menuKey = getStorageMenuKey();
    const { ANNOTATION, MACHINE_LEARNING } = AUTHENTICATION_TYPE_MATRIX;
    const {
      LEARNING_PROJECTS_LIST_LEARNING_PROJECTS_LINK,
      LEARNING_PROJECTS_LIST_DELETE_BUTTON,
    } = menuKey === 'annotation-menu' ? ANNOTATION : MACHINE_LEARNING;

    const isLearningProjectLinkDisabled = !await containsUIAuthType(LEARNING_PROJECTS_LIST_LEARNING_PROJECTS_LINK);
    const isDeleteButtonDisabled = !await containsUIAuthType(LEARNING_PROJECTS_LIST_DELETE_BUTTON);

    const loadProjects = await this.loadProjects();
    if (loadProjects.length === 0) {
      this.setState({
        projectComponents: [],
      });

      return;
    }

    const projectComponents = loadProjects.map((loadProject) => {
      const projectComponent = (
        <Menu.Item key={loadProject.id} disabled={isLearningProjectLinkDisabled}>
          <ProjectMenuItem
            name={loadProject.name}
            onClick={(event) => this.handleClickProjectDelete(event, loadProject.id)}
            isLearningProjectLinkDisabled={isLearningProjectLinkDisabled}
            isDeleteButtonDisabled={isDeleteButtonDisabled}
          />
        </Menu.Item>
      );
      return projectComponent;
    });

    this.setState({
      projectComponents,
    });
  }

  /**
   * レンダリング直後に一度だけ呼ばれる。
   * プロジェクト情報を取得し、画面に反映する。
   */
  async componentDidMount() {
    this.setState({ loading: true });
    const menuKey = getStorageMenuKey();
    const { ANNOTATION, MACHINE_LEARNING } = AUTHENTICATION_TYPE_MATRIX;
    const {
      LEARNING_PROJECTS_LIST_ADD_BUTTON,
      LEARNING_PROJECTS_LIST_UPDATE_BUTTON,
    } = menuKey === 'annotation-menu' ? ANNOTATION : MACHINE_LEARNING;

    // プロジェクト一覧読み込み
    await this.createProjectComponents();

    this.setState({
      loading: false,
      isAddButtonDisabled: !await containsUIAuthType(LEARNING_PROJECTS_LIST_ADD_BUTTON),
      isReloadButtonDisabled: !await containsUIAuthType(LEARNING_PROJECTS_LIST_UPDATE_BUTTON),
    });
  }

  /**
   * プロジェクト追加ボタン押下イベント
   *
   * プロジェクト作成ダイアログを表示する。
   */
  handleClickAddProject = () => {
    this.setState({ createProjectDialogVisible: true });
  };

  /**
   * プロジェクト作成ダイアログ「作成」ボタン押下イベント
   * プロジェクト一覧を再描画し、ダイアログを非表示にする。
   */
  handleModalOk = async () => {
    // プロジェクト一覧再読み込み
    await this.createProjectComponents();

    this.setState({ createProjectDialogVisible: false });
  };

  /**
   * プロジェクト作成ダイアログ「キャンセル」ボタン押下イベント
   * ダイアログを非表示にする。
   */
  handleModalCancel = () => {
    this.setState({ createProjectDialogVisible: false });
  };

  /**
   * プロジェクト選択イベント
   * @param {object} event 選択したプロジェクト
   */
  handleClickProjects = (event) => {
    this.props.onSelect({ key: event.key, title: event.item.node.innerText });
  };

  /**
   * プロジェクト削除ボタン押下イベント
   * @param {object} event 選択したプロジェクト
   * @param {number} id 選択したプロジェクトのID
   */
  handleClickProjectDelete = (event, id) => {
    // <Menu>のクリックイベントは呼び出さないようにする。
    event.stopPropagation();

    this.deleteProjectId = id; // 削除対象の学習プロジェクトID
    this.setState({ deleteProjectDialogVisible: true });
  };

  /**
   * 学習プロジェクト削除確認ダイアログ 削除ボタン押下イベント
   */
  handleClickDialogDelete = async () => {
    this.setState({ deleteProjectDialogLoading: true });

    await this.deleteProjects(this.deleteProjectId);

    // プロジェクト一覧再読み込み
    await this.createProjectComponents();

    // コンテンツエリアクリア
    this.props.onDeleted();

    message.success(SUCCESS_DELETE_LEARNING_PROJECT);

    this.setState({ deleteProjectDialogVisible: false, deleteProjectDialogLoading: false });
  };

  /**
   * 学習プロジェクト削除確認ダイアログ キャンセルボタン押下イベント
   */
  handleClickDialogCancel = () => {
    this.deleteProjectId = undefined;
    this.setState({ deleteProjectDialogVisible: false });
  };

  /**
   * プロジェクト一覧再読み込みボタン押下イベント
   */
  handleClickReload = async () => {
    this.setState({ loading: true });
    // プロジェクト一覧再読み込み
    await this.createProjectComponents();
    this.setState({ loading: false });
  };

  /**
   * 学習プロジェクト一覧をレンダリングする。
   */
  render() {
    const { title } = this.props;
    const {
      projectComponents,
      createProjectDialogVisible,
      deleteProjectDialogVisible,
      deleteProjectDialogLoading,
      loading,
      isAddButtonDisabled,
      isReloadButtonDisabled,
    } = this.state;

    return (
      <>
        <ProjectListContainer width={300}>
          <ProjectTitle
            title={title}
            onAddPj={this.handleClickAddProject}
            onReload={this.handleClickReload}
            isAddButtonDisabled={isAddButtonDisabled}
            isReloadButtonDisabled={isReloadButtonDisabled}
          />

          <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />

          <Spin spinning={loading} style={{ top: '200px' }}>
            {
              projectComponents &&
              <Menu mode="inline" onClick={this.handleClickProjects}>
                {projectComponents}
              </Menu>
            }
          </Spin>
        </ProjectListContainer>
        {
          createProjectDialogVisible &&
          <CreateProjectDialog
            visible={createProjectDialogVisible}
            title="学習プロジェクト追加"
            modalOk={this.handleModalOk}
            modalCancel={this.handleModalCancel}
          />
        }
        <Modal
          title={WARNING_DELETE_LEARNING_PROJECT_TITLE}
          visible={deleteProjectDialogVisible}
          confirmLoading={deleteProjectDialogLoading}
          centered
          onOk={this.handleClickDialogDelete}
          okText={MODAL_BUTTON_TEXT.DELETE}
          okType="danger"
          onCancel={this.handleClickDialogCancel}
          cancelText={MODAL_BUTTON_TEXT.CANCEL}
        >
          {
            CONFIRM_DELETE_LEARNING_PROJECT_MESSAGES.map((message, index) => <p key={index}>{message}</p>)
          }
        </Modal>
      </>
    );
  }
}

/**
 * プロジェクトタイトルコンポーネント
 * @param {{title: string, onAddPj: function, onReload: function, isAddButtonDisabled: boolean, isReloadButtonDisabled: boolean }} { title, onAddPj, onReload, isAddButtonDisabled, isReloadButtonDisabled }
 * 表示するタイトル、追加ボタン押下時の関数
 */
const ProjectTitle = ({
  title, onAddPj, onReload, isAddButtonDisabled, isReloadButtonDisabled,
}) => (
  <ProjectTitleContainer>
    <Row>
      <Col span={18}>
        <Title level={4}>{title}</Title>
      </Col>
      <Col span={3}>
        <Tooltip title={isAddButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
          <Button type="link" icon="plus" onClick={onAddPj} disabled={isAddButtonDisabled} />
        </Tooltip>
      </Col>
      <Col span={3}>
        <Tooltip title={isReloadButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
          <Button type="link" icon="reload" onClick={onReload} disabled={isReloadButtonDisabled} />
        </Tooltip>
      </Col>
    </Row>
  </ProjectTitleContainer>
);

/**
 * プロジェクトの各行を表すコンポーネント
 * @param {{name: string, onClick: function, isLearningProjectLinkDisabled: boolean, isDeleteButtonDisabled: boolean}} { name, onClick, isLearningProjectLinkDisabled, isDeleteButtonDisabled }
 * プロジェクト名、削除ボタン押下時の関数
 */
const ProjectMenuItem = ({
  name, onClick, isLearningProjectLinkDisabled, isDeleteButtonDisabled,
}) => (
  <Row>
    <Col span={20}>
      <Tooltip placement="topLeft" title={isLearningProjectLinkDisabled && ERROR_NO_AUTH_MESSAGE}>
        <ProjectName>{name}</ProjectName>
      </Tooltip>
    </Col>
    <Col span={4}>
      <Tooltip title={isDeleteButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
        <Button type="link" onClick={onClick} disabled={isDeleteButtonDisabled}>
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </Col>
  </Row>
);

export default ProjectList;
