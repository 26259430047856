import React from 'react';
import { Spin } from 'antd';

import './Spinner.css';

/**
 * 画面中央に表示するSpinコンポーネント
 */
const Spinner: React.VFC = () => (
  <div className="spinner-container">
    <Spin size="large" tip="Loading..." />
  </div>
);

export default Spinner;
