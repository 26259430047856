/* 太陽光ダッシュボード顧客種別 */

const SolarClientGroup = {
  AeG: 'ae_g',
  AeEgs: 'ae_egs',
} as const;

type SolarClientGroup = typeof SolarClientGroup[keyof typeof SolarClientGroup];

// 顧客種別に応じた各種設定値
const CLIENT_GROUP_INFO = {
  ae_g: {
    urlMetadataKey: 'url',
    siteSelectScreenTitle: '運用ダッシュボードサイト選択',
  },
  ae_egs: {
    urlMetadataKey: 'maintenance_url',
    siteSelectScreenTitle: '保守ダッシュボードサイト選択',
  },
};

/**
 * 顧客種別に応じて、GrafanaのダッシュボードURLを保持するメタデータ項目名を取得する。
 * @param {SolarClientGroup} clientGroup 顧客種別
 * @returns {string} GrafanaのダッシュボードURLを保持するメタデータの項目名
 */
const getDashboardUrlMetadataKey = (clientGroup: SolarClientGroup): string => (
  CLIENT_GROUP_INFO[clientGroup].urlMetadataKey
);

/**
 * 顧客種別に応じて、Grafanaのサイト選択用画面の名称を取得する。
 * @param {SolarClientGroup} clientGroup 顧客種別
 * @returns {string} Grafanaのサイト選択用画面の名称
 */
const getSiteSelectScreenTitle = (clientGroup: SolarClientGroup): string => (
  CLIENT_GROUP_INFO[clientGroup].siteSelectScreenTitle
);

export {
  SolarClientGroup,
  getDashboardUrlMetadataKey,
  getSiteSelectScreenTitle,
};
