import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  message,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';

import EmailNotificationGroupSettingsModal from './parts/EmailNotificationGroupSettingsModal';
import { FilterIcon, TextFilter, FilterDropdown } from './parts/FilterComponent';
import { ERROR_NO_AUTH_MESSAGE, ERROR_NOTIFICATION_DELETE, SUCCESS_NOTIFICATION_DELETED } from '../../utils/messages';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import EmailNotificationGroup from '../../utils/DynamoDB/EmailNotificationGroup';
import { getEmailTypeJp, emailTypeFilter } from '../../utils/DynamoDB/EmailTypes';
import EmailNotificationTargetSiteInfo from '../../utils/Event/EmailNotificationTargetSiteInfo';

import './EmailNotificationGroupSettings.css';

const { Title } = Typography;

/**
 * ソート処理(デフォルト表示)
 * @param {EmailNotificationGroup} a 比較対象A
 * @param {EmailNotificationGroup} b 比較対象B
 * @returns {number} ソートの判定結果
 */
const sortGroupsByCreatedDate = (a: EmailNotificationGroup, b: EmailNotificationGroup) => {
  if (a.created === b.created) {
    return a.notificationGroup.localeCompare(b.notificationGroup);
  }
  if (!a.created) {
    return -1;
  }
  if (!b.created) {
    return 1;
  }
  return a.created - b.created;
};

/**
 * 発電・死活監視メール通知設定画面
 */
const EmailNotificationGroupSettings: React.FC = () => {
  const [notificationGroups, setNotificationGroups] = useState<EmailNotificationGroup[]>([]);
  const [editTargets, setEditTargets] = useState<EmailNotificationGroup>(new EmailNotificationGroup());
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [tableRefresh, setTableRefresh] = useState<number>(0);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState<boolean>(false);
  const [siteInformationListForModal, setSiteInformationListForModal] = useState<EmailNotificationTargetSiteInfo[]>([]);

  useEffect(() => {
    let canceled = false;

    (async () => {
      setLoading(true);

      const {
        SOLAR_MENU: {
          EMAIL_NOTIFICATION_SETTINGS: {
            SOLAR_EMAIL_NOTIFICATION_SETTINGS_UPDATE: EMAIL_NOTIFICATION_GROUP_UPDATE,
            SOLAR_EMAIL_NOTIFICATION_SETTINGS_DELETE: EMAIL_NOTIFICATION_GROUP_DELETE,
          },
        },
      } = AUTHENTICATION_TYPE_MATRIX;

      const allNotificationGroups = await EmailNotificationGroup.getAllNotificationGroups()
        .then((groups) => [...groups].sort(sortGroupsByCreatedDate));

      // CDFからサイト情報を取得
      const siteInformationList = await EmailNotificationTargetSiteInfo.loadSiteInformationList() ?? [];

      if (!canceled) {
        setIsAddButtonDisabled(!await containsUIAuthType(EMAIL_NOTIFICATION_GROUP_UPDATE));
        setIsDeleteButtonDisabled(!await containsUIAuthType(EMAIL_NOTIFICATION_GROUP_DELETE));
        setNotificationGroups(allNotificationGroups);
        setSiteInformationListForModal(siteInformationList);
      }
      setLoading(false);
    })();

    return () => { canceled = true; };
  }, [tableRefresh]);

  /**
   * 編集ボタン押下時のイベントハンドラ
   * @param {EmailNotificationGroup} targetGroup 選択項目
   */
  const handleOpenEditModal = (targetGroup: EmailNotificationGroup) => {
    setEditTargets(targetGroup);
    setIsModalVisible(true);
  };

  /**
   * ＋追加ボタン押下時のイベントハンドラ
   */
  const handleOpenAddModal = () => {
    setEditTargets(new EmailNotificationGroup());
    setIsModalVisible(true);
  };

  /**
   * モーダルを閉じる処理
   */
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  /**
   * 親画面の一覧リフレッシュ処理
   */
  const handleTableRefresh = () => {
    setTableRefresh(tableRefresh + 1);
  };

  /**
   * 削除ボタン押下時のイベントハンドラ
   * @param {EmailNotificationGroup} targetGroup 削除対象のデータ
   */
  const handleDeleteMailSetting = async (targetGroup: EmailNotificationGroup) => {
    try {
      await EmailNotificationGroup.deleteNotificationGroup(targetGroup);
      handleTableRefresh();
      message.success(SUCCESS_NOTIFICATION_DELETED);
    } catch {
      message.error(ERROR_NOTIFICATION_DELETE);
    }
  };

  const columns: ColumnProps<EmailNotificationGroup>[] = [
    {
      title: 'メール通知分類名',
      dataIndex: 'notificationGroup',
      width: 100,
      filterIcon: FilterIcon,
      filterDropdown: !!notificationGroups && TextFilter,
      onFilter: (value, result) => (result.notificationGroup.toLowerCase().includes((value as string).toLowerCase())),
      render: (_, item: EmailNotificationGroup) => item.notificationGroup,
    },
    {
      title: '通知先グループ',
      dataIndex: 'targetGroups',
      width: 190,
      filterIcon: FilterIcon,
      filterDropdown: !!notificationGroups && TextFilter,
      onFilter: (value, result) => (result.targetGroupNames.some((targetGroupName) => targetGroupName.includes(value))),
      render: (_, item: EmailNotificationGroup) => item.targetGroupNames.map((targetGroupName) => (
        <Tag key={targetGroupName}>{targetGroupName}</Tag>
      )),
    },
    {
      title: '通知メール',
      dataIndex: 'targetEmailTypes',
      width: 205,
      filters: emailTypeFilter,
      onFilter: (value, result) => result.emailTypes.some((mailType) => mailType === value),
      filterDropdown: !!notificationGroups && FilterDropdown,
      render: (_, item: EmailNotificationGroup) => item.emailTypes.map((targetEmailType) => (
        <Tag key={targetEmailType}>{getEmailTypeJp(targetEmailType)}</Tag>
      )),
    },
    {
      dataIndex: 'edit',
      width: 60,
      render: (_, item: EmailNotificationGroup) => (
        <Row>
          <Col span={12}>
            <Button
              className="group-setting-edit-button"
              type="primary"
              onClick={() => handleOpenEditModal(item)}
            >
              編集
            </Button>
          </Col>
          <Col span={12}>
            <Popconfirm
              title="本当に削除しますか？"
              okText="はい"
              cancelText="いいえ"
              onConfirm={() => handleDeleteMailSetting(item)}
              disabled={isDeleteButtonDisabled}
            >
              <Tooltip title={isDeleteButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                <Button
                  className="group-setting-delete-button"
                  disabled={isDeleteButtonDisabled}
                >
                  削除
                </Button>
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div className="email-notification-group-settings-container">
      <Spin spinning={loading}>
        <div className="email-notification-group-settings-title">
          <Title level={4}>発電・死活監視メール設定</Title>
        </div>
        <Divider className="email-notification-group-settings-divider" />

        <div className="email-notification-group-settings-add-button">
          <Tooltip title={isAddButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
            <Button
              type="primary"
              onClick={handleOpenAddModal}
              style={{ marginLeft: 15 }}
              disabled={isAddButtonDisabled}
            >
              ＋追加
            </Button>
          </Tooltip>
        </div>
        <EmailNotificationGroupSettingsModal
          visible={isModalVisible}
          onClose={handleCloseModal}
          targetInfo={editTargets}
          siteInformationListForModal={siteInformationListForModal}
          refreshParentTable={handleTableRefresh}
        />
        <Table<EmailNotificationGroup>
          className="email-notification-group-settings-table"
          dataSource={notificationGroups}
          pagination={false}
          columns={columns}
          rowKey="notificationGroup"
          scroll={{ y: 715 }}
        />
      </Spin>
    </div>
  );
};

export default EmailNotificationGroupSettings;
