import {
  Asset,
  AssetListScope,
  CursorAndAsyncIterator,
  AssetAggregateQuery,
  AggregateResponse,
  ItemsResponse,
} from '@cognite/sdk';

import { postApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_ROOT_ASSET_PROJECT, EP_PATH_LEARNING_PJ_AGGREGATE } from '../AWS/EndpointPath';
import BaseAsset from './BaseAsset';

class AiProject extends BaseAsset {
  /*
   * クラスメソッド
   */
  /**
   * Projectアセットを取得する。
   * @returns Projectアセット
   */
  static async loadAllFromCDF(): Promise<AiProject[]> {
    const scope: AssetListScope = {
      filter: { metadata: { assetType: 'deeplearning' } },
      limit: Infinity,
    };

    const assets = await postApiGateway<AssetListScope, CursorAndAsyncIterator<Asset>>(
      EP_PATH_ROOT_ASSET_PROJECT, scope,
    );

    return assets.items.map((asset) => new AiProject(asset));
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      super(asset);
    } else {
      super();
    }
  }

  /*
   * メンバメソッド
   */
  cloneSelf(): AiProject {
    const clone = new AiProject();
    Object.assign(clone, this);

    return clone;
  }

  // eslint-disable-next-line class-methods-use-this
  async loadChildrenFromCDF(): Promise<BaseAsset[]> {
    return [];
  }

  /**
   * 学習プロジェクト配下のアセット数カウント
   * @returns アセット数
   */
  async countSubAssets(): Promise<number> {
    const filter: AssetAggregateQuery = {
      filter: { assetSubtreeIds: [{ id: this.id }] },
    };

    const aiProjectResultAggregates = (
      await postApiGateway<AssetAggregateQuery, ItemsResponse<AggregateResponse>>(
        EP_PATH_LEARNING_PJ_AGGREGATE, filter,
      )
    );

    return aiProjectResultAggregates.items[0].count;
  }
}

export default AiProject;
