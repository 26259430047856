/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import { getAllAssets } from '../dataAccess';
import editor from '../cocoJsonEditor';
import { isEmpty } from '../common';
import { FILE_METADATA_TYPE } from '../common/SDFDataType';
import { EP_PATH_LEARNING_PJ_LIST, EP_PATH_LEARNING_PJ_FILES_LIST } from '../AWS/EndpointPath';
import { postApiGateway } from '../AWS/ApiGateway';
import {
  VALIDATE_ERROR_PROJECT_NAME_REQUIRE,
  VALIDATE_ERROR_PROJECT_NAME_TOO_LONG,
  VALIDATE_ERROR_PROJECT_NAME_ALREADY_EXISTS,
  VALIDATE_ERROR_CLASS_NAME_CHARACTER_TYPE,
  VALIDATE_ERROR_CLASS_NAME_TOO_LONG,
  VALIDATE_ERROR_CLASS_NAME_ALREADY_EXISTS,
  VALIDATE_ERROR_MODEL_NAME_REQUIRE,
  VALIDATE_ERROR_PARAMETER_REQUIRE,
  VALIDATE_ERROR_NO_EXISTS_ANNOTATION_FILE,
} from '../messages';

/**
 * 学習プロジェクト名の上限
 * @link [Cognite Create Asset](https://docs.cognite.com/api/v1/#operation/createAssets)
 */
const LEARNING_PROJECT_NAME_LIMIT = 140;

/** クラス名文字種別 */
const CLASS_NAME_CHARACTER_TYPE = /^[0-9a-zA-Z]*$/;
/** クラス名の上限 */
const CLASS_NAME_LIMIT = 30;

/**
 * 学習プロジェクト名を検証する。下記の場合、エラーと判断する。
 * - 未入力の場合(undefined, null, 空白のみの文字列)
 * - 桁数が140文字より大きい場合
 * - 同名の学習プロジェクトが存在する場合
 * @param {title} projectName 学習プロジェクト名
 * @param {number} dlAssetId DLAssetID
 * @returns {Object} 判定結果(エラーが存在する場合: { hasError: true, message: string}, 存在しない場合: { hasError: false })
 */
async function validateLearningProjectName(projectName, dlAssetId) {
  // 必須入力チェック
  const isEmptyProjectName = isEmpty(projectName);
  if (isEmptyProjectName) {
    return {
      hasError: true,
      message: VALIDATE_ERROR_PROJECT_NAME_REQUIRE,
    };
  }

  // 文字数チェック
  const isLimitWithin = isLimitWithinLearningProjectName(projectName);
  if (!isLimitWithin) {
    return {
      hasError: true,
      message: VALIDATE_ERROR_PROJECT_NAME_TOO_LONG,
    };
  }

  // 存在確認
  const isExists = await isExistsLearningProject(projectName, dlAssetId);
  if (isExists) {
    return {
      hasError: true,
      message: VALIDATE_ERROR_PROJECT_NAME_ALREADY_EXISTS,
    };
  }

  return { hasError: false };
}

/**
 * クラス名を検証する。下記の場合、エラーと判断する。
 * - 半角英数字以外が含まれている場合
 * - 桁数が30文字より大きい場合
 * - 同名のクラス名が存在する場合
 * @param {string} className クラス名
 * @param {string} externalId エクスターナルID
 * @returns {Object} 判定結果(エラーが存在する場合: { hasError: true, message: string}, 存在しない場合: { hasError: false })
 */
async function validateLearningClassName(className, externalId) {
  // 文字種別チェック
  const isValid = isValidCharacterTypeClassName(className);
  if (!isValid) {
    return {
      hasError: true,
      message: VALIDATE_ERROR_CLASS_NAME_CHARACTER_TYPE,
    };
  }

  // 文字数
  const isLimitWithin = isLimitWithinClassName(className);
  if (!isLimitWithin) {
    return {
      hasError: true,
      message: VALIDATE_ERROR_CLASS_NAME_TOO_LONG,
    };
  }

  // 存在確認
  const isExists = await isExistsClassName(className, externalId);
  if (isExists) {
    return {
      hasError: true,
      message: VALIDATE_ERROR_CLASS_NAME_ALREADY_EXISTS,
    };
  }

  return { hasError: false };
}

/**
 * 学習パラメータを検証する。下記の場合、エラーと判断する。
 * - 学習モデル名が未入力の場合
 * - アノテーション情報ファイルが存在しない場合
 * @param {string} modelName 学習モデル名
 * @param {string} parameter パラメータ
 * @param {number} learningProjectAssetId 学習プロジェクトID
 * @returns {Object} 判定結果(エラーが存在する場合: { hasError: true, field: string, value: string, message: string}, 存在しない場合: { hasError: false })
 */
async function validateLearningParameters(modelName, parameter, learningProjectAssetId) {
  // 学習モデル名必須入力チェック
  const isEmptyModelName = isEmpty(modelName);
  if (isEmptyModelName) {
    return {
      hasError: true,
      field: 'modelName',
      value: modelName,
      message: VALIDATE_ERROR_MODEL_NAME_REQUIRE,
    };
  }

  // パラメータ必須入力チェック
  const isEmptyParameter = isEmpty(parameter);
  if (isEmptyParameter) {
    return {
      hasError: true,
      field: 'parameter',
      value: parameter,
      message: VALIDATE_ERROR_PARAMETER_REQUIRE,
    };
  }

  // アノテーション情報ファイル存在確認
  const isExists = await isExistsAnnotationFile(learningProjectAssetId);
  if (!isExists) {
    return {
      hasError: true,
      message: VALIDATE_ERROR_NO_EXISTS_ANNOTATION_FILE,
    };
  }

  return { hasError: false };
}

/**
 * 学習プロジェクト名の長さが上限以内か判定する。
 * @param {string} learningProjectName 学習プロジェクト名
 * @returns {boolean} 判定結果(true: 上限以内 / false: 上限より大きい場合)
 */
function isLimitWithinLearningProjectName(learningProjectName) {
  const isLimitWithin = [...learningProjectName].length <= LEARNING_PROJECT_NAME_LIMIT;
  return isLimitWithin;
}

/**
 * 同名の学習プロジェクトが登録済みか判定する。
 * @param {string} learningProjectName 学習プロジェクト名
 * @param {number} dlAssetId DLAssetID
 * @returns {boolean} 判定結果(true: 有 / false: 無)
 */
async function isExistsLearningProject(learningProjectName, dlAssetId) {
  const sameNameFilter = { name: learningProjectName, parentIds: [dlAssetId] };
  const sameNameProjects = await getAllAssets(EP_PATH_LEARNING_PJ_LIST, sameNameFilter);
  return sameNameProjects.length > 0;
}

/**
 * クラス名の文字種別を判定する。(半角英数字のみ可)
 * @param {string}} className クラス名
 * @returns {boolean} 判定結果(true: 有 / false: 無)
 */
function isValidCharacterTypeClassName(className) {
  const isValid = className.match(CLASS_NAME_CHARACTER_TYPE);
  return isValid;
}

/**
 * クラス名の長さが上限以内か判定する。
 * @param {string} className クラス名の上限
 * @returns {boolean} 判定結果(true: 有 / false: 無)
 */
function isLimitWithinClassName(className) {
  const isLimitWithin = [...className].length <= CLASS_NAME_LIMIT;
  return isLimitWithin;
}

/**
 * 同名のクラス名が登録済みか判定する。
 * @param {string} className クラス名
 * @param {string} externalId エクスターナルID
 * @returns {boolean} 判定結果(true: 登録済み / false: 未登録)
 */
async function isExistsClassName(className, externalId) {
  const jsonFile = await editor.getCocoJsonFile(externalId);
  if (!jsonFile) {
    // JSONファイルが存在しない場合
    return false;
  }

  const isExists = jsonFile.categories.some((element) => className === element.name);
  return isExists;
}

/**
 * アノテーション情報ファイルが存在するか判定する。
 * @param {string} className クラス名
 * @returns {boolean} 判定結果(true: あり / false: なし)
 */
async function isExistsAnnotationFile(learningProjectAssetId) {
  // 検索条件
  const filesFilter = {
    filter: {
      assetIds: [learningProjectAssetId],
      metadata: {
        type: FILE_METADATA_TYPE.COCO_JSON,
      },
    },
  };

  const files = await postApiGateway(EP_PATH_LEARNING_PJ_FILES_LIST, filesFilter);
  return files.items.length > 0;
}

export {
  validateLearningProjectName,
  validateLearningClassName,
  validateLearningParameters,
};
