import {
  CogniteInternalId, FileChangeUpdate, FileInfo,
} from '@cognite/sdk';

import {
  getApiGateway,
  getApiGatewayForImage,
  putApiGateway,
  RequestFileChangeUpdate,
  ResponseCogniteError,
  ResponseCogniteItems,
  ResponseCogniteOrError,
} from '../AWS/ApiGateway';
import {
  EP_PATH_LEARNING_PJ_FILES,
  EP_PATH_LEARNING_PJ_FILES_ICON,
} from '../AWS/EndpointPath';

/**
 * 学習プロジェクトに紐づくファイルを取得する(ID指定)
 * @param id ファイルID
 * @returns ファイル情報
 */
const retrieveLearningProjectFile = async (id: CogniteInternalId): Promise<FileInfo> => {
  const endpoint = `${EP_PATH_LEARNING_PJ_FILES}/${id}`;
  const learningProjectFile = await getApiGateway<FileInfo | ResponseCogniteError>(endpoint);

  if ((learningProjectFile as ResponseCogniteError).error) {
    throw learningProjectFile;
  }

  return learningProjectFile as FileInfo;
};

/**
 * 学習プロジェクトに紐づくファイルのアイコンを取得する
 * @param fileId ファイルID
 * @returns アイコンデータ(base64形式の文字列)
 */
const getLearningProjectFilesIcon = async (fileId: CogniteInternalId): Promise<string> => {
  const getUrl = `${EP_PATH_LEARNING_PJ_FILES_ICON}/${fileId}`;
  const learningProjectFilesIcon = await getApiGatewayForImage(getUrl);
  return learningProjectFilesIcon;
};

/**
 * 学習プロジェクトにファイルを紐づける
 * @param change ファイル更新情報
 * @returns 更新後のファイル情報
 */
const addImageFileToLearningProject = async (
  change: FileChangeUpdate,
): Promise<ResponseCogniteItems<FileInfo[]>> => {
  const updatedFiles = (
    await putApiGateway<RequestFileChangeUpdate, ResponseCogniteOrError<FileInfo[]>>(
      EP_PATH_LEARNING_PJ_FILES,
      { items: [change] },
    )
  );

  if ((updatedFiles as ResponseCogniteError).error) {
    throw updatedFiles;
  }

  return updatedFiles as ResponseCogniteItems<FileInfo[]>;
};

export {
  retrieveLearningProjectFile,
  getLearningProjectFilesIcon,
  addImageFileToLearningProject,
};
