import React, { forwardRef, useImperativeHandle } from 'react';
import { Asset, Metadata } from '@cognite/sdk';
import {
  Modal, Form, Input, message, Select,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import BaseAsset from '../../../utils/Asset/BaseAsset';
import ManagedFacility from '../../../utils/Asset/ManagedFacility';
import RootAsset from '../../../utils/Asset/RootAsset';
import MetadataTable from '../Metadata/MetadataTable';
import { assetReservedKeyList } from '../Metadata/SystemReservedKey';
import { getAssetRetrieveEndpoint, ResourceType } from '../../../utils/common/SDFDataType';
import { getApiGateway } from '../../../utils/AWS/ApiGateway';

import {
  SUCCESS_SAVE_FACILITY,
  ERROR_SAVE_FACILITY,
  VALIDATE_ERROR_FACILITY_NAME_REQUIRE,
  VALIDATE_ERROR_ROOT_ASSET_SELECT_REQUIRE,
  ERROR_LOAD_PARENT_FACILITY,
  ERROR_LOAD_ROOT_ASSET,
} from '../../../utils/messages';

const { Option } = Select;

interface OptionItem {
  label: string,
  value: number,
}

const FormItem = {
  Name: { name: 'name', label: '名前' },
  Parent: { name: 'parentId', label: '親設備' },
  Description: { name: 'description', label: '説明' },
  RootAsset: { name: 'rootAsset', label: 'ルートアセット' },
  Metadata: { name: 'metadata', label: '詳細情報' },
} as const;

interface AssetEditFormModalProps extends FormComponentProps {
  visible: boolean,
  target?: BaseAsset,
  parentResourceType: ResourceType;
  onAdd: (asset: BaseAsset) => void,
  onUpdate: (asset: BaseAsset) => void,
  onCancel: () => void
}

type Ref = FormComponentProps;

/**
 * 設備情報登録・更新ダイアログコンポーネント
 */
const AssetEditFormModal = forwardRef<Ref, AssetEditFormModalProps>(
  (props: AssetEditFormModalProps, ref) => {
    /*
     * 変数/定数定義
     */
    const [saving, setSaving] = React.useState<boolean>(false);
    const [parent, setParent] = React.useState<Asset | undefined>(undefined);
    const [rootAssetLoading, setRootAssetLoading] = React.useState<boolean>(true);
    const [options, setOptions] = React.useState<OptionItem[]>([]);
    const [selectRootAsset, setSelectRootAsset] = React.useState<RootAsset>();

    type MetadataTableHandle = React.ElementRef<typeof MetadataTable>;
    const metadataTableRef = React.useRef<MetadataTableHandle>(null);

    const {
      visible, target, parentResourceType, onAdd, onUpdate, onCancel, form,
    } = props;

    const {
      validateFields, getFieldValue, getFieldDecorator, resetFields, setFieldsValue,
    } = form;

    useImperativeHandle(ref, () => ({ form }));

    /*
     * イベントハンドラ
     */
    /** データ読み込み処理 */
    React.useEffect(
      () => {
        resetFields();

        if (!target) return () => { /* 何もしない */ };

        // 呼び出し元から対象設備を渡された場合
        setFieldsValue({
          name: target.name,
          description: target.description,
        });

        const isManagedFacility = target?.type === ManagedFacility.assetType;
        let canceled = false;
        if (!isManagedFacility) {
          // 管理設備以外の場合
          if (!target.parentId) {
            // 親設備が存在しない場合、stateをリセット
            setParent(undefined);
            return () => { /* 何もしない */ };
          }

          // 親設備が存在する場合、親設備情報を読み込む
          const { parentId } = target;

          (async () => {
            try {
              const getUrl = getAssetRetrieveEndpoint(parentResourceType, parentId);
              const assets = await getApiGateway<Asset>(getUrl, { ignoreUnknownIds: true });
              if (!assets) throw new Error();
              if (!canceled) {
                setParent(assets);
              }
            } catch (exception) {
              message.error(ERROR_LOAD_PARENT_FACILITY);
            }
          })();
        }

        if (isManagedFacility) {
          if (target.id > 0) {
            // 更新時、stateをリセット
            setOptions([]);
            return () => { /* 何もしない */ };
          }

          // 管理設備の場合、ルートアセット情報を読み込み
          (async () => {
            let loadOptions: OptionItem[] = [];
            try {
              const rootAssets = await RootAsset.loadAllFromCDF();
              if (rootAssets.length === 1) {
                // ルートアセットが1件の場合、ドロップダウンは非表示とする
                setSelectRootAsset(rootAssets[0]);
                setRootAssetLoading(false);
                return;
              }

              loadOptions = rootAssets.map((rootAsset) => ({
                label: rootAsset.name,
                value: rootAsset.id,
              }));
            } catch (exception) {
              message.error(ERROR_LOAD_ROOT_ASSET);
            }
            if (canceled) return;

            setOptions(loadOptions);
            setRootAssetLoading(false);
          })();
        }

        return () => { canceled = true; };
      },
      [target, parentResourceType, resetFields, setFieldsValue],
    );

    /** 登録・更新処理 */
    React.useEffect(
      () => {
        if (!saving) return () => { /* 何もしない */ };

        if (!target) return () => { /* 何もしない */ };

        const inputName = getFieldValue(FormItem.Name.name);
        const inputDescription = getFieldValue(FormItem.Description.name);
        const inputMetadata = metadataTableRef.current?.getValue();
        let canceled = false;
        (async () => {
          const isNew = target.id <= 0;
          const isManagedFacility = target?.type === ManagedFacility.assetType;

          let savedAsset: BaseAsset | null = null;

          try {
            await validateFields();
            if (!inputMetadata) {
              setSaving(false);
              return;
            }

            if (
              target.name === inputName
              && target.description === inputDescription
              && target.metadata
            ) {
              /**
               * 名前、説明に変更がなかった時、
               * 詳細情報に変更があるか判定し、ない場合はupdate処理を行わない。
              */

              // オブジェクトをソート済み配列に変換する
              const objToSortedArray = (obj: Metadata) => Object.entries(obj).sort();
              // ソート済み配列を文字列に変換して比較する
              const isEqualSortArray = (obj1: Metadata, obj2: Metadata) => (
                JSON.stringify(objToSortedArray(obj1)) === JSON.stringify(objToSortedArray(obj2))
              );

              if (isEqualSortArray(target.metadata, inputMetadata)) {
                setSaving(false);
                onCancel();
                return;
              }
            }

            target.name = getFieldValue(FormItem.Name.name);
            target.description = getFieldValue(FormItem.Description.name);
            target.metadata = inputMetadata;
            target.parentResourceType = parentResourceType;

            if (isManagedFacility) {
              // 管理設備かつ新規の場合、画面上で選択しているルートアセットから取得
              if (isNew) {
                target.parentId = selectRootAsset?.id;
                target.dataSetId = selectRootAsset?.dataSetId;
              }
            } else {
              // 設備の場合
              target.dataSetId = parent?.dataSetId;
            }

            await target.saveSelfToCDF();

            savedAsset = target;

            const savedTarget = isManagedFacility ? '管理設備' : '設備';
            const process = isNew ? '追加' : '更新';

            message.success(SUCCESS_SAVE_FACILITY.replace('[target]', savedTarget).replace('[process]', process));
          } catch (exception) {
            const savedTarget = isManagedFacility ? '管理設備' : '設備';
            const process = isNew ? '追加' : '更新';

            message.error(ERROR_SAVE_FACILITY.replace('[target]', savedTarget).replace('[process]', process));
          }

          if (!canceled) {
            setSaving(false);

            if (savedAsset) {
              if (isNew) {
                onAdd(savedAsset);
              } else {
                onUpdate(savedAsset);
              }
            }
          }
        })();

        return () => { canceled = true; };
      },
      [
        target, saving, parent, selectRootAsset, parentResourceType,
        onAdd, onUpdate, validateFields, getFieldValue, onCancel,
      ],
    );

    /**
     * ルートアセット情報を読み込む
     * @param rootAssetId ルートアセットID
     */
    const onSelectRootAsset = async (rootAssetId: number) => {
      let rootAsset;
      try {
        rootAsset = await RootAsset.retrieveFromCDF(rootAssetId);
        if (rootAsset) {
          setSelectRootAsset(rootAsset);
        }
      } catch (exception) {
        message.error(ERROR_LOAD_ROOT_ASSET);
      }
    };
    /*
     * メソッド
     */

    /*
     * 画面描画
     */
    const isManagedFacility = target?.type === ManagedFacility.assetType;
    const modalTitle = isManagedFacility ? '管理設備' : '設備';
    let titleKind = `${modalTitle}の追加`;
    if (target && target?.id > 0) {
      titleKind = `${modalTitle}の更新`;
    }
    const showRootAsset = isManagedFacility && options.length > 0;
    let modalBodyHeight = 430;
    if (!isManagedFacility || showRootAsset) {
      // 管理設備以外の場合、親設備表示領域を確保
      // 管理設備かつルートアセットが複数県の場合、ルートアセット表示領域を確保
      modalBodyHeight += 50;
    }

    return (
      <Modal
        visible={visible}
        centered
        title={titleKind}
        okText="保存"
        cancelText="キャンセル"
        width={800}
        bodyStyle={{ height: modalBodyHeight }}
        onOk={(event) => {
          event.currentTarget.blur();
          setSaving(true);
        }}
        onCancel={onCancel}
        okButtonProps={{ loading: saving }}
        cancelButtonProps={{ disabled: saving }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Form.Item
            label={FormItem.Name.label}
          >
            {getFieldDecorator(FormItem.Name.name, {
              rules: [
                { required: true, message: VALIDATE_ERROR_FACILITY_NAME_REQUIRE },
              ],
            })(
              <Input />,
            )}
          </Form.Item>
          <Form.Item
            label={FormItem.Description.label}
          >
            {getFieldDecorator(FormItem.Description.name)(<Input />)}
          </Form.Item>
          {
            // 管理設備以外の場合、親設備を表示
            !isManagedFacility && (
              <Form.Item
                label={FormItem.Parent.label}
              >
                {parent?.name}
              </Form.Item>
            )
          }
          {
            // 管理設備かつルートアセットが複数件の場合、ルートアセットを表示
            isManagedFacility && options.length > 0 && (
              <Form.Item
                label={FormItem.RootAsset.label}
                required
              >
                {getFieldDecorator(FormItem.RootAsset.name, {
                  rules: [
                    { required: true, message: VALIDATE_ERROR_ROOT_ASSET_SELECT_REQUIRE },
                  ],
                })(
                  <Select
                    loading={rootAssetLoading}
                    showSearch
                    onSelect={(value: number) => onSelectRootAsset(value)}
                  >
                    {options.map((optionItem) => (
                      <Option key={optionItem.value} value={optionItem.value}>
                        {optionItem.label}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            )
          }
          <Form.Item label={FormItem.Metadata.label}>
            <MetadataTable
              ref={metadataTableRef}
              value={target?.metadata}
              editable
              height={150}
              systemReservedKeyList={assetReservedKeyList}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);

const EnhancedAssetEditFormModal = Form.create<AssetEditFormModalProps>()(AssetEditFormModal);

export default EnhancedAssetEditFormModal;
