/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import React from 'react';
import { CogniteClient } from '@cognite/sdk';
import { Select, message } from 'antd';

import AssetTable from '../Common/Asset/AssetTable';
import { AssetColumnChart } from './Column/AssetColumnChart';
import { TimeseriesColumnChart } from './Column/TimeseriesColumnChart';
import { FileColumnChart } from './Column/FileColumnChart';
import ManagedFacility from '../../utils/Asset/ManagedFacility';

import { ERROR_LOAD_MANAGED_FACILITIES } from '../../utils/messages';

import './DataCollection.css';

const { Option } = Select;

/** 集計対象コンボボックスに表示するセレクトボックス項目 */
const OptionList = {
  Asset: {
    label: 'アセット',
    value: 'asset',
  },
  Timeseries: {
    label: 'タイムシリーズ',
    value: 'timeseries',
  },
  File: {
    label: 'ファイル',
    value: 'file',
  },
} as const;

/**
 * ダッシュボード画面管理設備一覧コンポーネント
 */
const DataCollection: React.FC<{
  client: CogniteClient;
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedTarget, setSelectedTarget] = React.useState<string>(OptionList.Asset.value);
  const [managedFacilities, setManagedFacilities] = React.useState<ManagedFacility[]>([]);
  const [checkedManagedFacilities, setCheckedManagedFacilities] = (
    React.useState<ManagedFacility[]>([])
  );

  const { client } = props;

  /*
   * イベントハンドラ
   */
  React.useEffect(() => {
    setManagedFacilities([]);

    setLoading(true);

    let canceled = false;
    (async () => {
      try {
        const loadManagedFacilities = await ManagedFacility.loadAllFromCDF();
        const sortedManagedFacilities = loadManagedFacilities.sort(compare);
        if (!canceled) {
          setManagedFacilities(sortedManagedFacilities);
        }
      } catch (exception) {
        message.error(ERROR_LOAD_MANAGED_FACILITIES);
      } finally {
        if (!canceled) {
          setLoading(false);
        }
      }
    })();

    return () => {
      canceled = true;
    };
  }, [client]);

  /*
   * メソッド
   */

  /**
   * ManagedFacility.idでソートを行う。
   * @param a 管理設備A
   * @param b 管理設備B
   * @returns 管理設備A.id < 管理設備B.idの場合: -1, 管理設備A.id > 管理設備B.idの場合: 1, それ以外の場合: 0を返却
   */
  function compare(a: ManagedFacility, b: ManagedFacility): number {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;

    return 0;
  }

  // 集計対象の選択値によって表示するグラフを変更
  let chart = <></>;
  switch (selectedTarget) {
    case OptionList.Asset.value:
      // アセット
      chart = (
        <AssetColumnChart
          managedFacilities={checkedManagedFacilities}
        />
      );
      break;
    case OptionList.Timeseries.value:
      // タイムシリーズ
      chart = (
        <TimeseriesColumnChart
          managedFacilities={checkedManagedFacilities}
        />
      );
      break;
    case OptionList.File.value:
      // ファイル
      chart = (
        <FileColumnChart managedFacilities={checkedManagedFacilities} />
      );
      break;
    default:
      break;
  }

  // Selectの中身のOptionリスト
  const SelectOptionList: JSX.Element[] = [];
  const OptionVal = Object.values(OptionList);
  OptionVal.forEach((data) => (
    SelectOptionList.push(
      <Option
        key={`aggregate-${data.value}`}
        value={data.value}
        disabled={loading}
      >
        {data.label}
      </Option>,
    )
  ));

  /*
   * 画面描画
   */
  return (
    <div className="data-collection">
      <div className="asset-table">
        <AssetTable
          loading={loading}
          assets={managedFacilities}
          checkedIds={checkedManagedFacilities.map((managedFacility) => managedFacility.id)}
          height={656}
          onCheckAll={(selected) => {
            if (selected) {
              setCheckedManagedFacilities(managedFacilities);
            } else {
              setCheckedManagedFacilities([]);
            }
          }}
          onCheck={(managedFacility, selected, selectedManagedFacilities) => {
            setCheckedManagedFacilities(
              selectedManagedFacilities.sort(compare),
            );
          }}
        />
      </div>
      <div className="chart-container">
        <div className="target-selector">
          集計対象：
          <Select
            defaultValue={OptionList.Asset.value}
            onChange={(value: string) => setSelectedTarget(value as string)}
            size="small"
            style={{ width: 200 }}
          >
            {SelectOptionList}
          </Select>
        </div>
        <div>{chart}</div>
      </div>
    </div>
  );
};

export default DataCollection;
