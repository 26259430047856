/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import React, {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import {
  Metadata,
  FileFilterProps,
} from '@cognite/sdk';
import {
  Divider,
  Typography,
  Row,
  Col,
  Descriptions,
  Upload,
  Button,
  message,
  Tooltip,
  Modal,
} from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import FiscalYearSelect from './parts/FiscalYearSelect';
import { searchDuplicateUploadFile, deleteDuplicateUploadFile } from '../../utils/File/SolarFinancialReportFile';
import MonthSelect from './parts/MonthSelect';
import SiteCascader, { SiteCascaderSelect } from './parts/SiteCascader';
import { loadFinanceReportKeepAsset } from '../../utils/Asset/SolarSiteAsset';
import singleFileUpload from '../Common/File/singleFileUpload';
import { MimeType } from '../../utils/File/BaseFile';
import { EP_PATH_SOLAR_PROCESSES_FINANCIAL_REPORT } from '../../utils/AWS/EndpointPath';
import {
  SUCCESS_FILES_UPLOAD,
  ERROR_FILES_UPLOAD,
  ERROR_NO_AUTH_MESSAGE,
  FILE_TYPE_CHECK_ERROR,
  ERROR_FINANCIAL_REPORT_VALIDATION_REQUIRE,
  FILE_UPDATE_CHECK,
} from '../../utils/messages';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import './FinancialReportRegistration.css';

interface FinancialReportRegistrationProps {
  setLoadingFlag: (flag: boolean) => void;
}

type FinancialReportRegistrationValidationResult =
  { hasError: true; errorMessage: string; }
  | {
    hasError: false;
    inputFinancialReportFile: UploadFile;
    inputBusinessClassificationName: string;
    inputFirstCategoryName: string;
    inputSecondCategoryName: string;
    inputSiteName: string;
    inputFiscalYear: string;
  }
  ;

/** ダイアログのボタンラベル */
const MODAL_BUTTON_TEXT = {
  OK: '登録',
  CANCEL: 'キャンセル',
};

const { Title } = Typography;
/**
 * 財務帳票登録画面
 */
const FinancialReportRegistration: React.FC<FinancialReportRegistrationProps> = (props) => {
  const [financialReportFile, setFinancialReportFile] = useState<UploadFile | undefined>();
  const [siteInfo, setSiteInfo] = useState<[string, string, string, string, number | undefined] | undefined>();
  const [fiscalYear, setFiscalYear] = useState<string | undefined>();
  const [month, setMonth] = useState<string | undefined>();
  const [isFileUploadDisabled, setIsFileUploadDisabled] = useState<boolean>(true);
  const [deleteProjectDialogVisible, setDeleteProjectDialogVisible] = useState<boolean>(false);
  const deleteFileId = useRef<number>();

  const uploadProps: UploadProps = {
    onRemove: () => {
      // 単一ファイルのみ選択可能なため固定でundefinedを設定
      setFinancialReportFile(undefined);
    },
    beforeUpload: (file) => {
      setFinancialReportFile(file);
      return false;
    },
    fileList: financialReportFile ? [financialReportFile] : undefined,
  };

  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        SOLAR_MENU: {
          FINANCIAL_REPORT_REGISTER: {
            FINANCIAL_REPORT_FILE_UPLOAD,
          },
        },
      } = AUTHENTICATION_TYPE_MATRIX;

      if (!canceled) {
        setIsFileUploadDisabled(!await containsUIAuthType(FINANCIAL_REPORT_FILE_UPLOAD));
      }
    })();

    return () => { canceled = true; };
  }, []);

  /**
   * サイト選択Cascaderチェンジイベントハンドラ
   * @param {SiteCascaderSelect} selectedValues 選択したサイト情報
   */
  const handleChangeSite = (selectedValues?: SiteCascaderSelect) => {
    if (!selectedValues) {
      setSiteInfo(undefined);
      return;
    }

    const {
      businessClassification, firstCategory, secondCategory, site,
    } = selectedValues;
    setSiteInfo([businessClassification.name, firstCategory.name, secondCategory.name, site.name, site.dataSetId]);
  };

  /**
   * 登録ボタンクリックイベントハンドラ
   */
  const handleClickRegistration = async () => {
    const { setLoadingFlag } = props;
    setLoadingFlag(true);

    const validationResult = validateInputData();
    if (validationResult.hasError) {
      message.error(validationResult.errorMessage);
      setLoadingFlag(false);
      return;
    }

    const {
      inputFinancialReportFile,
      inputBusinessClassificationName,
      inputFirstCategoryName,
      inputSecondCategoryName,
      inputSiteName,
      inputFiscalYear,
    } = validationResult;
    // ファイルの重複チェックを実施
    try {
      const search = await checkDuplicateUploadFinanceReportFile(
        inputFinancialReportFile,
        inputBusinessClassificationName,
        inputFirstCategoryName,
        inputSecondCategoryName,
        inputSiteName,
        inputFiscalYear,
      );

      // ファイルの重複があれば削除を実施した上で登録、重複がなければ登録のみを実施する
      if (search.id) {
        deleteFileId.current = search.id;
        setDeleteProjectDialogVisible(true);
      } else {
        await uploadFinanceReportFile(
          inputFinancialReportFile,
          inputBusinessClassificationName,
          inputFirstCategoryName,
          inputSecondCategoryName,
          inputSiteName,
          inputFiscalYear,
        );
        message.info(SUCCESS_FILES_UPLOAD);
      }
    } catch (e) {
      message.error(ERROR_FILES_UPLOAD);
    } finally {
      setLoadingFlag(false);
    }
  };

  /**
   * 画面項目の入力チェック
   * - 必須チェック
   * - アップロードファイル種別チェック
   * @returns 判定結果
   */
  const validateInputData = (): FinancialReportRegistrationValidationResult => {
    // 必須チェック
    if (!financialReportFile || !siteInfo || !fiscalYear) {
      return { hasError: true, errorMessage: ERROR_FINANCIAL_REPORT_VALIDATION_REQUIRE };
    }

    // ファイル種別チェック
    if (financialReportFile.type !== MimeType.Pdf) {
      return { hasError: true, errorMessage: FILE_TYPE_CHECK_ERROR };
    }

    const [businessClassificationName, firstCategoryName, secondCategoryName, siteName] = siteInfo;
    return {
      hasError: false,
      inputFinancialReportFile: financialReportFile,
      inputBusinessClassificationName: businessClassificationName,
      inputFirstCategoryName: firstCategoryName,
      inputSecondCategoryName: secondCategoryName,
      inputSiteName: siteName,
      inputFiscalYear: fiscalYear,
    };
  };

  /**
   * 財務帳票ファイルアップロード
   * @param {UploadFile} inputFinancialReportFile アップロードファイル
   * @param {string} inputBusinessClassificationName サイト選択：事業
   * @param {string} inputFirstCategoryName サイト選択：第一カテゴリー
   * @param {string} inputSecondCategoryName サイト選択：第二カテゴリー
   * @param {string} inputSiteName サイト選択：サイト
   * @param {string} inputFiscalYear 年度
   */
  const uploadFinanceReportFile = async (
    inputFinancialReportFile: UploadFile,
    inputBusinessClassificationName: string,
    inputFirstCategoryName: string,
    inputSecondCategoryName: string,
    inputSiteName: string,
    inputFiscalYear: string,
  ): Promise<void> => {
    const metadata: Metadata = {
      businessClassification: inputBusinessClassificationName,
      category1: inputFirstCategoryName,
      businessName: inputSecondCategoryName,
      site: inputSiteName,
      fiscalYear: inputFiscalYear,
    };
    if (month) {
      metadata.month = month;
    }

    const solarFinanceReportKeepAsset = await loadFinanceReportKeepAsset();
    const { id } = solarFinanceReportKeepAsset;
    // 登録ボタン押下時validateInputDataでチェックがされているためnullになることはない
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [, , , , dataSetId] = siteInfo!;
    const data = {
      assetId: id,
      dataSetId,
      metadata,
      endpoint: EP_PATH_SOLAR_PROCESSES_FINANCIAL_REPORT,
      uploadFile: inputFinancialReportFile,
    };

    const uploadResponse = await singleFileUpload({ data });
    if (uploadResponse.result === 'error') {
      throw new Error();
    }
  };

  /**
   * 財務帳票ファイル重複チェック
   * @param {UploadFile} inputFinancialReportFile アップロードファイル
   * @param {string} inputBusinessClassificationName サイト選択：事業
   * @param {string} inputFirstCategoryName サイト選択：第一カテゴリー
   * @param {string} inputSecondCategoryName サイト選択：第二カテゴリー
   * @param {string} inputSiteName サイト選択：サイト
   * @param {string} inputFiscalYear 年度
   * @returns {Promise<id: number;>} 重複ファイルID
   */
  const checkDuplicateUploadFinanceReportFile = async (
    inputFinancialReportFile: UploadFile,
    inputBusinessClassificationName: string,
    inputFirstCategoryName: string,
    inputSecondCategoryName: string,
    inputSiteName: string,
    inputFiscalYear: string,
  ): Promise<{ id?: number; }> => {
    const inputMetadata: Metadata = {
      businessClassification: inputBusinessClassificationName,
      category1: inputFirstCategoryName,
      businessName: inputSecondCategoryName,
      site: inputSiteName,
      fiscalYear: inputFiscalYear,
    };
    if (month) {
      inputMetadata.month = month;
    }
    const filterProp: FileFilterProps = {
      metadata: inputMetadata,
      name: inputFinancialReportFile.name,
      mimeType: inputFinancialReportFile.type,
    };

    // ファイル検索を実施
    const searchResult = await searchDuplicateUploadFile(
      filterProp,
    );

    // 検索結果が存在する場合は、metadataの月の値と画面入力値の月の値を確認し合致するものが無いかを確認する
    if (searchResult.items.length > 0) {
      // 検索条件に合致したという事なので、metadataが無い事はあり得ないという想定
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const matchFileData = searchResult.items.find((item) => item.metadata!.month === inputMetadata.month);
      if (matchFileData !== undefined) {
        return { id: matchFileData.id };
      }
    }

    return { id: undefined };
  };

  /**
   * 重複ファイル上書き確認ダイアログ 登録ボタン押下イベント
   */
  const handleClickDialogSubmit = async () => {
    const { setLoadingFlag } = props;
    setLoadingFlag(true);
    // モーダルが呼び出された時点でIDが設定された状態である事が確認出来ている為
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await deleteDuplicateUploadFile(deleteFileId.current!);
    // 入力チェックが通った後の処理なので、undefinedではない事を確認済み
    const [businessClassificationName, firstCategoryName, secondCategoryName, siteName] = siteInfo!;// eslint-disable-line @typescript-eslint/no-non-null-assertion
    try {
      await uploadFinanceReportFile(
        financialReportFile!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        businessClassificationName!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        firstCategoryName!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        secondCategoryName!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        siteName!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        fiscalYear!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      );
      message.info(SUCCESS_FILES_UPLOAD);
    } catch (e) {
      message.error(ERROR_FILES_UPLOAD);
    } finally {
      setDeleteProjectDialogVisible(false);
      setLoadingFlag(false);
    }
  };

  /**
   * 重複ファイル上書き確認ダイアログ キャンセルボタン押下イベント
   */
  const handleClickDialogCancel = async () => {
    setDeleteProjectDialogVisible(false);
  };

  /**
   * ラベルに注釈を付与したReactNodeを返す
   * @param {string} label ラベル
   * @param {boolean} isAnnotationFront 注釈をラベルの前に表示するフラグ
   * @returns ラベル+注釈のDOM
   */
  const labelWithAnnotation = (label: string, isAnnotationFront?: boolean): ReactNode => (
    isAnnotationFront
      ? (
        <>
          <span className="financial-report-registration-annotation">※</span>
          {label}
        </>
      )
      : (
        <>
          {label}
          <span className="financial-report-registration-annotation">※</span>
        </>
      )
  );

  return (
    <div className="financial-report-registration">
      <div className="financial-report-registration-title">
        <div className="financial-report-registration-margin">
          <Title level={4}>財務帳票登録</Title>
        </div>
        <Divider className="financial-report-registration-divider" />
      </div>
      <Row>
        <Col span={20} offset={1}>
          <Descriptions bordered>
            <Descriptions.Item label={labelWithAnnotation('ファイル選択（選択可能ファイル形式：PDF）')}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Upload {...uploadProps}>
                <Button disabled={financialReportFile !== undefined}>参照</Button>
              </Upload>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <div className="financial-report-registration-title">
        <div className="financial-report-registration-margin">
          <Title level={4}>タグ</Title>
        </div>
        <Divider className="financial-report-registration-divider" />
      </div>
      <Row>
        <Col span={20} offset={1}>
          <Descriptions bordered>
            <Descriptions.Item label={labelWithAnnotation('サイト選択')}>
              <SiteCascader
                onChange={handleChangeSite}
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={20} offset={1}>
          <Descriptions bordered>
            <Descriptions.Item label={labelWithAnnotation('年度')}>
              <FiscalYearSelect
                value={fiscalYear}
                onChange={(value) => setFiscalYear(value)}
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={20} offset={1}>
          <Descriptions bordered>
            <Descriptions.Item label="月">
              <MonthSelect
                value={month}
                onChange={(value) => setMonth(value)}
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={16} offset={1}>
          <div className="financial-report-registration-annotation-description">
            {labelWithAnnotation('必須項目', true)}
          </div>
        </Col>
        <Col span={4} offset={17}>
          <div className="register-button">
            <Tooltip title={isFileUploadDisabled && ERROR_NO_AUTH_MESSAGE}>
              <Button
                type="primary"
                onClick={handleClickRegistration}
                disabled={isFileUploadDisabled}
                style={{ marginTop: 16 }}
              >
                {MODAL_BUTTON_TEXT.OK}
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Modal
        title="警告"
        visible={deleteProjectDialogVisible}
        centered
        onOk={handleClickDialogSubmit}
        okText={MODAL_BUTTON_TEXT.OK}
        okType="danger"
        onCancel={handleClickDialogCancel}
        cancelText={MODAL_BUTTON_TEXT.CANCEL}
      >
        {
          FILE_UPDATE_CHECK.map((errormessage) => <p key={errormessage}>{errormessage}</p>)
        }
      </Modal>
    </div>
  );
};

export default FinancialReportRegistration;
