/* eslint-disable no-unused-expressions */
// eslint警告未対応
import React from 'react';
import {
  Layout, Col, Row, message,
} from 'antd';
import { ImagesListViewer, LIST_TYPE } from '../../util/ImagesListViewer';
import { FileDetailView } from '../../Common/File/FileDetailView';
import { LearningModelList } from './LearningModelList';
import ProjectList from '../Common/ProjectList';
import ProjectTitle from '../Common/ProjectTitle';
import SettingLearningParameterDialog from './SettingLearningParameterDialog';
import { ResourceType } from '../../../utils/common/SDFDataType';
import { VALIDATE_ERROR_NO_SELECT_IMAGE, SUCCESS_ADD_LEARNING_MODEL_TASK } from '../../../utils/messages';
import { LEARNING } from '../../Common/File/FileTable';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../../utils/common/Authentication';
import { isReadOnlyPermission } from '../../../utils/common/CacheApi';

const { Content } = Layout;

/**
 * 学習画面component
 * @property {object} client CogniteClient
 * @property {function} setLoadingFlag Load Flag設定関数
 */
class Learning extends React.Component {
  constructor(props) {
    super(props);
    /** 学習プロジェクト一覧へのref */
    this.projectListRef = React.createRef();
  }

  state = {
    selectedAssetID: null,
    selectedAssetName: null,
    selectedResourceType: null,
    selectedFileId: null,
    /** 学習パラメータ設定ダイアログ表示状態 true: 表示 / false: 非表示 */
    settingLearningParameterVisible: false,
    /** 選択した画像ファイルIDリスト */
    checkFileIds: [],
    isModalEditButtonDisabled: true,
    isModalDownloadButtonDisabled: true,
    isLearningPjEditDisabled: true,
    isReadOnly: true,
  };

  /**
   * component render前の処理
   */
  async componentDidMount() {
    const { EQUIPMENT_DETAILS_EQ, MACHINE_LEARNING } = AUTHENTICATION_TYPE_MATRIX;
    const { EXPANDED_MODAL_EDIT_BUTTON } = EQUIPMENT_DETAILS_EQ;
    const { EXPANDED_MODAL_DOWNLOAD_BUTTON, LEARNING_PROJECTS_NAME_EDIT_BUTTON } = MACHINE_LEARNING;

    this.setState({
      isModalEditButtonDisabled: !await containsUIAuthType(EXPANDED_MODAL_EDIT_BUTTON),
      isModalDownloadButtonDisabled: !await containsUIAuthType(EXPANDED_MODAL_DOWNLOAD_BUTTON),
      isLearningPjEditDisabled: !await containsUIAuthType(LEARNING_PROJECTS_NAME_EDIT_BUTTON),
      isReadOnly: await isReadOnlyPermission(),
    });
  }

  /**
   * ファイル一覧チェックボックスの活性状態判定処理
   * 読み取り専用権限では常にチェックボックス非活性
   * 未アノテーション画像行の場合、チェックボックスを非活性にする。
   * @param {object} record
   * @returns 判定結果(true: 非活性 false: 活性)
   */
  needsDisabledCheckbox = (record) => this.state.isReadOnly === true || record.annotated === 'false';

  /**
   * 学習プロジェクト名を更新した際の後処理
   * 学習プロジェクト一覧を再読み込みする。
   */
  handleUpdatedProjectTitle = () => {
    // プロジェクトリスト再読み込み
    this.projectListRef.current && this.projectListRef.current.createProjectComponents();
  };

  /**
   * Asset選択時のイベントハンドラ
   * @param {object} asset 選択されたAsset
   */
  onSelectAsset = async (asset) => {
    this.setState({
      selectedAssetID: asset.key,
      selectedAssetName: asset.title,
      selectedResourceType: ResourceType.LearningProject,
      selectedFileId: null,
    });
  };

  /**
   * 学習プロジェクト削除時のイベントハンドラ
   */
  onDeleted = () => {
    this.setState({ selectedAssetID: null });
  };

  /**
   * パラメータ設定ボタンクリック時のイベントハンドラ
   * @param {array} checkFileIds 画面で選択した画像ファイルIDのリスト
   */
  handleClickSettingLearningParameter = (checkFileIds) => {
    if (checkFileIds.length === 0) {
      // 画像未選択の場合、エラーメッセージを表示して学習パラメータ設定ダイアログは表示しない
      message.error(VALIDATE_ERROR_NO_SELECT_IMAGE);
      return;
    }

    this.setState({ settingLearningParameterVisible: true, checkFileIds });
  };

  /**
   * 学習パラメータ設定モーダルダイアログキャンセルボタンイベントハンドラ
   */
  handleModalCancel = () => {
    this.setState({ settingLearningParameterVisible: false });
  };

  /**
   * 学習モデル作成タスク登録完了イベントハンドラ
   */
  handleSuccessCreateLearningModel = () => {
    message.success(SUCCESS_ADD_LEARNING_MODEL_TASK);
    this.setState({ settingLearningParameterVisible: false });
  };

  /**
   * Imageクリック時のイベント
   * @param {number} fileId 選択されたImageのID
   */
  onClickImage = async (fileId) => {
    this.setState({
      selectedFileId: fileId,
    });
  };

  render() {
    const {
      checkFileIds,
      selectedAssetID,
      selectedAssetName,
      selectedFileId,
      selectedResourceType,
      settingLearningParameterVisible,
      isModalEditButtonDisabled,
      isModalDownloadButtonDisabled,
      isLearningPjEditDisabled,
    } = this.state;

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <ProjectList
          title="学習プロジェクト"
          onSelect={this.onSelectAsset}
          onDeleted={this.onDeleted}
          ref={this.projectListRef}
        />

        <Content>

          {
            selectedAssetID && (
              <>
                <ProjectTitle
                  client={this.props.client.client}
                  assetId={selectedAssetID}
                  updatedFunction={this.handleUpdatedProjectTitle}
                  isLearningPjEditDisabled={isLearningPjEditDisabled}
                />

                <Row>
                  <Col span={17}>
                    <ImagesListViewer
                      client={this.props.client}
                      listType={LIST_TYPE.JPEG_ONLY}
                      assetId={selectedAssetID}
                      assetName={selectedAssetName}
                      resourceType={selectedResourceType}
                      showAddButton={false}
                      showImageDisplaySelection={false}
                      showSettingLearningParameterButton
                      onClickSettingLearningParameter={this.handleClickSettingLearningParameter}
                      inActiveCheckbox={this.needsDisabledCheckbox}
                      onClickImage={this.onClickImage}
                      customStyle={{ cardStyle: { height: '500px', marginLeft: '10px' }, fileTableHeight: '350px', fileListHeight: '290px' }}
                      showAddPjButton={false}
                      showAnnotation={false}
                      onClickAnnotation={null}
                      showAnnotationColumns
                      fileNameStatus={LEARNING}
                      isModalEditButtonDisabled={isModalEditButtonDisabled}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                    />
                  </Col>
                  <Col span={7}>
                    <FileDetailView
                      resourceType={selectedResourceType}
                      fileId={selectedFileId}
                      isModalEditButtonDisabled={isModalEditButtonDisabled}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                      customStyle={{ cardStyle: { height: '500px', marginRight: '10px' }, detailsListHeight: '260px' }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <LearningModelList
                      client={this.props.client}
                      assetId={selectedAssetID}
                      customStyle={{
                        cardStyle: {
                          height: '470px', marginLeft: '10px', marginRight: '10px', marginTop: '10px',
                        },
                      }}
                    />
                  </Col>
                </Row>

                {
                  settingLearningParameterVisible && (
                    <SettingLearningParameterDialog
                      visible={settingLearningParameterVisible}
                      fileIds={checkFileIds}
                      learningProjectAssetId={selectedAssetID}
                      learningProjectAssetName={selectedAssetName}
                      modalCancel={this.handleModalCancel}
                      onSuccessCreateLearningModel={this.handleSuccessCreateLearningModel}
                    />
                  )
                }
              </>
            )
          }
        </Content>
      </Layout>
    );
  }
}

export default Learning;
