import { CogniteEvent, EventFilter, Timestamp } from '@cognite/sdk';
import moment from 'moment';

import BaseEvent from './BaseEvent';
import { EP_PATH_SOLAR_EVENTS_LIST } from '../AWS/EndpointPath';
import { getAllEvents } from '../dataAccess';

// CSV出力時の日付の表示形式
const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

/**
 * 死活監視結果
 */
class ActivityCheckHistory extends BaseEvent {
  /**
   * メンバ変数
   */
  get gmuId(): string {
    if (!this.metadata || !this.metadata.gmu_id) return '';
    return this.metadata.gmu_id;
  }

  get message(): string {
    if (!this.metadata || !this.metadata.message) return '';
    return this.metadata.message;
  }

  get siteName(): string {
    if (!this.metadata || !this.metadata.site_name_jp) return '';
    return this.metadata.site_name_jp;
  }

  get companyName(): string {
    if (!this.metadata || !this.metadata.company_name_jp) return '';
    return this.metadata.company_name_jp;
  }

  get dateForDisplay(): string {
    if (!this.startTime) return '';
    return moment(this.startTime).format(DATE_FORMAT);
  }

  /**
   * クラスメソッド
   */

  /**
   * 死活監視結果を一覧で取得する
   * @param {[Timestamp, Timestamp] | undefined} occurrenceDates 発生日時
   * @returns {Promise<ActivityCheckHistory[]>} 検索結果一覧
   */
  static async loadAllActivityCheckHistoryEvents(occurrenceDates?: [Timestamp, Timestamp]): Promise<ActivityCheckHistory[]> {
    const eventFilter: EventFilter = {
      type: 'activity_check_history',
    };
    if (occurrenceDates) {
      const [min, max] = occurrenceDates;
      eventFilter.startTime = { min, max };
    }

    const eventSort = { property: ['startTime'], order: 'desc' };
    const allEvents = await getAllEvents(
      EP_PATH_SOLAR_EVENTS_LIST,
      eventFilter,
      eventSort,
    ) as CogniteEvent[];
    return allEvents && allEvents.length
      ? allEvents.map((event) => new ActivityCheckHistory(event))
      : [];
  }

  /**
   * 直近100件の死活監視結果を一覧で取得する。
   * CDFのAPI仕様として「検索条件に合致したイベント（作成日時の昇順でソートされている）を指定した件数に絞ってから
   * 指定したソート条件をかける」形になっているので、代替方式としてすべての死活監視結果を取得してからArray.slice()で100件に絞って返却する。
   * @returns {Promise<ActivityCheckHistory[]>} 検索結果一覧
   */
  static async loadLatestActivityCheckHistoryEvents(): Promise<ActivityCheckHistory[]> {
    const allHistoryEvents = await ActivityCheckHistory.loadAllActivityCheckHistoryEvents();
    return allHistoryEvents.slice(0, 100);
  }

  /**
   * 指定した検索条件に一致する死活監視結果を一覧で取得する。
   * CDFのAPI仕様としてメタデータ項目の部分一致での検索はできないので、
   * 全件取得後にGMU-IDとサイト名の検索条件でフィルタリングした結果を返却する。
   * @param {[Timestamp, Timestamp] | undefined} occurrenceDates 発生日時
   * @param {string | undefined} gmuId GMU-ID（部分一致対応）
   * @param {string | undefined} idSiteName サイト名（部分一致対応）
   * @returns {Promise<ActivityCheckHistory[]>} 指定した検索条件に一致する死活監視結果一覧
   */
  static async loadActivityCheckHistoryEventsByFilter(
    occurrenceDates?: [Timestamp, Timestamp],
    gmuId?: string,
    idSiteName?: string,
  ): Promise<ActivityCheckHistory[]> {
    const allHistoryEvents = await ActivityCheckHistory.loadAllActivityCheckHistoryEvents(occurrenceDates);

    // CDFのAPI仕様としてメタデータ項目の部分一致でクエリができないので、対象イベントをすべて取得後にフィルタをかける
    const searchResults = allHistoryEvents.filter((event) => {
      const doesGmuIdMatch = !gmuId || (!!gmuId && event.gmuId.includes(gmuId));
      const doesSiteNameMatch = !idSiteName || (!!idSiteName && event.message.includes(idSiteName));
      return doesGmuIdMatch && doesSiteNameMatch;
    });

    return searchResults;
  }
}

export default ActivityCheckHistory;
