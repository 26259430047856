import {
  Asset,
  CogniteEvent,
  AssetListScope,
  CursorAndAsyncIterator,
  EventFilterRequest,
  ItemsResponse,
} from '@cognite/sdk';

import BaseAsset from './BaseAsset';
import ManagedFacility from './ManagedFacility';
import InspectionResult from '../Event/InspectionResult';
import { implementSubtreeAssets } from '../common/CogniteApi';

import { postApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_INSPECTION_RESULT_LIST,
  EP_PATH_ROOT_ASSET_LIST,
} from '../AWS/EndpointPath';
import { ResourceType } from '../common/SDFDataType';

const ASSET_TYPE = 'root';

class RootAsset extends BaseAsset {
  /*
   * クラスメソッド
   */
  static async loadAllFromCDF(): Promise<RootAsset[]> {
    const scope: AssetListScope = {
      filter: { metadata: { assetType: ASSET_TYPE } },
      limit: Infinity,
    };

    const assets = await postApiGateway<AssetListScope, CursorAndAsyncIterator<Asset>>(
      EP_PATH_ROOT_ASSET_LIST, scope,
    );

    return assets.items.map((asset) => new RootAsset(asset));
  }

  /**
   * ルートアセットを特定
   * @param {number} rootAssetId ルートアセットID
   * @returns ルートアセット
   */
  static async retrieveFromCDF(rootAssetId: number): Promise<RootAsset> {
    const allAssets = await RootAsset.loadAllFromCDF();
    const rootAssets = allAssets.filter((asset) => asset.id === rootAssetId);

    return rootAssets[0];
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      super(asset);
    } else {
      super();
      this.metadata = { assetType: ASSET_TYPE };
    }
  }

  /*
   * メンバメソッド
   */
  cloneSelf(): RootAsset {
    const clone = new RootAsset();
    Object.assign(clone, this);

    return clone;
  }

  async loadChildrenFromCDF(): Promise<BaseAsset[]> {
    const children = await this.loadManagedFacilitiesFromCDF();
    return children;
  }

  /**
   * 管理設備一覧取得
   * @returns 管理設備一覧リスト
   */
  async loadManagedFacilitiesFromCDF(): Promise<ManagedFacility[]> {
    const assets = await implementSubtreeAssets(ResourceType.ManagedFacility, [{ id: this.id }], { assetType: 'equipment' });
    return assets.items.map((asset) => new ManagedFacility(asset));
  }

  /**
   * 点検結果一覧取得
   * @returns 点検結果一覧リスト
   */
  async loadInspectionResultsFromCDF(): Promise<InspectionResult[]> {
    const managedFacilities = await this.loadManagedFacilitiesFromCDF();
    if (managedFacilities.length === 0) {
      return [];
    }

    // ルートアセット配下の管理設備一覧に紐づく点検結果一覧取得
    const limit = 5000; // Events.list.assetIdsの上限値
    const count = Math.ceil(managedFacilities.length / limit);

    const eventsListPromises: Promise<ItemsResponse<CogniteEvent>>[] = [];
    for (let i = 0; i < count; i++) {
      const sliceAssetIds = managedFacilities
        .slice(limit * i, limit * (i + 1))
        .map((managedFacility) => managedFacility.id);
      const eventListPromise: EventFilterRequest = {
        filter: {
          assetIds: sliceAssetIds,
          type: 'inspection',
          subtype: 'result',
        },
        limit: Infinity,
      };

      const events = postApiGateway<EventFilterRequest, ItemsResponse<CogniteEvent>>(
        EP_PATH_INSPECTION_RESULT_LIST, eventListPromise,
      );
      eventsListPromises.push(events);
    }
    const eventItemResponses: ItemsResponse<CogniteEvent>[] = await Promise.all(eventsListPromises);
    const inspectionResults = eventItemResponses
      .map((eventItemResponse) => eventItemResponse.items)
      .flat()
      .map((event) => new InspectionResult(event));

    return inspectionResults;
  }
}

export default RootAsset;
