import { CogniteEvent, CursorResponse, EventFilterRequest } from '@cognite/sdk';
import moment from 'moment';

import BaseEvent from './BaseEvent';
import { postApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_SOLAR_EGS_EVENTS_LIST } from '../AWS/EndpointPath';
import { getAllEvents } from '../dataAccess';

/** 日付フォーマット */
const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

/**
 * 診断結果
 */
class PcsComparisonStatus extends BaseEvent {
  /**
   * メンバ変数
   */
  get siteName(): string {
    if (!this.metadata || !this.metadata.site_name_jp) return '';
    return this.metadata.site_name_jp;
  }

  get pcsNo(): string {
    if (!this.metadata || !this.metadata.pcs_no) return '';
    return this.metadata.pcs_no;
  }

  get status(): string {
    if (!this.metadata || !this.metadata.status) return '';
    return this.metadata.status;
  }

  get dateForDisplay(): string {
    if (!this.startTime || !this.startTime) return '';
    return moment(this.startTime).format(DATE_FORMAT);
  }

  /**
   * 検索条件に該当するPCS比較診断結果履歴情報を取得する。
   * ※この関数ではnextCursorを用いた再帰処理を行わないため注意
   * @param {EventFilterRequest} eventFilter 検索条件
   * @returns {Promise<CursorResponse<PcsComparisonStatus[]>>} PCS比較診断結果履歴情報
   */
  static async loadPcsComparisonByFilter(
    eventFilter: EventFilterRequest,
  ): Promise<CursorResponse<PcsComparisonStatus[]>> {
    const customEventFilter: EventFilterRequest = {
      ...eventFilter,
      filter: {
        ...eventFilter.filter,
      },
    };
    const loadPcsComparisonStatuses = await postApiGateway<
      EventFilterRequest, CursorResponse<CogniteEvent[]>
    >(EP_PATH_SOLAR_EGS_EVENTS_LIST, customEventFilter);

    const pcsComparisonStatuses = loadPcsComparisonStatuses.items.map(
      (loadPcsComparisonStatus) => new PcsComparisonStatus(loadPcsComparisonStatus),
    );

    return {
      ...loadPcsComparisonStatuses,
      items: pcsComparisonStatuses,
    };
  }

  /**
   * 検索条件に該当するすべてのPCS比較診断結果情報を取得する。
   * @param {EventFilterRequest} eventFilter 検索条件
   * @returns {Promise<PcsComparisonStatus[]>} PCS比較診断結果履歴情報
   */
  static async loadAllPcsComparisonByFilter(
    eventFilter: EventFilterRequest,
  ): Promise<PcsComparisonStatus[]> {
    const customEventFilter = {
      ...eventFilter.filter,
    };

    const loadPcsComparisonStatuses = await getAllEvents(EP_PATH_SOLAR_EGS_EVENTS_LIST, customEventFilter);
    return loadPcsComparisonStatuses.map((event) => new PcsComparisonStatus(event));
  }
}

export default PcsComparisonStatus;
