import React, { useState, useEffect } from 'react';
import { EventFilter, EventFilterRequest } from '@cognite/sdk';
import {
  Typography, Divider, Row, Col, Descriptions, Button, message, Tooltip,
} from 'antd';
import moment from 'moment';

import SiteCascader, { SiteCascaderSelect } from './parts/SiteCascader';
import ElectricEnergyStatusSelect, { ELECTRIC_ENERGY_STATUS } from './parts/ElectricEnergyStatusSelect';
import ElectricEnergyOccurrenceDates from './parts/ElectricEnergyOccurrenceDates';
import SolarSite from '../../utils/Asset/SolarSite';

import ElectricEnergyStatus from '../../utils/Event/ElectricEnergyStatus';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';

import {
  ERROR_NOT_ENTERED_SEARCH_CONDITION,
  ERROR_SEARCH_RESULT_OVER_LIMIT,
  ERROR_NO_AUTH_MESSAGE,
  ERROR_FILE_DOWNLOAD,
  INFORMATION_NO_SEARCH_RESULTS,
} from '../../utils/messages';
import { generateCsvData } from '../../utils/File/CsvFile';
import './ElectricEnergyHistory.css';
import SearchedEventTable from './SearchedEventTable';

const { Title } = Typography;

/**
 * 発電量ステータス一覧画面
 */
const ElectricEnergyHistory: React.FC = () => {
  const [occurrenceDates, setOccurrenceDates] = useState<[moment.Moment, moment.Moment] | []>([]);
  const [solarSite, setSolarSite] = useState<SolarSite | undefined>();
  const [status, setStatus] = useState<number | undefined>();
  const [
    electricEnergyStatuses, setElectricEnergyStatuses,
  ] = useState<ElectricEnergyStatus[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState<boolean>(true);
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        ELECTRIC_ENERGY_HISTORY: { ELECTRIC_ENERGY_HISTORY_SEARCH_BUTTON, ELECTRIC_ENERGY_HISTORY_DOWNLOAD_BUTTON },
      } = AUTHENTICATION_TYPE_MATRIX;

      if (!canceled) {
        setIsSearchButtonDisabled(!await containsUIAuthType(ELECTRIC_ENERGY_HISTORY_SEARCH_BUTTON));
        setIsDownloadButtonDisabled(!await containsUIAuthType(ELECTRIC_ENERGY_HISTORY_DOWNLOAD_BUTTON));
      }
    })();

    return () => { canceled = true; };
  }, []);

  /**
   * ステータスの表示名変換
   * @param {string} statusNo ステータス番号
   * @return {string} ステータス名
   */
  const statusChange = (statusNo: string): string => {
    const result = ELECTRIC_ENERGY_STATUS.find((el) => String(el.value) === statusNo);
    return result ? result.displayName : '';
  };

  /**
   * 発生日時選択時のイベントハンドラ
   * @param {[] | [moment.Moment, moment.Moment]} dates 選択した発生日時
   */
  const handleChangeOccurrenceDates = (dates: [] | [moment.Moment, moment.Moment]): void => {
    setOccurrenceDates(dates);
  };

  /**
   * サイト選択時のイベントハンドラ
   * @param {SiteCascaderSelect | undefined} selectedValues 選択したサイト情報
   */
  const handleChangeSite = (selectedValues?: SiteCascaderSelect): void => {
    if (!selectedValues) {
      setSolarSite(selectedValues);
    } else {
      const value = new SolarSite(selectedValues.site);
      setSolarSite(value);
    }
  };

  /**
   * ステータス選択時のイベントハンドラ
   * @param {number | undefined} value 選択したステータス
   */
  const handleChangeStatus = (value?: number): void => {
    setStatus(value);
  };

  /**
   * 発電ステータス検索オブジェクト作成
   * @returns {EventFilterRequest} 発電ステータス検索オブジェクト
   */
  const createEventFilterRequest = (): EventFilterRequest => {
    const filter: EventFilter = {};
    if (occurrenceDates.length !== 0) {
      const [startTime, endTime] = occurrenceDates;
      filter.startTime = {
        min: startTime?.valueOf(),
        max: endTime?.valueOf(),
      };
    }

    if (solarSite) {
      filter.type = `${solarSite.externalId}_electric_energy_status_history`;
    }

    if (status) {
      filter.metadata = { status: String(status) };
    }

    // CogniteSDKとAPIで差分があるためasを用いてキャスト
    const eventFilterRequest = {
      filter,
      sort: [
        // CDFの仕様上、第二ソートまでしか設定できない
        { property: ['startTime'], order: 'desc' },
        { property: ['metadata', 'site_name_jp'], order: 'asc' },
      ],
      limit: 1000,
    } as EventFilterRequest;

    return eventFilterRequest;
  };

  /**
   * 発電ステータスをソートする。
   * 1. 発生日時: 降順
   * 2. サイト名: 昇順
   * 3. PCS番号: 昇順
   * 4. ステータス: 昇順
   * @param {ElectricEnergyStatus[]} sortTargetElectricEnergyStatuses ソート対象の発電ステータスリスト
   * @returns ソート後の発電ステータスリスト
   */
  const sortElectricEnergyStatus = (
    sortTargetElectricEnergyStatuses: ElectricEnergyStatus[],
  ): ElectricEnergyStatus[] => {
    const sortedElectricEnergyStatuses = [...sortTargetElectricEnergyStatuses];
    sortedElectricEnergyStatuses.sort((a, b) => {
      const startTimeA = Number(a.startTime);
      const startTimeB = Number(b.startTime);
      if (startTimeA !== startTimeB) {
        return startTimeB - startTimeA;
      }

      // 登録時に必ず設定される
      const metadataA = a.metadata!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
      const metadataB = b.metadata!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
      if (metadataA.site_name_jp !== metadataB.site_name_jp) {
        return metadataA.site_name_jp.localeCompare(metadataB.site_name_jp);
      }

      if (metadataA.pcs_no !== metadataB.pcs_no) {
        return Number(metadataA.pcs_no) - Number(metadataB.pcs_no);
      }

      return Number(metadataA.status) - Number(metadataB.status);
    });

    return sortedElectricEnergyStatuses;
  };

  /**
   * 検索ボタンクリック時のイベントハンドラ
   */
  const handleClickSearch = async (): Promise<void> => {
    setLoading(true);

    if (occurrenceDates.length === 0 && !solarSite && !status) {
      message.error(ERROR_NOT_ENTERED_SEARCH_CONDITION);
      setLoading(false);
      return;
    }

    const eventFilterRequest = createEventFilterRequest();
    const loadElectricEnergyStatuses = await ElectricEnergyStatus.loadElectricEnergyByFilter(
      eventFilterRequest,
    );

    // 検索結果がない場合はメッセージを表示
    if (loadElectricEnergyStatuses.items.length === 0) {
      message.info(INFORMATION_NO_SEARCH_RESULTS);
      setElectricEnergyStatuses(undefined);
      setLoading(false);
      return;
    }

    if (loadElectricEnergyStatuses.nextCursor) {
      message.error(ERROR_SEARCH_RESULT_OVER_LIMIT);
      setLoading(false);
      return;
    }
    const sortedElectricEnergyStatuses = sortElectricEnergyStatus(loadElectricEnergyStatuses.items);

    setElectricEnergyStatuses(sortedElectricEnergyStatuses);
    setLoading(false);
  };

  /**
   * ダウンロードボタンクリック時のイベントハンドラ
   */
  const handleClickDownload = async (): Promise<void> => {
    setDownloading(true);

    // 検索条件不備の場合はエラーメッセージを表示しダウンロードしない
    if (occurrenceDates.length === 0 && !solarSite && !status) {
      message.error(ERROR_NOT_ENTERED_SEARCH_CONDITION);
      setDownloading(false);
      return;
    }

    const eventFilterRequest = createEventFilterRequest();
    const loadElectricEnergyStatuses = await ElectricEnergyStatus.loadAllElectricEnergyByFilter(
      eventFilterRequest,
    );

    // 検索結果がない場合はメッセージを表示しダウンロードしない
    if (loadElectricEnergyStatuses.length === 0) {
      message.info(INFORMATION_NO_SEARCH_RESULTS);
      setDownloading(false);
      return;
    }

    const sortedElectricEnergyStatuses = sortElectricEnergyStatus(loadElectricEnergyStatuses);
    const electricEnergyStatusesBody = sortedElectricEnergyStatuses.map((item) => {
      const statusArr = {
        ...item,
        metadata: {
          ...item.metadata,
          status: item.metadata ? statusChange(item.metadata.status) : '',
        },
      };
      return new ElectricEnergyStatus(statusArr);
    });

    const header = [
      { key: 'dateForCsv', headerString: '発生日時' },
      { key: 'siteName', headerString: 'サイト名' },
      { key: 'pcsNo', headerString: 'PCS番号' },
      { key: 'status', headerString: 'ステータス' },
    ];

    const csvData = generateCsvData(header, electricEnergyStatusesBody);
    const aTagForDownload = document.createElement('a');
    let url = '';
    try {
      url = URL.createObjectURL(csvData);
      document.body.appendChild(aTagForDownload);
      aTagForDownload.href = url;
      aTagForDownload.download = `SDF_発電量ステータス一覧_${moment().format('YYYYMMDDHHmmss')}.csv`;
      aTagForDownload.click();
    } catch (e) {
      message.error(ERROR_FILE_DOWNLOAD);
    } finally {
      aTagForDownload.remove();
      URL.revokeObjectURL(url);
    }
    setDownloading(false);
  };

  return (
    <div className="electric-energy-history-container">
      <div className="electric-energy-history-title">
        <Title level={4}>発電量ステータス一覧</Title>
      </div>

      <Divider className="electric-energy-history-divider" />

      <Row>
        <Col span={20} offset={1}>
          <Descriptions bordered>
            <Descriptions.Item label="発生日時" span={3}>
              <ElectricEnergyOccurrenceDates onChange={handleChangeOccurrenceDates} />
            </Descriptions.Item>
            <Descriptions.Item label="サイト選択" span={3}>
              <SiteCascader
                onChange={handleChangeSite}
              />
            </Descriptions.Item>
            <Descriptions.Item label="ステータス" span={3}>
              <ElectricEnergyStatusSelect onChange={handleChangeStatus} />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={17} offset={1}>
          <span className="electric-energy-history-annotation">*</span>
          検索条件は1つ以上選択してください。
        </Col>
        <Col span={4}>
          <Tooltip title={isSearchButtonDisabled ? ERROR_NO_AUTH_MESSAGE : '検索'}>
            <Button
              type="primary"
              onClick={handleClickSearch}
              style={{ marginTop: 16, marginBottom: 16 }}
              loading={loading}
              disabled={isSearchButtonDisabled}
            >
              検索
            </Button>
          </Tooltip>
          <Tooltip title={isDownloadButtonDisabled ? ERROR_NO_AUTH_MESSAGE : 'ダウンロード'}>
            <Button
              type="primary"
              onClick={handleClickDownload}
              style={{ marginLeft: 15 }}
              loading={downloading}
              disabled={downloading || isDownloadButtonDisabled}
            >
              ダウンロード
            </Button>
          </Tooltip>
        </Col>
      </Row>
      {electricEnergyStatuses && (
        <Row>
          <SearchedEventTable loading={loading} searchedEventList={electricEnergyStatuses} />
        </Row>
      )}
    </div>
  );
};

export default ElectricEnergyHistory;
