import {
  CogniteEvent,
  CursorResponse,
  EventFilter,
  EventFilterRequest,
  ExternalEvent,
  ItemsResponse,
  ItemsWrapper,
} from '@cognite/sdk';
import moment from 'moment';

import BaseEvent from './BaseEvent';
import { postApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_SOLAR_PROCESSES_OPERATIONAL_STATUS, EP_PATH_SOLAR_EVENTS_LIST } from '../AWS/EndpointPath';

/**
 * 運転状況イベント
 */
export default class OperationalStatus extends BaseEvent {
  /** Events.type */
  static eventType = 'operational status';

  /*
   * メンバ変数
   */
  /**
   * 逸失原因
   */
  get status(): string {
    if (!this.metadata || !this.metadata.status) return '';
    return this.metadata.status;
  }

  /**
   * 開始年月日
   */
  get startDate(): string {
    if (!this.startTime) return '';
    const startTimestamp = moment(this.startTime);
    return startTimestamp.format('YYYY/MM/DD');
  }

  /**
   * 開始時刻
   */
  get startTimeToDisplay(): string {
    if (!this.startTime) return '';
    const startTimestamp = moment(this.startTime);
    return startTimestamp.format('HH:mm:ss');
  }

  /**
   * 終了年月日
   */
  get endDate(): string {
    if (!this.endTime) return '';
    const endTimestamp = moment(this.endTime);
    return endTimestamp.format('YYYY/MM/DD');
  }

  /**
   * 終了時刻
   */
  get endTimeToDisplay(): string {
    if (!this.endTime) return '';
    const endTimestamp = moment(this.endTime);
    return endTimestamp.format('HH:mm:ss');
  }

  /**
   * 逸失発電量
   */
  get lostEnergy(): string {
    if (!this.metadata || !this.metadata.lostEnergy) return '';
    return this.metadata.lostEnergy;
  }

  /**
   * 運転状況を取得する。
   * ※この関数ではnextCursorを用いた再帰処理を行わないため注意
   * @param {number[]} pcsIds PCSアセットIDリスト
   * @param {[moment.Moment, moment.Moment] | []} occurrenceDates 発生日時（開始～終了）
   * @returns {Promise<CursorResponse<OperationalStatus[]>>} 運転状況イベントリスト
   */
  static async loadOperationalStatus(
    pcsIds: number[],
    occurrenceDates: [moment.Moment, moment.Moment] | [],
  ): Promise<CursorResponse<OperationalStatus[]>> {
    const filter: EventFilter = {
      assetIds: pcsIds,
      type: OperationalStatus.eventType,
      metadata: {
        isDeleted: 'false',
      },
    };

    if (occurrenceDates.length) {
      const [startTime, endTime] = occurrenceDates;
      filter.startTime = { min: startTime.startOf('minute').valueOf() };
      filter.endTime = { max: endTime.endOf('minute').valueOf() };
    }

    const eventFilter: EventFilterRequest = {
      filter,
      limit: 1000,
    };

    const response = await postApiGateway<EventFilterRequest, CursorResponse<CogniteEvent[]>>(EP_PATH_SOLAR_EVENTS_LIST, eventFilter);

    const operationalStatuses = response.items.map((res) => new OperationalStatus(res));
    return {
      ...response,
      items: operationalStatuses,
    };
  }

  /**
   * 運転状況イベントを作成する。
   * @param {OperationalStatus[]} operationalStatus ステータス情報
   */
  static async createOperationalStatusEvent(operationalStatus: OperationalStatus[]): Promise<void> {
    const items = operationalStatus.map((elm) => ({
      assetIds: elm.assetIds,
      dataSetId: elm.dataSetId,
      startTime: elm.startTime,
      endTime: elm.endTime,
      type: OperationalStatus.eventType,
      metadata: elm.metadata,
    }));

    await postApiGateway<ItemsWrapper<ExternalEvent[]>, ItemsResponse<CogniteEvent[]>>(
      EP_PATH_SOLAR_PROCESSES_OPERATIONAL_STATUS, { items },
    );
  }
}
