/* eslint-disable import/no-named-as-default-member */
import jwtDecode from 'jwt-decode';
import { Mutex } from 'await-semaphore';
import storage from '../../utils/localStorageCache';

const AUTH_RESULT_STORAGE_KEY = 'authResult';
// if token is valid for less than 5 more minutes we might just discard it
const TOKEN_TTL_BUFFER_SECONDS = 60 * 5;

// semaphore
const mutex = new Mutex();
let semaphore;

/**
 * 認証情報をローカルストレージに格納する。
 * @param {Object} authResult 認証情報
 */
export const persistAuthResult = (authResult) => {
  storage.setItem(AUTH_RESULT_STORAGE_KEY, authResult);
};

/**
 * 認証情報の有効期限までの秒数を取得する。
 * @param {number} exp 認証有効時間
 * @returns 有効期限までの秒数
 */
function getTokenTtlSeconds(exp) {
  const currentTimestampSeconds = Math.floor(Date.now() / 1000);
  return exp - currentTimestampSeconds;
}

/**
 * ローカルストレージから認証情報を取得する。
 * @returns 認証情報
 */
export const retrieveAuthResult = () => {
  const authResult = storage.getItem(AUTH_RESULT_STORAGE_KEY);
  return authResult;
};

/**
 * 認証情報をローカルストレージから削除する。
 */
export const removeAuthResultFromStorage = () => {
  storage.removeItem(AUTH_RESULT_STORAGE_KEY);
};

/**
 * ローカルストレージの認証情報存在確認
 * @returns true:認証情報あり／false:認証情報なし
 */
export const isCredentialInLocalStorage = () => {
  const persistedAuthResult = retrieveAuthResult();
  const tenant = persistedAuthResult ? persistedAuthResult.project : undefined;
  const user = persistedAuthResult ? persistedAuthResult.user : undefined;
  const accessToken = persistedAuthResult ? persistedAuthResult.accessToken : undefined;
  const awsIdToken = persistedAuthResult ? persistedAuthResult.awsIdToken : undefined;

  return (
    tenant !== undefined
    && user !== undefined
    && accessToken !== undefined
    && awsIdToken !== undefined
  );
};

/**
 * トークンの有効時間取得
 * @param {string} idToken IDトークン
 * @returns トークンの有効時間[msec] ※idTokenが保持する有効時間より短い時間を返す
 */
export const getTokenValidityTime = (idToken) => {
  const decodedIdToken = jwtDecode(idToken);
  const tokenTtlSeconds = getTokenTtlSeconds(decodedIdToken.exp);
  return (tokenTtlSeconds - TOKEN_TTL_BUFFER_SECONDS) * 1000;
};

/**
 * Cognitoトークンの有効時間取得
 * @param {number} exp IDトークン有効期限
 * @returns トークンの有効時間[msec]
 */
export const getCognitoTokenValidityTime = (exp) => {
  const tokenTtlSeconds = getTokenTtlSeconds(exp);
  return tokenTtlSeconds * 1000;
};

/**
 * ローカルストレージから認証情報のプロジェクト名を取得する。
 * @returns プロジェクト名
 */
export const getAuthResultProjectName = () => {
  const authResult = storage.getItem(AUTH_RESULT_STORAGE_KEY);

  if (!authResult) {
    return undefined;
  }

  return authResult.project;
};

/**
 * BinarySemaphore取得
 */
export const takeBinarySemaphore = async () => {
  semaphore = await mutex.acquire();
};

/**
* BinarySemaphore解放
*/
export const giveBinarySemaphore = () => {
  if (semaphore) {
    semaphore();
    semaphore = undefined;
  }
};
