import React, { useCallback } from 'react';

import { CogniteClient, IdEither } from '@cognite/sdk';
import {
  CogniteCadModel,
  Cognite3DViewer,
  CognitePointCloudModel,
  Intersection,
} from '@cognite/reveal';
import * as THREE from 'three';

import { Spin, Tooltip, message } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

import Facility from '../../../utils/Asset/Facility';
import ManagedFacility from '../../../utils/Asset/ManagedFacility';
import BaseFile, { ImageFile } from '../../../utils/File/BaseFile';
import DetectionResult from '../../../utils/Asset/DetectionResult';
import InspectionResult from '../../../utils/Event/InspectionResult';
import { ConfigJson } from '../../../utils/File/ConfigJsonFile';
import { Scan } from '../../../utils/File/ScansJsonFile';
import {
  EP_PATH_MANAGED_FACILITY_FILES_DOWNLOAD,
} from '../../../utils/AWS/EndpointPath';

import { FilePoint, DetectionFilePoint, ScanPoint } from './types';
import ControlPanel from './ControlPanel';
import ScanEditForm from './ScanEditForm';

import { ERROR_NO_AUTH_MESSAGE } from '../../../utils/messages';
import './Model3DView.css';

interface Pixel {
  offsetX: number;
  offsetY: number;
}

interface Model3DViewHandle {
  getScans: () => Scan[];
}

interface Model3DViewProps {
  managedFacility?: ManagedFacility;
  targetFacility?: Facility;
  detectionResult?: DetectionResult;
  inspectionResult?: InspectionResult;
  inspectable?: boolean;
  client: CogniteClient;
  height?: number;
  isShow3dDisabled?: boolean,
  isReadScanDisabled?: boolean,
  isUpdateScanDisabled?: boolean,
}

const Model3DViewBase: React.ForwardRefRenderFunction<
  Model3DViewHandle,
  Model3DViewProps
> = (props, ref) => {
  /*
   * 変数/定数定義
   */
  const [playing, setPlaying] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [scanning, setScanning] = React.useState<boolean>(false);
  const [viewer, setViewer] = React.useState<Cognite3DViewer>();
  const [pointClouds, setPointClouds] = React.useState<
    CognitePointCloudModel[]
  >([]);
  const [cadModels, setCadModels] = React.useState<CogniteCadModel[]>([]);
  const [filePoints, setFilePoints] = React.useState<FilePoint[]>([]);
  const [detectionFilePoints, setDetectionFilePoints] = React.useState<
    DetectionFilePoint[]
  >([]);
  const [scanPoints, setScanPoints] = React.useState<ScanPoint[]>([]);
  const [targetScan, setTargetScan] = React.useState<Scan>();
  const [clickedPoint, setClickedPoint] = React.useState<THREE.Vector3>();
  const [pixel, setPixel] = React.useState<Pixel>();

  const canvasWrapperRef = React.useRef<HTMLDivElement>(null);

  const {
    managedFacility,
    targetFacility,
    detectionResult,
    inspectionResult,
    inspectable,
    height,
    client,
    isShow3dDisabled,
    isReadScanDisabled,
    isUpdateScanDisabled,
  } = props;

  function createObject3DFromScan(
    scan: Scan,
    configJson: ConfigJson,
  ): THREE.Object3D {
    let color = 0xBEBEBE;
    if (!scan.damaged) {
      // gray
      color = 0xBEBEBE;
    } else if (!scan.repairIsNeeded) {
      // blue
      color = 0x0000FF;
    } else if (!scan.repaired) {
      // red
      color = 0xFF0000;
    } else if (scan.repaired) {
      // green
      color = 0x32CD32;
    }

    let sphereRadius = 1.0;
    let sphereWidthSegments = 8.0;
    let sphereHeightSegments = 6.0;
    if (configJson.scanObjects) {
      sphereRadius = configJson.scanObjects.radius;
      sphereWidthSegments = configJson.scanObjects.widthSegments;
      sphereHeightSegments = configJson.scanObjects.heightSegments;
    }
    const geometry = new THREE
      .SphereGeometry(sphereRadius, sphereWidthSegments, sphereHeightSegments);
    const material = new THREE.MeshBasicMaterial({ color });
    const object3D = new THREE.Mesh(geometry, material);
    const { x, y, z } = scan.point;
    object3D.position.set(x, y, z);
    object3D.visible = true;

    return object3D;
  }

  /*
   * 外部に公開するメソッド
   */
  React.useImperativeHandle(ref, () => ({
    getScans: () => scanPoints.map((scanPoint) => scanPoint.scan),
  }));

  const loadConfigJsonOf = useCallback(async (
    _managedFacility: ManagedFacility,
  ): Promise<ConfigJson> => {
    const configJsonFile = await _managedFacility.loadConfigJsonFileFromCDF();
    if (!configJsonFile) throw new Error('設定ファイルがありません。');

    const configJson = await configJsonFile.loadContentFromCDF(
      EP_PATH_MANAGED_FACILITY_FILES_DOWNLOAD,
    );
    if (!configJson.camera || !configJson.defaults) {
      throw new Error('設定ファイルの内容が不正です。');
    }

    return configJson;
  }, []);

  /*
   * イベントハンドラ
   */
  React.useEffect(() => {
    const domElement = canvasWrapperRef.current;
    if (!domElement) return undefined;

    const newViewer = new Cognite3DViewer({
      sdk: client,
      domElement,
      pointCloudEffects: {
        pointBlending: true,
        edlOptions: 'disabled',
      },
      loadingIndicatorStyle: {
        placement: 'topLeft',
        opacity: 0.2,
      },
    });
    newViewer.on('click', (pix: Pixel) => {
      setPixel(pix);
    });

    setViewer(newViewer);

    return () => {
      newViewer.dispose();
    };
  }, [client]);

  // 3Dビューがクリックされたときの処理
  React.useEffect(() => {
    if (!viewer) return;

    if (!pixel) return;

    /*
     * メソッド
     */
    const getIntersectionWith3DModelFromPixel = async (
      pix: Pixel,
    ): Promise<Intersection | null> => {
      const intersection = viewer.getIntersectionFromPixel(
        pix.offsetX,
        pix.offsetY,
      );
      return intersection || null;
    };

    const getIntersectionWith3DObjectFromPixel = (
      pix: Pixel,
      object3Ds: THREE.Object3D[],
    ): THREE.Intersection | null => {
      if (object3Ds.length === 0) return null;

      const canvasWrapper = canvasWrapperRef.current;
      if (!canvasWrapper) return null;

      const coordinates = {
        x: (pix.offsetX / canvasWrapper.clientWidth) * 2 - 1,
        y: (pix.offsetY / canvasWrapper.clientHeight) * -2 + 1,
      };

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(coordinates, viewer.cameraManager.getCamera());

      const intersections = raycaster.intersectObjects(object3Ds);

      return intersections.length === 0 ? null : intersections[0];
    };

    const getScanPointFromPixel = (pix: Pixel): ScanPoint | null => {
      if (scanPoints.length === 0) return null;

      const intersection = getIntersectionWith3DObjectFromPixel(
        pix,
        scanPoints.map((scanPoint) => scanPoint.object3D),
      );
      if (!intersection) return null;

      const clickedScanPoint = scanPoints.find(
        (scanPoint) => scanPoint.object3D === intersection.object,
      );
      return clickedScanPoint || null;
    };

    /*
     * メイン処理
     */
    const scanPoint = getScanPointFromPixel(pixel);
    if (scanPoint) {
      if (!isReadScanDisabled) {
        setTargetScan(scanPoint.scan);
        setScanning(true);
        setPixel(undefined);
      }
      return;
    }

    let canceled = false;
    (async () => {
      const intersection = await getIntersectionWith3DModelFromPixel(pixel);
      if (!intersection) return undefined;

      if (!canceled && intersection && inspectable && !isUpdateScanDisabled) {
        setTargetScan(undefined);
        setClickedPoint(intersection.point);
        setScanning(true);
        setPixel(undefined);
        return undefined;
      }

      return () => {
        canceled = true;
      };
    })();

    setPixel(undefined);
  }, [viewer, pixel, scanPoints, inspectable, isUpdateScanDisabled, isReadScanDisabled]);

  React.useEffect(() => {
    if (!playing) return () => { /* 何もしない */ };

    if (!loading) return () => { /* 何もしない */ };

    if (!viewer) return () => { /* 何もしない */ };

    if (!managedFacility) return () => { /* 何もしない */ };

    /*
     * メソッド
     */

    const addPointCloudsToViewer = async (
      _viewer: Cognite3DViewer,
      configJson: ConfigJson,
    ): Promise<CognitePointCloudModel[]> => {
      if (!configJson.pointClouds) return [];

      const cognitePointClouds: CognitePointCloudModel[] = [];

      const cognitePointCloudsPromise: Promise<void>[] = configJson.pointClouds.map(
        async (pointCloudJson) => {
          const model3D = await _viewer.addPointCloudModel({
            modelId: pointCloudJson.modelId,
            revisionId: pointCloudJson.revisionId,
          });

          const positions = pointCloudJson.position || configJson.defaults.position;
          const scales = pointCloudJson.scale || configJson.defaults.scale;
          const makeTranslation = new THREE.Matrix4().makeTranslation(...positions);
          const makeScale = new THREE.Matrix4().makeScale(...scales);

          const modelTransformation = model3D.getModelTransformation();
          modelTransformation.multiply(makeTranslation);
          modelTransformation.multiply(makeScale);
          model3D.setModelTransformation(modelTransformation);
          const classes = model3D.getClasses();
          classes.forEach((pointClass) => {
            model3D.setClassVisible(pointClass.code, true);
          });

          if (pointCloudJson.pointSize) {
            model3D.pointSize = pointCloudJson.pointSize;
          }

          cognitePointClouds.push(model3D);
        },
      );

      await Promise.all(cognitePointCloudsPromise);

      return cognitePointClouds;
    };

    const addCadModelsToViewer = async (
      _viewer: Cognite3DViewer,
      configJson: ConfigJson,
    ): Promise<CogniteCadModel[]> => {
      if (!configJson.cadModels) return [];

      const cogniteCadModels: CogniteCadModel[] = [];

      const cogniteCadModelsPromise: Promise<void>[] = configJson.cadModels.map(
        async (cadModelJson) => {
          const model3D = await _viewer.addCadModel({
            modelId: cadModelJson.modelId,
            revisionId: cadModelJson.revisionId,
          });
          const positions = cadModelJson.position || configJson.defaults.position;
          const scales = cadModelJson.scale || configJson.defaults.scale;
          const makeTranslation = new THREE.Matrix4().makeTranslation(...positions);
          const makeScale = new THREE.Matrix4().makeScale(...scales);

          const modelTransformation = model3D.getModelTransformation();
          modelTransformation.multiply(makeTranslation);
          modelTransformation.multiply(makeScale);
          model3D.setModelTransformation(modelTransformation);

          cogniteCadModels.push(model3D);
        },
      );

      await Promise.all(cogniteCadModelsPromise);

      return cogniteCadModels;
    };

    /*
     * メイン処理
     */
    pointClouds.forEach((pointCloud) => viewer.removeModel(pointCloud));
    cadModels.forEach((cadModel) => viewer.removeModel(cadModel));

    let canceled = false;
    (async () => {
      let cognitePointClouds: CognitePointCloudModel[] = [];
      let cogniteCadModels: CogniteCadModel[] = [];

      try {
        const configJson = await loadConfigJsonOf(managedFacility);
        cognitePointClouds = await addPointCloudsToViewer(viewer, configJson);
        cogniteCadModels = await addCadModelsToViewer(viewer, configJson);
      } catch (exception) {
        message.error('3Dモデルの描画に失敗しました。');
      }

      if (cognitePointClouds.length > 0) {
        viewer.fitCameraToModel(cognitePointClouds[0]);
      }

      if (!canceled) {
        setLoading(false);
        setPointClouds(cognitePointClouds);
        setCadModels(cogniteCadModels);
      }
    })();

    return () => { canceled = true; };
  }, [
    client,
    viewer,
    managedFacility,
    pointClouds,
    cadModels,
    playing,
    loading,
    loadConfigJsonOf,
  ]);

  React.useEffect(() => {
    if (!viewer) return () => { /* 何もしない */ };

    setFilePoints((prev) => {
      prev.forEach((filePoint) => viewer.removeObject3D(filePoint.object3D));
      return [];
    });

    if (!targetFacility) {
      setFilePoints([]);
      return undefined;
    }

    let canceled = false;
    (async () => {
      const newFilePoints: FilePoint[] = [];

      try {
        const files = await targetFacility.loadLinkedFilesFromCDF();

        const filesPromise: Promise<void>[] = files.map(async (file) => {
          const { latitude, longitude, altitude } = file;
          if (!latitude || !longitude || !altitude) return;

          const geometry = new THREE.ConeGeometry(2, 2, 4);
          const material = new THREE.MeshBasicMaterial({ color: 0x00FFFF });

          const object3D = new THREE.Mesh(geometry, material);
          object3D.position.set(Number(latitude), Number(altitude), -Number(longitude));
          object3D.visible = false;

          viewer.addObject3D(object3D);

          newFilePoints.push({ file, object3D });
        });

        await Promise.all(filesPromise);
      } catch (exception) {
        message.error('ファイル一覧の読み込みに失敗しました。');
      }

      if (!canceled) {
        setFilePoints(newFilePoints);
      }
    })();

    return () => { canceled = true; };
  }, [client, viewer, targetFacility]);

  React.useEffect(() => {
    if (!viewer) return () => { /* 何もしない */ };

    setDetectionFilePoints((prev) => {
      prev.forEach((detectionFilePoint) => (
        viewer.removeObject3D(detectionFilePoint.object3D)
      ));
      return [];
    });

    if (!detectionResult) {
      setDetectionFilePoints([]);
      return undefined;
    }

    let canceled = false;
    (async () => {
      const newDetectionFilePoints: DetectionFilePoint[] = [];

      try {
        const detectionFiles = await detectionResult.loadDetectionImageFilesFromCDF();

        const originalFileIds: IdEither[] = [];
        detectionFiles.forEach((detectionFile) => {
          const targetFileId = detectionFile.originalImageFileId;
          if (targetFileId) originalFileIds.push({ id: targetFileId });
        });

        const originalFiles = await BaseFile.loadFilesByIdsFromCDF(
          originalFileIds,
        );

        detectionFiles.forEach((detectionFile) => {
          const originalFile = originalFiles.find(
            (_originalFile) => _originalFile.id === detectionFile.originalImageFileId,
          );
          if (!originalFile) return;

          const { latitude, longitude, altitude } = originalFile;
          if (!latitude || !longitude || !altitude) return;

          const geometry = new THREE.ConeGeometry(2, 2, 4);
          const material = new THREE.MeshBasicMaterial({ color: 0x00FFFF });

          const object3D = new THREE.Mesh(geometry, material);
          object3D.position.set(Number(latitude), Number(altitude), -Number(longitude));
          object3D.visible = false;

          viewer.addObject3D(object3D);

          newDetectionFilePoints.push({
            file: detectionFile,
            originalImageFile: originalFile as ImageFile,
            object3D,
          });
        });
      } catch (exception) {
        message.error('検出ファイル一覧の読み込みに失敗しました。');
      }

      if (!canceled) {
        setDetectionFilePoints(newDetectionFilePoints);
      }
    })();

    return () => { canceled = true; };
  }, [client, viewer, detectionResult]);

  React.useEffect(() => {
    if (!viewer) return () => { /* 何もしない */ };

    if (!managedFacility) return () => { /* 何もしない */ };

    if (!playing) return () => { /* 何もしない */ };

    setScanPoints((prev) => {
      prev.forEach((scanPoint) => viewer.removeObject3D(scanPoint.object3D));
      return [];
    });

    if (!inspectionResult) return undefined;

    let canceled = false;
    (async () => {
      const newScanPoints: ScanPoint[] = [];

      try {
        const scans = await inspectionResult.loadScansFromCDF();
        const configJson = await loadConfigJsonOf(managedFacility);

        scans.forEach((scan) => {
          const object3D = createObject3DFromScan(scan, configJson);

          viewer.addObject3D(object3D);

          newScanPoints.push({ scan, object3D });
        });
      } catch (exception) {
        message.error('点検内容の読み込みに失敗しました。');
      }

      if (!canceled) {
        setScanPoints(newScanPoints);
      }
    })();

    return () => { canceled = true; };
  }, [
    inspectionResult,
    loadConfigJsonOf,
    managedFacility,
    playing,
    viewer,
  ]);

  /*
   * メソッド
   */
  const handlePointCloudsCheck = useCallback((checked) => {
    pointClouds.forEach((pointCloud) => {
      const classes = pointCloud.getClasses();
      classes.forEach((pointClass) => {
        pointCloud.setClassVisible(pointClass.code, checked);
      });
    });
    if (viewer) {
      viewer.requestRedraw();
    }
  }, [pointClouds, viewer]);

  const handlePointCloudsClick = useCallback(() => {
    if (pointClouds.length > 0) {
      if (viewer) {
        viewer.fitCameraToModel(pointClouds[0]);
      }
    }
  }, [pointClouds, viewer]);

  const handleCadModelsCheck = useCallback((checked) => {
    cadModels.forEach((cadModel) => cadModel.setDefaultNodeAppearance({ visible: checked }));
  }, [cadModels]);

  const handleCadModelsClick = useCallback(() => {
    if (cadModels.length > 0) {
      if (viewer) {
        viewer.fitCameraToModel(cadModels[0]);
      }
    }
  }, [cadModels, viewer]);

  const handleFilePointsCheck = useCallback((checked) => {
    filePoints.forEach((filePoint) => {
      const tmpFilePoint = filePoint;
      tmpFilePoint.object3D.visible = checked;
    });
    if (viewer) {
      viewer.requestRedraw();
    }
  }, [filePoints, viewer]);

  const handleDetectionFilePointsCheck = useCallback((checked) => {
    detectionFilePoints.forEach((detectionFilePoint) => {
      const tmpDetectionFilePoint = detectionFilePoint;
      tmpDetectionFilePoint.object3D.visible = checked;
    });
    if (viewer) {
      viewer.requestRedraw();
    }
  }, [detectionFilePoints, viewer]);

  const handleScanPointsCheck = useCallback((checked) => {
    scanPoints.forEach((scanPoint) => {
      const tmpScanPoint = scanPoint;
      tmpScanPoint.object3D.visible = checked;
    });
    if (viewer) {
      viewer.requestRedraw();
    }
  }, [scanPoints, viewer]);

  /*
   * 画面描画
   */
  const show3dIconColor = isShow3dDisabled ? 'rgba(217, 217, 217, 0.25)' : 'rgb(24, 144, 255)';
  return (
    <div className="common-model-3d-view" style={{ height: height || 800 }}>
      <div className="canvas-wrapper" ref={canvasWrapperRef} />
      {scanning ? (
        <ScanEditForm
          visible={scanning}
          target={targetScan}
          managedFacility={managedFacility}
          point={clickedPoint}
          editable={inspectable}
          onAdd={async (newScan) => {
            if (viewer && managedFacility) {
              const tpmNewScan = newScan;
              tpmNewScan.id = scanPoints.length;
              const configJson = await loadConfigJsonOf(managedFacility);
              const object3D = createObject3DFromScan(tpmNewScan, configJson);
              viewer.addObject3D(object3D);
              scanPoints.push({
                scan: tpmNewScan,
                object3D,
              });
            }
            setScanning(false);
            setScanPoints([...scanPoints]);
          }}
          onUpdate={async (target) => {
            const targetScanPoint = scanPoints.find(
              (scanPoint) => scanPoint.scan.id === target.id,
            );

            let newScanPoints = scanPoints;
            if (viewer && targetScanPoint && managedFacility) {
              viewer.removeObject3D(targetScanPoint.object3D);

              targetScanPoint.scan = target;
              const configJson = await loadConfigJsonOf(managedFacility);
              targetScanPoint.object3D = createObject3DFromScan(target, configJson);

              viewer.addObject3D(targetScanPoint.object3D);

              newScanPoints = [...scanPoints];
            }
            setScanning(false);
            setTargetScan(undefined);
            setScanPoints(newScanPoints);
          }}
          onDelete={(target) => {
            const targetScanPoint = scanPoints.find(
              (scanPoint) => scanPoint.scan.id === target.id,
            );

            let newScanPoints = scanPoints;
            if (viewer && targetScanPoint) {
              viewer.removeObject3D(targetScanPoint.object3D);
              newScanPoints = scanPoints.filter(
                (scanPoint) => scanPoint.scan.id !== target.id,
              );
              newScanPoints.forEach((scanPoint, index) => {
                const tmpScanPoint = scanPoint;
                tmpScanPoint.scan.id = index;
              });
            }
            setScanning(false);
            setTargetScan(undefined);
            setScanPoints(newScanPoints);
          }}
          onCancel={() => setScanning(false)}
        />
      ) : (
        <></>
      )}
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          display: !playing || loading ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {playing ? (
          <Spin tip="Loading..." size="large" />
        ) : (
          <Tooltip title={isShow3dDisabled && ERROR_NO_AUTH_MESSAGE}>
            <PlayCircleOutlined
              onClick={() => !isShow3dDisabled && setPlaying(true)}
              style={{ fontSize: 100, color: show3dIconColor }}
              disabled={isShow3dDisabled}
            />
          </Tooltip>
        )}
      </div>
      <div
        className="control-panel"
        style={{
          display: playing && !loading ? 'block' : 'none',
        }}
      >
        <ControlPanel
          pointClouds={pointClouds}
          cadModels={cadModels}
          filePoints={filePoints}
          detectionFilePoints={detectionFilePoints}
          scanPoints={scanPoints}
          onReloadRequest={() => setLoading(true)}
          onPointCloudsCheck={handlePointCloudsCheck}
          onPointCloudClick={handlePointCloudsClick}
          onCadModelsCheck={handleCadModelsCheck}
          onCadModelClick={handleCadModelsClick}
          onFilePointsCheck={handleFilePointsCheck}
          onDetectionFilePointsCheck={handleDetectionFilePointsCheck}
          onScanPointsCheck={handleScanPointsCheck}
        />
      </div>
    </div>
  );
};

const Model3DView = React.forwardRef(Model3DViewBase);

export default Model3DView;
