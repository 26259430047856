/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import {
  CogniteEvent,
  ExternalDatapoint,
  ExternalDatapointsQuery,
  ExternalEvent,
  ItemsResponse,
  ItemsWrapper,
} from '@cognite/sdk';
import * as XLSX from 'xlsx';
import SolarSite from '../../../utils/Asset/SolarSite';
import { EmptyResponse, postApiGateway } from '../../../utils/AWS/ApiGateway';
import { EP_PATH_SOLAR_TIMESERIES_DATAPOINTS, EP_PATH_SOLAR_EVENTS } from '../../../utils/AWS/EndpointPath';
import {
  VALIDATIONS_CHECK_ERROR,
  VALIDATIONS_LIMIT_CHECK_ERROR,
  VALIDATIONS_ENTERED_MANAGEMENT_NO_CHECK_ERROR,
  VALIDATIONS_NOT_EXISTS_MANAGEMENT_NO,
  ERROR_FILES_UPLOAD,
} from '../../../utils/messages';
import { getParentBusinessAssetsOfSolarSite } from '../../../utils/Asset/SolarSiteAsset';

interface RegisterPresumedInsolationResult {
  error: boolean;
  message?: string | string[];
}

/** 計画日射量ファイルフォーマット定義 */
const PRESUMED_INSOLATION_CELL_POSITIONS = {
  ROW: { START: 6, END: 205 },
  COLUMNS: { START: 67, END: 78 }, // C列 ～ N列
  FISCAL_YEAR: 'B4', // 登録年度
  DATA_ARIA: 'A6:N205',
};

/**
 * 計画日射量登録シートの基本情報（登録年度）項目のバリデーション
 * 登録年度は後続処理に影響があるため型および数値チェックも実施
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns {boolean} 基本情報項目のバリデーション結果
 */
const checkRequiredValidity = (sheet: XLSX.Sheet): boolean => {
  const fiscalYear = sheet[PRESUMED_INSOLATION_CELL_POSITIONS.FISCAL_YEAR];

  // データポイントのタイムスタンプ出力時に影響があるので、登録年度の値が1970以上の整数であるかも確認
  const isFiscalYearValid = fiscalYear && Number.isInteger(fiscalYear.v) && fiscalYear.v >= 1970;
  return isFiscalYearValid;
};

/**
 * 計画日射量登録シートの登録データ項目のバリデーション
 * 値が記入されている月のデータ（セルC5〜N205）に対して型チェックを実施
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns {boolean} 登録データ項目のバリデーション結果
 */
const checkDataValidity = (sheet: XLSX.Sheet): boolean => {
  const { ROW, COLUMNS } = PRESUMED_INSOLATION_CELL_POSITIONS;
  for (let rowNumber = ROW.START; rowNumber <= ROW.END; rowNumber++) {
    for (let charCode = COLUMNS.START; charCode <= COLUMNS.END; charCode++) {
      const cell = `${String.fromCharCode(charCode)}${rowNumber}`;
      // 'n'number
      if (sheet[cell] && sheet[cell].t !== 'n') return false;
    }
  }
  return true;
};

/**
 * 計画日射量登録データシートのバリデーション
 * 「計画日射量登録シート」の存在、基本情報（登録年度）項目、
 * および登録データ項目のチェック結果が全てOKかを確認
 * @param {XLSX.Sheet} sheet 計画日射量登録データシート
 * @returns {RegisterPresumedInsolationResult} バリデーションチェック結果、メッセージ
 */
const checkSheetValidity = (sheet: XLSX.Sheet): RegisterPresumedInsolationResult => {
  if (!sheet) return { error: true, message: VALIDATIONS_CHECK_ERROR };
  const isInfoValid = checkRequiredValidity(sheet);
  const isDataValid = checkDataValidity(sheet);

  if (!checkDataLimitValidity(sheet)) {
    return { error: true, message: VALIDATIONS_LIMIT_CHECK_ERROR };
  }
  if (!checkDataManagementNoValidity(sheet)) {
    return { error: true, message: VALIDATIONS_ENTERED_MANAGEMENT_NO_CHECK_ERROR };
  }
  if (!(isInfoValid && isDataValid)) {
    return { error: true, message: VALIDATIONS_CHECK_ERROR };
  }
  return { error: false };
};

/**
 * 計画日射量シートのデータ件数バリデーション
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns データ件数のバリデーション結果
 */
const checkDataLimitValidity = (sheet: XLSX.Sheet): boolean => {
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, number>>(sheet);
  const lastRowData = sheetJson.pop();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, no-underscore-dangle
  return lastRowData!.__rowNum__ <= (PRESUMED_INSOLATION_CELL_POSITIONS.ROW.END - 1);
};

/**
 * 計画日射量シートの管理No.未入力バリデーション
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns 管理No.未入力のバリデーション結果
 */
const checkDataManagementNoValidity = (sheet: XLSX.Sheet): boolean => {
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, string>>(sheet);
  return !(sheetJson.some((record) => !record.計画日射量登録シート));
};

/**
 * 計画日射量シートの管理Noバリデーション
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @param {SolarSite[]} solarSites 管理Noをmetadataに持つ太陽光アセットリスト
 * @returns データ記入用シートにのみ存在する管理No（すべて存在する場合、空の配列を返却）
 */
const checkManagementNoValidity = async (sheet: XLSX.Sheet, solarSites: SolarSite[]): Promise<string[]> => {
  // 管理番号をmetadataに持つAssetから管理番号のみ抽出
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const managementNos = solarSites.map((solarSite) => solarSite.metadata!.managementNo);
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, string>>(
    sheet, { header: ['managementNo'] },
  );

  // アップロードされたファイルにのみ存在する管理番号を抽出
  const onlyInputExistsManagementNo = sheetJson
    .map((sheetJsonItem) => sheetJsonItem.managementNo)
    .filter((managementNo) => !managementNos.includes(managementNo));

  return onlyInputExistsManagementNo;
};

/**
 * 登録年度の各月初日の日付を一覧で取得
 * 登録データの抽出とデータポイントへの変換時に使用
 * @param {number} year 登録年度
 * @returns {string[]} 登録年度の各月初日の日付
 */
const createHeaderDates = (year: number): string[] => {
  const headerDates = [];
  headerDates.push('managementNo');
  headerDates.push('siteName');
  const firstDate = new Date(`${year}-04-01T00:00:00.000`);
  headerDates.push(firstDate.toDateString());

  let nextDate = new Date(firstDate);
  for (let i = 0; i < 11; i++) {
    const nextMonth = nextDate.getMonth() + 1;
    nextDate = new Date(nextDate.setMonth(nextMonth));
    headerDates.push(nextDate.toDateString());
  }
  return headerDates;
};

/**
 * アップロードされた登録データ記入用シートからデータを抽出し、データポイントに変換する
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @param {string[]} headerDates 登録年度の各月初日の日付一覧（データ読み込みとタイムスタンプの変換に使用）
 * @param {SolarSite[]} solarSites 管理Noをmetadataに持つ太陽光アセットリスト
 * @returns 太陽光サイトごとの記入データを変換したデータポイントの配列
 */
const convertFileDataToDatapoints = (
  sheet: XLSX.Sheet,
  headerDates: string[],
  solarSites: SolarSite[],
): { solarSite: SolarSite, datapoints: ExternalDatapoint[] }[] => {
  // シート記載の登録データの値を抽出し、データが1件でもあればデータポイントへの変換を実施
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, string | number>>(
    sheet, { header: headerDates },
  );
  const solarSiteWithDatapoints = sheetJson.map((json) => {
    const { managementNo } = json;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [solarSite] = solarSites.filter((s) => s.metadata!.managementNo === managementNo);

    const datapoints = Object.entries(json)
      .filter(([key]) => !['managementNo', 'siteName'].includes(key)) // 0: managementNo, 1: siteName以外を取り出す
      .map((predictedData) => {
        const timestamp = new Date(predictedData[0]).valueOf(); // valueOfメソッドを使用して、string型に交換する
        const value = predictedData[1];
        return { timestamp, value };
      });

    return { solarSite, datapoints };
  });

  return solarSiteWithDatapoints;
};

/**
 * アップロードされた計画日射量登録データファイルに記入された計画日射量データをCDFに登録
 * 登録前にファイルの記入内容に対してバリデーションチェックを行い、チェック結果を返却する
 * @param {XLSX.WorkBook} file アップロードファイル
 * @returns ファイル記入内容のバリデーションチェック結果（画面で使用するため）、メッセージ
 */
export const registerPredictedInsolation = async (
  file: XLSX.WorkBook,
): Promise<RegisterPresumedInsolationResult> => {
  // ファイルの中身のバリデーションチェックを実施し、チェックOKの場合のみ後続処理に進む
  const dataSheet = file.Sheets['計画日射量登録シート'];
  const checkSheetResult = checkSheetValidity(dataSheet);
  if (checkSheetResult.error) {
    return checkSheetResult;
  }

  dataSheet['!ref'] = PRESUMED_INSOLATION_CELL_POSITIONS.DATA_ARIA;
  const assetsWithManagementNo = await SolarSite.loadAllAssetWithManagementNos();
  const notExistsManagementNos = await checkManagementNoValidity(dataSheet, assetsWithManagementNo);
  if (notExistsManagementNos.length > 0) {
    return { error: true, message: [VALIDATIONS_NOT_EXISTS_MANAGEMENT_NO, ...notExistsManagementNos] };
  }

  const fiscalYear = dataSheet[PRESUMED_INSOLATION_CELL_POSITIONS.FISCAL_YEAR].v;
  const headerDates = createHeaderDates(fiscalYear);

  const solarSiteWithDatapoints = convertFileDataToDatapoints(dataSheet, headerDates, assetsWithManagementNo);

  // 登録データが1件でもある場合のみCDFへのデータ登録を実施
  const externalDatapoints: ExternalDatapointsQuery[] = [];
  solarSiteWithDatapoints.forEach(({ solarSite, datapoints }) => {
    if (datapoints.length === 0) return;
    const externalId = `${solarSite.externalId}_predicted_insolation`;
    externalDatapoints.push({ externalId, datapoints });
  });

  if (externalDatapoints.length === 0) {
    // 登録データなしの場合
    return { error: false };
  }

  const postResponse = await postApiGateway<ItemsWrapper<ExternalDatapointsQuery[]>, EmptyResponse>(
    EP_PATH_SOLAR_TIMESERIES_DATAPOINTS, { items: externalDatapoints },
  );
  if ('error' in postResponse) {
    return { error: true, message: ERROR_FILES_UPLOAD };
  }

  // 計画日射量更新通知イベント作成
  const businessClassificationAssets = await getParentBusinessAssetsOfSolarSite(solarSiteWithDatapoints.map((item) => item.solarSite));
  if (businessClassificationAssets.length > 0) {
    const items = businessClassificationAssets.map((asset) => ({
      dataSetId: asset.dataSetId,
      startTime: new Date().getTime(),
      type: 'predicted_insolation_daily_task',
      assetIds: [asset.id],
      metadata: { fiscal_year: fiscalYear },
    }));

    const createBody = { items };
    const createEventsResponse = await postApiGateway<ItemsWrapper<ExternalEvent[]>, ItemsResponse<CogniteEvent[]>>(
      EP_PATH_SOLAR_EVENTS, createBody,
    );
    if ('error' in createEventsResponse) {
      return { error: true, message: ERROR_FILES_UPLOAD };
    }
  }

  return { error: false };
};
