import {
  Asset,
  CogniteInternalId,
  AssetListScope,
  FileRequestFilter,
  FileInfo,
  CursorAndAsyncIterator,
  AssetAggregateQuery,
  TimeseriesAggregateQuery,
  ItemsResponse,
  AggregateResponse,
} from '@cognite/sdk';

import Facility from './Facility';
import BaseAsset from './BaseAsset';
import { ImageFile } from '../File/BaseFile';
import ConfigJsonFile from '../File/ConfigJsonFile';
import DetectionImageFile from '../File/DetectionImageFile';
import ScansJsonFile from '../File/ScansJsonFile';

import { postApiGateway, getApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_MANAGED_FACILITY_LIST,
  EP_PATH_MANAGED_FACILITY_FILES_ICON,
  EP_PATH_MANAGED_FACILITY_FILES_LIST,
  EP_PATH_AI_DETECT_RESULT_AGGREGATE,
  EP_PATH_FACILITY_AGGREGATE,
  EP_PATH_MANAGED_FACILITY_TIMESERIES_AGGREGATE,
  EP_PATH_ROOT_ASSET_AI_DETECT_RESULT,
  EP_PATH_MANAGED_FACILITY_FILES_AGGREGATE,
} from '../AWS/EndpointPath';
import { getAllAssets } from '../dataAccess';

const ASSET_TYPE = 'equipment';

class ManagedFacility extends Facility {
  /*
   * クラス変数
   */
  static get assetType(): string {
    return 'equipment';
  }

  /*
   * クラスメソッド
   */

  /**
   * 管理設備情報全件取得
   * @returns 管理設備情報
   */
  static async loadAllFromCDF(): Promise<ManagedFacility[]> {
    const scope = {
      metadata: { assetType: ManagedFacility.assetType },
    };
    const assets = await getAllAssets(EP_PATH_MANAGED_FACILITY_LIST, scope);
    return assets.map((asset) => new ManagedFacility(asset));
  }

  /**
   * 管理設備情報取得
   * @param {CogniteInternalId} id
   * @returns 管理設備情報
   */
  static async loadOneByIdFromCDF(
    id: CogniteInternalId,
  ): Promise<ManagedFacility | null> {
    const facility = await super.loadOneByIdFromCDF(id);
    return facility ? new ManagedFacility(facility) : null;
  }

  /**
   * topflgがtrueに設定されている管理設備リストを取得する。
   * @returns 管理設備リスト
   */
  static async loadDisplayedTopFromCDF(): Promise<ManagedFacility[]> {
    const scope: AssetListScope = {
      filter: {
        metadata: {
          topflg: BaseAsset.TOP_FLAG,
          assetType: ASSET_TYPE,
        },
      },
      limit: 1000,
    };

    const result = await postApiGateway<AssetListScope, CursorAndAsyncIterator<Asset>>(
      EP_PATH_MANAGED_FACILITY_LIST, scope,
    );
    return result.items.map((item) => new ManagedFacility(item));
  }

  /**
   * topIconがtrueに設定されている管理設備のサムネイルを取得する
   * @param {CogniteInternalId} assetId 管理設備のAssetID
   * @returns サムネイルデータ
   * @使用画面 設備一覧
   */
  static async getTopIcon(assetId: CogniteInternalId): Promise<string | undefined> {
    const filter: FileRequestFilter = {
      filter: {
        assetIds: [assetId],
        mimeType: 'image/jpeg',
        metadata: { type: 'topIcon' },
      },
      limit: 1,
    };

    const result = await postApiGateway<FileRequestFilter, CursorAndAsyncIterator<FileInfo>>(
      EP_PATH_MANAGED_FACILITY_FILES_LIST, filter,
    );

    if (result.items.length <= 0) return undefined;

    const item = result.items[0];
    const getUrl = EP_PATH_MANAGED_FACILITY_FILES_ICON.concat(`?id=${item.id}`);
    const data = await getApiGateway<string>(getUrl);

    return data;
  }

  /*
   * メンバ変数
   */

  /*
   * アクセサ
   */
  static get type(): string {
    return ASSET_TYPE;
  }

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      super(asset);
    } else {
      super();
      this.metadata = {
        assetType: ASSET_TYPE,
        topflg: BaseAsset.TOP_FLAG,
      };
    }
  }

  /*
   * メソッド
   */
  cloneSelf(): ManagedFacility {
    const clone = new ManagedFacility();
    Object.assign(clone, this);

    return clone;
  }

  /**
   * 管理設備設定ファイル情報取得
   * @returns 管理設備設定ファイル
   */
  async loadConfigJsonFileFromCDF(): Promise<ConfigJsonFile | null> {
    const filter: FileRequestFilter = {
      filter: {
        assetIds: [this.id],
        metadata: {
          type: 'equipmentConf',
        },
      },
    };

    const files = await postApiGateway<FileRequestFilter, CursorAndAsyncIterator<FileInfo>>(
      EP_PATH_MANAGED_FACILITY_FILES_LIST, filter,
    );

    if (files.items.length === 0) return null;

    const configJsonFile = new ConfigJsonFile();
    Object.assign(configJsonFile, files.items[0]);

    return configJsonFile;
  }

  /**
   * AI関連アセット数カウント
   * @returns アセット数
   */
  async countResultAiAssets(): Promise<number> {
    const filter: AssetAggregateQuery = {
      filter: { metadata: { managementEquipmentId: String(this.id) } },
    };

    const detectionResultAggregates = (
      await postApiGateway<AssetAggregateQuery, ItemsResponse<AggregateResponse>>(
        EP_PATH_AI_DETECT_RESULT_AGGREGATE, filter,
      )
    );

    return detectionResultAggregates.items[0].count;
  }

  /**
   * 設備アセット数カウント
   * @returns アセット数
   */
  async countFacilityAssets(): Promise<number> {
    const filter: AssetAggregateQuery = {
      filter: { assetSubtreeIds: [{ id: this.id }] },
    };

    const facilityAggregates = (
      await postApiGateway<AssetAggregateQuery, ItemsResponse<AggregateResponse>>(
        EP_PATH_FACILITY_AGGREGATE, filter,
      )
    );

    return facilityAggregates.items[0].count;
  }

  /**
   * タイムシリーズ数カウント
   * @returns タイムシリーズ数
   */
  async countTimeseries(): Promise<number> {
    const filter: TimeseriesAggregateQuery = {
      filter: { assetSubtreeIds: [{ id: this.id }] },
    };

    const timeseriesAggregates = (
      await postApiGateway<TimeseriesAggregateQuery, ItemsResponse<AggregateResponse>>(
        EP_PATH_MANAGED_FACILITY_TIMESERIES_AGGREGATE, filter,
      )
    );

    return timeseriesAggregates.items[0].count;
  }

  /**
   * アセットに紐づくファイル数カウント
   * @returns ファイル数
   */
  async countFilesWithAsset(): Promise<number> {
    // アセットに紐づくファイル総数カウント
    const totalFilesWithAssetAggregates = await ImageFile.countTotalFilesWithAsset(
      EP_PATH_MANAGED_FACILITY_FILES_AGGREGATE,
      this.id,
    );
    // 点検内容ファイル数カウント
    const scansFilesAggregates = await ScansJsonFile.countScansFiles(this.id);
    // アセットに紐づくファイル総数からシステムで作成されるファイル（点検内容）数を除いて算出
    const filesWithAssetAggregates = totalFilesWithAssetAggregates - scansFilesAggregates;

    return filesWithAssetAggregates;
  }

  /**
   * AI検出画像ファイル数カウント
   * @returns ファイル数
   */
  async countResultAiFiles(): Promise<number> {
    // 管理設備に紐づいたAI検出結果を特定
    const scope: AssetListScope = {
      filter: { metadata: { managementEquipmentId: String(this.id) } },
    };

    const resultAiAsset = (
      await postApiGateway<AssetListScope, CursorAndAsyncIterator<Asset>>(
        EP_PATH_ROOT_ASSET_AI_DETECT_RESULT, scope,
      )
    );

    // 特定したAI検出結果に紐づくAI検出画像ファイル数を加算
    let resultAiAggregates = 0;
    await Promise.all(
      resultAiAsset.items.map(async (item) => {
        const itemAggregates = await DetectionImageFile.countDetectImageFiles(item.id);
        resultAiAggregates += itemAggregates;
      }),
    );

    return resultAiAggregates;
  }
}

export default ManagedFacility;
