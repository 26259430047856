import {
  FileInfo,
  FileAggregateQuery,
  ItemsResponse,
  FileAggregate,
} from '@cognite/sdk';
import { JsonFile } from './BaseFile';
import { FILE_METADATA_TYPE } from '../common/SDFDataType';

import { postApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_LOG_FILE_AGGREGATE,
} from '../AWS/EndpointPath';

class LogFile extends JsonFile {
  /*
  * クラスメソッド
  */

  /**
   * ログファイル数カウント
   * @returns ファイル数
   */
  static async countLogFiles(): Promise<number> {
    const filter: FileAggregateQuery = {
      filter: { metadata: { type: FILE_METADATA_TYPE.LOG } },
    };

    const logAggregates = (
      await postApiGateway<FileAggregateQuery, ItemsResponse<FileAggregate>>(
        EP_PATH_LOG_FILE_AGGREGATE, filter,
      )
    );

    return logAggregates.items[0].count;
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(fileInfo?: FileInfo) {
    if (fileInfo) {
      super(fileInfo);
    } else {
      super();
    }
  }

  /*
   * メンバメソッド
   */
}

export default LogFile;
