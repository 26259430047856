import React, { useEffect, useState, useRef } from 'react';
import {
  Typography, Divider, Row, Col, Descriptions, Button, Tooltip, message, Form, Input, InputNumber, Spin,
} from 'antd';
import type { FormComponentProps, GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import type { EventChange, ItemsResponse } from '@cognite/sdk';

import type { SiteCascaderSelect } from './parts/SiteCascader';
import SiteCascader from './parts/SiteCascader';
import SolarEquipmentInformation from '../../utils/Event/SolarEquipmentInformation';
import SolarUnitPrice from '../../utils/Event/SolarUnitPrice';
import { putApiGateway } from '../../utils/AWS/ApiGateway';
import { EP_PATH_SOLAR_PROCESSES_INFORMATION } from '../../utils/AWS/EndpointPath';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import {
  ERROR_NO_AUTH_MESSAGE,
  ERROR_SITE_NOT_SELECTED,
  ERROR_INFORMATION_EVENT,
  SUCCESS_UPDATE_INFORMATION,
  ERROR_UPDATE_INFORMATION,
  ERROR_UPDATE_INFORMATION_400,
  VALIDATE_ERROR_UNIT_PRICE,
} from '../../utils/messages';

const { Title } = Typography;

/**
 * 発電所カタログ編集項目
 * @property {string} key metadata key
 * @property {string} title 表示名称
 * @property {string} type 設定値の型
 * @property {boolean} disabled 編集可否(true：編集不可／false：編集可能)
 */
const EDIT_ITEMS = [
  /* eslint-disable object-curly-newline */
  { key: 'site_name_jp', title: '発電所名', type: 'string', disabled: true },
  { key: 'address', title: '所在地', type: 'string', disabled: false },
  { key: '運転開始年月', title: '運転開始日', type: 'string', disabled: false },
  { key: 'AM事業者', title: 'AM事業者', type: 'string', disabled: false },
  { key: 'O_M事業者', title: 'O&M事業者', type: 'string', disabled: false },
  { key: '連系容量（kW）', title: '連系容量（kW）', type: 'number', disabled: false },
  { key: 'パネル容量（kW）', title: 'パネル容量（kW）', type: 'number', disabled: false },
  { key: 'PCS会社名', title: 'PCS会社名', type: 'string', disabled: false },
  { key: 'PCS型番', title: 'PCS型番', type: 'string', disabled: false },
  { key: 'PCS容量（kW）', title: 'PCS容量（kW）', type: 'string', disabled: false },
  { key: 'PCS台数', title: 'PCS台数', type: 'number', disabled: false },
  { key: 'パネル会社名', title: 'パネル会社名', type: 'string', disabled: false },
  { key: 'パネル型番', title: 'パネル型番', type: 'string', disabled: false },
  { key: 'パネル単一容量（W）', title: 'パネル単一容量（W）', type: 'string', disabled: false },
  { key: 'パネル枚数', title: 'パネル枚数', type: 'number', disabled: false },
  { key: '架台', title: '架台（段数、角度、部材）', type: 'string', disabled: false },
  { key: 'エリア', title: 'エリア', type: 'string', disabled: false },
  { key: '供給先', title: '供給先', type: 'string', disabled: false },
  { key: '売電単価（円）', title: '売電単価（円）', type: 'number', disabled: false },
  { key: '投資意思決定単位', title: '投資意思決定単位', type: 'string', disabled: false },
  /* eslint-enable object-curly-newline */
];

/**
 * イベント更新
 * @param {SolarEquipmentInformation} informationEvent informationイベント情報
 * @param {SolarUnitPrice | undefined} unitPriceEvent unit_priceイベント情報
 */
const updateEvent = async (
  informationEvent: SolarEquipmentInformation,
  unitPriceEvent: SolarUnitPrice | undefined,
) => {
  const infoMetadata = informationEvent.metadata;
  if (!infoMetadata) return;

  // 更新可能項目のみ要求する
  const updateMetadata: { [key: string]: string; } = {};
  EDIT_ITEMS.forEach((item) => {
    if (!item.disabled) updateMetadata[item.key] = infoMetadata[item.key];
  });
  const changeInformation: EventChange = {
    id: informationEvent.id,
    update: { metadata: { add: updateMetadata, remove: [] } },
  };

  const requestItems = [changeInformation];
  if (unitPriceEvent) {
    // unit_price更新要求
    requestItems.push({
      id: unitPriceEvent.id,
      update: { metadata: { add: { value: updateMetadata['売電単価（円）'] }, remove: [] } },
    });
  }

  await putApiGateway<{ items: EventChange[] }, ItemsResponse<Event>>(
    EP_PATH_SOLAR_PROCESSES_INFORMATION, { items: requestItems },
  );
};

/**
* 発電所カタログ編集画面
* @param props Form API群
* @returns 発電所カタログ編集画面
*/
const EditEquipmentInformationBase = (props: FormComponentProps) => {
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState<boolean>(true);
  const [isReloadButtonDisabled, setIsReloadButtonDisabled] = useState<boolean>(true);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState<boolean>(true);
  const [selectSiteId, setSelectSiteId] = useState<number | undefined>(undefined);
  const [editComponent, setEditComponent] = useState<boolean>(false);
  const [loadEditComponent, setLoadEditComponent] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);

  const informationEvent = useRef<SolarEquipmentInformation | undefined>(undefined);
  const unitPriceEvent = useRef<SolarUnitPrice | undefined>(undefined);

  const { form } = props;
  const { validateFieldsAndScroll, getFieldDecorator } = form;

  /**
   * メッセージ表示
   * @param type メッセージタイプ
   * @param wording 表示文言
   */
  const displayMessage = (type: string, wording: string) => {
    message.destroy();
    if (type === 'error') {
      message.error(wording);
    } else {
      message.success(wording);
    }
  };

  /** 初期化処理 */
  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        EDIT_EQUIPMENT_INFORMATION_EDIT, EDIT_EQUIPMENT_INFORMATION_RELOAD, EDIT_EQUIPMENT_INFORMATION_UPDATE,
      } = AUTHENTICATION_TYPE_MATRIX.SOLAR_MENU.EDIT_EQUIPMENT_INFORMATION;

      if (!canceled) {
        setIsEditButtonDisabled(!await containsUIAuthType(EDIT_EQUIPMENT_INFORMATION_EDIT));
        setIsReloadButtonDisabled(!await containsUIAuthType(EDIT_EQUIPMENT_INFORMATION_RELOAD));
        setIsUpdateButtonDisabled(!await containsUIAuthType(EDIT_EQUIPMENT_INFORMATION_UPDATE));
      }
    })();

    return () => { canceled = true; };
  }, []);

  /** 編集画面作成 */
  useEffect(() => {
    let canceled = false;
    (async () => {
      if (!canceled) {
        if (!selectSiteId || !loadEditComponent) return;

        // informationイベント検索
        informationEvent.current = await SolarEquipmentInformation.loadEquipmentInformationByAssetId(selectSiteId);

        // サイトにinformationイベントが存在しない場合は編集を行わない
        if (!informationEvent.current || !informationEvent.current.metadata) {
          displayMessage('error', ERROR_INFORMATION_EVENT);
          setLoadEditComponent(false);
          return;
        }

        // 売電単価イベント検索
        unitPriceEvent.current = await SolarUnitPrice.loadUnitPriceByAssetId(selectSiteId);

        setEditComponent(true);
        setLoadEditComponent(false);
      }
    })();

    return () => { canceled = true; };
  }, [loadEditComponent, selectSiteId]);

  /** 登録処理 */
  useEffect(() => {
    let canceled = false;
    (async () => {
      if (!canceled) {
        if (!updating) return;
        if (!informationEvent.current || !informationEvent.current.metadata) return;

        // 設定値チェック
        let validateError = false;
        let values: { [key: string]: string } = {};
        validateFieldsAndScroll({ scroll: { offsetBottom: 30 } }, async (errors, formValues) => {
          // Formから設定値を取得
          values = formValues;
          if (errors) {
            validateError = true;
          }
        });
        if (validateError) {
          setUpdating(false);
          return;
        }

        // 更新処理
        const { metadata } = informationEvent.current;
        EDIT_ITEMS.forEach((item) => {
          if (values[item.key] === undefined || values[item.key] === null) {
            metadata[item.key] = '';
          } else {
            metadata[item.key] = String(values[item.key]);
          }
        });

        try {
          await updateEvent(informationEvent.current, unitPriceEvent.current);
          displayMessage('success', SUCCESS_UPDATE_INFORMATION);
        } catch (e: unknown) {
          if (e instanceof Error && e.message === '400') {
            displayMessage('error', ERROR_UPDATE_INFORMATION_400);
          } else {
            displayMessage('error', ERROR_UPDATE_INFORMATION);
          }
        } finally {
          setUpdating(false);
        }
      }
    })();

    return () => { canceled = true; };
  }, [updating, validateFieldsAndScroll]);

  /**
   * サイト選択時のイベントハンドラ
   * @param {SiteCascaderSelect | undefined} selectedValues 選択したサイト情報
   */
  const handleChangeSite = (selectedValues?: SiteCascaderSelect): void => {
    if (selectSiteId !== selectedValues?.site.id) {
      // サイト選択切り替えが行われたら編集コンポーネントを初期化
      setEditComponent(false);
      setSelectSiteId(selectedValues?.site.id);
    }
  };

  /**
   * 編集ボタンクリック時のイベントハンドラ
   */
  const handleClickEdit = (): void => {
    // サイト未選択時はエラー表示して終了
    if (!selectSiteId) {
      displayMessage('error', ERROR_SITE_NOT_SELECTED);
      return;
    }

    // 編集画面未作成時のみ作成開始する（サイト変更せず編集ボタン連打時の対応）
    if (!editComponent) {
      setLoadEditComponent(true);
    }
  };

  /**
   * リセットボタンクリック時のイベントハンドラ
   */
  const handleReset = (): void => {
    setEditComponent(false);
    setLoadEditComponent(true);
  };

  /**
   * 更新ボタンクリック時のイベントハンドラ
   */
  const handleUpdate = async (): Promise<void> => {
    setUpdating(true);
  };

  /**
   * 編集用Form作成
   */
  const createEditForm = (): JSX.Element | undefined => {
    if (!informationEvent.current || !informationEvent.current.metadata) return undefined;

    // 編集画面構築
    const formItems: JSX.Element[] = [];

    // Form作成
    const { metadata } = informationEvent.current;
    const unitPrice = unitPriceEvent.current;
    EDIT_ITEMS.forEach((item) => {
      let defaultValue = metadata[item.key];
      const option: GetFieldDecoratorOptions = { validateTrigger: 'onSubmit' };

      // 売電単価はinformationに存在しなければunit_priceの値を使用する
      if (metadata) {
        if (item.key === '売電単価（円）' && unitPrice) {
          if (defaultValue === undefined || defaultValue === '') defaultValue = unitPrice.value;
          option.rules = [{ required: true, message: VALIDATE_ERROR_UNIT_PRICE }];
        }
      }

      if (item.type === 'number') {
        // 数値入力
        option.initialValue = defaultValue ? Number(defaultValue) : undefined;
        formItems.push(
          <Form.Item label={item.title} key={item.key}>
            {getFieldDecorator(item.key, option)(
              <InputNumber style={{ width: '100%' }} disabled={item.disabled} />,
            )}
          </Form.Item>,
        );
      } else {
        // 文字列入力
        option.initialValue = defaultValue;
        formItems.push(
          <Form.Item label={item.title} key={item.key}>
            {getFieldDecorator(item.key, option)(
              <Input disabled={item.disabled} />,
            )}
          </Form.Item>,
        );
      }
    });

    return (
      <>
        <Row>
          <Col span={22} offset={1} style={{ width: '90%', maxHeight: '800px', overflowY: 'scroll' }}>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
              {formItems}
            </Form>
          </Col>
        </Row>
        <Row>
          <Col span={22} offset={1} style={{ width: '90%', textAlign: 'right' }}>
            <Tooltip title={isReloadButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
              <Button
                type="default"
                style={{ marginTop: 16, marginBottom: 10, marginRight: 10 }}
                onClick={handleReset}
                disabled={isReloadButtonDisabled}
              >
                リセット
              </Button>
            </Tooltip>
            <Tooltip title={isUpdateButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
              <Button
                type="primary"
                style={{ marginTop: 16, marginBottom: 10 }}
                onClick={handleUpdate}
                disabled={isUpdateButtonDisabled}
              >
                登録
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div style={{ backgroundColor: 'white', minHeight: '1080px' }}>
      <div style={{ marginTop: '10px', paddingRight: '16px', paddingLeft: '24px' }}>
        <Title level={4}>発電所カタログ編集</Title>
      </div>

      <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />

      <Spin spinning={updating} tip="Loading..." size="large" style={{ marginTop: '10%' }}>
        <Row>
          <Col span={20} offset={1}>
            <Descriptions bordered>
              <Descriptions.Item label="サイト選択" span={3}>
                <SiteCascader
                  onChange={handleChangeSite}
                />
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row>
          <Col span={4} offset={20}>
            <Tooltip title={isEditButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
              <Button
                type="primary"
                onClick={handleClickEdit}
                style={{ marginTop: 16, marginBottom: 16 }}
                disabled={isEditButtonDisabled}
              >
                編集
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Spin spinning={loadEditComponent} tip="Loading..." size="large" style={{ marginTop: '10%' }}>
          <Row>{editComponent && createEditForm()}</Row>
        </Spin>
      </Spin>
    </div>
  );
};

const EditEquipmentInformation = Form.create<FormComponentProps>()(EditEquipmentInformationBase);

export default EditEquipmentInformation;
