import EmailGroup from './EmailGroup';
import { postApiGateway, putApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_ALERTS_LIST, EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_ALERTS_UPDATE } from '../AWS/EndpointPath';

interface SendParameter {
  gmuId: string,
  subKey: string,
  lastUpdated: number,
  targetGroupIds: string[],
}

class AlertMappingInfo {
  /**
   * 「alert-mapping-list」テーブルの全レコードを取得
   * @returns {Promise<AlertMappingInfo[]>} 取得結果
   */
  static async getAllAlertMappings(): Promise<AlertMappingInfo[]> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_ALERTS_LIST;
    const extractedAlertMappingList = await postApiGateway(endpointPath, {})
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((result) => (result as any[]).map((value) => ({ id: value.gmuId + value.subKey, ...value }))) as AlertMappingInfo[];

    const allEmailGroups = await EmailGroup.getAllGroups();
    const alertMappingsWithGroupNames = extractedAlertMappingList.map((alertMapping) => {
      const targetGroupNames = allEmailGroups.filter(({ id }) => alertMapping.targetGroupIds.includes(id))
        .map(({ groupName }) => groupName);
      return { ...alertMapping, targetGroupNames };
    });
    return alertMappingsWithGroupNames;
  }

  /**
   * 「alert-mapping-list」テーブルのレコードを更新
   * @param {SendParameter} sendParameter 更新用のデータ
   */
  static async updateTargetGroupIds(sendParameter: SendParameter): Promise<void> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_ALERTS_UPDATE;
    await putApiGateway(endpointPath, sendParameter);
  }

  gmuId: string;

  subKey: string;

  areaNameJp: string;

  severity: number;

  signalName: string;

  targetGroupIds: string[];

  lastUpdated: number;

  targetGroupNames: string[];

  id: string;

  constructor() {
    this.gmuId = '';
    this.subKey = '';
    this.areaNameJp = '';
    this.severity = 0;
    this.signalName = '';
    this.targetGroupIds = [];
    this.lastUpdated = 0;
    this.targetGroupNames = [];
    this.id = '';
  }
}
export default AlertMappingInfo;
