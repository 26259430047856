import { postApiGateway, putApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_SOLAR_EGS_PROCESSES_DIAGNOSIS_SETTINGS_PCS_COMPARISON_STANDARD_PCS_LIST,
  EP_PATH_SOLAR_EGS_PROCESSES_DIAGNOSIS_SETTINGS_PCS_COMPARISON_STANDARD_PCS_UPDATE,
} from '../AWS/EndpointPath';

export interface SendParameter {
  siteName: string,
  newStandardPcsNo: number,
  oldStandardPcsNo: number,
}

export interface PcsListParameter {
  interComparisonCoefficient: number,
  pcsNo: number,
  ratedCapacity: number,
}

class StandardPcsGroup {
  /**
   * 「diagnosis-setting-list」テーブルの全レコード取得および初期ソート
    * @returns {Promise<StandardPcsGroup[]>} 取得結果
   */
  static async getStandardPcsGroups(): Promise<StandardPcsGroup[]> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_DIAGNOSIS_SETTINGS_PCS_COMPARISON_STANDARD_PCS_LIST;
    const allStandardPcsSiteGroups = await postApiGateway(endpointPath, {})
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((result) => (result as any[]).map((value, index) => ({ id: index, ...value }))
        .sort((a, b) => a.managementNo.localeCompare(b.managementNo, 'en', { numeric: true }))) as StandardPcsGroup[];
    return allStandardPcsSiteGroups;
  }

  /**
   * 「diagnosis-setting-list」テーブルのレコードを更新
   * @param {SendParameter} sendParameter 更新用のデータ
   */
  static async updateStandardPcs(sendParameter: SendParameter): Promise<void> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_DIAGNOSIS_SETTINGS_PCS_COMPARISON_STANDARD_PCS_UPDATE;
    await putApiGateway(endpointPath, sendParameter);
  }

  siteName: string;

  companyNameJp: string;

  siteNameJp: string;

  managementNo: string;

  standardPcsNo: number;

  pcsList: PcsListParameter[];

  id: number;

  constructor() {
    this.siteName = '';
    this.companyNameJp = '';
    this.siteNameJp = '';
    this.managementNo = '';
    this.standardPcsNo = 0;
    this.pcsList = [];
    this.id = 0;
  }
}

export default StandardPcsGroup;
