import {
  FileInfo,
  FileAggregateQuery,
  ItemsResponse,
  FileAggregate,
} from '@cognite/sdk';

import { JsonFile } from './BaseFile';
import { postApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_LEARNING_MODEL_FILES_CONFIG_AGGREGATE,
} from '../AWS/EndpointPath';

/**
 * 機械学習やAI検出を実行する際の設定ファイル
 */
class ConfigFile extends JsonFile {
  /*
   * クラスメソッド
   */

  /**
   * コンフィグファイル数カウント
   * @returns ファイル数
   */
  static async countConfigFiles()
    : Promise<number> {
    const filter: FileAggregateQuery = {
      filter: { metadata: { type: 'config' } },
    };

    const countConfigAggregates = (
      await postApiGateway<FileAggregateQuery, ItemsResponse<FileAggregate>>(
        EP_PATH_LEARNING_MODEL_FILES_CONFIG_AGGREGATE, filter,
      )
    );

    return countConfigAggregates.items[0].count;
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(fileInfo?: FileInfo) {
    if (fileInfo) {
      super(fileInfo);
    } else {
      super();
    }
  }

  /*
   * メンバメソッド
   */
}

export default ConfigFile;
