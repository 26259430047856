import { CogniteInternalId, FileInfo } from '@cognite/sdk';
import { getApiGateway, ResponseCogniteError } from '../AWS/ApiGateway';
import { EP_PATH_SOLAR_FILES } from '../AWS/EndpointPath';

/**
* 財務帳票ファイルを取得する(ID指定)
* @param id ファイルID
* @returns ファイル情報
*/
const retrieveFinancialReportFile = async (id: CogniteInternalId): Promise<FileInfo> => {
  const endpoint = `${EP_PATH_SOLAR_FILES}/${id}`;
  const financialReportFile = await getApiGateway<FileInfo | ResponseCogniteError>(endpoint);

  if ((financialReportFile as ResponseCogniteError).error) {
    throw financialReportFile;
  }
  return financialReportFile as FileInfo;
};

export {
  retrieveFinancialReportFile,
};
