import { CogniteEvent, EventFilter } from '@cognite/sdk';

import BaseEvent from './BaseEvent';
import { EP_PATH_SOLAR_OM_EVENTS_LIST } from '../AWS/EndpointPath';
import { getAllEvents } from '../dataAccess';

class OMStatusEvent extends BaseEvent {
  /**
   * メンバ変数
   */

  get title(): string {
    if (!this.metadata || !this.metadata?.title) return '';
    return this.metadata.title;
  }

  get totalElectricEnergy(): number {
    if (!this.metadata || !this.metadata?.total_electric_energy) return 0;
    return Number(this.metadata.total_electric_energy);
  }

  get solarRadiationIntensity(): number {
    if (!this.metadata || !this.metadata?.solar_radiation_intensity) return 0;
    return Number(this.metadata.solar_radiation_intensity);
  }

  get temperature(): number {
    if (!this.metadata || !this.metadata?.temperature) return 0;
    return Number(this.metadata.temperature);
  }

  /**
   * 対象エリアに紐付くステータス保持イベント一覧を取得する。
   * @param {number} areaAssetId 対象エリアのアセットID
   * @returns {Promise<OMStatusEvent[]>} 対象エリア配下のステータス保持イベント一覧
   */
  static async loadStatusEvents(areaAssetId: number): Promise<OMStatusEvent[]> {
    const statusEventFilter: EventFilter = {
      assetSubtreeIds: [
        {
          id: areaAssetId,
        },
      ],
      type: 'status_holder',
      subtype: 'latest',
    };

    const statusEventSort = {
      property: ['metadata', 'status_sort_key'],
      order: 'asc',
    };

    const statusEvents = await getAllEvents(
      EP_PATH_SOLAR_OM_EVENTS_LIST,
      statusEventFilter,
      statusEventSort,
    ) as CogniteEvent[];
    return statusEvents.map((event) => new OMStatusEvent(event));
  }
}

export default OMStatusEvent;
