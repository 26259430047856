import React, {
  useState, useEffect, useRef,
} from 'react';
import {
  Asset,
} from '@cognite/sdk';
import {
  Typography,
  Divider,
  Descriptions,
  Row,
  Col,
  Button,
  message,
  Tooltip,
} from 'antd';

import SiteCascader, { SiteCascaderSelect } from './parts/SiteCascader';
import { loadAllAssetsByParentIds } from '../../utils/Asset/SolarSiteAsset';
import SolarOperationalStatusInputTable from './SolarOperationalStatusInputTable';
import { ERROR_NO_AUTH_MESSAGE, ERROR_SITE_NOT_SELECTED } from '../../utils/messages';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import { sortName } from '../../utils/sort';
import './SolarOperationalStatusInput.css';

const { Title } = Typography;

/**
 * 運転状況入力サイト選択画面
 */
const SolarOperationalStatusInput: React.FC = () => {
  const [isSelectButtonDisabled, setIsSelectButtonDisabled] = useState<boolean>(true);
  const [isDisplayInputArea, setIsDisplayInputArea] = useState<boolean>(false);
  const [selectSiteId, setSelectSiteId] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pcsInfo = useRef<Asset[]>([]);
  const dataSetId = useRef<number>();

  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        SOLAR_OPERATIONAL_STATUS_INPUT_SELECT,
      } = AUTHENTICATION_TYPE_MATRIX.SOLAR_MENU.SOLAR_OPERATIONAL_STATUS_INPUT;
      if (!canceled) {
        setIsSelectButtonDisabled(!await containsUIAuthType(SOLAR_OPERATIONAL_STATUS_INPUT_SELECT));
      }
    })();
    return () => { canceled = true; };
  }, []);

  /**
   * サイト選択時のイベントハンドラ
   * @param {SiteCascaderSelect | undefined} selectedValues 選択したサイト情報
   */
  const handleChangeSite = async (selectedValues?: SiteCascaderSelect): Promise<void> => {
    // サイト選択切り替えが行われたら入力エリアを非表示
    if (selectSiteId !== selectedValues?.site.id) {
      setIsDisplayInputArea(false);
      setSelectSiteId(selectedValues?.site.id);
      dataSetId.current = selectedValues?.site.dataSetId;
    }
  };

  /**
   * 選択ボタンクリック時のイベントハンドラ
   */
  const handleClickSelect = async (): Promise<void> => {
    setIsLoading(true);
    if (!selectSiteId) {
      message.error(ERROR_SITE_NOT_SELECTED);
      setIsLoading(false);

      return;
    }
    const pcsList = await loadAllAssetsByParentIds([selectSiteId]);
    sortName(pcsList, 'asc');
    pcsInfo.current = pcsList;
    setIsDisplayInputArea(true);
    setIsLoading(false);
  };

  /**
   * データ登録画面をレンダリングする
   */
  return (
    <>
      <div className="solar-operational-status-input-container">
        <div className="solar-operational-status-input-title">
          <div className="solar-operational-status-input-margin">
            <Title level={4}>逸失原因登録</Title>
          </div>
          <Divider className="solar-operational-status-input-divider" />
        </div>
        <Row>
          <Col span={20} offset={1}>
            <Descriptions bordered>
              <Descriptions.Item label="サイト選択" span={3}>
                <SiteCascader
                  onChange={handleChangeSite}
                />
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row>
          <Col span={4} offset={20}>
            <Tooltip title={isSelectButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
              <Button
                type="primary"
                style={{ marginTop: 5 }}
                onClick={handleClickSelect}
                disabled={isSelectButtonDisabled}
              >
                選択
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <br />
        {isDisplayInputArea && (
          <div>
            <SolarOperationalStatusInputTable
              loading={isLoading}
              pcsInfo={pcsInfo.current}
              dataSetId={dataSetId.current}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SolarOperationalStatusInput;
