import { Tabs } from 'antd';
import React from 'react';
import ElectricEnergyComparisonHistory from './ElectricEnergyComparisonHistory';
import './ElectricEnergyComparisonTabs.css';

const { TabPane } = Tabs;

interface TabInfo {
  title: string;
  key: string;
  period: 'hourly' | 'daily';
}

const allTabNames: TabInfo[] = [
  { title: '1時間判定', key: 'hourly-electric-energy-tab', period: 'hourly' },
  { title: '1日判定', key: 'daily-electric-energy-tab', period: 'daily' },
];

/**
 * 「発電量診断結果一覧」を押した際に出てくる「1時間判定」と「1日判定」のタブ
 * @returns {React.FC} タブコンポーネント
 */
const ElectricEnergyComparisonTabs: React.FC = () => (
  <div className="electric-energy-comparison-tabs-background-color">
    <Tabs defaultActiveKey="1" className="electric-energy-comparison-tabs">
      {allTabNames.map((tab) => (
        <TabPane tab={tab.title} key={tab.key}>
          <ElectricEnergyComparisonHistory period={tab.period} />
        </TabPane>
      ))}
    </Tabs>
  </div>
);
export default ElectricEnergyComparisonTabs;
