import React, { useState } from 'react';

import {
  Button,
  Row,
  Table,
  Tooltip,
} from 'antd';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import moment from 'moment';

import { JoinedItem, outerJoinDatapoints } from '../MeasurementList';
import OMMeasurement, { TransformedDatapoint } from '../../../../utils/Timeseries/OMMeasurement/OMMeasurement';
import { RetrievalTimeRangeInfo } from '../../../../utils/Timeseries/OMMeasurement/TimeInterval';

interface MeasurementTableProps {
  measurements: OMMeasurement[];
  timeRange: RetrievalTimeRangeInfo;
  loading: boolean;
}

/**
 * 計測一覧画面での計測値表示テーブルのコンポーネント
 * @param {MeasurementTableProps} props プロパティ
 * @returns 計測値表示テーブル
 */
const MeasurementTable: React.FC<MeasurementTableProps> = (props: MeasurementTableProps) => {
  const {
    measurements,
    timeRange,
    loading,
  } = props;

  const [sortInfo, setSortInfo] = useState<SorterResult<JoinedItem> | null>(null);

  /**
   * 「ソート解除」ボタンをクリックした時のイベントハンドラ
   * @param {React.MouseEvent} e ボタンのクリックイベント
   */
  const handleClickSortReset = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.currentTarget.blur();
    setSortInfo(null);
  };

  // 表示対象計測値のデータポイント抽出と表示用の形式への変換
  const allDatapoints: Record<string, TransformedDatapoint[]> = {};
  measurements.forEach(({ displayName, transformedDatapoints }) => {
    allDatapoints[displayName] = transformedDatapoints;
  });

  const joinedDatapoints = Object.keys(allDatapoints).length ? outerJoinDatapoints(allDatapoints) : [];

  // 対象の計測値の名称を基に、テーブルのカラムの設定値を動的に生成
  const tableColumns: ColumnProps<JoinedItem>[] = joinedDatapoints.length
    ? Object.keys(joinedDatapoints[0])
      .map((key, index) => (
        {
          title: key === 'timestamp' ? '日時' : key,
          dataIndex: `displayItem_${index}`,
          sorter: (a, b) => {
            const aValue = a[key];
            const bValue = b[key];
            return key === 'timestamp'
              ? (aValue as Date).valueOf() - (bValue as Date).valueOf()
              : (aValue as number) - (bValue as number);
          },
          sortOrder: sortInfo?.columnKey === `displayItem_${index}` && sortInfo.order,
          render: (_, item: JoinedItem) => {
            const target = item[key];
            const title = key === 'timestamp' ? moment(target as Date).format('YYYY/MM/DD HH:mm:ss') : target;
            const displayValue = key === 'timestamp' ? moment(target as Date).format(timeRange.displayFormat) : target;
            const uniqueKey = `${key}_${index}`;
            return (
              <Tooltip title={title} key={uniqueKey}>{displayValue}</Tooltip>
            );
          },
        }
      )) : [];

  return (
    <div style={{ marginTop: '1%' }}>
      {!!tableColumns.length && (
        <>
          <Row type="flex" justify="end">
            <Button
              style={{ marginRight: 25 }}
              onClick={handleClickSortReset}
            >
              ソート解除
            </Button>
          </Row>
          <Row style={{ marginTop: '1%' }}>
            <Table
              loading={loading}
              rowKey="timestamp"
              columns={tableColumns}
              dataSource={joinedDatapoints}
              onChange={(_, __, sorter) => setSortInfo(sorter)}
              pagination={false}
              scroll={{ y: 360 }}
            />
          </Row>
        </>
      )}
    </div>
  );
};

export default MeasurementTable;
