/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
/* eslint-disable react/sort-comp */
/* eslint-disable arrow-body-style */
// eslint警告未対応
import React from 'react';
import {
  Row, Tag, Input, message, Modal, Tooltip,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { validateLearningClassName } from '../../../utils/learningModelManagement/learningModelValidator';
import { WARNING_DELETE_CLASS_TITLE, WARNING_DELETE_CLASS_MESSAGE, ERROR_NO_AUTH_MESSAGE } from '../../../utils/messages';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../../utils/common/Authentication';

/**
 * クラスタイトル
 */
const ClassTitle = styled.h1`
  padding-left: 15px;
  padding-bottom: 3px;
  border-bottom: 1px solid darkgray;
  font-size: 18px;
`;

/**
 * クラスタグ
 */
const ClassTag = styled(Tag)`
  border: 1px dashed #d9d9d9;
`;

/**
 * ダイアログのボタンラベル
 */
const MODAL_BUTTON_TEXT = {
  DELETE: '削除',
  CANCEL: 'キャンセル',
};

/**
 * 学習クラスコンポーネントクラス
 */
class LearningClass extends React.Component {
  /**
   * コンストラクタ
   * @param {Object} props プロパティ
   */
  constructor(props) {
    super(props);

    this.state = {
      /** クラス情報 */
      classTags: [],
      /** クラス入力テキストボックスの表示状態 */
      classInputVisible: false,
      /** クラス入力テキストボックスの活性状態 */
      classInputDisabled: false,
      /** クラス削除確認ダイアログの表示状態 */
      classConfirmVisible: false,
      /** クラス削除確認ダイアログの処理状態 */
      classConfirmLoading: false,
      isAddClassDisabled: true,
      isRemoveClassDisabled: true,
    };
  }

  /**
   * クラス情報に変更があるか判定する。
   * @param {Object} prevProps 変更前情報
   * @returns 判定結果 true: 変更あり / false: 変更なし
   */
  isChangeClasses(prevProps) {
    const prevClasses = prevProps.classes;
    const thisClasses = this.props.classes;

    if (prevClasses.length !== thisClasses.length) {
      // クラス数が異なる場合
      return true;
    }

    // 要素数が同じ、かつ全ての要素が存在するかを判定
    const isEqualAll = prevClasses.every((prevClass) => thisClasses.includes(prevClass));
    return !isEqualAll;
  }

  /**
   * クラスタグリストを作成する。
   */
  createClassTags() {
    const { isRemoveClassDisabled } = this.state;
    const classTags = this.props.classes.map((className) => {
      return (
        <Tooltip key={className} title={isRemoveClassDisabled && ERROR_NO_AUTH_MESSAGE}>
          <Tag key={className} value={className} closable onClose={(e) => this.handleCloseTag(e, className)}>
            {className}
          </Tag>
        </Tooltip>
      );
    });

    this.setState({ classTags });
  }

  /**
   * クラスタグの削除時のイベントハンドラ
   * @param {Object} e イベントオブジェクト
   * @param {string} className 削除クラス名
   */
  handleCloseTag = (e, className) => {
    e.preventDefault();

    const { isRemoveClassDisabled } = this.state;
    if (isRemoveClassDisabled) return;

    this.deleteClassName = className;
    this.setState({ classConfirmVisible: true });
  };

  /**
   * クラスタグ削除確認ダイアログの「削除」ボタン押下イベント
   */
  handleOkDeleteClass = async () => {
    this.setState({ classConfirmLoading: true });

    // jsonファイルから削除
    await this.props.onDeleteClass(this.deleteClassName);
  };

  /**
   * クラスタグ削除確認ダイアログの「キャンセル」ボタン押下イベント
   */
  handleCancelDeleteClass = () => this.setState({ classConfirmVisible: false });

  /**
   * 追加ボタン押下イベント
   */
  handleClickAddClass = () => {
    const { isAddClassDisabled } = this.state;
    if (isAddClassDisabled) return;
    this.setState({ classInputVisible: true }, () => this.classInput.focus());
  };

  /**
   * クラス入力イベント
   * テキストボックスからフォーカスを外した場合、もしくはEnterキーを押下した場合に動作する。
   * 入力チェックを行い、エラーが存在する場合はエラーメッセージを表示する。
   * - 文字種チェック(半角英数字のみ)
   * - 桁数チェック(30文字以内)
   * - 存在チェック(同名のクラス名が存在する場合はエラー)
   * @param {Object} e イベントオブジェクト
   */
  handleInputClass = async (e) => {
    this.setState({ classInputDisabled: true });

    const className = e.target.value;
    if (!className) {
      this.setState({ classInputVisible: false, classInputDisabled: false });
      return;
    }

    const validateResult = await validateLearningClassName(className, this.props.jsonExternalId);
    if (validateResult.hasError) {
      // 入力チェックエラーの場合、メッセージを表示
      message.error(validateResult.message);
      this.setState({ classInputVisible: false, classInputDisabled: false });
      return;
    }

    // jsonファイルに保存
    await this.props.onSaveClass(className);

    this.setState({ classInputVisible: false, classInputDisabled: false });
  };

  /**
   * クラス入力Elementの設定
   * @param {Object} input クラス入力Element
   */
  classInputRef = (input) => { this.classInput = input; };

  /**
   * レンダリング直後に一度だけ呼ばれる。
   * クラスタグを画面に反映する。
   */
  async componentDidMount() {
    this.createClassTags();

    const { ANNOTATION } = AUTHENTICATION_TYPE_MATRIX;
    const {
      LEARNING_PROJECTS_DETAILS_ADD_CLASS,
      LEARNING_PROJECTS_DETAILS_REMOVE_CLASS_ADDITION,
    } = ANNOTATION;

    this.setState({
      isAddClassDisabled: !await containsUIAuthType(LEARNING_PROJECTS_DETAILS_ADD_CLASS),
      isRemoveClassDisabled: !await containsUIAuthType(LEARNING_PROJECTS_DETAILS_REMOVE_CLASS_ADDITION),
    });
  }

  /**
   * コンポーネントが更新された直後に呼び出される。
   * 変更前後でクラス情報が更新された場合、クラスタグを再表示する。
   * @param {Object} prevProps 変更前のProps
   */
  componentDidUpdate(prevProps) {
    if (this.isChangeClasses(prevProps)) {
      // 再描画する前にstateを初期状態に戻す
      this.setState({ classConfirmVisible: false, classConfirmLoading: false });

      // クラス情報が変更された場合、再描画
      this.createClassTags();
    }
  }

  /**
   * クラスタグをレンダリングする。
   */
  render() {
    const { isAddClassDisabled } = this.state;
    return (
      <>
        <Row>
          <ClassTitle>クラス</ClassTitle>
        </Row>
        <Row>
          {this.state.classTags}

          {this.state.classInputVisible
            ?
            <Input
              aria-label="クラス入力"
              ref={this.classInputRef}
              type="text"
              size="small"
              style={{ width: 100 }}
              onBlur={this.handleInputClass}
              onPressEnter={this.handleInputClass}
              disabled={this.state.classInputDisabled}
            />
            :
            <Tooltip title={isAddClassDisabled && ERROR_NO_AUTH_MESSAGE}>
              <ClassTag onClick={this.handleClickAddClass}>
                <PlusOutlined />
                追加
              </ClassTag>
            </Tooltip>
          }
        </Row>
        <Modal
          title={WARNING_DELETE_CLASS_TITLE}
          visible={this.state.classConfirmVisible}
          confirmLoading={this.state.classConfirmLoading}
          centered
          onOk={this.handleOkDeleteClass}
          okText={MODAL_BUTTON_TEXT.DELETE}
          okType="danger"
          onCancel={this.handleCancelDeleteClass}
          cancelText={MODAL_BUTTON_TEXT.CANCEL}
        >
          {WARNING_DELETE_CLASS_MESSAGE}
        </Modal>
      </>
    );
  }
}

export default LearningClass;
