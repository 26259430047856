import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Icon,
  Input,
  Menu,
  Table,
  Tooltip,
  message,
} from 'antd';
import { ColumnFilterItem, ColumnProps, FilterDropdownProps } from 'antd/lib/table';
import moment from 'moment';
import SolarEquipmentInformation from '../../utils/Event/SolarEquipmentInformation';
import './EquipmentInformationTable.css';
import { ERROR_FILE_DOWNLOAD, ERROR_NO_AUTH_MESSAGE, VALIDATE_ERROR_UNSELECTED_EQUIPMENT } from '../../utils/messages';
import { generateCsvData } from '../../utils/File/CsvFile';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';

interface EquipmentInformationTableProps {
  loading: boolean;
  solarEquipments: SolarEquipmentInformation[];
}

/**
 * フィルタアイコン取得
 * @param {boolean} filtered
 * @returns フィルタアイコン
 */
const getFilterIcon = (filtered: boolean) => (
  <Icon type="search" style={{ color: filtered ? '1890ff' : undefined }} />
);

/**
 * ソートして返却
 * @param {string} a
 * @param {string} b
 * @returns ソートの判定結果
 */
const getSortedItems = (a: string, b: string) => {
  const first = [a, b].sort()[0];
  return first === a ? -1 : 1;
};

/**
 * ソートして返却(数値)
 * @param {string} a
 * @param {string} b
 * @returns ソートの判定結果
 */
const getSortedNumberItems = (a: string, b: string) => {
  // 値が空の場合は昇順の最上部に配置
  if (a === '') {
    return -1;
  }
  if (b === '') {
    return 1;
  }
  return Number(a) - Number(b);
};

/**
 * 小数点第二位を四捨五入、カンマ区切りにして返却
 * @param {string | undefined} x
 * @returns 変換後の文字列
 */
const convertRoundOffCommaSeparatedNumber = (x: string | undefined) => Number(x).toLocaleString('ja-JP', { minimumFractionDigits: 1, maximumFractionDigits: 1 });

/**
 * フィルタアイテムリスト作成
 * @param {Set<string | undefined>} uniqueData 一意の設備情報
 * @returns フィルタアイテムリスト
 */
const createTableFilter = (uniqueData: Set<string | undefined>): ColumnFilterItem[] => {
  const filterItem: ColumnFilterItem[] = [];
  let text;
  uniqueData.forEach((elm) => {
    if (elm) {
      text = elm.replaceAll('<br>', ' ');
      filterItem.push(
        { text, value: String(elm) },
      );
    }
  });

  // ドロップダウン選択肢をソート
  return filterItem.sort((a, b) => {
    // 29行目のif分にてtextがnullになることはないためESLintの警告回避とする
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (a.text! > b.text!) {
      return 1;
    }
    return -1;
  });
};

/**
 * 数値フィルタアイテムリスト作成
 * @param {Set<string>} uniqueData 一意の設備情報
 * @param {boolean} round 四捨五入・小数点第一位要否
 * @returns フィルタアイテムリスト
 */
const createTableFilterByNumber = (uniqueData: Set<string>, round: boolean): ColumnFilterItem[] => {
  const filterItem: ColumnFilterItem[] = [];
  const sortedArray = Array.from(uniqueData).sort(getSortedNumberItems);
  sortedArray.forEach((elm) => {
    let value;
    if (round) {
      value = convertRoundOffCommaSeparatedNumber(elm);
    } else {
      value = Number(elm).toLocaleString();
    }
    filterItem.push(
      { text: value, value },
    );
  });
  return filterItem;
};

/**
 * フィルタドロップダウン作成
 * @param {FilterDropdownProps} ドロップダウンのproperty
 * @returns フィルタドロップダウン
 */
const createFilterDropdown = ({
  setSelectedKeys, selectedKeys, filters, confirm, clearFilters,
}: FilterDropdownProps): JSX.Element => (
  <>
    <Menu
      className="ant-dropdown-menu-without-submenu file-list-table"
      onClick={({ key }) => {
        let newSelectedKeys;
        if (selectedKeys && selectedKeys.indexOf(key) >= 0) {
          // 選択済みのデータはチェックを外す
          newSelectedKeys = selectedKeys.filter((selectedKey) => selectedKey !== key);
        } else if (selectedKeys) {
          newSelectedKeys = selectedKeys.concat([key]);
        } else {
          newSelectedKeys = [key];
        }
        const stringSelectedKeys = newSelectedKeys
          .map((newSelectedKey) => String(newSelectedKey));
        if (setSelectedKeys) setSelectedKeys(stringSelectedKeys);
      }}
    >
      {filters && filters.map((filter) => {
        const { text, value } = filter;
        const isSelected = selectedKeys && selectedKeys.indexOf(value) >= 0;
        return (
          <Menu.Item key={value} className="file-list-item">
            <Checkbox checked={isSelected} />
            <span>{text}</span>
          </Menu.Item>
        );
      })}
    </Menu>
    <div className="ant-table-filter-dropdown-btns">
      <Button
        className="filter-list-button"
        type="link"
        onClick={confirm}
        size="small"
      >
        OK
      </Button>
      <Button
        className="filter-list-button clear"
        type="link"
        onClick={clearFilters}
        size="small"
      >
        Reset
      </Button>
    </div>
  </>
);

/**
 * テキストフィルタ作成
 * @param {FilterDropdownProps} テキストフィルタのproperty
 * @returns テキストフィルタ
 */
const createTextFilterBox = ({
  setSelectedKeys, selectedKeys, confirm, clearFilters,
}: FilterDropdownProps) => (
  <div className="equipment-information-table-filter-dropdown">
    <Input
      className="equipment-information-table-input"
      value={selectedKeys ? selectedKeys[0] : undefined}
      onChange={(event) => {
        if (setSelectedKeys) {
          setSelectedKeys(event.target.value ? [event.target.value] : []);
        }
      }}
      onPressEnter={() => {
        if (confirm) {
          confirm();
        }
      }}
    />
    <Button
      className="equipment-information-table-search-button"
      type="primary"
      onClick={() => {
        if (confirm) {
          confirm();
        }
      }}
      icon="search"
      size="small"
    >
      検索
    </Button>
    <Button
      className="equipment-information-table-reset-button"
      onClick={() => {
        if (clearFilters) {
          clearFilters();
        }
      }}
      size="small"
    >
      リセット
    </Button>
  </div>
);

/**
 * 発電所カタログテーブル
 * @param {EquipmentInformationTableProps} props プロパティ
 * @returns 発電所カタログテーブル
 */
const EquipmentInformationTable: React.FC<EquipmentInformationTableProps> = (
  props: EquipmentInformationTableProps,
) => {
  const {
    loading,
    solarEquipments,
  } = props;

  const selectedRowKeys = useRef<number[]>([]);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [isDlButtonDisabled, setIsDlButtonDisabled] = useState<boolean>(true);

  const isInformationExisted = solarEquipments.length > 0;
  const columns: ColumnProps<SolarEquipmentInformation>[] = [
    {
      title: '発電所名',
      dataIndex: 'name',
      width: 260,
      fixed: 'left',
      filterIcon: getFilterIcon,
      filterDropdown: isInformationExisted
        ? createTextFilterBox
        : null,
      onFilter: (value, result) => (
        result.name ? result.name.toLowerCase().includes((value as string).toLowerCase()) : false
      ),
      sorter: (a, b) => getSortedItems(a.name, b.name),
      render: (_, item: SolarEquipmentInformation) => item.name?.split('<br>').map((elm, index) => {
        const uniqueKey = `name${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: '所在地',
      dataIndex: 'address',
      ellipsis: true,
      width: 300,
      filterIcon: getFilterIcon,
      filterDropdown: isInformationExisted
        ? createTextFilterBox
        : null,
      onFilter: (value, result) => (
        result.address ? result.address.toLowerCase().includes((value as string).toLowerCase()) : false
      ),
      sorter: (a, b) => getSortedItems(a.address, b.address),
      render: (_, item: SolarEquipmentInformation) => item.address?.split('<br>').map((elm, index) => {
        const uniqueKey = `address${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: '運転開始日',
      dataIndex: 'drivingStartDate',
      width: 200,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.drivingStartDate));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.drivingStartDate === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.drivingStartDate, b.drivingStartDate),
      render: (_, item: SolarEquipmentInformation) => item.drivingStartDate?.split('<br>').map((elm, index) => {
        const uniqueKey = `drivingStartDate${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: 'AM事業者',
      dataIndex: 'amBusinessOperator',
      ellipsis: true,
      width: 200,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.amBusinessOperator));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.amBusinessOperator === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.amBusinessOperator, b.amBusinessOperator),
      render: (_, item: SolarEquipmentInformation) => item.amBusinessOperator?.split('<br>').map((elm, index) => {
        const uniqueKey = `amBusinessOperator${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: 'O&M事業者',
      dataIndex: 'omBusinessOperator',
      ellipsis: true,
      width: 180,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.omBusinessOperator));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.omBusinessOperator === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.omBusinessOperator, b.omBusinessOperator),
      render: (_, item: SolarEquipmentInformation) => item.omBusinessOperator?.split('<br>').map((elm, index) => {
        const uniqueKey = `omBusinessOperator${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: '連系容量（kW）',
      dataIndex: 'continuousCapacity',
      ellipsis: true,
      width: 170,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string>();
        solarEquipments.forEach((elm) => {
          if (elm.continuousCapacity) { uniqueData.add(Number(elm.continuousCapacity).toFixed(1)); }
        });
        return createTableFilterByNumber(uniqueData, true);
      })(),
      onFilter: (value, result) => (result.continuousCapacity !== '' ? convertRoundOffCommaSeparatedNumber(result.continuousCapacity) === value : false),
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedNumberItems(a.continuousCapacity, b.continuousCapacity),
      render: (_, item: SolarEquipmentInformation) => {
        let value;
        if (item.continuousCapacity) { value = convertRoundOffCommaSeparatedNumber(item.continuousCapacity); }
        return (
          <Tooltip title={value}>
            {value}
            <br />
          </Tooltip>
        );
      },
    },
    {
      title: 'パネル容量（kW）',
      dataIndex: 'panelCapacity',
      width: 200,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string>();
        solarEquipments.forEach((elm) => {
          if (elm.panelCapacity) { uniqueData.add(Number(elm.panelCapacity).toFixed(1)); }
        });
        return createTableFilterByNumber(uniqueData, true);
      })(),
      onFilter: (value, result) => (result.panelCapacity !== '' ? convertRoundOffCommaSeparatedNumber(result.panelCapacity) === value : false),
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedNumberItems(a.panelCapacity, b.panelCapacity),
      render: (_, item: SolarEquipmentInformation) => {
        let value;
        if (item.panelCapacity) { value = convertRoundOffCommaSeparatedNumber(item.panelCapacity); }
        return (
          <Tooltip title={value}>
            {value}
            <br />
          </Tooltip>
        );
      },
    },
    {
      title: 'PCS会社名',
      dataIndex: 'pcsCompanyName',
      ellipsis: true,
      width: 180,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.pcsCompanyName));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.pcsCompanyName === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.pcsCompanyName, b.pcsCompanyName),
      render: (_, item: SolarEquipmentInformation) => item.pcsCompanyName?.split('<br>').map((elm, index) => {
        const uniqueKey = `pcsCompanyName${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: 'PCS型番',
      dataIndex: 'pcsModelNumber',
      ellipsis: true,
      width: 180,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.pcsModelNumber));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.pcsModelNumber === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.pcsModelNumber, b.pcsModelNumber),
      render: (_, item: SolarEquipmentInformation) => item.pcsModelNumber?.split('<br>').map((elm, index) => {
        const uniqueKey = `pcsModelNumber${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: 'PCS容量（kW）',
      dataIndex: 'pcsCapacity',
      width: 180,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.pcsCapacity));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.pcsCapacity === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.pcsCapacity, b.pcsCapacity),
      render: (_, item: SolarEquipmentInformation) => item.pcsCapacity?.split('<br>').map((elm, index) => {
        const uniqueKey = `pcsCapacity${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: 'PCS台数',
      dataIndex: 'pcsCount',
      width: 150,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string>();
        solarEquipments.forEach((elm) => {
          if (elm.pcsCount) { uniqueData.add(elm.pcsCount); }
        });
        return createTableFilterByNumber(uniqueData, false);
      })(),
      onFilter: (value, result) => Number(result.pcsCount).toLocaleString() === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedNumberItems(a.pcsCount, b.pcsCount),
      render: (_, item: SolarEquipmentInformation) => {
        let value;
        if (item.pcsCount) { value = Number(item.pcsCount).toLocaleString(); }
        return (
          <Tooltip title={value}>
            {value}
            <br />
          </Tooltip>
        );
      },
    },
    {
      title: 'パネル会社名',
      dataIndex: 'panelCompanyName',
      ellipsis: true,
      width: 180,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.panelCompanyName));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.panelCompanyName === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.panelCompanyName, b.panelCompanyName),
      render: (_, item: SolarEquipmentInformation) => item.panelCompanyName?.split('<br>').map((elm, index) => {
        const uniqueKey = `panelCompanyName${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: 'パネル型番',
      dataIndex: 'panelModelNumber',
      ellipsis: true,
      width: 180,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.panelModelNumber));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.panelModelNumber === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.panelModelNumber, b.panelModelNumber),
      render: (_, item: SolarEquipmentInformation) => item.panelModelNumber?.split('<br>').map((elm, index) => {
        const uniqueKey = `panelModelNumber${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: 'パネル単一容量（W）',
      dataIndex: 'panelSingleCapacity',
      width: 210,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.panelSingleCapacity));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.panelSingleCapacity === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.panelSingleCapacity, b.panelSingleCapacity),
      render: (_, item: SolarEquipmentInformation) => item.panelSingleCapacity?.split('<br>').map((elm, index) => {
        const uniqueKey = `panelSingleCapacity${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: 'パネル枚数',
      dataIndex: 'panelNumber',
      width: 160,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string>();
        solarEquipments.forEach((elm) => {
          if (elm.panelNumber) { uniqueData.add(elm.panelNumber); }
        });
        return createTableFilterByNumber(uniqueData, false);
      })(),
      onFilter: (value, result) => Number(result.panelNumber).toLocaleString() === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedNumberItems(a.panelNumber, b.panelNumber),
      render: (_, item: SolarEquipmentInformation) => {
        let value;
        if (item.panelNumber) { value = Number(item.panelNumber).toLocaleString(); }
        return (
          <Tooltip title={value}>
            {value}
            <br />
          </Tooltip>
        );
      },
    },
    {
      title: '架台（段数、角度、部材）',
      dataIndex: 'frame',
      ellipsis: true,
      width: 230,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.frame));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.frame === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.frame, b.frame),
      render: (_, item: SolarEquipmentInformation) => item.frame?.split('<br>').map((elm, index) => {
        const uniqueKey = `frame${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: '供給先',
      dataIndex: 'supply',
      ellipsis: true,
      width: 280,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.supply));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.supply === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.supply, b.supply),
      render: (_, item: SolarEquipmentInformation) => item.supply?.split('<br>').map((elm, index) => {
        const uniqueKey = `supply${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
    {
      title: '売電単価（円）',
      dataIndex: 'electricPrice',
      width: 170,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string>();
        solarEquipments.forEach((elm) => {
          if (elm.electricPrice) { uniqueData.add(elm.electricPrice); }
        });
        return createTableFilterByNumber(uniqueData, false);
      })(),
      onFilter: (value, result) => Number(result.electricPrice).toLocaleString() === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedNumberItems(a.electricPrice, b.electricPrice),
      render: (_, item: SolarEquipmentInformation) => {
        let value;
        if (item.electricPrice) { value = Number(item.electricPrice).toLocaleString(); }
        return (
          <Tooltip title={value}>
            {value}
            <br />
          </Tooltip>
        );
      },
    },
    {
      title: '投資意思決定単位',
      dataIndex: 'investmentUnit',
      ellipsis: true,
      width: 300,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string | undefined>();
        solarEquipments.forEach((elm) => uniqueData
          .add(elm?.investmentUnit));
        return createTableFilter(uniqueData);
      })(),
      onFilter: (value, result) => result.investmentUnit === value,
      filterDropdown: isInformationExisted
        ? createFilterDropdown
        : null,
      sorter: (a, b) => getSortedItems(a.investmentUnit, b.investmentUnit),
      render: (_, item: SolarEquipmentInformation) => item.investmentUnit?.split('<br>').map((elm, index) => {
        const uniqueKey = `investmentUnit${index}`;
        return (
          <Tooltip title={elm} key={uniqueKey}>
            {elm}
            <br />
          </Tooltip>
        );
      }),
    },
  ];

  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        SOLAR_MENU: {
          EQUIPMENT_INFORMATION: {
            EQUIPMENT_INFORMATION_DOWNLOAD_BUTTON,
          },
        },
      } = AUTHENTICATION_TYPE_MATRIX;

      if (!canceled) {
        setIsDlButtonDisabled(!await containsUIAuthType(EQUIPMENT_INFORMATION_DOWNLOAD_BUTTON));
      }
    })();
    return () => { canceled = true; };
  }, []);

  /**
   * チェックボックスクリック時のイベントハンドラ
   */
  const rowSelection = {
    onChange: (selectedRowKey: React.Key[]) => {
      selectedRowKeys.current = selectedRowKey as number[];
    },
  };

  /**
   * ダウンロードボタンクリック時のイベントハンドラ
   */
  const onClickDownloadButton = () => {
    setDownloading(true);
    if (selectedRowKeys.current.length === 0) {
      message.error(VALIDATE_ERROR_UNSELECTED_EQUIPMENT);
    } else {
      // チェックされた設備一覧の配列を生成
      const selectedSolarEquipments = solarEquipments.filter((elm) => selectedRowKeys.current.includes(elm.id));

      // 仕様上、必ずtitle, dataIndexは定義している。
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const header = columns.map(({ title, dataIndex }) => ({ key: dataIndex!, headerString: title!.toString() }));
      const csvData = generateCsvData(header, selectedSolarEquipments);

      const aTagForDownload = document.createElement('a');
      let url = '';
      try {
        url = URL.createObjectURL(csvData);
        document.body.appendChild(aTagForDownload);
        aTagForDownload.href = url;
        aTagForDownload.download = `SDF_発電所カタログ_${moment().format('YYYYMMDDHHmmss')}.csv`;
        aTagForDownload.click();
      } catch (e) {
        message.error(ERROR_FILE_DOWNLOAD);
      } finally {
        aTagForDownload.remove();
        URL.revokeObjectURL(url);
      }
    }
    setDownloading(false);
  };

  return (
    <div style={{ margin: '10px' }}>
      <div>
        <Col style={{ textAlign: 'right' }}>
          <Tooltip title={isDlButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
            <Button
              type="primary"
              onClick={onClickDownloadButton}
              style={{ marginBottom: 10, marginRight: 10 }}
              loading={downloading}
              disabled={loading || isDlButtonDisabled}
            >
              ダウンロード
            </Button>
          </Tooltip>
        </Col>
        <Table<SolarEquipmentInformation>
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={solarEquipments}
          pagination={false}
          scroll={{ x: 1650, y: 900 }}
          rowSelection={rowSelection}
        />
      </div>
    </div>
  );
};

export default EquipmentInformationTable;
