import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

// 発電量ステータス
export const ELECTRIC_ENERGY_STATUS = [
  { displayName: '発電量不足', value: 10 },
  { displayName: '発電量異常', value: 20 },
  { displayName: '発電量欠損', value: 30 },
];

interface ElectricEnergyStatusSelectProps {
  onChange: (value?: number) => void;
}

/**
 * 発電量ステータス一覧 ステータスセレクトボックス
 * @param {ElectricEnergyStatusSelectProps} props プロパティ
 * @returns ステータスセレクトボックス
 */
const ElectricEnergyStatusSelect: React.FC<ElectricEnergyStatusSelectProps> = (
  props: ElectricEnergyStatusSelectProps,
) => {
  const selectOptions = ELECTRIC_ENERGY_STATUS.map((status) => (
    <Option key={status.value} value={status.value}>{status.displayName}</Option>
  ));

  return (
    <Select style={{ width: 150 }} onChange={(value: number) => props.onChange(value)} allowClear>
      {selectOptions}
    </Select>
  );
};

export default ElectricEnergyStatusSelect;
