import {
  FileFilterProps,
  FileRequestFilter,
  IdEither,
  FileInfo,
  ItemsWrapper,
  CursorResponse,
} from '@cognite/sdk';
import { postApiGateway, deleteApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_SOLAR_FILES, EP_PATH_SOLAR_FILES_LIST } from '../AWS/EndpointPath';

/**
 * 財務帳票重複ファイル削除
 * @param {IdEither[]} deleteId 削除対象ID
 */
export const deleteDuplicateUploadFile = async (
  deleteId: number,
): Promise<void> => {
  await deleteApiGateway<ItemsWrapper<IdEither[]>>(
    EP_PATH_SOLAR_FILES, { items: [{ id: deleteId }] },
  );
};

/**
 * 財務帳票重複ファイル検索
 * @param {FileFilterProps} filter 検索条件
 * @return {ListResponse<FileInfo[]>} 検索条件に合ったファイル情報
 */
export const searchDuplicateUploadFile = async (
  filter: FileFilterProps,
): Promise<CursorResponse<FileInfo[]>> => {
  const files = await postApiGateway<
    FileRequestFilter,
    CursorResponse<FileInfo[]>
  >(EP_PATH_SOLAR_FILES_LIST, { filter });

  return files;
};
