import React, { useState, useEffect } from 'react';
import { EventFilter, EventFilterRequest } from '@cognite/sdk';
import {
  Typography, Divider, Row, Col, Descriptions, Button, message, Tooltip,
} from 'antd';
import moment from 'moment';
import SearchedPcsEventTable from './SearchedPcsEventTable';
import ElectricEnergyOccurrenceDates from './parts/ElectricEnergyOccurrenceDates';
import OMStatusSelect, { OM_STATUS } from './parts/OMStatusSelect';
import SiteCascader, { SiteCascaderSelect } from './parts/SiteCascader';
import {
  ERROR_NOT_ENTERED_SEARCH_CONDITION,
  ERROR_SEARCH_RESULT_OVER_LIMIT,
  ERROR_NO_AUTH_MESSAGE,
  ERROR_FILE_DOWNLOAD,
  INFORMATION_NO_SEARCH_RESULTS,
} from '../../utils/messages';
import SolarSite from '../../utils/Asset/SolarSite';
import PcsComparisonStatus from '../../utils/Event/PcsComparisonStatus';
import { generateCsvData } from '../../utils/File/CsvFile';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import './PcsComparisonHistory.css';

const { Title } = Typography;

/**
 * PCS比較診断一覧画面
 */
const PcsComparisonHistory: React.FC = () => {
  const [occurrenceDates, setOccurrenceDates] = useState<[moment.Moment, moment.Moment] | []>([]);
  const [solarSite, setSolarSite] = useState<SolarSite | undefined>();
  const [status, setStatus] = useState<number | undefined>();
  const [
    pcsComparisonStatuses, setPcsComparisonStatuses,
  ] = useState<PcsComparisonStatus[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState<boolean>(true);
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        PCS_COMPARISON_HISTORY: { PCS_COMPARISON_HISTORY_SEARCH_BUTTON, PCS_COMPARISON_HISTORY_DOWNLOAD_BUTTON },
      } = AUTHENTICATION_TYPE_MATRIX;

      if (!canceled) {
        setIsSearchButtonDisabled(!await containsUIAuthType(PCS_COMPARISON_HISTORY_SEARCH_BUTTON));
        setIsDownloadButtonDisabled(!await containsUIAuthType(PCS_COMPARISON_HISTORY_DOWNLOAD_BUTTON));
      }
    })();

    return () => { canceled = true; };
  }, []);

  /**
   * 診断結果の表示名変換
   * @param {string} statusNo 診断結果番号
   * @return {string} 診断結果名
   */
  const statusChange = (statusNo: string): string => {
    const result = OM_STATUS.find((el) => String(el.value) === statusNo);
    return result ? result.displayName : '';
  };

  /**
   * 診断日時選択時のイベントハンドラ
   * @param {[] | [moment.Moment, moment.Moment]} dates 選択した診断日時
   */
  const handleChangeOccurrenceDates = (dates: [] | [moment.Moment, moment.Moment]): void => {
    setOccurrenceDates(dates);
  };

  /**
   * サイト選択時のイベントハンドラ
   * @param {SiteCascaderSelect | undefined} selectedValues 選択したサイト情報
   */
  const handleChangeSite = (selectedValues?: SiteCascaderSelect): void => {
    if (!selectedValues) {
      setSolarSite(selectedValues);
    } else {
      const value = new SolarSite(selectedValues.site);
      setSolarSite(value);
    }
  };

  /**
   * 診断結果選択時のイベントハンドラ
   * @param {number | undefined} value 選択した診断結果
   */
  const handleChangeStatus = (value?: number): void => {
    setStatus(value);
  };

  /**
   * PCS診断結果検索オブジェクト作成
   * @returns {EventFilterRequest} PCS診断結果検索オブジェクト
   */
  const createEventFilterRequest = (): EventFilterRequest => {
    const filter: EventFilter = {
      type: 'pcs_comparison_status_history',
    };
    if (occurrenceDates.length !== 0) {
      const [startTime, endTime] = occurrenceDates;
      filter.startTime = {
        min: startTime?.valueOf(),
        max: endTime?.valueOf(),
      };
    }
    if (solarSite) {
      filter.assetIds = [solarSite.id];
    }

    if (status) {
      filter.metadata = { status: String(status) };
    }

    // CogniteSDKとAPIで差分があるためasを用いてキャスト
    const eventFilterRequest = {
      filter,
      sort: [
        // CDFの仕様上、第二ソートまでしか設定できない
        { property: ['startTime'], order: 'desc' },
        { property: ['metadata', 'site_name_jp'], order: 'asc' },
      ],
      limit: 1000,
    } as EventFilterRequest;

    return eventFilterRequest;
  };

  /**
   * 文字列項目のソート判定を実施する
   * @param {string | undefined} a 文字列項目A
   * @param {string | undefined} b 文字列項目B
   * @returns {number} ソートの判定結果
   */
  const sortStringItems = (a?: string, b?: string) => {
    const first = [a, b].sort()[0];
    return first === a ? -1 : 1;
  };

  /**
   * PCS比較診断結果をソートする。
   * 1. 診断日時: 降順
   * 2. サイト名: 昇順
   * 3. PCS番号: 昇順
   * 4. 診断結果: 昇順
   * @param {PcsComparisonStatus[]} sortTargetPcsComparisonStatuses ソート対象のPCS比較診断結果リスト
   * @returns {PcsComparisonStatus[]} ソート後のPCS比較診断結果リスト
   */
  const sortPcsComparisonStatus = (
    sortTargetPcsComparisonStatuses: PcsComparisonStatus[],
  ): PcsComparisonStatus[] => {
    const sortedPcsComparisonStatuses = [...sortTargetPcsComparisonStatuses];
    sortedPcsComparisonStatuses.sort((a, b) => {
      const startDateA = a.dateForDisplay;
      const startDateB = b.dateForDisplay;

      if (!startDateA) {
        return 1;
      }
      if (!startDateB) {
        return -1;
      }
      if (startDateA !== startDateB) {
        return sortStringItems(startDateB, startDateA);
      }

      // 登録時に必ず設定される
      if (a.siteName !== b.siteName) {
        return a.siteName.localeCompare(b.siteName);
      }

      if (a.pcsNo !== b.pcsNo) {
        return Number(a.pcsNo) - Number(b.pcsNo);
      }

      return Number(a.status) - Number(b.status);
    });

    return sortedPcsComparisonStatuses;
  };

  /**
   * 検索ボタンクリック時のイベントハンドラ
   */
  const handleClickSearch = async (): Promise<void> => {
    setLoading(true);
    if (occurrenceDates.length === 0 && !solarSite && !status) {
      message.error(ERROR_NOT_ENTERED_SEARCH_CONDITION);
      setLoading(false);
      return;
    }
    const eventFilterRequest = createEventFilterRequest();
    const loadPcsComparisonStatuses = await PcsComparisonStatus.loadPcsComparisonByFilter(
      eventFilterRequest,
    );

    // 検索結果がない場合はメッセージを表示
    if (loadPcsComparisonStatuses.items.length === 0) {
      message.info(INFORMATION_NO_SEARCH_RESULTS);
      setPcsComparisonStatuses(undefined);
      setLoading(false);
      return;
    }

    if (loadPcsComparisonStatuses.nextCursor) {
      message.error(ERROR_SEARCH_RESULT_OVER_LIMIT);
      setLoading(false);
      return;
    }
    const sortedPcsComparisonStatuses = sortPcsComparisonStatus(loadPcsComparisonStatuses.items);

    setPcsComparisonStatuses(sortedPcsComparisonStatuses);
    setLoading(false);
  };

  /**
   * ダウンロードボタンクリック時のイベントハンドラ
   */
  const handleClickDownload = async (): Promise<void> => {
    setDownloading(true);

    // 検索条件不備の場合はエラーメッセージを表示しダウンロードしない
    if (occurrenceDates.length === 0 && !solarSite && !status) {
      message.error(ERROR_NOT_ENTERED_SEARCH_CONDITION);
      setDownloading(false);
      return;
    }

    const eventFilterRequest = createEventFilterRequest();
    const loadPcsComparisonStatuses = await PcsComparisonStatus.loadAllPcsComparisonByFilter(
      eventFilterRequest,
    );

    // 検索結果がない場合はメッセージを表示しダウンロードしない
    if (loadPcsComparisonStatuses.length === 0) {
      message.info(INFORMATION_NO_SEARCH_RESULTS);
      setDownloading(false);
      return;
    }

    const sortedPcsComparisonStatuses = sortPcsComparisonStatus(loadPcsComparisonStatuses);
    const pcsComparisonStatusesBody = sortedPcsComparisonStatuses.map((item) => {
      const statusArr = {
        ...item,
        metadata: {
          ...item.metadata,
          status: item.metadata ? statusChange(item.metadata.status) : '',
        },
      };
      return new PcsComparisonStatus(statusArr);
    });

    const header = [
      { key: 'dateForDisplay', headerString: '診断日時' },
      { key: 'siteName', headerString: 'サイト名' },
      { key: 'pcsNo', headerString: 'PCS番号' },
      { key: 'status', headerString: '診断結果' },
    ];

    const csvData = generateCsvData(header, pcsComparisonStatusesBody);
    const aTagForDownload = document.createElement('a');
    let url = '';
    try {
      url = URL.createObjectURL(csvData);
      document.body.appendChild(aTagForDownload);
      aTagForDownload.href = url;
      aTagForDownload.download = `SDF_PCS比較診断結果_${moment().format('YYYYMMDDHHmmss')}.csv`;
      aTagForDownload.click();
    } catch (e) {
      message.error(ERROR_FILE_DOWNLOAD);
    } finally {
      aTagForDownload.remove();
      URL.revokeObjectURL(url);
    }
    setDownloading(false);
  };

  return (
    <div className="pcs-comparison-history-container">
      <div className="pcs-comparison-history-title">
        <Title level={4}>PCS比較診断</Title>
      </div>

      <Divider className="pcs-comparison-history-divider" />

      <Row>
        <Col span={20} offset={1}>
          <Descriptions bordered>
            <Descriptions.Item label="診断日時" span={3}>
              <ElectricEnergyOccurrenceDates onChange={handleChangeOccurrenceDates} />
            </Descriptions.Item>
            <Descriptions.Item label="サイト名" span={3}>
              <SiteCascader
                onChange={handleChangeSite}
              />
            </Descriptions.Item>
            <Descriptions.Item label="診断結果" span={3}>
              <OMStatusSelect onChange={handleChangeStatus} />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={17} offset={1}>
          <span className="pcs-comparison-history-annotation">*</span>
          検索条件は1つ以上選択してください。
        </Col>
        <Col span={4}>
          <Tooltip title={isSearchButtonDisabled ? ERROR_NO_AUTH_MESSAGE : '検索'}>
            <Button
              type="primary"
              id="pcs-comparison-list-search-button"
              onClick={handleClickSearch}
              style={{ marginTop: 16, marginBottom: 16 }}
              loading={loading}
              disabled={isSearchButtonDisabled}
            >
              検索
            </Button>
          </Tooltip>
          <Tooltip title={isDownloadButtonDisabled ? ERROR_NO_AUTH_MESSAGE : 'ダウンロード'}>
            <Button
              type="primary"
              id="pcs-comparison-list-download-button"
              onClick={handleClickDownload}
              style={{ marginLeft: 15 }}
              loading={downloading}
              disabled={downloading || isDownloadButtonDisabled}
            >
              ダウンロード
            </Button>
          </Tooltip>
        </Col>
      </Row>
      {pcsComparisonStatuses && (
        <Row>
          <SearchedPcsEventTable loading={loading} searchedPcsEventList={pcsComparisonStatuses} />
        </Row>
      )}
    </div>
  );
};

export default PcsComparisonHistory;
