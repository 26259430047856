import React from 'react';
import {
  AggregateResponse,
  AssetAggregateQuery,
  TimeseriesAggregateQuery,
  FileAggregateQuery,
  EventAggregateQuery,
  ItemsResponse,
} from '@cognite/sdk';
import {
  Spin,
  Statistic,
  message,
} from 'antd';
import {
  HomeOutlined,
  LineChartOutlined,
  FileOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';

import {
  EP_PATH_ASSET_AGGREGATE,
  EP_PATH_EVENTS_AGGREGATE,
  EP_PATH_FILES_AGGREGATE,
  EP_PATH_TIMESERIES_AGGREGATE,
} from '../../utils/AWS/EndpointPath';
import {
  postApiGateway,
} from '../../utils/AWS/ApiGateway';

import './DataTotalNumber.css';

const DataTotalNumber = (): JSX.Element => {
  /*
   * 変数/定数定義
   */
  const [countingAssets, setCountingAssets] = React.useState<boolean>(false);
  const [assetsNumber, setAssetsNumber] = React.useState<string | number>(0);

  const [countingTimeseries, setCountingTimeseries] = React.useState<boolean>(false);
  const [timeseriesNumber, setTimeseriesNumber] = React.useState<string | number>(0);

  const [countingFiles, setCountingFiles] = React.useState<boolean>(false);
  const [filesNumber, setFilesNumber] = React.useState<string | number>(0);

  const [countingEvents, setCountingEvents] = React.useState<boolean>(false);
  const [eventsNumber, setEventsNumber] = React.useState<string | number>(0);

  /*
   * イベントハンドラ
   */
  React.useEffect(() => {
    let canceled = false;

    setCountingAssets(true);
    (async () => {
      try {
        const filter: AssetAggregateQuery = {
          filter: {},
        };
        const aggregates = await postApiGateway<
          AssetAggregateQuery, ItemsResponse<AggregateResponse>>(
            EP_PATH_ASSET_AGGREGATE, filter,
          );
        if (!canceled) {
          setAssetsNumber(aggregates.items[0].count);
        }
      } catch (exception) {
        message.error('アセット数のカウントに失敗しました。');
        if (!canceled) {
          setAssetsNumber('カウント失敗');
        }
      } finally {
        if (!canceled) {
          setCountingAssets(false);
        }
      }
    })();

    setCountingTimeseries(true);
    (async () => {
      try {
        const filter: TimeseriesAggregateQuery = {
          filter: {},
        };
        const aggregates = await postApiGateway<
          TimeseriesAggregateQuery, ItemsResponse<AggregateResponse>>(
            EP_PATH_TIMESERIES_AGGREGATE, filter,
          );
        if (!canceled) {
          setTimeseriesNumber(aggregates.items[0].count);
        }
      } catch (exception) {
        message.error('タイムシリーズ数のカウントに失敗しました。');
        if (!canceled) {
          setTimeseriesNumber('カウント失敗');
        }
      } finally {
        if (!canceled) {
          setCountingTimeseries(false);
        }
      }
    })();

    setCountingFiles(true);
    (async () => {
      try {
        const filter: FileAggregateQuery = {
          filter: {},
        };
        const aggregates = await postApiGateway<
          FileAggregateQuery, ItemsResponse<AggregateResponse>>(
            EP_PATH_FILES_AGGREGATE, filter,
          );
        if (!canceled) {
          setFilesNumber(aggregates.items[0].count);
        }
      } catch (exception) {
        message.error('ファイル数のカウントに失敗しました。');
        if (!canceled) {
          setFilesNumber('カウント失敗');
        }
      } finally {
        if (!canceled) {
          setCountingFiles(false);
        }
      }
    })();

    setCountingEvents(true);
    (async () => {
      try {
        const filter: EventAggregateQuery = {
          filter: {},
        };
        const aggregates = await postApiGateway<
          EventAggregateQuery, ItemsResponse<AggregateResponse>>(
            EP_PATH_EVENTS_AGGREGATE, filter,
          );
        if (!canceled) {
          setEventsNumber(aggregates.items[0].count);
        }
      } catch (exception) {
        message.error('イベント数のカウントに失敗しました。');
        if (!canceled) {
          setEventsNumber('カウント失敗');
        }
      } finally {
        if (!canceled) {
          setCountingEvents(false);
        }
      }
    })();

    return () => {
      canceled = true;
    };
  }, []);

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  return (
    <>
      <div>
        <h1>
          <b className="total-number-title">データ総数</b>
        </h1>
      </div>
      <div className="total-number-wrap">
        <Spin spinning={countingAssets}>
          <Statistic
            title="アセット"
            value={assetsNumber}
            prefix={<HomeOutlined />}
            className="total-number"
          />
        </Spin>
        <Spin spinning={countingTimeseries}>
          <Statistic
            title="タイムシリーズ"
            value={timeseriesNumber}
            prefix={<LineChartOutlined />}
            className="total-number"
          />
        </Spin>
        <Spin spinning={countingFiles}>
          <Statistic
            title="ファイル"
            value={filesNumber}
            prefix={<FileOutlined />}
            className="total-number"
          />
        </Spin>
        <Spin spinning={countingEvents}>
          <Statistic
            title="イベント"
            value={eventsNumber}
            prefix={<ScheduleOutlined />}
            className="total-number"
          />
        </Spin>
      </div>
    </>
  );
};

export default DataTotalNumber;
