import React, { useState, useEffect } from 'react';
import { Typography, Divider } from 'antd';

import EquipmentInformationTable from './EquipmentInformationTable';
import SolarEquipmentInformation from '../../utils/Event/SolarEquipmentInformation';
import './EquipmentInformation.css';

const { Title } = Typography;

type LoadEquipments = {
  loading: boolean,
  solarEquipments: SolarEquipmentInformation[],
};

/**
 * 設備情報一覧画面
 * @returns 設備情報一覧画面
 */
const EquipmentInformation: React.FC = () => {
  const [
    loadEquipments, setLoadEquipments,
  ] = useState<LoadEquipments>({ loading: true, solarEquipments: [] });

  useEffect(() => {
    let canceled = false;
    (async () => {
      // 設備情報一覧の取得
      const loadSolarEquipments = await SolarEquipmentInformation.loadEquipmentInformationByFilter();

      if (!canceled) {
        setLoadEquipments({
          loading: false,
          solarEquipments: loadSolarEquipments,
        });
      }
    })();

    return () => { canceled = true; };
  }, []);

  const { loading, solarEquipments } = loadEquipments;
  return (
    <div className="equipment-information-container">
      <div className="equipment-information-title">
        <Title level={4}>発電所カタログ</Title>
      </div>

      <Divider className="equipment-information-divider" />

      <EquipmentInformationTable
        loading={loading}
        solarEquipments={solarEquipments}
      />
    </div>
  );
};

export default EquipmentInformation;
