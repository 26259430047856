import {
  ExternalDatapoint,
  ExternalDatapointsQuery,
  ItemsWrapper,
  ItemsResponse,
  ExternalEvent,
  CogniteEvent,
  Metadata,
  EventChange,
} from '@cognite/sdk';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { EmptyResponse, putApiGateway, postApiGateway } from '../../../utils/AWS/ApiGateway';
import {
  EP_PATH_SOLAR_TIMESERIES_DATAPOINTS,
  EP_PATH_SOLAR_PROCESSES_PRESUMED_ELECTRIC_ENERGY,
  EP_PATH_SOLAR_EVENTS,
  EP_PATH_SOLAR_EVENTS_LIST,
} from '../../../utils/AWS/EndpointPath';
import { sleep } from '../../../utils/common';
import {
  VALIDATIONS_CHECK_ERROR,
  VALIDATIONS_LIMIT_CHECK_ERROR,
  VALIDATIONS_NOT_EXISTS_MANAGEMENT_NO,
  VALIDATIONS_ENTERED_MANAGEMENT_NO_CHECK_ERROR,
  ERROR_FILES_UPLOAD,
} from '../../../utils/messages';
import { getParentBusinessAssetsOfSolarSite } from '../../../utils/Asset/SolarSiteAsset';
import SolarSite from '../../../utils/Asset/SolarSite';
import LostEnergyInformation from '../../../utils/Event/LostEnergyInformation';
import LostEnergyFiscalYearInformation from '../../../utils/Event/LostEnergyFiscalYearInformation';
import { eventRetrieve } from '../../../utils/dataAccess';

interface RegisterPresumedElectricEnergyResult {
  error: boolean;
  message?: string | string[];
}
// interfaceを実装する時にはインターフェースで定義されているすべてのプロパティに値を設定する必要がある
/**
* siteName（発電所名）の型はstring；
* fiscalYear（登録年度）の型はnumber又はstring；
*/
interface PresumedElectricEnergyParam {
  siteName: string;
  fiscalYear: number | string;
}

interface MonthlyPresumedElectricEnergy {
  siteId: number;
  externalId: string | undefined;
  dataSetId: number | undefined;
  year: number;
  month: number;
  value: string | number;
}

interface YearlyPresumedElectricEnergy {
  siteId: number;
  dataSetId: number | undefined;
  metadata: Metadata;
}

/** 計画発電量ファイルフォーマット定義 */
const PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS = {
  ROW: { START: 6, END: 205 },
  COLUMNS: { START: 67, END: 78 }, // C列 ～ N列
  FISCAL_YEAR: 'B4', // 登録年度
  DATA_ARIA: 'A6:N205',
};

/**
 * 計画発電量登録シートの必須（登録年度）項目のバリデーション
 * 登録年度は後続処理に影響があるため型および数値チェックも実施
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns 必須項目のバリデーション結果
 */
const checkRequiredValidity = (sheet: XLSX.Sheet): boolean => {
  const fiscalYear = sheet[PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS.FISCAL_YEAR];

  // データポイントのタイムスタンプ出力時に影響があるので、登録年度の値が1970以上の整数であるかも確認
  const isFiscalYearValid = fiscalYear && Number.isInteger(fiscalYear.v) && fiscalYear.v >= 1970;
  return !!isFiscalYearValid;
};

/**
 * 計画発電量登録シートの登録データ項目のバリデーション
 * 値が記入されている月のデータ（セルC5〜N205）に対して型チェックを実施
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns 登録データ項目のバリデーション結果
 */
const checkDataValidity = (sheet: XLSX.Sheet): boolean => {
  const { ROW, COLUMNS } = PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS;
  for (let rowNumber = ROW.START; rowNumber <= ROW.END; rowNumber++) {
    for (let charCode = COLUMNS.START; charCode <= COLUMNS.END; charCode++) {
      const cell = `${String.fromCharCode(charCode)}${rowNumber}`;
      // 'n'number
      if (sheet[cell] && sheet[cell].t !== 'n') return false;
    }
  }
  return true;
};

/**
 * 計画発電量シートのデータ件数バリデーション
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns データ件数のバリデーション結果
 */
const checkDataLimitValidity = (sheet: XLSX.Sheet): boolean => {
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, number>>(sheet);
  const lastRowData = sheetJson.pop();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, no-underscore-dangle
  return lastRowData!.__rowNum__ <= (PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS.ROW.END - 1);
};

/**
 * 計画発電量シートの管理No.未入力バリデーション
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns 管理No.未入力のバリデーション結果
 */
const checkDataManagementNoValidity = (sheet: XLSX.Sheet): boolean => {
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, string>>(sheet);
  return !(sheetJson.some((record) => !record.計画発電量登録シート));
};

/**
 * 計画発電量登録シートのバリデーション
 * 基本情報（登録年度）項目および登録データ項目のチェック結果が両方OKかを確認
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns {RegisterPresumedElectricEnergyResult} バリデーションチェック結果、メッセージ
 */
const checkSheetValidity = (sheet: XLSX.Sheet): RegisterPresumedElectricEnergyResult => {
  if (!sheet) return { error: true, message: VALIDATIONS_CHECK_ERROR };
  const isInfoValid = checkRequiredValidity(sheet);
  const isDataValid = checkDataValidity(sheet);

  if (!checkDataLimitValidity(sheet)) {
    return { error: true, message: VALIDATIONS_LIMIT_CHECK_ERROR };
  }
  if (!checkDataManagementNoValidity(sheet)) {
    return { error: true, message: VALIDATIONS_ENTERED_MANAGEMENT_NO_CHECK_ERROR };
  }
  if (!(isInfoValid && isDataValid)) {
    return { error: true, message: VALIDATIONS_CHECK_ERROR };
  }
  return { error: false };
};

/**
 * 計画発電量シートの管理Noバリデーション
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @param {SolarSite[]} solarSites 管理Noをmetadataに持つ太陽光アセットリスト
 * @returns データ記入用シートにのみ存在する管理No（すべて存在する場合、空の配列を返却）
 */
const checkManagementNoValidity = async (sheet: XLSX.Sheet, solarSites: SolarSite[]): Promise<string[]> => {
  // 管理番号をmetadataに持つAssetから管理番号のみ抽出
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const managementNos = solarSites.map((solarSite) => solarSite.metadata!.managementNo);
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, string>>(
    sheet, { header: ['managementNo'] },
  );

  // アップロードされたファイルにのみ存在する管理番号を抽出
  const onlyInputExistsManagementNo = sheetJson
    .map((sheetJsonItem) => sheetJsonItem.managementNo)
    .filter((managementNo) => !managementNos.includes(managementNo));

  return onlyInputExistsManagementNo;
};

/**
 * 登録年度の各月初日の日付を一覧で取得
 * 登録データの抽出とデータポイントへの変換時に使用
 * @param {number} year 登録年度
 * @returns 登録年度の各月初日の日付
 */
const createHeaderDates = (year: number): string[] => {
  const headerDates: string[] = [];
  headerDates.push('managementNo');
  headerDates.push('siteName');
  for (let i = 4; i <= 15; i++) {
    const yearString = (i > 12 ? year + 1 : year).toString();
    const monthValue = i > 12 ? i - 12 : i;
    const monthString = monthValue < 10 ? `0${monthValue}` : monthValue.toString();
    headerDates.push(`${yearString}-${monthString}-01T00:00:00.000`);
  }
  return headerDates;
};

/**
 * アップロードされた登録データ記入用シートからデータを抽出し、データポイントに変換する
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @param {string[]} headerDates 登録年度の各月初日の日付一覧（データ読み込みとタイムスタンプの変換に使用）
 * @param {SolarSite[]} solarSites 管理Noをmetadataに持つ太陽光アセットリスト
 * @returns 太陽光サイトごとの記入データを変換したデータポイントの配列
 */
const convertFileDataToDatapoints = (
  sheet: XLSX.Sheet,
  headerDates: string[],
  solarSites: SolarSite[],
): { solarSite: SolarSite, datapoints: ExternalDatapoint[] }[] => {
  // シート記載の登録データの値を抽出し、データが1件でもあればデータポイントへの変換を実施
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, string | number>>(
    sheet, { header: headerDates },
  );

  const solarSiteWithDatapoints = sheetJson.map((json) => {
    const { managementNo } = json;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [solarSite] = solarSites.filter((s) => s.metadata!.managementNo === managementNo);

    const datapoints = Object.entries(json)
      .filter(([key]) => !['managementNo', 'siteName'].includes(key)) // 0: managementNo, 1: siteName以外を取り出す
      .map((predictedData) => {
        const timestamp = new Date(predictedData[0]).valueOf(); // valueOfメソッドを使用して、string型に交換する
        const value = predictedData[1];
        return { timestamp, value };
      });

    return { solarSite, datapoints };
  });

  return solarSiteWithDatapoints;
};

/**
 * Excelに入力された計画発電情報を逸失発電情報(月別)更新情報に変換する
 * @param {MonthlyPresumedElectricEnergy[]} monthlyPresumedElectricEnergies 月別計画発電量
 * @param {CogniteEvent[]} registeredLostEnergyItems 登録済み逸失発電情報(月別)
 * @returns 逸失発電情報(月別)更新情報
 */
const convertMonthlyPresumedElectricEnergyToEventChange = (monthlyPresumedElectricEnergies: MonthlyPresumedElectricEnergy[], registeredLostEnergyItems: CogniteEvent[]): EventChange[] => {
  const monthlyEventUpdateItems = monthlyPresumedElectricEnergies.map((monthlyPresumedElectricEnergy) => {
    const {
      siteId, year, month, value,
    } = monthlyPresumedElectricEnergy;

    // 存在確認を行った後なのでここでデータが存在しないことは考慮しない
    const registeredLostEnergyItem = registeredLostEnergyItems.find(({ assetIds, metadata }) => (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      assetIds![0] === siteId && metadata!.year === year.toString() && metadata!.month === month.toString()
    ));

    return {
      id: registeredLostEnergyItem?.id, // 逸失発電情報(月別)はサイト/年/月ごとに1件のみの想定
      update: {
        metadata: {
          add: {
            計画発電量: value,
          },
          remove: [],
        },
      },
    } as EventChange;
  });

  return monthlyEventUpdateItems;
};

/**
 * Excelに入力された計画発電情報を逸失発電情報(月別)登録情報に変換する
 * @param {MonthlyPresumedElectricEnergy[]} monthlyPresumedElectricEnergies 月別計画発電量
 * @param {number} fiscalYear 年度
 * @returns 逸失発電情報(月別)登録情報
 */
const convertMonthlyPresumedElectricEnergyToExternalEvent = async (monthlyPresumedElectricEnergies: MonthlyPresumedElectricEnergy[], fiscalYear: number): Promise<ExternalEvent[]> => {
  const { siteId, externalId, dataSetId } = monthlyPresumedElectricEnergies[0];
  const locationEvent = await eventRetrieve({ endpoint: EP_PATH_SOLAR_EVENTS_LIST, id: undefined, externalId: `${externalId}_location_information` }) as CogniteEvent;
  const deviceEvent = await eventRetrieve({ endpoint: EP_PATH_SOLAR_EVENTS_LIST, id: undefined, externalId: `${externalId}_information` }) as CogniteEvent;

  const externalEvents: ExternalEvent[] = [];
  const baseDate = moment(`${fiscalYear}-04-01`).startOf('day');
  for (let j = 0; j < 12; j++) {
    const tmpDate = baseDate.clone().add(j, 'month');
    const externalEvent = LostEnergyInformation.new(
      dataSetId,
      siteId,
      tmpDate,
      locationEvent.metadata ? (locationEvent.metadata.business_classification_jp ?? '') : '',
      locationEvent.metadata ? (locationEvent.metadata.category1_jp ?? '') : '',
      locationEvent.metadata ? (locationEvent.metadata.business_name_jp ?? '') : '',
      locationEvent.metadata ? (locationEvent.metadata.site_name_jp ?? '') : '',
      deviceEvent.metadata ? (deviceEvent.metadata.エリア ?? '') : '',
    );
    const sameYearMonthPresumedElectricEnergy = monthlyPresumedElectricEnergies.find((monthlyPresumedElectricEnergy) => (
      monthlyPresumedElectricEnergy.siteId === siteId && monthlyPresumedElectricEnergy.year === tmpDate.year() && monthlyPresumedElectricEnergy.month === tmpDate.month() + 1
    ));
    if (sameYearMonthPresumedElectricEnergy) {
      // LostEnergyInformationのstatic method内でmetadataは必ず定義している
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      externalEvent.metadata!['計画発電量'] = sameYearMonthPresumedElectricEnergy.value.toString();
    }

    externalEvents.push(externalEvent.toExternalEvent());
  }

  return externalEvents;
};

/**
 * Excelに入力された計画発電情報を逸失発電情報(年度)更新情報に変換する
 * @param {YearlyPresumedElectricEnergy} yearlyPresumedElectricEnergy 年度別計画発電量
 * @param {CogniteEvent} registeredLostEnergyFiscalYearItem 登録済み逸失発電情報(年度)
 * @returns 逸失発電情報(年度)更新情報
 */
const convertYearlyPresumedElectricEnergyToEventChange = (yearlyPresumedElectricEnergy: YearlyPresumedElectricEnergy, registeredLostEnergyFiscalYearItem: CogniteEvent): EventChange => {
  const eventChange = {
    id: registeredLostEnergyFiscalYearItem.id,
    update: {
      metadata: {
        add: {
          ...yearlyPresumedElectricEnergy.metadata,
        },
      },
    },
  } as EventChange;
  return eventChange;
};

/**
 * Excelに入力された計画発電情報を逸失発電情報(年度)登録情報に変換する
 * @param {YearlyPresumedElectricEnergy} yearlyPresumedElectricEnergy 年度別計画発電量
 * @param {number} fiscalYear 年度
 * @returns 逸失発電情報(年度)登録情報
 */
const convertYearlyPresumedElectricEnergyToExternalEvent = (yearlyPresumedElectricEnergy: YearlyPresumedElectricEnergy, fiscalYear: number): ExternalEvent => {
  const baseDate = moment(`${fiscalYear}-04-01`).startOf('day');
  const lostEnergyFiscalYearInformation = LostEnergyFiscalYearInformation.new(
    yearlyPresumedElectricEnergy.dataSetId,
    yearlyPresumedElectricEnergy.siteId,
    baseDate,
    fiscalYear,
  );

  Object.keys(yearlyPresumedElectricEnergy.metadata).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    lostEnergyFiscalYearInformation.metadata![key] = yearlyPresumedElectricEnergy.metadata[key];
  });

  return lostEnergyFiscalYearInformation.toExternalEvent();
};

/**
 * アップロードされたExcelファイルに記入された計画発電量データをCDFに登録
 * 登録前にファイルの記入内容に対してバリデーションチェックを行い、チェック結果を返却する
 * @param {XLSX.WorkBook} file アップロードファイル
 * @returns ファイル記入内容のバリデーションチェック結果（画面で使用するため）、メッセージ
 */
export const registerPresumedElectricEnergy = async (
  file: XLSX.WorkBook,
): Promise<RegisterPresumedElectricEnergyResult> => {
  // ファイルの中身のバリデーションチェックを実施し、チェックOKの場合のみ後続処理に進む
  const dataSheet = file.Sheets['計画発電量登録シート'];
  const checkSheetResult = checkSheetValidity(dataSheet);
  if (checkSheetResult.error) {
    return checkSheetResult;
  }

  dataSheet['!ref'] = PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS.DATA_ARIA;
  const assetsWithManagementNo = await SolarSite.loadAllAssetWithManagementNos();
  const notExistsManagementNos = await checkManagementNoValidity(dataSheet, assetsWithManagementNo);
  if (notExistsManagementNos.length > 0) {
    return { error: true, message: [VALIDATIONS_NOT_EXISTS_MANAGEMENT_NO, ...notExistsManagementNos] };
  }

  const fiscalYear = dataSheet[PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS.FISCAL_YEAR].v;
  const headerDates = createHeaderDates(fiscalYear);

  const solarSiteWithDatapoints = convertFileDataToDatapoints(dataSheet, headerDates, assetsWithManagementNo);

  // 登録データが1件でもある場合のみCDFへのデータ登録を実施
  const externalDatapoints: ExternalDatapointsQuery[] = [];
  const presumedElectricEnergyParams: PresumedElectricEnergyParam[] = [];
  const monthlyPresumedElectricEnergies: MonthlyPresumedElectricEnergy[] = [];
  const yearlyPresumedElectricEnergies: YearlyPresumedElectricEnergy[] = [];
  solarSiteWithDatapoints.forEach(({ solarSite, datapoints }) => {
    if (datapoints.length === 0) return;

    const externalId = `${solarSite.externalId}_presumed_electric_energy_m`;
    externalDatapoints.push({ externalId, datapoints });

    const siteName = solarSite.externalId as string;
    const bodyItemPresumed: PresumedElectricEnergyParam = { siteName, fiscalYear };
    presumedElectricEnergyParams.push(bodyItemPresumed);

    const yearlyPresumedElectricEnergy: YearlyPresumedElectricEnergy = { siteId: solarSite.id, dataSetId: solarSite.dataSetId, metadata: {} };
    datapoints.forEach(({ timestamp, value }) => {
      const timestampMoment = moment(timestamp);
      monthlyPresumedElectricEnergies.push({
        siteId: solarSite.id,
        externalId: solarSite.externalId,
        dataSetId: solarSite.dataSetId,
        year: timestampMoment.year(),
        month: timestampMoment.month() + 1,
        value: value.toString(),
      });

      yearlyPresumedElectricEnergy.metadata[`計画発電量${timestampMoment.month() + 1}月`] = value.toString();
    });

    yearlyPresumedElectricEnergies.push(yearlyPresumedElectricEnergy);
  });

  if (externalDatapoints.length === 0) {
    // 登録データなしの場合
    return { error: false };
  }

  const postResponse = await postApiGateway<ItemsWrapper<ExternalDatapointsQuery[]>, EmptyResponse>(
    EP_PATH_SOLAR_TIMESERIES_DATAPOINTS, { items: externalDatapoints },
  );
  if ('error' in postResponse) {
    return { error: true, message: ERROR_FILES_UPLOAD };
  }

  // 日時積算処理用のイベント作成
  const businessClassificationAssets = await getParentBusinessAssetsOfSolarSite(solarSiteWithDatapoints.map((item) => item.solarSite));
  if (businessClassificationAssets.length > 0) {
    const items = businessClassificationAssets.map((asset) => ({
      dataSetId: asset.dataSetId,
      startTime: new Date().getTime(),
      type: 'presumed_electric_energy_daily_task',
      assetIds: [asset.id],
      metadata: { fiscal_year: fiscalYear },
    }));

    const createBody = { items };
    const createEventsResponse = await postApiGateway<ItemsWrapper<ExternalEvent[]>, ItemsResponse<CogniteEvent[]>>(
      EP_PATH_SOLAR_EVENTS, createBody,
    );
    if ('error' in createEventsResponse) {
      return { error: true, message: ERROR_FILES_UPLOAD };
    }
  }

  // 積算処理を同期で呼び出し
  // eslint-disable-next-line no-restricted-syntax
  for (const bodyItemPresumed of presumedElectricEnergyParams) {
    putApiGateway<PresumedElectricEnergyParam, EmptyResponse>(
      EP_PATH_SOLAR_PROCESSES_PRESUMED_ELECTRIC_ENERGY, bodyItemPresumed,
    );
    /*
      1000ミリ秒/40件=25ミリ秒（1件あたりの秒数）
      1秒あたり40件以下(Lambda内でinsert data pointsを3回呼び出しているためx3)
    */
    // eslint-disable-next-line no-await-in-loop
    await sleep(75);
  }

  /*
   * 逸失発電情報情報(月別/年度)はサイトリリース時or年度開始時に作成されている想定
   * 来年度の計画発電量を登録する場合に存在しないため12か月+年度データをこのタイミングで作成する。
   */
  const uniqueSiteIds = Array.from(new Set(monthlyPresumedElectricEnergies.map(({ siteId }) => siteId)));
  const registeredLostEnergyItems = await LostEnergyInformation.retrieveLostEnergyInformationByAssetIds(uniqueSiteIds);
  const registeredLostEnergyFiscalYearInformationItems = await LostEnergyFiscalYearInformation.retrieveLostEnergyFiscalYearInformationByAssetIds(uniqueSiteIds);

  const externalEvents: ExternalEvent[] = [];
  const eventChanges: EventChange[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const uniqueSiteId of uniqueSiteIds) {
    /*
     * サイトIDをキーにExcelに入力された情報を抽出する。
     *   siteMonthlyPresumedElectricEnergies: サイトごとの月別データ（1～12件）
     *   siteYearlyPresumedElectricEnergy: サイトごとの年度データ(1件)
     */
    const siteMonthlyPresumedElectricEnergies = monthlyPresumedElectricEnergies.filter(({ siteId }) => siteId === uniqueSiteId);
    const siteYearlyPresumedElectricEnergy = yearlyPresumedElectricEnergies.find(({ siteId }) => siteId === uniqueSiteId);

    // 逸失発電情報(月別/年度)は運用上、必ずセットで作成されるため年度のデータを使用して存在確認を行う
    const registeredLostEnergyFiscalYearInformationItem = registeredLostEnergyFiscalYearInformationItems.find((registeredItem) => (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      registeredItem.assetIds![0] === uniqueSiteId && registeredItem.metadata!.fiscalYear === fiscalYear.toString()
    ));

    if (!registeredLostEnergyFiscalYearInformationItem) {
      // 新規
      // eslint-disable-next-line no-await-in-loop
      const monthlyExternalEvents = await convertMonthlyPresumedElectricEnergyToExternalEvent(siteMonthlyPresumedElectricEnergies, fiscalYear);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const yearlyExternalEvent = convertYearlyPresumedElectricEnergyToExternalEvent(siteYearlyPresumedElectricEnergy!, fiscalYear);

      externalEvents.push(...monthlyExternalEvents);
      externalEvents.push(yearlyExternalEvent);
    } else {
      // 更新
      const monthlyEventUpdateItems = convertMonthlyPresumedElectricEnergyToEventChange(siteMonthlyPresumedElectricEnergies, registeredLostEnergyItems);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const yearlyEventUpdateItem = convertYearlyPresumedElectricEnergyToEventChange(siteYearlyPresumedElectricEnergy!, registeredLostEnergyFiscalYearInformationItem);

      eventChanges.push(...monthlyEventUpdateItems);
      eventChanges.push(yearlyEventUpdateItem);
    }
  }

  while (externalEvents.length) {
    const tmpLostEnergyCreateItems = externalEvents.splice(0, 1000);
    // eslint-disable-next-line no-await-in-loop
    const createResponse = await postApiGateway<ItemsWrapper<ExternalEvent[]>, EmptyResponse>(EP_PATH_SOLAR_EVENTS, { items: tmpLostEnergyCreateItems });
    if ('error' in createResponse) {
      return { error: true, message: ERROR_FILES_UPLOAD };
    }
  }

  while (eventChanges.length) {
    const tmpLostEnergyUpdateItems = eventChanges.splice(0, 1000);
    // eslint-disable-next-line no-await-in-loop
    const updateResponse = await putApiGateway<ItemsWrapper<EventChange[]>, EmptyResponse>(EP_PATH_SOLAR_EVENTS, { items: tmpLostEnergyUpdateItems });
    if ('error' in updateResponse) {
      return { error: true, message: ERROR_FILES_UPLOAD };
    }
  }

  return { error: false };
};
