import EmailGroup from './EmailGroup';
import { EmailType } from './EmailTypes';
import { postApiGateway, putApiGateway, deleteApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_NOTIFICATIONS_LIST,
  EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_NOTIFICATIONS_CREATE,
  EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_NOTIFICATIONS_DELETE,
  EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_NOTIFICATIONS_UPDATE,
} from '../AWS/EndpointPath';

export interface SendParameter {
  oldNotificationGroupName: string,
  editedNotificationGroupName: string,
  targetGroupIds: string[],
  emailTypes: string[],
  targetSites: string[],
  created: number,
  lastUpdated: number,
}

class EmailNotificationGroup {
  /**
   * 「notification-email-settings」テーブルの全レコードを取得
   * @returns {Promise<EmailNotificationGroup[]>} 取得結果
   */
  static async getAllNotificationGroups(): Promise<EmailNotificationGroup[]> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_NOTIFICATIONS_LIST;
    const allNotificationGroups = await postApiGateway(endpointPath, {}) as EmailNotificationGroup[];

    const allEmailGroups = await EmailGroup.getAllGroups();
    const notificationSettingsWithGroupNames = allNotificationGroups.map((notificationGroup) => {
      const targetGroupNames = allEmailGroups.filter(({ id }) => notificationGroup.targetGroupIds.includes(id))
        .map(({ groupName }) => groupName);
      return { ...notificationGroup, targetGroupNames };
    });
    return notificationSettingsWithGroupNames;
  }

  /**
   * 「notification-email-settings」テーブルにレコードを追加
   * @param {SendParameter} parameter 登録用のデータ
   */
  static async createNotificationGroup(parameter: SendParameter): Promise<void> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_NOTIFICATIONS_CREATE;
    await postApiGateway(endpointPath, parameter) as EmailNotificationGroup[];
  }

  /**
   * 「notification-email-settings」テーブルのレコードを更新
   * @param {SendParameter} parameter 更新用のデータ
   */
  static async updateNotificationGroup(parameter: SendParameter): Promise<void> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_NOTIFICATIONS_UPDATE;
    await putApiGateway(endpointPath, parameter) as EmailNotificationGroup[];
  }

  /**
   * 「notification-email-settings」テーブルのレコードを削除
   * @param {EmailNotificationGroup} sendParameter 削除するデータ
   */
  static async deleteNotificationGroup(sendParameter: EmailNotificationGroup): Promise<void> {
    const data = { ...sendParameter };
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_NOTIFICATIONS_DELETE;
    await deleteApiGateway(endpointPath, data);
  }

  /**
   * メンバ変数
   */
  notificationGroup: string;

  emailTypes: EmailType[];

  targetGroupIds: string[];

  targetGroupNames: string[];

  targetSites: string[];

  created: number;

  lastUpdated: number;

  /*
   * コンストラクタ
   */
  constructor() {
    this.notificationGroup = '';
    this.emailTypes = [];
    this.targetGroupIds = [];
    this.targetGroupNames = [];
    this.targetSites = [];
    this.created = 0;
    this.lastUpdated = 0;
  }
}

export default EmailNotificationGroup;
