import {
  Asset,
  CogniteInternalId,
  AssetListScope,
  FileRequestFilter,
  FileInfo,
  AssetAggregateQuery,
  CursorAndAsyncIterator,
  ItemsResponse,
  AggregateResponse,
} from '@cognite/sdk';

import BaseAsset from './BaseAsset';
import { postApiGateway, getApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_TEACHER_DATA_LIST,
  EP_PATH_TEACHER_DATA_FILES_ICON,
  EP_PATH_TEACHER_DATA_FILES_LIST,
  EP_PATH_TEACHER_DATA_AGGREGATE,
} from '../AWS/EndpointPath';

const ASSET_TYPE = 'training';

class TeacherData extends BaseAsset {
  /*
   * クラスメソッド
   */
  static async loadAllFromCDF(): Promise<TeacherData[]> {
    const scope: AssetListScope = {
      filter: { metadata: { assetType: ASSET_TYPE } },
      limit: Infinity,
    };

    const assets = await postApiGateway<AssetListScope, CursorAndAsyncIterator<Asset>>(
      EP_PATH_TEACHER_DATA_LIST, scope,
    );

    return assets.items.map((asset) => new TeacherData(asset));
  }

  /**
   * topflgがtrueに設定されている教師データリストを取得する。
   * @returns 教師データリスト
   */
  static async loadDisplayedTopFromCDF(): Promise<TeacherData[]> {
    const scope: AssetListScope = {
      filter: {
        metadata: {
          topflg: BaseAsset.TOP_FLAG,
          assetType: ASSET_TYPE,
        },
      },
      limit: 1000,
    };

    const result = await postApiGateway<AssetListScope, CursorAndAsyncIterator<Asset>>(
      EP_PATH_TEACHER_DATA_LIST, scope,
    );
    return result.items.map((item) => new TeacherData(item));
  }

  /**
   * topIconがtrueに設定されている教師データのサムネイルを取得する
   * @param {CogniteInternalId} assetId 教師データのAssetID
   * @returns サムネイルデータ
   * @使用画面 設備一覧
   */
  static async getTopIcon(assetId: CogniteInternalId): Promise<string | undefined> {
    const filter: FileRequestFilter = {
      filter: {
        assetIds: [assetId],
        mimeType: 'image/jpeg',
        metadata: { type: 'topIcon' },
      },
      limit: 1,
    };

    const result = await postApiGateway<FileRequestFilter, CursorAndAsyncIterator<FileInfo>>(
      EP_PATH_TEACHER_DATA_FILES_LIST, filter,
    );

    if (result.items.length <= 0) return undefined;

    const item = result.items[0];
    const getUrl = EP_PATH_TEACHER_DATA_FILES_ICON.concat(`?id=${item.id}`);
    const data = await getApiGateway<string>(getUrl);

    return data;
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      super(asset);
    } else {
      super();
    }
  }

  /*
   * メンバメソッド
   */
  cloneSelf(): TeacherData {
    const clone = new TeacherData();
    Object.assign(clone, this);

    return clone;
  }

  // eslint-disable-next-line class-methods-use-this
  async loadChildrenFromCDF(): Promise<BaseAsset[]> {
    return [];
  }

  /**
   * 教師データ配下のアセット数カウント
   * @returns アセット数
   */
  async countSubAssets(): Promise<number> {
    const filter: AssetAggregateQuery = {
      filter: { assetSubtreeIds: [{ id: this.id }] },
    };

    const teacherDataResultAggregates = (
      await postApiGateway<AssetAggregateQuery, ItemsResponse<AggregateResponse>>(
        EP_PATH_TEACHER_DATA_AGGREGATE, filter,
      )
    );

    return teacherDataResultAggregates.items[0].count;
  }
}

export default TeacherData;
