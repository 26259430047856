/* eslint-disable react/no-unstable-nested-components */
// eslint警告未対応
import React from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Icon,
  Input,
  Menu,
  Table,
  Tooltip,
} from 'antd';
import { ColumnFilterItem, ColumnProps, FilterDropdownProps } from 'antd/lib/table';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { RedoOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/ja_JP';
import moment from 'moment';

import SolarRegisteredFile from '../../utils/File/SolarRegisteredFile';
import { ERROR_NO_AUTH_MESSAGE } from '../../utils/messages';
import './RegisteredFilesTable.css';

interface RegisteredFilesTableProps {
  loading: boolean;
  solarRegisteredFiles: SolarRegisteredFile[];
  isFileNameDownloadDisabled: boolean;
  isReloadButtonDisabled: boolean;
  onClickFileName: (solarRegisteredFile: SolarRegisteredFile) => void;
  onClickReload: () => void;
}

const { RangePicker } = DatePicker;

/** 日付フォーマット */
const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

/**
 * 登録済みファイル一覧テーブル
 * @param {RegisteredFilesTableProps} props プロパティ
 * @returns 登録済みファイル一覧テーブル
 */
const RegisteredFilesTable: React.FC<RegisteredFilesTableProps> = (
  props: RegisteredFilesTableProps,
) => {
  const {
    loading,
    solarRegisteredFiles,
    isFileNameDownloadDisabled,
    isReloadButtonDisabled,
    onClickFileName,
    onClickReload,
  } = props;

  /**
   * フィルタアイテムリスト作成
   * @param {Set<string | undefined>} uniqueFileData 一意のファイル情報
   * @returns フィルタアイテムリスト
   */
  const createFileTableFilter = (uniqueFileData: Set<string | undefined>): ColumnFilterItem[] => {
    const fileDataTypeFilter: ColumnFilterItem[] = [];
    uniqueFileData.forEach((fileData) => (
      fileDataTypeFilter.push(
        { text: fileData, value: String(fileData) },
      )
    ));

    // データの昇順でソート
    fileDataTypeFilter.sort((fileA, fileB) => {
      if (fileA.value < fileB.value) {
        return -1;
      }

      // 同名のデータは存在しないため1固定で返却
      return 1;
    });

    return fileDataTypeFilter;
  };

  /**
   * フィルタドロップダウン作成
   * @param {FilterDropdownProps} ドロップダウンのproperty
   * @returns フィルタドロップダウン
   */
  const createFileFilterDropdown = ({
    setSelectedKeys, selectedKeys, filters, confirm, clearFilters,
  }: FilterDropdownProps): JSX.Element => (
    <>
      <Menu
        className="ant-dropdown-menu-without-submenu file-list-table"
        onClick={({ key }) => {
          let newSelectedKeys;
          if (selectedKeys && selectedKeys.indexOf(key) >= 0) {
            // 選択済みのデータはチェックを外す
            newSelectedKeys = selectedKeys.filter((selectedKey) => selectedKey !== key);
          } else if (selectedKeys) {
            newSelectedKeys = selectedKeys.concat([key]);
          } else {
            newSelectedKeys = [key];
          }
          const stringSelectedKeys = newSelectedKeys
            .map((newSelectedKey) => String(newSelectedKey));
          if (setSelectedKeys) setSelectedKeys(stringSelectedKeys);
        }}
      >
        {filters && filters.map((filter) => {
          const { text, value } = filter;
          const isSelected = selectedKeys && selectedKeys.indexOf(value) >= 0;
          return (
            <Menu.Item key={value} className="file-list-item">
              <Checkbox checked={isSelected} />
              <span>{text}</span>
            </Menu.Item>
          );
        })}
      </Menu>
      <div className="ant-table-filter-dropdown-btns">
        <Button
          className="filter-list-button"
          type="link"
          onClick={confirm}
          size="small"
        >
          OK
        </Button>
        <Button
          className="filter-list-button clear"
          type="link"
          onClick={clearFilters}
          size="small"
        >
          Reset
        </Button>
      </div>
    </>
  );

  const isFilesExisted = solarRegisteredFiles.length > 0;
  const columns: ColumnProps<SolarRegisteredFile>[] = [
    {
      title: 'アップロード日時',
      dataIndex: 'uploadDate',
      width: 180,
      filterIcon: (filtered) => (
        <Icon type="search" style={{ color: filtered ? '1890ff' : undefined }} />
      ),
      filterDropdown: isFilesExisted
        ? ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => {
          let rangePickerValue: RangePickerValue = [];
          if (selectedKeys && selectedKeys[0]) {
            const selectedKey = JSON.parse(String(selectedKeys[0]));
            const { startDate, endDate } = selectedKey;
            rangePickerValue = [moment(startDate), moment(endDate)];
          }

          return (
            <div className="registered-files-table-filter-dropdown">
              <RangePicker
                className="registered-files-table-upload-date-range-picker"
                value={rangePickerValue}
                locale={locale}
                onChange={(dates) => {
                  const [startDate, endDate] = dates;
                  if (setSelectedKeys) setSelectedKeys([JSON.stringify({ startDate, endDate })]);
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                }}
                format={DATE_FORMAT}
                size="small"
              />
              <Button
                className="registered-files-table-search-button"
                type="primary"
                onClick={() => {
                  if (confirm) confirm();
                }}
                icon="search"
                size="small"
              >
                検索
              </Button>
              <Button
                className="registered-files-table-reset-button"
                onClick={() => {
                  if (clearFilters) clearFilters();
                }}
                size="small"
              >
                リセット
              </Button>
            </div>
          );
        } : null,
      onFilter: (value, result) => {
        if (!result.uploadedTime) return false;
        const { startDate, endDate } = JSON.parse(value);
        const resultStartDate = new Date(result.uploadedTime);

        const inRange = moment(resultStartDate, DATE_FORMAT)
          .isBetween(moment(startDate), moment(endDate), 'seconds', '[]');
        return inRange;
      },
      render: (_, solarRegisteredFile: SolarRegisteredFile) => {
        const { uploadedTime } = solarRegisteredFile;
        return moment(uploadedTime).format(DATE_FORMAT);
      },
    },
    {
      title: 'ファイル名',
      dataIndex: 'fileName',
      ellipsis: true,
      filterIcon: (filtered) => (
        <Icon type="search" style={{ color: filtered ? '1890ff' : undefined }} />
      ),
      filterDropdown: isFilesExisted
        ? ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
          <div className="registered-files-table-filter-dropdown">
            <Input
              className="registered-files-table-file-name-input"
              value={selectedKeys ? selectedKeys[0] : undefined}
              onChange={(event) => {
                if (setSelectedKeys) {
                  setSelectedKeys(event.target.value ? [event.target.value] : []);
                }
              }}
              onPressEnter={() => {
                if (confirm) {
                  confirm();
                }
              }}
            />
            <Button
              className="registered-files-table-search-button"
              type="primary"
              onClick={() => {
                if (confirm) {
                  confirm();
                }
              }}
              icon="search"
              size="small"
            >
              検索
            </Button>
            <Button
              className="registered-files-table-reset-button"
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }
              }}
              size="small"
            >
              リセット
            </Button>
          </div>
        ) : null,
      onFilter: (value, result) => (
        result.name ? result.name.toLowerCase().includes((value as string).toLowerCase()) : false
      ),
      render: (_, solarRegisteredFile: SolarRegisteredFile) => {
        const { name } = solarRegisteredFile;
        return isFileNameDownloadDisabled ? (
          <Tooltip title={ERROR_NO_AUTH_MESSAGE} placement="topLeft">
            {name}
          </Tooltip>
        ) : (
          // AntDesign.Button type="link"だと三点リーダが表示されないため<span>で定義
          <span
            className="registered-files-table-link-button"
            role="button"
            onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
              onClickFileName(solarRegisteredFile);
              event.currentTarget.blur(); // フォーカスが残ったままだとキーボード操作で同じファイルがダウンロードされてしまうためフォーカスを外す
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLSpanElement>) => {
              onClickFileName(solarRegisteredFile);
              event.currentTarget.blur(); // フォーカスが残ったままだとキーボード操作で同じファイルがダウンロードされてしまうためフォーカスを外す
            }}
            tabIndex={-1}
          >
            {name}
          </span>
        );
      },
    },
    {
      title: '登録データ種別',
      dataIndex: 'dataType',
      width: 150,
      filters: ((): ColumnFilterItem[] => {
        // 全ファイルの登録データ種別を一意にする
        const uniqueFileDataType = new Set<string | undefined>();
        solarRegisteredFiles.forEach(({ metadata }) => uniqueFileDataType.add(metadata?.dataType));

        return createFileTableFilter(uniqueFileDataType);
      })(),
      onFilter: (value, result) => String(
        result.metadata?.dataType,
      )?.indexOf(value) === 0,
      filterDropdown: isFilesExisted
        ? createFileFilterDropdown
        : null,
      render: (_, solarRegisteredFile: SolarRegisteredFile) => {
        const { metadata } = solarRegisteredFile;
        const dataType = metadata && metadata.dataType;
        return dataType;
      },
    },
    {
      title: '事業',
      dataIndex: 'classificationName',
      width: 180,
      ellipsis: true,
      filters: ((): ColumnFilterItem[] => {
        // 全ファイルの事業を一意にする
        const uniqueFileClassificationName = new Set<string | undefined>();
        solarRegisteredFiles.forEach(
          ({ metadata }) => uniqueFileClassificationName.add(metadata?.classificationName),
        );

        return createFileTableFilter(uniqueFileClassificationName);
      })(),
      onFilter: (value, result) => String(
        result.metadata?.classificationName,
      )?.indexOf(value) === 0,
      filterDropdown: isFilesExisted
        ? createFileFilterDropdown
        : null,
      render: (_, solarRegisteredFile: SolarRegisteredFile) => {
        const { metadata } = solarRegisteredFile;
        const classificationName = metadata && metadata.classificationName;
        return classificationName;
      },
    },
    {
      title: '第１カテゴリー',
      dataIndex: 'firstCategory',
      width: 180,
      ellipsis: true,
      filters: ((): ColumnFilterItem[] => {
        // 全ファイルの第１カテゴリーを一意にする
        const uniqueFileFirstCategory = new Set<string | undefined>();
        solarRegisteredFiles.forEach(
          ({ metadata }) => uniqueFileFirstCategory.add(metadata?.firstCategory),
        );

        return createFileTableFilter(uniqueFileFirstCategory);
      })(),
      onFilter: (value, result) => String(
        result.metadata?.firstCategory,
      )?.indexOf(value) === 0,
      filterDropdown: isFilesExisted
        ? createFileFilterDropdown
        : null,
      render: (_, solarRegisteredFile: SolarRegisteredFile) => {
        const { metadata } = solarRegisteredFile;
        const firstCategory = metadata && metadata.firstCategory;
        return firstCategory;
      },
    },
    {
      title: '第２カテゴリー',
      dataIndex: 'businessName',
      width: 180,
      ellipsis: true,
      filters: ((): ColumnFilterItem[] => {
        // 全ファイルの第２カテゴリーを一意にする
        const uniqueFileSecondCategory = new Set<string | undefined>();
        solarRegisteredFiles.forEach(
          ({ metadata }) => uniqueFileSecondCategory.add(metadata?.businessName),
        );

        return createFileTableFilter(uniqueFileSecondCategory);
      })(),
      onFilter: (value, result) => String(
        result.metadata?.businessName,
      )?.indexOf(value) === 0,
      filterDropdown: isFilesExisted
        ? createFileFilterDropdown
        : null,
      render: (_, solarRegisteredFile: SolarRegisteredFile) => {
        const { metadata } = solarRegisteredFile;
        const secondCategory = metadata && metadata.businessName;
        return secondCategory;
      },
    },
    {
      title: 'サイト',
      dataIndex: 'siteName',
      width: 320,
      ellipsis: true,
      filters: ((): ColumnFilterItem[] => {
        // 全ファイルのサイトを一意にする
        const uniqueFileSiteName = new Set<string | undefined>();
        solarRegisteredFiles.forEach(({ metadata }) => uniqueFileSiteName.add(metadata?.siteName));

        return createFileTableFilter(uniqueFileSiteName);
      })(),
      onFilter: (value, result) => String(
        result.metadata?.siteName,
      )?.indexOf(value) === 0,
      filterDropdown: isFilesExisted
        ? createFileFilterDropdown
        : null,
      render: (_, solarRegisteredFile: SolarRegisteredFile) => {
        const { metadata } = solarRegisteredFile;
        const siteName = metadata && metadata.siteName;
        return siteName;
      },
    },
  ];

  return (
    <>
      <Col
        className="registered-files-table-col"
        span={24}
      >
        <Tooltip
          title={isReloadButtonDisabled ? ERROR_NO_AUTH_MESSAGE : 'reload'}
          placement={isReloadButtonDisabled ? 'topRight' : 'top'}
        >
          <Button
            className="registered-files-table-reload-button"
            type="primary"
            shape="circle"
            onClick={onClickReload}
            disabled={isReloadButtonDisabled}
          >
            <RedoOutlined />
          </Button>
        </Tooltip>
      </Col>
      <Table<SolarRegisteredFile>
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={solarRegisteredFiles}
        pagination={{
          className: 'registered-files-table-pagination',
          position: 'bottom',
          pageSize: 100,
        }}
        scroll={{ y: 760 }}
      />
    </>
  );
};

export default RegisteredFilesTable;
