import React from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';

import { DuplicateData, OperationData } from './DuplicateWarningModal';

interface DuplicateOperationTableProps {
  operationData: DuplicateData
}

/**
 * 重複運転状況テーブル
 * @param {DuplicateOperationTableProps} props
 * @param {DuplicateData} props.operationData 運転状況データ
 * @returns 重複運転状況テーブル
 */
export function DuplicateOperationTable(props: DuplicateOperationTableProps): JSX.Element {
  const columns: ColumnProps<OperationData>[] = [
    {
      title: 'PCS',
      dataIndex: 'pcs',
      width: 100,
    },
    {
      title: '逸失原因',
      dataIndex: 'status',
      width: 200,
    },
    {
      title: '対象期間',
      key: 'startTime',
      render: (_: string, record: OperationData) => {
        const { startTime, endTime } = record;
        const startTimeToDisplay = moment(startTime).format('YYYY年MM月DD日 HH時mm分');
        const endTimeToDisplay = moment(endTime).format('YYYY年MM月DD日 HH時mm分');
        return `${startTimeToDisplay} ～ ${endTimeToDisplay}`;
      },
    },
  ];

  const { operationData } = props;
  return (
    <Table<OperationData>
      columns={columns}
      dataSource={operationData}
      pagination={false}
      rowKey={(record, index) => `${record.pcs}-${record.status}-${record.startTime}-${index}`}
      scroll={{ y: 260 }}
    />
  );
}
