import React, { CSSProperties, useCallback } from 'react';

import { Metadata } from '@cognite/sdk';

import {
  Table, Form, Input, Popconfirm, Button, message,
} from 'antd';
import { ColumnProps } from 'antd/es/table';
import { FormComponentProps } from 'antd/lib/form';
import { isEmpty } from '../../../utils/common/index';
import {
  VALIDATE_ERROR_METADATA_REQUIRE,
  VALIDATE_ERROR_METADATA_KEY_TOO_LONG,
  VALIDATE_ERROR_METADATA_VALUE_TOO_LONG,
  CONFIRM_DELETE_METADATA_MESSAGE,
  ERROR_ADD_METADATA_LIMIT,
  VALIDATE_ERROR_METADATA_SYSTEM_RESERVED,
  VALIDATE_ERROR_METADATA_ALREADY_USED,
} from '../../../utils/messages';

import './MetadataTable.css';

/** metadata文字数制限(key) */
const LIMIT_METADATA_KEY_LENGTH = 40;
/** metadata文字数制限(value) */
const LIMIT_METADATA_VALUE_LENGTH = 60;
/** metadataユーザ登録上限数 */
const LIMIT_USER_REGISTER_METADATA = 100;

interface Record {
  id: number;
  key: string;
  value: string;
  system: boolean;
  isNewRecord: boolean;
  errorIndex?: string[];
}

interface MetadataTableHandle {
  getValue: () => Metadata | undefined
}

interface MetadataTableProps {
  value?: Metadata,
  editable?: boolean,
  height?: number,
  systemReservedKeyList: string[],
  tableStyle?: CSSProperties,
  plusButtonStyle?: CSSProperties,
  onSave?: (metadata: Metadata) => Promise<void> | void,
  onCancel?: () => void,
}

const MetadataTableBase: React.ForwardRefRenderFunction<
  MetadataTableHandle, MetadataTableProps
> = (props, ref) => {
  /*
   * 変数/定数定義
   */
  const [records, setRecords] = React.useState<Record[]>([]);
  const [addCounter, setAddCounter] = React.useState<number>(0);
  const [
    alreadyUsedKeyList, setAlreadyUsedKeyList,
  ] = React.useState<{ [x: string]: string; }[]>([]);

  const countRef = React.useRef<number>(0);

  const {
    value, editable, height, systemReservedKeyList, tableStyle, plusButtonStyle, onSave, onCancel,
  } = props;

  /** レコードをMetadata形式変換 */
  function convertFromRecordsToMetadata(): Metadata | undefined {
    const metadata: Metadata = {};
    records.forEach((data) => {
      if (!data.key) { return; }
      if (!data.value) { return; }
      if (data.key.length > LIMIT_METADATA_KEY_LENGTH) { return; }
      if (data.value.length > LIMIT_METADATA_VALUE_LENGTH) { return; }
      if (!data.system && systemReservedKeyList.indexOf(data.key) >= 0) { return; }
      metadata[data.key] = data.value;
    });
    if (records.length !== Object.keys(metadata).length) { return undefined; }

    return metadata;
  }

  /** 詳細情報未入力チェック */
  function setValidateRecords(): void {
    // Key or Valueが未入力のレコードが存在する場合、エラーメッセージを表示する
    const validateRecords = records.map((record) => {
      const errorIndex: string[] = [];
      if (isEmpty(record.key)) { errorIndex.push('key'); }
      if (isEmpty(record.value)) { errorIndex.push('value'); }
      record.errorIndex = errorIndex;
      return record;
    });

    setRecords(validateRecords);
  }

  /*
   * イベントハンドラ
   */
  /** レコード情報設定 */
  React.useEffect(
    () => {
      if (value) {
        countRef.current = 0;
        const keys = Object.keys(value);
        const newRecords = keys.map((key, index) => {
          const newRecord = {
            id: index,
            key,
            value: value[key],
            system: systemReservedKeyList.indexOf(key) >= 0,
            isNewRecord: false,
          };
          countRef.current += 1;
          return newRecord;
        });
        setRecords(newRecords);

        // ユーザが登録した詳細情報数をカウント
        let counter = 0;
        const usedKeyList: { [x: string]: string; }[] = [];
        newRecords.forEach((record) => {
          if (!record.system) counter += 1;
          usedKeyList.push({ [`metadataKey${record.id}`]: record.key });
        });
        setAddCounter(counter);
        setAlreadyUsedKeyList(usedKeyList);
      } else {
        setRecords([]);
      }
    },
    [value, systemReservedKeyList],
  );

  /** 詳細情報取得 */
  React.useImperativeHandle(
    ref,
    () => ({
      getValue: (): Metadata | undefined => {
        const metadataValue = convertFromRecordsToMetadata();
        if (!metadataValue) {
          // Key or Valueが未入力のレコードが存在する場合、エラーメッセージを表示し後続処理は行わない
          setValidateRecords();
          return undefined;
        }
        return metadataValue;
      },
    }),
  );

  /** 追加ボタンクリックイベント */
  const onPlusButtonClick = (): void => {
    if (addCounter >= LIMIT_USER_REGISTER_METADATA) {
      message.error(ERROR_ADD_METADATA_LIMIT);
      return;
    }
    records.push({
      id: countRef.current,
      key: '',
      value: '',
      system: false,
      isNewRecord: true,
    });
    countRef.current += 1;
    setRecords([...records]);
    setAddCounter(addCounter + 1);
  };

  /**
   * 削除ボタンクリックイベント
   * @param {Record} record 対象レコード情報
  */
  const onDeleteButtonClick = (record: Record): void => {
    // 対象レコードの削除
    const newRecords: Record[] = records.filter((data) => data.id !== record.id);
    const usedKeyList: { [x: string]: string; }[] = newRecords.map((newRecord) => ({ [`metadataKey${newRecord.id}`]: newRecord.key }));

    setRecords(newRecords);
    setAlreadyUsedKeyList(usedKeyList);
    setAddCounter(addCounter - 1);
  };

  /**
   * 編集完了イベント
   * @param {Record} record 対象レコード情報
  */
  const onCellEdit = (newRecord: Record): void => {
    const index: number = records.findIndex((record) => record.id === newRecord.id);
    records[index] = newRecord;

    alreadyUsedKeyList[index] = { [`metadataKey${records[index].id}`]: newRecord.key };

    setRecords([...records]);
    setAlreadyUsedKeyList([...alreadyUsedKeyList]);
  };

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  const columns: ColumnProps<Record>[] = [
    {
      title: '項目',
      key: 'key',
      dataIndex: 'key',
    },
    {
      title: '値',
      key: 'value',
      dataIndex: 'value',
    },
  ];

  if (editable) {
    columns.forEach((column) => {
      /* eslint no-param-reassign: ["error", { "props": false }] */
      column.onCell = (record: Record) => ({
        title: `${column.title}`,
        dataIndex: (column as ColumnProps<Record>).dataIndex,
        record,
        editable: true,
        systemKey: record.system,
        onEdit: onCellEdit,
        systemReservedKeyList,
        isNewRecord: record.isNewRecord,
        errorIndex: record.errorIndex,
        alreadyUsedKeyList,
      });
    });

    columns.push({
      title: 'アクション',
      width: 90,
      align: 'center',
      render: (_, record: Record) => (
        <Popconfirm
          disabled={record.system}
          title={CONFIRM_DELETE_METADATA_MESSAGE}
          onConfirm={() => onDeleteButtonClick(record)}
        >
          <Button
            type="link"
            icon="delete"
            disabled={record.system}
          />
        </Popconfirm>
      ),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const components = editable ? { body: { cell: WrappedEditableCell } } : undefined;

  return (
    <>
      <Table<Record>
        columns={columns}
        components={components}
        rowKey="id"
        dataSource={records}
        pagination={false}
        scroll={{ y: height || 800 }}
        size="small"
        style={tableStyle}
      />
      {
        editable && (
          <div className="common-metadata-table-button-group">
            <Button
              icon="plus"
              onClick={onPlusButtonClick}
              style={plusButtonStyle}
            >
              項目を追加
            </Button>
            {
              onSave && (
                <Button
                  type="primary"
                  onClick={(event) => {
                    event.currentTarget.blur();
                    const emptyRecords = records.filter(
                      (record) => isEmpty(record.key) || isEmpty(record.value),
                    );
                    if (emptyRecords.length > 0) {
                      // Key or Valueが未入力のレコードが存在する場合、エラーメッセージを表示し後続処理は行わない
                      setValidateRecords();
                      return;
                    }

                    const metadata = convertFromRecordsToMetadata();
                    if (!metadata) { return; }
                    onSave(metadata);
                  }}
                  style={{ float: 'right', marginLeft: '8px' }}
                >
                  保存
                </Button>
              )
            }
            {
              onCancel && (
                <Button
                  onClick={onCancel}
                  style={{ float: 'right' }}
                >
                  キャンセル
                </Button>
              )
            }
          </div>
        )
      }
    </>
  );
};

interface EditableCellProps extends FormComponentProps {
  title: React.ReactNode,
  dataIndex: keyof Record,
  record: Record,
  editable: boolean,
  systemKey: boolean,
  children: React.ReactNode,
  onEdit: (record: Record) => void,
  systemReservedKeyList: string[],
  isNewRecord: boolean,
  errorIndex: string[],
  alreadyUsedKeyList: { [x: string]: string; }[],
}

const EditableCell: React.FC<EditableCellProps> = (props) => {
  /*
   * 変数/定数定義
   */
  const {
    title,
    dataIndex,
    record,
    editable,
    systemKey,
    children,
    onEdit,
    form,
    systemReservedKeyList,
    isNewRecord,
    errorIndex,
    alreadyUsedKeyList,
    ...restProps
  } = props;

  const [editing, setEditing] = React.useState<boolean>(isNewRecord);
  const [inputFieldName, setInputFieldName] = React.useState<string>('');
  const inputRef = React.useRef<Input>(null);

  const { setFields, resetFields, validateFields } = form;

  /**
   * エラーメッセージ表示設定
   * @param {string} value 入力値
   * @param {string} errorMessage エラーメッセージ
   */
  const setFieldsValueWithErrors = useCallback((value: string, errorMessage: string): void => {
    setFields({
      [inputFieldName]: {
        value,
        errors: [new Error(errorMessage)],
      },
    });
  }, [setFields, inputFieldName]);

  /**
   * 重複エラー判定
   * @param {string} inputCellKey 入力値
   * @returns {boolean} 重複エラー判定
   */
  const validateDuplication = useCallback((inputCellKey: string): boolean => {
    // 詳細情報の重複した項目のフィールドネームを抜き出す。
    const duplicationMetadataKeys: string[] = [];
    alreadyUsedKeyList.forEach((obj) => {
      const alreadyUsedKey = Object.keys(obj)[0];
      if (obj[alreadyUsedKey] === inputCellKey) {
        duplicationMetadataKeys.push(alreadyUsedKey);
      }
    });

    // 抜き出したフィールドネームの中に入力したセルのフィールドネーム以外があった場合は重複していると判定する。
    const duplicationFlag = duplicationMetadataKeys
      .some((duplicationObjKey) => duplicationObjKey !== inputFieldName);

    return duplicationFlag;
  }, [alreadyUsedKeyList, inputFieldName]);

  /**
   * 予約文字列エラー判定
   * @param {string} inputCellKey 入力値
   * @returns {boolean} 予約文字列エラー判定
   */
  const validateSystemReservedError = useCallback((inputCellKey: string): boolean => {
    if (systemReservedKeyList.indexOf(inputCellKey) >= 0) {
      return true;
    }
    return false;
  }, [systemReservedKeyList]);

  /**
   * 文字数エラー判定
   * @param {string} inputCellKey 入力値
   * @param {string} limitLength metadata文字数制限
   * @returns {boolean} 文字数エラー判定
   */
  const validateTooLongError = useCallback((inputCellKey: string, limitLength: number): boolean => {
    if (inputCellKey.length > limitLength) {
      return true;
    }
    return false;
  }, []);

  /**
   * リセット判定
   * @param {string} inputCellKey 入力値
   * @returns {boolean} リセット判定
   */
  const validateReset = useCallback((inputCellKey: string): boolean => {
    const limitLength = dataIndex === 'key' ? LIMIT_METADATA_KEY_LENGTH : LIMIT_METADATA_VALUE_LENGTH;
    /*
     * 以下のいずれかの場合はリセット対象としない。
     *   - 重複エラー判定がtrue
     *   - 予約文字列エラー判定がtrue
     *   - 文字数エラー判定がtrue
     */
    if (
      validateDuplication(inputCellKey)
      || validateSystemReservedError(inputCellKey)
      || validateTooLongError(inputCellKey, limitLength)
    ) {
      return false;
    }
    return true;
  }, [validateDuplication, validateSystemReservedError, validateTooLongError, dataIndex]);

  /*
   * イベントハンドラ
   */
  /** フィールドネーム設定 */
  React.useEffect(
    () => {
      if (!record) { return; }
      const fieldName: string = dataIndex === 'key' ? `metadataKey${record.id}` : `metadataValue${record.id}`;
      setInputFieldName(fieldName);
    },
    [record, dataIndex],
  );

  /** 編集セルフォーカス移動 */
  React.useEffect(
    () => {
      if (isNewRecord) { return; }
      if (editing && inputRef.current) {
        inputRef.current.focus();
      }
    },
    [editing, isNewRecord],
  );

  /** 保存ボタン押下時未入力チェック */
  React.useEffect(() => {
    if (!errorIndex || errorIndex.length === 0) { return; }

    if (errorIndex.includes(dataIndex)) {
      const { key, value } = record;
      const inputValue: string = dataIndex === 'key' ? key : value;
      setFieldsValueWithErrors(inputValue, VALIDATE_ERROR_METADATA_REQUIRE.replace('[title]', `${title}`));
    }
  }, [setFieldsValueWithErrors, dataIndex, errorIndex, title, record]);

  /** エラーメッセージリセット処理 */
  React.useEffect(() => {
    if (dataIndex !== 'key') { return; }
    if (!inputFieldName) { return; }
    if (!inputRef.current?.state.value) { return; }

    const inputCellKey: string = inputRef.current.state.value;

    if (validateReset(inputCellKey)) {
      resetFields([inputFieldName]);
    }
  }, [resetFields, validateReset, dataIndex, inputFieldName]);

  /*
   * メソッド
   */

  /**
   * 未入力エラー判定
   * @param {string} inputCellKey 入力値
   * @returns {boolean} 未入力エラー判定
   */
  const validateRequireError = (inputCellKey: string): boolean => {
    if (!inputCellKey) {
      return true;
    }
    return false;
  };

  /**
   * エラーメッセージ文言設定
   * @param {string} inputCellKey 入力値
   * @returns {string} エラーメッセージ内容
   */
  const setErrorMessage = (inputCellKey: string): string => {
    let errorMessage = '';

    if (validateRequireError(inputCellKey)) {
      errorMessage = VALIDATE_ERROR_METADATA_REQUIRE.replace('[title]', `${title}`);
      return errorMessage;
    }

    if (dataIndex === 'key') {
      if (validateDuplication(inputCellKey)) {
        errorMessage = VALIDATE_ERROR_METADATA_ALREADY_USED;
      } else if (validateSystemReservedError(inputCellKey)) {
        errorMessage = VALIDATE_ERROR_METADATA_SYSTEM_RESERVED;
      } else if (validateTooLongError(inputCellKey, LIMIT_METADATA_KEY_LENGTH)) {
        errorMessage = VALIDATE_ERROR_METADATA_KEY_TOO_LONG.replace('[key_limit]', String(LIMIT_METADATA_KEY_LENGTH));
      }

      record.key = inputCellKey;
    } else {
      if (validateTooLongError(inputCellKey, LIMIT_METADATA_VALUE_LENGTH)) {
        errorMessage = VALIDATE_ERROR_METADATA_VALUE_TOO_LONG.replace('[value_limit]', String(LIMIT_METADATA_VALUE_LENGTH));
      }

      record.value = inputCellKey;
    }
    return errorMessage;
  };

  /** 入力確定時イベント */
  const onEditFinish = (): void => {
    validateFields((_, values) => {
      const inputCellKey: string = values[inputFieldName];
      const errorMessage: string = setErrorMessage(inputCellKey);

      if (errorMessage) {
        setFieldsValueWithErrors(inputCellKey, errorMessage);
      }

      if (onEdit) onEdit(record);
      if (!errorMessage) {
        setEditing(false);
      }
    });
  };

  /*
   * 画面描画
   */
  let content: React.ReactNode = children;
  if (!editable) { return <td {...restProps}>{content}</td>; }

  if (systemKey) {
    content = (
      <div className="common-metadata-table-uneditable-cell">
        {record[dataIndex]}
      </div>
    );
  } else {
    const fieldName: string = inputFieldName || 'metadata';
    content = editing ? (
      <Form.Item style={{ marginBottom: '0px' }}>
        {form.getFieldDecorator(fieldName, {
          trigger: 'onChange',
          valuePropName: 'defaultValue',
          initialValue: record[dataIndex],
        })(
          <Input
            ref={inputRef}
            onPressEnter={onEditFinish}
            onBlur={onEditFinish}
            placeholder={dataIndex === 'key' ? 'Key' : 'Value'}
          />,
        )}
      </Form.Item>
    ) : (
      <div
        className="common-metadata-table-editable-cell"
        onClick={() => {
          setEditing(true);
        }}
        aria-hidden="true"
      >
        {record[dataIndex]}
      </div>
    );
  }

  /* eslint react/jsx-props-no-spreading: ["off"] */
  return <td {...restProps}>{content}</td>;
};

const MetadataTable = React.forwardRef(MetadataTableBase);
const WrappedEditableCell = Form.create()(EditableCell);

export default MetadataTable;
