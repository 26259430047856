import { postApiGateway, putApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_SOLAR_EGS_PROCESSES_DIAGNOSIS_SETTINGS_ACTIVITY_CHECK_LIST,
  EP_PATH_SOLAR_EGS_PROCESSES_DIAGNOSIS_SETTINGS_ACTIVITY_CHECK_UPDATE,
} from '../AWS/EndpointPath';

/**
 * 更新処理に必要なパラメータの型定義
 */
export interface SendParameter {
  siteName: string;
  index: number;
  checkIntervalTime: number;
  oldCheckIntervalTime: number;
}

class ActivityCheckSetting {
  /**
   * 死活監視判定間隔の一覧テーブル取得
   * @returns {Promise<ActivityCheckSetting[]>} 死活監視判定間隔の一覧テーブル
   */
  static async getAllActivityCheckSettings(): Promise<ActivityCheckSetting[]> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_DIAGNOSIS_SETTINGS_ACTIVITY_CHECK_LIST;
    const allActivityCheckList = await postApiGateway(endpointPath, {}) as ActivityCheckSetting[];
    return allActivityCheckList;
  }

  /**
   * 死活監視判定間隔テーブルの更新処理
   * @param {SendParameter} item 更新処理に必要なパラメータ
   */
  static async updateActivityCheckSettings(item: SendParameter): Promise<void> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_DIAGNOSIS_SETTINGS_ACTIVITY_CHECK_UPDATE;
    await putApiGateway(endpointPath, item) as ActivityCheckSetting[];
  }

  siteName: string;

  companyNameJp: string;

  managementNo: string;

  gmuId: string;

  message: string;

  checkIntervalTime: number;

  index: number;

  constructor() {
    this.siteName = '';
    this.companyNameJp = '';
    this.managementNo = '';
    this.gmuId = '';
    this.message = '';
    this.checkIntervalTime = 0;
    this.index = 0;
  }
}

export default ActivityCheckSetting;
