/* eslint-disable class-methods-use-this */
// eslint警告未対応
import React from 'react';
import {
  Icon,
  Button,
  Col,
  Row,
  Input,
  message,
} from 'antd';
import RowCard from './RowCard';
import { getStorageDataSetId, getModelFileIdByLearningProjectId } from '../../../../utils/storageCommon';
import { MimeType } from '../../../../utils/File/BaseFile';
import {
  ENSEMBLE_MODEL_PLUS_ROW_CARD,
  ENSEMBLE_MODEL_DELETE_ROW_CARD,
  ENSEMBLE_MODEL_NAME_UNENTERED,
  ENSEMBLE_MODEL_NAME_DUPLICATION,
  ENSEMBLE_CLASS_NAME_OK_UNPRESSED,
  ENSEMBLE_CLASS_NAME_UNENTERED,
  ENSEMBLE_CLASS_NAME_DUPLICATION,
  ENSEMBLE_MODEL_MODEL_UNSELECTED,
} from '../../../../utils/messages';
import { postApiGateway } from '../../../../utils/AWS/ApiGateway';
import {
  EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES,
  EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES_LIST,
} from '../../../../utils/AWS/EndpointPath';
import { getAllFiles } from '../../../../utils/dataAccess';

const { Json } = MimeType;

/**
 * EnsembleModel component
 */
class EnsembleModel extends React.Component {
  constructor(props) {
    super(props);
    const rowCardRef = React.createRef();
    this.modelNameRef = React.createRef();
    this.rowKey = 2;
    const { client } = this.props;

    this.state = {
      stateRowCards: [<RowCard
        key="row1"
        id="row1"
        ref={rowCardRef}
        client={client}
        onClickDeleteRowCard={this.onClickDeleteRowCard}
      />],
      stateRowCardRefs: [rowCardRef],
      stateModelName: '',
      saveLoad: false,
    };
  }

  /**
   * RowCard追加時のイベントハンドラ
   */
  onClickAddRowCard = () => {
    const { stateRowCards, stateRowCardRefs } = this.state;
    const rowCards = [...stateRowCards];
    if (rowCards.length >= 5) {
      message.error(ENSEMBLE_MODEL_PLUS_ROW_CARD);
      return;
    }

    const rowCardRef = React.createRef();
    const { client } = this.props;
    rowCards.push(
      <RowCard
        key={`row${this.rowKey}`}
        id={`row${this.rowKey}`}
        ref={rowCardRef}
        client={client}
        onClickDeleteRowCard={this.onClickDeleteRowCard}
      />,
    );

    const rowCardRefs = [...stateRowCardRefs];
    rowCardRefs.push(rowCardRef);

    this.rowKey += 1;

    this.setState({
      stateRowCards: rowCards,
      stateRowCardRefs: rowCardRefs,
    });
  };

  /**
   * EnsembleModel名入力時のイベントハンドラ
   * @param {event} event
   */
  onChangeModelName = (event) => {
    this.setState({ stateModelName: event.target.value });
  };

  /**
   * RowCard削除時のイベントハンドラ
   * @param {event} event
   */
  onClickDeleteRowCard = (event) => {
    const { stateRowCards, stateRowCardRefs } = this.state;
    const rowCards = [...stateRowCards];
    const rowCardRefs = [...stateRowCardRefs];

    if (rowCards.length === 1) {
      message.error(ENSEMBLE_MODEL_DELETE_ROW_CARD);
      return;
    }

    const index = rowCards.findIndex((rowCard) => rowCard.props.id === event.target.id);

    rowCards.splice(index, 1);
    rowCardRefs.splice(index, 1);

    this.setState({
      stateRowCards: rowCards,
      stateRowCardRefs: rowCardRefs,
    });
  };

  /**
   * 「保存」ボタン押下時のイベントハンドラ
   */
  onClickSave = async () => {
    const { stateRowCardRefs, stateModelName } = this.state;
    const data = stateRowCardRefs.map((row) => row.current.getRowCardValue());

    this.setState({ saveLoad: true });

    const { learningProjectsId } = this.props;
    const modelName = stateModelName;
    const modelFileAssetId = await getModelFileIdByLearningProjectId(learningProjectsId);

    if (!modelName) {
      message.error(ENSEMBLE_MODEL_NAME_UNENTERED);
      this.setState({ saveLoad: false });
      return;
    }
    const duplexModelNameFlg = await this.chkDuplexModelName(modelName, modelFileAssetId);
    if (!duplexModelNameFlg) {
      message.error(ENSEMBLE_MODEL_NAME_DUPLICATION);
      this.setState({ saveLoad: false });
      return;
    }
    if (!this.chkOkButtonEnable(data)) {
      message.error(ENSEMBLE_CLASS_NAME_OK_UNPRESSED);
      this.setState({ saveLoad: false });
      return;
    }
    if (!this.chkEmptyClassNames(data)) {
      message.error(ENSEMBLE_CLASS_NAME_UNENTERED);
      this.setState({ saveLoad: false });
      return;
    }
    if (!this.chkDuplexClassNames(data)) {
      message.error(ENSEMBLE_CLASS_NAME_DUPLICATION);
      this.setState({ saveLoad: false });
      return;
    }
    if (!this.chkModel(data)) {
      message.error(ENSEMBLE_MODEL_MODEL_UNSELECTED);
      this.setState({ saveLoad: false });
      return;
    }

    const ensembleModelFile = this.createEnsembleModelFile(data);

    const bodyData = {
      name: `${modelName}.json`,
      assetIds: [learningProjectsId],
      dataSetId: getStorageDataSetId(),
      mimeType: Json,
      metadata: { type: 'parameter', engine: 'ensemble', modelName },
    };
    const fileContent = JSON.stringify(ensembleModelFile);
    await postApiGateway(EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES, { bodyData, fileContent });

    this.resetRowData();
    this.setState({ saveLoad: false });
    const { setTableData, setModalVisible } = this.props;
    setTableData();
    setModalVisible(false);
    this.setState({ stateModelName: '' });
  };

  /**
   * 閉じるボタン押下時のイベントハンドラ
   */
  onClickClose = () => {
    const { setModalVisible } = this.props;
    this.resetRowData();
    setModalVisible(false);
    this.setState({ stateModelName: '' });
  };

  /**
   * EnsembleModel名の重複チェック
   * @param {String} modelName EnsembleModel名
   * @param {String} assetId アセットID
   * @returns {Boolean} チェック結果
   */
  chkDuplexModelName = async (modelName, assetId) => {
    const filter = {
      assetIds: [assetId],
      dataSetIds: [{ id: getStorageDataSetId() }],
      mimeType: Json,
    };
    const files = await getAllFiles(EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES_LIST, filter);
    let result = true;
    files.forEach((item) => {
      if (item.name === `${modelName}.json`) {
        result = false;
      }
    });
    return result;
  };

  /**
   * 「OK」ボタン押下チェック
   * @param {object} data RowCardから取得したパラメータ
   * @returns {Boolean} チェック結果
   */
  chkOkButtonEnable = (data) => (data.every((row) => row.okButtonEnable === false));

  /**
   * クラス名の入力チェック
   * @param {object} data RowCardから取得したパラメータ
   * @returns {Boolean} チェック結果
   */
  chkEmptyClassNames = (data) => (data.every((row) => row.className.trim() !== ''));

  /**
   * クラス名の重複チェック
   * @param {object} data RowCardから取得したパラメータ
   * @returns {Boolean} チェック結果
   */
  chkDuplexClassNames = (data) => {
    const classList = new Set();
    data.forEach((row) => classList.add(row.className));
    if (data.length !== classList.size) {
      return false;
    }
    return true;
  };

  /**
   * modelの指定チェック
   * @param {object} data RowCardから取得したパラメータ
   * @returns {String} チェック結果
   */
  chkModel = (data) => {
    let result = '';
    this.getEnsembleClasses(data).forEach((param) => {
      if ('model' in param) {
        result = param.model;
      }
    });
    return result;
  };

  /**
   * classesの取得
   * @param {object} data RowCardから取得したパラメータ
   * @returns {Array} チェック結果
   */
  getEnsembleClasses = (data) => {
    const ensembleClasses = [];
    data.forEach((row) => {
      row.params.forEach((param) => {
        if ('classes' in param) {
          param.classes.forEach((paramClass) => {
            ensembleClasses.push(paramClass);
          });
        }
      });
    });
    return ensembleClasses;
  };

  /**
   * JsonFileの作成
   * @param {object} data RowCardから取得したパラメータ
   * @returns {object} 保存するJsonFile
   */
  createEnsembleModelFile = (data) => {
    this.generateEnsembleModelFileData(data);
    const ensembleModelFile = {};
    ensembleModelFile.header = this.getHeader();
    ensembleModelFile.body = this.body;
    ensembleModelFile.param = { name: this.name, variable: this.variable };
    ensembleModelFile.classList = this.classList;
    return ensembleModelFile;
  };

  /**
   * JsonFileのデータ作成
   * @param {object} data RowCardから取得したパラメータ
   */
  generateEnsembleModelFileData = (data) => {
    this.body = [];
    this.name = {};
    this.variable = {};
    this.classList = [];

    let classIndex = 1;
    data.forEach((row, index) => {
      const detectClassKey = `x${index + 1}`;
      this.name[detectClassKey] = row.className;
      this.classList.push(row.className);

      this.resetFormula();
      this.addFormula(`${detectClassKey}=`);

      row.params.forEach((param) => {
        if ('classes' in param) {
          const classLength = param.classes.length;
          if (classLength > 1) {
            this.addFormula('(');
          }
          param.classes.forEach((paramClass) => {
            const ensembleClassKey = `c${classIndex}`;
            if ('operator' in paramClass) {
              this.addFormula(paramClass.operator);
            } else {
              this.variable[ensembleClassKey] = this.getVariableData(paramClass);
              this.addFormula(ensembleClassKey);
              classIndex += 1;
            }
          });
          if (classLength > 1) {
            this.addFormula(')');
          }
        } else {
          this.addFormula(param.operator);
        }
      });
      this.body.push(this.formula);
    });
  };

  /**
   * 論理演算の記号を追加
   * @param {value} value
   */
  addFormula = (value) => {
    this.formula += value;
  };

  /**
   * 論理演算の記号(this.formula)を初期化
   */
  resetFormula = () => {
    this.formula = '';
  };

  /**
   * Headerのデータを追加
   * @returns {object} Header Object
   */
  getHeader = () => (
    { process: ['resize'], param: { resize: 'compression_gpumemory' } }
  );

  /**
   * Variableのデータを追加
   * @param {object} data RowCardから取得したclassesの値
   * @returns {object} Variable Object
   */
  getVariableData = (data) => (
    {
      rule: 'DL',
      engine: data.engine,
      modelId: data.model,
      class: data.class,
      confidence: data.confidence,
    }
  );

  /**
   * 入力内容リセット
   */
  resetRowData = () => {
    const { client } = this.props;
    const rowRef = React.createRef();
    const rowCard = <RowCard key={`row${this.rowKey}`} id={`row${this.rowKey}`} ref={rowRef} client={client} onClickDeleteRowCard={this.onClickDeleteRowCard} />;

    this.rowKey += 1;

    if (this.modelNameRef.current) {
      this.modelNameRef.current.state.value = '';
    }

    this.setState({
      stateRowCards: [rowCard],
      stateRowCardRefs: [rowRef],
    });
  };

  render() {
    const {
      stateRowCards,
      saveLoad,
    } = this.state;
    return (
      <div>
        <Row style={{ margin: '10px' }}>
          {stateRowCards}
        </Row>
        <Row style={{ margin: '10px' }}>
          <Button type="dashed" onClick={this.onClickAddRowCard} style={{ height: '150px', width: '100%' }}>
            <Icon type="plus" />
            アンサンブルクラス追加
          </Button>
        </Row>
        <Row style={{ margin: '10px' }}>
          <Col span={12} style={{ width: '50%', textAlign: 'right' }}>
            <Input ref={this.modelNameRef} placeholder="アンサンブルモデル名" onChange={this.onChangeModelName} aria-label="アンサンブルモデル名入力" style={{ width: '200px' }} />
          </Col>
          <Col span={12} style={{ width: '50%' }}>
            <Button
              type="primary"
              style={{ width: '100px', marginLeft: '20px' }}
              onClick={this.onClickSave}
              loading={saveLoad}
            >
              保存
            </Button>
            <Button type="default" onClick={this.onClickClose} style={{ marginLeft: '10px' }}>
              閉じる
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EnsembleModel;
