import React from 'react';
import { Tabs } from 'antd';

import DataRegistration from './DataRegistration';
import RegisteredFilesList from './RegisteredFilesList';

import './DataRegistrationTabs.css';

interface DataRegistrationTabsProps {
  setLoadingFlag: (flag: boolean) => void;
  display: '登録' | '一覧';
}

const { TabPane } = Tabs;

/** タブ表示文字列 */
const TAB_KEYS = {
  DATA_REGISTRATION: 'ファイルアップロード',
  REGISTERED_FILES_LIST: 'アップロードファイル一覧',
};

/**
 * ファイルアップロードタブ
 * @returns ファイルアップロードタブ画面
 */
const DataRegistrationTabs: React.FC<DataRegistrationTabsProps> = ({ setLoadingFlag, display }) => (
  <div className="DataRegistrationTabsContainer">
    <Tabs
      className="DataRegistrationTabs"
      type="card"
    >
      {display === '登録' && (
        <TabPane tab={TAB_KEYS.DATA_REGISTRATION} key={TAB_KEYS.DATA_REGISTRATION}>
          <DataRegistration setLoadingFlag={setLoadingFlag} />
        </TabPane>
      )}
      {display === '一覧' && (
        <TabPane tab={TAB_KEYS.REGISTERED_FILES_LIST} key={TAB_KEYS.REGISTERED_FILES_LIST}>
          <RegisteredFilesList />
        </TabPane>
      )}
    </Tabs>
  </div>
);

export default DataRegistrationTabs;
