import {
  Asset,
  AssetIdEither,
  AssetListScope,
  InternalId,
  ItemsWrapper,
} from '@cognite/sdk';

import { postApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_SOLAR_OM_ASSETS_LIST,
  EP_PATH_SOLAR_OM_ASSETS_BY_IDS,
} from '../AWS/EndpointPath';
import { getAllAssets } from '../dataAccess';

// TODO: OMEquipment.tsのクラスメソッドとして定義するとコンパイルエラーが発生するため暫定対応

/**
 * IDに紐づくO&M設備一覧の取得
 * @param {InternalId[]} ids IDリスト
 * @returns {Promise<ItemsWrapper<Asset[]>>} O&M設備一覧
 */
const loadAllOMEquipmentFromCDFByIds = async (
  ids: InternalId[],
): Promise<ItemsWrapper<Asset[]>> => {
  const assets = await postApiGateway<
    ItemsWrapper<AssetIdEither[]>,
    ItemsWrapper<Asset[]>
  >(EP_PATH_SOLAR_OM_ASSETS_BY_IDS, { items: ids });

  return assets;
};

/**
 * 検索条件に一致するO&M設備一覧の取得
 * @param {AssetListScope} scope 検索条件
 * @returns {Promise<Asset[]>} O&M設備一覧
 */
const loadAllOMEquipmentFromCDFByScope = async (scope: AssetListScope): Promise<Asset[]> => {
  const assets = await getAllAssets(EP_PATH_SOLAR_OM_ASSETS_LIST, scope.filter);

  return assets;
};

export {
  loadAllOMEquipmentFromCDFByIds,
  loadAllOMEquipmentFromCDFByScope,
};
