import React from 'react';
import { DatePicker } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import locale from 'antd/es/date-picker/locale/ja_JP';
import moment from 'moment';

interface ElectricEnergyOccurrenceDatesProps {
  onChange: (dates: [] | [moment.Moment, moment.Moment]) => void;
  showHourOnly?: boolean;
}

const { RangePicker } = DatePicker;

/**
 * 発電量ステータス一覧 発生日時RangePicker
 * @param {ElectricEnergyOccurrenceDatesProps} props プロパティ
 * @returns 発生日時RangePicker
 */
const ElectricEnergyOccurrenceDates: React.FC<ElectricEnergyOccurrenceDatesProps> = (
  props: ElectricEnergyOccurrenceDatesProps,
) => {
  /**
   * 発生日時変更イベントハンドラ
   * @param {RangePickerValue} dates 選択した発生日時
   */
  const handleChangeOccurrenceDates = (dates: RangePickerValue): void => {
    if (dates.length === 0) {
      props.onChange([]);
      return;
    }

    // UIで制御されているため必ずmoment.Momentが渡される
    const [startDate, endDate] = dates;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    props.onChange([startDate!, endDate!]);
  };
  const { showHourOnly } = props;

  const showTime = (showHourOnly
    ? {
      format: 'YYYY/MM/DD HH',
      hideDisabledOptions: true,
      defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
    }
    : {
      format: 'HH:mm',
      defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
    }
  );

  return (
    <RangePicker
      showTime={showTime}
      format={showHourOnly ? 'YYYY/MM/DD HH' : 'YYYY/MM/DD HH:mm'}
      placeholder={['開始日付', '終了日付']}
      locale={locale}
      onChange={handleChangeOccurrenceDates}
    />
  );
};

export default ElectricEnergyOccurrenceDates;
