import moment from 'moment';
import { MimeType } from './BaseFile';
import OMAlertEvent from '../Event/OMAlertEvent';
import { JoinedItem } from '../../components/Solar/OperationAndMaintenance/MeasurementList';

/**
 * BOM
 */
const BOM_UTF8 = '\uFEFF';

/**
 * デリミタ
 */
const DELIMITER = ',';

/**
 * レコードデリミタ
 */
const RECORD_DELIMITER = '\r\n';

/**
 * CSVヘッダ
 * { key: ボディとして出力する項目のproperty名, headerString: ヘッダ行に出力する文字列 }
 */
type Header = { key: string, headerString: string }[];

/**
 * CSVボディデータに有効なクラス定義
 */
type BodyData = JoinedItem | OMAlertEvent;

/**
 * 文字列をダブルクォーテーションで囲む
 * @param {string} value 文字列
 * @returns "文字列"
 */
const quoteDouble = (value: string): string => `"${value}"`;

/**
 * CSVの行データを生成する。
 * ヘッダー部を元にボディ部から対応する値を取得し、日時の場合は指定の形にフォーマットした後ダブルクォーテーションで囲んだ文字列をデリミタで連結する。
 * @param {Header} header ヘッダー部
 * @param {BodyData} bodyItem ボディ部
 * @param {string | undefined} dateFormat 日時のフォーマット
 * @returns レコード文字列
 */
const generateBodyRecord = (header: Header, bodyItem: BodyData, dateFormat?: string): string[] => {
  const bodyRecord = header.map(({ key, headerString }) => {
    let bodyValue: string | number | null;

    if ('timestamp' in bodyItem) {
      if (key === 'timestamp') {
        const timestamp = bodyItem.timestamp as Date;
        bodyValue = moment(timestamp).format(dateFormat);
      } else {
        const value = bodyItem[headerString as keyof typeof bodyItem];
        bodyValue = value === null ? '' : String(value);
      }
    } else if ('startTime' in bodyItem && key === 'startTime') {
      const { startTime } = bodyItem;
      bodyValue = moment(startTime).format(dateFormat);
    } else {
      bodyValue = String(bodyItem[key as keyof typeof bodyItem]);
    }

    return quoteDouble(bodyValue);
  });
  return (bodyRecord);
};

/**
 * ヘッダ部、ボディ部からCSVファイル文字列を生成し、Blobオブジェクトを返却する。
 * 出力するCSVファイルの詳細は下記のとおり。
 * - ヘッダ: あり
 * - 文字コード: UTF-8
 * - BOM: あり
 * - 引用符: "(ダブルクォーテーション)
 * - 改行タグ(\<br>)は改行コード(\r\n)に置換
 * @param {Header} header ヘッダー部
 * @param {BodyData[]} body ボディ部
 * @param {string | undefined} dateFormat 日時のフォーマット
 * @returns Blobオブジェクト
 */
const generateCsvData = (header: Header, body: BodyData[], dateFormat?: string): Blob => {
  const data: string[] = [];

  // BOM追加
  data.push(BOM_UTF8);

  // ヘッダ生成
  const csvHeader = header.map(({ headerString }) => quoteDouble(headerString)).join(DELIMITER);
  data.push(`${csvHeader}${RECORD_DELIMITER}`);

  // ボディ部生成
  const csvBody = body
    .map((bodyItem) => generateBodyRecord(header, bodyItem, dateFormat))
    .join(RECORD_DELIMITER);
  data.push(csvBody.replaceAll('<br>', RECORD_DELIMITER));

  return new Blob(data, { type: MimeType.Csv });
};

export { generateCsvData };
export type { Header, BodyData };
