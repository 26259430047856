import {
  Asset,
  AssetListScope,
  CursorAndAsyncIterator,
  FileFilterProps,
} from '@cognite/sdk';

import { getApiGateway, postApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_AI_DETECT_RESULT, EP_PATH_AI_DETECT_RESULT_FILES_LIST, EP_PATH_ROOT_ASSET_AI_DETECT_RESULT } from '../AWS/EndpointPath';
import BaseAsset from './BaseAsset';
import DetectionImageFile from '../File/DetectionImageFile';
import { implementSubtreeAssets } from '../common/CogniteApi';
import TreeNode from '../common/TreeNode';
import { getStorageDataSetId } from '../storageCommon';
import { ResourceType } from '../common/SDFDataType';
import { getAllFiles } from '../dataAccess';

class DetectionResult extends BaseAsset {
  /*
   * クラスメソッド
   */

  /**
   * 検出結果取得
   * @param {number} id
   * @returns 検出結果情報
   */
  static async loadDetectionFromCDF(id: number): Promise<DetectionResult | null> {
    const getUrl = `${EP_PATH_AI_DETECT_RESULT}/${id}`;
    const asset = await getApiGateway<Asset>(getUrl, { ignoreUnknownIds: true });

    if (!asset) return null;

    return asset.metadata?.assetType === 'detection'
      ? new DetectionResult(asset)
      : null;
  }

  /**
   * AI検出結果ツリー情報取得
   * @returns AI検出結果サブツリー情報
   */
  static async loadDetectionResultTreeFromCDF(): Promise<TreeNode<DetectionResult>> {
    // RootAsset > Resultを取得
    const dataSetId = getStorageDataSetId();
    const scope = {
      filter: { dataSetIds: [{ id: dataSetId }], metadata: { assetType: 'result' } },
    };

    const resultAssets = await postApiGateway<AssetListScope, CursorAndAsyncIterator<Asset>>(
      EP_PATH_ROOT_ASSET_AI_DETECT_RESULT, scope,
    );
    if (resultAssets.items.length === 0) {
      throw new Error();
    }

    const [resultAsset] = resultAssets.items;
    const assets = await implementSubtreeAssets(
      ResourceType.AIDetectResult,
      [{ id: resultAsset.id }],
    );

    const map: { [key: string]: TreeNode<DetectionResult>[] } = {};
    for (let i = 0; i < assets.items.length; i++) {
      if (assets.items[i].parentId) {
        const key = String(assets.items[i].parentId);
        if (!map[key]) {
          map[key] = [];
        }
        map[key].push(new TreeNode(
          String(assets.items[i].id), new DetectionResult(assets.items[i]),
        ));
      }
    }

    const node = new TreeNode<DetectionResult>(
      String(resultAsset.id), new DetectionResult(resultAsset),
    );
    this.addChildrenToNodeFromMap(node, map);

    return node;
  }

  /**
   * AI検出結果サブツリー情報生成
   * @param {TreeNode<DetectionResult>} node
   * @param {{ [key: string]: TreeNode<DetectionResult>[] }} map
   */
  private static addChildrenToNodeFromMap(
    node: TreeNode<DetectionResult>,
    map: { [key: string]: TreeNode<DetectionResult>[] },
  ): void {
    const children = map[node.key];
    if (children) {
      node.addChildren(children);
      // eslint-disable-next-line no-param-reassign
      delete map[node.key];
      for (let i = 0; i < node.children.length; i++) {
        this.addChildrenToNodeFromMap(node.children[i], map);
      }
    }
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      super(asset);
    } else {
      super();
    }
  }

  /*
   * メンバメソッド
   */
  cloneSelf(): DetectionResult {
    const clone = new DetectionResult();
    Object.assign(clone, this);

    return clone;
  }

  // eslint-disable-next-line class-methods-use-this
  async loadChildrenFromCDF(): Promise<BaseAsset[]> {
    return [];
  }

  /**
   * 検出結果画像取得
   * @param {boolean} onlyDetected
   * @returns 検出結果画像
   */
  async loadDetectionImageFilesFromCDF(onlyDetected?: boolean): Promise<DetectionImageFile[]> {
    const filter: FileFilterProps = {
      assetIds: [this.id],
    };
    if (onlyDetected) {
      filter.metadata = { detectFlag: 'true' };
    }

    const files = await getAllFiles(EP_PATH_AI_DETECT_RESULT_FILES_LIST, filter);
    return files.map((file) => new DetectionImageFile(file));
  }
}

export default DetectionResult;
