import { getStorageParentAssetType } from '../storageCommon';
import {
  EP_PATH_MANAGED_FACILITY_FILES_LIST,
  EP_PATH_FACILITY_FILES_LIST,
  EP_PATH_TEACHER_DATA_FILES_LIST,
  EP_PATH_LEARNING_PJ_FILES_LIST,
  EP_PATH_AI_DETECT_RESULT_FILES_LIST,
  EP_PATH_MANAGED_FACILITY_FILES_DOWNLOAD,
  EP_PATH_FACILITY_FILES_DOWNLOAD,
  EP_PATH_TEACHER_DATA_FILES_DOWNLOAD,
  EP_PATH_LEARNING_PJ_FILES_DOWNLOAD,
  EP_PATH_AI_DETECT_RESULT_FILES_DOWNLOAD,
  EP_PATH_MANAGED_FACILITY_FILES,
  EP_PATH_TEACHER_DATA_FILES,
  EP_PATH_FACILITY_FILES,
  EP_PATH_MANAGED_FACILITY,
  EP_PATH_FACILITY,
  EP_PATH_TEACHER_DATA,
  EP_PATH_LEARNING_PJ,
  EP_PATH_AI_DETECT,
  EP_PATH_AI_DETECT_RESULT_FILES,
} from '../AWS/EndpointPath';

/** ファイルの種類 */
export const FILE_METADATA_TYPE = {
  AI_CONFIG: 'config',
  AI_MODEL: 'model',
  COCO_JSON: 'coco',
  DETECT_IMAGE: 'detectimage',
  DETECT_PARAMETER: 'parameter',
  ENSEMBLE_MODEL: 'parameter',
  EQUIPMENT_CONFIG: 'equipmentConf',
  INSPECTION_SCANS: 'scans',
  LOG: 'log',
  PANORAMA: 'panorama',
  TOP_ICON: 'topIcon',
};

/** 学習モデルエンジン */
export const LEANING_MODEL_ENGINE = {
  SEGMENTATION_A: 'segmentationA',
  ENSEMBLE: 'ensemble',
};

/** エンジン */
export const ENGINE_VALUES = [
  { display: 'セグメンテーションA', value: LEANING_MODEL_ENGINE.SEGMENTATION_A },
];

/** エンジン: セグメンテーションA の格納番号 */
export const ENGINE_VALUES_SEG_A = 0;

/** SDFリソース */
const ResourceType = {
  /** 管理設備 */
  ManagedFacility: 'equipment',
  /** 設備 */
  Facility: 'facility',
  /** 教師データ */
  Training: 'training',
  /** 学習プロジェクト */
  LearningProject: 'learning-project',
  /** AI検出結果 */
  AIDetectResult: 'ai-detect-result',
  /** Root Asset */
  Root: 'root',
  /** 太陽光サイト */
  SolarSite: 'solar-site',
  /** O&M（特高監視）設備 */
  OMEquipment: 'o-m-equipment',
} as const;

type ResourceType = typeof ResourceType[keyof typeof ResourceType]

/**
 * アセット種別からリソース種別を取得する。
 * @param assetType アセット種別
 * @returns リソース種別
 */
const getResourceType = (assetType: string): ResourceType => {
  if (assetType === ResourceType.ManagedFacility) {
    return ResourceType.ManagedFacility;
  }

  if (assetType === ResourceType.Training) {
    return ResourceType.Training;
  }

  if (assetType === ResourceType.LearningProject) {
    return ResourceType.LearningProject;
  }

  if (assetType === ResourceType.AIDetectResult) {
    return ResourceType.AIDetectResult;
  }

  if (assetType === ResourceType.Root) {
    return ResourceType.Root;
  }

  // 管理設備・教師データ以外の場合、親の設備によって判定
  const parentAssetType = getStorageParentAssetType();
  if (parentAssetType === ResourceType.Training) {
    // 教師データ配下のデータは教師データ扱いとする
    return ResourceType.Training;
  }

  return ResourceType.Facility;
};

/**
 * リソース種別によってファイル取得のエンドポイントを判定する。
 * @param resourceType リソース種別
 * @returns エンドポイント
 */
const getFileListEndpoint = (resourceType: ResourceType): string => {
  if (resourceType === ResourceType.ManagedFacility) {
    return EP_PATH_MANAGED_FACILITY_FILES_LIST;
  }

  if (resourceType === ResourceType.Training) {
    return EP_PATH_TEACHER_DATA_FILES_LIST;
  }

  if (resourceType === ResourceType.LearningProject) {
    return EP_PATH_LEARNING_PJ_FILES_LIST;
  }

  if (resourceType === ResourceType.AIDetectResult) {
    return EP_PATH_AI_DETECT_RESULT_FILES_LIST;
  }

  return EP_PATH_FACILITY_FILES_LIST;
};

/**
 * リソース種別によってファイルダウンロードのエンドポイントを判定する。
 * @param resourceType リソース種別
 * @returns エンドポイント
 */
const getFileDownloadEndpoint = (resourceType: ResourceType): string => {
  if (resourceType === ResourceType.ManagedFacility) {
    return EP_PATH_MANAGED_FACILITY_FILES_DOWNLOAD;
  }

  if (resourceType === ResourceType.Training) {
    return EP_PATH_TEACHER_DATA_FILES_DOWNLOAD;
  }

  if (resourceType === ResourceType.LearningProject) {
    return EP_PATH_LEARNING_PJ_FILES_DOWNLOAD;
  }

  if (resourceType === ResourceType.AIDetectResult) {
    return EP_PATH_AI_DETECT_RESULT_FILES_DOWNLOAD;
  }

  return EP_PATH_FACILITY_FILES_DOWNLOAD;
};

/**
 * リソース種別によって指定ファイル取得のエンドポイントを判定する。
 * @param resourceType リソース種別
 * @returns エンドポイント
 */
const getFileEndpoint = (resourceType: ResourceType): string => {
  if (resourceType === ResourceType.ManagedFacility) {
    return EP_PATH_MANAGED_FACILITY_FILES;
  }

  if (resourceType === ResourceType.Training) {
    return EP_PATH_TEACHER_DATA_FILES;
  }

  if (resourceType === ResourceType.AIDetectResult) {
    return EP_PATH_AI_DETECT_RESULT_FILES;
  }

  return EP_PATH_FACILITY_FILES;
};

/**
 * リソース種別によってIDによるアセット取得のエンドポイントを判定する。
 * @param resourceType リソース種別
 * @param assetId アセットID
 * @returns エンドポイント
 */
const getAssetRetrieveEndpoint = (resourceType: ResourceType, assetId: number): string => {
  if (resourceType === ResourceType.ManagedFacility) {
    return `${EP_PATH_MANAGED_FACILITY}/${assetId}`;
  }

  if (resourceType === ResourceType.Training) {
    return `${EP_PATH_TEACHER_DATA}/${assetId}`;
  }

  if (resourceType === ResourceType.LearningProject) {
    return `${EP_PATH_LEARNING_PJ}/${assetId}`;
  }

  if (resourceType === ResourceType.AIDetectResult) {
    return `${EP_PATH_AI_DETECT}/${assetId}`;
  }

  return `${EP_PATH_FACILITY}/${assetId}`;
};

/**
 * リソース種別によってIDによるファイルアップロードのエンドポイントを判定する。
 * @param resourceType リソース種別
 * @returns エンドポイント
 */
const getFileUploadEndpoint = (resourceType: ResourceType): string => {
  if (resourceType === ResourceType.ManagedFacility) {
    return `${EP_PATH_MANAGED_FACILITY_FILES}`;
  }

  if (resourceType === ResourceType.Training) {
    return `${EP_PATH_TEACHER_DATA_FILES}`;
  }

  return `${EP_PATH_FACILITY_FILES}`;
};

export {
  ResourceType,
  getResourceType,
  getFileListEndpoint,
  getFileEndpoint,
  getAssetRetrieveEndpoint,
  getFileDownloadEndpoint,
  getFileUploadEndpoint,
};
