/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import React from 'react';

import {
  DoubleDatapoint,
  StringDatapoint,
} from '@cognite/sdk';

import {
  Spin, Descriptions, DatePicker, message,
} from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { Line } from '@ant-design/charts';
import moment from 'moment';
import 'moment/locale/ja';

import Timeseries from '../../../utils/Timeseries/Timeseries';

import { ERROR_LOAD_MANAGED_FACILITY_DATAPOINTS } from '../../../utils/messages';

interface Data {
  category: string,
  timestamp: string,
  value: number
}

interface DataListMap {
  [key: string]: Data[]
}

/**
 * タイムシリーズチャートコンポーネント
 */
const TimeseriesChart: React.FC<{
  height?: number,
  fromDate?: moment.Moment,
  toDate?: moment.Moment,
  timeseriesList: Timeseries[],
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [loading, setLoading] = React.useState<boolean>(false);
  const [period, setPeriod] = React.useState<[moment.Moment, moment.Moment]>();
  const [dataList, setDataList] = React.useState<Data[]>([]);

  const lineRef = React.useRef(null);

  const {
    height, fromDate, toDate, timeseriesList,
  } = props;

  /*
   * イベントハンドラ
   */

  /**
   * 開始日時、終了日時を設定する
   * 指定がない場合、7日前～当日を設定する
   */
  React.useEffect(
    () => {
      if (fromDate && toDate) {
        // 開始日時、終了日時が指定されている場合
        setPeriod([fromDate, toDate]);
        return;
      }

      if (fromDate && !toDate) {
        // 開始日時のみ指定されている場合
        setPeriod([fromDate, moment()]);
        return;
      }

      if (toDate) {
        // 終了日時のみ指定されている場合
        setPeriod([toDate.clone().add(-7, 'days'), toDate]);
        return;
      }

      const today = moment();
      const aWeekAgo = today.clone().add(-7, 'days');

      setPeriod([aWeekAgo, today]);
    },
    [fromDate, toDate],
  );

  /**
   * 選択したタイムシリーズリスト、表示期間変更時の処理
   * 変更後の情報を元に画面にグラフを表示する
   * 選択したタイムシリーズリストが空の場合、もしくは期間が未選択の場合は処理を行わない
   */
  React.useEffect(
    () => {
      if (timeseriesList.length === 0) {
        // ant-design-chart > LineChartの定義がany型で定義されているためany型で受け取る
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const lineConfig = lineRef.current as any;
        const chartRef = lineConfig.getChart();
        chartRef.changeData([]);
        chartRef.render();

        setDataList([]);
        return () => { /* 何もしない */ };
      }

      if (!period) return () => { /* 何もしない */ };

      setLoading(true);

      let canceled = false;
      (async () => {
        const timeseriesIds: string[] = [];
        const promises: Promise<DataListMap>[] = [];
        timeseriesList.forEach((timeseries) => {
          if (timeseries.isString) return;

          timeseriesIds.push(timeseries.id.toString());
          promises.push(
            loadDatapointsOf(
              timeseries, period[0].toDate(), period[1].toDate(),
            ),
          );
        });

        const results = await Promise.all(promises);

        const newDataListMap: DataListMap = {};
        results.forEach((result) => {
          const keys = Object.keys(result);
          keys.forEach((key) => {
            newDataListMap[key] = result[key];
          });
        });

        const newDataPoints: Data[] = [];
        timeseriesIds.forEach((timeseriesId) => {
          newDataPoints.push(...newDataListMap[timeseriesId]);
        });

        newDataPoints.sort((a, b) => {
          const aTimestamp = moment(a.timestamp, 'YYYY年MM月DD日HH時mm分ss秒');
          const bTimestamp = moment(b.timestamp, 'YYYY年MM月DD日HH時mm分ss秒');
          return aTimestamp.isBefore(bTimestamp) ? -1 : 1;
        });

        if (!canceled) {
          setLoading(false);
          setDataList(newDataPoints);
        }
      })();

      return () => { canceled = true; };
    },
    [timeseriesList, period],
  );

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  return (
    <Spin spinning={loading}>
      <Descriptions>
        <Descriptions.Item label="表示期間">
          <DatePicker.RangePicker
            value={period}
            locale={locale}
            onChange={(dates) => {
              if (dates && dates[0] && dates[1]) {
                setPeriod([dates[0], dates[1]]);
              }
            }}
            size="small"
          />
        </Descriptions.Item>
      </Descriptions>
      <Line
        data={dataList}
        xField="timestamp"
        yField="value"
        seriesField="category"
        padding="auto"
        ref={lineRef}
        height={height || 759}
        xAxis={{
          label: {
            formatter: (text) => {
              const name = `’${text.substring(2, 14)}`;
              return name;
            },
            autoRotate: true,
          },
          tickInterval: 60,
        }}
        yAxis={{ label: { style: { fontSize: 14 } } }}
      />
    </Spin>
  );
};

/**
 * タイムシリーズに紐づくデータポイントを指定した期間で絞り込み読み込む
 * @param {Timeseries} timeseries タイムシリーズ
 * @param {Date} fromDate 開始日時
 * @param {Date} toDate 終了日時
 * @returns {Promise<DataListMap>} データポイントマップ
 */
async function loadDatapointsOf(
  timeseries: Timeseries,
  fromDate: Date,
  toDate: Date,
) {
  // 前処理でStringDatapointsは対象外としているためここでは考慮しない
  const key = String(timeseries.id);

  const dataList: Data[] = [];

  try {
    const datapoints = await timeseries.loadDatapoints(
      fromDate, toDate,
    );

    let category = timeseries.name || 'no name';
    if (timeseries.unit) {
      // 単位が指定されている場合、末尾に単位を付与
      (category += `（${timeseries.unit}）`);
    }

    // データポイントをData形式に変換
    datapoints.forEach((datapoint: DoubleDatapoint | StringDatapoint) => {
      const timestamp = formatDate(datapoint.timestamp);
      dataList.push({
        category,
        timestamp,
        value: datapoint.value as number,
      });
    });
  } catch (exception) {
    message.error(ERROR_LOAD_MANAGED_FACILITY_DATAPOINTS);
  }

  const result: DataListMap = {};
  result[key] = dataList;

  return result;
}

/**
 * 日付をフォーマットする。
 * @param {Date} date 日付オブジェクト
 * @returns {String} YYYY年MM月DD日HH時mm分ss秒
 */
function formatDate(date: Date): string {
  const format = moment(date).format('YYYY年MM月DD日HH時mm分ss秒');
  return format;
}

export default TimeseriesChart;
