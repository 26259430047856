import BaseFile from './BaseFile';
import { EP_PATH_SOLAR_FILES_DOWNLOAD } from '../AWS/EndpointPath';

/**
 * 太陽光登録済みファイル
 */
class SolarRegisteredFile extends BaseFile {
  /**
   * 太陽光登録済みファイルのダウンロードURLを取得する。
   * @returns ダウンロードURL
   */
  loadDownloadURL(): Promise<string | undefined> {
    const downloadUrl = super.loadDownloadURLFromCDF(EP_PATH_SOLAR_FILES_DOWNLOAD);
    return downloadUrl;
  }
}

export default SolarRegisteredFile;
