import React, { useCallback } from 'react';
import { Metadata } from '@cognite/sdk';
import {
  Table, Form, Input, Popconfirm, Button,
} from 'antd';
import { ColumnProps } from 'antd/es/table';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import { isEmpty } from '../../../utils/common/index';
import {
  CONFIRM_DELETE_METADATA_MESSAGE,
  VALIDATE_ERROR_SEARCH_METADATA_REQUIRE,
} from '../../../utils/messages';

import { FacilitySwitchFlgStatus, FacilitySwitchFlgStatusType } from './SearchMetadataModal';

import './MetadataTable.css';

/**
 * レコードオブジェクト
 */
interface Record {
  id: number,
  key: string,
  value: string,
  errorIndex: string[]
}

/**
 * 編集セルプロパティ
 */
interface EditableCellProps {
  title: React.ReactNode,
  dataIndex: keyof Record,
  record: Record,
  editable: boolean,
  children: React.ReactNode,
  form: WrappedFormUtils,
}

/**
 * 編集セルコンポーネント
 */
const EditableCell: React.FC<EditableCellProps> = (props) => {
  /*
   * 変数/定数定義
   */
  const {
    title,
    dataIndex,
    record,
    editable,
    children,
    form,
    ...restProps
  } = props;

  /** エラーメッセージ表示設定 */
  const setFieldsValueWithErrors = useCallback((): void => {
    form.setFields({
      [`metadata-${dataIndex}-${record.id}`]: {
        errors: [new Error(VALIDATE_ERROR_SEARCH_METADATA_REQUIRE.replace('[title]', `${title}`))],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIndex, record, title]);

  React.useEffect(() => {
    if (!record || !record.errorIndex) { return; }

    const { errorIndex } = record;
    errorIndex.forEach((errorObj) => {
      if (errorObj === dataIndex) {
        setFieldsValueWithErrors();
      }
    });
  }, [setFieldsValueWithErrors, record, dataIndex]);

  /** エラーメッセージリセット処理 */
  React.useEffect(() => {
    if (!form) { return; }

    form.resetFields([`metadata-${dataIndex}-${record.id}`]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIndex, record]);

  if (!editable) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <td {...restProps}>{children}</td>;
  }

  const { getFieldDecorator } = form;

  /*
   * 画面描画
   */
  const content = (
    <Form.Item>
      {getFieldDecorator(`metadata-${dataIndex}-${record.id}`, {
        trigger: 'onChange',
        valuePropName: 'defaultValue',
        initialValue: record[dataIndex],
      })(
        <Input />,
      )}
    </Form.Item>
  );

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <td {...restProps}>{content}</td>;
};

/**
 * 詳細情報検索テーブルプロパティ
 */
interface SearchMetadataTableProps extends FormComponentProps {
  height?: number,
  records: Record[],
  onClickDelete: (record: Record) => void,
}

type Ref = FormComponentProps;

const SearchMetadataTableForm: React.FC<SearchMetadataTableProps> = React.forwardRef<
  Ref, SearchMetadataTableProps
>(
  ({
    form,
    height,
    records,
    onClickDelete,
  }: SearchMetadataTableProps, ref) => {
    /*
     * 外部に公開する関数
     */
    React.useImperativeHandle(ref, () => ({ form }));

    /*
     * イベントハンドラ
     */

    /**
     * 「削除」アイコンイベントハンドラ
     * @param record 削除対象レコードオブジェクト
     */
    const onDeleteButtonClick = (record: Record) => {
      onClickDelete(record);
    };

    /*
     * 画面描画
     */
    const columns: ColumnProps<Record>[] = [
      {
        title: '項目',
        key: 'key',
        dataIndex: 'key',
      },
      {
        title: '値',
        key: 'value',
        dataIndex: 'value',
      },
    ];

    // 項目、値セルの設定
    columns.forEach((column) => {
      /* eslint no-param-reassign: ["error", { "props": false }] */
      column.onCell = (record: Record) => ({
        title: `${column.title}`,
        dataIndex: (column as ColumnProps<Record>).dataIndex,
        record,
        editable: true,
        form,
      });
    });

    // アクション列の追加
    columns.push({
      title: 'アクション',
      width: 90,
      align: 'center',
      render: (_, record: Record) => (
        <Popconfirm
          title={CONFIRM_DELETE_METADATA_MESSAGE}
          onConfirm={() => onDeleteButtonClick(record)}
        >
          <Button
            type="link"
            icon="delete"
          />
        </Popconfirm>
      ),
    });

    const components = { body: { cell: EditableCell } };

    return (
      <>
        <Table<Record>
          columns={columns}
          components={components}
          rowKey="id"
          dataSource={records}
          pagination={false}
          scroll={{ y: height || 800 }}
          size="small"
        />
      </>
    );
  },
);

const EnhancedSearchMetadataTableForm = Form.create<
  SearchMetadataTableProps
>()(SearchMetadataTableForm);

/**
 * 詳細情報検索テーブル公開関数
 */
interface SearchMetadataTableBaseHandle {
  saveValues: () => void;
  validateRequireError: () => boolean;
  getValue: () => Metadata;
  resetValues: (facilitySwitchFlg: FacilitySwitchFlgStatusType) => void;
  restoreValues: () => void;
}

/**
 * 詳細情報検索テーブルプロパティ
 */
interface SearchMetadataTableBaseProps {
  height?: number,
  initialRecordCount?: number,
}

/**
 * 詳細情報検索テーブルコンポーネント
 */
const SearchMetadataTableBase: React.ForwardRefRenderFunction<
  SearchMetadataTableBaseHandle, SearchMetadataTableBaseProps
> = (props, ref) => {
  const [records, setRecords] = React.useState<Record[]>([]);
  const [savedRecords, setSavedRecords] = React.useState<Record[]>([]);
  const countRef = React.useRef<number>(0);
  const formRef = React.useRef<Ref>();

  const { height, initialRecordCount } = props;

  /**
   * Formの入力値をレコードリストに変換する。
   * @param formValues Form入力値
   * @returns レコードリスト
   */
  const convertFormValuesToRecords = (
    formValues: { [field: string]: string } | undefined,
  ): Record[] => {
    if (!formValues) return [];

    const inputKeys = Object.keys(formValues);
    if (inputKeys.length === 0) return [];

    const inputRecords: Record[] = Object.keys(formValues)
      .filter((formKey) => /metadata-key/.test(formKey))
      .map((formKey) => {
        const id = Number(formKey.split('-').reverse()[0]);
        const recordKey = formValues[formKey];
        const recordValue = formValues[`metadata-value-${id}`];
        return {
          id,
          key: recordKey,
          value: recordValue,
          errorIndex: [],
        };
      });

    return inputRecords;
  };

  /**
   * レコードオブジェクトをMetadataに変換する。
   * @param fromRecords レコードオブジェクト
   * @returns Metadata
   */
  const convertRecordsToMetadata = (fromRecords: Record[]): Metadata => {
    const metadata: Metadata = {};
    fromRecords.forEach((fromRecord) => {
      metadata[fromRecord.key] = fromRecord.value;
    });

    return metadata;
  };

  /**
   * 指定された数のレコードオブジェクトを作成する。
   * @param recordCount レコード数
   * @returns レコードオブジェクトリスト
   */
  const createRecords = React.useCallback((recordCount: number, currentId: number) => {
    const resultRecords: Record[] = [];
    for (let index = 0; index < recordCount; index++) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resultRecords.push(createRecord(currentId + index));
    }

    return resultRecords;
  }, []);

  /**
   * 初期表示時に表示する検索項目行を設定
   */
  React.useEffect(() => {
    if (!initialRecordCount) return;

    const initialRecords = createRecords(initialRecordCount, countRef.current);

    setRecords(initialRecords);
    setSavedRecords(initialRecords);
    countRef.current += initialRecordCount;
  }, [createRecords, initialRecordCount]);

  React.useImperativeHandle(ref, () => ({
    /**
     * Formの入力状態をRecord形式でstateに保存
     */
    saveValues: (): void => {
      // マスクエリアクリック後、再表示する際に元の状態に戻す必要があるためここでstateに保持
      const formValues = formRef.current?.form.getFieldsValue();
      const formRecords = convertFormValuesToRecords(formValues);

      setSavedRecords(formRecords);
    },

    /**
     * 未入力エラーチェック
     */
    validateRequireError: (): boolean => {
      const formValues = formRef.current?.form.getFieldsValue();
      const formRecords = convertFormValuesToRecords(formValues);
      let hasError = false;

      const validateFormRecords = formRecords.map((record) => {
        if (isEmpty(record.key) && !(isEmpty(record.value))) {
          // 項目×、値○
          record.errorIndex.push('key');
          hasError = true;
        } else if (!(isEmpty(record.key)) && isEmpty(record.value)) {
          // 項目○、値×
          record.errorIndex.push('value');
          hasError = true;
        }
        return record;
      });

      setRecords(validateFormRecords);

      return hasError;
    },

    /**
     * Formの入力状態をMetadata形式で取得
     * @returns {Metadata} メタデータ
     */
    getValue: (): Metadata => {
      const formValues = formRef.current?.form.getFieldsValue();

      const formRecords = convertFormValuesToRecords(formValues);
      if (formRecords.length === 0) return {};

      // Key,Value共に入力されている要素のみ取得
      const inputRecords = formRecords.filter(
        (formRecord) => !isEmpty(formRecord.key) && !isEmpty(formRecord.value),
      );

      return convertRecordsToMetadata(inputRecords);
    },

    /**
     * Formクリア
     */
    resetValues: (facilitySwitchFlg) => {
      // 入力状態を初期状態に戻す
      if (formRef.current) {
        formRef.current.form.resetFields();
      }

      const initialRecords = createRecords(initialRecordCount as number, countRef.current);
      setRecords(initialRecords);
      if (facilitySwitchFlg === FacilitySwitchFlgStatus.SWITCH_FACILITY) {
        // 設備切り替えの場合savedRecordsをリセット
        setSavedRecords(initialRecords);
      }
      countRef.current += initialRecordCount as number;
    },

    /**
     * Formの状態を元に戻す
     */
    restoreValues: () => {
      if (formRef.current) {
        formRef.current.form.resetFields();
      }

      // 保存しているレコード情報を元に新たなレコード情報を作成
      const restoreRecords = createRecords(savedRecords.length, countRef.current)
        .map((restoreRecord, index) => {
          // Key / Valueを反映
          if ((!(isEmpty(savedRecords[index].key)) && !(isEmpty(savedRecords[index].value)))
            || (isEmpty(savedRecords[index].key) && isEmpty(savedRecords[index].value))) {
            restoreRecord.key = savedRecords[index].key;
            restoreRecord.value = savedRecords[index].value;
          }
          return restoreRecord;
        });

      setRecords(restoreRecords);
      countRef.current += savedRecords.length;
    },
  }));

  /**
   * レコードオブジェクトを作成する。
   * @param id ID
   * @returns レコードオブジェクト
   */
  const createRecord = (id: number) => {
    const newRecord = {
      id,
      key: '',
      value: '',
    } as Record;

    return newRecord;
  };

  /**
   * 「追加」アイコンイベントハンドラ
   */
  const onPlusButtonClick = () => {
    records.push(createRecord(countRef.current));
    countRef.current += 1;

    setRecords([...records]);
  };

  /*
   * メソッド
   */

  /**
   * 「削除」アイコンクリックコールバック関数
   * @param record レコード
   */
  const handleClickDelete = (record: Record) => {
    const newRecords = records.filter((data) => data.id !== record.id);
    setRecords(newRecords);
  };

  return (
    <>
      <EnhancedSearchMetadataTableForm
        wrappedComponentRef={formRef}
        height={height}
        records={records}
        onClickDelete={handleClickDelete}
      />
      <div className="common-metadata-table-button-group">
        <Button
          type="link"
          icon="plus"
          onClick={onPlusButtonClick}
        />
      </div>
    </>
  );
};

const SearchMetadataTable = React.forwardRef(SearchMetadataTableBase);

export default SearchMetadataTable;
