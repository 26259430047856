/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import {
  InternalId,
  Asset,
  ExternalEvent,
  ItemsWrapper,
  CogniteEvent,
  ItemsResponse,
} from '@cognite/sdk';
import { loadAllSolarSiteFromCDFByIds } from '../../utils/Asset/SolarSiteAsset';
import SolarSite from '../../utils/Asset/SolarSite';
import { postApiGateway } from '../../utils/AWS/ApiGateway';
import { EP_PATH_SOLAR_EVENTS } from '../../utils/AWS/EndpointPath';

interface ParentAssets {
  category2Assets: Asset[];
  category1Assets: Asset[];
  businessClassificationAssets: Asset[];
}

const ACTUAL_FINANCE_DAILY_TASK = 'actual_finance_daily_task';
const PLANNED_FINANCE_DAILY_TASK = 'planned_finance_daily_task';
const PRESUMED_ELECTRIC_ENERGY_DAILY_TASK = 'presumed_electric_energy_daily_task';

/**
 * 事業、第１カテゴリー、第２カテゴリーアセットの取得
 * @param {SolarSite[]} solarSites 太陽光アセットリスト
 * @returns 指定された太陽光アセットに該当する事業、第１カテゴリー、第２カテゴリーアセットアセット
 */
const getParentAssets = async (solarSites: SolarSite[]): Promise<ParentAssets> => {
  const siteParentIds = getInternalIds(solarSites);
  const category2Assets = await loadSolarAssetsByIds(siteParentIds);
  const category2ParentIds = getInternalIds(category2Assets);
  const category1Assets = await loadSolarAssetsByIds(category2ParentIds);
  const category1ParentIds = getInternalIds(category1Assets);
  const businessClassificationAssets = await loadSolarAssetsByIds(category1ParentIds);

  return {
    category2Assets,
    category1Assets,
    businessClassificationAssets,
  };
};

/**
 * IDリストの取得
 * @param {Asset[]} assets アセットリスト
 * @returns 指定されたアセットリストの親IDの重複を除去したリスト
 */
const getInternalIds = (assets: Asset[]): InternalId[] => {
  const parentIds = Array.from(new Set(assets.map((asset) => asset.parentId)));
  const internalIds: InternalId[] = [];
  parentIds.forEach((id) => {
    if (id) internalIds.push({ id });
  });
  return internalIds;
};

/**
 * 指定したIDリストのアセットを取得
 * @param {InternalId[]} ids アセットIDリスト
 * @returns アセットリスト
 */
const loadSolarAssetsByIds = async (ids: InternalId[]): Promise<Asset[]> => {
  const solarParentAssets = await loadAllSolarSiteFromCDFByIds(ids);
  return solarParentAssets.items.map((asset) => asset);
};

/**
 * 日時処理のイベント登録
 * @param {string} eventType イベントタイプ
 * @param {Asset[]} solarSites 太陽光サイトのリスト
 * @param {number} fiscalYear 登録年度
 * @param {number} category2AssetId 第２カテゴリーアセットID
 * @returns イベント登録レスポンス
 */
const createFinanceDailyTask = async (
  eventType: typeof ACTUAL_FINANCE_DAILY_TASK | typeof PLANNED_FINANCE_DAILY_TASK,
  solarSites: Asset[],
  fiscalYear: number,
  category2AssetId: number,
): Promise<ItemsResponse<CogniteEvent[]>> => {
  const requestBody = solarSites.map((asset) => (
    {
      dataSetId: asset.dataSetId,
      startTime: new Date().getTime(),
      type: eventType,
      assetIds: [asset.id],
      metadata: {
        fiscal_year: fiscalYear.toString(),
        assetId: category2AssetId.toString(),
      },
    }));

  const createEventsResponse = await postApiGateway<ItemsWrapper<ExternalEvent[]>, ItemsResponse<CogniteEvent[]>>(
    EP_PATH_SOLAR_EVENTS, { items: requestBody },
  );
  return createEventsResponse;
};

export {
  getParentAssets,
  createFinanceDailyTask,
  ACTUAL_FINANCE_DAILY_TASK,
  PLANNED_FINANCE_DAILY_TASK,
  PRESUMED_ELECTRIC_ENERGY_DAILY_TASK,
};
