import { CogniteEvent, ExternalEvent } from '@cognite/sdk';
import moment from 'moment';
import { getAllEvents } from '../dataAccess';
import { EP_PATH_SOLAR_EVENTS_LIST } from '../AWS/EndpointPath';
import BaseEvent from './BaseEvent';

/**
 * 逸失発電情報（月別）クラス
 */
export default class LostEnergyInformation extends BaseEvent {
  /** Events.type */
  static eventType = 'lost_energy_information';

  /**
   * アセットIDをキーに逸失発電情報（月別）を取得する。
   * @param {number[]} assetIds アセットID
   * @returns 逸失発電情報（月別）
   */
  static async retrieveLostEnergyInformationByAssetIds(assetIds: number[]): Promise<CogniteEvent[]> {
    const filter = {
      type: LostEnergyInformation.eventType,
      assetIds,
    };

    const result = await getAllEvents(EP_PATH_SOLAR_EVENTS_LIST, filter) as CogniteEvent[];
    return result;
  }

  /**
   * 逸失発電情報（月別）のインスタンスを生成する
   * @param {number | undefined} dataSetId データセットID
   * @param {number} assetId アセットID
   * @param {moment.Moment} currentTime 設定する時間
   * @param {string} business 事業
   * @param {string} firstCategory 第１カテゴリー
   * @param {string} secondCategory 第２カテゴリー
   * @param {string} siteName サイト名
   * @param {string} area エリア
   * @returns 逸失発電情報（月別）
   */
  static new(
    dataSetId: number | undefined,
    assetId: number,
    currentTime: moment.Moment,
    business: string,
    firstCategory: string,
    secondCategory: string,
    siteName: string,
    area: string,
  ): LostEnergyInformation {
    const lostEnergyInformation = new LostEnergyInformation();
    lostEnergyInformation.dataSetId = dataSetId;
    lostEnergyInformation.startTime = currentTime.valueOf();
    lostEnergyInformation.type = LostEnergyInformation.eventType;
    lostEnergyInformation.assetIds = [assetId];
    lostEnergyInformation.metadata = {
      year: currentTime.year().toString(),
      month: (currentTime.month() + 1).toString(),
      事業: business,
      第１カテゴリー: firstCategory,
      第２カテゴリー: secondCategory,
      サイト名: siteName,
      エリア: area,
      要因不明損失率: '',
      要因不明損失量: '',
      故障要因損失率: '',
      故障要因損失量: '',
      メンテナンス要因損失率: '',
      メンテナンス要因損失量: '',
      出力抑制要因損失率: '',
      出力抑制要因損失量: '',
      天候要因損失率: '',
      天候要因損失量: '',
      計画発電量: '',
      期待発電量: '',
      実績発電量: '',
    };

    return lostEnergyInformation;
  }

  // コンストラクタをprivateにするため警告解除
  // eslint-disable-next-line no-useless-constructor
  private constructor() {
    super();
  }

  /**
   * CDF登録用オブジェクトに変換する
   * @returns CDF登録用オブジェクト
   */
  toExternalEvent(): ExternalEvent {
    const externalEvent: ExternalEvent = {};
    externalEvent.dataSetId = this.dataSetId;
    externalEvent.startTime = this.startTime;
    externalEvent.type = this.type;
    externalEvent.assetIds = this.assetIds;
    externalEvent.metadata = this.metadata;

    return externalEvent;
  }
}
