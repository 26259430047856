import React from 'react';

import { List } from 'antd';

import OMAlertEvent from '../../../../utils/Event/OMAlertEvent';

import './MeasurementPoint.css';

interface MeasurementPointProps {
  targetPoints: OMAlertEvent[];
}

/**
 * 計測ポイント表示のコンポーネント
 * @param {MeasurementPointProps} props プロパティ
 * @returns 計測ポイント
 */
const MeasurementPoint: React.FC<MeasurementPointProps> = (props: MeasurementPointProps) => {
  const {
    targetPoints,
  } = props;

  return (
    <div>
      <div className="om-dashboard-measurement-point-title">計測ポイント</div>
      {targetPoints.length > 0 && (
        <div className="om-dashboard-measurement-point-items">
          <List
            grid={{ column: 4 }}
            dataSource={targetPoints}
            renderItem={(item) => (
              <List.Item style={{ textAlign: 'center', margin: '3px 0' }}>
                <span
                  className={item.happening ? (`om-dashboard-${item.severity === 1 ? 'red' : 'yellow'}-style-circle`) : undefined}
                  style={{ marginRight: '3px', fontSize: '17px', fontFamily: 'メイリオ' }}
                >
                  {item.happening ? '●' : '○'}
                </span>
                {item.monitorName}
              </List.Item>
            )}
            style={{ maxHeight: '400px', overflowY: 'auto' }}
          />
        </div>
      )}
    </div>
  );
};

export default MeasurementPoint;
