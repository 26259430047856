/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable arrow-body-style */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/sort-comp */
// eslint警告未対応
import React from 'react';
import {
  Form, Modal, Select, Input, Cascader, Spin, Tag, Tabs,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  getDetectTaskAssetId, getDlAssetId, getResultAssetId, getStorageDataSetId, getStorageParentAssetId,
} from '../../utils/storageCommon';
import { getTaskPriority } from '../../utils/common';
import { validateDetectParametersForStandard, validateDetectParametersForEnsemble } from '../../utils/detect/detectTaskValidator';
import {
  getAllAssets, getAllFiles, fileRetrieve, getDownloadUrl, uploadFile, createEvent,
} from '../../utils/dataAccess';
import { MimeType } from '../../utils/File/BaseFile';
import { sortOrder, sortCreatedTime } from '../../utils/sort';
import { ENGINE_VALUES, ENGINE_VALUES_SEG_A } from '../../utils/common/SDFDataType';
import { ERROR_ADD_AI_DETECT_TASK } from '../../utils/messages';
import {
  EP_PATH_LEARNING_PJ_LIST,
  EP_PATH_LEARNING_MODEL_AI_MODEL_FILES,
  EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES,
  EP_PATH_AI_DETECT_EXECUTE,
  EP_PATH_LEARNING_MODEL_FILES_CONFIG_DOWNLOAD,
  EP_PATH_LEARNING_MODEL_AI_MODEL_FILES_LIST,
  EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES_LIST,
  EP_PATH_AI_DETECT_FILES_DOWNLOAD,
  EP_PATH_DETECT_TASK_LIST,
} from '../../utils/AWS/EndpointPath';
import { postApiGateway } from '../../utils/AWS/ApiGateway';

const { Option } = Select;
const { TabPane } = Tabs;

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
/** カラーパターン */
const COLOR_PATTERN = {
  pattern1: {
    color: [[255, 0, 0], [0, 255, 0], [0, 255, 255], [255, 0, 255], [255, 255, 0]],
    colorName: ['Red', 'Lime', 'Aqua', 'Magenta', 'Yellow'],
  },
  pattern2: {
    color: [[0, 255, 0], [255, 0, 255], [255, 0, 0], [0, 255, 255], [255, 255, 0]],
    colorName: ['Lime', 'Magenta', 'Red', 'Aqua', 'Yellow'],
  },
  pattern3: {
    color: [[255, 255, 0], [255, 0, 255], [0, 255, 255], [0, 255, 0], [255, 0, 0]],
    colorName: ['Yellow', 'Magenta', 'Aqua', 'Lime', 'Red'],
  },
};
/** 確信度 */
const CONFIDENCE_VALUES_FOR_SEGMENTATION_A = ['0.0'];
/** エンジン: セグメンテーションA */
const ENGINE_SEGMENTATION_A = ENGINE_VALUES[ENGINE_VALUES_SEG_A].value;
/** Cascader modelId */
const CASCADER_MODEL_ID = 1;
/** 通常モデル */
const STANDARD_MODEL = 'standardModel';
/** アンサンブルモデル */
const ENSEMBLE_MODEL = 'ensembleModel';

/** カラーパレット */
const ColorPaletteUnit = styled.span`
  padding: 0px 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
`;

/** エラーメッセージ */
const ErrorMessages = styled.div`
  color: #f5222d;
`;

/**
 * AI検出パラメータ設定ダイアログコンポーネントクラス
 * @property {boolean} visible ダイアログ表示状態
 * @property {array} fileIds 選択したファイルIDリスト
 * @property {number} detectAssetId 検出設備ID
 * @property {function} modalCancel ダイアログキャンセルボタンイベントハンドラ
 */
const SettingDetectParameterDialog = Form.create({ name: 'setting_detect_parameter_dialog' })(

  class extends React.Component {
    constructor(props) {
      super(props);
      this.standardDetectTaskNameRef = React.createRef();
      this.ensembleDetectTaskNameRef = React.createRef();
    }

    state = {
      confirmLoading: false,
      learningProjectsWithModels: { options: [], selectedItems: [] },
      learningProjectsWithModelsLoad: false,
      confidence: { options: [], selectedItem: undefined, disabled: false },
      confidenceLoad: false,
      classes: { options: [], selectedItems: [] },
      classesLoad: false,
      standardModelColorPattern: { options: [], selectedItem: undefined },
      ensembleModelColorPattern: { options: [], selectedItem: undefined },
      colorPatternLoad: false,
      errorMessage: undefined,
      ensembleModels: { options: [], selectedItems: [] },
      ensembleModelsLoad: false,
      selectedTab: STANDARD_MODEL,
    };

    /**
     * プロジェクト情報を取得する。
     * @returns {array} プロジェクト情報
     */
    async loadProjects() {
      const dlAssetId = await getDlAssetId();
      const projects = await getAllAssets(EP_PATH_LEARNING_PJ_LIST, { parentIds: [dlAssetId] });

      return projects;
    }

    /**
     * エンジン情報を取得する。
     * @param {string} modelId 学習モデルID
     * @returns エンジン情報
     */
    async loadEngine(modelId) {
      const { selectedTab } = this.state;
      const endpoint = selectedTab === STANDARD_MODEL
        ? EP_PATH_LEARNING_MODEL_AI_MODEL_FILES
        : EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES;

      const modelFile = await fileRetrieve({ endpoint, id: modelId });
      return modelFile.metadata.engine;
    }

    /**
     * クラス情報を取得する。
     * @param {number} modelId 学習モデルID
     * @returns クラス情報
     */
    async loadClasses(modelId) {
      // クラス情報取得処理は通常モデルのみ行うためエンドポイント固定
      const endpoint = EP_PATH_LEARNING_MODEL_AI_MODEL_FILES;

      const classes = [];

      // 学習モデルファイルに紐づく設定ファイルからクラス情報を抽出
      const modelFile = await fileRetrieve({ endpoint, id: modelId });
      const configFileUrl = await getDownloadUrl(
        EP_PATH_LEARNING_MODEL_FILES_CONFIG_DOWNLOAD,
        Number(modelFile.metadata.configId),
      );
      const res = await fetch(configFileUrl);
      const configJson = await res.json();
      configJson.coco.categories.forEach((category) => classes.push(category.name));

      return classes;
    }

    /**
     * 確信度情報を取得する。
     * @param {string} engine エンジン
     * @returns 確信度情報
     */
    loadConfidence(engine) {
      // エンジンによって確信度を変更
      const confidenceValues = CONFIDENCE_VALUES_FOR_SEGMENTATION_A;

      const options = confidenceValues.map((value, index) => <Option key={index} value={value}>{String(value)}</Option>);
      const selectedItem = confidenceValues[0];
      const disabled = engine === ENGINE_SEGMENTATION_A; // segmentationAの場合、確信度は非活性

      return { options, selectedItem, disabled };
    }

    /**
     * 指定されたclassと全てのクラスリストをマージする。
     * @param {array} inputClasses 指定したクラス
     * @returns {array} マージしたクラスリスト
     */
    async mergeClasses(inputClasses) {
      const [, modelId] = this.state.learningProjectsWithModels.selectedItems;
      const mergeClasses = [...inputClasses];
      const classes = await this.loadClasses(modelId);
      classes.forEach((c) => {
        if (mergeClasses.indexOf(c) < 0) {
          mergeClasses.push(c);
        }
      });

      return mergeClasses;
    }

    /**
     * クラスセレクトボックスのOptionリストを取得する。
     * @param {array} classes クラス情報
     * @returns {array} Optionリスト
     */
    getClassOptions(classes) {
      // 選択しているカラーパターンをクラスタグに反映
      const colorNames = COLOR_PATTERN[this.state.standardModelColorPattern.selectedItem].colorName;

      const classOptions = classes.map((value, index) => {
        const result = colorNames[index]
          ? <Option key={index} value={value}><Tag color={colorNames[index].toLowerCase()}>{value}</Tag></Option>
          : <Option key={index} value={value}>{value}</Option>;
        return result;
      });

      return classOptions;
    }

    /**
     * 学習プロジェクト/通常モデル選択カスケード作成
     * @returns 学習プロジェクト/通常モデル選択データ
     */
    async generateLearningProjectsWithModels() {
      this.setState({ learningProjectsWithModelsLoad: true });

      // 学習プロジェクトデータ取得
      const projects = await this.loadProjects();
      const dataSetId = getStorageDataSetId();

      const learningProjectsWithModels = [];
      const projectIdAllList = projects.map((project) => project.id);
      const filesList = [];

      // assetIdsの同時検索は100件まで
      for (let i = 0; i < projects.length; i += 100) {
        const projectIdList = projectIdAllList.slice(i, i + 100);
        const fileFilter = {
          metadata: { type: 'model' },
          dataSetIds: [{ id: dataSetId }],
          assetIds: projectIdList,
        };
        // eslint-disable-next-line no-await-in-loop
        const files = await getAllFiles(EP_PATH_LEARNING_MODEL_AI_MODEL_FILES_LIST, fileFilter);
        filesList.push(...files);
      }

      projects.forEach((project) => {
        // プロジェクトと学習モデルの親子関係を全探索
        // 存在しない場合は空配列が返る
        const fileDataList = filesList.filter((file) => file.assetIds[0] === project.id);

        if (fileDataList.length > 0) {
          // 学習モデルが作成済みのデータのみ抽出してCascade構築
          const fileData = fileDataList.map((f) => ({ value: f.id, label: f.metadata.modelName, createdTime: f.createdTime }));
          learningProjectsWithModels.push({
            value: project.id,
            label: project.name,
            metadata: { order: project.metadata.order },
            children: fileData,
          });
        }
      });

      // 学習プロジェクトをmetadata.orderの昇順でソート
      sortOrder(learningProjectsWithModels);

      // 学習モデルを作成日時の昇順でソート
      learningProjectsWithModels.forEach((learningProjectWithModels) => sortCreatedTime(learningProjectWithModels.children));

      this.setState({
        learningProjectsWithModels: { ...this.state.learningProjectsWithModels, options: learningProjectsWithModels },
        learningProjectsWithModelsLoad: false,
      });
    }

    /**
     * 学習プロジェクト/アンサンブルモデル選択カスケード作成
     * @returns 学習プロジェクト/アンサンブルモデル選択データ
     */
    async generateEnsembleModels() {
      this.setState({ ensembleModelsLoad: true });

      // 学習プロジェクトデータ取得
      const projects = await this.loadProjects();
      const dataSetId = getStorageDataSetId();

      const ensembleModels = [];
      const projectIdAllList = projects.map((project) => project.id);
      const filesList = [];

      // assetIdsの同時検索は100件まで
      for (let i = 0; i < projects.length; i += 100) {
        const projectIdList = projectIdAllList.slice(i, i + 100);
        const fileFilter = {
          metadata: { engine: 'ensemble' },
          dataSetIds: [{ id: dataSetId }],
          assetIds: projectIdList,
        };
        // eslint-disable-next-line no-await-in-loop
        const files = await getAllFiles(EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES_LIST, fileFilter);
        filesList.push(...files);
      }

      projects.forEach((project) => {
        // プロジェクトとアンサンブルモデルの親子関係を全探索
        // 存在しない場合は空配列が返る
        const fileDataList = filesList.filter((file) => file.assetIds[0] === project.id);

        if (fileDataList.length > 0) {
          // アンサンブルモデルを抽出してCascade構築
          const fileData = fileDataList.map((f) => ({ value: f.id, label: f.metadata.modelName, createdTime: f.createdTime }));
          ensembleModels.push({
            value: project.id,
            label: project.name,
            metadata: { order: project.metadata.order },
            children: fileData,
          });
        }
      });

      // 学習プロジェクトをmetadata.orderの昇順でソート
      sortOrder(ensembleModels);

      // アンサンブルモデルを作成日時の昇順でソート
      ensembleModels.forEach((learningProjectWithModels) => sortCreatedTime(learningProjectWithModels.children));

      this.setState({
        ensembleModels: { ...this.state.ensembleModels, options: ensembleModels },
        ensembleModelsLoad: false,
      });
    }

    /**
     * 確信度セレクトボックス作成
     * @param {number} modelId 学習モデルID
     */
    async generateConfidence(modelId) {
      this.setState({ confidenceLoad: true });

      if (!modelId) {
        // 学習モデル未選択時は初期化
        this.setState({ confidenceLoad: false, confidence: { options: [], selectedItem: undefined, disabled: false } });
        return;
      }

      // 学習モデルのエンジンを確認
      const engine = await this.loadEngine(modelId);
      const confidence = this.loadConfidence(engine);

      this.setState({
        confidence,
        confidenceLoad: false,
      });
    }

    /**
     * カラーパターンセレクトボックス作成
     * @param {number} modelId 学習モデルID
     * @param {string} modelType モデルタイプ（standardModel / ensembleModel）
     */
    generateColorPattern(modelId, modelType) {
      this.setState({ colorPatternLoad: true });
      if (!modelId) {
        if (modelType === STANDARD_MODEL) {
          // 学習モデル未選択時は初期化
          this.setState({ colorPatternLoad: false, standardModelColorPattern: { options: [], selectedItem: undefined } });
        } else {
          // アンサンブルモデル未選択時は初期化
          this.setState({ colorPatternLoad: false, ensembleModelColorPattern: { options: [], selectedItem: undefined } });
        }
        return;
      }

      const defaultValue = 'pattern1';
      const colorPalettes = [];
      Object.keys(COLOR_PATTERN).forEach((pattern) => {
        const colorPalette = [];
        COLOR_PATTERN[pattern].colorName.forEach((colorName) => {
          // カラー
          colorPalette.push(<ColorPaletteUnit key={colorName} style={{ backgroundColor: colorName.toLowerCase() }} />);
        });
        colorPalettes.push(colorPalette);
      });

      const colorOptions = Object.keys(COLOR_PATTERN).map((value, index) => <Option key={index} value={value.toLowerCase()}>{value}{colorPalettes[index]}</Option>);

      if (modelType === STANDARD_MODEL) {
        this.setState({
          standardModelColorPattern: { options: colorOptions, selectedItem: defaultValue },
        });
      } else {
        this.setState({
          ensembleModelColorPattern: { options: colorOptions, selectedItem: defaultValue },
        });
      }
      this.setState({
        colorPatternLoad: false,
      });
    }

    /**
     * クラスセレクトボックス作成
     * @param {number} modelId 学習モデルID
     */
    async generateClasses(modelId) {
      this.setState({ classesLoad: true });

      if (!modelId) {
        // 学習モデル未選択時は初期化
        this.setState({ classesLoad: false, classes: { options: [], selectedItems: [] } });
        return;
      }

      const classes = await this.loadClasses(modelId);
      const classOptions = this.getClassOptions(classes);

      this.setState({
        classes: { options: classOptions, selectedItems: classes },
        classesLoad: false,
      });
    }

    /**
     * 検出パラメータに使用する各項目のカスケード/セレクトボックスを作成する。
     */
    async generateSelectParameters() {
      // 学習プロジェクト / モデルカスケード作成
      await this.generateLearningProjectsWithModels();
      // 学習プロジェクト / アンサンブルモデルカスケード作成
      await this.generateEnsembleModels();
    }

    /**
     * AI検出パラメータオブジェクトを取得する。
     * @param {number} modelId 学習モデルID
     * @param {string} engine エンジン
     * @param {array} classes クラス
     * @param {string} colorPattern カラーパターン
     * @param {string} confidence 確信度
     * @param {array} fileIds 選択したファイルIDリスト
     * @returns AI検出パラメータオブジェクト
     */
    async getDetectParameter({
      modelId, engine, classes, colorPattern, confidence, fileIds,
    }) {
      let parameter = {};
      if (engine === 'ensemble') {
        const dlFiles = await postApiGateway(
          EP_PATH_AI_DETECT_FILES_DOWNLOAD, { items: [{ id: modelId }] },
        );
        const res = await fetch(dlFiles.items[0].downloadUrl, { mode: 'cors' });
        parameter = await res.json();

        const body = [...parameter.body];
        body.forEach((item, index) => {
          const colorKey = 'color' + String(index + 1);
          parameter.param.variable[colorKey] = {
            rule: 'Draw',
            method: 'paint',
            color: COLOR_PATTERN[colorPattern].color[index],
            colorName: COLOR_PATTERN[colorPattern].colorName[index],
          };
          const bodyValue = item + '*' + colorKey;
          parameter.body[index] = bodyValue;
        });
        parameter.imageList = fileIds;
        parameter.color = COLOR_PATTERN[colorPattern].color;
        parameter.colorName = COLOR_PATTERN[colorPattern].colorName;
      } else {
        parameter = {
          engine,
          modelId,
          confidence: ENGINE_SEGMENTATION_A === engine ? 0 : confidence, // engineがsegmentationAの場合、0固定
          color: COLOR_PATTERN[colorPattern].color,
          colorName: COLOR_PATTERN[colorPattern].colorName,
          classList: classes,
          imageList: fileIds,
        };
      }
      return parameter;
    }

    /**
     * AI検出パラメータファイルを保存する。
     * @param {number} modelId 学習モデルID
     * @param {string} engine エンジン
     * @param {array} classes クラス
     * @param {string} colorPattern カラーパターン
     * @param {string} confidence 確信度
     * @param {string} detectTaskName 検出タスク名
     * @returns AI検出パラメータファイルID
     */
    async saveParameterJson({
      modelId, engine, classes, colorPattern, confidence, detectTaskName,
    }) {
      const { Json } = MimeType;
      const fileIds = this.props.fileIds.map((fileId) => String(fileId));
      const parameter = await this.getDetectParameter({
        modelId, engine, classes, colorPattern, confidence, fileIds,
      });
      const fileInfo = {
        name: `${detectTaskName}.json`,
        dataSetId: getStorageDataSetId(),
        mimeType: Json,
        metadata: { type: 'parameter', engine },
      };

      const endpoint = engine === 'ensemble'
        ? EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES
        : EP_PATH_LEARNING_MODEL_AI_MODEL_FILES;

      const uploadFileId = await uploadFile(endpoint, fileInfo, JSON.stringify(parameter));
      return uploadFileId;
    }

    /**
     * AI検出タスクを登録する。
     * @param {number} modelId 学習モデルID
     * @param {string} engine エンジン
     * @param {string} detectTaskName 検出タスク名
     * @param {number} parameterFileId パラメータファイルID
     */
    async saveDetectTask({
      modelId, engine, detectTaskName, parameterFileId,
    }) {
      const { detectAssetId } = this.props;
      const detectTaskId = await getDetectTaskAssetId();
      const priority = await getTaskPriority(EP_PATH_DETECT_TASK_LIST, detectTaskId);
      const resultId = await getResultAssetId();
      const managementEquipmentId = await getStorageParentAssetId();

      const event = {
        assetIds: [detectTaskId],
        dataSetId: getStorageDataSetId(),
        startTime: priority,
        type: 'detect',
        subtype: 'untreated',
        description: detectTaskName,
        metadata: {
          modelId: this.state.selectedTab === ENSEMBLE_MODEL ? '' : String(modelId),
          engine,
          parameterId: String(parameterFileId),
          listName: detectTaskName,
          originalAssetId: String(detectAssetId),
          resultId: String(resultId),
          ensembleModelFileId: this.state.selectedTab === ENSEMBLE_MODEL ? String(modelId) : '',
          managementEquipmentId: String(managementEquipmentId),
        },
      };

      await createEvent(EP_PATH_AI_DETECT_EXECUTE, event);
    }

    /**
     * 検出パラメータファイルを作成し、検出タスクを登録する。
     */
    async createDetectTask() {
      this.setState({ confirmLoading: true, errorMessage: undefined });

      const { form } = this.props;
      let modelId;
      let classes;
      let colorPattern;
      let confidence;
      let detectTaskName;
      let validateResult;
      const { selectedTab } = this.state;
      try {
        if (selectedTab === STANDARD_MODEL) {
          modelId = this.state.learningProjectsWithModels.selectedItems[CASCADER_MODEL_ID];
          classes = this.state.classes.selectedItems;
          colorPattern = this.state.standardModelColorPattern.selectedItem;
          confidence = this.state.confidence.selectedItem;
          detectTaskName = this.standardDetectTaskNameRef.current.state.value;
          validateResult = validateDetectParametersForStandard({ modelId, classes, detectTaskName });
        } else {
          modelId = this.state.ensembleModels.selectedItems[CASCADER_MODEL_ID];
          classes = [];
          colorPattern = this.state.ensembleModelColorPattern.selectedItem;
          detectTaskName = this.ensembleDetectTaskNameRef.current.state.value;
          validateResult = validateDetectParametersForEnsemble({ modelId, detectTaskName });
        }

        if (validateResult.hasError) {
          const { errorField, errorValue, message } = validateResult;
          form.setFields({
            [errorField]: { value: errorValue, errors: [new Error(message)] },
          });

          this.setState({ confirmLoading: false });

          return;
        }

        const engine = await this.loadEngine(modelId);

        // パラメータファイル登録
        const parameterFileId = await this.saveParameterJson({
          modelId, engine, classes, colorPattern, confidence, detectTaskName,
        });

        // DetectTask(Event)の登録
        await this.saveDetectTask({
          modelId, engine, detectTaskName, parameterFileId,
        });
      } catch (e) {
        const errorCode = e.errors && e.errors[0].status ? e.errors[0].status : '-';
        this.setState({ confirmLoading: false, errorMessage: `${ERROR_ADD_AI_DETECT_TASK}${errorCode}` });
        return;
      }

      this.setState({ confirmLoading: false });

      // タスクの登録が完了後、呼び出し元の処理を呼び出す。
      this.props.onSuccessStartDetect();
    }

    /**
     * 学習プロジェクト/通常モデルカスケード変更イベントハンドラ
     * @param {array} value 変更後の選択項目配列
     */
    handleChangeLearningProjectWithModels = (value) => {
      // エラーステータスを解除
      this.props.form.setFields({ standardModel: { value } });

      const [, modelId] = value; // [projectId, modelId]で渡される

      // 確信度セレクトボックス作成
      this.generateConfidence(modelId);
      // カラーパターンセレクトボックス作成
      this.generateColorPattern(modelId, STANDARD_MODEL);
      // クラスセレクトボックス作成
      this.generateClasses(modelId);

      this.setState({ learningProjectsWithModels: { ...this.state.learningProjectsWithModels, selectedItems: value } });
    };

    /**
     * 学習プロジェクト/アンサンブルモデルカスケード変更イベントハンドラ
     * @param {array} value 変更後の選択項目配列
     */
    handleChangeEnsembleModels = (value) => {
      // エラーステータスを解除
      this.props.form.setFields({ ensembleModel: { value } });

      const [, modelId] = value; // [projectId, modelId]で渡される

      // カラーパターンセレクトボックス作成
      this.generateColorPattern(modelId, ENSEMBLE_MODEL);

      this.setState({ ensembleModels: { ...this.state.ensembleModels, selectedItems: value } });
    };

    /**
     * クラスセレクトボックス変更イベントハンドラ
     * @param {array} changeClasses 変更後の選択項目配列
     */
    handleChangeClasses = async (changeClasses) => {
      // エラーステータスを解除
      this.props.form.setFields({ standardModelClasses: { value: changeClasses } });

      this.setState({ classesLoad: true });

      const mergeClasses = await this.mergeClasses(changeClasses);
      const classOptions = this.getClassOptions(mergeClasses);

      this.setState({ classesLoad: false, classes: { options: classOptions, selectedItems: changeClasses } });
    };

    /**
     * カラーパターンセレクトボックス変更イベントハンドラ
     * @param {string} changeColorPattern 変更後の選択項目
     */
    handleChangeColorPattern = async (changeColorPattern) => {
      let classes;
      let classOptions;
      // エラーステータスを解除
      if (this.state.selectedTab === STANDARD_MODEL) {
        this.props.form.setFields({ standardModelColorPattern: { value: changeColorPattern } });
        this.setState({ standardModelColorPattern: { ...this.state.standardModelColorPattern, selectedItem: changeColorPattern }, classesLoad: true });

        classes = this.state.classes.selectedItems;
        const mergeClasses = await this.mergeClasses(classes);
        classOptions = this.getClassOptions(mergeClasses);
      } else {
        this.props.form.setFields({ ensembleModelColorPattern: { value: changeColorPattern } });
        this.setState({ ensembleModelColorPattern: { ...this.state.ensembleModelColorPattern, selectedItem: changeColorPattern }, classesLoad: true });

        classes = [];
        classOptions = [];
      }

      this.setState({ classesLoad: false, classes: { options: classOptions, selectedItems: classes } });
    };

    /**
     * 確信度セレクトボックス変更イベントハンドラ
     * @param {string} value 変更後の選択項目
     */
    handleChangeConfidence = (value) => {
      this.setState({ confidence: { ...this.state.confidence, selectedItem: value } });
    };

    /**
     * AI検出開始ボタンクリックイベントハンドラ
     */
    handleClickOk = async () => {
      await this.createDetectTask();
    };

    /**
     * キャンセルイベントハンドラ
     * 閉じるボタン(☓) or モーダルダイアログ外をクリックした際に呼び出される。
     */
    handleClickCancel = () => this.props.modalCancel();

    /**
     * タブクリックイベントハンドラ
     * @param {string} value standardModel / ensembleModel
     */
    onTabClick = (value) => {
      this.setState({ selectedTab: value });
    };

    /**
   * パラメータ設定フォーム
   * @param {string} modelType 選択モデルタイプ 通常モデル / アンサンブルモデル
   * @returns {object} パラメータ設定フォーム
   */
    settingParameterForm(modelType) {
      const itemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };
      const {
        learningProjectsWithModels,
        learningProjectsWithModelsLoad,
        confidence,
        confidenceLoad,
        classes,
        classesLoad,
        standardModelColorPattern,
        ensembleModelColorPattern,
        colorPatternLoad,
        ensembleModels,
        ensembleModelsLoad,
      } = this.state;

      const { form } = this.props;
      const { getFieldDecorator } = form;

      let cascaderLoad;
      let cascaderModels;
      let cascaderOnChange;
      let modelColorPattern;
      let detectTaskNameRef;
      if (modelType === STANDARD_MODEL) {
        cascaderLoad = learningProjectsWithModelsLoad;
        cascaderModels = learningProjectsWithModels;
        cascaderOnChange = this.handleChangeLearningProjectWithModels;
        modelColorPattern = standardModelColorPattern;
        detectTaskNameRef = this.standardDetectTaskNameRef;
      } else {
        cascaderLoad = ensembleModelsLoad;
        cascaderModels = ensembleModels;
        cascaderOnChange = this.handleChangeEnsembleModels;
        modelColorPattern = ensembleModelColorPattern;
        detectTaskNameRef = this.ensembleDetectTaskNameRef;
      }

      return (
        <Form layout="horizontal">
          <Form.Item label="学習プロジェクト / モデル" {...itemLayout}>
            {getFieldDecorator(modelType)(
              <Spin spinning={cascaderLoad} indicator={LoadingIcon}>
                <Cascader
                  value={cascaderModels.selectedItems}
                  options={cascaderModels.options}
                  onChange={cascaderOnChange}
                  placeholder="学習プロジェクト / モデルを選択してください"
                />
              </Spin>,
            )}
          </Form.Item>
          <Form.Item label="クラス" {...itemLayout}>
            {getFieldDecorator(`${modelType}Classes`)(
              <Spin spinning={classesLoad} indicator={LoadingIcon}>
                {modelType === STANDARD_MODEL
                  ? <Select mode="multiple" value={classes.selectedItems} onChange={this.handleChangeClasses} aria-label="classes">{classes.options}</Select>
                  : <Select disabled mode="multiple" aria-label="classes"></Select>
                }
              </Spin>,
            )}
          </Form.Item>
          <Form.Item label="カラーパターン" {...itemLayout}>
            {getFieldDecorator(`${modelType}ColorPattern`)(
              <Spin spinning={colorPatternLoad} indicator={LoadingIcon}>
                <Select value={modelColorPattern.selectedItem} onChange={this.handleChangeColorPattern} aria-label={`${modelType}ColorPattern`}>{modelColorPattern.options}</Select>
              </Spin>,
            )}
          </Form.Item>
          <Form.Item label="確信度" {...itemLayout}>
            {getFieldDecorator(`${modelType}Confidence`)(
              <Spin spinning={confidenceLoad} indicator={LoadingIcon}>
                {modelType === STANDARD_MODEL
                  ? <Select value={confidence.selectedItem} onChange={this.handleChangeConfidence} disabled={confidence.disabled} aria-label="confidence">{confidence.options}</Select>
                  : <Select disabled aria-label="confidence"></Select>
                }
              </Spin>,
            )}
          </Form.Item>
          <Form.Item label="検出タスク名" {...itemLayout}>
            {getFieldDecorator(`${modelType}DetectTaskName`)(
              <>
                <Input ref={detectTaskNameRef} aria-label="検出タスク名" />
              </>,
            )}
          </Form.Item>
        </Form>
      );
    }

    /**
     * レンダリング直後に一度だけ呼ばれる。
     */
    componentDidMount() {
      this.generateSelectParameters();
    }

    render() {
      const {
        confirmLoading,
        errorMessage,
      } = this.state;
      const { visible } = this.props;

      return (
        <Modal
          width={800}
          title="AI検出パラメータ設定"
          centered
          visible={visible}
          onCancel={this.handleClickCancel}
          onOk={this.handleClickOk}
          confirmLoading={confirmLoading}
          okText="AI検出開始"
          okButtonProps={{ block: true, style: { margin: '0px' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Tabs defaultActiveKey={STANDARD_MODEL} onTabClick={this.onTabClick}>
            <TabPane tab="通常モデル" key={STANDARD_MODEL}>
              {this.settingParameterForm(STANDARD_MODEL)}
            </TabPane>
            <TabPane tab="アンサンブルモデル" key={ENSEMBLE_MODEL}>
              {this.settingParameterForm(ENSEMBLE_MODEL)}
            </TabPane>
          </Tabs>
          {errorMessage && <ErrorMessages>{errorMessage}</ErrorMessages>}
        </Modal>
      );
    }
  },
);

export default SettingDetectParameterDialog;
