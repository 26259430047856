/* eslint-disable react/no-array-index-key */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-return-assign */
/* eslint-disable default-case */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable no-continue */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-var-requires */
// eslint警告未対応
// revealのメソッドにアンダースコアがあるため無効化
/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  Layout, Card, Checkbox, Col, Empty, Button, Spin, Tooltip, Switch, Tabs,
} from 'antd';
import { PlayCircleOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import { Cognite3DViewer } from '@cognite/reveal';
import * as THREE from 'three';
import { HttpError } from '@cognite/sdk';
import axios from 'axios';
import styled from 'styled-components';
import { Buffer } from 'buffer';
import AssetTree from '../Common/Asset/AssetTree';
import { getStorageParentAssetId, getStorageParentAssetType } from '../../utils/storageCommon';
import da from '../../utils/dataAccess';
import CameraResetButton from './CameraResetButton';

import { ImagesListViewer, LIST_TYPE } from '../util/ImagesListViewer';
import { FileDetailView } from '../Common/File/FileDetailView';
import { getFileListEndpoint, getResourceType, ResourceType } from '../../utils/common/SDFDataType';
import { MimeType, CAPTURED_IMAGE, DETECTION_RESULT } from '../../utils/File/BaseFile';
import {
  EP_PATH_MANAGED_FACILITY_FILES_LIST,
} from '../../utils/AWS/EndpointPath';
import './DigitalTwinApp.css';
import { DIGITAL_TWIN_APP } from '../Common/File/FileTable';
import { getDownloadUrl } from '../../utils/AWS/AWSRequest';
import { getManagedFacilityFileDownloadUrl, getManagedFacilityFiles } from '../../utils/File/ManagedFacilityFile';
import { MetadataBaseInfo } from '../Common/Metadata/MetadataBaseInfo';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import { ERROR_NO_AUTH_MESSAGE } from '../../utils/messages';

const utmObj = require('utm-latlng');

const utm = new utmObj();

const { TabPane } = Tabs;
const { Content } = Layout;

/**
 * 3DViewer配下のボタンを格納するコンテナ
 */
const ButtonContainer = styled.div`
  margin-top: 10px;
`;

/**
 * 属性情報下の余白作成用コンテナ
 */
const MetadataContainer = styled.div`
  margin-top: 17px;
`;

const { Jpeg, Png } = MimeType;

/**
 * 3D Viewer画面component
 * @property {object} client CogniteClient
 * @property {number} parentAssetsId AssetID
 * @property {function} setLoadingFlag Load Flag設定関数
 */
class DigitalTwinApp extends React.Component {
  constructor(props) {
    super(props);
    this.listSelectRef = React.createRef();
  }

  state = {
    parentAssetsId: getStorageParentAssetId(),
    selectedAssetId: getStorageParentAssetId(),
    selectedAssetType: getStorageParentAssetType(),
    selectedResourceType: getStorageParentAssetType(),
    selectedFileType: CAPTURED_IMAGE,
    selectedFileId: null,
    layersReady: false,
    layerOptions: {},
    showViewer: false,
    playloading: false,
    nonConfig: false,
    panoramaLoading: false,
    displaySwitchDisabled: true,
    displaySwitchChange: false,
    treeCollapsed: true,
    isReloadFacilityDisabled: true,
    isAddFacilityDisabled: true,
    isReadFacilityDisabled: true,
    isEditFacilityDisabled: true,
    is3DViewDisabled: true,
    isCloseButtonDisabled: true,
    isAssetMetadataTabDisabled: true,
    isFileTabDisabled: true,
    isPointCloudDisabled: true,
    isMeshDisabled: true,
    isShootingPointDisabled: true,
    is3DModelDisplayDisabled: true,
    isCameraResetDisabled: true,
    isModalEditButtonDisabled: true,
    isModalDownloadButtonDisabled: true,
  };

  /**
   * レンダリング直後に一度だけ呼ばれる。
   * 3DViewer(再生ボタン押下前)を表示する。
   */
  async componentDidMount() {
    this.props.setLoadingFlag(true);
    const { client } = this.props.client;

    const viewer = new Cognite3DViewer({
      sdk: client,
      domElement: this.viewerContainer,
      pointCloudEffects: {
        pointBlending: true,
        edlOptions: 'disabled',
      },
      loadingIndicatorStyle: {
        placement: 'topLeft',
        opacity: 0.2,
      },
    });
    this.viewer = viewer;
    window.viewer = viewer; // Debug

    this.layerOptions = [];
    this.visibleLayers = [];

    const targetAssetType = getStorageParentAssetType();

    viewer.on('click', this.onClick);
    viewer.on('cameraChange', this.handleCameraChange);
    this.props.setLoadingFlag(false);

    const { EQUIPMENT_DETAILS_EQ, EQUIPMENT_DETAILS_TD } = AUTHENTICATION_TYPE_MATRIX;
    const {
      EQUIPMENT_LIST_MENU_UPDATE_BUTTON,
      EQUIPMENT_LIST_MENU_ADD_BUTTON,
      EQUIPMENT_LIST_MENU_EQUIPMENT_DETAILS_LINK,
      EQUIPMENT_LIST_MENU_EDIT_BUTTON,
      DETAILED_INFORMATION_THREE_D_VIEW,
      DETAILED_INFORMATION_X_BUTTON,
      DETAILED_INFORMATION_ATTRIBUTE_INFORMATION,
      DETAILED_INFORMATION_FILE,
      THREE_D_VIEW_POINT_CLOUD,
      THREE_D_VIEW_MESH,
      THREE_D_VIEW_SHOOTING_POINT,
      THREE_D_VIEW_THREE_D_MODEL_DISPLAY,
      THREE_D_VIEW_CAMERA_RESET,
      EXPANDED_MODAL_EDIT_BUTTON,
      EXPANDED_MODAL_DOWNLOAD_BUTTON,
    } = targetAssetType === 'equipment' ? EQUIPMENT_DETAILS_EQ : EQUIPMENT_DETAILS_TD;

    this.setState({
      layerOptions: this.layerOptions,
      isReloadFacilityDisabled: !await containsUIAuthType(EQUIPMENT_LIST_MENU_UPDATE_BUTTON),
      isAddFacilityDisabled: !await containsUIAuthType(EQUIPMENT_LIST_MENU_ADD_BUTTON),
      isReadFacilityDisabled: !await containsUIAuthType(EQUIPMENT_LIST_MENU_EQUIPMENT_DETAILS_LINK),
      isEditFacilityDisabled: !await containsUIAuthType(EQUIPMENT_LIST_MENU_EDIT_BUTTON),
      is3DViewDisabled: !await containsUIAuthType(DETAILED_INFORMATION_THREE_D_VIEW),
      isCloseButtonDisabled: !await containsUIAuthType(DETAILED_INFORMATION_X_BUTTON),
      isAssetMetadataTabDisabled: !await containsUIAuthType(DETAILED_INFORMATION_ATTRIBUTE_INFORMATION),
      isFileTabDisabled: !await containsUIAuthType(DETAILED_INFORMATION_FILE),
      isPointCloudDisabled: !await containsUIAuthType(THREE_D_VIEW_POINT_CLOUD),
      isMeshDisabled: !await containsUIAuthType(THREE_D_VIEW_MESH),
      isShootingPointDisabled: !await containsUIAuthType(THREE_D_VIEW_SHOOTING_POINT),
      is3DModelDisplayDisabled: !await containsUIAuthType(THREE_D_VIEW_THREE_D_MODEL_DISPLAY),
      isCameraResetDisabled: !await containsUIAuthType(THREE_D_VIEW_CAMERA_RESET),
      isModalEditButtonDisabled: !await containsUIAuthType(EXPANDED_MODAL_EDIT_BUTTON),
      isModalDownloadButtonDisabled: !await containsUIAuthType(EXPANDED_MODAL_DOWNLOAD_BUTTON),
    });
  }

  /**
   * チェックボックス押下時のイベントハンドラ
   * @param {Array} checkedValues チェックのついている項目
   */
  onLayersChanged = (checkedValues) => {
    this.checkedValues = checkedValues;
    this.layerOptions.forEach((l) => {
      this[l.value].visible = checkedValues.includes(l.value);
      if (l.value === 'photoPositionGroup') {
        if (this.panoramaPhotoPositionGroup !== undefined) {
          this.panoramaPhotoPositionGroup.visible = this.photoPositionGroup.visible;
        }
        // 360度写真表示時360度写真撮影点以外を非表示
        if (this.skyBox) {
          if (this.skyBox.visible) {
            if (this.pc0) {
              this.pc0.pointCloudNode.visible = false;
            }

            if (this.cad0) {
              this.cad0.setDefaultNodeAppearance({ visible: false });
            }

            if (this.photoPositionGroup) {
              this.photoPositionGroup.visible = false;
            }
          }
        }
      } else if (l.value === 'cad0') {
        this[l.value].setDefaultNodeAppearance({ visible: checkedValues.includes(l.value) });
      } else if (l.value.startsWith('pc')) {
        this[l.value].pointCloudNode.visible = checkedValues.includes(l.value);
      }
    }, this);
    this.viewer.requestRedraw();
  };

  /**
   * 3DViewer上にメッシュデータを追加
  */
  addCadModels = async () => {
    if (!('cadModels' in this.config)) {
      return;
    }

    for (const [idx, cadModel] of this.config.cadModels.entries()) {
      const model = await this.viewer.addCadModel({ modelId: cadModel.modelId, revisionId: cadModel.revisionId });

      const positions = cadModel.position || this.config.defaults.position;
      const scales = cadModel.scale || this.config.defaults.scale;
      const makeTranslation = new THREE.Matrix4().makeTranslation(...positions);
      const makeScale = new THREE.Matrix4().makeScale(...scales);

      const modelTransformation = model.getModelTransformation();
      modelTransformation.multiply(makeTranslation);
      modelTransformation.multiply(makeScale);
      model.setModelTransformation(modelTransformation);

      // Debug
      window.cadModels = window.cadModels || [];
      window.cadModels.push(model);

      const cadModelId = `cad${idx}`;
      this[cadModelId] = model;
      this[cadModelId].setDefaultNodeAppearance({ visible: cadModel.visible });

      this.layerOptions.push({ label: cadModel.name, value: cadModelId });
      cadModel.visible && this.visibleLayers.push(cadModelId);
    }
  };

  /**
   * 3DViewer上に点群データを追加
  */
  addPointClouds = async () => {
    if (!('pointClouds' in this.config)) {
      return;
    }

    for (const [idx, pointCloud] of this.config.pointClouds.entries()) {
      const model = await this.viewer.addPointCloudModel({ modelId: pointCloud.modelId, revisionId: pointCloud.revisionId });

      const positions = pointCloud.position || this.config.defaults.position;
      const scales = pointCloud.scale || this.config.defaults.scale;
      const makeTranslation = new THREE.Matrix4().makeTranslation(...positions);
      const makeScale = new THREE.Matrix4().makeScale(...scales);

      const modelTransformation = model.getModelTransformation();
      modelTransformation.multiply(makeTranslation);
      modelTransformation.multiply(makeScale);
      model.setModelTransformation(modelTransformation);

      model.pointCloudNode.visible = pointCloud.visible;
      model.pointSize = pointCloud.pointSize;

      // Debug
      window.pointClouds = window.pointClouds || [];
      window.pointClouds.push(model);

      const pointCloudId = `pc${idx}`;
      this[pointCloudId] = model;

      this.layerOptions.push({ label: pointCloud.name, value: pointCloudId });
      pointCloud.visible && this.visibleLayers.push(pointCloudId);
    }
  };

  /**
   * 撮影点情報を追加
   */
  addPhotoPositions = async () => {
    this.layerOptions.push({ label: '撮影点', value: 'photoPositionGroup' });
    this.visibleLayers.push('photoPositionGroup');
    await this.setPhotoPositions(this.state.selectedAssetId);

    // Debug
    window.photoFiles = this.photoFiles;
    window.photoPositions = this.photoPositions;
  };

  /**
   * 3DViewer上に360度写真の撮影点を追加
   * @param {number} assetId Asset ID
   */
  add360PhotoPositions = async (assetId) => {
    this.panoramaPhotoPositions = [];
    const panoramaPhotoPositionGroup = new THREE.Group();

    const fileFilter = { assetIds: [assetId], mimeType: Png, metadata: { type: 'panorama' } };
    const allFiles = await da.getAllFiles(EP_PATH_MANAGED_FACILITY_FILES_LIST, fileFilter);

    for (const panoramaFile of allFiles) {
      if (!panoramaFile.metadata.position) {
        continue;
      }
      if (!panoramaFile.metadata.scale) {
        continue;
      }
      const geometry = new THREE.SphereGeometry(1);
      const material = new THREE.MeshBasicMaterial({ color: 0x00FFFF });
      const object = new THREE.Mesh(geometry, material);

      const objectPosition = JSON.parse(panoramaFile.metadata.position);
      const objectScale = JSON.parse(panoramaFile.metadata.scale);

      if (
        objectPosition.x === undefined
        || objectPosition.y === undefined
        || objectPosition.z === undefined
      ) {
        continue;
      }
      if (
        objectScale.x === undefined
        || objectScale.y === undefined
        || objectScale.z === undefined
      ) {
        continue;
      }

      object.position.set(objectPosition.x, objectPosition.y, objectPosition.z);
      object.scale.set(objectScale.x, objectScale.y, objectScale.z);
      panoramaPhotoPositionGroup.add(object);

      this.panoramaPhotoPositions.push({ object, fileInfo: panoramaFile });
    }

    if (this.checkedValues) {
      panoramaPhotoPositionGroup.visible = this.checkedValues.includes('photoPositionGroup');
    } else {
      panoramaPhotoPositionGroup.visible = true;
    }

    const { children } = this.viewer._sceneHandler.scene;
    if (children.includes(this.panoramaPhotoPositionGroup)) {
      const panoramaPhotoPositionGroupIndex = children.indexOf(this.panoramaPhotoPositionGroup);
      // 既存の360度撮影点を削除し、再度追加を行わないと画面に表示されない
      children.splice(panoramaPhotoPositionGroupIndex, 1);
      this.viewer.addObject3D(panoramaPhotoPositionGroup);
      if (!this.state.displaySwitchDisabled) this.handleClickCameraReset();
    } else {
      this.viewer.addObject3D(panoramaPhotoPositionGroup);
    }
    this.panoramaPhotoPositionGroup = panoramaPhotoPositionGroup;
  };

  /**
   * 3DViewer上に平面写真の撮影点を追加
   * @param {number} assetId Asset ID
   */
  setPhotoPositions = async (assetId) => {
    this.photoFiles = [];
    this.photoPositions = [];

    const photoPositionGroup = new THREE.Group();

    // Asset IDに紐づくFile Assetをすべて取得
    const fileFilter = { assetIds: [assetId], mimeType: Jpeg };

    const { selectedResourceType } = this.state;
    const endpoint = getFileListEndpoint(selectedResourceType);
    const allFiles = await da.getAllFiles(endpoint, fileFilter);

    allFiles.forEach((file) => {
      if (!file.metadata) {
        return;
      }
      const altitude = file.metadata['高度'];
      const latitude = file.metadata['緯度'];
      const longitude = file.metadata['経度'];
      if (!(altitude && latitude && longitude)) {
        return;
      }

      const utmPosition = utm.convertLatLngToUtm(latitude, longitude, 3);

      const geometry = new THREE.ConeGeometry(1, 1, 4);
      const material = new THREE.MeshBasicMaterial({ color: 0x00FFFF });
      const cone = new THREE.Mesh(geometry, material);

      cone.position.set(utmPosition.Easting, Number(altitude), -utmPosition.Northing);
      photoPositionGroup.add(cone);

      this.photoFiles.push(file);
      this.photoPositions.push(cone);
    });

    if (this.checkedValues) {
      photoPositionGroup.visible = this.checkedValues.includes('photoPositionGroup');
    } else {
      photoPositionGroup.visible = true;
    }

    photoPositionGroup.position.set(...this.config.defaults.position);
    photoPositionGroup.scale.set(...this.config.defaults.scale);

    const { children } = this.viewer._sceneHandler.scene;
    if (children.includes(this.photoPositionGroup)) {
      const photoPositionGroupIndex = children.indexOf(this.photoPositionGroup);
      // 既存の平面撮影点を削除し、再度追加を行わないと画面に表示されない
      children.splice(photoPositionGroupIndex, 1);
      this.viewer.addObject3D(photoPositionGroup);
    } else {
      this.viewer.addObject3D(photoPositionGroup);
    }

    this.photoPositionGroup = photoPositionGroup;
  };

  /**
   * イベント有効化処理
   * @param {String} type イベントタイプ
   * @param {Function} callback コールバック関数
   */
  activateEvent = (type, callback) => this.viewer.domElement.addEventListener(type, callback);

  /**
   * イベント無効化処理
   * @param {String} type イベントタイプ
   * @param {Function} callback コールバック関数
   */
  deactivateEvent = (type, callback) => this.viewer.domElement.removeEventListener(type, callback);

  /**
   * マウスホイール操作とPan(平行移動)処理の制御解除と右クリック有効処理
   */
  activateWheelWithPan = () => {
    // Pan(平行移動)処理の制御を解除する
    this.deactivateEvent('mousedown', this.handleMousedown);
    this.activateMouseEvent();
  };

  /**
   * マウスホイール操作とPan(平行移動)処理の制御と右クリック無効処理
   */
  deactivateWheelWithPan = () => {
    this.deactivateMouseEvent();
    this.activateEvent('mousedown', this.handleMousedown);
  };

  /**
   * マウス操作の無効化処理
   */
  deactivateMouseEvent = () => {
    // マウスホイール操作によるZoom Up/Outは無効
    this.deactivateEvent('wheel', this.viewer.cameraManager._controls.onMouseWheel);
    // Pan(平行移動)処理を制御する
    this.deactivateEvent('mousedown', this.viewer.cameraManager._controls.onMouseDown);
    this.deactivateEvent('pointerdown', this.viewer.cameraManager._controls.onPointerDown);
  };

  /**
   * マウス操作の有効化処理
   */
  activateMouseEvent = () => {
    // マウスホイール操作有効
    this.activateEvent('wheel', this.viewer.cameraManager._controls.onMouseWheel);

    // Pan(平行移動)処理の制御を解除する
    this.activateEvent('mousedown', this.viewer.cameraManager._controls.onMouseDown);
    this.activateEvent('pointerdown', this.viewer.cameraManager._controls.onPointerDown);
  };

  /**
   * 画像ファイルをDLし、base64に変換する処理
   * @param {String} url 画像データのURL
   */
  toBase64Obj = async (url) => {
    const imageBuffer = await axios.get(url, { responseType: 'arraybuffer' });
    return {
      url: `data:image/jpeg;base64,${new Buffer.from(imageBuffer.data, 'binary').toString('base64')}`,
    };
  };

  /**
   * 360度写真の作成処理
   * @param {object} values 360度写真の画像情報
   * @param {object} panoramaPhotoPosition 360度写真の撮影点オブジェクト情報
   */
  createCubeTexture = (values, panoramaPhotoPosition) => {
    if (this.skyBox) {
      this.viewer.removeObject3D(this.skyBox);
    }

    const { fileInfo } = panoramaPhotoPosition;

    // CubeTexture設定
    const texture = new THREE.TextureLoader().load(
      values.url, () => { this.viewer.requestRedraw(); },
    );
    const skyBox = new THREE.Mesh(
      new THREE.SphereGeometry(3000, 60, 60),
      new THREE.MeshBasicMaterial({
        side: THREE.BackSide,
        map: texture,
      }),
    );

    const skyBoxPosition = JSON.parse(fileInfo.metadata.position);
    let skyBoxRotation;
    if (!fileInfo.metadata.rotation) {
      skyBoxRotation = { x: 0.0, y: 0.0, z: 0.0 };
    } else {
      skyBoxRotation = JSON.parse(fileInfo.metadata.rotation);
      if (skyBoxRotation.x === undefined) {
        skyBoxRotation.x = 0.0;
      }
      if (skyBoxRotation.y === undefined) {
        skyBoxRotation.y = 0.0;
      }
      if (skyBoxRotation.z === undefined) {
        skyBoxRotation.z = 0.0;
      }
    }

    const matrix4 = new THREE.Matrix4().makeRotationFromEuler(
      new THREE.Euler(
        // ラジアン単位のx軸の角度(ラジアン(radian) = 任意の角度 / 180 * Math.PI)
        (skyBoxRotation.x / 180) * Math.PI,
        // ラジアン単位のy軸の角度(ラジアン(radian) = 任意の角度 / 180 * Math.PI)
        (skyBoxRotation.y / 180) * Math.PI,
        // ラジアン単位のz軸の角度(ラジアン(radian) = 任意の角度 / 180 * Math.PI)
        (skyBoxRotation.z / 180) * Math.PI,
        // 回転が適用される順序を表す文字列
        'XYZ',
      ),
    );
    skyBox.geometry.applyMatrix4(matrix4);

    skyBox.position.set(
      skyBoxPosition.x,
      skyBoxPosition.y,
      skyBoxPosition.z,
    );

    this.skyBox = skyBox;
    this.viewer.addObject3D(this.skyBox);
  };

  /**
   * 360度写真の撮影点を押下した場合の処理
   * @param {Object} obj clickした撮影点オブジェクトの情報
   */
  onClick360PhotoPositions = (obj) => {
    // clickした撮影点オブジェクトの情報
    const clickedPoint = obj.position;
    const targetPoint = clickedPoint.clone();

    if (!this.clickedObj) {
      // 点群データ => 撮影点表示の場合

      // positionとtargetの値が同じだと回転がおかしくなるため少しずらして設定
      targetPoint.x > 0 ? targetPoint.x += 0.1 : targetPoint.x -= 0.1;
      targetPoint.z > 0 ? targetPoint.z += 0.1 : targetPoint.z -= 0.1;
    } else {
      // 撮影点 => 撮影点表示の場合

      // 移動元の非表示解除
      this.clickedObj.visible = true;

      // 移動前のCameraPosition, Targetから向いている方向を特定
      const cameraState = this.viewer.cameraManager.getCameraState();
      const cameraPosition = cameraState.position;
      const cameraTarget = cameraState.target;

      const direction = cameraPosition.sub(cameraTarget); // 向いている方向(座標) = position - target
      targetPoint.add(direction);
    }
    this.clickedTarget = null;
    this.viewer.cameraManager.setCameraState({ position: targetPoint, target: clickedPoint });
    this.clickedTarget = clickedPoint;
  };

  /**
   * クリックした場所に平面写真の撮影点が存在するか確認する処理
   * @param {Object} event offsetXとoffsetYを取得
   */
  clickPhotoPositionsCheck = async (event) => {
    const { offsetX, offsetY } = event;
    const raycaster = new THREE.Raycaster();

    const otherPickResult = ((objects) => {
      const coords = {
        x: (offsetX / this.viewerContainer.clientWidth) * 2 - 1,
        y: (offsetY / this.viewerContainer.clientHeight) * -2 + 1,
      };
      raycaster.setFromCamera(coords, this.viewer.cameraManager.getCamera());
      const intersections = raycaster.intersectObjects(objects);
      if (intersections.length === 0) {
        return;
      }
      return intersections[0];
    })(this.photoPositions);

    if (otherPickResult) {
      if (!this.photoPositionGroup.visible) {
        return;
      }
      const photo = otherPickResult.object;
      const clickPositionIdx = this.photoPositions.indexOf(photo);
      this.setState({ selectedFileId: this.photoFiles[clickPositionIdx].id });
      this.changePhotoPositionColor(clickPositionIdx);
    }
  };

  /**
   * クリックした場所に360度写真の撮影点が存在するか確認する処理
   * @param {Object} event offsetXとoffsetYを取得
   */
  click360PhotoPositionsCheck = (event) => {
    const { offsetX, offsetY } = event;
    const raycaster = new THREE.Raycaster();

    const otherPickResult = ((objects) => {
      const coords = {
        x: (offsetX / this.viewerContainer.clientWidth) * 2 - 1,
        y: (offsetY / this.viewerContainer.clientHeight) * -2 + 1,
      };
      raycaster.setFromCamera(coords, this.viewer.cameraManager.getCamera());
      const intersections = raycaster.intersectObjects(objects);
      if (intersections.length === 0) {
        return;
      }
      return intersections[0];
    })(this.panoramaPhotoPositions.map((panoramaPoint) => panoramaPoint.object));

    if (!otherPickResult) {
      return;
    }

    const obj = otherPickResult.object;
    if (!obj.visible) {
      // 非表示状態のobjの場合、何も処理をしない
      return;
    }

    return obj;
  };

  /**
   * 3DViewer上を押下した場合の処理
   * @param {Object} event offsetXとoffsetYを取得
   */
  onClick = async (event) => {
    // クリックした場所にオブジェクトがあるか判断
    this.clickPhotoPositionsCheck(event);
    const obj = this.click360PhotoPositionsCheck(event);
    if (obj === undefined) {
      return;
    }

    this.setState({ panoramaLoading: true });

    const panoramaPhotoPosition = this.panoramaPhotoPositions.find((panoramaPoint) => panoramaPoint.object === obj);

    try {
      // 360度写真の取得
      const { selectedResourceType } = this.state;
      const { id } = panoramaPhotoPosition.fileInfo;
      const url = await getDownloadUrl(selectedResourceType, id);

      this.toBase64Obj(url).then((values) => {
        // Switchボタンの有効化とpointCloudsからpanoramaへの切り替え
        this.setState({
          displaySwitchDisabled: false,
          displaySwitchChange: true,
        });

        // 撮影点をクリックしたときの処理
        this.createCubeTexture(values, panoramaPhotoPosition);
        this.onClick360PhotoPositions(obj);

        if (this.pc0) {
          // 点群データ非表示
          this.pc0.pointCloudNode.visible = false;
        }

        if (this.cad0) {
          // メッシュデータ非表示
          this.cad0.setDefaultNodeAppearance({ visible: false });
        }

        if (this.photoPositionGroup) {
          // 平面写真撮影点非表示
          this.photoPositionGroup.visible = false;
        }

        if (this.selectedObject) {
          // 選択している平面写真の撮影点が存在する場合、色を元に戻す
          this.selectedObject.material.color = new THREE.Color(0x00FFFF);
        }

        // 撮影点が重なって表示されるので選択した撮影点objectは非表示にする。
        obj.visible = false;
        this.clickedObj = obj;

        // マウスホイール、Pan操作無効化
        this.deactivateWheelWithPan();

        this.setState({ panoramaLoading: false });
      });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * 3Dモデル⇔360度写真Switchボタンを押下した場合の処理
   */
  onClickSwitchButton = () => {
    if (this.skyBox) {
      if (!this.skyBox.visible) {
        // 点群データ => 360度写真
        this.skyBox.visible = true;
        if (this.pc0) {
          // 点群データ非表示
          this.pc0.pointCloudNode.visible = false;
        }

        if (this.cad0) {
          // メッシュデータ非表示
          this.cad0.setDefaultNodeAppearance({ visible: false });
        }

        if (this.photoPositionGroup) {
          // 平面写真撮影点非表示
          this.photoPositionGroup.visible = false;
        }

        this.setState({
          displaySwitchChange: true,
        });

        // マウスホイール、Pan操作無効化
        this.deactivateWheelWithPan();
      } else {
        // 360度写真 => 点群データ
        // データのパターンによって表示する3Dモデルが変わる
        if (!this.checkedValues) {
          // 点群、メッシュ両方表示データの場合
          if (this.pc0 && this.cad0) {
            this.pc0.pointCloudNode.visible = false;
            this.cad0.setDefaultNodeAppearance({ visible: true });
            this.photoPositionGroup.visible = true;
          }

          // 点群のみ表示データの場合
          if (this.pc0 && !this.cad0) {
            this.pc0.pointCloudNode.visible = true;
            this.photoPositionGroup.visible = true;
          }

          // メッシュのみ表示データの場合
          if (!this.pc0 && this.cad0) {
            this.cad0.setDefaultNodeAppearance({ visible: true });
            this.photoPositionGroup.visible = true;
          }
        }
        this.skyBox.visible = false;
        this.checkedObjDisplay();
        this.setState({
          displaySwitchChange: false,
        });

        // マウスホイール、Pan操作有効化
        this.activateWheelWithPan();
      }
    }

    this.viewer.requestRedraw();
  };

  /**
   * カメラの位置に変更があった場合の処理
   * @param {Vector3} position
   * @param {Vector3} target
   */
  handleCameraChange = (position, target) => {
    if (this.clickedTarget && !this.clickedTarget.equals(target)) {
      // 撮影点選択時に設定したTargetから位置の変更があった場合、表示切替ボタンを非活性化
      this.setState({ displaySwitchDisabled: true });
      if (this.clickedObj !== undefined) {
        // 移動元の非表示解除
        this.clickedObj.visible = true;
      }
      this.clickedObj = undefined;
    }
  };

  /**
   * 右クリック無効処理
   * @param {MouseEvent} event
   */
  handleMousedown = (event) => {
    if (THREE.MOUSE.RIGHT === event.button) {
      return;
    }

    // ComboControls.startMouseRotation()呼び出し
    this.viewer.cameraManager._controls.startMouseRotation(event);
  };

  /**
   * 選択した360度写真撮影点に対する処理
   */
  clearPhotoPosition = () => {
    if (this.clickedObj !== undefined) {
      this.clickedObj.visible = true;
      this.clickedObj = undefined;
      this.clickedTarget = null;
      if (this.skyBox.visible) {
        this.clearPanorama();
      }
    }
  };

  /**
   * 360度写真表示時にカメラリセットボタンを押下した場合の処理
   */
  clearPanorama = () => {
    this.skyBox.visible = false;
    this.setState({
      displaySwitchChange: false,
    });
  };

  /**
   * チェックボックスにチェックがついているもののみを表示させる処理
   */
  checkedObjDisplay = () => {
    if (this.checkedValues !== undefined) {
      this.layerOptions.forEach((l) => {
        this[l.value].visible = this.checkedValues.includes(l.value);
        if (l.value === 'cad0') {
          this[l.value].setDefaultNodeAppearance({ visible: this.checkedValues.includes(l.value) });
        } else if (l.value.startsWith('pc')) {
          this[l.value].pointCloudNode.visible = this.checkedValues.includes(l.value);
        }
      }, this);
    }
  };

  /**
   * カメラリセットボタン押下処理
   * カメラの表示位置を初期表示位置に変更する。
   */
  handleClickCameraReset = () => {
    // データのパターンによって表示する3Dモデルが変わる
    if (this.checkedValues === undefined) {
      // 点群、メッシュ両方表示データの場合
      if (this.pc0 && this.cad0) {
        this.pc0.pointCloudNode.visible = false;
        this.cad0.setDefaultNodeAppearance({ visible: true });
        this.photoPositionGroup.visible = true;
      }

      // 点群のみ表示データの場合
      if (this.pc0 && !this.cad0) {
        this.pc0.pointCloudNode.visible = true;
        this.photoPositionGroup.visible = true;
      }

      // メッシュのみ表示データの場合
      if (!this.pc0 && this.cad0) {
        this.cad0.setDefaultNodeAppearance({ visible: true });
        this.photoPositionGroup.visible = true;
      }
    }
    this.clearPhotoPosition();
    this.checkedObjDisplay();

    // 初期表示状態にするのでSwitchボタンを無効化
    this.setState({
      displaySwitchDisabled: true,
    });
    // マウスホイール、Pan操作有効化
    this.activateWheelWithPan();
    // カメラポジション初期化
    this.initializeCameraPosition();
  };

  /**
   * ３Ｄモデル、点群、撮影点の表示処理
   */
  initializeScene = async () => {
    const url = await this.getConfigFileUrl(this.state.parentAssetsId);
    if (!url) {
      this.setState({ nonConfig: true });
      return;
    }

    const response = await fetch(url);
    if (!response.ok) {
      const headers = {};
      response.headers.forEach((key, value) => {
        headers[key] = value;
      });
      throw new HttpError(response.status, response.body, headers);
    }
    const configFile = await response.json();
    if (!('camera' in configFile) || !('defaults' in configFile)) {
      this.setState({ nonConfig: true });
      return;
    }

    this.config = configFile;
    this.initializeCameraPosition();

    this.setState({ layersReady: true });

    await this.addPointClouds();
    await this.addCadModels();
    await this.addPhotoPositions();
    await this.add360PhotoPositions(this.state.selectedAssetId);

    if (this.state.selectedFileId) {
      this.changePhotoPositionColor(this.photoFiles.findIndex((photoFile) => photoFile.id === this.state.selectedFileId));
    }
  };

  /**
   * カメラの表示位置を初期化する。
   * 表示する位置はconfig.jsonから取得し、設定する項目は下記のとおり。
   * - camera.position
   * - camera.target
   */
  initializeCameraPosition = () => {
    const position = new THREE.Vector3(...this.config.camera.position);
    const target = new THREE.Vector3(...this.config.camera.target);
    this.viewer.cameraManager.setCameraState({ position, target });
  };

  /**
   * ConfigFileの取得
   * @param {number} assetId Asset ID
   * @return {url} config FileのURL
   */
  getConfigFileUrl = async (assetId) => {
    let url;
    const scope = {
      filter: {
        assetIds: [assetId],
        metadata: { type: 'equipmentConf' },
      },
      limit: 1,
    };
    const files = await getManagedFacilityFiles(scope);
    const item = files.items[0];
    if (item) {
      url = await getManagedFacilityFileDownloadUrl(item.id);
    }
    return url;
  };

  /**
   * Checkboxの項目取得
   * @param {Array} options
   * @returns {Array} Checkboxの項目
   */
  getCheckBoxItems = (options) => {
    const { isPointCloudDisabled, isMeshDisabled, isShootingPointDisabled } = this.state;
    const checkBoxItems = options.map((option, index) => {
      let checkBoxCondition;
      switch (option.label) {
        case '点群':
          checkBoxCondition = isPointCloudDisabled;
          break;
        case 'メッシュ':
          checkBoxCondition = isMeshDisabled;
          break;
        case '撮影点':
          checkBoxCondition = isShootingPointDisabled;
          break;
      }
      return (
        <div key={`checkBoxItems${index}`}>
          <Tooltip title={checkBoxCondition && ERROR_NO_AUTH_MESSAGE}>
            <Checkbox value={option.value} disabled={checkBoxCondition}>{option.label}</Checkbox>
          </Tooltip>
        </div>
      );
    });
    return checkBoxItems;
  };

  /**
   * Asset選択時のイベントハンドラ
   * @param {object} asset 選択されたAsset
   */
  onSelectAsset = async (asset) => {
    const { id, metadata: { assetType } } = asset;
    const { selectedFileType } = this.state;

    // ファイル種別で「AI検出結果」を選択している場合、リソース種別はAI検出結果固定
    const resourceType = selectedFileType === DETECTION_RESULT
      ? ResourceType.AIDetectResult
      : getResourceType(assetType);

    if (this.config) {
      await this.setPhotoPositions(id);
      await this.add360PhotoPositions(id);
    }

    this.setState({
      selectedAssetId: id,
      selectedAssetType: assetType,
      selectedResourceType: resourceType,
      selectedFileId: null,
    });
  };

  /**
   * Imageクリック時のイベントハンドラ
   * @param {number} fileId 選択されたImageのID
   */
  onClickImage = async (fileId) => {
    this.setState({
      selectedFileId: fileId,
    });
    // 3DViewerの撮影点をハイライト表示
    if (this.state.showViewer) {
      this.changePhotoPositionColor(this.photoFiles.findIndex((photoFile) => photoFile.id === fileId));
    }
  };

  /**
   * 3DViewerの再生ボタン押下時のイベントハンドラ
   */
  onClickPlay3DViewer = async () => {
    this.setState({ playloading: true });
    await this.initializeScene();
    this.setState({ playloading: false, showViewer: true });
  };

  /**
   * ファイル種別変更時のイベントハンドラ
   * @param {number} fileType ファイル種別
   */
  handleChangeFileType = (fileType) => {
    const { selectedAssetType } = this.state;
    // ファイル種別で「AI検出結果」を選択している場合、リソース種別はAI検出結果固定
    const resourceType = fileType === DETECTION_RESULT
      ? ResourceType.AIDetectResult
      : getResourceType(selectedAssetType);

    this.setState({
      selectedFileType: fileType,
      selectedResourceType: resourceType,
    });
  };

  /**
   * Asset開閉時のイベントハンドラ
   * @param {boolean} treeCollapsed true: 設備詳細展開 false: 設備詳細縮小
   */
  onCollapseTree = (treeCollapsed) => {
    this.setState({
      treeCollapsed,
    });
  };

  /**
   * 撮影点オブジェクトの色を変更する。
   * @param {number} index 変更するオブジェクトのindex
   */
  async changePhotoPositionColor(index) {
    if (this.selectedObject) {
      // ひとつ前に選択しているobjectが存在する場合、色を元に戻す
      this.selectedObject.material.color = new THREE.Color(0x00FFFF);
    }

    if (this.photoPositionGroup.children[index]) {
      this.photoPositionGroup.children[index].material.color = new THREE.Color(0xFF00FF);
      this.selectedObject = this.photoPositionGroup.children[index];
    }
    this.viewer.requestRedraw();
  }

  /**
   * 設備詳細画面をレンダリングする
   */
  render() {
    const {
      is3DViewDisabled,
      isCloseButtonDisabled,
      isAssetMetadataTabDisabled,
      isFileTabDisabled,
      isReadFacilityDisabled,
      isReloadFacilityDisabled,
      isAddFacilityDisabled,
      isEditFacilityDisabled,
      is3DModelDisplayDisabled,
      isCameraResetDisabled,
      isModalEditButtonDisabled,
      isModalDownloadButtonDisabled,
    } = this.state;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <div className="facility-tree">
          <AssetTree
            client={this.props.client.client}
            parentAssetsId={this.state.parentAssetsId}
            title="3D Viewer"
            editable
            onSelect={async (facility) => {
              if (!isReadFacilityDisabled) {
                await this.onSelectAsset(facility);
              }
            }}
            collapsed={this.state.treeCollapsed}
            onCollapse={(value) => {
              this.onCollapseTree(value);
            }}
            menuCollapsed={this.props.menuCollapsed}
            rootResourceType={ResourceType.ManagedFacility}
            isExpandedRoot
            isReloadDisabled={isReloadFacilityDisabled}
            isAddDisabled={isAddFacilityDisabled}
            isReadDisabled={isReadFacilityDisabled}
            isEditDisabled={isEditFacilityDisabled}
          />
        </div>
        <Content className="main">
          <Col span={24}>
            <Card bordered={false} style={{ marginLeft: '10px', marginTop: '8px' }}>
              <Col span={18}>
                {this.state.nonConfig
                  ? <Empty style={{ height: '600px', width: '100%' }} />
                  : (
                    <div>
                      {!this.state.showViewer && (
                        <div style={{
                          paddingLeft: '45%', paddingTop: '22%', position: 'absolute', zIndex: 2,
                        }}
                        >
                          <Spin spinning={this.state.playloading} indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />}>
                            {!this.state.playloading && (
                              <Tooltip title={is3DViewDisabled ? ERROR_NO_AUTH_MESSAGE : 'Play 3D Viewer'}>
                                <Button
                                  type="link"
                                  className="digital-twin-app-play-3D-viewer"
                                  onClick={this.onClickPlay3DViewer}
                                  disabled={is3DViewDisabled}
                                >
                                  <PlayCircleOutlined style={{ fontSize: 100 }} />
                                </Button>
                              </Tooltip>
                            )}
                          </Spin>
                        </div>
                      )}
                      <Spin tip="Loading..." spinning={this.state.panoramaLoading}>
                        <div ref={(el) => this.viewerContainer = el} className="viewerContainer" aria-label="viewerContainer" style={{ height: '600px', width: '100%', zIndex: 1 }} />
                      </Spin>
                    </div>
                  )}
              </Col>
              <Col span={6} style={{ position: 'relative' }}>
                <Tooltip title={isCloseButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                  <Button
                    onClick={() => this.onCollapseTree(false)}
                    className="digital-twin-app-ant-btn"
                    disabled={isCloseButtonDisabled}
                  >
                    <CloseOutlined />
                  </Button>
                </Tooltip>
                {
                  this.state.layersReady ? (
                    <Card className="digital-twin-app-ant-card" bordered>
                      <div>
                        <div>表示/非表示切替</div>
                        <Checkbox.Group defaultValue={this.visibleLayers} onChange={this.onLayersChanged}>
                          {this.getCheckBoxItems(this.state.layerOptions)}
                        </Checkbox.Group>
                        {
                          this.layerOptions.length > 0 ? (
                            <ButtonContainer>
                              <div style={{ marginBottom: '5px' }}>
                                <Tooltip title={is3DModelDisplayDisabled && ERROR_NO_AUTH_MESSAGE}>
                                  <Switch
                                    disabled={this.state.displaySwitchDisabled || is3DModelDisplayDisabled}
                                    checked={this.state.displaySwitchChange}
                                    onClick={this.onClickSwitchButton}
                                    style={{ marginRight: '10px' }}
                                  />
                                </Tooltip>
                                {this.state.displaySwitchChange ? '360度写真表示' : '3Dモデル表示'}
                              </div>
                              <Tooltip title={isCameraResetDisabled && ERROR_NO_AUTH_MESSAGE}>
                                <div style={{ margin: '5px 0px' }}>
                                  <CameraResetButton disabled={!this.state.showViewer || isCameraResetDisabled} onClick={this.handleClickCameraReset} />
                                </div>
                              </Tooltip>
                            </ButtonContainer>
                          )
                            : null
                        }
                      </div>
                    </Card>
                  )
                    : null
                }
              </Col>
            </Card>

            <Card bordered={false} style={{ marginLeft: '10px', marginTop: '8px' }} className="custom_info">
              <Tabs defaultActiveKey="file" type="line" size="default">
                <TabPane
                  tab={(
                    <Tooltip title={isFileTabDisabled && ERROR_NO_AUTH_MESSAGE}>
                      ファイル
                    </Tooltip>
                  )}
                  key="file"
                  disabled={isFileTabDisabled}
                >
                  <Col span={17}>
                    <ImagesListViewer
                      client={this.props.client}
                      listType={LIST_TYPE.ALL_FILE}
                      assetId={this.state.selectedAssetId}
                      resourceType={this.state.selectedResourceType}
                      showAddButton
                      showImageDisplaySelection
                      onClickImage={this.onClickImage}
                      customStyle={{ cardStyle: { height: '350px', margin: '0px' }, fileTableHeight: '234px', fileListHeight: '170px' }}
                      showDelButton
                      showAddPjButton
                      onChangeFileType={this.handleChangeFileType}
                      fileNameStatus={DIGITAL_TWIN_APP}
                      downloadable
                      isModalEditButtonDisabled={isModalEditButtonDisabled}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                    />
                  </Col>
                  <Col span={7}>
                    <FileDetailView
                      resourceType={this.state.selectedResourceType}
                      fileId={this.state.selectedFileId}
                      isModalEditButtonDisabled={isModalEditButtonDisabled}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                      customStyle={{ cardStyle: { height: '350px', margin: '0px' }, detailsListHeight: '140px' }}
                    />
                  </Col>
                </TabPane>
                <TabPane
                  tab={(
                    <Tooltip title={isAssetMetadataTabDisabled && ERROR_NO_AUTH_MESSAGE}>
                      属性情報
                    </Tooltip>
                  )}
                  key="asset metadata"
                  disabled={isAssetMetadataTabDisabled}
                >
                  <MetadataBaseInfo
                    selectedAssetId={this.state.selectedAssetId}
                  />
                  <MetadataContainer />
                </TabPane>
              </Tabs>
            </Card>
          </Col>

        </Content>

      </Layout>
    );
  }
}

export default DigitalTwinApp;
