class TreeNode<T> {
  /*
   * メンバ変数
   */
  key: string;

  data: T;

  parent?: TreeNode<T>;

  children: TreeNode<T>[];

  /*
   * アクセサ
   */
  get isLeaf(): boolean {
    return this.children.length === 0;
  }

  /*
   * コンストラクタ
   */
  constructor(key: string, data: T) {
    this.key = key;
    this.data = data;
    this.children = [];
  }

  /*
   * メンバメソッド
   */
  addChild(child: TreeNode<T>): void {
    // eslint-disable-next-line no-param-reassign
    child.parent = this;
    this.children.push(child);
  }

  addChildren(children: TreeNode<T>[]): void {
    for (let i = 0; i < children.length; i++) {
      // eslint-disable-next-line no-param-reassign
      children[i].parent = this;
      this.children.push(children[i]);
    }
  }

  findNodeByKey(key: string): TreeNode<T> | null {
    if (this.key === key) {
      return this;
    }

    for (let i = 0; i < this.children.length; i++) {
      const node = this.children[i].findNodeByKey(key);
      if (node) return node;
    }

    return null;
  }
}

export default TreeNode;
