import React from 'react';
import { CogniteClient } from '@cognite/sdk';
import { Row, Spin } from 'antd';
import ClassCard, { Variables } from './ClassCard';
import { EnsembleConfigJson } from '../../../../utils/File/EnsembleConfigJsonFile';
import LearningModel from '../../../../utils/File/LearningModel';

const DisplayEnsembleConfig: React.FC<{
  client: CogniteClient,
  json: EnsembleConfigJson,
}> = (props) => {
  const {
    client,
    json,
  } = props;

  /*
   * 変数/定数定義
   */
  const [classCards, setClassCards] = React.useState<JSX.Element[] | undefined>(undefined);

  /*
   * イベントハンドラ
   */
  React.useEffect(
    () => {
      /*
       * メソッド
       */
      /**
       * fileのmetadataからmodelNameを取得
       * @param {number} modelId 学習モデルのfileId
       * @returns {string | undefined} 学習モデルの名称
       */
      const getModelName = async (modelId: number): Promise<string | undefined> => {
        const learningModel = await LearningModel.retrieveLearningModel(modelId);
        if (!learningModel) {
          return undefined;
        }

        return learningModel.metadata?.modelName;
      };

      /**
       * Variables配列をindexで昇順ソート
       * @param {Variables[]} variable jsonのvariableパラメータ配列
       */
      const sortVariablesByIndex = (variable: Variables[]) => {
        variable.sort((a: Variables, b: Variables) => {
          const indexA = Number(a.index.match(/[0-9]{1,3}/));
          const indexB = Number(b.index.match(/[0-9]{1,3}/));
          if (indexA > indexB) return 1;
          return -1;
        });
      };

      /**
       * JSX.Element配列をensembleClassNoで昇順ソート
       * @param {JSX.Element[]} jsxElement JSX.Element配列
       */
      const sortJsxElementByEnsembleClassNo = (jsxElement: JSX.Element[]) => {
        jsxElement.sort((a: JSX.Element, b: JSX.Element) => {
          if (a.props.ensembleClassNo > b.props.ensembleClassNo) return 1;
          return -1;
        });
      };

      let canceled = false;
      const createClassCard = async (): Promise<void> => {
        const tempClassCards: JSX.Element[] = [];

        const ensembleFormula = [...Object.values(json.body)];
        const ensembleClass = [...Object.values(json.classList)];
        const variables = [...Object.values(json.param.variable)];

        const waitPromise: Promise<void>[] = ensembleFormula.map(async (formula) => {
          const variableName = formula.match(/c[0-9]{1,3}/g);
          if (!variableName) return;

          const variable: Variables[] = [];

          const promises = variableName.map(async (name) => {
            const variableIndex = Number(name.match(/[0-9]{1,3}/)) - 1;
            const modelName = await getModelName(variables[variableIndex].modelId);
            variable.push(
              {
                index: name,
                model: String(modelName),
                className: variables[variableIndex].class,
                confidence: variables[variableIndex].confidence,
              },
            );
          });

          await Promise.all(promises);

          sortVariablesByIndex(variable);

          const classNo = formula.match(/x[0-9]{1,3}/);
          if (!classNo) return;
          const ensembleClassNo = Number(classNo[0].match(/[0-9]{1,3}/));

          tempClassCards.push(
            <ClassCard
              key={`class-card${ensembleClassNo}`}
              ensembleClassNo={ensembleClassNo}
              ensembleClass={ensembleClass[ensembleClassNo - 1]}
              formula={formula}
              variables={variable}
            />,
          );
        });

        await Promise.all(waitPromise);
        sortJsxElementByEnsembleClassNo(tempClassCards);
        if (!canceled) {
          setClassCards(tempClassCards);
        }
      };

      createClassCard();
      return () => {
        // 2回目以降のレンダリング前に実行される
        setClassCards(undefined);
        canceled = true;
      };
    },
    [client, json],
  );

  return (
    <Spin spinning={classCards === undefined}>
      <div style={{ maxHeight: '840px', overflow: 'auto' }}>
        <Row>
          {classCards}
        </Row>
      </div>
    </Spin>
  );
};

export default DisplayEnsembleConfig;
