import React, { CSSProperties, useCallback } from 'react';

import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import ManagedFacility from '../../../utils/Asset/ManagedFacility';

interface Record {
  id: number;
  key: string;
  value: string;
}

interface MetadataProps {
  selectedAssetId?: number | undefined
  tableStyle?: CSSProperties | undefined
}

export const MetadataBaseInfo: React.FC<
  MetadataProps
> = React.memo((props) => {
  /*
   * 変数/定数定義
   */
  const [records, setRecords] = React.useState<Record[]>([]);
  const {
    tableStyle, selectedAssetId,
  } = props;

  /** CDFにアクセスして設備情報を取得する */
  const loadFacility = useCallback(async () => {
    if (!selectedAssetId) {
      return undefined;
    }
    const targetFacility = await ManagedFacility.loadOneByIdFromCDF(selectedAssetId);
    return targetFacility;
  }, [selectedAssetId]);

  /*
   * イベントハンドラ
   */
  React.useEffect(() => {
    let canceled = false;
    (async () => {
      const managedFacility = await loadFacility();
      if (managedFacility?.metadata) {
        const metadata = managedFacility?.metadata;
        const keys = Object.keys(metadata);
        const newRecords = keys.map((key, index) => {
          const newRecord = {
            id: index,
            key,
            value: metadata[key],
          };
          return newRecord;
        });
        if (!canceled) {
          setRecords(newRecords);
        }
      } else if (!canceled) {
        setRecords([]);
      }
    })();

    return () => {
      canceled = true;
    };
  }, [loadFacility]);

  /*
   * 画面描画
   */
  const columns: ColumnProps<Record>[] = [
    {
      title: '項目',
      key: 'key',
      dataIndex: 'key',
    },
    {
      title: '値',
      key: 'value',
      dataIndex: 'value',
    },
  ];

  return (
    <>
      <div>
        <Table<Record>
          columns={columns}
          rowKey="id"
          dataSource={records}
          pagination={false}
          style={tableStyle}
          bordered
          size="middle"
        />
      </div>
    </>
  );
});
