import {
  CogniteEvent,
  CogniteInternalId,
  FileInfo,
} from '@cognite/sdk';

import BaseEvent from './BaseEvent';
import {
  EP_PATH_INSPECTION_RESULT_FILES,
  EP_PATH_INSPECTION_RESULT_FILES_DOWNLOAD,
  EP_PATH_INSPECTION_RESULT,
} from '../AWS/EndpointPath';

import ScansJsonFile, { Scan } from '../File/ScansJsonFile';
import { getApiGateway } from '../AWS/ApiGateway';

const TYPE = 'inspection';
const SUBTYPE = 'result';

class InspectionResult extends BaseEvent {
  /*
   * メンバ変数
   */
  set name(name: string | undefined) {
    if (name) {
      this.description = name;
    } else {
      delete this.description;
    }
  }

  get name(): string | undefined {
    return this.description;
  }

  set inspectorName(inspectorName: string) {
    if (!this.metadata) {
      this.metadata = {};
    }
    this.metadata.inspectorName = inspectorName;
  }

  get inspectorName(): string {
    if (!this.metadata || !this.metadata.inspectorName) {
      return '';
    }
    return this.metadata.inspectorName;
  }

  set targetManagedFacilityId(id: CogniteInternalId | undefined) {
    if (id) {
      this.assetIds = [id];
    } else {
      delete this.assetIds;
    }
  }

  get targetManagedFacilityId(): CogniteInternalId | undefined {
    return this.assetIds && this.assetIds.length > 0
      ? this.assetIds[0] : undefined;
  }

  set scansJsonFileId(scansJsonFileId: CogniteInternalId | undefined) {
    if (!this.metadata) {
      this.metadata = {};
    }
    this.metadata.scansJsonFileId = String(scansJsonFileId);
  }

  get scansJsonFileId(): CogniteInternalId | undefined {
    if (!this.metadata || !this.metadata.scansJsonFileId) {
      return undefined;
    }

    return Number(this.metadata.scansJsonFileId);
  }

  set message(message: string | undefined) {
    if (!message) return;

    if (!this.metadata) {
      this.metadata = {};
    }
    this.metadata.message = message;
  }

  get message(): string | undefined {
    if (!this.metadata || !this.metadata.message) {
      return '';
    }
    return this.metadata.message;
  }

  scans: Scan[];

  /*
   * コンストラクタ
   */
  constructor(other?: CogniteEvent) {
    if (other) {
      super(other);
      if (other instanceof InspectionResult) {
        this.scans = (other as InspectionResult).scans;
      } else {
        this.scans = [];
      }
    } else {
      super();
      this.scans = [];
    }

    this.type = TYPE;
    this.subtype = SUBTYPE;
  }

  /*
   * メンバメソッド
   */
  async loadScansJsonFileFromCDF(): Promise<ScansJsonFile | null> {
    const { scansJsonFileId } = this;
    if (!scansJsonFileId) return null;
    const getUrl = `${EP_PATH_INSPECTION_RESULT_FILES}/${scansJsonFileId}`;
    const scansJsonFile = await getApiGateway<FileInfo>(getUrl, { ignoreUnknownIds: true });

    if (!scansJsonFile) return null;

    return new ScansJsonFile(scansJsonFile);
  }

  async loadScansFromCDF(): Promise<Scan[]> {
    const scansJsonFile = await this.loadScansJsonFileFromCDF();
    if (!scansJsonFile) return [];
    const scans = await scansJsonFile.loadContentFromCDF(EP_PATH_INSPECTION_RESULT_FILES_DOWNLOAD);
    return scans;
  }

  /**
   * 点検結果情報保存
   */
  async saveSelfToCDF(): Promise<void> {
    if (!this.name) {
      throw new Error('点検結果名が指定されていません。');
    }
    if (!this.startTime) {
      throw new Error('開始日時が指定されていません。');
    }
    if (!this.endTime) {
      throw new Error('終了日時が指定されていません。');
    }
    if (!this.inspectorName || this.inspectorName.length === 0) {
      throw new Error('点検者名が指定されていません。');
    }
    if (!this.targetManagedFacilityId) {
      throw new Error('対象の管理設備が指定されていません。');
    }

    let scansJsonFile = await this.loadScansJsonFileFromCDF();
    if (!scansJsonFile) {
      scansJsonFile = new ScansJsonFile();
    }

    scansJsonFile.linkedManagedFacilityId = this.targetManagedFacilityId;
    scansJsonFile.scans = this.scans;
    scansJsonFile.dataSetId = this.dataSetId;

    await scansJsonFile.uploadSelfToCDF();

    this.scansJsonFileId = scansJsonFile.id;

    await super.saveSelfToCDF(EP_PATH_INSPECTION_RESULT);
  }

  async removeFromCDF(): Promise<void> {
    const scansJsonFile = await this.loadScansJsonFileFromCDF();
    if (scansJsonFile) {
      await scansJsonFile.removeSelfFromCDF(EP_PATH_INSPECTION_RESULT_FILES);
    }

    await super.removeSelfFromCDF(EP_PATH_INSPECTION_RESULT);
  }
}

export default InspectionResult;
