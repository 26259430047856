import ActivityCheckHistory from '../Event/ActivityCheckHistory';
import ElectricEnergyStatus from '../Event/ElectricEnergyStatus';
import OperationalStatus from '../Event/OperationalStatus';
import ElectricEnergyComparisonStatus from '../Event/ElectricEnergyComparisonStatus';
import PcsComparisonStatus from '../Event/PcsComparisonStatus';
import SolarEquipmentInformation from '../Event/SolarEquipmentInformation';
import { MimeType } from './BaseFile';

/**
 * BOM
 */
const BOM_UTF8 = '\uFEFF';

/**
 * デリミタ
 */
const DELIMITER = ',';

/**
 * レコードデリミタ
 */
const RECORD_DELIMITER = '\r\n';

/**
 * CSVヘッダ
 * { key: ボディとして出力する項目のproperty名, headerString: ヘッダ行に出力する文字列 }
 */
type Header = { key: string, headerString: string }[];

/**
 * CSVボディデータに有効なクラス定義
 */
type BodyData = SolarEquipmentInformation | ElectricEnergyStatus | OperationalStatus | ElectricEnergyComparisonStatus | PcsComparisonStatus | ActivityCheckHistory;

/**
 * 文字列をダブルクォーテーションで囲む
 * @param {string} value 文字列
 * @returns "文字列"
 */
const quoteDouble = (value: string): string => `"${value}"`;

/**
 * CSVの行データを生成する。
 * ヘッダー部を元にボディ部から対応する値を取得し、ダブルクォーテーションで囲んだ文字列をデリミタで連結する。
 * @param {Header} header ヘッダー部
 * @param {BodyData} bodyItem ボディ部
 * @returns レコード文字列
 */
const generateBodyRecord = (header: Header, bodyItem: BodyData): string => {
  const bodyRecord = header.map(({ key }) => {
    const bodyValue = String(bodyItem[key as keyof typeof bodyItem]);
    return quoteDouble(bodyValue);
  });

  return bodyRecord.join(DELIMITER);
};

/**
 * ヘッダ部、ボディ部からCSVファイル文字列を生成し、Blobオブジェクトを返却する。
 * 出力するCSVファイルの詳細は下記のとおり。
 * - ヘッダ: あり
 * - 文字コード: UTF-8
 * - BOM: あり
 * - 引用符: "(ダブルクォーテーション)
 * - 改行タグ(\<br>)は改行コード(\r\n)に置換
 * @param {Header} header ヘッダー部
 * @param {BodyData[]} body ボディ部
 * @returns Blobオブジェクト
 */
const generateCsvData = (header: Header, body: BodyData[]): Blob => {
  const data: string[] = [];

  // BOM追加
  data.push(BOM_UTF8);

  // ヘッダ生成
  const csvHeader = header.map(({ headerString }) => quoteDouble(headerString)).join(DELIMITER);
  data.push(`${csvHeader}${RECORD_DELIMITER}`);

  // ボディ部生成
  const csvBody = body
    .map((bodyItem) => generateBodyRecord(header, bodyItem))
    .join(RECORD_DELIMITER);
  data.push(csvBody.replaceAll('<br>', RECORD_DELIMITER));

  return new Blob(data, { type: MimeType.Csv });
};

export { generateCsvData };
export type { Header, BodyData };
