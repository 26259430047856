/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import React from 'react';

import { Spin, Tree, message } from 'antd';
import { LineChartOutlined } from '@ant-design/icons';

import TreeNode from '../../../utils/common/TreeNode';
import Facility from '../../../utils/Asset/Facility';
import ManagedFacility from '../../../utils/Asset/ManagedFacility';
import Timeseries from '../../../utils/Timeseries/Timeseries';

import {
  ERROR_LOAD_MANAGED_FACILITY,
  ERROR_LOAD_MANAGED_FACILITY_TIMESERIES,
} from '../../../utils/messages';

interface TreeNodesMap {
  [key: string]: TreeNode<Facility | Timeseries>
}

/**
 * タイムシリーズ選択コンポーネント
 */
const TimeseriesTree: React.FC<{
  height?: number,
  onCheck?: (timeseriesList: Timeseries[]) => void
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [loading, setLoading] = React.useState<boolean>(true);
  const [treeNodes, setTreeNodes] = React.useState<TreeNode<Facility | Timeseries>[]>([]);
  const [treeNodesMap, setTreeNodesMap] = React.useState<TreeNodesMap>({});

  const { height } = props;

  /*
   * イベントハンドラ
   */

  /**
   * 表示可能な管理設備を読み込み、紐づく設備/タイムシリーズ情報を読み込む
   * 読み込んだ情報をTreeNodeに変換し、画面に表示する
   */
  React.useEffect(
    () => {
      if (!loading) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        /*
         * メソッド
         */

        /*
         * メイン処理
         */
        const managedFacilities = await loadAllManagedFacilities();

        const promises: Promise<{ [key: string]: TreeNode<Facility | Timeseries> }>[] = [];
        managedFacilities.forEach((managedFacility) => {
          const key = String(managedFacility.id);
          if (!treeNodesMap[key]) {
            promises.push(loadTreeOf(managedFacility));
          }
        });

        const results = await Promise.all(promises);

        const newTreeNodesMap = { ...treeNodesMap };
        results.forEach((result) => {
          const keys = Object.keys(result);
          keys.forEach((key) => {
            newTreeNodesMap[key] = result[key];
          });
        });

        const newTreeNodes: TreeNode<Facility | Timeseries>[] = [];
        const keys = Object.keys(newTreeNodesMap);
        keys.sort().forEach((key) => {
          const treeNode = newTreeNodesMap[key];
          if (treeNode && timeseriesExistsIn(treeNode)) {
            newTreeNodes.push(newTreeNodesMap[key]);
          }
        });

        if (!canceled) {
          setLoading(false);
          setTreeNodesMap(treeNodesMap);
          setTreeNodes(newTreeNodes);
        }
      })();

      return () => { canceled = true; };
    },
    [loading, treeNodesMap],
  );

  /*
   * メソッド
   */

  /*
   * 画面描画
   */

  /**
   * ツリー情報を画面に表示する
   * @param {TreeNode<Facility | Timeseries>[]} newTreeNodes ツリー情報
   * @returns {JSX.Element[]} ツリー情報
   */
  const renderTreeNodes = (newTreeNodes: TreeNode<Facility | Timeseries>[]) => (
    newTreeNodes.map((treeNode) => {
      const { data } = treeNode;

      if (data instanceof Timeseries) {
        // タイムシリーズの場合、チェックボックス > タイムシリーズアイコン > 名称を表示する
        const title = (
          <>
            <LineChartOutlined />
            <span>{data.name}</span>
          </>
        );
        return (
          <Tree.TreeNode
            key={String(data.id)}
            title={title}
            data={treeNode}
            disabled={data.isString} // StringDatapointはグラフ表示対象外
          />
        );
      }

      return treeNode.isLeaf
        ? (
          // 葉要素の場合、名称のみを表示する
          <Tree.TreeNode
            key={String(treeNode.data.id)}
            title={treeNode.data.name}
            checkable={false}
            data={treeNode}
          />
        )
        : (
          // その他の場合、自身をチェックボックス無しで表示し紐づく子要素を配下に表示する
          <Tree.TreeNode
            key={String(treeNode.data.id)}
            title={treeNode.data.name}
            checkable={false}
            data={treeNode}
          >
            {renderTreeNodes(treeNode.children)}
          </Tree.TreeNode>
        );
    })
  );

  return (
    <Spin spinning={loading}>
      <Tree
        blockNode
        checkable
        onCheck={(checked) => {
          const checkedTimeseries: Timeseries[] = [];

          // 葉要素のみチェックボックスが表示されるため checked.halfChecked は使用しない
          const checkedKeys = Array.isArray(checked) ? checked : checked.checked;
          for (let i = 0; i < checkedKeys.length; i++) {
            const checkedKey = checkedKeys[i];
            for (let j = 0; j < treeNodes.length; j++) {
              const node = treeNodes[j].findNodeByKey(checkedKey as string);
              if (node && node.data instanceof Timeseries) {
                checkedTimeseries.push(node.data);
                break;
              }
            }
          }

          if (props.onCheck) {
            props.onCheck(checkedTimeseries);
          }
        }}
        style={{
          height: height || 800,
          overflowX: 'auto',
          overflowY: 'auto',
        }}
      >
        {renderTreeNodes(treeNodes)}
      </Tree>
    </Spin>
  );
};

/**
 * 管理設備リストを読み込む
 * 読み込みに失敗した場合、例外をスローする
 * @returns {Promise<ManagedFacility[]>} 管理設備リスト
 */
async function loadAllManagedFacilities(): Promise<ManagedFacility[]> {
  try {
    const managedFacilities = await ManagedFacility.loadAllFromCDF();

    return managedFacilities;
  } catch (exception) {
    message.error(ERROR_LOAD_MANAGED_FACILITY);
  }

  return [];
}

/**
 * 管理設備に紐づく設備/タイムシリーズ情報を読み込む
 * 読み込みに失敗した場合、例外をスローする
 * @param {ManagedFacility} managedFacility 管理設備
 * @returns {Promise<TreeNodesMap>} 設備/タイムシリーズTreeNodeMap
 */
async function loadTreeOf(managedFacility: ManagedFacility): Promise<TreeNodesMap> {
  try {
    const treeNode = await Timeseries.loadAllTreeInManagedFacilityFromCDF(managedFacility);

    const key = String(managedFacility.id);
    const result: TreeNodesMap = {};
    result[key] = treeNode;

    return result;
  } catch (exception) {
    message.error(ERROR_LOAD_MANAGED_FACILITY_TIMESERIES);
  }
  return {};
}

/**
 * ツリーノードにタイムシリーズが存在するか判定する。
 * 下記の場合、存在すると判定
 * - ツリーノードが葉要素
 * - ツリーノードに紐づくデータがタイムシリーズ
 * @param {TreeNode<Facility | Timeseries>} treeNode ツリーノード
 * @returns {boolean} 判定結果
 */
function timeseriesExistsIn(treeNode: TreeNode<Facility | Timeseries>): boolean {
  if (treeNode.isLeaf && treeNode.data instanceof Timeseries) return true;

  for (let i = 0; i < treeNode.children.length; i++) {
    // 全てのノードを再帰的に判定
    const exist = timeseriesExistsIn(treeNode.children[i]);
    if (exist) return true;
  }

  return false;
}

export default TimeseriesTree;
