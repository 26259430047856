import {
  Asset,
  AssetListScope,
  CursorAndAsyncIterator,
} from '@cognite/sdk';
import { postApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_ROOT_ASSET_DETECT_TASK } from '../AWS/EndpointPath';

import BaseAsset from './BaseAsset';

class DetectTask extends BaseAsset {
  /*
   * クラスメソッド
   */

  /**
   * 検出タスクアセットリスト取得
   * @returns 検出タスクアセットリスト
   */
  static async loadAllFromCDF(): Promise<DetectTask[]> {
    const scope: AssetListScope = {
      filter: { metadata: { assetType: 'detecttask' } },
      limit: Infinity,
    };
    const assets = await postApiGateway<AssetListScope, CursorAndAsyncIterator<Asset>>(
      EP_PATH_ROOT_ASSET_DETECT_TASK, scope,
    );

    return assets.items.map((asset) => new DetectTask(asset));
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      super(asset);
    } else {
      super();
    }
  }

  /*
   * メンバメソッド
   */
  cloneSelf(): DetectTask {
    const clone = new DetectTask();
    Object.assign(clone, this);

    return clone;
  }

  // eslint-disable-next-line class-methods-use-this
  async loadChildrenFromCDF(): Promise<BaseAsset[]> {
    return [];
  }
}

export default DetectTask;
