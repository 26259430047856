/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
/* eslint-disable max-classes-per-file */
import {
  CogniteExternalId,
  CogniteInternalId,
  FileInfo,
  FileMimeType,
  FileName,
  IdEither,
  Label,
  Metadata,
  FileAggregateQuery,
  ItemsResponse,
  FileAggregate,
  ExternalFileInfo,
  ItemsWrapper,
} from '@cognite/sdk';

import axios from 'axios';
import { ResourceType, FILE_METADATA_TYPE } from '../common/SDFDataType';
import { getFilesIcon } from '../AWS/AWSRequest';
import { convertExifInfoFrom } from './ConvertExif';

import {
  getApiGateway,
  postApiGateway,
  deleteApiGateway,
  ResponseDownload,
  ResponseDownloadURL,
  ResponseCogniteItems,
} from '../AWS/ApiGateway';
import {
  EP_PATH_FACILITY_FILES,
  EP_PATH_FACILITY_FILES_LIST,
  EP_PATH_FILES_AGGREGATE,
} from '../AWS/EndpointPath';
import { getAllFiles } from '../dataAccess';

export const MimeType = {
  Json: 'application/json',
  Pdf: 'application/pdf',
  Text: 'text/plain',
  Html: 'text/html',
  Csv: 'text/csv',
  Jpeg: 'image/jpeg',
  Png: 'image/png',
  Bmp: 'image/bmp',
  Gif: 'image/gif',
  Tiff: 'image/tiff',
  Mp4: 'video/mp4',
} as const;

/**
 * 表示画像の種類（撮影画像 or 検出結果）
 */
export const CAPTURED_IMAGE = 0; // 撮影画像
export const DETECTION_RESULT = 1; // 検出結果

abstract class BaseFile implements FileInfo {
  /*
   * クラスメソッド
   */
  static async loadOneByIdFromCDF(
    id: CogniteInternalId,
  ): Promise<BaseFile | undefined> {
    const getUrl = `${EP_PATH_FACILITY_FILES}/${id}`;
    const files = await getApiGateway<FileInfo>(getUrl, { ignoreUnknownIds: true });

    if (!files) return undefined;

    return this.getInstanceFromFileInfo(files);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async loadFilesByIdsFromCDF(ids: IdEither[]): Promise<BaseFile[]> {
    // 未実装機能
    const emptyFiles = [] as BaseFile[];
    return emptyFiles;
  }

  static async loadFilesLinkedToFacilityId(
    facilityId: CogniteInternalId,
  ): Promise<BaseFile[]> {
    const filter = {
      assetIds: [facilityId],
    };

    const files = await getAllFiles(EP_PATH_FACILITY_FILES_LIST, filter);
    return files.map((file) => this.getInstanceFromFileInfo(file));
  }

  static getInstanceOfMimeType(mimeType: string): BaseFile {
    let instance = null;

    switch (mimeType) {
      case MimeType.Text:
        instance = new TextFile();
        break;
      case MimeType.Json:
        instance = new JsonFile();
        break;
      case MimeType.Jpeg:
        instance = new ImageFile();
        break;
      default:
        instance = new UnknownFile();
        break;
    }

    instance.mimeType = mimeType;

    return instance;
  }

  private static getInstanceFromFileInfo(fileInfo: FileInfo): BaseFile {
    switch (fileInfo.mimeType) {
      case MimeType.Text:
        return new TextFile(fileInfo);
      case MimeType.Json:
        return new JsonFile(fileInfo);
      case MimeType.Jpeg:
        return new ImageFile(fileInfo);
      default:
        return new UnknownFile(fileInfo);
    }
  }

  /*
   * メンバ変数
   */
  id: CogniteInternalId;

  externalId?: CogniteExternalId;

  name: FileName;

  mimeType?: FileMimeType;

  metadata?: Metadata;

  uploaded: boolean;

  uploadedTime?: Date;

  assetIds?: CogniteInternalId[];

  dataSetId?: CogniteInternalId;

  labels?: Label[];

  securityCategories?: CogniteInternalId[];

  source?: string;

  sourceCreatedTime?: Date;

  createdTime: Date;

  lastUpdatedTime: Date;

  /*
   * アクセサ
   */
  get type(): string | undefined {
    if (!this.metadata) return undefined;

    return this.metadata.type;
  }

  set type(type: string | undefined) {
    if (!this.metadata) {
      this.metadata = {};
    }
    if (type) {
      this.metadata.type = type;
    } else {
      delete this.metadata.type;
    }
  }

  get latitude(): string | undefined {
    if (!this.metadata) return undefined;

    return this.metadata.latitude || this.metadata['緯度'];
  }

  set latitude(latitude: string | undefined) {
    if (!this.metadata) {
      this.metadata = {};
    }
    if (latitude) {
      this.metadata['緯度'] = latitude;
    } else {
      delete this.metadata['緯度'];
    }
  }

  get longitude(): string | undefined {
    if (!this.metadata) return undefined;

    return this.metadata.longitude || this.metadata['経度'];
  }

  set longitude(longitude: string | undefined) {
    if (!this.metadata) {
      this.metadata = {};
    }
    if (longitude) {
      this.metadata['経度'] = longitude;
    } else {
      delete this.metadata['経度'];
    }
  }

  get altitude(): string | undefined {
    if (!this.metadata) return undefined;

    return this.metadata.altitude || this.metadata['高度'];
  }

  set altitude(altitude: string | undefined) {
    if (!this.metadata) {
      this.metadata = {};
    }
    if (altitude) {
      this.metadata['高度'] = altitude;
    } else {
      delete this.metadata['高度'];
    }
  }

  get imageSize(): string | undefined {
    if (!this.metadata) return undefined;

    return this.metadata['画像サイズ'];
  }

  set imageSize(imageSize: string | undefined) {
    if (!this.metadata) {
      this.metadata = {};
    }
    if (imageSize) {
      this.metadata['画像サイズ'] = imageSize;
    } else {
      delete this.metadata['画像サイズ'];
    }
  }

  get shootDateTime(): string | undefined {
    if (!this.metadata) return undefined;

    return this.metadata['撮影日時'];
  }

  set shootDateTime(shootDateTime: string | undefined) {
    if (!this.metadata) {
      this.metadata = {};
    }
    if (shootDateTime) {
      this.metadata['撮影日時'] = shootDateTime;
    } else {
      delete this.metadata['撮影日時'];
    }
  }

  /*
   * コンストラクタ
   */
  constructor(fileInfo?: FileInfo) {
    if (fileInfo) {
      this.id = fileInfo.id;
      this.externalId = fileInfo.externalId;
      this.name = fileInfo.name;
      this.mimeType = fileInfo.mimeType;
      this.metadata = fileInfo.metadata;
      this.uploaded = fileInfo.uploaded;
      this.uploadedTime = fileInfo.uploadedTime;
      this.assetIds = fileInfo.assetIds;
      this.dataSetId = fileInfo.dataSetId;
      this.securityCategories = fileInfo.securityCategories;
      this.source = fileInfo.source;
      this.sourceCreatedTime = fileInfo.sourceCreatedTime;
      this.createdTime = fileInfo.createdTime;
      this.lastUpdatedTime = fileInfo.lastUpdatedTime;
    } else {
      this.id = 0;
      this.name = '';
      this.uploaded = true;
      this.createdTime = new Date();
      this.lastUpdatedTime = new Date();
    }
  }

  /*
   * メンバメソッド
   */
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  setExifInfoFrom(exifInfo: any): void {
    const metadata = convertExifInfoFrom(exifInfo);
    this.shootDateTime = metadata['撮影日時'];
    this.imageSize = metadata['画像サイズ'];
    this.latitude = metadata['緯度'];
    this.longitude = metadata['経度'];
    this.altitude = metadata['高度'];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async loadContentFromURL(url: string | undefined): Promise<any> {
    if (!url) return null;

    const response = await fetch(url);
    if (!response.ok) return null;

    let content = null;
    switch (this.mimeType) {
      case MimeType.Text:
        content = await response.text();
        break;
      case MimeType.Json:
        content = await response.json();
        break;
      default:
        content = null;
        break;
    }

    return content;
  }

  async loadDownloadURLFromCDF(endpoint: string): Promise<string | undefined> {
    const fileLinks = await postApiGateway<ResponseDownload, ResponseDownloadURL>(
      endpoint, { items: [{ id: this.id }] },
    );
    return fileLinks.items.length > 0 ? fileLinks.items[0].downloadUrl : undefined;
  }

  /**
   * アイコンを取得する。
   * @param resourceType リソース種別
   * @returns アイコン情報(ase64)
   */
  async loadThumbnailURLFromCDF(resourceType: ResourceType): Promise<string | undefined> {
    const data = await getFilesIcon(resourceType, this.id);
    return data;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async loadContentFromCDF(endpoint: string): Promise<any> {
    const downloadURL = await this.loadDownloadURLFromCDF(endpoint);
    const content = await this.loadContentFromURL(downloadURL);

    return content;
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  async uploadSelfToCDF(endpoint: string, content: any): Promise<void> {
    const fileInfo = {
      externalId: this.externalId,
      name: this.name,
      mimeType: this.mimeType ? this.mimeType : 'application/octet-stream',
      metadata: this.metadata,
      assetIds: this.assetIds,
      dataSetId: this.dataSetId,
      labels: this.labels,
      securityCategories: this.securityCategories,
      source: this.source,
      sourceCreatedTime: this.sourceCreatedTime,
    };

    const uploadedFile = await postApiGateway<
      {
        bodyData: ExternalFileInfo,
        fileContent: unknown
      },
      ResponseCogniteItems<FileInfo[]>
    >(endpoint, {
      bodyData: fileInfo,
      fileContent: content,
    });

    Object.assign(this, uploadedFile.items[0]);
  }

  async removeSelfFromCDF(endpoint: string): Promise<void> {
    await deleteApiGateway(
      endpoint,
      {
        items: [{
          id: this.id,
        }],
      },
    );
  }
}

export class TextFile extends BaseFile {
  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(fileInfo?: FileInfo) {
    if (fileInfo) {
      super(fileInfo);
    } else {
      super();
      this.mimeType = MimeType.Text;
    }
  }

  /*
   * メンバメソッド
   */
}

export class JsonFile extends TextFile {
  /*
   * メンバ変数
   */
  /**
   * Metadataのtypeにparameterが設定されているファイル総数カウント
   * (通常モデルの検出用、アンサンブルモデル作成用、アンサンブルモデル検出用)
   * @returns ファイル数
   */
  static async countTotalParameterFiles(): Promise<number> {
    const filter: FileAggregateQuery = {
      filter: {
        metadata: {
          type: FILE_METADATA_TYPE.ENSEMBLE_MODEL,
        },
      },
    };

    const parameterAggregates = (
      await postApiGateway<FileAggregateQuery, ItemsResponse<FileAggregate>>(
        EP_PATH_FILES_AGGREGATE, filter,
      )
    );

    return parameterAggregates.items[0].count;
  }

  /*
   * コンストラクタ
   */
  constructor(fileInfo?: FileInfo) {
    if (fileInfo) {
      super(fileInfo);
    } else {
      super();
      this.mimeType = MimeType.Json;
    }
  }

  /*
   * メンバメソッド
   */
}

export class ImageFile extends BaseFile {
  /*
   * クラスメソッド
   */

  /**
   * アセットに紐づくファイル総数カウント
   * @param {string} endpoint
   * @param {number} assetId
   * @returns ファイル数
   */
  static async countTotalFilesWithAsset(endpoint: string, assetId: number): Promise<number> {
    const assetFilesAggregates = await postApiGateway<
      FileAggregateQuery,
      ItemsWrapper<FileAggregate[]>
    >(endpoint, {
      filter: { assetSubtreeIds: [{ id: assetId }] },
    });

    return assetFilesAggregates.items[0].count;
  }

  /**
   * サムネイル表示判定
   * @param {string | undefined} mimeType
   * @returns サムネイル表示判定 true: 表示可 false: 表示不可
   */
  static validateThumbnailDisplay(mimeType: string | undefined): boolean {
    const {
      Jpeg, Png, Bmp, Gif, Tiff,
    } = MimeType;
    let thumbnailDisplayFlag = false;
    if (
      mimeType === Jpeg
      || mimeType === Png
      || mimeType === Bmp
      || mimeType === Gif
      || mimeType === Tiff
    ) {
      thumbnailDisplayFlag = true;
    }

    return thumbnailDisplayFlag;
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(fileInfo?: FileInfo) {
    if (fileInfo) {
      super(fileInfo);
    } else {
      super();
      this.mimeType = MimeType.Jpeg;
    }
  }

  /*
   * メンバメソッド
   */

  /**
   * Base64形式でファイル情報を読み込む
   * @param {string} endpoint endpoint
   * @returns ファイル情報(base64形式)
   */
  async loadContentByBase64FromCDF(endpoint: string): Promise<string | undefined> {
    const downloadURL = await super.loadDownloadURLFromCDF(endpoint);
    if (!downloadURL) {
      return undefined;
    }

    const imageBuffer = await axios.get(downloadURL, { responseType: 'arraybuffer' });
    const imageBase64 = `data:image/jpeg;base64,${Buffer.from(imageBuffer.data, 'binary').toString('base64')}`;
    return imageBase64;
  }
}

export class UnknownFile extends BaseFile {
  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(fileInfo?: FileInfo) {
    if (fileInfo) {
      super(fileInfo);
    } else {
      super();
    }
  }

  /*
   * メンバメソッド
   */
  static getInstance(): UnknownFile {
    return new UnknownFile();
  }
}

export default BaseFile;
