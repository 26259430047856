/* eslint-disable class-methods-use-this */
import { Asset, CogniteInternalId } from '@cognite/sdk';

import BaseAsset from './BaseAsset';
import { getApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_SOLAR_OM_ASSETS } from '../AWS/EndpointPath';
import { implementSubtreeAssets } from '../common/CogniteApi';
import { ResourceType } from '../common/SDFDataType';
import TreeNode from '../common/TreeNode';

export type FilterAssetMode = 'facility' | 'measurement' | 'area';
export const FILTER_MODE_FACILITY: FilterAssetMode = 'facility';
export const FILTER_MODE_MEASUREMENT: FilterAssetMode = 'measurement';
export const FILTER_MODE_AREA: FilterAssetMode = 'area';

class OMEquipment extends BaseAsset {
  /**
   * メンバ変数
   */
  get assetLevel(): string {
    if (!this.metadata || !this.metadata?.o_m_asset_level) return '';
    return this.metadata.o_m_asset_level;
  }

  get hasChildFloors(): boolean {
    if (!this.metadata
      || !this.metadata?.has_child_floors
      || !['true', 'false'].includes(this.metadata?.has_child_floors)
    ) return false;
    return JSON.parse(this.metadata.has_child_floors);
  }

  get sortKey(): string {
    if (!this.metadata || !this.metadata.sort_key) return '';
    return this.metadata.sort_key;
  }

  /*
   * クラスメソッド
   */

  /**
   * O&M情報取得
   * @param {CogniteInternalId} id
   * @returns {Promise<OMEquipment | null>} O&M情報
   */
  static async loadOneByIdFromCDF(id: CogniteInternalId): Promise<OMEquipment | null> {
    const getUrl = `${EP_PATH_SOLAR_OM_ASSETS}/${id}`;
    const asset = await getApiGateway<Asset>(getUrl, { ignoreUnknownIds: true });

    if (!asset) return null;

    return new OMEquipment(asset);
  }

  /**
   * アセット二つを名前順でソート
   * @param {OMEquipment} a 比較対象アセット（一つ目）
   * @param {OMEquipment} b 比較対象アセット（二つ目）
   * @returns {number} 比較結果
   */
  static sortAssetsByName(a: OMEquipment, b: OMEquipment): number {
    const nameA = a.name;
    const nameB = b.name;

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  }

  /**
   * アセット二つをソートキーの順でソート
   * @param {OMEquipment} a 比較対象アセット（一つ目）
   * @param {OMEquipment} b 比較対象アセット（二つ目）
   * @returns {number} 比較結果
   */
  static sortAssetsBySortKey(a: OMEquipment, b: OMEquipment): number {
    const keysA = a.sortKey.split('-').map((key) => Number(key));
    const keysB = b.sortKey.split('-').map((key) => Number(key));

    if (keysA.length < keysB.length) return -1;
    if (keysA.length > keysB.length) return 1;

    for (let i = 0; i < keysA.length; i++) {
      const keyA = keysA[i];
      const keyB = keysB[i];

      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
    }

    return OMEquipment.sortAssetsByName(a, b);
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      super(asset);
    } else {
      super();
    }
  }

  /*
   * メソッド
   */

  /**
   * クローンオブジェクト生成
   * @returns {OMEquipment} O&M情報
   */
  cloneSelf(): OMEquipment {
    const clone = new OMEquipment();
    Object.assign(clone, this);

    return clone;
  }

  // 未使用だが、BaseAsset継承で抽象メソッド定義があるため実体定義
  async loadChildrenFromCDF(): Promise<OMEquipment[]> {
    return [];
  }

  /**
   * O&Mツリー情報取得
   * @returns {Promise<TreeNode<OMEquipment>>} O&Mサブツリー情報
   */
  async loadOMEquipmentTreeFromCDF(filterMode: FilterAssetMode = FILTER_MODE_FACILITY): Promise<TreeNode<OMEquipment>> {
    let filterAssetLevels = ['event_holder', 'ts_holder'];
    if (filterMode === FILTER_MODE_MEASUREMENT) {
      filterAssetLevels = ['feeder', 'pcs', 'event_holder'];
    } else if (filterMode === FILTER_MODE_AREA) {
      filterAssetLevels = ['site', 'floor', 'feeder', 'pcs', 'event_holder', 'ts_holder'];
    }
    const allAssets = await implementSubtreeAssets(ResourceType.OMEquipment, [{ id: this.id }]);
    const assets = allAssets.items
      .filter((asset) => asset.metadata && !filterAssetLevels.includes(asset.metadata.o_m_asset_level))
      .map((asset) => new OMEquipment(asset));
    const sortedAssets = [...assets].sort(OMEquipment.sortAssetsBySortKey);

    const map: { [key: string]: TreeNode<OMEquipment>[] } = {};
    for (let i = 0; i < sortedAssets.length; i++) {
      const asset = sortedAssets[i];
      const { parentId, id } = asset;
      if (parentId) {
        const key = String(parentId);
        if (!map[key]) {
          map[key] = [];
        }
        map[key].push(new TreeNode(
          String(id), new OMEquipment(asset),
        ));
      }
    }

    const node = new TreeNode<OMEquipment>(String(this.id), this);
    this.addChildrenToNodeFromMap(node, map);

    return node;
  }

  /**
   * O&Mサブツリー情報生成
   * @param {TreeNode<OMEquipment>} node
   * @param {{ [key: string]: TreeNode<OMEquipment>[] }} map
   */
  private addChildrenToNodeFromMap(
    node: TreeNode<OMEquipment>,
    map: { [key: string]: TreeNode<OMEquipment>[] },
  ): void {
    const children = map[node.key];
    if (children) {
      node.addChildren(children);
      // eslint-disable-next-line no-param-reassign
      delete map[node.key];
      for (let i = 0; i < node.children.length; i++) {
        this.addChildrenToNodeFromMap(node.children[i], map);
      }
    }
  }
}

export default OMEquipment;
