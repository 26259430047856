/* eslint-disable no-console */
const STORAGE_VERSION = 1;
const BASE_KEY = `nttcom_digital_twin__v${STORAGE_VERSION}__storage`;

let currentTenant;
let currentAppName;

/**
 * Define a no-op localStorage implementation to use as a fallback when
 * localStorage can't be used (eg, private browsing).
 */
const FAKE_LOCAL_STORAGE = {
  getItem: () => { },
  removeItem: () => { },
  setItem: () => undefined,
};

/**
 * Provide a reference to localStorage that's safe to use (because it falls back
 * to the no-op implementation).
 */
const SAFE_LOCAL_STORAGE = localStorage || FAKE_LOCAL_STORAGE;

export const init = ({ tenant, appName }) => {
  currentTenant = tenant || currentTenant;
  currentAppName = appName || currentAppName;
};

export const ASSET_ID_STORAGE_KEY = 'selected-asset-id';

// This shouldn't be updated to CDF because we'll have migrate everyone.
export const KEY_LAST_OPINT_TENANT = 'last_CDP_project';

/** Create a key useful to store things at the root level. */
const createRootKey = (key) => `${BASE_KEY}/${key}`;

/** Create a key useful to store things on the app (opin, opin-dev) level. */
const createAppKey = (key) => `${currentAppName}/${key}`;

/** Create a key useful for storing things on the tenant level. */
const createTenantKey = (key) => `${currentTenant}/${key}`;

const getRootItem = (key, defaultValue) => {
  const rootKey = createRootKey(key);

  const stringValue = SAFE_LOCAL_STORAGE.getItem(rootKey);
  if (stringValue === null) {
    return defaultValue;
  }

  try {
    return JSON.parse(stringValue);
  } catch (ex) {
    console.warn(ex, stringValue);
    return stringValue;
  }
};

const getAppItem = (key, defaultValue) => {
  const appKey = createAppKey(key);
  return getRootItem(appKey, defaultValue);
};

const setRootItem = (key, data) => {
  try {
    SAFE_LOCAL_STORAGE.setItem(
      createRootKey(key),
      JSON.stringify(data),
    );
  } catch (ex) {
    // This happens if the local storage is full or the user is browing privately.
    // Simulating native behaviour of localStorage.setItem
    console.warn(ex);
  }
};

const setAppItem = (key, data) => {
  const appKey = createAppKey(key);
  setRootItem(appKey, data);
};

export const getRootString = (key, defaultValue) => {
  const rootKey = createRootKey(key);
  const maybeJson = SAFE_LOCAL_STORAGE.getItem(rootKey) || `"${defaultValue}"`;

  try {
    return JSON.parse(maybeJson);
  } catch (e) {
    return defaultValue;
  }
};

export const getItem = (key, defaultValue) => {
  const tenantKey = createTenantKey(key);
  return getAppItem(tenantKey, defaultValue);
};

export const setItem = (key, data) => {
  const tenantKey = createTenantKey(key);
  setAppItem(tenantKey, data);
};

export const removeItem = (key) => {
  const tenantKey = createTenantKey(key);
  const appKey = createAppKey(tenantKey);
  const rootKey = createRootKey(appKey);
  localStorage.removeItem(rootKey);
};

export default {
  init,
  getItem,
  setItem,
  getRootString,
  removeItem,
};
