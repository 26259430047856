import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Table,
  Typography,
  Tooltip,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';

import StandardPcsSettingModal from './parts/StandardPcsSettingModal';
import { FilterIcon, TextFilter } from './parts/FilterComponent';
import { ERROR_NO_AUTH_MESSAGE } from '../../utils/messages';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import StandardPcsGroup from '../../utils/DynamoDB/StandardPcsGroup';

import './StandardPcsSetting.css';

const { Title } = Typography;

const StandardPcsSetting: React.FC = () => {
  const [standardPcsSiteGroup, setStandardPcsSiteGroup] = useState<StandardPcsGroup[]>([]);
  const [tableRefresh, setTableRefresh] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPcsData, setSelectedPcsData] = useState<StandardPcsGroup>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    let canceled = false;
    (async () => {
      setLoading(true);
      const standardPcsList = await StandardPcsGroup.getStandardPcsGroups();
      const {
        SOLAR_MENU: {
          STANDARD_PCS_SETTING: { STANDARD_PCS_SETTING_UPDATE_BUTTON },
        },
      } = AUTHENTICATION_TYPE_MATRIX;
      if (!canceled) {
        setStandardPcsSiteGroup(standardPcsList);
        setIsUpdateButtonDisabled(!await containsUIAuthType(STANDARD_PCS_SETTING_UPDATE_BUTTON));
      }
      setLoading(false);
    })();
    return () => { canceled = true; };
  }, [tableRefresh]);

  /**
   * 子画面の表示処理
   * @param {StandardPcsGroup} item 選択したサイトの基準PCS情報
   */
  const handleOpenModal = (item: StandardPcsGroup) => {
    setSelectedPcsData(item);
    setIsModalVisible(true);
  };

  /**
   * 子画面を閉じる処理
   */
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  /**
   * 親画面の一覧リフレッシュ処理
   */
  const handleTableRefresh = () => {
    setTableRefresh(tableRefresh + 1);
  };

  const columns: ColumnProps<StandardPcsGroup>[] = [
    {
      title: '会社名',
      dataIndex: 'companyNameJp',
      width: 170,
      filterIcon: FilterIcon,
      filterDropdown: !!standardPcsSiteGroup.length && TextFilter,
      onFilter: (value, result) => !!result.companyNameJp && result.companyNameJp.toLowerCase().includes((value as string).toLowerCase()),
      render: (_, item: StandardPcsGroup) => item.companyNameJp,
    },
    {
      title: 'サイト名',
      dataIndex: 'siteNameJp',
      width: 170,
      filterIcon: FilterIcon,
      filterDropdown: !!standardPcsSiteGroup.length && TextFilter,
      onFilter: (value, result) => !!result.siteNameJp && result.siteNameJp.toLowerCase().includes((value as string).toLowerCase()),
      render: (_, item: StandardPcsGroup) => item.siteNameJp,
    },
    {
      title: '基準PCS',
      dataIndex: 'standardPcsNo',
      width: 100,
      render: (_, item: StandardPcsGroup) => `PCS${item.standardPcsNo}`,
    },
    {
      dataIndex: 'edit',
      width: 70,
      render: (_, item: StandardPcsGroup) => (
        <div>
          <Tooltip title={isUpdateButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
            <Button
              type="primary"
              onClick={() => handleOpenModal(item)}
              disabled={isUpdateButtonDisabled}
            >
              編集
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="standard-pcs-setting-container">
      <div className="standard-pcs-setting-title">
        <Title level={4}>PCS比較診断の基準PCS設定</Title>
        <Divider className="standard-pcs-setting-title-divider" />
      </div>
      {selectedPcsData && (
        <StandardPcsSettingModal
          selectedPcsGroup={selectedPcsData}
          visible={isModalVisible}
          onClose={handleCloseModal}
          refreshParentTable={handleTableRefresh}
        />
      )}
      <Table<StandardPcsGroup>
        loading={loading}
        className="standard-pcs-setting-table"
        dataSource={standardPcsSiteGroup}
        pagination={false}
        columns={columns}
        rowKey="id"
        scroll={{ y: 616 }}
      />
    </div>
  );
};

export default StandardPcsSetting;
