import { ColumnFilterItem } from 'antd/lib/table';

const EmailType = {
  Activity: 'activity',
  Daily: 'daily',
  Monthly: 'monthly',
} as const;

type EmailType = typeof EmailType[keyof typeof EmailType];

const EMAIL_TYPES_JP: Record<EmailType, string> = {
  activity: '死活監視',
  daily: '発電診断（日次）',
  monthly: '発電診断（月次）',
};

const getEmailTypeJp = (emailType: EmailType): string => EMAIL_TYPES_JP[emailType];

const emailTypeFilter = Object.entries(EMAIL_TYPES_JP).map(([value, text]) => ({ text, value } as ColumnFilterItem));

export {
  EmailType,
  getEmailTypeJp,
  emailTypeFilter,
};
