import Encoding from 'encoding-japanese';
import * as xlsx from 'xlsx';

const XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const failureData = '故障データ';
const sampleSheet = '記入例';
const startNum = 0;
const endNum = 5;
const failureKeyNum = 11;

/**
 * 登録データ種別選択時のイベントハンドラ
 * @param {string | undefined} value データ種別
 * @param {File} file ファイル
 * @param {string} type ファイルタイプ
 * @returns {Promise<xlsx.WorkBook | xlsx.WorkSheet | false>} ファイルタイプ
 */
const dataCheck = async (
  values: string | undefined, file: File, type: string,
): Promise<xlsx.WorkBook | xlsx.WorkSheet | false> => {
  if (type === XLSX) {
    const data = await file.arrayBuffer();
    const workbook = xlsx.read(data);
    if (!workbook.Sheets[sampleSheet]) {
      return false;
    }

    const sheet = workbook.Sheets[sampleSheet];
    const checkString = sheet.A2.v.slice(startNum, endNum);
    if (values === checkString) {
      return workbook;
    }
    return false;
  }

  // ファイルデータの文字コードを変換する（fileはinputしたファイル）
  let fileData: ArrayBuffer = await file.arrayBuffer();
  fileData = Encoding.convert(new Uint8Array(fileData), {
    to: 'UNICODE',
    type: 'arraybuffer',
  });

  // CSVファイルを読み込む
  const workbook = xlsx.read(fileData, {
    type: 'buffer', cellText: false, cellDates: true, dense: true,
  });
  const sheet = workbook.Sheets.Sheet1;
  const keysLength = sheet[0].length;
  if (keysLength === failureKeyNum && values === failureData) {
    return sheet;
  }
  return false;
};

export default dataCheck;
