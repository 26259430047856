import { retrieveAuthResult } from '../AuthWrapper/persistance';
import singleFileUpload from '../Common/File/singleFileUpload';
import SolarSite from '../../utils/Asset/SolarSite';
import { retrieveAsset } from '../../utils/AWS/AWSRequest';
import { EP_PATH_SOLAR_FILES } from '../../utils/AWS/EndpointPath';
import { ResourceType } from '../../utils/common/SDFDataType';

/**
 * 対象サイトが紐づいている事業、第一カテゴリ、および第二カテゴリを取得
 * @param {SolarSite} siteAsset 対象サイト
 * @returns {Promise<{ classificationName: string, firstCategory: string, businessName: string }>} 事業、第一カテゴリ、および第二カテゴリ
*/
const retrieveBusinessInfo = async (siteAsset: SolarSite):
  Promise<{
    classificationName: string,
    firstCategory: string,
    businessName: string,
  }> => {
  const businessAsset = await retrieveAsset(
    ResourceType.SolarSite,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    [{ id: siteAsset.parentId! }],
  ).then(({ items }) => items[0]);
  const businessName = businessAsset.name;

  const firstCategoryAsset = await retrieveAsset(
    ResourceType.SolarSite,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    [{ id: businessAsset.parentId! }],
  ).then(({ items }) => items[0]);
  const firstCategory = firstCategoryAsset.name;

  const classificationAsset = await retrieveAsset(
    ResourceType.SolarSite,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    [{ id: firstCategoryAsset.parentId! }],
  ).then(({ items }) => items[0]);
  const classificationName = classificationAsset.name;

  return { classificationName, firstCategory, businessName };
};

/**
* 登録データファイルアップロード処理
* @param {string} dataType 登録データ種別
* @param {SolarSite} rootAsset 対象サイトのルートアセット
* @param {SolarSite | undefined} siteAsset 対象サイト（財務値以外の場合、undefinedが渡される
* @param {File} uploadFile アップロードファイル
* @returns {Promise<{ key: number, result: string }>} アップロード結果
*/
const uploadRegistrationFile = async (
  dataType: string,
  rootAsset: SolarSite,
  siteAsset: SolarSite | undefined,
  uploadFile: File,
): Promise<{ key: number, result: string }> => {
  let businessName = '―';
  let firstCategory = '―';
  let classificationName = '―';
  let siteName = '―';

  if (siteAsset) {
    // 財務値の場合、各情報を設定
    // メタデータ項目のデータ取得と整形
    const businessInfo = await retrieveBusinessInfo(siteAsset);
    businessName = businessInfo.businessName;
    firstCategory = businessInfo.firstCategory;
    classificationName = businessInfo.classificationName;
    siteName = siteAsset.name;
  }

  const { user } = retrieveAuthResult();
  const metadata = {
    dataType,
    classificationName,
    firstCategory,
    businessName,
    siteName,
    user,
  };

  // リクエストボディの整形
  const data = {
    assetId: rootAsset.id,
    dataSetId: rootAsset.dataSetId,
    metadata,
    endpoint: EP_PATH_SOLAR_FILES,
    uploadFile,
  };

  // ファイルアップロード処理の呼び出し
  return singleFileUpload({ data });
};

export default uploadRegistrationFile;
