import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  message,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { FilterIcon, TextFilter } from './parts/FilterComponent';
import GroupSettingModal from './parts/GroupSettingModal';
import { ERROR_DELETE_EMAIL_GROUP, ERROR_NO_AUTH_MESSAGE, SUCCESS_DELETE_EMAIL_GROUP } from '../../utils/messages';
import EmailGroup from '../../utils/DynamoDB/EmailGroup';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';

import './GroupSetting.css';

const { Title } = Typography;

/**
 * EmailGroupのソート処理
 * @param {EmailGroup} a グループの比較対象
 * @param {EmailGroup}  b グループの比較対象
 * @returns {number} EmailGroupのソート順序
 */
const sortByCreated = (a: EmailGroup, b: EmailGroup) => {
  if (a.created === b.created) {
    return a.groupName.localeCompare(b.groupName);
  }

  if (!a.created) {
    return -1;
  }
  if (!b.created) {
    return 1;
  }

  return a.created - b.created;
};

/**
 * グループ設定画面
 * @returns グループ設定画面コンポーネント
 */
const GroupSetting: React.FC = () => {
  const [emailGroups, setEmailGroups] = useState<EmailGroup[]>([]);
  const [editEmailGroup, setEditEmailGroup] = useState<EmailGroup>(new EmailGroup());
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [tableRefresh, setTableRefresh] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(false);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    let canceled = false;
    setLoading(true);

    (async () => {
      const {
        SOLAR_MENU: {
          EMAIL_NOTIFICATION_SETTINGS: {
            SOLAR_EMAIL_NOTIFICATION_SETTINGS_UPDATE,
            SOLAR_EMAIL_NOTIFICATION_SETTINGS_DELETE,
          },
        },
      } = AUTHENTICATION_TYPE_MATRIX;

      const allEmailGroups = await EmailGroup.getAllGroups()
        .then((groups) => [...groups].sort(sortByCreated));

      if (!canceled) {
        setUpdateButtonDisabled(!await containsUIAuthType(SOLAR_EMAIL_NOTIFICATION_SETTINGS_UPDATE));
        setDeleteButtonDisabled(!await containsUIAuthType(SOLAR_EMAIL_NOTIFICATION_SETTINGS_DELETE));
        setEmailGroups(allEmailGroups);
      }

      setLoading(false);
    })();
    return () => { canceled = true; };
  }, [tableRefresh]);

  /**
   * モーダルクリック時のイベントハンドラ
   */
  const handleCloseModal = () => {
    setEditEmailGroup(new EmailGroup());
    setIsModalVisible(false);
  };

  /**
   * 画面のリフレッシュ
   */
  const handleTableRefresh = () => {
    setTableRefresh(tableRefresh + 1);
  };

  /**
   * 編集ボタンクリック時のイベントハンドラ
   * @param {EmailGroup} targetGroup 選択されたグループの情報
   */
  const handleEditOpenModal = (targetGroup: EmailGroup) => {
    setEditEmailGroup(targetGroup);
    setIsModalVisible(true);
  };

  /**
   * 追加ボタンボリック時のイベントハンドラ
   */
  const handleAddOpenModal = () => {
    setEditEmailGroup(new EmailGroup());
    setIsModalVisible(true);
  };

  /**
   * 削除ボタンクリック時のイベントハンドラ
   * @param {EmailGroup} targetGroup 選択されたグループの情報
   */
  const handleDelete = async (targetGroup: EmailGroup) => {
    setLoading(true);

    try {
      await EmailGroup.deleteGroup(targetGroup);
      handleTableRefresh();
      message.success(SUCCESS_DELETE_EMAIL_GROUP);
    } catch (exception) {
      message.error(ERROR_DELETE_EMAIL_GROUP);
    }

    setLoading(false);
  };

  const columns: ColumnProps<EmailGroup>[] = [
    {
      title: 'グループ名',
      dataIndex: 'groupName',
      width: 100,
      filterIcon: FilterIcon,
      filterDropdown: !!emailGroups && TextFilter,
      onFilter: (value, result) => (result.groupName.toLowerCase().includes((value as string).toLowerCase())),
      render: (_, item: EmailGroup) => item.groupName,
    },
    {
      title: 'メールアドレス',
      dataIndex: 'mailAddress',
      width: 350,
      filterIcon: FilterIcon,
      filterDropdown: !!emailGroups && TextFilter,
      onFilter: (value, result) => (result.addressList.some((address) => address.includes(value))),
      render: (_, item: EmailGroup) => item.addressList.map((address) => (
        <Tag key={address}>{address}</Tag>
      )),
    },
    {
      title: '',
      dataIndex: 'button',
      width: 60,
      render: (_, item: EmailGroup) => (
        <Row>
          <Col span={12}>
            <Tooltip title={updateButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
              <Button
                type="primary"
                disabled={updateButtonDisabled}
                onClick={() => handleEditOpenModal(item)}
              >
                編集
              </Button>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Popconfirm
              disabled={deleteButtonDisabled}
              title="本当に削除しますか？"
              okText="はい"
              cancelText="いいえ"
              onConfirm={() => handleDelete(item)}
            >
              <Tooltip title={deleteButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                <Button disabled={deleteButtonDisabled}>
                  削除
                </Button>
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div className="group-setting-container">
      <Spin spinning={loading}>
        <div className="group-setting-title">
          <Title level={4}>グループ設定</Title>
        </div>
        <Divider className="group-setting-divider" />

        <div className="group-setting-add-button">
          <Tooltip title={updateButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
            <Button
              type="primary"
              onClick={handleAddOpenModal}
              disabled={updateButtonDisabled}
              style={{ marginLeft: 10 }}
            >
              ＋追加
            </Button>
          </Tooltip>
        </div>
        <Table<EmailGroup>
          className="group-setting-table"
          dataSource={emailGroups}
          rowKey="groupName"
          pagination={false}
          columns={columns}
          scroll={{ y: 730 }}
        />
        <GroupSettingModal
          visible={isModalVisible}
          onClose={handleCloseModal}
          targetEmailGroup={editEmailGroup}
          refreshParentTable={handleTableRefresh}
        />
      </Spin>
    </div>
  );
};

export default GroupSetting;
