import React from 'react';
import { Spin } from 'antd';
import { Column } from '@ant-design/charts';

export interface CountData {
  name: string,
  legend: string,
  value: number
}

export interface CountsDataMap {
  [key: string]: CountData[]
}

/**
 * 積み上げ棒グラフコンポーネント
 * @see https://charts.ant.design/demos/column?type=api
 */
export const ColumnChart: React.FC<{
  loading: boolean,
  countsData: CountData[],
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const { loading, countsData } = props;

  /*
   * イベントハンドラ
   */

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  return (
    <Spin spinning={loading}>
      <Column
        data={countsData}
        xField="name"
        yField="value"
        height={648}
        width={1300}
        isStack
        seriesField="legend"
        label={{
          position: 'middle',
          layout: [
            { type: 'interval-adjust-position' },
            { type: 'interval-hide-overlap' },
            { type: 'adjust-color' },
          ],
        }}
        legend={{
          position: 'right-top',
          offsetX: 8,
          title: {
            text: '',
            spacing: 8,
          },
        }}
        xAxis={{
          label: {
            autoRotate: true,
          },
        }}
        slider={{
          start: 0,
          end: 1,
        }}
      />
    </Spin>
  );
};
