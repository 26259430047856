import React from 'react';

type NoImageProps = React.ComponentProps<'img'>

/**
 * NoImage画像
 */
const NoImageThumbnail: React.FC<NoImageProps> = ({ style, className }: NoImageProps) => (
  <img alt="NoImage" src="/icon/NoImage.png" style={style} className={className} />
);

export default NoImageThumbnail;
