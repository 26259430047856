import exifr from 'exifr';
import { convertExifInfoFrom } from '../../../utils/File/ConvertExif';
import env from '../../../sdf-env.json';
import { createRequestUrl } from '../../../utils/AWS/ApiGateway';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * API Gatewayのエンドポイントを用いて単一ファイルをアップロードする
 * @param {Object} event 呼び出し元から渡されるデータ
 * @returns {Promise<{ key: number, result: string }>} アップロード結果
 */
const singleFileUpload = async (event) => {
  const { data } = event;

  const fileReader = new FileReader();
  const fileRead = new Promise((resolve) => {
    // ファイルデータの読み込み処理
    fileReader.readAsArrayBuffer(data.uploadFile);
    fileReader.onload = async () => {
      const fileContent = Buffer.from(fileReader.result);
      let metadata = data.metadata ?? {};
      try {
        // 呼び出し側からメタデータが渡ってきていない、かつJPEGファイルの場合、Exif情報読み込み
        if (!Object.keys(metadata).length && data.uploadFile.type.match(/image\/jpeg/)) {
          const exifInfo = await exifr.parse(data.uploadFile, { silentErrors: false });
          if (exifInfo) {
            metadata = convertExifInfoFrom(exifInfo);
          }
        }
        const envUploadUri = process.env.REACT_APP_UPLOAD_ORIGIN ?? env.uploadOrigin;
        const bodyData = {
          name: data.uploadFile.name,
          assetIds: [data.assetId],
          dataSetId: data.dataSetId,
          mimeType: data.uploadFile.type || 'application/octet-stream',
          metadata: Object.keys(metadata).length === 0 ? undefined : metadata,
          uploadOrigin: envUploadUri,
        };

        // accessTokenは再認証時に更新されるため使用直前に取得すること
        const cacheToken = await caches.open('sdf-preset-ap');
        const tokenData = await cacheToken.match('/auth-information');
        const cacheTokenData = await tokenData.json();
        const { requestHeader } = cacheTokenData;
        const requestUrl = createRequestUrl(data.endpoint);
        // ファイルデータアップロード
        const req = new Request(requestUrl, {
          method: 'POST',
          headers: requestHeader,
          body: JSON.stringify(bodyData),
        });
        const res = await fetch(req);
        if (!res.ok) {
          resolve({ key: data.assetId, result: 'error' });
          return;
        }

        const { uploadUrl, mimeType } = await res.json();

        const fileContentUploadRequest = new Request(uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': mimeType || 'application/octet-stream',
            'Content-Length': fileContent.length,
            Origin: envUploadUri,
          },
          body: fileContent,
        });

        const uploadDataResponse = await fetch(fileContentUploadRequest);
        if (!uploadDataResponse.ok) {
          resolve({ key: data.assetId, result: 'error' });
        }
      } catch (e) {
        resolve({ key: data.assetId, result: 'error' });
      }

      resolve({ key: data.assetId, result: 'success' });
    };
    fileReader.onerror = () => resolve({ key: data.assetId, result: 'error' });
  });

  const fileReadReturn = await fileRead;
  return fileReadReturn;
};

export default singleFileUpload;
