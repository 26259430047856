import React from 'react';
import { Button } from 'antd';

interface CameraResetButtonProps {
  disabled: boolean,
  onClick: () => void,
}

/**
 * カメラリセットボタン
 */
const CameraResetButton: React.FC<CameraResetButtonProps> = (props) => {
  const { disabled, onClick } = props;

  /**
   * カメラリセットボタンをレンダリングする。
   */
  return (
    <Button
      type="primary"
      icon="undo"
      disabled={disabled}
      onClick={onClick}
    >
      カメラリセット
    </Button>
  );
};

export default CameraResetButton;
