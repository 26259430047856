import React from 'react';
import { CogniteInternalId } from '@cognite/sdk';
import { Button, Tooltip } from 'antd';
import FileUpload from './FileUpload';
import { takeBinarySemaphore, giveBinarySemaphore } from '../../AuthWrapper/persistance';
import { ResourceType } from '../../../utils/common/SDFDataType';
import { ERROR_NO_AUTH_MESSAGE } from '../../../utils/messages';

const FileUploadAddButton: React.FC<{
  assetId: CogniteInternalId,
  disabled?: boolean,
  authentication?: boolean,
  resourceType: ResourceType | null
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [loading, setLoading] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<File[]>([]);

  const fileInput = React.useRef<HTMLInputElement>(null);

  const {
    assetId, disabled, authentication, resourceType,
  } = props;

  /**
   * 追加ボタンクリック時のイベントハンドラ
   */
  const onClickAddToAsset = async () => {
    if (!fileInput.current) return;
    // 再認証ポップアップ中は処理を中断、エクスプローラ表示時は再認証を割り込ませない
    await takeBinarySemaphore();
    fileInput.current.click();
  };

  /**
   * 追加ファイル選択時のイベントハンドラ
   */
  async function onChangeAddFile() {
    if (!fileInput.current || !fileInput.current.files || fileInput.current.files.length === 0) {
      return;
    }
    setLoading(true);
    setFiles(Array.from(fileInput.current.files));
  }

  /** loading Stateにfalseを設定する */
  const handleUploadComplete = () => {
    setLoading(false);
  };

  /*
   * 画面描画
   */
  return (
    <>
      <input
        ref={fileInput}
        type="file"
        aria-label="add-file"
        onChange={onChangeAddFile}
        // eslint-disable-next-line
        onClick={(e: any) => {
          // 同じファイルを選択できるようにvalueを初期化する
          e.target.value = '';
          // エクスプローラーのキャンセルイベントを拾う
          // 単体テストを通すことができないため、カバレッジの対象外とする
          /* istanbul ignore next */
          document.body.onfocus = () => {
            setTimeout(() => {
              if (!fileInput || !fileInput.current || !fileInput.current.files) return;
              if (!fileInput.current.files.length) {
                giveBinarySemaphore();
              }
              document.body.onfocus = null;
            }, 500);
          };
        }}
        style={{ display: 'none' }}
        multiple
      />
      <Tooltip title={authentication && ERROR_NO_AUTH_MESSAGE}>
        <Button
          type="primary"
          loading={loading}
          onClick={onClickAddToAsset}
          style={{ width: '80px', fontWeight: 'bold' }}
          disabled={disabled}
        >
          追加
        </Button>
      </Tooltip>
      <FileUpload
        assetId={assetId}
        resourceType={resourceType}
        files={files}
        onUploadComplete={handleUploadComplete}
      />
    </>
  );
};

export default React.memo(FileUploadAddButton);
