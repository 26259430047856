import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Input,
  Modal,
  message,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';

import { SUCCESS_STANDARD_PCS_SETTING_UPDATE, ERROR_STANDARD_PCS_SETTING_UPDATE } from '../../../utils/messages';
import StandardPcsGroup, { SendParameter } from '../../../utils/DynamoDB/StandardPcsGroup';

import './StandardPcsSettingModal.css';

const { Title } = Typography;
const { Option } = Select;

interface StandardPcsSettingModalProps {
  selectedPcsGroup: StandardPcsGroup;
  visible: boolean;
  onClose: () => void;
  refreshParentTable: () => void;
}

export const StandardPcsSettingModal: React.FC<StandardPcsSettingModalProps> = (props: StandardPcsSettingModalProps) => {
  const {
    selectedPcsGroup,
    visible,
    onClose,
    refreshParentTable,
  } = props;

  const {
    siteName,
    siteNameJp,
    companyNameJp,
    standardPcsNo,
    pcsList,
  } = selectedPcsGroup;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStandardPcs, setSelectedStandardPcs] = useState<number>(standardPcsNo);

  useEffect(() => {
    if (!visible) return;
    setSelectedStandardPcs(standardPcsNo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  /**
   * 基準PCS選択時のイベントハンドラ
   * @param {number} value 選択された基準PCS
   */
  const handleChange = (value: number) => {
    setSelectedStandardPcs(value);
  };

  /**
   * 更新ボタンクリック時のイベントハンドラ
   */
  const handleUpdateStandardPcs = async () => {
    setLoading(true);
    const sendParameter: SendParameter = {
      siteName,
      newStandardPcsNo: selectedStandardPcs,
      oldStandardPcsNo: standardPcsNo,
    };
    try {
      await StandardPcsGroup.updateStandardPcs(sendParameter);
      message.success(SUCCESS_STANDARD_PCS_SETTING_UPDATE);
      setLoading(false);
      refreshParentTable();
      onClose();
    } catch {
      message.error(ERROR_STANDARD_PCS_SETTING_UPDATE);
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
    >
      <div>
        <Spin spinning={loading}>
          <div>
            <Title level={4}>PCS比較診断の基準PCS編集</Title>
            <Divider className="standard-pcs-input-divider" />
          </div>
          <Row>
            <h4>会社名</h4>
            <Input
              id="companyNameJp"
              value={companyNameJp}
              disabled
              className="standard-pcs-input-spacing"
            />
          </Row>
          <Row>
            <h4>サイト名</h4>
            <Input
              id="siteNameJp"
              value={siteNameJp}
              disabled
              className="standard-pcs-input-spacing"
            />
          </Row>
          <Row>
            <h4>基準PCS</h4>
            <Select
              value={selectedStandardPcs}
              onChange={handleChange}
              className="standard-pcs-pulldown"
            >
              {pcsList.sort((a, b) => a.pcsNo - b.pcsNo)
                .map(({ pcsNo }) => (
                  <Option key={`${pcsNo}`} value={pcsNo}>
                    {`PCS${pcsNo}`}
                  </Option>
                ))}
            </Select>
          </Row>
          <div className="standard-pcs-update-button">
            <Button
              type="primary"
              onClick={handleUpdateStandardPcs}
              disabled={loading}
            >
              更新
            </Button>
          </div>
        </Spin>
      </div>
    </Modal>
  );
};

export default StandardPcsSettingModal;
