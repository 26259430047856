import {
  FileInfo,
  FileChangeUpdate,
  CogniteInternalId,
} from '@cognite/sdk';

import {
  getApiGateway,
  getApiGatewayForImage,
  putApiGateway,
  ResponseCogniteOrError,
  ResponseCogniteError,
  RequestFileChangeUpdate,
  ResponseCogniteItems,
  deleteApiGateway,
  RequestSpecifyID,
} from '../AWS/ApiGateway';
import {
  EP_PATH_TEACHER_DATA_FILES,
  EP_PATH_TEACHER_DATA_FILES_ICON,
} from '../AWS/EndpointPath';

/**
 * 教師データに紐づくファイルを取得する(ID指定)
 * @param id ファイルID
 * @returns ファイル情報
 */
const retrieveTeacherDataFile = async (id: CogniteInternalId): Promise<FileInfo> => {
  const endpoint = `${EP_PATH_TEACHER_DATA_FILES}/${id}`;
  const teacherDataFile = await getApiGateway<FileInfo | ResponseCogniteError>(endpoint);

  if ((teacherDataFile as ResponseCogniteError).error) {
    throw teacherDataFile;
  }

  return teacherDataFile as FileInfo;
};

/**
 * 教師データに紐づくファイルのアイコンを取得する
 * @param fileId ファイルID
 * @returns アイコンデータ(base64形式の文字列)
 */
const getTeacherDataFilesIcon = async (fileId: CogniteInternalId): Promise<string> => {
  const getUrl = `${EP_PATH_TEACHER_DATA_FILES_ICON}/${fileId}`;
  const teacherDataFilesIcon = await getApiGatewayForImage(getUrl);
  return teacherDataFilesIcon;
};

/**
 * 教師データに紐づくファイル情報を更新する。
 * @param change ファイル更新情報
 * @returns 更新後のファイル情報
 */
const updateTeacherDataFile = async (
  change: FileChangeUpdate,
): Promise<ResponseCogniteItems<FileInfo[]>> => {
  const updatedFiles = (
    await putApiGateway<RequestFileChangeUpdate, ResponseCogniteOrError<FileInfo[]>>(
      EP_PATH_TEACHER_DATA_FILES,
      { items: [change] },
    )
  );

  if ((updatedFiles as ResponseCogniteError).error) {
    throw updatedFiles;
  }

  return updatedFiles as ResponseCogniteItems<FileInfo[]>;
};

/**
 * 教師データに紐づくファイルを削除する。
 * @param id ファイルID
 */
const deleteTeacherDataFile = async (id: CogniteInternalId): Promise<void> => {
  await deleteApiGateway<RequestSpecifyID>(EP_PATH_TEACHER_DATA_FILES, { items: [{ id }] });
};

export {
  retrieveTeacherDataFile,
  getTeacherDataFilesIcon,
  updateTeacherDataFile,
  deleteTeacherDataFile,
};
