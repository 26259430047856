import React, { Fragment, useEffect, useState } from 'react';

import {
  Button,
  Col,
  Collapse,
  DatePicker,
  message,
  Radio,
  Row,
  Spin,
} from 'antd';
import moment, { Moment, unitOfTime } from 'moment';

import MeasurementSelect from './parts/MeasurementSelect';
import MeasurementTable from './parts/MeasurementTable';
import OMEquipment, { FILTER_MODE_MEASUREMENT } from '../../../utils/Asset/OMEquipment';
import TreeNode from '../../../utils/common/TreeNode';
import { generateCsvData } from '../../../utils/File/OMCsvFile';
import { ERROR_LOAD_FACILITY_ASSET_TREE } from '../../../utils/messages';
import OMMeasurement, { TransformedDatapoint } from '../../../utils/Timeseries/OMMeasurement/OMMeasurement';
import { RetrievalTimeRangeInfo, TIME_RANGE_INFO_FOR_DISPLAY } from '../../../utils/Timeseries/OMMeasurement/TimeInterval';

const { Panel } = Collapse;

// 計測値のデータポイントを結合する際に用いる型
export type JoinedItem = Record<string, number | Date | null>;
type DatapointAccumulator = {
  displayNames: string[],
  items: JoinedItem[],
}

/**
 * 計測値のデータポイントを外部結合し、テーブル表示やCSV出力ができる形式に変換する
 * @param {Record<string, TransformedDatapoint[]>} allDatapoints 表示対象計測値のデータポイント一覧
 * @returns {JoinedItem[]} 対象データポイントを結合してテーブル表示やCSV出力ができる形に変換した一覧
 */
export const outerJoinDatapoints = (allDatapoints: Record<string, TransformedDatapoint[]>): JoinedItem[] => {
  /**
   * 特定のタイムスタンプを持つデータポイントが見つからなかった場合に、結合用に対象計測値の値をnullで埋めて返す
   * @param {string[]} keys 対象計測値の表示項目名の一覧
   * @returns {JoinedItem} 対象項目の値をnullで埋めたテーブル表示用レコード
   */
  const getNullProperties = (keys: string[]): JoinedItem => Object.fromEntries(keys.map((key) => [key, null]));

  const joined = Object.entries(allDatapoints).reduce((acc: DatapointAccumulator, [displayName, rows]) => {
    rows.forEach(({ timestamp, ...rest }) => {
      // 結合用データに、対象タイムスタンプを含むレコードが既に存在するか検索（ない場合はいったんgetNullProperties()で枠だけ用意する）
      let targetIndex = acc.items.findIndex((item) => item.timestamp === timestamp);
      if (targetIndex < 0) {
        targetIndex = acc.items.push({ timestamp, ...getNullProperties(acc.displayNames) }) - 1;
      }
      acc.items[targetIndex][displayName] = rest[displayName];
    });
    acc.displayNames.push(displayName);
    acc.items.forEach((item) => {
      acc.displayNames.forEach((table) => {
        // eslint-disable-next-line no-param-reassign
        item[table] = item[table] ?? null;
      });
    });
    return acc;
  }, { displayNames: [], items: [] });

  return [...joined.items].sort((a, b) => (a.timestamp as number) - (b.timestamp as number));
};

interface MeasurementListProps {
  areaAssetId: number;
}

/**
 * 計測一覧画面のコンポーネント
 * @param {MeasurementListProps} props プロパティ
 * @returns 計測一覧画面
 */
const MeasurementList: React.FC<MeasurementListProps> = (props: MeasurementListProps) => {
  const { areaAssetId } = props;

  const [treeDataLoading, setTreeDataLoading] = useState<boolean>(true);
  const [rootTreeNode, setRootTreeNode] = useState<TreeNode<OMEquipment>>();
  const [startDate, setStartDate] = useState<Moment>();
  const [timeRange, setTimeRange] = useState<RetrievalTimeRangeInfo>();
  /* 種別の選択肢（timeRange）をテーブルの表示形式に紐付けているとテーブル表示後も種別の選択に応じて日付の表示が変わってしまうので、
      表示用の種別を保持するstate変数を用意し、検索完了時のみセットしてテーブルに渡すように修正 */
  const [displayTimeRange, setDisplayTimeRange] = useState<RetrievalTimeRangeInfo>();
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [searchPanelKey, setSearchPanelKey] = useState<string | string[]>(['1']);
  const [measurementData, setMeasurementData] = useState<OMMeasurement[]>();
  const [measurementDataLoading, setMeasurementDataLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);

  /**
   * 指定された計測値のタイムシリーズを一覧で取得し、それぞれについて指定期間内のデータポイントも取得しまとめて返却する
   * @param start 表示開始日時
   * @param selectedRange 表示期間の種別
   * @returns 指定した期間中のデータポイントを含む対象計測値データの一覧
   */
  const retrieveTargetMeasurements = async (start: Moment, selectedRange: RetrievalTimeRangeInfo): Promise<OMMeasurement[]> => {
    const { addTimeSpan } = selectedRange;
    const from = start.clone().startOf(addTimeSpan as unitOfTime.StartOf);
    const to = from.clone().endOf(addTimeSpan);
    return OMMeasurement.loadAllSelectedMeasurements(
      checkedItems.map((value) => Number(value)),
      from.toDate(),
      to.toDate(),
      selectedRange.range,
    );
  };

  /*
   * イベントハンドラ
   */

  /**
   * 表示開始期間をカレンダー（DatePicker)で選択・変更した際のイベントハンドラ
   * @param {Moment | null} date 日付
   */
  const handleDateSelect = (date: Moment | null) => {
    const selectedDate = date ?? undefined;
    setStartDate(selectedDate);
  };

  /**
   * 計測グループ・計測ポイントのプルダウンから表示対象計測値の選択を変更した際のイベントハンドラ
   * @param {TreeNodeValue} changeValue 変更後の計測ポイントのID一覧
   */
  const handleTreeSelectChange = (changeValue: string[]) => {
    setCheckedItems(changeValue);
  };

  /**
   * 「表示」ボタンクリック時のイベントハンドラ
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e クリックイベント
   */
  const handleClickSearchButton = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setMeasurementDataLoading(true);

    // 表示条件設定の項目に一つでも未設定のものがあった場合
    if (!(!!startDate && !!timeRange && !!checkedItems.length)) {
      message.error('表示開始時期、種別、計測ポイントのすべてを指定してください。');
      setMeasurementDataLoading(false);
      return;
    }

    // 計測ポイントが5つ以上選択されている場合
    if (checkedItems.length > 4) {
      message.error('表示対象の計測ポイントは4つ以下を指定してください。');
      setMeasurementDataLoading(false);
      return;
    }

    // 表示条件設定のパネルを閉じてから対象データを取得する
    setSearchPanelKey([]);
    const targetMeasurements = await retrieveTargetMeasurements(startDate, timeRange);
    if (
      !targetMeasurements.length
      || targetMeasurements.every(({ transformedDatapoints }) => !transformedDatapoints.length)
    ) {
      message.error('指定した条件に合致するデータが見つかりませんでした。');
      setMeasurementDataLoading(false);
      return;
    }

    setMeasurementData(targetMeasurements);
    setDisplayTimeRange(timeRange);
    setMeasurementDataLoading(false);
  };

  /**
   * 「ダウンロード」ボタンクリック時のイベントハンドラ
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e クリックイベント
  */
  const handleClickDownload = async (e: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> => {
    e.stopPropagation();
    setDownloading(true);

    // 表示条件設定の項目に一つでも未設定のものがあった場合
    if (!(!!startDate && !!timeRange && !!checkedItems.length)) {
      message.error('表示開始時期、種別、計測ポイントのすべてを指定してください。');
      setDownloading(false);
      return;
    }
    // 指定された計測値のタイムシリーズを一覧で取得し、それぞれについて指定期間内のデータポイントも取得する
    const targetMeasurements = await retrieveTargetMeasurements(startDate, timeRange);
    if (
      !targetMeasurements.length
      || targetMeasurements.every(({ transformedDatapoints }) => !transformedDatapoints.length)
    ) {
      message.error('指定した条件に合致するデータが見つかりませんでした。');
      setDownloading(false);
      return;
    }

    // 表示対象計測値のデータポイント抽出と表示用の形式への変換
    const allDatapoints: Record<string, TransformedDatapoint[]> = {};
    targetMeasurements?.forEach(({ displayName, transformedDatapoints }) => {
      allDatapoints[displayName] = transformedDatapoints;
    });
    const joinedDatapoints = outerJoinDatapoints(allDatapoints);

    const header = Object.keys(joinedDatapoints[0])
      .map((key) => (
        {
          key,
          headerString: key === 'timestamp' ? startDate.format(timeRange.csvHeaderFormat) : key,
        }
      ));

    const csvData = generateCsvData(header, joinedDatapoints, timeRange.displayFormat);
    const aTagForDownload = document.createElement('a');
    let url = '';
    try {
      url = URL.createObjectURL(csvData);
      document.body.appendChild(aTagForDownload);
      aTagForDownload.href = url;
      aTagForDownload.download = `MeasureList_${moment().format('YYYYMMDDHHmmss')}.csv`;
      aTagForDownload.click();
    } catch (error) {
      message.error('ダウンロードできませんでした。');
    } finally {
      aTagForDownload.remove();
      URL.revokeObjectURL(url);
    }
    setDownloading(false);
  };

  /**
   * 「条件クリア」ボタンクリック時のイベントハンドラ
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e クリックイベント
  */
  const handleClickClear = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    e.currentTarget.blur();
    setStartDate(undefined);
    setTimeRange(undefined);
    setCheckedItems([]);
    setMeasurementData(undefined);
    // 表示条件設定が閉じている場合は再度開く（初期表示時は開いているため）
    if (!searchPanelKey.length) {
      setSearchPanelKey(['1']);
    }
  };

  useEffect(
    () => {
      if (!treeDataLoading) return () => { /* 何もしない */ };

      if (!areaAssetId) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        let loadRootTreeNode: TreeNode<OMEquipment> | undefined;
        try {
          const site = await OMEquipment.loadOneByIdFromCDF(areaAssetId);
          if (!site) {
            throw new Error();
          }

          loadRootTreeNode = await site.loadOMEquipmentTreeFromCDF(FILTER_MODE_MEASUREMENT);
        } catch (exception) {
          message.error(ERROR_LOAD_FACILITY_ASSET_TREE);
        }

        if (!canceled) {
          setRootTreeNode(loadRootTreeNode);
          setTreeDataLoading(false);
        }
      })();

      return () => { canceled = true; };
    },
    [areaAssetId, treeDataLoading],
  );

  return (
    <div style={{ marginTop: '3.3%' }}>
      <Collapse activeKey={searchPanelKey} onChange={setSearchPanelKey}>
        <Panel
          key="1"
          header={(
            <Fragment key="search_panel_header">
              <Row>
                <h3>表示条件設定</h3>
              </Row>
              <Row>
                {/* カレンダー操作時に表示条件設定メニューの開閉を防ぐためにColのクリックハンドラで制御を追加 */}
                <Col span={16} onClick={(e) => e.stopPropagation()}>
                  <span>●表示開始期間 </span>
                  <DatePicker
                    id="om-dashboard-measurement-date-picker"
                    placeholder="日付を選択"
                    format="YYYY/MM/DD"
                    value={startDate}
                    onChange={handleDateSelect}
                    onFocus={(e) => e.stopPropagation()}
                  />
                </Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  <Button
                    type="primary"
                    id="om-dashboard-measurement-search-button"
                    style={{ marginRight: 10 }}
                    loading={measurementDataLoading}
                    onClick={handleClickSearchButton}
                  >
                    表示
                  </Button>
                  <Button
                    type="primary"
                    id="om-dashboard-measurement-download-button"
                    onClick={handleClickDownload}
                    style={{ marginRight: 10 }}
                    disabled={downloading}
                    loading={downloading}
                  >
                    CSV出力
                  </Button>
                  <Button
                    id="om-dashboard-measurement-clear-button"
                    onClick={handleClickClear}
                    style={{ marginRight: 10 }}
                  >
                    条件クリア
                  </Button>
                </Col>
              </Row>
            </Fragment>
          )}
        >
          {/* 表示条件設定（パネル開閉時に展開される箇所） */}
          <Row style={{ padding: 10 }}>
            <div style={{ paddingBottom: 10 }}>
              <span>●種別 </span>
            </div>
            <div>
              <Radio.Group
                id="om-dashboard-measurement-range-select"
                value={timeRange}
                onChange={(e) => setTimeRange(e.target.value)}
              >
                {TIME_RANGE_INFO_FOR_DISPLAY.map((rangeInfo) => (
                  <Radio
                    key={`radio_${rangeInfo.range}`}
                    value={rangeInfo}
                    checked={rangeInfo.displayName === timeRange?.displayName}
                  >
                    {rangeInfo.displayName}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </Row>
          <Row style={{ padding: 10 }}>
            <div style={{ paddingBottom: 10 }}>
              <span>●計測グループ・計測ポイント </span>
            </div>
            <Spin spinning={treeDataLoading}>
              <MeasurementSelect
                rootTreeNode={rootTreeNode}
                checkedItems={checkedItems}
                onChange={handleTreeSelectChange}
              />
            </Spin>
          </Row>
        </Panel>
      </Collapse>

      {/* 計測値表示テーブル（ソート解除ボタン含む） */}
      {(measurementData && displayTimeRange) && (
        <MeasurementTable
          measurements={measurementData}
          timeRange={displayTimeRange}
          loading={measurementDataLoading}
        />
      )}
    </div>
  );
};

export default MeasurementList;
