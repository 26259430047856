/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-template */
// eslint警告未対応
import {
  EP_PATH_LEARNING_PJ_FILES_LIST,
  EP_PATH_LEARNING_PJ_FILES_DOWNLOAD,
  EP_PATH_LEARNING_PJ_FILES,
} from '../AWS/EndpointPath';
import { getStorageDataSetId } from '../storageCommon';
import { fileRetrieve } from '../dataAccess';
import { MimeType } from '../File/BaseFile';
import {
  postApiGateway,
} from '../AWS/ApiGateway';

/**
 * coco_json FileのExternalIdの取得
 * @param {String} assetID assetID
 * @param {String} assetName asset名
 * @returns {String} ExternalID
 */
export function getCocoJsonExternalId(assetID, assetName) {
  // TODO 現状、jsonは１ファイルしか考慮していないので連番部分は固定
  return assetID + assetName + '_1';
}

/**
 * coco_json File名の取得
 * @param {String} assetName asset名
 * @returns {String} coco_json FileName
 */
export function getCocoJsonName(assetName) {
  return assetName + '_coco.json';
}

/**
 * coco_json Fileの取得
 * @param {String} externalId ExternalID
 * @returns {object} coco_json
 */
export async function getCocoJsonFile(externalId) {
  let jsonFile;
  const file = await fileRetrieve({ endpoint: EP_PATH_LEARNING_PJ_FILES_LIST, externalId });
  if (file) {
    const downloadUrl = await postApiGateway(
      EP_PATH_LEARNING_PJ_FILES_DOWNLOAD, { items: [{ id: file.id }] },
    );
    const url = downloadUrl.items[0].downloadUrl;
    const res = await fetch(url);
    jsonFile = await res.json();
  }
  return jsonFile;
}

/**
 * jsonFileの保存
 * @param {String} externalId ExternalID
 * @param {String} name fileName
 * @param {number} assetId AssetID
 * @param {object} file coco_json
 */
export async function saveCocoJsonFile(externalId, name, assetId, file) {
  const { Json } = MimeType;
  const bodyData = {
    externalId,
    name,
    assetIds: [assetId],
    dataSetId: getStorageDataSetId(),
    mimeType: Json,
    metadata: { type: 'coco' },
  };
  const fileContent = JSON.stringify(file);
  await postApiGateway(EP_PATH_LEARNING_PJ_FILES, { bodyData, fileContent });
}

/**
 * annotation情報imagesの削除
 * @param {array} images 削除対象の画像情報
 * @param {string} fileId 削除対象のfileID
 * @returns {number} 削除したImageID
 */
export function deleteImages(images, fileId) {
  const imagesCopy = [...images];
  let imageId;
  for (const [index, image] of imagesCopy.entries()) {
    if (image.file_name.indexOf(fileId) !== -1) {
      const deleteImage = images.splice(index, 1);
      imageId = deleteImage[0].id;
      break;
    }
  }
  return imageId;
}

/**
 * Categoriesの削除
 * @param {array} categories削除対象のクラス情報
 * @param {string} classTag 削除対象のクラスタグ
 * @returns {number} 削除したCategoryID
 */
export function deleteCategories(categories, classTag) {
  const categoriesCopy = [...categories];
  let categoryId;
  for (const [index, category] of categoriesCopy.entries()) {
    if (category.name === classTag) {
      const deleteCategory = categories.splice(index, 1);
      categoryId = deleteCategory[0].id;
      break;
    }
  }
  return categoryId;
}

/**
 * Annotationsの削除
 * @param {array} annotations 削除対象のAnnotation情報
 * @param {string} target 削除対象(image_id or category_id)
 * @param {number} id 削除対象のID
 */
export function deleteAnnotations(annotations, target, id) {
  const annotationsCopy = [...annotations];
  for (const [index, annotation] of annotationsCopy.entries()) {
    if (annotation[target] === id) {
      annotations.splice(index, 1);
      deleteAnnotations(annotations, target, id);
      break;
    }
  }
}

export default {
  getCocoJsonExternalId,
  getCocoJsonName,
  getCocoJsonFile,
  saveCocoJsonFile,
  deleteImages,
  deleteCategories,
  deleteAnnotations,
};
