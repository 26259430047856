import React, {
  useState,
  useEffect,
} from 'react';
import {
  Asset,
  Timestamp,
} from '@cognite/sdk';
import moment from 'moment';
import {
  Row,
  Col,
  Button,
  DatePicker,
  Table,
  Select,
  Modal,
  Form,
  Tooltip,
  message,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { FormComponentProps } from 'antd/lib/form';
import locale from 'antd/es/date-picker/locale/ja_JP';

import { DuplicateData, OperationData, showDuplicateWarningModal } from './OperationalStatusInput/DuplicateWarningModal';
import {
  CONFIRM_DELETE_INPUT_AREA,
  CONFIRM_DELETE_TITLE,
  ERROR_CAUSE_OF_LOSS_NOT_SELECTED,
  ERROR_INPUT_OPERATIONAL_STATUS,
  ERROR_NO_AUTH_MESSAGE,
  ERROR_PCS_NOT_SELECTED,
  ERROR_RANGE_FRAUDULENT_DATA,
  ERROR_RANGE_NOT_SELECTED,
  ERROR_RANGE_OVERLAP_DATA,
  ERROR_OVERLAP_EVENT_EXISTS,
  ERROR_STATUS_NOT_SELECTED,
  SUCCESS_INPUT_OPERATIONAL_STATUS,
} from '../../utils/messages';

import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import OperationalStatus from '../../utils/Event/OperationalStatus';
import { getAllEvents } from '../../utils/dataAccess';
import { EP_PATH_SOLAR_EVENTS_LIST } from '../../utils/AWS/EndpointPath';
import './SolarOperationalStatusInputTable.css';

interface SolarOperationalStatusInputTableProps extends FormComponentProps {
  loading: boolean;
  pcsInfo: Asset[] | undefined;
  dataSetId: number | undefined;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * 運転状況入力画面
 */
const SolarOperationalStatusInputTableBase = (props: SolarOperationalStatusInputTableProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRegisterButtonDisabled, setIsRegisterButtonDisabled] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<{ key: number }[]>([]);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isDeleteDialogLoading, setIsDeleteDialogLoading] = useState(false);
  const [selectedRecordKey, setSelectedRecordKey] = useState<number | undefined>(undefined);

  const {
    loading,
    pcsInfo,
    dataSetId,
    form,
  } = props;
  const { getFieldsValue } = form;

  /** ダイアログのボタンラベル */
  const MODAL_BUTTON_TEXT = {
    DELETE: '削除',
    CANCEL: 'キャンセル',
  };

  /** 逸失原因 */
  const CAUSE_OF_LOSS = [
    { displayName: '出力抑制', value: '出力抑制' },
    { displayName: 'メンテナンス(電力会社起因)', value: 'メンテナンス(電力会社起因)' },
    { displayName: 'メンテナンス(発電所起因)', value: 'メンテナンス(発電所起因)' },
    { displayName: '故障(ケーブル盗難)', value: '故障(ケーブル盗難)' },
    { displayName: '故障', value: '故障' },
  ];

  /** 入力テーブル初期表示レコード数 */
  const InitialDisplayNumber = 10;

  /**
   * テーブルに表示するレコードを作成
   * @param {number} keyValue レコードのkey
   */
  const createRowData = (keyValue: number) => ({ key: keyValue });

  /**
   * イベントのbody(一件分)を作成
   * @param {string} elm アセットId
   * @param {string} status 逸失原因
   * @param {Timestamp} startTime 開始日時
   * @param {Timestamp} endTime 終了日時
   * @return {OperationalStatus} イベントbody
   */
  const createStatusItem = (elm: string, status: string, startTime: Timestamp, endTime: Timestamp): OperationalStatus => {
    const statusItem = new OperationalStatus();
    statusItem.dataSetId = dataSetId;
    statusItem.assetIds = [Number(elm)];
    statusItem.metadata = { status, isProcessed: 'false', isDeleted: 'false' };
    statusItem.startTime = moment(startTime).valueOf();
    statusItem.endTime = endTime;
    return statusItem;
  };

  /**
   * 登録済みイベント重複確認
   * @param {string[]} pcs PCSアセットIdの配列
   * @param {string[]} causeOfLoss 逸失原因の配列
   * @param {Timestamp[][]} targetPeriod 開始日時、終了時間の配列
   * @return 登録済みイベントの配列,重複した入力イベントの配列
   */
  const checkOverLappingEvent = async (pcs: string[], causeOfLoss: string[], targetPeriod: Timestamp[][]): Promise<{
    registeredData: OperationData[];
    unRegisteredData: OperationData[];
  }> => {
    // 登録済みイベントとの重複チェック
    const startTimeArr: number[] = [];
    const endTimeArr: number[] = [];
    targetPeriod.forEach((item) => {
      startTimeArr.push(moment(item[0]).valueOf());
      endTimeArr.push(moment(item[1]).valueOf());
    });
    const uniquePcsAssetIds = Array.from(new Set(pcs.map((elm) => Number(elm))));
    const filter = {
      type: OperationalStatus.eventType,
      assetIds: uniquePcsAssetIds,
      metadata: {
        isDeleted: 'false',
      },
    };
    const response = await getAllEvents(EP_PATH_SOLAR_EVENTS_LIST, filter);
    const registeredData: OperationData[] = [];
    const unRegisteredData: OperationData[] = [];
    const overlappingEventId: number[] = [];
    if (response.length > 0) {
      // 入力イベントとの重複を確認
      pcs.forEach((pcsId, index) => {
        const isOverLappingEvents = response.filter((existingEvent) => (
          Number(pcsId) === existingEvent.assetIds?.[0]
          && causeOfLoss[index] === existingEvent.metadata?.status
          && (startTimeArr[index] < Number(existingEvent.endTime) && endTimeArr[index] > Number(existingEvent.startTime))
        ));
        if (isOverLappingEvents.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const { name } = pcsInfo!.find((pcsInfoItem) => pcsInfoItem.id === Number(pcsId))!;
          isOverLappingEvents.forEach(({
            id, metadata, startTime, endTime,
          }) => {
            if (overlappingEventId.includes(id) === false) {
              // 既存重複イベントを追加
              registeredData.push({
                pcs: name,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                status: metadata!.status,
                startTime: Number(startTime),
                endTime: Number(endTime),
              });
              overlappingEventId.push(id);
            }
          });
          // 入力イベントを追加
          unRegisteredData.push({
            pcs: name,
            status: causeOfLoss[index],
            startTime: startTimeArr[index],
            endTime: endTimeArr[index],
          });
        }
      });
    }
    return {
      registeredData,
      unRegisteredData,
    };
  };

  /** 初期化処理 */
  useEffect(() => {
    let canceled = false;
    (async () => {
      const {
        SOLAR_OPERATIONAL_STATUS_INPUT_REGISTRATION,
      } = AUTHENTICATION_TYPE_MATRIX.SOLAR_MENU.SOLAR_OPERATIONAL_STATUS_INPUT;
      if (!canceled) {
        setIsRegisterButtonDisabled(!await containsUIAuthType(SOLAR_OPERATIONAL_STATUS_INPUT_REGISTRATION));
      }
    })();
    return () => { canceled = true; };
  }, []);

  /** テーブル作成 */
  useEffect(() => {
    let canceled = false;
    const data = [];
    for (let i = 0; i < InitialDisplayNumber; i++) {
      data.push(createRowData(i));
    }
    if (!canceled) {
      setDataSource(data);
    }
    return () => { canceled = true; };
  }, []);

  /** 行を追加 */
  const handleClickAdd = () => {
    const newDataSource = [...dataSource, createRowData(Object.keys(dataSource).length + 1)];
    setDataSource(newDataSource);
  };

  /** 行を削除 */
  const handleDelete = () => {
    const newDataSource = dataSource.filter((item) => item.key !== selectedRecordKey);
    setDataSource(newDataSource);
    setIsDeleteDialogVisible(false);
    setIsDeleteDialogLoading(false);
  };

  /**
   * 削除ボタン押下イベント
   * @param {number} key 選択したレコードのkey
   */
  const handleClickDelete = (key: number) => {
    setSelectedRecordKey(key);
    setIsDeleteDialogVisible(true);
  };

  /**
   * 削除確認ダイアログ キャンセルボタン押下イベント
   */
  const handleClickDialogCancel = () => {
    setIsDeleteDialogVisible(false);
  };

  /** 登録ボタン押下イベント */
  const handleClickRegistration = () => {
    form.validateFieldsAndScroll({ force: true, scroll: { offsetBottom: 30 } }, async (err, items) => {
      if (err) return;
      // 登録処理
      setIsLoading(true);
      const inputValueItems = JSON.parse(JSON.stringify(items));
      const pcs: string[] = [];
      const causeOfLoss: string[] = [];
      const targetPeriod: Timestamp[][] = [];
      // 入力値を成形
      Object.keys(inputValueItems).forEach((key) => {
        if (inputValueItems[key] !== undefined && key.includes('pcs')) {
          pcs.push(inputValueItems[key]);
        } else if (inputValueItems[key] !== undefined && key.includes('causeOfLoss')) {
          causeOfLoss.push(inputValueItems[key]);
        } else if (inputValueItems[key] !== undefined && key.includes('targetPeriod')) {
          targetPeriod.push(inputValueItems[key]);
        }
      });

      // 入力がない場合はメッセージを表示
      if (pcs.length === 0) {
        message.error(ERROR_STATUS_NOT_SELECTED);
        setIsLoading(false);
        return;
      }

      // 登録済みイベントとの重複チェック
      const { registeredData, unRegisteredData } = await checkOverLappingEvent(pcs, causeOfLoss, targetPeriod);
      if (registeredData.length > 0) {
        showDuplicateWarningModal(registeredData as DuplicateData, unRegisteredData as DuplicateData);
        setIsLoading(false);
        return;
      }

      const eventItem: OperationalStatus[] = [];
      pcs.forEach((elm, key) => {
        let startTime = targetPeriod[key][0];
        let endTime = targetPeriod[key][1];
        // 月をまたぐ場合は月ごとに分割してイベントを登録（終了日時が月初0時の場合、終了月のイベントは作成しない）
        while (!(moment(startTime).isSame(endTime, 'month')) && !(moment(endTime).isSame(moment(startTime).add(1, 'month').startOf('month')))) {
          const tmpEndTime = moment(startTime).add(1, 'month').startOf('month').valueOf();
          const statusItem = createStatusItem(elm, causeOfLoss[key], startTime, tmpEndTime);
          eventItem.push(statusItem);
          startTime = moment(startTime).add(1, 'month').startOf('month').valueOf();
        }
        endTime = moment(endTime).valueOf();
        const statusItem = createStatusItem(elm, causeOfLoss[key], startTime, endTime);
        eventItem.push(statusItem);
      });
      try {
        await OperationalStatus.createOperationalStatusEvent(eventItem);
        message.info(SUCCESS_INPUT_OPERATIONAL_STATUS);
      } catch (e) {
        if (e instanceof Error) {
          if (e.message === '400') {
            message.error(ERROR_OVERLAP_EVENT_EXISTS);
          } else {
            message.error(ERROR_INPUT_OPERATIONAL_STATUS);
          }
        }
      } finally {
        setIsLoading(false);
      }
    });
  };

  /** pcs選択領域のオプション作成 */
  const createPcsOptions = () => pcsInfo?.map((item) => (
    <Option key={item.id} value={item.id}>{item.name}</Option>
  ));

  /** 逸失原因のオプション作成 */
  const createCauseOfLossOptions = CAUSE_OF_LOSS.map((cause) => (
    <Option key={cause.value} value={cause.value}>{cause.displayName}</Option>
  ));

  /**
   * フィールドの未入力チェック
   * @param {any} rule フィールド情報
   * @param {any} value 入力された値
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validator = async (rule: any, value: any) => {
    const key = rule.field.replace(/[^0-9]/g, '');
    const fieldsValue = getFieldsValue([`pcs-${key}`, `targetPeriod-${key}`, `causeOfLoss-${key}`]);
    if (fieldsValue[`pcs-${key}`] === undefined
      && (fieldsValue[`targetPeriod-${key}`] === undefined || fieldsValue[`targetPeriod-${key}`].length === 0)
      && fieldsValue[`causeOfLoss-${key}`] === undefined) {
      return;
    }
    if (value === undefined || value.length === 0) {
      throw new Error(rule.message);
    }
  };

  /**
   * 期間指定の入力チェック（同日同時間が指定された場合はエラーを返す）
   * @param {any} rule フィールド情報
   * @param {any} value 入力された値
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rangeValidator = async (rule: any, value: any) => {
    const key = rule.field.replace(/[^0-9]/g, '');
    const fieldsValue = getFieldsValue([`targetPeriod-${key}`]);
    if (fieldsValue[`pcs-${key}`] === undefined
      && (fieldsValue[`targetPeriod-${key}`] === undefined || fieldsValue[`targetPeriod-${key}`].length === 0)
      && fieldsValue[`causeOfLoss-${key}`] === undefined) {
      return;
    }
    if (value.length !== 0 && fieldsValue[`targetPeriod-${key}`][0].isSame(fieldsValue[`targetPeriod-${key}`][1])) {
      throw new Error(rule.message);
    }
  };

  /**
   * 入力項目の重複チェック（入力値の逸失原因・PCS・期間すべてに被りがある場合エラーを返す）
   * @param {any} rule フィールド情報
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const overlapValidator = async (rule: any) => {
    const key = rule.field.replace(/[^0-9]/g, '');
    const fieldsValue = getFieldsValue([`pcs-${key}`, `targetPeriod-${key}`, `causeOfLoss-${key}`]);
    // 期間が入力されているかチェック
    if (fieldsValue[`targetPeriod-${key}`] === undefined || fieldsValue[`targetPeriod-${key}`].length === 0) {
      return;
    }
    // 入力フォームのすべての値を取得
    const allInputData = form.getFieldsValue();
    const keys = Object.keys(allInputData);

    // 入力フォームのすべての値を配列に格納する
    const inputDataArray = [];
    for (let i = 0; i < keys.length; i += 3) {
      inputDataArray.push(
        {
          pcs: allInputData[keys[i]],
          causeOfLoss: allInputData[keys[i + 1]],
          targetPeriod: allInputData[keys[i + 2]],
        },
      );
    }
    // 配列の中から重複しているデータを探し、各行にエラーメッセージを表示
    const result = inputDataArray.filter((item) => (
      (item.pcs && item.pcs === fieldsValue[`pcs-${key}`])
      && (item.causeOfLoss && item.causeOfLoss === fieldsValue[`causeOfLoss-${key}`])
      && (item.targetPeriod && item.targetPeriod[0].isBefore(fieldsValue[`targetPeriod-${key}`][1]))
      && (item.targetPeriod && item.targetPeriod[1].isAfter(fieldsValue[`targetPeriod-${key}`][0]))
    ));
    if (result.length >= 2) {
      throw new Error(rule.message);
    }
  };

  const columns = [
    {
      title: 'PCS',
      dataIndex: 'pcs',
      key: 'pcs',
      width: 150,
      render: (_: unknown, record: { key: number; }) => (
        <Form.Item key={record.key}>
          {form.getFieldDecorator(`pcs-${record.key}`, {
            validateTrigger: 'onSubmit',
            rules: [{ message: ERROR_PCS_NOT_SELECTED, validator }],
          })(
            <Select style={{ width: 250 }} allowClear placeholder="Please select">
              {createPcsOptions()}
            </Select>,
          )}
        </Form.Item>
      ),
    },
    {
      title: '逸失原因',
      dataIndex: 'causeOfLoss',
      key: 'causeOfLoss',
      width: 150,
      render: (_: unknown, record: { key: number; }) => (
        <Form.Item key={record.key}>
          {form.getFieldDecorator(`causeOfLoss-${record.key}`, {
            validateTrigger: 'onSubmit',
            rules: [{ message: ERROR_CAUSE_OF_LOSS_NOT_SELECTED, validator }],
          })(
            <Select style={{ width: 250 }} allowClear placeholder="Please select">
              {createCauseOfLossOptions}
            </Select>,
          )}
        </Form.Item>
      ),
    },
    {
      title: '対象期間',
      dataIndex: 'targetPeriod',
      key: 'targetPeriod',
      width: 180,
      render: (_: unknown, record: { key: number; }) => (
        <Form.Item key={record.key}>
          {form.getFieldDecorator(`targetPeriod-${record.key}`, {
            validateTrigger: 'onSubmit',
            rules: [
              { message: ERROR_RANGE_NOT_SELECTED, validator },
              { message: ERROR_RANGE_FRAUDULENT_DATA, validator: rangeValidator },
              { message: ERROR_RANGE_OVERLAP_DATA, validator: overlapValidator },
            ],
          })(
            <RangePicker
              showTime={{
                format: 'YYYY/MM/DD HH:mm',
                hideDisabledOptions: true,
                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
              }}
              format="YYYY/MM/DD HH:mm"
              placeholder={['開始年月日時', '終了年月日時']}
              locale={locale}
            />,
          )}
        </Form.Item>
      ),

    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      width: 30,
      render: (_: unknown, record: { key: number; }) => dataSource && (dataSource.length > 1 ? (
        <Button
          type="link"
          onClick={() => handleClickDelete(record.key)}
        >
          <DeleteOutlined />
        </Button>
      ) : undefined),
    },
  ];

  /**
   * データ登録画面をレンダリングする
   */
  return (
    <>
      <div>
        <Table
          style={{
            width: '90%',
            marginLeft: '70px',
          }}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
          scroll={{ y: 650 }}
          loading={loading}
        />
        <Row>
          <Col span={3} offset={1}>
            <Button
              type="primary"
              style={{ marginTop: 16, marginBottom: 16 }}
              onClick={handleClickAdd}
              disabled={dataSource.length >= 50}
            >
              +
            </Button>
            <span className="solar-operational-status-input-table-annotation"> （最大50件） </span>
          </Col>
          <Col offset={20}>
            <Tooltip title={isRegisterButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
              <Button
                type="primary"
                style={{ marginTop: 16, marginBottom: 16 }}
                onClick={handleClickRegistration}
                loading={isLoading}
                disabled={isRegisterButtonDisabled}
              >
                登録
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </div>
      <Modal
        title={CONFIRM_DELETE_TITLE}
        visible={isDeleteDialogVisible}
        confirmLoading={isDeleteDialogLoading}
        centered
        onOk={handleDelete}
        okText={MODAL_BUTTON_TEXT.DELETE}
        okType="danger"
        onCancel={handleClickDialogCancel}
        cancelText={MODAL_BUTTON_TEXT.CANCEL}
      >
        {CONFIRM_DELETE_INPUT_AREA}
      </Modal>
    </>
  );
};

const SolarOperationalStatusInputTable = Form.create<SolarOperationalStatusInputTableProps>()(SolarOperationalStatusInputTableBase);

export default SolarOperationalStatusInputTable;
