import {
  CogniteInternalId,
  FileInfo,
  FileAggregateQuery,
  ItemsResponse,
  FileAggregate,
} from '@cognite/sdk';

import { ImageFile } from './BaseFile';
import { postApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_AI_DETECT_RESULT_FILES_AGGREGATE,
} from '../AWS/EndpointPath';

const TYPE = 'detectimage';

class DetectionImageFile extends ImageFile {
  /*
   * クラスメソッド
   */

  /**
   * AI検出画像ファイル数カウント
   * @param {number} assetId
   * @returns ファイル数
   */
  static async countDetectImageFiles(assetId: number): Promise<number> {
    const filter: FileAggregateQuery = {
      filter: {
        assetIds: [assetId],
        metadata: { type: 'detectimage' },
      },
    };

    const detectImageAggregates = (
      await postApiGateway<FileAggregateQuery, ItemsResponse<FileAggregate>>(
        EP_PATH_AI_DETECT_RESULT_FILES_AGGREGATE, filter,
      )
    );

    return detectImageAggregates.items[0].count;
  }

  /*
   * メンバ変数
   */

  /*
   * アクセサ
   */
  // 本ファイルのタイプ
  static get type(): string {
    return TYPE;
  }

  // 検出対象のファイルのID
  get originalImageFileId(): CogniteInternalId | undefined {
    return Number(this.metadata?.baseImageId);
  }

  set originalImageFileId(targetImageFileId: CogniteInternalId | undefined) {
    if (!this.metadata) return;
    this.metadata.baseImageId = String(targetImageFileId);
  }

  // 何かが検出されたかどうか
  get detected(): boolean {
    if (!this.metadata || !this.metadata.detectFlag) return false;

    if (this.metadata.detectFlag.toLowerCase() === 'true') return true;

    return false;
  }

  set detected(detected: boolean) {
    if (!this.metadata) {
      this.metadata = {};
    }

    this.metadata.detectFlag = String(detected);
  }

  // 検出の際に利用したエンジンの名前
  get engineName(): string | undefined {
    return this.metadata?.engine;
  }

  set engineName(engineName: string | undefined) {
    if (engineName === undefined) {
      delete this.metadata?.engine;
    } else {
      if (!this.metadata) {
        this.metadata = {};
      }
      this.metadata.engine = engineName;
    }
  }

  /*
   * コンストラクタ
   */
  constructor(fileInfo?: FileInfo) {
    if (fileInfo) {
      super(fileInfo);
    } else {
      super();
    }

    if (!this.metadata) {
      this.metadata = {};
    }
    this.metadata.type = TYPE;
  }

  /*
   * メンバメソッド
   */
}

export default DetectionImageFile;
