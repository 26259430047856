import {
  Asset,
  AssetListScope,
  CursorAndAsyncIterator,
} from '@cognite/sdk';

import BaseAsset from './BaseAsset';

import { postApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_ROOT_ASSET_TRAIN_TASK } from '../AWS/EndpointPath';

const ASSET_TYPE = 'traintask';

class TrainTask extends BaseAsset {
  /*
   * クラスメソッド
   */
  static async loadAllFromCDF(): Promise<TrainTask[]> {
    const scope: AssetListScope = {
      filter: { metadata: { assetType: ASSET_TYPE } },
      limit: Infinity,
    };

    const assets = await postApiGateway<AssetListScope, CursorAndAsyncIterator<Asset>>(
      EP_PATH_ROOT_ASSET_TRAIN_TASK, scope,
    );

    return assets.items.map((asset) => new TrainTask(asset));
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      super(asset);
    } else {
      super();
    }
  }

  /*
   * メンバメソッド
   */
  cloneSelf(): TrainTask {
    const clone = new TrainTask();
    Object.assign(clone, this);

    return clone;
  }

  // eslint-disable-next-line class-methods-use-this
  async loadChildrenFromCDF(): Promise<BaseAsset[]> {
    return [];
  }
}

export default TrainTask;
