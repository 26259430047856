import { RequestHeader } from '../AWS/ApiGateway';

type AuthInformationData = {
  project: string;
  accessToken: string;
  requestHeader: RequestHeader;
  annotationFilesUploadUrl: string;
  imageFilesDownloadUrl: string;
  annotationFilesDownloadUrl: string;
};

/**
 * 権限取得
 */
const getAuthentication = async (): Promise<string[]> => {
  const cacheToken = await caches.open('sdf-preset-ap-func');
  const tokenData = await cacheToken.match('/authFunction');
  if (tokenData !== undefined) {
    const cacheTokenData = await tokenData.json();

    const actionsAuthentication = cacheTokenData.PermissionFunction.actions;
    return actionsAuthentication;
  }
  return [];
};

/**
 * 読み取り専用権限判定
 * ユーザー権限を取得し、その権限が読み取り専用かその他の権限かを判定する。
 * @returns 読み取り専用を取得した場合、trueを返却する。その他の場合、falseを返却する。
 */
async function isReadOnlyPermission(): Promise<boolean> {
  const cacheToken = await caches.open('sdf-preset-ap-func');
  const tokenData = await cacheToken.match('/authFunction');
  if (tokenData === undefined) {
    return false;
  }
  const cacheTokenData = await tokenData.json();
  const authentication = cacheTokenData.PermissionFunction;
  if (authentication === undefined) {
    return false;
  }
  const userRoleAuthentication = authentication.userRole;
  if (userRoleAuthentication === undefined) {
    return false;
  }
  return userRoleAuthentication === 'readOnly';
}

/**
 * Cacheの認証情報を更新する
 * @param authInformationData 更新後の認証情報
 */
const refreshAuthInformation = async (authInformationData: AuthInformationData): Promise<void> => {
  const cache = await caches.open('sdf-preset-ap');
  cache.put('/auth-information', new Response(JSON.stringify(authInformationData)));
};

/**
 * 認証情報をCacheから削除する
 */
const removeAuthInformation = async (): Promise<void> => {
  const authCache = await caches.open('sdf-preset-ap');
  authCache.delete('/auth-information');
};

/**
 * 権限情報をCacheから削除する
 */
const removeAuthFunction = async (): Promise<void> => {
  const funcCache = await caches.open('sdf-preset-ap-func');
  funcCache.delete('/authFunction');
};

export {
  getAuthentication, isReadOnlyPermission, refreshAuthInformation, removeAuthInformation, removeAuthFunction,
};
