import React from 'react';

import {
  Spin,
  Tree,
  Tooltip,
  message,
  Button,
  Layout,
  Typography,
  Divider,
} from 'antd';
import { AntTreeNodeExpandedEvent, AntTreeNodeSelectedEvent } from 'antd/lib/tree';

import TreeNode from '../../../utils/common/TreeNode';
import Facility from '../../../utils/Asset/Facility';
import ManagedFacility from '../../../utils/Asset/ManagedFacility';
import DetectionResult from '../../../utils/Asset/DetectionResult';
import SolarSite from '../../../utils/Asset/SolarSite';
import AssetEditFormModal from './AssetEditFormModal';
import { ResourceType, getResourceType } from '../../../utils/common/SDFDataType';

import {
  ERROR_LOAD_FACILITY_ASSET_TREE,
  ERROR_NO_AUTH_MESSAGE,
} from '../../../utils/messages';

import './AssetTree.css';

const { Sider } = Layout;
const { Title } = Typography;
const { DirectoryTree } = Tree;

const MANAGED_FACILITY_POSITION_KEY = '0-0';
const DEFAULT_TREE_WIDTH = 300;
const FIRST_OPENED_TREE_WIDTH = 340;
const ADD_TREE_WIDTH = 18;
const CHILD_FACILITY_LENGTH = 2;
const NAME_LENGTH_LIMIT = 15;

const AssetTree: React.FC<{
  parentAssetsId?: number,
  editable?: boolean,
  height?: number,
  title: string,
  onSelect: (asset?: Facility | DetectionResult | SolarSite) => void
  collapsed?: boolean,
  onCollapse?: (value: boolean) => void
  menuCollapsed?: boolean,
  rootResourceType: ResourceType,
  isExpandedRoot?: boolean,
  isReloadDisabled?: boolean,
  isAddDisabled?: boolean,
  isReadDisabled?: boolean,
  isEditDisabled?: boolean,
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [loading, setLoading] = React.useState<boolean>(true);
  const [editing, setEditing] = React.useState<boolean>(false);
  const [rootTreeNode, setRootTreeNode] = React.useState<
    TreeNode<Facility | DetectionResult | SolarSite>
  >();
  const [target, setTarget] = React.useState<Facility | DetectionResult | SolarSite>();
  const [treeWidth, setTreeWidth] = React.useState<number>(DEFAULT_TREE_WIDTH);
  const [treePositionList, setTreePositionList] = React.useState<{
    positionKey: string; length: number; expanded: boolean;
  }[]>([]);
  const [siderHeight, setSiderHeight] = React.useState<number>(window.innerHeight);
  const [siderWidth, setSiderWidth] = React.useState<number>(window.innerWidth);
  const [
    parentResourceType, setParentResourceType,
  ] = React.useState<ResourceType>(ResourceType.Facility);
  const [defaultExpandedKeys, setDefaultExpandedKeys] = React.useState<string[]>([]);

  const {
    parentAssetsId,
    editable,
    height,
    title,
    collapsed,
    menuCollapsed,
    rootResourceType,
    isExpandedRoot,
    isReloadDisabled,
    isAddDisabled,
    isReadDisabled,
    isEditDisabled,
  } = props;

  /**
   * Siderサイズ変更
   */
  const changeSiderSize = (): void => {
    const { innerHeight, innerWidth } = window;
    setSiderHeight(innerHeight);
    setSiderWidth(innerWidth);
  };

  /*
   * イベントハンドラ
   */
  React.useEffect(() => {
    window.addEventListener('resize', changeSiderSize);
    return () => window.removeEventListener('resize', changeSiderSize);
  }, []);

  React.useEffect(() => {
    if (isExpandedRoot) {
      setTreeWidth(FIRST_OPENED_TREE_WIDTH);
    }
  }, [isExpandedRoot]);

  React.useEffect(
    () => {
      if (!loading) return () => { /* 何もしない */ };

      if (!parentAssetsId) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        let loadRootTreeNode: TreeNode<Facility | DetectionResult | SolarSite> | undefined;

        let loadRootId: number | undefined;
        try {
          if (rootResourceType === ResourceType.ManagedFacility) {
            const facility = await ManagedFacility.loadOneByIdFromCDF(parentAssetsId);

            if (!facility) {
              throw new Error();
            }
            loadRootTreeNode = await facility.loadFacilityTreeFromCDF();
            loadRootId = facility.id;
          } else if (rootResourceType === ResourceType.SolarSite) {
            const site = await SolarSite.loadOneByIdFromCDF(parentAssetsId);

            if (!site) {
              throw new Error();
            }
            loadRootTreeNode = await site.loadSolarSiteTreeFromCDF();
            loadRootId = site.id;
          } else {
            // AI検出結果の場合
            loadRootTreeNode = await DetectionResult.loadDetectionResultTreeFromCDF();
            loadRootId = Number(loadRootTreeNode.key);
          }
        } catch (exception) {
          message.error(ERROR_LOAD_FACILITY_ASSET_TREE);
        }

        if (!canceled) {
          if (isExpandedRoot && loadRootId) {
            setDefaultExpandedKeys([loadRootId.toString()]);
          }
          setTarget(undefined);
          setRootTreeNode(loadRootTreeNode);
          setLoading(false);
        }
      })();

      return () => { canceled = true; };
    },
    [isExpandedRoot, loading, parentAssetsId, rootResourceType],
  );

  /**
   * 開閉情報変更
   * @param {string} positionKey 対象のTreeNodeの階層情報
   * @param {boolean} flag 開閉フラグ
   */
  const changeExpanded = (positionKey: string, flag: boolean): void => {
    treePositionList
      .filter((treePosition) => treePosition.positionKey === positionKey)
      // eslint-disable-next-line no-param-reassign
      .forEach((treePosition) => { treePosition.expanded = flag; });
  };

  /**
   * 縮小しているTreeNodeの階層情報リスト取得
   * @returns {string[]} 階層情報リスト
   */
  const getNotExpandedKeys = (): string[] => treePositionList
    .filter((treePosition) => !treePosition.expanded)
    .map((treePosition) => treePosition.positionKey);

  /**
   * 展開しているTreeNode情報リスト取得
   * @param {string[]} notExpandedKeys 縮小しているTreeNodeの階層情報リスト
   * @returns {{ positionKey: string; length: number; expanded: boolean; }[]} TreeNode情報リスト
   */
  const getExpandedTreeNodeList = (notExpandedKeys: string[]): {
    positionKey: string; length: number; expanded: boolean;
  }[] => treePositionList.filter((treePosition) => {
    const { expanded } = treePosition;
    const isNotExpandedChildren = !notExpandedKeys.some((notExpandedKey) => {
      const currentPositionKey = treePosition.positionKey;
      return currentPositionKey.startsWith(notExpandedKey);
    });

    return expanded && isNotExpandedChildren;
  });

  /**
   * Tree幅取得
   * @returns {number} Tree幅
   */
  const getTreeWidth = (): number => {
    const notExpandedKeys = getNotExpandedKeys();
    const expandedTreeNodeList = getExpandedTreeNodeList(notExpandedKeys);

    // 展開されているTreeNodeのうち、縮小されているTreeNode配下でないものの中から最大値を取得
    const maxLength = Math.max(
      ...expandedTreeNodeList
        .map((treePosition) => treePosition.length),
    );

    if (maxLength === CHILD_FACILITY_LENGTH) {
      return FIRST_OPENED_TREE_WIDTH;
    }
    return FIRST_OPENED_TREE_WIDTH + (maxLength - 2) * ADD_TREE_WIDTH;
  };

  /**
   * TreeNode展開
   * @param {string} positionKey 対象のTreeNodeの階層情報
   * @param {number} currentTreeLength 対象のTreeNodeの階層の深さ
   * @param {number} treePositionIndex 対象のTreeNodeのindex
   */
  const openTreeNode = (
    positionKey: string,
    currentTreeLength: number,
    treePositionIndex: number,
  ): void => {
    changeExpanded(positionKey, true);

    if (treePositionList.length === 0) {
      // 初回管理設備
      setTreePositionList([{
        positionKey,
        length: currentTreeLength,
        expanded: true,
      }]);
      setTreeWidth(FIRST_OPENED_TREE_WIDTH);
      return;
    }

    // 子設備以降
    if (treePositionIndex < 0) {
      // 未展開子設備
      treePositionList.push({
        positionKey,
        length: currentTreeLength,
        expanded: true,
      });
    }

    setTreePositionList(treePositionList);

    const currentTreeWidth = getTreeWidth();
    if (currentTreeWidth > treeWidth) setTreeWidth(currentTreeWidth);
  };

  /**
   * TreeNode縮小
   * @param {string} positionKey 対象のTreeNodeの階層情報
   */
  const closeTreeNode = (positionKey: string): void => {
    changeExpanded(positionKey, false);
    setTreePositionList(treePositionList);

    if (positionKey === MANAGED_FACILITY_POSITION_KEY) {
      // 管理設備
      setTreeWidth(DEFAULT_TREE_WIDTH);
      return;
    }

    const currentTreeWidth = getTreeWidth();
    if (currentTreeWidth < treeWidth) setTreeWidth(currentTreeWidth);
  };

  /**
   * ツリー開閉時のイベントハンドラ
   * @param {string[]} _
   * @param {AntTreeNodeExpandedEvent} data TreeNode情報
   */
  const handleExpand = (
    _: string[],
    data: AntTreeNodeExpandedEvent,
  ): void => {
    const currentTreePosition = data.node.props.pos;
    const currentTreeLength = currentTreePosition.split('-').length;
    const treePositionIndex = treePositionList.findIndex(
      (position) => position.positionKey === currentTreePosition,
    );
    if (data.expanded) {
      openTreeNode(currentTreePosition, currentTreeLength, treePositionIndex);
    } else {
      closeTreeNode(currentTreePosition);
    }
  };

  /**
   * ツリー選択時のイベントハンドラ
   * @param {string[]} selectedKeys 選択したTreeNodeのkey
   * @param {AntTreeNodeSelectedEvent} info TreeNodeの選択イベント情報
   */
  const handleSelect = (selectedKeys: string[], info: AntTreeNodeSelectedEvent): void => {
    if (info.selected) {
      const key = String(selectedKeys[0]);
      const facilityNode = rootTreeNode?.findNodeByKey(key);
      if (!facilityNode) {
        return;
      }
      props.onSelect(facilityNode.data);
    }
    if (!collapsed && props.onCollapse) props.onCollapse(true);
  };

  /*
   * メソッド
   */

  /**
   * ファイル名描画
   * @param {Facility | DetectionResult | SolarSite} facility 設備
   * @return {JSX.Element} ファイル名
   */
  const renderFacilityName = (facility: Facility | DetectionResult | SolarSite): JSX.Element => {
    const className = isReadDisabled ? 'name disable' : 'name';
    const tooltipTitle = isReadDisabled ? ERROR_NO_AUTH_MESSAGE : facility.name;
    return (
      <div className={className}>
        <Tooltip title={tooltipTitle} placement="bottomLeft">
          {facility.name.length > NAME_LENGTH_LIMIT ? `${facility.name.substring(0, NAME_LENGTH_LIMIT)}...` : facility.name}
        </Tooltip>
      </div>
    );
  };

  /**
   * 追加ボタン描画
   * @param {Facility | DetectionResult | SolarSite} facility 設備
   * @return {JSX.Element} 追加ボタン
   */
  const renderAddButton = (facility: Facility | DetectionResult | SolarSite): JSX.Element => {
    const returnNode = (
      <Tooltip title={isAddDisabled && ERROR_NO_AUTH_MESSAGE}>
        <Button
          type="link"
          icon="plus"
          onClick={(event) => {
            event.stopPropagation();
            const newFacility = new Facility();
            newFacility.parentId = facility.id;
            newFacility.dataSetId = facility.dataSetId;
            const isSelectManagedFacility = rootTreeNode?.data.id === facility.id;
            const resourceType = isSelectManagedFacility
              ? getResourceType(rootTreeNode?.data.metadata?.assetType as string)
              : getResourceType(facility?.metadata?.assetType as string);
            setParentResourceType(resourceType);
            setTarget(newFacility);
            setEditing(true);
          }}
          disabled={isAddDisabled}
        />
      </Tooltip>
    );
    return returnNode;
  };

  /**
   * 編集ボタン描画
   * @param {Facility | DetectionResult | SolarSite} facility 設備
   * @return {JSX.Element} 編集ボタン
   */
  const renderEditButton = (facility: Facility | DetectionResult | SolarSite): JSX.Element => {
    let returnNode;
    if (facility.id !== rootTreeNode?.data.id) {
      returnNode = (
        <>
          <Tooltip title={isEditDisabled && ERROR_NO_AUTH_MESSAGE}>
            <Button
              type="link"
              icon="edit"
              onClick={(event) => {
                event.stopPropagation();
                const isParentManagedFacility = (
                  rootTreeNode?.data.id === facility.parentId
                );
                const resourceType = isParentManagedFacility
                  ? (
                    getResourceType(
                      rootTreeNode?.data.metadata?.assetType as string,
                    )
                  ) : (
                    getResourceType(facility?.metadata?.assetType as string)
                  );
                setParentResourceType(resourceType);
                setTarget(facility.cloneSelf());
                setEditing(true);
              }}
              disabled={isEditDisabled}
            />
          </Tooltip>
        </>
      );
    } else {
      returnNode = <></>;
    }
    return returnNode;
  };

  /*
   * 画面描画
   */

  /**
   * TreeNodeのレンダリング
   * @param {TreeNode<Facility | DetectionResult | SolarSite>[]} treeNodes Tree用treeNodeリスト
   * @returns {JSX.Element[]} Tree用TreeNode
   */
  const renderTreeNodes = (
    treeNodes: TreeNode<Facility | DetectionResult | SolarSite>[],
  ): JSX.Element[] => (
    treeNodes.map((treeNode) => {
      const facility = treeNode.data;
      const facilityTitle = (
        <div className="common-facility-tree-node">
          {renderFacilityName(facility)}
          <div className="button-group">
            {
              editable
                ? (
                  <>
                    {renderAddButton(facility)}
                    {renderEditButton(facility)}
                  </>
                )
                : <></>
            }
            {
              facility.id === rootTreeNode?.data.id
                ? (
                  <Tooltip title={isReloadDisabled && ERROR_NO_AUTH_MESSAGE}>
                    <Button
                      type="link"
                      icon="reload"
                      onClick={(event) => {
                        event.stopPropagation();
                        setLoading(true);
                      }}
                      disabled={loading || isReloadDisabled}
                    />
                  </Tooltip>
                )
                : <></>
            }
          </div>
        </div>
      );

      return treeNode.isLeaf
        ? <Tree.TreeNode key={`${facility.id}`} title={facilityTitle} data={treeNode} />
        : (
          <Tree.TreeNode key={`${facility.id}`} title={facilityTitle} data={treeNode}>
            {renderTreeNodes(treeNode.children)}
          </Tree.TreeNode>
        );
    })
  );

  /**
   * DirectoryTreeNodeのレンダリング
   * @param {TreeNode<Facility | DetectionResult | SolarSite>[]} treeNodes DirectoryTree用treeNodeリスト
   * @returns {JSX.Element[]} DirectoryTree用TreeNode
   */
  const renderDirectoryTreeNodes = (
    treeNodes: TreeNode<Facility | DetectionResult | SolarSite>[],
  ): JSX.Element[] => (
    treeNodes.map((treeNode) => {
      const { id, name } = treeNode.data;

      return treeNode.isLeaf
        ? <Tree.TreeNode key={`${id}`} title={name} data={treeNode} icon={<></>} />
        : (
          <Tree.TreeNode key={`${id}`} title={name} data={treeNode} icon={<></>}>
            {renderDirectoryTreeNodes(treeNode.children)}
          </Tree.TreeNode>
        );
    })
  );

  return (
    <div>
      <Sider
        collapsible
        collapsed={collapsed}
        collapsedWidth={DEFAULT_TREE_WIDTH}
        trigger={null}
        width={siderWidth - (menuCollapsed ? 80 : 250)}
      >
        <Spin spinning={loading} style={{ height: siderHeight }}>
          <div
            style={{
              position: 'fixed',
              height: siderHeight,
              width: collapsed ? DEFAULT_TREE_WIDTH : '100%',
              backgroundColor: 'white',
              overflow: 'auto',
            }}
          >
            <div className="common-facility-tree-ant-title">
              <Title level={4}>{title}</Title>
            </div>
            <Divider className="common-facility-tree-ant-divider" />
            {
              rootTreeNode && (
                rootResourceType === ResourceType.SolarSite
                  ? (
                    <DirectoryTree
                      blockNode
                      defaultExpandedKeys={defaultExpandedKeys}
                      onSelect={handleSelect}
                      style={{
                        width: treeWidth,
                        height: 200,
                      }}
                    >
                      {renderDirectoryTreeNodes(rootTreeNode ? [rootTreeNode] : [])}
                    </DirectoryTree>
                  ) : (
                    <Tree
                      blockNode
                      defaultExpandedKeys={defaultExpandedKeys}
                      onSelect={handleSelect}
                      onExpand={handleExpand}
                      style={{
                        width: treeWidth,
                        height: height || 200,
                      }}
                    >
                      {renderTreeNodes(rootTreeNode ? [rootTreeNode] : [])}
                    </Tree>
                  )
              )
            }
          </div>
          {
            target && (
              <AssetEditFormModal
                visible={editing}
                target={target}
                parentResourceType={parentResourceType}
                onAdd={() => {
                  setTarget(undefined);
                  setEditing(false);
                  setLoading(true);
                }}
                onUpdate={() => {
                  setTarget(undefined);
                  setEditing(false);
                  setLoading(true);
                }}
                onCancel={() => {
                  setTarget(undefined);
                  setEditing(false);
                }}
              />
            )
          }
        </Spin>
      </Sider>
    </div>
  );
};

export default AssetTree;
