import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

// 判定結果
export const OM_STATUS = [
  { displayName: 'OK', value: 1 },
  { displayName: 'NG', value: 2 },
  { displayName: '判定対象外', value: 3 },
];

interface StatusSelectProps {
  onChange: (value?: number) => void;
}

/**
 * PCS比較診断結果一覧などに用いられる 判定結果セレクトボックス
 * @param {StatusSelectProps} props プロパティ
 * @returns 判定結果セレクトボックス
 */
const OMStatusSelect: React.FC<StatusSelectProps> = (
  props: StatusSelectProps,
) => {
  const selectOptions = OM_STATUS.map((status) => (
    <Option key={status.value} value={status.value}>{status.displayName}</Option>
  ));

  return (
    <Select
      id="om-status-select"
      style={{ width: 150 }}
      onChange={(value: number) => props.onChange(value)}
      allowClear
    >
      {selectOptions}
    </Select>
  );
};

export default OMStatusSelect;
