import React from 'react';
import Queue from 'promise-queue';
import {
  Button, Card, Row, Col, Tooltip, Select,
  message, Modal, Upload,
} from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import FileTable, {
  NO_DATA_STRING,
  DIGITAL_TWIN_APP,
  ANNOTATION,
  LEARNING,
  DETECT,
  DETECT_RESULT,
} from '../Common/File/FileTable';
import { sortName, sortOrder } from '../../utils/sort';
import { getAllAssets, getAllFiles, getProjectList } from '../../utils/dataAccess';
import { getCocoJsonExternalId, getCocoJsonFile } from '../../utils/cocoJsonEditor';
import { getDlAssetId, getResultAssetId } from '../../utils/storageCommon';
import FileUploadAddButton from '../Common/File/FileUploadAddButton';
import SearchMetadataModal from '../Common/Metadata/SearchMetadataModal';
import { ImageCompareView } from '../Common/File/ImageCompareView';
import { getFileListEndpoint } from '../../utils/common/SDFDataType';
import {
  ImageFile, UnknownFile, MimeType, CAPTURED_IMAGE, DETECTION_RESULT,
} from '../../utils/File/BaseFile';
import NoImageThumbnail from '../Common/File/parts/NoImageThumbnail';
import {
  EP_PATH_LEARNING_PJ_LIST,
  EP_PATH_AI_DETECT_RESULT_FILES_LIST,
  EP_PATH_ROOT_ASSET_AI_DETECT_RESULT,
} from '../../utils/AWS/EndpointPath';
import {
  POPUP_ERROR_MESSAGE_DISPLAY_TIME,
  IMAGE_DELETION_COMPLETED_SUCCESSFULLY,
  VALIDATE_ERROR_UNSELECTED_IMAGE,
  VALIDATE_ERROR_EXIST_IN_LEARNING_PJ_AFTER_FILE,
  VALIDATE_ERROR_EXIST_IN_LEARNING_PJ_AFTER_PJ,
  VALIDATE_ERROR_EXIST_IN_LEARNING_PJ,
  SUCCESS_ADD_IMAGE_TO_PJ,
  FAILED_ADD_IMAGE_TO_PJ,
  WARNING_ADD_IMAGE_TO_PJ,
  VALIDATE_ERROR_NO_SELECT_FILE_ADD_IMAGE_TO_PJ,
  VALIDATE_ERROR_NO_SELECT_PJ_ADD_IMAGE_TO_PJ,
  WARNING_DELETE_IMAGE_FROM_PJ_TITLE,
  WARNING_DELETE_IMAGE_FROM_PJ_MESSAGE,
  ERROR_FILES_GET_DL_URL,
  WARNING_DRAG_AND_DROP_FILE,
  ERROR_NO_AUTH_MESSAGE,
} from '../../utils/messages';
import { deleteFile, retrieveFile } from '../../utils/AWS/AWSRequest';
import { addImageFileToLearningProject } from '../../utils/File/LearningProjectFile';
import FileUpload from '../Common/File/FileUpload';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';

import './ImagesListViewer.css';

const { Option } = Select;
const { Jpeg, Png } = MimeType;

/**
 * ダイアログのボタンラベル
 */
const MODAL_BUTTON_TEXT = {
  DELETE: '削除',
  CANCEL: 'キャンセル',
};

/**
 * リスト表示タイプ
 */
export const LIST_TYPE = {
  JPEG_ONLY: 0, // JPEGのみ表示
  ALL_FILE: 1, // 全ファイル表示
};

/**
 * 画像一覧部共通component
 * @property {object} client CogniteClient
 * @property {number} listType リスト表示タイプ(LIST_TYPE参照)
 * @property {number} assetId 画像リスト取得するAssetID
 * @property {object} resourceType 画像リスト取得するResourceType
 * @property {string} assetName Asset名
 * @property {boolean} showAddButton 画像追加ボタンの表示有無
 * @property {boolean} showImageDisplaySelection  SelectBoxの表示可否
 * @property {boolean} showSettingLearningParameterButton パラメータ設定ボタン表示可否
 * @property {function} inActiveCheckbox CheckBoxの活性可否
 * @property {function} onClickImage 画像クリック時のfunction
 * @property {boolean} showDelButton 削除ボタン表示有無
 * @property {boolean} showDelFromPjButton 学習プロジェクトから削除ボタン表示有無
 * @property {function} deleteImageFromPj 学習プロジェクトから画像削除を行うfunction
 * @property {function} onClickSettingLearningParameter パラメータ設定クリック時のfunction
 * @property {boolean} showAddPjButton 学習プロジェクト追加ボタンの表示有無
 * @property {boolean} showAnnotation アノテーション開始ボタンの表示有無
 * @property {function} onClickAnnotation アノテーション開始ボタン押下時のfunction
 * @property {boolean} showDetectButton AI検出パラメータ設定ボタン表示有無
 * @property {function} onClickSettingDetectParameter AI検出パラメータ設定ボタン押下時のfunction
 * @property {boolean} showDetectColumns FileTable検出結果カラムの表示有無
 * @property {object} customStyle カスタムスタイル(cardStyle, fileListHeight, fileTableHeight)
 * @property {boolean} showAnnotationColumns FileTableアノテーションカラム表示有無
 * @property {function} onChangeFileType ファイル種別選択時のイベントハンドラ
 * @property {boolean} isModalEditButtonDisabled ファイル詳細ダイアログのメタデータ編集ボタンの活性状態
 * @property {boolean} isModalDownloadButtonDisabled ファイル詳細ダイアログのDLボタンの活性状態
 * @property {boolean} showSearchMetadata 高度な検索ボタンの表示有無
 * @property {number} fileNameStatus ファイル一覧のファイル名表示出し分けステータス
 */
export class ImagesListViewer extends React.Component {
  constructor(props) {
    super(props);
    this.allCheck = React.createRef();
    this.listSelectRef = React.createRef();
    this.checkBoxRefs = [];
  }

  state = {
    files: [],
    imageLoad: false,
    selectedDisplayTarget: 0,
    selectBoxOptions: [],
    selectedProject: undefined,
    addToProjectButtonStatus: false,
    deleteConfirmLoading: false,
    listOptions: null,
    selectedList: null,
    selectedDisplayTargetDisabled: false,
    deletingImage: false,
    checkFileList: [],
    searchMetadataModalVisible: false,
    metadataFilter: {},
    imageCompareViewVisible: false,
    disableCompareButton: true,
    detectResultAssetId: undefined,
    updateFiles: [],
    isFileTabAddButtonDisabled: true,
    isFileTabDeleteButtonDisabled: true,
    isFileTabPhotoComparisonDisabled: true,
    isFileTabLearningProjectSelectionDisabled: true,
    isFileTabAddLearningProjectDisabled: true,
    isLearningPJDetailsAnnotationStartDisabled: true,
    isLearningPJDetailsRemoveFromLearningPJDisabled: true,
    isLearningPJDetailsParameterSettingsDisabled: true,
    isEquipmentListAiParameterSettingsDisabled: true,
    isEquipmentListDeleteButtonDisabled: true,
    isInfoSearchDisabled: true,
    isUpdateButtonDisabled: true,
    isFileLinkDisabled: true,
    thumbnailInfoList: [],
  };

  /**
 * component render前の処理
 */
  async componentDidMount() {
    const id = this.props.assetId;
    const name = this.props.assetName;
    if (id !== null) {
      this.setState({ imageLoad: true, checkFileList: [] });
      this.allUncheck();

      const { resourceType, fileNameStatus } = this.props;

      switch (fileNameStatus) {
        case DIGITAL_TWIN_APP: {
          const baseMatrix = resourceType === 'equipment'
            ? AUTHENTICATION_TYPE_MATRIX.EQUIPMENT_DETAILS_EQ
            : AUTHENTICATION_TYPE_MATRIX.EQUIPMENT_DETAILS_TD;
          const {
            FILE_TAB_ADD_BUTTON,
            FILE_TAB_DELETE_BUTTON,
            FILE_TAB_DETAILED_INFORMATION_SEARCH,
            FILE_TAB_PHOTO_COMPARISON,
            FILE_TAB_LEARNING_PROJECT_SELECTION,
            FILE_TAB_ADD_LEARNING_PROJECT,
            FILE_TAB_UPDATE_BUTTON,
            FILE_TAB_UPLOAD_FILE_LINK,
          } = baseMatrix;

          this.setState({
            isFileTabAddButtonDisabled: !await containsUIAuthType(FILE_TAB_ADD_BUTTON),
            isFileTabDeleteButtonDisabled: !await containsUIAuthType(FILE_TAB_DELETE_BUTTON),
            isInfoSearchDisabled: !await containsUIAuthType(FILE_TAB_DETAILED_INFORMATION_SEARCH),
            isFileTabPhotoComparisonDisabled: !await containsUIAuthType(FILE_TAB_PHOTO_COMPARISON),
            isFileTabLearningProjectSelectionDisabled: !await containsUIAuthType(FILE_TAB_LEARNING_PROJECT_SELECTION),
            isFileTabAddLearningProjectDisabled: !await containsUIAuthType(FILE_TAB_ADD_LEARNING_PROJECT),
            isUpdateButtonDisabled: !await containsUIAuthType(FILE_TAB_UPDATE_BUTTON),
            isFileLinkDisabled: !await containsUIAuthType(FILE_TAB_UPLOAD_FILE_LINK),
          });
          break;
        }
        case ANNOTATION: {
          const {
            LEARNING_PROJECTS_DETAILS_UPDATE_BUTTON,
            LEARNING_PROJECTS_DETAILS_ANNOTATION_START,
            LEARNING_PROJECTS_DETAILS_REMOVED_FROM_LEARNING_PROJECT,
            LEARNING_PROJECTS_DETAILS_DETAILED_INFORMATION_SEARCH,
          } = AUTHENTICATION_TYPE_MATRIX.ANNOTATION;

          this.setState({
            isUpdateButtonDisabled: !await containsUIAuthType(LEARNING_PROJECTS_DETAILS_UPDATE_BUTTON),
            isLearningPJDetailsAnnotationStartDisabled: !await containsUIAuthType(LEARNING_PROJECTS_DETAILS_ANNOTATION_START),
            isLearningPJDetailsRemoveFromLearningPJDisabled: !await containsUIAuthType(LEARNING_PROJECTS_DETAILS_REMOVED_FROM_LEARNING_PROJECT),
            isInfoSearchDisabled: !await containsUIAuthType(LEARNING_PROJECTS_DETAILS_DETAILED_INFORMATION_SEARCH),
          });
          break;
        }
        case LEARNING: {
          const matrixLearning = AUTHENTICATION_TYPE_MATRIX.MACHINE_LEARNING;

          this.setState({
            isUpdateButtonDisabled: !await containsUIAuthType(matrixLearning.LEARNING_PROJECTS_DETAILS_UPDATE_BUTTON),
            isLearningPJDetailsParameterSettingsDisabled: !await containsUIAuthType(matrixLearning.LEARNING_PROJECTS_DETAILS_PARAMETER_SETTINGS),
            isInfoSearchDisabled: !await containsUIAuthType(matrixLearning.LEARNING_PROJECTS_DETAILS_DETAILED_INFORMATION_SEARCH),
          });
          break;
        }
        case DETECT: {
          const {
            EQUIPMENT_LIST_AI_PARAMETER_SETTINGS,
            EQUIPMENT_LIST_EQUIPMENT_UPDATE,
            EQUIPMENT_LIST_DELETE_BUTTON,
            EQUIPMENT_LIST_DETAILED_INFORMATION_SEARCH,
          } = AUTHENTICATION_TYPE_MATRIX.AI_DETECT;

          this.setState({
            isEquipmentListAiParameterSettingsDisabled: !await containsUIAuthType(EQUIPMENT_LIST_AI_PARAMETER_SETTINGS),
            isUpdateButtonDisabled: !await containsUIAuthType(EQUIPMENT_LIST_EQUIPMENT_UPDATE),
            isEquipmentListDeleteButtonDisabled: !await containsUIAuthType(EQUIPMENT_LIST_DELETE_BUTTON),
            isInfoSearchDisabled: !await containsUIAuthType(EQUIPMENT_LIST_DETAILED_INFORMATION_SEARCH),
          });
          break;
        }
        case DETECT_RESULT: {
          const {
            DETECTION_RESULT_DETAILS_DETAILED_INFORMATION_SEARCH,
            DETECTION_RESULTS_LIST_DETECT_RESULT_UPDATE,
          } = AUTHENTICATION_TYPE_MATRIX.AI_DETECT_RESULT;

          this.setState({
            isInfoSearchDisabled: !await containsUIAuthType(DETECTION_RESULT_DETAILS_DETAILED_INFORMATION_SEARCH),
            isUpdateButtonDisabled: !await containsUIAuthType(DETECTION_RESULTS_LIST_DETECT_RESULT_UPDATE),
          });
          break;
        }
        default:
      }

      const thumbnailMaxConcurrent = 1; // 並列実行するサムネイル取得タスク数
      this.thumbnailQueue = new Queue(thumbnailMaxConcurrent);
      await this.generateCocoJson(id, name);
      await this.generatePanelList(id);
      await this.generateSelectBoxForLearningProject();
      if (this.props.onChangeFileType) {
        this.props.onChangeFileType(0, undefined);
      }

      this.setState({
        imageLoad: false,
      });

      const fileListArea = document.getElementById('fileListArea');
      if (fileListArea) {
        fileListArea.addEventListener('dragover', (event) => {
          const { isFileTabAddButtonDisabled, selectedDisplayTarget } = this.state;
          if (isFileTabAddButtonDisabled || (selectedDisplayTarget === DETECTION_RESULT)) {
            // eslint-disable-next-line no-param-reassign
            event.dataTransfer.dropEffect = 'none';
            const tooltip = document.getElementById('tooltip');

            let xPos = 0;
            if (event.pageX < window.innerWidth / 2) { // マウスカーソル位置が画面の左半分側かどうか
              // 左半分にいたらカーソル位置の右側にツールチップを表示
              xPos = event.pageX + 10;
            } else {
              // 右側にいたらカーソルの右側にツールチップを表示
              xPos = event.pageX - tooltip.getBoundingClientRect().width - 10;
            }

            let yPos = 0;
            if (event.pageY < window.innerHeight / 2) { // マウスカーソル位置が画面の上半分かどうか
              // 上半分にいたらカーソル位置の下にツールチップを表示
              yPos = event.pageY + 10;
            } else {
              // 下半分にいたらカーソル位置の上にツールチップを表示
              yPos = event.pageY - tooltip.getBoundingClientRect().height - 10;
            }

            tooltip.style.visibility = 'visible';
            tooltip.style.top = `${yPos}px`;
            tooltip.style.left = `${xPos}px`;
            tooltip.innerHTML = selectedDisplayTarget === DETECTION_RESULT
              ? WARNING_DRAG_AND_DROP_FILE
              : ERROR_NO_AUTH_MESSAGE;
          }
        });

        fileListArea.addEventListener('dragleave', () => {
          const { isFileTabAddButtonDisabled, selectedDisplayTarget } = this.state;
          if (isFileTabAddButtonDisabled || (selectedDisplayTarget === DETECTION_RESULT)) {
            const tooltip = document.getElementById('tooltip');
            tooltip.style.visibility = 'hidden';
          }
        });
      }
    }
  }

  /**
 * props 更新時の処理
 * @param {Array} prevProps 前回表示時のプロパティ
 */
  async componentDidUpdate(prevProps) {
    const id = this.props.assetId;
    const name = this.props.assetName;
    if (prevProps.assetId !== id) {
      this.setState({ imageLoad: true, checkFileList: [] });
      this.allUncheck();
      await this.generateCocoJson(id, name);
      if (this.state.selectedDisplayTarget === CAPTURED_IMAGE) {
        await this.generatePanelList(id);
      } else if (this.state.selectedDisplayTarget === DETECTION_RESULT) {
        await this.generateListOptions(id);
      }
      await this.generateSelectBoxForLearningProject();
      this.setState({ imageLoad: false });
    }
  }

  /**
   * 表示画像の種類変更時のイベントハンドラ
   * @param {number} value 選択された表示画像の種類
   */
  onChangeDisplayTarget = (value) => {
    this.setState({
      selectedDisplayTarget: value,
    });
    const id = this.props.assetId;
    if (value === CAPTURED_IMAGE) {
      this.generatePanelList(id);
      if (this.props.onChangeFileType) {
        this.props.onChangeFileType(CAPTURED_IMAGE, undefined);
      }
      this.setState({ selectedDisplayTargetDisabled: false });
    } else if (value === DETECTION_RESULT) {
      this.generateListOptions(id);
      this.setState({ selectedDisplayTargetDisabled: true });
    }

    this.setState({ checkFileList: [] });
    this.allUncheck();

    const { onChangeFileType } = this.props;
    if (onChangeFileType) {
      onChangeFileType(value);
    }
  };

  /**
   * 検出結果リスト変更時のイベントハンドラ
   * @param {number} value 選択された検出結果リストのAssetID
   */
  onChangeDetectionResultList = async (value) => {
    this.setState({
      imageLoad: true,
      selectedList: value,
    });

    await this.createResultImageList(value);

    const { detectResultAssetId } = this.state;
    this.props.onChangeFileType(DETECTION_RESULT, detectResultAssetId);

    this.setState({
      imageLoad: false,
      checkFileList: [],
    });
    this.allUncheck();
  };

  /**
   * 画像を追加するプロジェクト変更時のイベントハンドラ
   * @param {number} pjAssetId 選択されたプロジェクトのアセットID
   */
  onChangeAddToProject = (pjAssetId) => {
    this.setState({
      selectedProject: pjAssetId,
    });
  };

  /**
   * 「学習プロジェクトに追加」ボタンクリック時のイベントハンドラ
   */
  onClickAddToProject = async () => {
    if (this.state.checkFileList.length === 0) {
      message.error(VALIDATE_ERROR_NO_SELECT_FILE_ADD_IMAGE_TO_PJ);
      return;
    }

    if (this.state.selectedProject === undefined) {
      message.error(VALIDATE_ERROR_NO_SELECT_PJ_ADD_IMAGE_TO_PJ);
      return;
    }

    this.setState({
      addToProjectButtonStatus: true,
    });

    const { resourceType } = this.props;
    const notJpegFileIds = [];

    await Promise.all(
      this.state.checkFileList.map(async (fileId) => {
        const checkFile = await retrieveFile(resourceType, fileId);
        if (checkFile.mimeType !== Jpeg) {
          notJpegFileIds.push(fileId);
          return;
        }

        const changes = {
          id: fileId,
          update: {
            assetIds: { add: [this.state.selectedProject] },
          },
        };
        await addImageFileToLearningProject(changes);
      }),
    );

    this.setState({
      addToProjectButtonStatus: false,
    });

    if (this.state.checkFileList.length === notJpegFileIds.length) {
      // 学習プロジェクトのJPEGファイル登録失敗メッセージ
      message.error(FAILED_ADD_IMAGE_TO_PJ);
    } else if (notJpegFileIds.length === 0) {
      // 学習プロジェクトのJPEGファイル登録成功メッセージ
      message.success(SUCCESS_ADD_IMAGE_TO_PJ);
    } else {
      // 学習プロジェクトのJPEGファイル登録警告メッセージ
      message.warn(WARNING_ADD_IMAGE_TO_PJ);
    }
  };

  /**
   * 削除ボタンクリック時のイベントハンドラ
   */
  onClickDeleteImage = async () => {
    /**
     * 存在チェックのメッセージ取得
     * @param {Array} projectsList fileに紐づくprojectList
     */
    const getProjectExistsMeg = (projectsList) => {
      const messages = [];
      projectsList.forEach((val) => {
        if (val.projects.length > 0) {
          messages.push(
            `${val.name}${VALIDATE_ERROR_EXIST_IN_LEARNING_PJ_AFTER_FILE}`
            + `${val.projects}${VALIDATE_ERROR_EXIST_IN_LEARNING_PJ_AFTER_PJ}`,
          );
        }
      });
      return messages;
    };

    this.setState({ imageLoad: true, deletingImage: true });

    if (this.state.checkFileList.length === 0) {
      message.error(VALIDATE_ERROR_UNSELECTED_IMAGE, POPUP_ERROR_MESSAGE_DISPLAY_TIME);
    } else {
      const projectsList = await getProjectList(this.state.checkFileList);
      const messages = getProjectExistsMeg(projectsList);
      if (messages.length > 0) {
        const errorMessage = (
          <>
            <span key="ErrorMsg">{VALIDATE_ERROR_EXIST_IN_LEARNING_PJ}</span>
            <ul key="NG_List">
              {messages.map((msg) => (
                <li key={msg} style={{ textAlign: 'left', marginLeft: '20px' }}>
                  {msg}
                </li>
              ))}
            </ul>
          </>
        );
        message.error(errorMessage, POPUP_ERROR_MESSAGE_DISPLAY_TIME);
      } else {
        const { resourceType } = this.props;
        await Promise.all(
          this.state.checkFileList.map(async (fileId) => {
            await deleteFile(resourceType, fileId);
          }),
        );
        this.setState({ checkFileList: [] });
        this.allUncheck();
        message.success(IMAGE_DELETION_COMPLETED_SUCCESSFULLY);
      }
    }

    this.setState({ imageLoad: false, deletingImage: false });
  };

  /**
   * AI検出パラメータ設定ボタンクリック時のイベントハンドラ
   */
  onClickDetect = () => {
    this.props.onClickSettingDetectParameter(this.state.checkFileList);
  };

  /**
   * check box変更時のイベントハンドラ
   * @param {array} fileIds ファイルIDリスト
   */
  onChangeCheckbox = (fileIds) => {
    this.setState({ checkFileList: fileIds });
  };

  /**
   * パラメータ設定ボタンクリックイベントハンドラ
   */
  onClickSettingLearningParameter = () => {
    this.props.onClickSettingLearningParameter(this.state.checkFileList);
  };

  /**
   * Assetが持つ全画像ファイル情報の取得
   * @param {string} endpoint エンドポイント
   * @param {object} fileFilter cogniteSDK files.listのfilter
   * @returns {Promise<object[]>} All FIles
   */
  async getAllImageFiles(endpoint, fileFilter) {
    /**
   * ファイル名、拡張子の生成
   * ファイル情報の名称からファイル名、拡張子を生成する。
   * @param {object} baseFile ファイル情報
   * @returns { string, string } ファイル名、拡張子
   */
    function createFileNameWithExtension(baseFile) {
      if (baseFile.name.startsWith('.')) {
        // 「.」始まりのファイルはすべてファイル名として扱う
        return { fileName: baseFile.name, fileExtension: NO_DATA_STRING };
      }

      const lastDotIndex = baseFile.name.lastIndexOf('.');
      if (lastDotIndex < 0) {
        // 「.」が存在しない場合
        return { fileName: baseFile.name, fileExtension: NO_DATA_STRING };
      }

      const fileName = baseFile.name.slice(0, lastDotIndex);
      // 拡張子には「.」を含まず大文字に統一
      const fileExtension = baseFile.name.slice(lastDotIndex + 1).toUpperCase();
      return { fileName, fileExtension };
    }

    const files = await getAllFiles(endpoint, fileFilter);
    const maxConcurrent = 14; // 並列実行するタスク数
    const queue = new Queue(maxConcurrent);
    const promises = [];
    const allFiles = [];

    files.forEach((f) => {
      promises.push(queue.add(async () => {
        const { fileName, fileExtension } = createFileNameWithExtension(f);
        const pushData = {
          id: f.id,
          name: f.name,
          mimeType: f.mimeType,
          fileName,
          fileExtension,
          annotated: String(this.isAnnotated(f.id)),
          metadata: f.metadata,
        };

        allFiles.push(pushData);
      }));
    });

    await Promise.all(promises);

    sortName(allFiles);
    return allFiles;
  }

  /**
   * サムネイル情報の取得
   * @param {object[]} files ファイル情報
   */
  async getThumbnailInfoList(files) {
    this.setState({ thumbnailInfoList: [] });

    const { resourceType } = this.props;
    const promises = [];
    const thumbnailInfoList = [];
    this.thumbnailQueue.queue = [];

    files.forEach((file) => {
      const { mimeType, id } = file;
      promises.push(this.thumbnailQueue.add(async () => {
        // アイコン画像
        let element = <NoImageThumbnail className="file-table-thumbnail" />;
        if (ImageFile.validateThumbnailDisplay(mimeType)) {
          const newFile = UnknownFile.getInstance();
          newFile.id = id;
          try {
            const createUrl = await newFile.loadThumbnailURLFromCDF(resourceType);
            element = <img alt="サムネイル" src={createUrl} onError={(e) => { e.currentTarget.src = '/icon/NoImage.png'; }} className="file-table-thumbnail" />;
          } catch (e) {
            message.error(ERROR_FILES_GET_DL_URL);
          }
        }
        thumbnailInfoList.push({ id, element });
        this.setState({ thumbnailInfoList });
      }));
    });

    await Promise.all(promises);
  }

  /**
   * アノテーション済み判定
   * @param {number} id 対象のFileID
   * @returns {boolean} true: annotated / false: not annotated
   */
  isAnnotated = (id) => {
    if (!this.cocojson) return false;

    const imageId = this.getImageId(id);
    return this.getAnnotated(imageId);
  };

  /**
   * ImageIDの取得
   * @param {number} id 対象のFileID
   * @returns {number} ImageID
   */
  getImageId = (id) => {
    const result = this.cocojson.images.find((img) => img.file_name === String(id));
    return result ? result.id : undefined;
  };

  /**
   * アノテーション済み判定
   * @param {number} imageId 対象のimageId
   * @returns {boolean} true: annotated / false: not annotated
   */
  getAnnotated = (imageId) => {
    const result = this.cocojson.annotations.find((annotation) => annotation.image_id === imageId);
    return !!result;
  };

  /**
   * reloadボタンのイベントハンドラ
   */
  onClickReload = async () => {
    const id = this.props.assetId;
    const name = this.props.assetName;

    await this.generateCocoJson(id, name);
    if (this.state.selectedDisplayTarget === CAPTURED_IMAGE) {
      this.generatePanelList(id);
    } else if (this.state.selectedDisplayTarget === DETECTION_RESULT) {
      this.onChangeDetectionResultList(this.state.selectedList);
    }
  };

  /**
   * 全てのチェックボックスのチェックを外す
   */
  allUncheck = () => {
    for (let i = 0; i < this.checkBoxRefs.length; i++) {
      if (this.checkBoxRefs[i]) this.checkBoxRefs[i].checked = false;
    }
    if (this.allCheck.current) {
      this.allCheck.current.checked = false;
    }
  };

  /**
   * 学習プロジェクトから画像削除ボタンクリック時のイベントハンドラ
   */
  onClickDeleteFromPj = () => {
    if (this.state.checkFileList.length === 0) {
      message.error(VALIDATE_ERROR_UNSELECTED_IMAGE);
      return;
    }
    this.setState({ deleteConfirmModalVisible: true });
  };

  /**
   * 学習プロジェクトから画像削除の確認ダイアログの「削除」ボタン押下イベント
   */
  onOkDeleteImageFromPj = async () => {
    this.setState({ deleteConfirmLoading: true });
    await this.props.deleteImageFromPj(this.state.checkFileList);
    this.setState({ deleteConfirmLoading: false, deleteConfirmModalVisible: false });
  };

  /**
   * 学習プロジェクトから画像削除の確認ダイアログの「キャンセル」ボタン押下イベント
   */
  onCancelDeleteImageFromPj = () => this.setState({ deleteConfirmModalVisible: false });

  /**
   * 「詳細情報検索」ボタン押下イベントハンドラ
   * @param {React.MouseEvent} event マウスイベント
   */
  handleClickSearchMetadata = (event) => {
    event.currentTarget.blur(); // フォーカスを外す
    this.setState({ searchMetadataModalVisible: true });
  };

  /**
   * 詳細情報検索ダイアログの「保存」ボタン押下イベントハンドラ
   * @param {SearchMetadataInputValues} searchMetadataValues 詳細情報検索ダイアログ選択項目
   */
  handleSaveSearchMetadata = (searchMetadataValues) => {
    this.setState({
      metadataFilter: searchMetadataValues.metadata,
      searchMetadataModalVisible: false,
    });
  };

  /**
   * 詳細情報検索ダイアログのマスクエリア押下イベントハンドラ
   */
  handleClickMask = () => {
    this.setState({ searchMetadataModalVisible: false });
  };

  /**
   * 「写真比較」ボタン押下イベントハンドラ
   */
  handleClickCompareImage = () => {
    this.setState({ imageCompareViewVisible: true });
  };

  /**
   * 「写真比較」モーダルクローズ時のイベントハンドラ
   */
  handleCloseCompareImage = () => {
    this.setState({ imageCompareViewVisible: false });
  };

  /** 詳細情報検索ボタン */
  SearchMetadata = () => {
    const {
      metadataFilter,
      isInfoSearchDisabled,
    } = this.state;

    return (
      <span style={{ marginLeft: '5px', marginRight: '2px' }}>
        <Tooltip title={isInfoSearchDisabled && ERROR_NO_AUTH_MESSAGE}>
          {
            Object.keys(metadataFilter).length === 0
              ? <Button icon="search" disabled={isInfoSearchDisabled} onClick={this.handleClickSearchMetadata}>詳細情報検索</Button>
              : <Button icon="search" disabled={isInfoSearchDisabled} onClick={this.handleClickSearchMetadata} type="primary" ghost>詳細情報検索</Button>
          }
        </Tooltip>
      </span>
    );
  };

  /** 写真比較ボタン */
  CompareImage = () => {
    const {
      disableCompareButton,
      isFileTabPhotoComparisonDisabled,
    } = this.state;
    return (
      <span style={{ marginLeft: '5px', marginRight: '5px' }}>
        <Tooltip title={isFileTabPhotoComparisonDisabled && ERROR_NO_AUTH_MESSAGE}>
          <Button
            disabled={disableCompareButton || isFileTabPhotoComparisonDisabled}
            type="primary"
            style={{ fontWeight: 'bold' }}
            onClick={this.handleClickCompareImage}
          >
            写真比較
          </Button>
        </Tooltip>
      </span>
    );
  };

  /** ドラッグアンドドロップ時ファイルリストセット処理
   * @param {Array} fileList ドラッグアンドドロップされたファイルリスト
   */
  filesDragAndDrop = (fileList) => {
    if (this.props.fileNameStatus === DIGITAL_TWIN_APP && this.state.selectedDisplayTarget === CAPTURED_IMAGE) {
      // 設備詳細画面、アップロード・ファイルタブ選択時の場合
      this.setState({ updateFiles: fileList });
    } else {
      // 上記条件以外の場合
      message.warning(WARNING_DRAG_AND_DROP_FILE);
    }
  };

  /**
 * 検出結果リストセレクトボックスの生成
 * @param {number} assetId 画像リスト取得するAssetID
 */
  generateListOptions = async (assetId) => {
    const resultAssetId = await getResultAssetId();
    const assetFilter = { parentIds: [resultAssetId], metadata: { baseAsset: assetId } };
    const assets = await getAllAssets(EP_PATH_ROOT_ASSET_AI_DETECT_RESULT, assetFilter);
    sortOrder(assets);
    const options = assets.map((item) => <Option key={item.id} value={item.id} aria-label={item.name}>{item.name}</Option>);
    const defaultValue = assets[0] ? assets[0].id : '';

    if (this.listSelectRef.current) {
      this.listSelectRef.current.rcSelect.state.value = [defaultValue];
    }
    if (defaultValue) {
      this.onChangeDetectionResultList(defaultValue);
    } else {
      this.setState({
        files: [],
        detectResultAssetId: undefined,
        disableCompareButton: true,
      });
      this.props.onClickImage(null);
    }
    this.setState({
      listOptions: options,
      selectedList: defaultValue,
    });
  };

  /**
 * Result画像リストを生成
 * @param {string} assetId アセットのID
 */
  createResultImageList = async (assetId) => {
    if (!assetId) {
      return;
    }

    const fileFilter = { assetIds: [assetId], mimeType: Jpeg, metadata: { detectFlag: 'true' } };
    const allFiles = await this.getAllImageFiles(EP_PATH_AI_DETECT_RESULT_FILES_LIST, fileFilter);

    if (allFiles.length > 0) {
      this.props.onClickImage(allFiles[0].id);
    } else {
      this.props.onClickImage(null);
    }

    this.setState({
      files: allFiles,
      detectResultAssetId: assetId,
      imageLoad: false,
      disableCompareButton: allFiles.length < 2,
    });

    await this.getThumbnailInfoList(allFiles);
  };

  /**
 * Assetが持つ画像リストの生成処理
 * @param {number} assetId 対象のAssetID
 */
  generatePanelList = async (assetId) => {
    let fileFilter;
    if (this.props.listType === LIST_TYPE.JPEG_ONLY) {
      fileFilter = { assetIds: [assetId], mimeType: Jpeg, uploaded: true };
    } else {
      fileFilter = { assetIds: [assetId], uploaded: true };
    }

    const { resourceType } = this.props;
    const endpoint = getFileListEndpoint(resourceType);

    this.allFiles = await this.getAllImageFiles(endpoint, fileFilter);
    if (this.allFiles.length > 0) {
      this.props.onClickImage(this.allFiles[0].id);
    } else {
      this.props.onClickImage(null);
    }

    // 写真比較に使用できるファイル数
    const countPicture = this.allFiles.filter(
      (file) => file.mimeType === Jpeg || file.mimeType === Png,
    );

    this.setState({
      files: this.allFiles,
      disableCompareButton: countPicture.length < 2,
      imageLoad: false,
    });

    await this.getThumbnailInfoList(this.allFiles);
  };

  /*
    外部公開関数
    別ファイルから呼び出されるためESLintの警告対象外とする
   */

  /**
   * 前画像ファイルID取得
   * @param {number} fileId 現在選択中のFileId
   * @returns {number} 前画像のFileId
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  prevImageId = (fileId) => {
    const currentFileIndex = this.allFiles.findIndex((f) => f.id === fileId);
    const prevFile = currentFileIndex < 1 ? this.allFiles[0] : this.allFiles[currentFileIndex - 1];
    return prevFile.id;
  };

  /**
   * 次画像ファイルID取得
   * @param {number} fileId 現在選択中のFileId
   * @returns {number} 次画像のFileId
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  nextImageId = (fileId) => {
    const currentFileIndex = this.allFiles.findIndex((f) => f.id === fileId);
    const nextFile = currentFileIndex < this.allFiles.length - 1 ? this.allFiles[currentFileIndex + 1] : this.allFiles[currentFileIndex];
    return nextFile.id;
  };

  /**
 * 学習プロジェクトのSelectBox生成処理
 */
  async generateSelectBoxForLearningProject() {
    const dlAssetId = await getDlAssetId();
    const assetsFilter = { parentIds: [dlAssetId] };
    const assets = await getAllAssets(EP_PATH_LEARNING_PJ_LIST, assetsFilter);
    sortOrder(assets);
    const optionDataList = [];
    assets.forEach((asset) => {
      optionDataList.push(<Option key={asset.name} value={asset.id}>{asset.name}</Option>);
    });
    this.setState({
      selectBoxOptions: optionDataList,
      selectedProject: undefined,
    });
  }

  /**
 * Cocojsonの生成
 * @param {number} id 対象のAssetID
 * @param {string} name 対象のAsset名
 */
  async generateCocoJson(id, name) {
    let cocojson;

    if (name) {
      const externalId = getCocoJsonExternalId(id, name);
      cocojson = await getCocoJsonFile(externalId);
    }
    this.cocojson = cocojson;
  }

  /**
   * 画像一覧部共通componentのレンダリング処理
   */
  render() {
    const {
      onClickImage,
      onClickAnnotation,
      customStyle,
      showSettingLearningParameterButton,
      showImageDisplaySelection,
      showDetectColumns,
      showAnnotationColumns,
      inActiveCheckbox,
      isModalEditButtonDisabled,
      isModalDownloadButtonDisabled,
      showSearchMetadata,
      assetId,
      resourceType,
      fileNameStatus,
    } = this.props;
    const {
      checkFileList,
      selectedDisplayTarget,
      searchMetadataModalVisible,
      imageCompareViewVisible,
      metadataFilter,
      files,
      detectResultAssetId,
      isFileTabAddButtonDisabled,
      isFileTabDeleteButtonDisabled,
      isFileTabLearningProjectSelectionDisabled,
      isFileTabAddLearningProjectDisabled,
      isLearningPJDetailsAnnotationStartDisabled,
      isLearningPJDetailsRemoveFromLearningPJDisabled,
      isLearningPJDetailsParameterSettingsDisabled,
      isEquipmentListAiParameterSettingsDisabled,
      isEquipmentListDeleteButtonDisabled,
      isUpdateButtonDisabled,
      isFileLinkDisabled,
      thumbnailInfoList,
    } = this.state;

    const cardStyle = (customStyle && customStyle.cardStyle)
      ? customStyle.cardStyle
      : { height: '410px', margin: '0px' };
    const fileListHeight = (customStyle && customStyle.fileListHeight)
      ? customStyle.fileListHeight
      : '20vh';
    const fileTableHeight = (customStyle && customStyle.fileTableHeight)
      ? customStyle.fileTableHeight
      : '20vh';
    const targetAssetId = selectedDisplayTarget === CAPTURED_IMAGE ? assetId : detectResultAssetId;

    return (
      <Card style={cardStyle}>
        <Row style={{ margin: '10px', padding: '8px' }}>
          <Col span={16}>
            {
              this.props.showImageDisplaySelection ? (
                <Select
                  defaultValue={this.state.selectedDisplayTarget}
                  onChange={this.onChangeDisplayTarget}
                  disabled={false}
                  style={{ width: '200px', margin: '0px', color: '#000000D9' }}
                >
                  <Option value={0} aria-label="capturedImage">アップロード・ファイル</Option>
                  <Option value={1} aria-label="detectionResult">AI検出結果</Option>
                </Select>
              ) : null
            }
            {
              this.state.selectedDisplayTarget === DETECTION_RESULT && (
                <Select
                  ref={this.listSelectRef}
                  defaultValue={this.state.selectedList}
                  onChange={this.onChangeDetectionResultList}
                  style={{ width: '200px' }}
                >
                  {this.state.listOptions}
                </Select>
              )
            }
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Tooltip title={isUpdateButtonDisabled ? ERROR_NO_AUTH_MESSAGE : 'reload'}>
              <Button
                type="primary"
                shape="circle"
                onClick={this.onClickReload}
                style={{ backgroundColor: 'green', border: 'green' }}
                disabled={isUpdateButtonDisabled}
              >
                <RedoOutlined />
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <div id="fileListArea" style={{ height: fileTableHeight }}>
            <Upload
              name="files"
              listType="text"
              multiple
              showUploadList={false}
              beforeUpload={(_, fileList) => {
                this.filesDragAndDrop(fileList);
                return false;
              }}
              openFileDialogOnClick={false}
            >
              <span id="tooltip" />
              <FileTable
                client={this.props.client.client}
                imageLoad={this.state.imageLoad}
                files={this.state.files}
                onClickImage={onClickImage}
                handleRowChange={this.onChangeCheckbox}
                checkFileList={checkFileList}
                showDetectColumns={showDetectColumns || (showImageDisplaySelection && selectedDisplayTarget === DETECTION_RESULT)}
                height={fileListHeight}
                showAnnotationColumns={showAnnotationColumns}
                needsDisabledCheckbox={inActiveCheckbox}
                isModalEditButtonDisabled={isModalEditButtonDisabled}
                isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                metadataFilter={metadataFilter}
                facilityAssetId={assetId}
                detectResultAssetId={detectResultAssetId}
                displayTarget={selectedDisplayTarget}
                fileNameStatus={fileNameStatus}
                resourceType={resourceType}
                isFileLinkDisabled={isFileLinkDisabled}
                thumbnailInfoList={thumbnailInfoList}
              />
            </Upload>
          </div>
        </Row>
        <Row style={{ textAlign: 'left', marginTop: '4px', marginBottom: '4px' }}>
          {
            (this.props.showAddButton || this.props.showDelButton) && (
              <Col span={12}>
                {
                  this.props.showAddButton
                  && (
                    <span style={{ marginLeft: '2px', marginRight: '2px' }}>
                      <FileUploadAddButton
                        assetId={this.props.assetId}
                        disabled={this.state.selectedDisplayTargetDisabled || isFileTabAddButtonDisabled}
                        authentication={isFileTabAddButtonDisabled}
                        resourceType={resourceType}
                      />
                    </span>
                  )
                }
                {
                  this.props.showDelButton
                  && (
                    <span style={{ marginLeft: '2px', marginRight: '2px' }}>
                      <Tooltip title={isFileTabDeleteButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                        <Button
                          type="danger"
                          onClick={this.onClickDeleteImage}
                          style={{ width: '80px', fontWeight: 'bold' }}
                          loading={this.state.deletingImage}
                          disabled={this.state.selectedDisplayTargetDisabled || isFileTabDeleteButtonDisabled}
                        >
                          削除
                        </Button>
                      </Tooltip>
                    </span>
                  )
                }
                {this.SearchMetadata()}
                {this.CompareImage()}
              </Col>
            )
          }
          {
            this.props.showAnnotation && this.props.showDelFromPjButton
            && (
              <Col span={24}>
                {
                  this.props.showAnnotation
                  && (
                    <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                      <Tooltip title={isLearningPJDetailsAnnotationStartDisabled && ERROR_NO_AUTH_MESSAGE}>
                        <Button
                          type="primary"
                          onClick={onClickAnnotation}
                          style={{ width: '160px', fontWeight: 'bold' }}
                          disabled={isLearningPJDetailsAnnotationStartDisabled}
                        >
                          アノテーション開始
                        </Button>
                      </Tooltip>
                    </span>
                  )
                }
                {
                  this.props.showDelFromPjButton
                  && (
                    <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                      <Tooltip title={isLearningPJDetailsRemoveFromLearningPJDisabled && ERROR_NO_AUTH_MESSAGE}>
                        <Button
                          type="primary"
                          onClick={this.onClickDeleteFromPj}
                          style={{
                            width: '200px', backgroundColor: 'red', border: 'red', fontWeight: 'bold',
                          }}
                          disabled={isLearningPJDetailsRemoveFromLearningPJDisabled}
                        >
                          学習プロジェクトから削除
                        </Button>
                      </Tooltip>
                    </span>
                  )
                }
                {this.SearchMetadata()}
              </Col>
            )
          }
          {
            this.props.showAddPjButton
            && (
              <Col span={12} style={{ textAlign: 'right' }}>
                <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                  <Tooltip title={isFileTabLearningProjectSelectionDisabled && ERROR_NO_AUTH_MESSAGE}>
                    <Select
                      defaultValue={undefined}
                      onChange={this.onChangeAddToProject}
                      placeholder="学習プロジェクト選択"
                      style={{ width: '200px', margin: '0px', color: '#000000D9' }}
                      aria-label="select-learning-pj"
                      disabled={this.state.selectedDisplayTargetDisabled || isFileTabLearningProjectSelectionDisabled}
                    >
                      {this.state.selectBoxOptions}
                    </Select>
                  </Tooltip>
                  <Tooltip title={isFileTabAddLearningProjectDisabled && ERROR_NO_AUTH_MESSAGE}>
                    <Button
                      type="primary"
                      loading={this.state.addToProjectButtonStatus}
                      onClick={this.onClickAddToProject}
                      style={{ width: '200px', margin: '0px' }}
                      disabled={this.state.selectedDisplayTargetDisabled || isFileTabAddLearningProjectDisabled}
                    >
                      学習プロジェクトに追加
                    </Button>
                  </Tooltip>
                </span>
              </Col>
            )
          }
          {
            showSettingLearningParameterButton
            && (
              <Col span={24}>
                <Tooltip title={isLearningPJDetailsParameterSettingsDisabled && ERROR_NO_AUTH_MESSAGE}>
                  <Button
                    type="primary"
                    onClick={this.onClickSettingLearningParameter}
                    style={{ fontWeight: 'bold' }}
                    disabled={isLearningPJDetailsParameterSettingsDisabled}
                  >
                    パラメータ設定
                  </Button>
                </Tooltip>
                {this.SearchMetadata()}
              </Col>
            )
          }
          {
            this.props.showDetectButton
            && (
              <Col span={24}>
                <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                  <Tooltip title={isEquipmentListAiParameterSettingsDisabled && ERROR_NO_AUTH_MESSAGE}>
                    <Button
                      type="primary"
                      onClick={this.onClickDetect}
                      style={{ fontWeight: 'bold' }}
                      disabled={isEquipmentListAiParameterSettingsDisabled}
                    >
                      AI検出パラメータ設定
                    </Button>
                  </Tooltip>
                </span>
                <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                  <Tooltip title={isEquipmentListDeleteButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                    <Button
                      type="primary"
                      onClick={this.onClickDeleteImage}
                      style={{ backgroundColor: 'red', border: 'red', fontWeight: 'bold' }}
                      loading={this.state.deletingImage}
                      disabled={isEquipmentListDeleteButtonDisabled}
                    >
                      削除
                    </Button>
                  </Tooltip>
                </span>
                {this.SearchMetadata()}
              </Col>
            )
          }
          {showSearchMetadata && this.SearchMetadata()}
        </Row>
        <Modal
          title={WARNING_DELETE_IMAGE_FROM_PJ_TITLE}
          visible={this.state.deleteConfirmModalVisible}
          confirmLoading={this.state.deleteConfirmLoading}
          onOk={this.onOkDeleteImageFromPj}
          okText={MODAL_BUTTON_TEXT.DELETE}
          okType="danger"
          onCancel={this.onCancelDeleteImageFromPj}
          cancelText={MODAL_BUTTON_TEXT.CANCEL}
          centered
        >
          {WARNING_DELETE_IMAGE_FROM_PJ_MESSAGE}
        </Modal>
        <SearchMetadataModal
          visible={searchMetadataModalVisible}
          targetFiles={files}
          onSave={this.handleSaveSearchMetadata}
          onClickMask={this.handleClickMask}
        />
        <ImageCompareView
          visible={imageCompareViewVisible}
          client={this.props.client.client}
          assetId={targetAssetId}
          resourceType={resourceType}
          fileIdList={checkFileList}
          onClose={this.handleCloseCompareImage}
        />
        <FileUpload
          assetId={assetId}
          files={this.state.updateFiles}
          resourceType={resourceType}
        />
      </Card>
    );
  }
}
