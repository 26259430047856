import {
  ExternalDatapointExternalId,
  ItemsWrapper,
} from '@cognite/sdk';
import * as XLSX from 'xlsx';
import Decimal from 'decimal.js';

import { upsertActualFinanceEvents } from './upsertActualFinanceEvents';
import { fileFormatCheck } from './fileFormatChecker';
import SolarSite from '../../../../utils/Asset/SolarSite';
import { EmptyResponse, postApiGateway, putApiGateway } from '../../../../utils/AWS/ApiGateway';
import {
  EP_PATH_SOLAR_TIMESERIES_DATAPOINTS,
  EP_PATH_SOLAR_PROCESSES_ACTUAL_FINANCE,
} from '../../../../utils/AWS/EndpointPath';
import {
  ACTUAL_FINANCE_DAILY_TASK,
  createFinanceDailyTask,
  getParentAssets,
} from '../../DailyTaskEvent';

type FinancialFigureMapItem = {
  ref?: string,
  jpName: string,
  externalId?: string,
}

type ActualData = {
  actualData: [string, number][] | []
};

export type MapItemWithData = FinancialFigureMapItem & ActualData;

export const MILLION = 1000000;

const financialFigureMap: FinancialFigureMapItem[] = [
  { ref: 'B6:M6', jpName: '売電収入額_実績', externalId: 'actual_income' },
  { ref: 'B7:M7', jpName: '売上原価_実績', externalId: 'actual_expenses' },
  { ref: 'B8:M8', jpName: '販管費_実績', externalId: 'actual_expenses' },
  { ref: 'B9:M9', jpName: '営業利益_実績', externalId: 'actual_operating_income' },
  { ref: 'B10:M10', jpName: '営業外収益_実績' },
  { ref: 'B11:M11', jpName: '営業外費用_実績' },
  { ref: 'B12:M12', jpName: '経常利益_実績' },
  { ref: 'B13:M13', jpName: '当期純利益_実績' },
  { ref: 'B14:M14', jpName: 'EBITDA_実績', externalId: 'actual_ebitda' },
  { ref: 'B16:M16', jpName: '減価償却費_実績' },
  { ref: 'B17:M17', jpName: '営業権償却費_実績' },
  { ref: 'B18:M18', jpName: '修繕費_実績' },
  { ref: 'B19:M19', jpName: '受取利息_実績' },
  { ref: 'B20:M20', jpName: '支払利息_実績' },
  { ref: 'B21:M21', jpName: '事業税_償却資産税_実績' },
  { ref: 'B22:M22', jpName: '法人税等_実績' },
];

/**
 * 財務実績値の基本情報（事業者名、発電所名、登録年度）項目のバリデーション
 * 事業者名および発電所名は必須チェックのみ、登録年度は後続処理に影響があるため型および数値チェックも実施
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns {boolean} 基本情報項目のバリデーション結果
 */
const checkInfoValidity = (sheet: XLSX.Sheet): boolean => {
  const businessName = sheet.A4;
  const siteName = sheet.B4;
  const fiscalYear = sheet.C4;
  // データポイントのタイムスタンプ出力時に影響があるので、登録年度の値が1970以上の整数であるかも確認
  const isFiscalYearValid = fiscalYear && Number.isInteger(fiscalYear.v) && fiscalYear.v >= 1970;
  return !!(businessName && siteName && isFiscalYearValid);
};

/**
 * 財務実績値登録シートの登録データ項目のバリデーション
 * 値が記入されている月のデータ（セルB6〜M22）に対して型チェックを実施
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns {boolean} 登録データ項目のバリデーション結果
 */
const checkDataValidity = (sheet: XLSX.Sheet): boolean => {
  for (let rowNumber = 6; rowNumber <= 22; rowNumber++) {
    if (rowNumber !== 15) { // 個別項目ラベル行は対象外
      for (let charCode = 66; charCode <= 77; charCode++) {
        const cell = `${String.fromCharCode(charCode)}${rowNumber}`;
        // 対象セルに数値以外の値が入力されている場合はNG（未入力は許容）
        if (sheet[cell] && sheet[cell].t !== 'n') return false;
      }
    }
  }
  return true;
};

/**
 * 財務実績値登録データファイルのバリデーション
 * 「財務実績値登録シート」の存在、基本情報（事業者名、発電所名、登録年度）項目、
 * および登録データ項目のチェック結果が全てOKかを確認
 * @param {XLSX.WorkSheet} sheet 実績日射量登録データファイル
 * @returns {boolean} 登録データ項目のバリデーション結果
 */
const checkFileValidity = (sheet: XLSX.WorkSheet): boolean => {
  if (!sheet) return false;
  const isInfoValid = checkInfoValidity(sheet);
  const isDataValid = checkDataValidity(sheet);
  return isInfoValid && isDataValid;
};

/**
 * 登録年度の各月初日の日付を一覧で取得
 * 登録データの抽出とデータポイントへの変換時に使用
 * @param {number} year 登録年度
 * @returns {string[]} 登録年度の各月初日の日付
 */
const createHeaderDates = (year: number): string[] => {
  const headerDates = [];
  const firstDate = new Date(`${year}-04-01T00:00:00.000`);
  headerDates.push(firstDate.toDateString());

  let nextDate = new Date(firstDate);
  for (let i = 0; i < 11; i++) {
    const nextMonth = nextDate.getMonth() + 1;
    nextDate = new Date(nextDate.setMonth(nextMonth));
    headerDates.push(nextDate.toDateString());
  }
  return headerDates;
};

/**
 * データ記入用シートから各種登録データを抽出
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @param {string[]} headerDates 登録年度の各月初日の日付一覧
 * @returns {MapItemWithData[]} 項目名と当該項目の登録データを紐付けたマッピング情報
 */
const extractDataFromFile = (
  sheet: XLSX.Sheet,
  headerDates: string[],
): MapItemWithData[] => {
  const copySheet: XLSX.Sheet = JSON.parse(JSON.stringify(sheet));
  const mapWithData = financialFigureMap.map((figure: FinancialFigureMapItem) => {
    // 登録データ項目ごとにシートの読み込み範囲を指定し、データを抽出
    copySheet['!ref'] = figure.ref;
    const sheetJson = XLSX.utils.sheet_to_json<Record<string, number>>(
      copySheet, { header: headerDates },
    );
    const actualData = sheetJson.length ? Object.entries(sheetJson[0]) : [];
    // 登録データを追加したマッピング情報を返却
    return { ...figure, actualData } as MapItemWithData;
  });
  return mapWithData;
};

/**
 * 売上原価と販管費を合算し、営業費用としてマッピング情報に追加
 * @param {MapItemWithData[]} mapWithData 項目名と当該項目の登録データを紐付けたマッピング情報
 * @param {string[]} headerDates 登録年度の各月初日の日付一覧
 * @returns {MapItemWithData[]} 合算した営業費用を新たに追加したマッピング情報
 */
const addMergedActualExpenses = (
  mapWithData: MapItemWithData[],
  headerDates: string[],
): MapItemWithData[] => {
  // マッピング情報から営業費用の元となる売上原価と販管費を抽出
  const actualExpenseItems = mapWithData.filter((item: MapItemWithData) => (
    item.externalId === 'actual_expenses'
  ));
  const mergedData: [string, number][] = [];
  headerDates.forEach((date: string) => {
    // 各月の売上原価と販管費を取得
    const costOfSales = actualExpenseItems[0].actualData
      .find((item) => item[0] === date);
    const generalExpenses = actualExpenseItems[1].actualData
      .find((item) => item[0] === date);

    // 売上原価と販管費を合算（片方が未記入の場合はもう片方を合算値とし、両方未記入の場合は当該月の営業費用は算出しない（ゼロ埋め回避）
    let actualExpenses: number | undefined;
    if (costOfSales && generalExpenses) actualExpenses = costOfSales[1] + generalExpenses[1];
    else if (costOfSales && !generalExpenses) [, actualExpenses] = costOfSales;
    else if (!costOfSales && generalExpenses) [, actualExpenses] = generalExpenses;

    if (actualExpenses !== undefined) {
      const newItem: [string, number] = [date, actualExpenses];
      mergedData.push(newItem);
    }
  });
  const actualExpenses = {
    externalId: 'actual_expenses',
    jpName: '営業費用_実績',
    actualData: mergedData,
  };

  const newItems = JSON.parse(JSON.stringify(mapWithData));
  newItems.push(actualExpenses);

  return newItems;
};

/**
 * 項目ごとのマッピング情報を、タイムシリーズへのデータポイント挿入用リクエストボディの項目に変換
 * このとき数値を1/1000000にする
 * @param {MapItemWithData} mapItem 1項目分のマッピング情報
 * @param {SolarSite} siteAsset 対象発電サイトの情報
 * @returns {ExternalDatapointExternalId} データポイント挿入用リクエストボディの項目
 */
const convertMapItemToBodyItem = (
  mapItem: MapItemWithData,
  siteAsset: SolarSite,
): ExternalDatapointExternalId => {
  const datapoints = mapItem.actualData.map((entry: [string, number]) => (
    {
      timestamp: new Date(entry[0]).valueOf(),
      // 小数演算誤差が生じるためライブラリを使用して演算
      value: new Decimal(entry[1]).div(MILLION).toNumber(),
    }
  ));
  const externalId = `${siteAsset.externalId}_${mapItem.externalId}`;
  return { externalId, datapoints };
};

/**
 * アップロードされた財務計画値登録データファイルに記入された登録データをCDFのタイムシリーズに登録
 * タイムシリーズへのデータ登録後、単月データ登録更新処理および積算データ作成用エンドポイントを呼び出す
 * 登録前にファイルの記入内容に対してバリデーションチェックを行い、チェック結果を返却する
 * @param {XLSX.WorkBook} file 財務計画値登録データファイル
 * @param {SolarSite} siteAsset 対象発電サイトの情報
 * @returns {Promise<boolean>} ファイル記入内容のバリデーションチェック結果（画面側の後続処理で使用するため）
 */
export const registerActualFinance = async (
  file: XLSX.WorkBook,
  siteAsset: SolarSite,
): Promise<boolean> => {
  // ファイルの中身のバリデーションチェックを実施し、チェックOKの場合のみ後続処理に進む
  const dataSheet = file.Sheets['財務実績値登録シート'];
  if (!fileFormatCheck(dataSheet)) {
    return false;
  }

  const validationResult = checkFileValidity(dataSheet);
  if (validationResult) {
    const fiscalYear = dataSheet.C4.v;
    const headerDates = createHeaderDates(fiscalYear);

    // シートからのデータ抽出と営業費用の算出を実施
    const sheetData = extractDataFromFile(dataSheet, headerDates);
    const dataWithActualExpenses = addMergedActualExpenses(sheetData, headerDates)
      .filter((item) => item.actualData.length);

    /* マッピング情報から売上原価と販管費を省き、残った項目を用いてデータポイント挿入用リクエストボディを成形
      （タイムシリーズに挿入されるのは合算された営業費用のみのため） */
    const datapointBodyItems: ExternalDatapointExternalId[] = dataWithActualExpenses
      .filter((data) => (
        data.jpName !== '売上原価_実績'
        && data.jpName !== '販管費_実績'
      ))
      .filter((data) => data.externalId) // Timeseries登録対象外のデータを除外
      .map((data) => convertMapItemToBodyItem(data, siteAsset));

    /* 登録データが存在すれば、タイムシリーズにデータポイントを挿入し、単月データ登録更新および積算データ作成処理を呼び出す
        積算データ作成処理はレスポンスを待たずに画面に操作を戻す */
    if (datapointBodyItems.length) {
      const datapointsRequestBody = { items: datapointBodyItems };

      await postApiGateway<ItemsWrapper<ExternalDatapointExternalId[]>, EmptyResponse>(
        EP_PATH_SOLAR_TIMESERIES_DATAPOINTS, datapointsRequestBody,
      );

      const parentAssets = await getParentAssets([siteAsset]);
      const { category2Assets, businessClassificationAssets } = parentAssets;
      if (businessClassificationAssets.length > 0) {
        const createEventsResponse = await createFinanceDailyTask(ACTUAL_FINANCE_DAILY_TASK, businessClassificationAssets, fiscalYear, category2Assets[0].id);
        if ('error' in createEventsResponse) {
          return false;
        }
      }

      const sumActualFinanceRequestBody = {
        siteName: siteAsset.externalId as string,
        fiscalYear,
      };
      await putApiGateway<typeof sumActualFinanceRequestBody, string>(
        EP_PATH_SOLAR_PROCESSES_ACTUAL_FINANCE, sumActualFinanceRequestBody,
      );
    }

    if (dataWithActualExpenses.length) {
      await upsertActualFinanceEvents(headerDates, siteAsset, dataWithActualExpenses, fiscalYear);
    }
  }

  return validationResult;
};
