/**
 * orderの昇順ソート処理
 * @param {Array} array ソート対象の配列
 * @param {String} order 並び順 asc or desc
 */
export function sortOrder(array, order = 'asc') {
  if (array.length === 0) {
    return;
  }

  if (order !== 'asc' && order !== 'desc') {
    return;
  }

  array.sort((item1, item2) => {
    if (item1.metadata && item1.metadata.order && item2.metadata && item2.metadata.order) {
      if (order === 'asc') {
        if (Number(item1.metadata.order) < Number(item2.metadata.order)) return -1;
        if (Number(item1.metadata.order) > Number(item2.metadata.order)) return 1;
      } else {
        if (Number(item1.metadata.order) < Number(item2.metadata.order)) return 1;
        if (Number(item1.metadata.order) > Number(item2.metadata.order)) return -1;
      }
    }
    return 0;
  });
}

/**
 * nameのソート処理
 * @param {Array} array ソート対象の配列
 * @param {String} order 並び順 asc or desc
 */
export function sortName(array, order = 'asc') {
  if (array.length === 0) {
    return;
  }

  if (order !== 'asc' && order !== 'desc') {
    return;
  }
  array.sort((item1, item2) => {
    if (order === 'asc') {
      if (item1.name < item2.name) return -1;
      if (item1.name > item2.name) return 1;
    } else {
      if (item1.name < item2.name) return 1;
      if (item1.name > item2.name) return -1;
    }
    return 0;
  });
}

/**
 * createdTimeのソート処理
 * @param {Array} array ソート対象の配列
 * @param {String} order 並び順 asc or desc
 */
export function sortCreatedTime(array, order = 'asc') {
  if (array.length === 0) {
    return;
  }

  if (order !== 'asc' && order !== 'desc') {
    return;
  }
  array.sort((item1, item2) => {
    if (order === 'asc') {
      if (item1.createdTime < item2.createdTime) return -1;
      if (item1.createdTime > item2.createdTime) return 1;
    } else {
      if (item1.createdTime < item2.createdTime) return 1;
      if (item1.createdTime > item2.createdTime) return -1;
    }
    return 0;
  });
}

/**
 * idのソート処理
 * @param {Array} array ソート対象の配列
 * @param {String} order 並び順 asc or desc
 */
export function sortId(array, order = 'asc') {
  if (array.length === 0) {
    return;
  }

  if (order !== 'asc' && order !== 'desc') {
    return;
  }

  array.sort((item1, item2) => {
    if (order === 'asc') {
      if (item1.id < item2.id) return -1;
      if (item1.id > item2.id) return 1;
    } else {
      if (item1.id < item2.id) return 1;
      if (item1.id > item2.id) return -1;
    }
    return 0;
  });
}

/**
 * 撮影日時のソート処理
 * @param {Array} array ソート対象の配列
 * @param {String} order 並び順 asc or desc
 */
export function sortDateTime(array, order = 'asc') {
  if (array.length === 0) {
    return;
  }

  if (order !== 'asc' && order !== 'desc') {
    return;
  }

  array.sort((item1, item2) => {
    let dateTime1;
    let dateTime2;
    if (item1.metadata && item1.metadata['撮影日時']) {
      dateTime1 = Date.parse(item1.metadata['撮影日時']);
    } else {
      dateTime1 = Date.parse('2222/12/31 23:59:59');
    }
    if (item2.metadata && item2.metadata['撮影日時']) {
      dateTime2 = Date.parse(item2.metadata['撮影日時']);
    } else {
      dateTime2 = Date.parse('2222/12/31 23:59:59');
    }
    if (order === 'asc') {
      if (dateTime1 < dateTime2) return -1;
      if (dateTime1 > dateTime2) return 1;
    } else {
      if (dateTime1 < dateTime2) return 1;
      if (dateTime1 > dateTime2) return -1;
    }
    return 0;
  });
}
