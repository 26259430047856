import React, { useEffect } from 'react';
import { CogniteInternalId } from '@cognite/sdk';
import { Modal, message } from 'antd';
import { getStorageDataSetId } from '../../../utils/storageCommon';
import FileUploadProgress from './FileUploadProgress';
import { takeBinarySemaphore, giveBinarySemaphore } from '../../AuthWrapper/persistance';
import { ResourceType, getFileUploadEndpoint } from '../../../utils/common/SDFDataType';
import { createRequestUrl } from '../../../utils/AWS/ApiGateway';
import {
  POPUP_ERROR_MESSAGE_DISPLAY_TIME,
  FAILED_ADD_FILE,
  INFORMATION_REQUEST_RELOAD_AFTER_ADD_FILE,
  VALIDATE_ERROR_NUMBER_OF_SIMULTANEOUS_ADDITION_OF_FILE_FIRST,
  VALIDATE_ERROR_NUMBER_OF_SIMULTANEOUS_ADDITION_OF_FILE_SECOND,
} from '../../../utils/messages';

/**
 * ファイルの同時アップロード数上限
 * @link sdf-docs:03_画面仕様書/03-02_画面仕様書_設備管理_詳細表示・変更.xlsx
 */
const UPLOAD_FILES_LIMIT = 1000;

const FileUpload: React.FC<{
  assetId: CogniteInternalId,
  resourceType: ResourceType | null
  files: File[],
  onUploadComplete?: () => void
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [progressModalVisible, setProgressModalVisible] = React.useState<boolean>(false);
  const [totalNumberFiles, setTotalNumberFiles] = React.useState<number>(0);

  type ProgressHandle = React.ElementRef<typeof FileUploadProgress>;
  const progressRef = React.useRef<ProgressHandle>(null);
  const uploadFiles = React.useRef<File[]>([]);
  const uploadFilesQue = React.useRef<number[]>([]);

  const {
    assetId, resourceType, files, onUploadComplete,
  } = props;

  const EXECUTIONS_LIMIT = 16; // 同時実行数
  const completeFiles: number[] = [];

  // /** ファイルアップロードを別スレッドで行うWorkerオブジェクト */
  const fileUploadWorker: Worker = new Worker(new URL('./worker/fileuploader.worker.js', import.meta.url), { type: 'module' });

  /** WorkerにメッセージをPostする */
  const postMessageForWorkerAsync = async (
    dataSetId: number,
    uploadKey: number,
    uploadFile: File,
  ) => {
    if (!uploadFile) {
      return;
    }

    if (fileUploadWorker) {
      // 再認証ポップアップ中は処理を中断
      await takeBinarySemaphore();
      const endpointUrl = getFileUploadEndpoint(resourceType as ResourceType);
      const requestUrl = createRequestUrl(endpointUrl);
      fileUploadWorker.postMessage({
        uploadKey, dataSetId, assetId, requestUrl, uploadFile,
      });
      giveBinarySemaphore();
    }
  };

  /*
   * イベントハンドラ
   */
  useEffect(() => {
    fileUploadWorker.onmessage = (event: MessageEvent) => {
      const { data } = event;
      const { key, result } = data;
      completeFiles.push(key);
      if (progressRef && progressRef.current) {
        progressRef.current.countUp();
      }

      const uploadedFile = uploadFiles.current[Number(key)];
      if (result === 'error') {
        // エラーが発生した場合
        message.error(`${uploadedFile.name} ${FAILED_ADD_FILE}`);
      }

      if (uploadFilesQue.current.length === 0
        && completeFiles.length === uploadFiles.current.length) {
        // 全ファイルがアップロード完了
        message.info(
          INFORMATION_REQUEST_RELOAD_AFTER_ADD_FILE, POPUP_ERROR_MESSAGE_DISPLAY_TIME,
        );
        if (onUploadComplete) {
          onUploadComplete();
        }
        setProgressModalVisible(false);
        setTotalNumberFiles(0);
        if (fileUploadWorker) {
          fileUploadWorker.terminate();
        }
        if (progressRef && progressRef.current) {
          progressRef.current.reset();
        }
        return;
      }

      if (uploadFilesQue.current.length === 0
        && completeFiles.length < uploadFiles.current.length) {
        // アップロード待ちファイルが無い場合、他スレッドの処理完了待ち
        return;
      }

      // データが残っている場合、新たにアップロード
      (async () => {
        const dataSetId = getStorageDataSetId();

        const uploadKey = uploadFilesQue.current.shift();
        const uploadFile = uploadFiles.current[uploadKey as number];

        postMessageForWorkerAsync(
          dataSetId, uploadKey as number, uploadFile,
        );
      })();
    };
    // Workerの通知のみを検知して動作するため警告対象から外す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadWorker]);

  useEffect(() => {
    if (!files || files.length === 0) {
      giveBinarySemaphore();
      if (onUploadComplete) {
        onUploadComplete();
      }
      return;
    }

    // 同時アップロード数チェック
    if (files.length > UPLOAD_FILES_LIMIT) {
      message.error(`${VALIDATE_ERROR_NUMBER_OF_SIMULTANEOUS_ADDITION_OF_FILE_FIRST}
          ${UPLOAD_FILES_LIMIT}
          ${VALIDATE_ERROR_NUMBER_OF_SIMULTANEOUS_ADDITION_OF_FILE_SECOND}`);
      giveBinarySemaphore();
      if (onUploadComplete) {
        onUploadComplete();
      }
      return;
    }

    setProgressModalVisible(true);
    // プログレス表示された後に再認証モーダルを最前面に表示させる
    giveBinarySemaphore();

    setTotalNumberFiles(files.length);

    const filesArray = Array.from(files);
    const dataSetId = getStorageDataSetId();

    // 同時実行数 最大16
    const numberOfThreads = filesArray.length <= EXECUTIONS_LIMIT
      ? filesArray.length
      : EXECUTIONS_LIMIT;

    uploadFiles.current = [...filesArray];
    uploadFilesQue.current = Array(filesArray.length).fill(0).map((_, index) => index);

    for (let i = 0; i < numberOfThreads; i++) {
      const uploadKey = uploadFilesQue.current.shift();
      const uploadFile = uploadFiles.current[uploadKey as number];

      postMessageForWorkerAsync(
        dataSetId, uploadKey as number, uploadFile,
      );
    }
    // filesの変更のみを検知して動作するため警告対象から外す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  return (
    <>
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        closable={false}
        footer={null}
        visible={progressModalVisible}
        width={300}
      >
        <FileUploadProgress totalCount={totalNumberFiles} ref={progressRef} />
      </Modal>
    </>
  );
};

export default React.memo(FileUpload);
