/* eslint-disable react-hooks/exhaustive-deps */
// eslint警告未対応
import React, { useEffect, useCallback } from 'react';
import { message } from 'antd';
import Queue from 'promise-queue';
import ManagedFacility from '../../../utils/Asset/ManagedFacility';
import { ColumnChart, CountData, CountsDataMap } from './ColumnChart';
import LearningModel from '../../../utils/File/LearningModel';
import { JsonFile } from '../../../utils/File/BaseFile';
import ConfigFile from '../../../utils/File/ConfigFile';
import LogFile from '../../../utils/File/LogFile';
import CocoJsonFile from '../../../utils/File/CocoJsonFile';
import ScansJsonFile from '../../../utils/File/ScansJsonFile';
import { ERROR_LOAD_MANAGED_FACILITY_FILES_COUNT } from '../../../utils/messages';

const LEGEND_UPLOAD = 'アップロードファイル';
const LEGEND_AI_RESULT = 'AI関連';
const LEGEND_SCANS = '点検内容';
const SYSTEM_DATA = {
  KEY: '0',
  NAME: 'システム',
  LEGEND_MODEL: '学習モデル',
  LEGEND_COCO: 'coco.json',
  LEGEND_PARAMETER: 'パラメータ',
  LEGEND_CONFIG: 'コンフィグ',
  LEGEND_LOG: 'ログ',
} as const;

const DEFAULT_COUNT_DATA = [
  { legend: LEGEND_UPLOAD },
  { legend: LEGEND_AI_RESULT },
  { legend: LEGEND_SCANS },
  { legend: SYSTEM_DATA.LEGEND_MODEL },
  { legend: SYSTEM_DATA.LEGEND_COCO },
  { legend: SYSTEM_DATA.LEGEND_PARAMETER },
  { legend: SYSTEM_DATA.LEGEND_CONFIG },
  { legend: SYSTEM_DATA.LEGEND_LOG },
] as CountData[];

export const FileColumnChart: React.FC<{
  managedFacilities: ManagedFacility[],
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [loading, setLoading] = React.useState<boolean>(false);
  const [countsData, setCountsData] = React.useState<CountData[]>(DEFAULT_COUNT_DATA);
  const [countsDataMap, setCountsDataMap] = React.useState<CountsDataMap>({});

  const { managedFacilities } = props;

  const maxConcurrent = 16; // 並列実行するタスク数
  const queue = new Queue(maxConcurrent);

  /**
   * カウント情報設定
   * @param {string} name
   * @param {string} legend
   * @param {number} value
   * @returns カウント情報
   */
  const setCountData = useCallback((name, legend, value): CountData => (
    { name, legend, value }
  ), []);

  /**
   * 管理設備配下のファイル数カウント
   * @param {ManagedFacility} managedFacility 管理設備
   * @returns カウント情報群
   */
  const countFilesOf = useCallback(async (
    managedFacility: ManagedFacility,
  ): Promise<CountsDataMap> => {
    // アセットに紐づくファイル数取得
    const countFilesWithAsset = await managedFacility.countFilesWithAsset();

    /// AI検出画像ファイル数取得
    const countResultAi = await managedFacility.countResultAiFiles();

    // 点検内容のファイル数取得
    const countScans = await ScansJsonFile.countScansFiles(managedFacility.id);

    const result: CountsDataMap = {};
    const key = String(managedFacility.id);
    result[key] = [
      setCountData(managedFacility.name, LEGEND_UPLOAD, countFilesWithAsset),
      setCountData(managedFacility.name, LEGEND_AI_RESULT, countResultAi),
      setCountData(managedFacility.name, LEGEND_SCANS, countScans),
    ];

    return result;
  }, [setCountData]);

  /*
   * イベントハンドラ
   */
  useEffect(
    () => {
      if (managedFacilities.length === 0) {
        setCountsData(DEFAULT_COUNT_DATA);
        return;
      }
      setLoading(true);
    },
    [managedFacilities],
  );

  useEffect(
    () => {
      if (!loading) return () => { /* 何もしない */ };

      let canceled = false;

      (async () => {
        /*
         * メイン処理
         */
        try {
          // 管理設備配下のファイル数を非同期でカウント
          const promises: Promise<CountsDataMap>[] = [];
          managedFacilities.forEach((managedFacility) => {
            const key = String(managedFacility.id);
            if (!countsDataMap[key]) {
              promises.push(queue.add(() => countFilesOf(managedFacility)));
            }
          });

          const results = await Promise.all(promises);

          const newCountsDataMap = { ...countsDataMap };
          results.forEach((result) => {
            const keys = Object.keys(result);
            keys.forEach((key) => {
              newCountsDataMap[key] = result[key];
            });
          });

          const keys = managedFacilities.map(
            (managedFacility) => (String(managedFacility.id)),
          );
          const newCountsData: CountData[] = [];
          keys.sort().forEach((key) => {
            newCountsData.push(...newCountsDataMap[key]);
          });
          const promisesSystemCount: Promise<number>[] = [
            // 学習モデルファイル数取得
            LearningModel.countModelFiles(),
            // coco.jsonファイル数取得
            CocoJsonFile.countCocoFiles(),
            // パラメタファイル数取得(通常モデル検出用、アンサンブルモデル作成用・検出用)
            JsonFile.countTotalParameterFiles(),
            // コンフィグファイル数取得
            ConfigFile.countConfigFiles(),
            // ログファイル数取得
            LogFile.countLogFiles(),
          ];

          const resultsSystemCount = await Promise.all(promisesSystemCount);

          // システム－運用データ
          // 管理設備配下に存在しない運用ファイルをカウントする非同期処理
          const systemCountDataArray: CountData[] = [
            setCountData(SYSTEM_DATA.NAME, SYSTEM_DATA.LEGEND_MODEL, resultsSystemCount[0]),
            setCountData(SYSTEM_DATA.NAME, SYSTEM_DATA.LEGEND_COCO, resultsSystemCount[1]),
            setCountData(SYSTEM_DATA.NAME, SYSTEM_DATA.LEGEND_PARAMETER, resultsSystemCount[2]),
            setCountData(SYSTEM_DATA.NAME, SYSTEM_DATA.LEGEND_CONFIG, resultsSystemCount[3]),
            setCountData(SYSTEM_DATA.NAME, SYSTEM_DATA.LEGEND_LOG, resultsSystemCount[4]),
          ];

          const result: CountsDataMap = {};
          result[SYSTEM_DATA.KEY] = systemCountDataArray;
          newCountsDataMap[SYSTEM_DATA.KEY] = result[SYSTEM_DATA.KEY];
          newCountsData.push(...newCountsDataMap[SYSTEM_DATA.KEY]);

          // 管理設備名に重複がある場合は、名前を変更する
          for (let i = 0; i < newCountsData.length - 1; i++) {
            for (let j = i + 1; j < newCountsData.length; j++) {
              if (newCountsData[i].name === newCountsData[j].name
                && newCountsData[i].legend === newCountsData[j].legend) {
                newCountsData[j].name = newCountsData[j].name.concat(' ');
              }
            }
          }

          if (!canceled) {
            setLoading(false);
            setCountsDataMap(newCountsDataMap);
            setCountsData(newCountsData);
          }
        } catch (exception) {
          if (!canceled) {
            setLoading(false);
            message.error(ERROR_LOAD_MANAGED_FACILITY_FILES_COUNT);
          }
        }
      })();

      return () => { canceled = true; };
    },
    [
      managedFacilities, countsDataMap, loading, queue,
      countFilesOf, setCountData,
    ],
  );

  /*
   * メソッド
   */
  /*
   * 画面描画
   */
  return (
    <ColumnChart loading={loading} countsData={countsData} />
  );
};
