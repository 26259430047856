import React from 'react';
import {
  Button,
  Input,
  Icon,
  Checkbox,
  Menu,
} from 'antd';
import { FilterDropdownProps, ColumnFilterItem } from 'antd/lib/table';
import './FilterComponent.css';

/**
 * フィルタアイコン取得
 * @param {boolean} filtered
 * @returns フィルタアイコン
 */
const FilterIcon = (filtered: boolean): JSX.Element => (
  <Icon type="search" style={{ color: filtered ? '1890ff' : undefined }} />
);

/**
 * テキストフィルタ作成
 * @param {FilterDropdownProps} props テキストフィルタのproperty
 * @returns テキストフィルタ
 */
const TextFilter: React.FC<FilterDropdownProps> = (
  props: FilterDropdownProps,
) => {
  const {
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  } = props;

  return (
    <div className="filter-component-text-filter-dropdown">
      <Input
        className="filter-component-text-filter-input"
        value={selectedKeys ? selectedKeys[0] : undefined}
        onChange={(event) => {
          if (setSelectedKeys) {
            setSelectedKeys(event.target.value ? [event.target.value] : []);
          }
        }}
        onPressEnter={() => {
          if (confirm) {
            confirm();
          }
        }}
      />
      <Button
        className="filter-component-text-filter-search-button"
        type="primary"
        icon="search"
        size="small"
        onClick={() => {
          if (confirm) {
            confirm();
          }
        }}
      >
        検索
      </Button>
      <Button
        className="filter-component-text-filter-reset-button"
        size="small"
        onClick={() => {
          if (clearFilters) {
            clearFilters();
          }
        }}
      >
        リセット
      </Button>
    </div>
  );
};

/**
 * フィルタドロップダウン作成
 * @param {FilterDropdownProps} props ドロップダウンのproperty
 * @returns フィルタドロップダウン
 */
const FilterDropdown: React.FC<FilterDropdownProps> = (
  props: FilterDropdownProps,
) => {
  const {
    setSelectedKeys,
    selectedKeys,
    filters,
    confirm,
    clearFilters,
  } = props;
  return (
    <>
      <Menu
        className="ant-dropdown-menu-without-submenu filter-component-file-list-table"
        onClick={({ key }) => {
          let newSelectedKeys;
          if (selectedKeys && selectedKeys.indexOf(key) >= 0) {
            // 選択済みのデータはチェックを外す
            newSelectedKeys = selectedKeys.filter((selectedKey) => selectedKey !== key);
          } else if (selectedKeys) {
            newSelectedKeys = selectedKeys.concat([key]);
          } else {
            newSelectedKeys = [key];
          }
          const stringSelectedKeys = newSelectedKeys
            .map((newSelectedKey) => String(newSelectedKey));
          if (setSelectedKeys) setSelectedKeys(stringSelectedKeys);
        }}
      >
        {filters && filters.map((filter) => {
          const { text, value } = filter;
          const isSelected = selectedKeys && selectedKeys.indexOf(value) >= 0;
          return (
            <Menu.Item key={value} className="filter-component-file-list-item">
              <Checkbox checked={isSelected} />
              <span>{text}</span>
            </Menu.Item>
          );
        })}
      </Menu>
      <div className="ant-table-filter-dropdown-btns">
        <Button
          className="filter-component-filter-list-button"
          type="link"
          onClick={confirm}
          size="small"
        >
          OK
        </Button>
        <Button
          className="filter-component-filter-list-button clear"
          type="link"
          onClick={clearFilters}
          size="small"
        >
          Reset
        </Button>
      </div>
    </>
  );
};

/**
 * フィルタアイテムリスト作成
 * @param {Set<string | undefined>} uniqueData 一意の設備情報
 * @returns フィルタアイテムリスト
 */
const TableFilter = (uniqueData: Set<string | undefined>): ColumnFilterItem[] => {
  const filterItem: ColumnFilterItem[] = [];
  let text;
  uniqueData.forEach((elm) => {
    if (elm) {
      text = elm.replaceAll('<br>', ' ');
      filterItem.push(
        { text, value: String(elm) },
      );
    }
  });

  // ドロップダウン選択肢をソート
  return filterItem.sort((a, b) => {
    // 77行目のif分にてtextがnullになることはないためESLintの警告回避とする
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (a.text! > b.text!) {
      return 1;
    }
    return -1;
  });
};

export {
  FilterIcon,
  TextFilter,
  FilterDropdown,
  TableFilter,
};
