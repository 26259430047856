import React, { useState } from 'react';
import { Button, Tooltip, message } from 'antd';

import { ERROR_NO_AUTH_MESSAGE, ERROR_FILES_GET_DL_URL } from '../../../../utils/messages';
import { UnknownFile } from '../../../../utils/File/BaseFile';
import { ResourceType, getFileDownloadEndpoint } from '../../../../utils/common/SDFDataType';

export const DownloadButton: React.FC<{
  fileId: number | null,
  isModalDownloadButtonDisabled: boolean
  resourceType: ResourceType | null,
}> = (props) => {
  /*
   * 変数/定数宣言
   */
  const [downloading, setDownloading] = useState<boolean>(false);

  const {
    fileId,
    isModalDownloadButtonDisabled,
    resourceType,
  } = props;

  /**
   * ダウンロードボタンクリック時のイベントハンドラ
   */
  const handleClickDownload = async () => {
    setDownloading(true);

    let downloadUrl;
    let errorFileFlag = false;

    // ファイル読み込み用
    const newFile = UnknownFile.getInstance();
    newFile.id = fileId as number;
    try {
      const endpoint = getFileDownloadEndpoint(resourceType as ResourceType);
      downloadUrl = await newFile.loadDownloadURLFromCDF(
        endpoint,
      );
    } catch (e) {
      errorFileFlag = true;
    } finally {
      if (errorFileFlag) {
        // downloadURL取得失敗時はエラーメッセージを表示
        message.error(ERROR_FILES_GET_DL_URL);
      } else if (downloadUrl) {
        const aTagForDownload = document.createElement('a');
        document.body.appendChild(aTagForDownload);
        aTagForDownload.href = downloadUrl;
        aTagForDownload.click();
        aTagForDownload.remove();
      }
    }

    setDownloading(false);
  };

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  return (
    <>
      <div style={{ textAlign: 'right', marginTop: '10px' }}>
        <Tooltip title={isModalDownloadButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
          <Button
            type="primary"
            loading={downloading}
            onClick={handleClickDownload}
            disabled={isModalDownloadButtonDisabled}
          >
            ダウンロード
          </Button>
        </Tooltip>
      </div>
    </>
  );
};
