/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
// eslint警告未対応
import React from 'react';
import {
  Button,
  Popconfirm,
  Table,
  message,
  Icon,
  Input,
  Menu,
  Checkbox,
  DatePicker,
  Tooltip,
} from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import 'moment/locale/ja';
import { ColumnFilterItem, ColumnProps } from 'antd/lib/table';
import moment from 'moment';

import InspectionResult from '../../utils/Event/InspectionResult';
import RootAsset from '../../utils/Asset/RootAsset';
import ManagedFacility from '../../utils/Asset/ManagedFacility';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import { ERROR_LOAD_INSPECTION_RESULTS, ERROR_NO_AUTH_MESSAGE } from '../../utils/messages';

/** 日付フォーマット */
const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

const InspectionResultList: React.FC<{
  onSelect: (result?: InspectionResult) => void,
  editable?: boolean,
  rootAsset: RootAsset
}> = (props) => {
  /*
   * 定数/変数定義
   */
  const [loading, setLoading] = React.useState<boolean>(true);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [results, setResults] = React.useState<InspectionResult[]>([]);
  const [managedFacilities, setManagedFacilities] = React.useState<ManagedFacility[]>([]);
  const [target, setTarget] = React.useState<InspectionResult>();
  const [startDate, setStartDate] = React.useState<moment.Moment | null>();
  const [endDate, setEndDate] = React.useState<moment.Moment | null>();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = React.useState<boolean>(true);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = React.useState<boolean>(true);
  const [isDetailsLinkDisabled, setIsDetailsLinkDisabled] = React.useState<boolean>(true);

  const {
    rootAsset,
    editable,
  } = props;

  /**
 * 点検結果リストを開始年月日の降順でソート
 * @param {InspectionResult[]} inspectionResults 点検結果リスト
 * @returns 点検結果リスト(開始年月日の降順でソート済み)
 */
  function sortInspectionResults(inspectionResults: InspectionResult[]) {
    inspectionResults.sort((item1, item2) => {
      if (!item1.startTime || !item2.startTime) { return 0; }
      if (item1.startTime > item2.startTime) { return -1; }
      if (item1.startTime < item2.startTime) { return 1; }
      return 0;
    });
  }

  /*
   * イベントハンドラ
   */
  React.useEffect(() => {
    const {
      INSPECTION_RESULTS_LIST: {
        INSPECTION_RESULTS_LIST_ADD_BUTTON,
        INSPECTION_RESULTS_LIST_DELETE_BUTTON,
        INSPECTION_RESULTS_LIST_INSPECTION_RESULT_DETAILED_LINK,
      },
    } = AUTHENTICATION_TYPE_MATRIX;

    (async () => {
      setIsAddButtonDisabled(!await containsUIAuthType(INSPECTION_RESULTS_LIST_ADD_BUTTON));
      setIsDeleteButtonDisabled(!await containsUIAuthType(INSPECTION_RESULTS_LIST_DELETE_BUTTON));
      setIsDetailsLinkDisabled(
        !await containsUIAuthType(INSPECTION_RESULTS_LIST_INSPECTION_RESULT_DETAILED_LINK),
      );
    })();
  }, []);

  React.useEffect(
    () => {
      setLoading(true);
    },
    [rootAsset],
  );

  React.useEffect(
    () => {
      if (!loading) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        let rootAssetResults: InspectionResult[] = [];
        let rootAssetFacilities: ManagedFacility[] = [];

        try {
          rootAssetResults = await rootAsset.loadInspectionResultsFromCDF();
          rootAssetFacilities = await rootAsset.loadManagedFacilitiesFromCDF();
        } catch (exception) {
          message.error(ERROR_LOAD_INSPECTION_RESULTS);
        }

        sortInspectionResults(rootAssetResults);

        if (!canceled) {
          setLoading(false);
          setTarget(undefined);
          setManagedFacilities(rootAssetFacilities);
          setResults(rootAssetResults);
        }
      })();

      return () => { canceled = true; };
    },
    [rootAsset, loading],
  );

  React.useEffect(
    () => {
      if (!deleting) return () => { /* 何もしない */ };

      if (!target) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        try {
          await target.removeFromCDF();

          message.success('点検結果が正常に削除されました。');
        } catch (exception) {
          message.error('点検結果の削除に失敗しました。');
        }

        if (canceled) return;

        if (!canceled) {
          setDeleting(false);
          setTarget(undefined);
          setLoading(true);
        }
      })();

      return () => { canceled = true; };
    },
    [target, deleting],
  );

  /*
   * メソッド
   */

  /*
   * 画面描画
   */

  const createTargetManagedFacilityFilter = () => {
    // 全点検結果の対象管理設備を一意にする
    const managedFacilityFilter: ColumnFilterItem[] = [];

    managedFacilities.forEach((managedFacility) => {
      const targetManagedFacility: InspectionResult | undefined = results
        .find((result) => (
          managedFacility.id === result.targetManagedFacilityId
        ));
      if (!targetManagedFacility) {
        return;
      }
      managedFacilityFilter.push({
        text: managedFacility.name,
        value: String(managedFacility.id),
      });
    });

    // 対象管理設備の昇順でソート
    managedFacilityFilter.sort((managedFacilityA, managedFacilityB) => {
      // 管理設備名称が空白の場合、末尾にする
      if (!managedFacilityA.text) { return 1; }
      if (!managedFacilityB.text) { return 1; }

      if (managedFacilityA.text < managedFacilityB.text) {
        return -1;
      }

      // 同名の対象管理設備は存在しないため1固定で返却
      return 1;
    });

    return managedFacilityFilter;
  };

  /**
   * 選択した開始日と終了日を比較し、入力不可とする日付を判定する。
   * @param startValue 選択した開始日
   * @returns 判定結果(開始日/終了日が選択されている状態、かつ開始日が終了日より大きい場合true)
   */
  const disabledStartDate = (startValue: moment.Moment | null) => {
    if (!startValue || !endDate) {
      return false;
    }
    return startValue.valueOf() > endDate.valueOf();
  };

  /**
   * 開始日と選択した終了日を比較し、入力不可とする日付を判定する。
   * @param endValue 選択した終了日
   * @returns 判定結果(開始日/終了日が選択されている状態、かつ開始日が終了日より大きい場合true)
   */
  const disabledEndDate = (endValue: moment.Moment | null) => {
    if (!endValue || !startDate) {
      return false;
    }
    return endValue.valueOf() < startDate.valueOf();
  };

  // 対象管理設備フィルタドロップダウン作成
  const columns: ColumnProps<InspectionResult>[] = [
    {
      title: '開始年月日',
      dataIndex: 'startTime',
      width: 200,
      filterIcon: (filtered) => (
        <Icon type="search" style={{ color: filtered ? '1890ff' : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => {
        let datePickerValue;
        if (selectedKeys && selectedKeys[0]) {
          datePickerValue = moment(selectedKeys[0], DATE_FORMAT);
        }

        return (
          <div style={{ padding: 8 }}>
            <DatePicker
              disabledDate={disabledStartDate}
              value={datePickerValue}
              locale={locale}
              onChange={(dates) => {
                const selectedStartDate = dates;
                if (setSelectedKeys && selectedStartDate) {
                  setSelectedKeys([selectedStartDate.format(DATE_FORMAT)]);
                }
              }}
              size="small"
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => {
                if (confirm) {
                  confirm();
                }
                setStartDate(selectedKeys ? moment(selectedKeys[0], DATE_FORMAT) : null);
              }}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              検索
            </Button>
            <Button
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }

                setStartDate(null);
              }}
              size="small"
              style={{ width: 90 }}
            >
              リセット
            </Button>
          </div>
        );
      },
      onFilter: (value, result) => {
        if (!result.startTime) return false;
        const selectDate = moment(value, DATE_FORMAT);
        const resultStartDate = new Date(result.startTime);

        const inRange = moment(resultStartDate, DATE_FORMAT).isSameOrAfter(selectDate, 'days');
        return inRange;
      },
      render: (text: string, result: InspectionResult, index: number) => moment(result.startTime).format('YYYY年MM月DD日'),
    },
    {
      title: '終了年月日',
      dataIndex: 'endTime',
      width: 200,
      filterIcon: (filtered) => (
        <Icon type="search" style={{ color: filtered ? '1890ff' : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => {
        let datePickerValue;
        if (selectedKeys && selectedKeys[0]) {
          datePickerValue = moment(selectedKeys[0], DATE_FORMAT);
        }

        return (
          <div style={{ padding: 8 }}>
            <DatePicker
              disabledDate={disabledEndDate}
              value={datePickerValue}
              locale={locale}
              onChange={(dates) => {
                const selectedEndDate = dates;
                if (setSelectedKeys && selectedEndDate) {
                  setSelectedKeys([selectedEndDate.format(DATE_FORMAT)]);
                }
              }}
              size="small"
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => {
                if (confirm) {
                  confirm();
                }
                setEndDate(selectedKeys ? moment(selectedKeys[0], DATE_FORMAT) : null);
              }}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              検索
            </Button>
            <Button
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }

                setEndDate(null);
              }}
              size="small"
              style={{ width: 90 }}
            >
              リセット
            </Button>
          </div>
        );
      },
      onFilter: (value, result) => {
        if (!result.endTime) return false;
        const selectDate = moment(value, DATE_FORMAT);
        const resultEndDate = new Date(result.endTime);

        const inRange = moment(resultEndDate, DATE_FORMAT).isSameOrBefore(selectDate, 'days');
        return inRange;
      },
      render: (text: string, result: InspectionResult, index: number) => moment(result.endTime).format('YYYY年MM月DD日'),
    },
    {
      title: '名前',
      dataIndex: 'description',
      key: 'name',
      filterIcon: (filtered) => (
        <Icon type="search" style={{ color: filtered ? '1890ff' : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys ? selectedKeys[0] : undefined}
            onChange={(event) => {
              if (setSelectedKeys) {
                setSelectedKeys(event.target.value ? [event.target.value] : []);
              }
            }}
            onPressEnter={() => {
              if (confirm) {
                confirm();
              }
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              if (confirm) {
                confirm();
              }
            }}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            検索
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
              }
            }}
            size="small"
            style={{ width: 90 }}
          >
            リセット
          </Button>
        </div>
      ),
      onFilter: (value, result) => (
        result.name ? result.name.toLowerCase().includes((value as string).toLowerCase()) : false
      ),
      render: (text, file) => (
        <Button
          type="link"
          onClick={(event) => {
            event.stopPropagation();
            setTarget(file);
          }}
          style={{ padding: 0, color: 'rgba(0, 0, 0, 0.65)' }}
        >
          {file.name}
        </Button>
      ),
    },
    {
      title: '対象管理設備',
      dataIndex: 'targetManagedFacility',
      render: (text: string, result: InspectionResult, index: number) => {
        if (!result.assetIds || result.assetIds.length === 0) return '-';

        const managedFacilityId = result.assetIds[0];
        const managedFacility = managedFacilities.find((managedFacilityItem) => (
          managedFacilityItem.id === managedFacilityId
        ));
        return managedFacility ? managedFacility.name : '-';
      },
      filters: createTargetManagedFacilityFilter(),
      onFilter: (value, result) => String(
        result.targetManagedFacilityId,
      )?.indexOf(value) === 0,
      filterDropdown: results.length === 0
        ? null
        : ({
          setSelectedKeys,
          selectedKeys,
          filters,
          confirm,
          clearFilters,
        }) => (
          <>
            <Menu
              className="ant-dropdown-menu-without-submenu file-list-table"
              onClick={({ key }) => {
                let newSelectedKeys;
                if (selectedKeys && selectedKeys.indexOf(key) >= 0) {
                  // 選択済みの対象管理設備はチェックを外す
                  newSelectedKeys = selectedKeys.filter((selectedKey) => selectedKey !== key);
                } else if (selectedKeys) {
                  newSelectedKeys = selectedKeys.concat([key]);
                } else {
                  newSelectedKeys = [key];
                }
                const stringSelectedKeys: string[] = [];
                newSelectedKeys.forEach((newSelectedKey) => (
                  stringSelectedKeys.push(String(newSelectedKey))
                ));
                if (!setSelectedKeys) {
                  return;
                }

                setSelectedKeys(stringSelectedKeys);
              }}
            >
              {filters && filters.map((filter) => {
                const { text, value } = filter;
                const isSelected = selectedKeys && selectedKeys.indexOf(value) >= 0;
                return (
                  <Menu.Item key={value} className="file-list-item">
                    <Checkbox checked={isSelected} />
                    <span>{text}</span>
                  </Menu.Item>
                );
              })}
            </Menu>
            <div className="ant-table-filter-dropdown-btns">
              <Button
                className="filter-list-button"
                type="link"
                onClick={confirm}
                size="small"
              >
                OK
              </Button>
              <Button
                className="filter-list-button clear"
                type="link"
                onClick={clearFilters}
                size="small"
              >
                Reset
              </Button>
            </div>
          </>
        ),
    },
    {
      title: 'アクション',
      dataIndex: 'action',
      align: 'center',
      width: 150,
      render: (text: string, result: InspectionResult) => {
        const deleteIconColor = isDeleteButtonDisabled ? '#b4b5b8' : '#eb2f96';

        return (
          <>
            {
              editable && (
                <Popconfirm
                  title="本当に削除してよろしいですか？"
                  disabled={isDeleteButtonDisabled}
                  onConfirm={() => {
                    setTarget(result);
                    setDeleting(true);
                  }}
                >
                  <Tooltip title={isDeleteButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                    <Button
                      type="link"
                      loading={deleting && target?.id === result.id}
                      disabled={(deleting && target?.id !== result.id) || isDeleteButtonDisabled}
                    >
                      <Icon type="delete" theme="twoTone" twoToneColor={deleteIconColor} />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              )
            }
          </>
        );
      },
    },
  ];

  return (
    <div style={{ margin: '10px' }}>
      <div>
        <span>点検結果一覧</span>
      </div>
      <hr />
      <Table<InspectionResult>
        columns={columns}
        rowKey="id"
        dataSource={results}
        loading={loading}
        onRow={(result) => ({
          onDoubleClick: () => {
            if (!isDetailsLinkDisabled) {
              props.onSelect(result);
            }
          },
        })}
        components={{
          body: {
            row: (rowProps) => (
              <Tooltip title={isDetailsLinkDisabled && ERROR_NO_AUTH_MESSAGE}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <tr {...rowProps} />
              </Tooltip>
            ),
          },
        }}
        pagination={false}
        scroll={{ y: 920 }}
      />
      <div style={{ marginTop: 10 }}>
        <Tooltip title={isAddButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
          <Button
            type="link"
            icon="plus"
            onClick={() => { props.onSelect(); }}
            disabled={loading || deleting || isAddButtonDisabled}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default InspectionResultList;
