import React from 'react';

import {
  Card,
  List,
  Row,
  Statistic,
} from 'antd';
import moment from 'moment';

import OMStatusEvent from '../../../../utils/Event/OMStatusEvent';

import './StatDisplay.css';

interface StatDisplayProps {
  statusEvents: OMStatusEvent[];
  loading: boolean;
}

/**
 * 発電量・日射強度・気温表示ステータスのコンポーネント
 * @param {StatDisplayProps} props プロパティ
 * @returns 発電量・日射強度・気温表示ステータス
 */
const StatDisplay: React.FC<StatDisplayProps> = (props) => {
  const { statusEvents, loading } = props;

  /**
   * ステータス表示のタイトルを取得
   * @param {OMStatusEvent} statusEvent
   * @returns {string} ステータス表示用タイトル
   */
  const getStatusTitle = (statusEvent: OMStatusEvent) => {
    if (statusEvent.title !== '') return statusEvent.title;

    const updateTime = moment(statusEvent.startTime).format('YYYY/MM/DD HH:mm');
    const statusTitle = `${updateTime} 更新`;

    return statusTitle;
  };

  return (
    <>
      <Row>
        <List
          loading={loading}
          grid={{
            gutter: 0,
            column: 3,
          }}
          dataSource={statusEvents}
          renderItem={(item) => (
            <List.Item>
              <Card
                className="om-dashboard-stat-display"
              >
                <h4 className="om-dashboard-stat-display-title">{getStatusTitle(item)}</h4>
                <div className="om-dashboard-stat-display-content">
                  <Statistic
                    title="総発電量"
                    value={item.totalElectricEnergy}
                    suffix={<span style={{ fontSize: '0.8em' }}>kWh/d</span>}
                    className="om-dashboard-stat-display-number"
                  />
                  <Statistic
                    title="日射強度"
                    value={item.solarRadiationIntensity}
                    suffix={<span style={{ fontSize: '0.8em' }}>kW/㎡</span>}
                    className="om-dashboard-stat-display-number"
                  />
                  <Statistic
                    title="外気温"
                    value={item.temperature}
                    suffix={<span style={{ fontSize: '0.8em' }}>℃</span>}
                    className="om-dashboard-stat-display-number"
                  />
                </div>
              </Card>
            </List.Item>
          )}
        />
      </Row>
    </>
  );
};

export default StatDisplay;
