import React from 'react';
import { Tabs } from 'antd';

import AlertEmailSetting from './AlertEmailSetting';
import GroupSetting from './GroupSetting';
import EmailNotificationGroupSettings from './EmailNotificationGroupSettings';

import './SolarEmailSettingsTabs.css';

const { TabPane } = Tabs;

interface NotificationTabInfo {
  title: string;
  key: string;
  displayScreen: JSX.Element;
}

const allTabNames: NotificationTabInfo[] = [
  {
    title: 'グループ設定',
    key: 'group-setting-tab',
    displayScreen: <GroupSetting />,
  },
  {
    title: '発電・死活監視メール設定',
    key: 'solar-electric-activity-check-mail-setting-tab',
    displayScreen: <EmailNotificationGroupSettings />,
  },
  {
    title: '警報メール設定',
    key: 'alarm-mail-setting-tab',
    displayScreen: <AlertEmailSetting />,
  },
];

/**
 * メール通知設定画面選択タブ
 * @returns メール通知設定画面選択タブコンポーネント
 */
const SolarEmailSettingsTabs: React.FC = () => (
  <div className="solar-email-setting-background-color">
    <Tabs defaultActiveKey={allTabNames[0].key}>
      {allTabNames.map(({ title, key, displayScreen }) => (
        <TabPane tab={title} key={key}>{displayScreen}</TabPane>
      ))}
    </Tabs>
  </div>
);

export default SolarEmailSettingsTabs;
