import { Point } from './Point';

/**
 * EventTouchクラス
 */
class EventTouch {
  /*
   * クラスメソッド
   */

  static mouseDelta(e: WheelEvent): number {
    let delta: number;
    if (e.deltaY) {
      delta = -(e.deltaY);
    } else {
      delta = -(e.detail);
    }

    return delta;
  }

  static clientPosition(e: MouseEvent): Point {
    return new Point(e.clientX, e.clientY);
  }

  /*
   * メンバ変数
   */
  isDown: boolean;

  currentPoint: Point;

  startPoint: Point;

  diffPoint: Point;

  scale: number;

  /*
   * コンストラクタ
   */
  constructor() {
    this.isDown = false;

    this.currentPoint = new Point();
    this.startPoint = new Point();
    this.diffPoint = new Point();

    this.scale = 1;

    document.addEventListener('mousedown', this.onStart.bind(this), false);
    document.addEventListener('mousemove', this.onMove.bind(this), false);
    document.addEventListener('mouseup', this.onUp.bind(this), false);
  }

  onStart(e: MouseEvent): void {
    this.isDown = true;
    this.diffPoint = new Point();
    this.startPoint = EventTouch.clientPosition(e);
  }

  onMove(e: MouseEvent): void {
    this.currentPoint = EventTouch.clientPosition(e);

    if (!this.isDown) return;

    this.diffPoint = new Point(this.currentPoint.x, this.currentPoint.y).subtract(this.startPoint);
  }

  onUp(): void {
    this.isDown = false;
  }

  removeEvent(): void {
    document.removeEventListener('mousedown', this.onStart.bind(this), false);
    document.removeEventListener('mousemove', this.onMove.bind(this), false);
    document.removeEventListener('mouseup', this.onUp.bind(this), false);
  }
}

export { EventTouch };
