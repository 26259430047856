import { unitOfTime } from 'moment';

/* 計測ポイントデータの期間種別 */
const TimeInterval = {
  Instant: 'instant', // 1日分（1分単位）
  Hourly: 'hourly', // 1日分（1時間単位）
  Daily: 'daily', // 1月分（1日単位）
  Monthly: 'monthly', // 1年分（1月単位）
  None: 'none', // なし（OMMeasurementのgetterのデフォルト値用）
} as const;

type TimeInterval = typeof TimeInterval[keyof typeof TimeInterval];

/* 計測一覧画面での計測値のテーブル表示、CSV出力用情報 */
export interface RetrievalTimeRangeInfo {
  range: TimeInterval;
  displayName: string;
  addTimeSpan: unitOfTime.DurationConstructor;
  displayFormat: string;
  csvHeaderFormat: string;
}

const TIME_RANGE_INFO: Record<TimeInterval, RetrievalTimeRangeInfo> = {
  instant: {
    range: 'instant',
    displayName: '1日分（1分単位）',
    addTimeSpan: 'day',
    displayFormat: 'HH:mm',
    csvHeaderFormat: 'YYYY年MM月DD日',
  },
  hourly: {
    range: 'hourly',
    displayName: '1日分（1時間単位）',
    addTimeSpan: 'day',
    displayFormat: 'HH時',
    csvHeaderFormat: 'YYYY年MM月DD日',
  },
  daily: {
    range: 'daily',
    displayName: '1月分（1日単位）',
    addTimeSpan: 'month',
    displayFormat: 'DD日',
    csvHeaderFormat: 'YYYY年MM月',
  },
  monthly: {
    range: 'monthly',
    displayName: '1年分（1月単位）',
    addTimeSpan: 'year',
    displayFormat: 'MM月',
    csvHeaderFormat: 'YYYY年',
  },
  none: {
    range: 'none',
    displayName: '',
    addTimeSpan: '' as unitOfTime.DurationConstructor,
    displayFormat: '',
    csvHeaderFormat: '',
  },
};

const TIME_RANGE_INFO_FOR_DISPLAY = Object.values(TIME_RANGE_INFO)
  .filter(({ range }) => range !== TimeInterval.None);

const getTimeRangeInfo = (timeInterval: TimeInterval): RetrievalTimeRangeInfo => TIME_RANGE_INFO[timeInterval];

export {
  TimeInterval,
  TIME_RANGE_INFO,
  TIME_RANGE_INFO_FOR_DISPLAY,
  getTimeRangeInfo,
};
