/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import React, { useState, useEffect } from 'react';
import { AssetListScope } from '@cognite/sdk';
import { message, Typography, Divider } from 'antd';

import { loadAllSolarSiteFromCDFByScope } from '../../utils/Asset/SolarSiteAsset';
import SolarSite from '../../utils/Asset/SolarSite';
import RegisteredFilesTable from './RegisteredFilesTable';
import SolarRegisteredFile from '../../utils/File/SolarRegisteredFile';
import { ERROR_FILES_GET_DL_URL } from '../../utils/messages';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import './RegisteredFilesList.css';

type LoadRegisteredFiles = {
  loading: boolean,
  solarRegisteredFiles: SolarRegisteredFile[],
};

const SDF_ASSET_TYPE = process.env.REACT_APP_SDF_ASSET ?? 'sdfasset';

/**
 * アップロードファイル一覧画面
 * @returns アップロードファイル一覧画面
 */
const RegisteredFilesList: React.FC = () => {
  const [
    loadRegisteredFiles, setLoadRegisteredFiles,
  ] = useState<LoadRegisteredFiles>({ loading: true, solarRegisteredFiles: [] });
  const [isFileNameDownloadDisabled, setIsFileNameDownloadDisabled] = useState(true);
  const [isReloadButtonDisabled, setIsReloadButtonDisabled] = useState(true);

  useEffect(() => {
    let canceled = false;
    (async () => {
      const loadSolarRegisteredFiles = await loadSortedSolarRegisteredFiles();

      if (!canceled) {
        setLoadRegisteredFiles({
          loading: false,
          solarRegisteredFiles: loadSolarRegisteredFiles,
        });
        const { SOLAR_MENU: { DATA_REGISTER } } = AUTHENTICATION_TYPE_MATRIX;
        const {
          SOLAR_MENU_DATA_REGISTER_FILE_NAME_DOWNLOAD,
          SOLAR_MENU_DATA_REGISTER_RELOAD_BUTTON,
        } = DATA_REGISTER;
        setIsFileNameDownloadDisabled(
          !await containsUIAuthType(SOLAR_MENU_DATA_REGISTER_FILE_NAME_DOWNLOAD),
        );
        setIsReloadButtonDisabled(
          !await containsUIAuthType(SOLAR_MENU_DATA_REGISTER_RELOAD_BUTTON),
        );
      }
    })();

    return () => { canceled = true; };
  }, []);

  /**
   * 登録済みファイル一覧の取得
   * @returns 登録済みファイル一覧
   */
  const loadSortedSolarRegisteredFiles = async (): Promise<SolarRegisteredFile[]> => {
    // metadata.assetTypeがsdfassetを持つデータは必ず1件しか存在しないためlimitは不要
    const scope: AssetListScope = {
      filter: {
        metadata: {
          assetType: SDF_ASSET_TYPE,
        },
      },
    };

    const [siteInfoAsset] = await loadAllSolarSiteFromCDFByScope(scope);
    const solarSite = new SolarSite(siteInfoAsset);
    const loadSolarRegisteredFiles = await solarSite.loadSolarRegisteredFiles();

    return loadSolarRegisteredFiles.sort(
      (a, b) => {
        // 第一ソート（アップロード日時 降順）
        const convertUploadedTimeA = Number(a.uploadedTime);
        const convertUploadedTimeB = Number(b.uploadedTime);
        if (convertUploadedTimeA !== convertUploadedTimeB) {
          return convertUploadedTimeB - convertUploadedTimeA;
        }

        // 第二ソート（ID 昇順）
        return a.id - b.id;
      },
    );
  };

  /**
   * 登録済みファイルダウンロード
   * @param {SolarRegisteredFile} solarRegisteredFile クリックしたファイル
   */
  const handleClickDownload = async (solarRegisteredFile: SolarRegisteredFile): Promise<void> => {
    const downloadUrl = await solarRegisteredFile.loadDownloadURL();
    if (!downloadUrl) {
      message.error(ERROR_FILES_GET_DL_URL);
      return;
    }

    const aTagForDownload = document.createElement('a');
    document.body.appendChild(aTagForDownload);
    aTagForDownload.download = solarRegisteredFile.name;
    aTagForDownload.href = downloadUrl;
    aTagForDownload.click();
    aTagForDownload.remove();
  };

  /**
   * reloadボタンのイベントハンドラ
   */
  const handleClickReload = async (): Promise<void> => {
    setLoadRegisteredFiles({
      loading: true,
      solarRegisteredFiles: [],
    });

    const loadSolarRegisteredFiles = await loadSortedSolarRegisteredFiles();

    setLoadRegisteredFiles({
      loading: false,
      solarRegisteredFiles: loadSolarRegisteredFiles,
    });
  };

  const { Title } = Typography;
  const { loading, solarRegisteredFiles } = loadRegisteredFiles;
  return (
    <div>
      <div
        className="registered-files-list-title"
      >
        <div className="registered-files-list-margin">
          <Title level={4}>アップロードファイル一覧</Title>
        </div>
        <Divider
          className="registered-files-list-divider"
        />
      </div>
      <RegisteredFilesTable
        loading={loading}
        solarRegisteredFiles={solarRegisteredFiles}
        isFileNameDownloadDisabled={isFileNameDownloadDisabled}
        isReloadButtonDisabled={isReloadButtonDisabled}
        onClickFileName={handleClickDownload}
        onClickReload={handleClickReload}
      />
    </div>
  );
};

export default RegisteredFilesList;
