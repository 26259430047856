/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
/* eslint-disable react/sort-comp */
// eslint警告未対応
import React from 'react';
import {
  Modal, Input, Form, Select,
} from 'antd';
import styled from 'styled-components';

import {
  getCocoJsonExternalId, getCocoJsonFile, deleteImages, deleteAnnotations,
} from '../../../utils/cocoJsonEditor';
import { getStorageDataSetId, getTrainTaskAssetId } from '../../../utils/storageCommon';
import { uploadFile, createEvent } from '../../../utils/dataAccess';
import { validateLearningParameters } from '../../../utils/learningModelManagement/learningModelValidator';
import { getTaskPriority } from '../../../utils/common';
import { MimeType } from '../../../utils/File/BaseFile';
import { ENGINE_VALUES } from '../../../utils/common/SDFDataType';
import { ERROR_ADD_LEARNING_MODEL_TASK } from '../../../utils/messages';
import { EP_PATH_LEARNING_MODEL_AI_MODEL_FILES, EP_PATH_AI_LEARNING_EXECUTE, EP_PATH_TRAIN_TASK_LIST } from '../../../utils/AWS/EndpointPath';

const { Option } = Select;

/** エラーメッセージ */
const ErrorMessages = styled.div`
  color: #f5222d;
`;

/**
 * 学習パラメータ設定ダイアログコンポーネントクラス
 * @property {boolean} visible ダイアログ表示状態
 * @property {function} modalCancel ダイアログキャンセルボタンイベントハンドラ
 */
const SettingLearningParameterDialog = Form.create({ name: 'setting_learning_parameter_dialog' })(

  class extends React.Component {
    state = {
      /** パラメータセレクトボックスの情報 */
      parameters: {
        hasData: false,
        selectBox: undefined,
        defaultValue: undefined,
      },
      /** 学習モデル作成フラグ true: 作成中 / false: 作成中以外 */
      confirmLoading: false,
      /** エラーメッセージ */
      errorMessage: undefined,
    };

    /**
     * エンジンに応じた設定用jsonファイルを読み込む
     */
    async loadTrainConfig() {
      const configPath = 'train_config_deeplab.json';
      const res = await fetch(configPath);

      this.trainConfig = await res.json();
    }

    /**
     * パラメータセレクトボックスを作成する。
     */
    generateParameterSelectBox() {
      this.setState({ parameters: { hasData: false, selectBox: undefined, defaultValue: undefined } });

      const options = [];
      Object.keys(this.trainConfig).forEach((key, index) => {
        options.push(<Option key={index} value={key}>{key}</Option>);
      });

      const defaultValue = options.length > 0 ? options[0].props.value : '';
      const parameterSelect = <Select aria-label="parameter">{options}</Select>;

      this.selectedPattern = defaultValue;

      this.setState({ parameters: { hasData: true, selectBox: parameterSelect, defaultValue } });
    }

    /**
     * Categoriesの削除
     * @param {array} categories 削除対象のクラス情報
     * @param {number} categoryId 削除対象のクラスタグ
     */
    deleteCategories = (categories, categoryId) => {
      const categoriesCopy = [...categories];
      categoriesCopy.forEach((category, index) => {
        if (category.id === categoryId) {
          categories.splice(index, 1);
        }
      });
    };

    /**
     * チェックしていない画像の取得
     * @param {object} jsonFile JSONファイル情報
     * @returns {array} uncheckImages 未チェックの画像リスト
     */
    getUncheckImage = (jsonFile) => {
      const allImages = jsonFile.images.map((image) => image.file_name);
      const uncheckImages = [];
      allImages.forEach((fileId) => {
        if (this.props.fileIds.indexOf(Number(fileId)) === -1) {
          uncheckImages.push(fileId);
        }
      });
      return uncheckImages;
    };

    /**
     * アノテーションしていない画像の取得
     * @param {object} jsonFile JSONファイル情報
     * @returns {array} notAnnotationImages 未アノテーションの画像リスト
     */
    getNotAnnotationImages = (jsonFile) => {
      const annotationImages = jsonFile.annotations.map((annotation) => annotation.image_id);
      const notAnnotationImages = [];
      jsonFile.images.forEach((image) => {
        if (annotationImages.indexOf(image.id) === -1) {
          notAnnotationImages.push(image.file_name);
        }
      });
      return notAnnotationImages;
    };

    /**
     * 使用していないclassの取得
     * @param {object} jsonFile JSONファイル情報
     * @returns {array} notExistCategories 未使用のクラスリスト
     */
    getNotExistCategories = (jsonFile) => {
      const existCategories = jsonFile.annotations.map((annotation) => annotation.category_id);
      const notExistCategories = [];
      jsonFile.categories.forEach((category) => {
        if (existCategories.indexOf(category.id) === -1) {
          notExistCategories.push(category.id);
        }
      });
      return notExistCategories;
    };

    /**
     * 未選択の画像情報をJSONから削除
     * @param {object} jsonFile JSONファイル情報
     */
    deleteUncheckImages(jsonFile) {
      const uncheckImage = this.getUncheckImage(jsonFile);
      uncheckImage.forEach((fileId) => {
        const imageId = deleteImages(jsonFile.images, fileId);
        deleteAnnotations(jsonFile.annotations, 'image_id', imageId);
      });
    }

    /**
     * 未アノテーション画像情報をJSONから削除
     * @param {object} jsonFile JSONファイル情報
     */
    deleteNotAnnotationImages(jsonFile) {
      const notAnnotationImages = this.getNotAnnotationImages(jsonFile);
      notAnnotationImages.forEach((fileName) => {
        deleteImages(jsonFile.images, fileName);
      });
    }

    /**
     * 未使用のクラス情報をJSONから削除
     * @param {object} jsonFile JSONファイル情報
     */
    deleteNotExistsCategories(jsonFile) {
      const notExistCategories = this.getNotExistCategories(jsonFile);
      notExistCategories.forEach((categoryId) => {
        this.deleteCategories(jsonFile.categories, categoryId);
      });
    }

    /**
     * JSONファイル情報を取得
     * @returns JSONファイル情報
     */
    async getCocoJsonData() {
      const { learningProjectAssetId, learningProjectAssetName } = this.props;
      const jsonExternalId = getCocoJsonExternalId(learningProjectAssetId, learningProjectAssetName);
      const jsonFile = await getCocoJsonFile(jsonExternalId);

      // アノテーションした画像のうち、未チェックの画像情報削除
      this.deleteUncheckImages(jsonFile);

      // アノテーションしていない画像情報削除
      this.deleteNotAnnotationImages(jsonFile);

      // 未使用クラス情報を削除
      this.deleteNotExistsCategories(jsonFile);

      return jsonFile;
    }

    /**
     * Train ConfigFileを保存する。
     * @param {string} modelName 学習モデル名
     * @param {string} engine エンジン
     * @param {string} parameter パラメータ
     * @param {object} cocoJsonData cocoJSON
     * @returns 保存したファイルのID
     */
    async saveTrainConfigJson(modelName, engine, parameter, cocoJsonData) {
      const { Json } = MimeType;
      const fileInfo = {
        name: `${modelName}.json`,
        assetIds: [this.props.learningProjectAssetId],
        dataSetId: getStorageDataSetId(),
        mimeType: Json,
        metadata: {
          type: 'config',
          engine,
        },
      };
      const fileContent = JSON.stringify({ config: this.trainConfig[parameter], coco: cocoJsonData });

      const uploadFileId = await uploadFile(EP_PATH_LEARNING_MODEL_AI_MODEL_FILES, fileInfo, fileContent);
      return uploadFileId;
    }

    /**
     * 学習モデル作成タスクを登録する。
     * @param {string} modelName 学習モデル名
     * @param {string} engine エンジン
     * @param {number} configFileId コンフィグファイルID
     */
    async createTrainTask(modelName, engine, configFileId) {
      const { learningProjectAssetId } = this.props;
      const trainTaskId = await getTrainTaskAssetId();
      const priority = await getTaskPriority(EP_PATH_TRAIN_TASK_LIST, trainTaskId);

      const event = {
        assetIds: [trainTaskId],
        dataSetId: getStorageDataSetId(),
        startTime: priority,
        type: 'train',
        subtype: 'untreated',
        description: modelName,
        metadata: {
          engine,
          configId: String(configFileId),
          projectId: String(learningProjectAssetId),
        },
      };
      await createEvent(EP_PATH_AI_LEARNING_EXECUTE, event);
    }

    /**
     * キャンセルボタンクリックイベントハンドラ
     * 呼び出し元から渡されたキャンセルボタンイベントハンドラを呼び出す。
     */
    handleClickCancel = () => this.props.modalCancel();

    /**
     * 学習開始ボタンクリックイベントハンドラ
     */
    handleClickOk = async () => {
      this.setState({ confirmLoading: true, errorMessage: undefined });

      const { form } = this.props;
      const { modelName, engine, parameter } = form.getFieldsValue();

      // エラー情報クリア
      form.setFields({ modelName: { value: modelName, errors: null } });

      // 入力チェック
      const { learningProjectAssetId } = this.props;
      try {
        const validateResult = await validateLearningParameters(modelName, parameter, learningProjectAssetId);

        if (validateResult.hasError) {
          if (validateResult.field === 'modelName') {
            // 学習モデル名入力チェックエラー
            form.setFields({
              modelName: { value: validateResult.value, errors: [new Error(validateResult.message)] },
            });
            this.setState({ confirmLoading: false });
            return;
          }

          if (validateResult.field === 'parameter') {
            // パラメータ入力チェックエラー
            form.setFields({
              parameter: { value: validateResult.value, errors: [new Error(validateResult.message)] },
            });
            this.setState({ confirmLoading: false });
            return;
          }

          // アノテーション情報ファイルエラー
          this.setState({ confirmLoading: false, errorMessage: validateResult.message });
          return;
        }

        // cocoJSONデータの取得
        const cocoJsonData = await this.getCocoJsonData();

        // TrainConfigFileの保存
        const configFileId = await this.saveTrainConfigJson(modelName, engine, parameter, cocoJsonData);

        // TrainTask(Event)の登録
        await this.createTrainTask(modelName, engine, configFileId);
      } catch (e) {
        const errorCode = e.errors && e.errors[0].status ? e.errors[0].status : '-';
        this.setState({ confirmLoading: false, errorMessage: `${ERROR_ADD_LEARNING_MODEL_TASK} ${errorCode}` });
        return;
      }

      this.setState({ confirmLoading: false });

      // タスクの登録が完了後、呼び出し元の処理を呼び出す。
      this.props.onSuccessCreateLearningModel();
    };

    /**
     * エンジンセレクトボックス変更イベントハンドラ
     * @param {string} value 選択したエンジン
     */
    handleChangeEngine = async (value) => {
      await this.loadTrainConfig(value);
      this.generateParameterSelectBox();
      this.selectedEngine = value;
    };

    /**
     * レンダリング直後に一度だけ呼ばれる。
     */
    async componentDidMount() {
      const defaultEngine = ENGINE_VALUES[0].value;

      this.selectedEngine = defaultEngine;
      await this.loadTrainConfig(defaultEngine);
      this.generateParameterSelectBox();
    }

    /**
     * 学習パラメータ設定ダイアログをレンダリングする。
     */
    render() {
      const itemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };
      const { parameters, confirmLoading, errorMessage } = this.state;
      const { visible, form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          title="学習パラメータ設定"
          centered
          visible={visible}
          onCancel={this.handleClickCancel}
          onOk={this.handleClickOk}
          confirmLoading={confirmLoading}
          okText="学習開始"
          okButtonProps={{ block: true, style: { margin: '0px' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Form layout="horizontal">
            <Form.Item label="学習モデル名" {...itemLayout}>
              {getFieldDecorator('modelName')(<Input />)}
            </Form.Item>
            <Form.Item label="エンジン" {...itemLayout}>
              {
                getFieldDecorator('engine', { initialValue: ENGINE_VALUES[0].value })(
                  <Select onChange={this.handleChangeEngine} aria-label="engine">
                    {ENGINE_VALUES.map((engine, index) => <Option key={index} value={engine.value}>{engine.display}</Option>)}
                  </Select>,
                )
              }
            </Form.Item>
            {
              parameters.hasData &&
              <Form.Item label="パラメータ" {...itemLayout}>
                {
                  getFieldDecorator('parameter', { initialValue: parameters.defaultValue })(
                    parameters.selectBox,
                  )
                }
              </Form.Item>
            }
          </Form>
          {errorMessage && <ErrorMessages>{errorMessage}</ErrorMessages>}
        </Modal>
      );
    }
  },
);

export default SettingLearningParameterDialog;
