import React from 'react';
import { CognitePointCloudModel, CogniteCadModel } from '@cognite/reveal';
import { Button, Checkbox, Icon } from 'antd';
import { FilePoint, DetectionFilePoint, ScanPoint } from './types';

import './ControlPanel.css';

const ControlPanel: React.FC<{
  pointClouds: CognitePointCloudModel[],
  cadModels: CogniteCadModel[],
  filePoints: FilePoint[],
  detectionFilePoints: DetectionFilePoint[],
  scanPoints: ScanPoint[],
  onReloadRequest: () => void,
  onPointCloudsCheck: (checked: boolean) => void,
  onPointCloudClick: () => void,
  onCadModelsCheck: (checked: boolean) => void,
  onCadModelClick: () => void,
  onFilePointsCheck: (checked: boolean) => void
  onDetectionFilePointsCheck: (checked: boolean) => void
  onScanPointsCheck: (checked: boolean) => void
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [pointCloudsChecked, setPointCloudsChecked] = React.useState<boolean>(false);
  const [cadModelsChecked, setCadModelsChecked] = React.useState<boolean>(false);
  const [filesChecked, setFilesChecked] = React.useState<boolean>(false);
  const [detectionFilesChecked, setDetectionFilesChecked] = React.useState<boolean>(false);
  const [scansChecked, setScansChecked] = React.useState<boolean>(false);

  const {
    pointClouds,
    cadModels,
    filePoints,
    detectionFilePoints,
    scanPoints,
    onPointCloudsCheck,
    onCadModelsCheck,
    onFilePointsCheck,
    onDetectionFilePointsCheck,
    onScanPointsCheck,
  } = props;

  /*
   * イベントハンドラ
   */
  React.useEffect(
    () => {
      const checked = pointClouds.length > 0;
      setPointCloudsChecked(checked);
      onPointCloudsCheck(checked);
    },
    [pointClouds, onPointCloudsCheck],
  );

  React.useEffect(
    () => {
      const checked = cadModels.length > 0;
      setCadModelsChecked(checked);
      onCadModelsCheck(checked);
    },
    [cadModels, onCadModelsCheck],
  );

  React.useEffect(
    () => {
      const checked = filePoints.length > 0;
      setFilesChecked(checked);
      onFilePointsCheck(checked);
    },
    [filePoints, onFilePointsCheck],
  );

  React.useEffect(
    () => {
      const checked = detectionFilePoints.length > 0;
      setDetectionFilesChecked(checked);
      onDetectionFilePointsCheck(checked);
    },
    [detectionFilePoints, onDetectionFilePointsCheck],
  );

  React.useEffect(
    () => {
      const checked = scanPoints.length > 0;
      setScansChecked(checked);
      onScanPointsCheck(checked);
    },
    [scanPoints, onScanPointsCheck],
  );

  /*
   * メソッド
   */

  /*
   * 画面描画
   */
  return (
    <>
      <Button
        type="link"
        onClick={() => props.onReloadRequest()}
      >
        <Icon type="reload" />
      </Button>
      <Checkbox
        checked={pointCloudsChecked}
        onChange={(event) => {
          const { checked } = event.target;
          setPointCloudsChecked(checked);
          onPointCloudsCheck(checked);
        }}
        disabled={pointClouds.length === 0}
      >
        <Button
          type="link"
          onClick={(event) => {
            event.stopPropagation();
            props.onPointCloudClick();
          }}
          disabled={pointClouds.length === 0}
          style={{ padding: 0 }}
        >
          点群
        </Button>
      </Checkbox>
      <Checkbox
        checked={cadModelsChecked}
        onChange={(event) => {
          const { checked } = event.target;
          setCadModelsChecked(checked);
          onCadModelsCheck(checked);
        }}
        disabled={cadModels.length === 0}
      >
        <Button
          type="link"
          onClick={(event) => {
            event.stopPropagation();
            props.onCadModelClick();
          }}
          disabled={cadModels.length === 0}
          style={{ padding: 0 }}
        >
          メッシュ
        </Button>
      </Checkbox>
      <Checkbox
        checked={filesChecked}
        onChange={(event) => {
          const { checked } = event.target;
          setFilesChecked(checked);
          onFilePointsCheck(checked);
        }}
        disabled={filePoints.length === 0}
      >
        ファイル
      </Checkbox>
      <Checkbox
        checked={detectionFilesChecked}
        onChange={(event) => {
          const { checked } = event.target;
          setDetectionFilesChecked(checked);
          onDetectionFilePointsCheck(checked);
        }}
        disabled={detectionFilePoints.length === 0}
      >
        AI検出画像
      </Checkbox>
      <Checkbox
        checked={scansChecked}
        onChange={(event) => {
          const { checked } = event.target;
          setScansChecked(checked);
          onScanPointsCheck(checked);
        }}
        disabled={scanPoints.length === 0}
      >
        点検
      </Checkbox>
    </>
  );
};

export default ControlPanel;
