import BaseAsset from './BaseAsset';

/**
 * 太陽光PCSアセットクラス
 */
export default class SolarSitePcs extends BaseAsset {
  /**
   * クローンオブジェクト生成
   * @returns {SolarSitePcs} 太陽光サイトPCS情報
   */
  cloneSelf(): SolarSitePcs {
    const clone = new SolarSitePcs();
    Object.assign(clone, this);

    return clone;
  }

  // 未使用だが、BaseAsset継承で抽象メソッド定義があるため実体定義
  // eslint-disable-next-line class-methods-use-this
  async loadChildrenFromCDF(): Promise<SolarSitePcs[]> {
    return [];
  }
}
