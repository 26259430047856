import { CogniteEvent, CursorResponse, EventFilterRequest } from '@cognite/sdk';
import moment from 'moment';

import GenerationStatus from '../Asset/GenerationStatus';
import BaseEvent from './BaseEvent';
import { postApiGateway } from '../AWS/ApiGateway';
import { EP_PATH_SOLAR_EVENTS_LIST } from '../AWS/EndpointPath';
import { getAllEvents } from '../dataAccess';

/** 日付フォーマット */
const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

/**
 * 発電ステータス
 */
class ElectricEnergyStatus extends BaseEvent {
  /**
   * メンバ変数
   */
  get siteName(): string {
    if (!this.metadata || !this.metadata.site_name_jp) return '';
    return this.metadata.site_name_jp;
  }

  get pcsNo(): string {
    if (!this.metadata || !this.metadata.pcs_no) return '';
    return this.metadata.pcs_no;
  }

  get status(): string {
    if (!this.metadata || !this.metadata.status) return '';
    return this.metadata.status;
  }

  get dateForCsv(): string {
    if (!this.startTime || !this.startTime) return '';
    return moment(this.startTime).format(DATE_FORMAT);
  }

  /**
   * 検索条件に該当する発電ステータス履歴情報を取得する。
   * ※この関数ではnextCursorを用いた再帰処理を行わないため注意
   * @param {EventFilterRequest} eventFilter 検索条件
   * @returns {Promise<CursorResponse<ElectricEnergyStatus[]>>} 発電ステータス履歴情報
   */
  static async loadElectricEnergyByFilter(
    eventFilter: EventFilterRequest,
  ): Promise<CursorResponse<ElectricEnergyStatus[]>> {
    const generationStatus = await GenerationStatus.loadOneFromCDF();

    const customEventFilter: EventFilterRequest = {
      ...eventFilter,
      filter: {
        ...eventFilter.filter,
        assetIds: [generationStatus.id],
      },
    };
    const loadElectricEnergyStatuses = await postApiGateway<
      EventFilterRequest, CursorResponse<CogniteEvent[]>
    >(EP_PATH_SOLAR_EVENTS_LIST, customEventFilter);

    const electricEnergyStatuses = loadElectricEnergyStatuses.items.map(
      (loadElectricEnergyStatus) => new ElectricEnergyStatus(loadElectricEnergyStatus),
    );

    return {
      ...loadElectricEnergyStatuses,
      items: electricEnergyStatuses,
    };
  }

  /**
   * 検索条件に該当するすべての発電ステータス履歴情報を取得する。
   * @param {EventFilterRequest} eventFilter 検索条件
   * @returns {Promise<ElectricEnergyStatus[]>} 発電ステータス履歴情報
   */
  static async loadAllElectricEnergyByFilter(
    eventFilter: EventFilterRequest,
  ): Promise<ElectricEnergyStatus[]> {
    const generationStatus = await GenerationStatus.loadOneFromCDF();

    const customEventFilter = {
      ...eventFilter.filter,
      assetIds: [generationStatus.id],
    };

    const loadElectricEnergyStatuses = await getAllEvents(EP_PATH_SOLAR_EVENTS_LIST, customEventFilter);
    return loadElectricEnergyStatuses;
  }
}

export default ElectricEnergyStatus;
