import {
  FileInfo,
  FileChangeUpdate,
  CogniteInternalId,
} from '@cognite/sdk';

import {
  getApiGateway,
  getApiGatewayForImage,
  putApiGateway,
  ResponseCogniteOrError,
  ResponseCogniteError,
  RequestFileChangeUpdate,
  ResponseCogniteItems,
  RequestSpecifyID,
  deleteApiGateway,
  postApiGateway,
  ResponseDownloadURL,
} from '../AWS/ApiGateway';
import {
  EP_PATH_FACILITY_FILES, EP_PATH_FACILITY_FILES_ICON, EP_PATH_FACILITY_FILES_DOWNLOAD,
} from '../AWS/EndpointPath';

/**
 * 設備に紐づくファイルを取得する(ID指定)
 * @param id ファイルID
 * @returns ファイル情報
 */
const retrieveFacilityFile = async (id: CogniteInternalId): Promise<FileInfo> => {
  const endpoint = `${EP_PATH_FACILITY_FILES}/${id}`;
  const facilityFile = await getApiGateway<FileInfo | ResponseCogniteError>(endpoint);

  if ((facilityFile as ResponseCogniteError).error) {
    throw facilityFile;
  }

  return facilityFile as FileInfo;
};

/**
 * 設備に紐づくファイルのアイコンを取得する
 * @param fileId ファイルID
 * @returns アイコンデータ(base64形式の文字列)
 */
const getFacilityFilesIcon = async (fileId: CogniteInternalId): Promise<string> => {
  const getUrl = `${EP_PATH_FACILITY_FILES_ICON}/${fileId}`;
  const facilityFilesIcon = await getApiGatewayForImage(getUrl);
  return facilityFilesIcon;
};

/**
 * 設備に紐づくファイルのダウンロードURLを取得する
 * @param fileId ファイルID
 * @returns ダウンロードURL
 */
const getFacilityFileDownloadUrl = async (fileId: CogniteInternalId): Promise<string> => {
  const scope = { items: [{ id: fileId }] };
  const downloadInfos = await postApiGateway<
    RequestSpecifyID,
    ResponseDownloadURL | ResponseCogniteError
  >(
    EP_PATH_FACILITY_FILES_DOWNLOAD,
    scope,
  );

  if ((downloadInfos as ResponseCogniteError).error) {
    throw downloadInfos;
  }

  const downloadInfo = (downloadInfos as ResponseDownloadURL).items[0];
  return downloadInfo.downloadUrl;
};

/**
 * 設備に紐づくファイル情報を更新する。
 * @param change ファイル更新情報
 * @returns 更新後のファイル情報
 */
const updateFacilityFile = async (
  change: FileChangeUpdate,
): Promise<ResponseCogniteItems<FileInfo[]>> => {
  const updatedFiles = (
    await putApiGateway<RequestFileChangeUpdate, ResponseCogniteOrError<FileInfo[]>>(
      EP_PATH_FACILITY_FILES,
      { items: [change] },
    )
  );

  if ((updatedFiles as ResponseCogniteError).error) {
    throw updatedFiles;
  }

  return updatedFiles as ResponseCogniteItems<FileInfo[]>;
};

/**
 * 設備に紐づくファイルを削除する。
 * @param id ファイルID
 */
const deleteFacilityFile = async (id: CogniteInternalId): Promise<void> => {
  await deleteApiGateway<RequestSpecifyID>(EP_PATH_FACILITY_FILES, { items: [{ id }] });
};

export {
  retrieveFacilityFile,
  getFacilityFilesIcon,
  getFacilityFileDownloadUrl,
  updateFacilityFile,
  deleteFacilityFile,
};
