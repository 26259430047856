/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import {
  CogniteError,
  FileChangeUpdate,
  InternalId,
  ItemsWrapper,
} from '@cognite/sdk';
import env from '../../sdf-env.json';
import { retrieveAuthResult } from '../../components/AuthWrapper/persistance';

/**
 * リクエストヘッダー
 */
export type RequestHeader = {
  Authorization: string,
  project: string,
}

/** ファイルダウンロードURLリクエスト */
export type RequestSpecifyID = ItemsWrapper<InternalId[]>;
/** ファイル更新リクエスト */
export type RequestFileChangeUpdate = { items: FileChangeUpdate[] }
/** エラーレスポンス */
export type ResponseCogniteError = { error: CogniteError };
/** 正常レスポンス */
export type ResponseCogniteItems<ResponseType> = { items: ResponseType };
/** 正常レスポンス（空の場合） */
export type EmptyResponse = Record<string, never>;
/** レスポンス */
export type ResponseCogniteOrError<ResponseType> =
  ResponseCogniteItems<ResponseType> | ResponseCogniteError;
/** ダウンロード */
export type ResponseDownload = { items: [{ id: number }] };

export type ResponseDownloadURL = { items: [{ id: number, downloadUrl: string }] };
/** 機能権限取得 */
export type RequestFunctionAuthentication = { project: string };

export type ResponseFunctionAuthentication = ItemsWrapper<{
  userRole: string, actions: string[], accessibleScreenList: string[],
}>;

/** テナント情報取得 */
export type PostTenantInfo = { project: string };
export type ResponseTenantInfo = string[] | undefined;

/** ユーザー権限に対する道路プロジェクト取得 */
export type PostRoadProject = { project: string };
export type ResponseRoadProject = string[] | undefined;

/**
 * POSTメソッド
 * @param endpoint エンドポイント
 * @param scope 検索条件(CDFで定義されている型を想定)
 * @returns 取得した要素の配列
 */
const postApiGateway = async <T, E>(endpoint: string, scope: T): Promise<E> => {
  const requestHeader = createRequestHeader();
  const requestUrl = createRequestUrl(endpoint);
  const response = await fetch(requestUrl, {
    method: 'POST',
    headers: requestHeader,
    body: JSON.stringify(scope),
  });
  if (!response.ok) {
    // HTTP statusが200-299以外の場合
    throw new Error(String(response.status));
  }

  const responseData = await response.json();
  return responseData;
};

/**
 * GETメソッド
 * @param {string} endpoint エンドポイント
 * @param {{ ignoreUnknownIds: boolean }} retrieveParam 不明なIDを無視するかの判定
 * @returns 取得した要素の配列
 */
const getApiGateway = async <E>(endpoint: string, retrieveParam?: { ignoreUnknownIds: boolean })
  : Promise<E | undefined> => {
  const requestHeader = createRequestHeader();
  const requestUrl = createRequestUrl(endpoint);
  const response = await fetch(requestUrl, {
    method: 'GET',
    headers: requestHeader,
  });
  if (!response.ok) {
    // HTTP statusが200-299以外の場合
    throw new Error(String(response.status));
  }

  const responseData = await response.json();

  // 不明なIDを無視する場合
  if (responseData.error && retrieveParam?.ignoreUnknownIds) return undefined;

  return responseData;
};

/**
 * GETメソッド(画像データ用)
 * @param endpoint エンドポイント
 * @returns 画像データ(base64形式)
 */
const getApiGatewayForImage = async (endpoint: string): Promise<string> => {
  const requestHeader = createRequestHeader();
  const requestUrl = createRequestUrl(endpoint);

  const response = await fetch(requestUrl, {
    method: 'GET',
    headers: requestHeader,
  });
  if (!response.ok) {
    // HTTP statusが200-299以外の場合
    throw new Error(String(response.status));
  }

  // API Gatewayからbase64形式の文字列で返却される
  const responseData = await response.text();
  return responseData;
};

/**
 * PUTメソッド
 * @param endpoint エンドポイント
 * @param data 追加・更新条件(CDFで定義されている型を想定)
 * @returns 取得した要素の配列
 */
const putApiGateway = async <T, E>(endpoint: string, data: T): Promise<E> => {
  const requestHeader = createRequestHeader();
  const requestUrl = createRequestUrl(endpoint);

  const response = await fetch(requestUrl, {
    method: 'PUT',
    headers: requestHeader,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    // HTTP statusが200-299以外の場合
    throw new Error(String(response.status));
  }

  const responseData = await response.json();
  return responseData;
};

/**
 * DELETEメソッド
 * @param endpoint エンドポイント
 * @param data 削除条件(CDFで定義されている型を想定)
 */
const deleteApiGateway = async <T>(endpoint: string, data: T): Promise<void> => {
  const requestHeader = createRequestHeader();
  const requestUrl = createRequestUrl(endpoint);

  const response = await fetch(requestUrl, {
    method: 'DELETE',
    headers: requestHeader,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    // HTTP statusが200-299以外の場合
    throw new Error(String(response.status));
  }
};

/**
 * リクエストヘッダーを作成する
 * @returns リクエストヘッダー
 */
const createRequestHeader = (): RequestHeader => {
  const authResult = retrieveAuthResult();
  const { project, awsIdToken } = authResult;

  const headers = {
    Authorization: awsIdToken,
    project,
  };
  return headers;
};

/**
 * リクエストURLを作成する。
 * @param endpoint エンドポイント
 * @returns リクエストURL
 */
const createRequestUrl = (endpoint: string): string => {
  const { region, apiGateway } = env.awsConfig;
  const { gatewayId, stage } = apiGateway;
  const envStage = process.env.REACT_APP_STAGE ?? stage;
  const envProductionUrl = process.env.REACT_APP_PRODUCTION ?? '';

  if (envProductionUrl !== '') {
    const requestProductionUrl = `${envProductionUrl}${envStage}${endpoint}`;
    return requestProductionUrl;
  }
  const requestUrl = `https://${gatewayId}.execute-api.${region}.amazonaws.com/${envStage}${endpoint}`;
  return requestUrl;
};

export {
  postApiGateway,
  getApiGateway,
  getApiGatewayForImage,
  putApiGateway,
  deleteApiGateway,
  createRequestHeader,
  createRequestUrl,
};
