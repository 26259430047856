import React, { ChangeEvent, useState, useEffect } from 'react';
import { Timestamp } from '@cognite/sdk';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Input,
  Row,
  Tooltip,
  Typography,
  message,
} from 'antd';
import moment, { Moment } from 'moment';

import ActivityCheckHistoryTable from './ActivityCheckHistoryTable';
import ElectricEnergyOccurrenceDates from './parts/ElectricEnergyOccurrenceDates';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import ActivityCheckHistory from '../../utils/Event/ActivityCheckHistory';
import { generateCsvData } from '../../utils/File/CsvFile';
import {
  ERROR_NOT_ENTERED_SEARCH_CONDITION,
  ERROR_SEARCH_RESULT_OVER_LIMIT,
  ERROR_NO_AUTH_MESSAGE,
  ERROR_FILE_DOWNLOAD,
  INFORMATION_NO_SEARCH_RESULTS,
} from '../../utils/messages';

import './ActivityCheckList.css';

const { Title } = Typography;

/**
 * 文字列項目のソート判定を実施する
 * @param {string} a 文字列項目A
 * @param {string} b 文字列項目B
 * @returns {number} ソートの判定結果
 */
const sortStringItems = (a: string, b: string) => {
  const first = [a, b].sort()[0];
  return first === a ? -1 : 1;
};

/**
 * 死活監視結果の一覧をソートする。
 * 1. 発生日時: 降順
 * 2. GMU-ID、サイト名: 昇順
 * （GMU-IDとサイト名（message）は1:1の関係にあるため、サイト名それ自体でのソートは実施しないこととする
 * @param {ActivityCheckHistory[]} targetEvents ソート対象の死活監視結果の一覧
 * @returns {ActivityCheckHistory[]} ソート後の死活監視結果の一覧
 */
const sortActivityCheckHistoryEvents = (
  targetEvents: ActivityCheckHistory[],
): ActivityCheckHistory[] => (
  [...targetEvents].sort((a, b) => {
    const startDateA = a.dateForDisplay;
    const startDateB = b.dateForDisplay;

    if (!startDateA) {
      return 1;
    }
    if (!startDateB) {
      return -1;
    }

    if (startDateA !== startDateB) {
      return sortStringItems(startDateB, startDateA);
    }

    return sortStringItems(a.gmuId, b.gmuId);
  })
);

/**
 * 死活監視結果一覧画面
 * @returns 死活監視結果一覧画面コンポーネント
 */
const ActivityCheckList: React.FC = () => {
  const [occurrenceDates, setOccurrenceDates] = useState<[Moment, Moment] | []>([]);
  const [gmuId, setGmuId] = useState<string>('');
  const [idSiteName, setIdSiteName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [historyEvents, setHistoryEvents] = useState<ActivityCheckHistory[]>([]);
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState<boolean>(true);
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    let canceled = false;
    (async () => {
      // 直近100件の死活監視結果イベント取得
      const latestHistoryEvents = await ActivityCheckHistory.loadLatestActivityCheckHistoryEvents()
        .then((events) => sortActivityCheckHistoryEvents(events));
      // 検索、ダウンロードボタンの操作権限ロール取得
      const {
        ACTIVITY_CHECK_LIST: { ACTIVITY_CHECK_LIST_SEARCH_BUTTON, ACTIVITY_CHECK_LIST_DOWNLOAD_BUTTON },
      } = AUTHENTICATION_TYPE_MATRIX;

      if (!canceled) {
        setHistoryEvents(latestHistoryEvents);
        setIsSearchButtonDisabled(!await containsUIAuthType(ACTIVITY_CHECK_LIST_SEARCH_BUTTON));
        setIsDownloadButtonDisabled(!await containsUIAuthType(ACTIVITY_CHECK_LIST_DOWNLOAD_BUTTON));
        setLoading(false);
      }
    })();

    return () => { canceled = true; };
  }, []);

  /**
   * 発生日時選択時のイベントハンドラ
   * @param {[] | [moment.Moment, moment.Moment]} dates 選択した発生日時
   */
  const handleChangeOccurrenceDates = (dates: [] | [moment.Moment, moment.Moment]): void => {
    setOccurrenceDates(dates);
  };

  /**
   * GMU-ID入力時のイベントハンドラ
   * @param {ChangeEvent<HTMLInputElement>} e 入力イベント
   */
  const handleChangeGmuId = (e: ChangeEvent<HTMLInputElement>): void => {
    setGmuId(e.currentTarget.value);
  };

  /**
   * サイト名入力時のイベントハンドラ
   * @param {ChangeEvent<HTMLInputElement>} e 入力イベント
   */
  const handleChangeSiteName = (e: ChangeEvent<HTMLInputElement>): void => {
    setIdSiteName(e.currentTarget.value);
  };

  /**
   * 検索ボタンクリック時のイベントハンドラ
   */
  const handleClickSearch = async (): Promise<void> => {
    setLoading(true);

    if (!occurrenceDates.length && !gmuId && !idSiteName) {
      message.error(ERROR_NOT_ENTERED_SEARCH_CONDITION);
      setLoading(false);
      return;
    }

    let datesAsTimestamp;
    if (occurrenceDates.length) {
      const [min, max] = occurrenceDates;
      datesAsTimestamp = [min.valueOf(), max.valueOf()] as [Timestamp, Timestamp];
    }
    const searchResults = await ActivityCheckHistory.loadActivityCheckHistoryEventsByFilter(
      datesAsTimestamp,
      gmuId,
      idSiteName,
    ).then((events) => sortActivityCheckHistoryEvents(events));

    if (!searchResults.length) {
      message.info(INFORMATION_NO_SEARCH_RESULTS);
      setHistoryEvents([]);
      setLoading(false);
      return;
    }

    if (searchResults.length > 1000) {
      message.error(ERROR_SEARCH_RESULT_OVER_LIMIT);
      setLoading(false);
      return;
    }

    setHistoryEvents(searchResults);
    setLoading(false);
  };

  /**
   * ダウンロードボタンクリック時のイベントハンドラ
   */
  const handleClickDownload = async (): Promise<void> => {
    setDownloading(true);

    if (!occurrenceDates.length && !gmuId && !idSiteName) {
      message.error(ERROR_NOT_ENTERED_SEARCH_CONDITION);
      setDownloading(false);
      return;
    }

    let datesAsTimestamp;
    if (occurrenceDates.length) {
      const [min, max] = occurrenceDates;
      datesAsTimestamp = [min.valueOf(), max.valueOf()] as [Timestamp, Timestamp];
    }

    const searchResults = await ActivityCheckHistory.loadActivityCheckHistoryEventsByFilter(
      datesAsTimestamp,
      gmuId,
      idSiteName,
    ).then((events) => sortActivityCheckHistoryEvents(events));

    if (!searchResults.length) {
      message.info(INFORMATION_NO_SEARCH_RESULTS);
      setDownloading(false);
      return;
    }

    const header = [
      { key: 'dateForDisplay', headerString: '発生日時' },
      { key: 'gmuId', headerString: 'GMU-ID' },
      { key: 'message', headerString: 'サイト名' },
    ];

    const csvData = generateCsvData(header, searchResults);
    const aTagForDownload = document.createElement('a');
    let url = '';
    try {
      url = URL.createObjectURL(csvData);
      document.body.appendChild(aTagForDownload);
      aTagForDownload.href = url;
      aTagForDownload.download = `SDF_死活監視結果_${moment().format('YYYYMMDDHHmmss')}.csv`;
      aTagForDownload.click();
    } catch (e) {
      message.error(ERROR_FILE_DOWNLOAD);
    } finally {
      aTagForDownload.remove();
      URL.revokeObjectURL(url);
    }
    setDownloading(false);
  };

  return (
    <div className="activity-check-list-container">
      <div className="activity-check-list-title">
        <Title level={4}>死活監視結果一覧</Title>
      </div>

      <Divider className="activity-check-list-divider" />

      <Row>
        <Col span={20} offset={1}>
          <Descriptions bordered>
            <Descriptions.Item label="発生日時" span={3}>
              <ElectricEnergyOccurrenceDates onChange={handleChangeOccurrenceDates} />
            </Descriptions.Item>
            <Descriptions.Item label="GMU-ID" span={3}>
              <Input
                id="gmu-id"
                allowClear
                value={gmuId}
                onChange={handleChangeGmuId}
              />
            </Descriptions.Item>
            <Descriptions.Item label="サイト名" span={3}>
              <Input
                id="gmu-id-site-name"
                allowClear
                value={idSiteName}
                onChange={handleChangeSiteName}
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={17} offset={1}>
          <span className="activity-check-list-annotation">*</span>
          検索条件は1つ以上選択してください。
        </Col>
        <Col span={4}>
          <Tooltip title={isSearchButtonDisabled ? ERROR_NO_AUTH_MESSAGE : '検索'}>
            <Button
              type="primary"
              id="activity-check-list-search-button"
              onClick={handleClickSearch}
              style={{ marginTop: 16, marginBottom: 16 }}
              loading={loading}
              disabled={isSearchButtonDisabled}
            >
              検索
            </Button>
          </Tooltip>
          <Tooltip title={isDownloadButtonDisabled ? ERROR_NO_AUTH_MESSAGE : 'ダウンロード'}>
            <Button
              type="primary"
              id="activity-check-list-download-button"
              onClick={handleClickDownload}
              style={{ marginLeft: 15 }}
              loading={downloading}
              disabled={downloading || isDownloadButtonDisabled}
            >
              ダウンロード
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <>
        <ActivityCheckHistoryTable
          loading={loading}
          historyEvents={historyEvents}
        />
      </>
    </div>
  );
};

export default ActivityCheckList;
