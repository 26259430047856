import React from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { ELECTRIC_ENERGY_STATUS } from './parts/ElectricEnergyStatusSelect';
import { OM_STATUS } from './parts/OMStatusSelect';
import ElectricEnergyComparisonStatus from '../../utils/Event/ElectricEnergyComparisonStatus';
import './SearchedElectricEnergyComparisonEventTable.css';

interface SearchedEventListProps {
  period: string;
  loading: boolean;
  searchedEventList: ElectricEnergyComparisonStatus[];
}

/**
 * ステータスの表示名変換
 * @param {string} statusNo ステータス番号
 * @param {string} period 'hourly'または'daily'
 * @returns {string} ステータス名
 */
const statusChange = (
  status: string,
  period: string,
) => {
  const statusTypes = period === 'hourly' ? ELECTRIC_ENERGY_STATUS : OM_STATUS;
  const result = statusTypes.find((el) => String(el.value) === status);
  return result?.displayName;
};

/**
 * 検索済みイベント一覧テーブル
 * @param {SearchedEventListProps} props プロパティ
 * @returns 検索済みイベント一覧テーブル
 */
const SearchedElectricEnergyComparisonEventTable: React.FC<SearchedEventListProps> = (
  props: SearchedEventListProps,
) => {
  const {
    period,
    loading,
    searchedEventList,
  } = props;

  /**
   * ソートして返却(String)
   * @param {string| undefined} a
   * @param {string| undefined} b
   * @returns {number} ソートの判定結果
   */
  const getSortedItems = (a?: string, b?: string) => {
    const first = [a, b].sort()[0];
    return first === a ? -1 : 1;
  };

  const columns: ColumnProps<ElectricEnergyComparisonStatus>[] = [
    {
      title: '診断日時',
      dataIndex: 'startTime',
      width: 170,
      sorter: (a, b) => getSortedItems(a.dateForDisplay, b.dateForDisplay),
      render: (_, item: ElectricEnergyComparisonStatus) => item.dateForDisplay,
    },
    {
      title: 'サイト名',
      dataIndex: 'site_name_jp',
      width: 170,
      sorter: (a, b) => getSortedItems(a.siteName, b.siteName),
      render: (_, item: ElectricEnergyComparisonStatus) => item.siteName,
    },
    {
      title: 'PCS番号',
      dataIndex: 'pcs_no',
      width: 170,
      sorter: (a, b) => Number(a.pcsNo) - Number(b.pcsNo),
      render: (_, item: ElectricEnergyComparisonStatus) => item.pcsNo,
    },
    {
      title: period === 'hourly' ? 'ステータス' : '診断結果', // 一時間判定の場合は「ステータス」、一日判定の場合は「診断結果」
      dataIndex: 'status',
      width: 170,
      sorter: (a, b) => getSortedItems(a.status, b.status),
      render: (_, item: ElectricEnergyComparisonStatus) => statusChange(item.status, props.period),
    },
  ];

  return (
    <>
      <Table<ElectricEnergyComparisonStatus>
        loading={loading}
        dataSource={searchedEventList}
        pagination={{
          className: 'searched-event-table-pagination',
          position: 'bottom',
          pageSize: 100,
        }}
        columns={columns}
        rowKey="id"
        scroll={{ y: 616 }}
      />
    </>
  );
};

export default SearchedElectricEnergyComparisonEventTable;
