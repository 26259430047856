import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Row,
  Table,
  Tooltip,
} from 'antd';
import { ColumnProps, SorterResult } from 'antd/lib/table';

import OMPcsMeasurement from '../../../utils/Asset/OMPcsMeasurement';

import './PcsMeasurementEventList.css';

interface PcsMeasurementListProps {
  areaAssetId: number;
  handleDisplayAlert: () => void;
  displayReload: number;
}

/**
 * ソートして返却（ソートキー）
 * @param {OMPcsMeasurement} a 比較対象PCS計測値データ（一つ目）
 * @param {OMPcsMeasurement} b 比較対象PCS計測値データ（二つ目）
 * @returns {number} 比較結果
*/
const getSortedItemsByKey = (a: OMPcsMeasurement, b: OMPcsMeasurement): number => {
  const keysA = a.sortKey.split('-').map((key) => Number(key));
  const keysB = b.sortKey.split('-').map((key) => Number(key));

  if (keysA.length < keysB.length) return -1;
  if (keysA.length > keysB.length) return 1;

  for (let i = 0; i < keysA.length; i++) {
    const keyA = keysA[i];
    const keyB = keysB[i];

    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
  }

  return 0;
};

/**
 * ソートして返却（文字列）
 * @param {string} a
 * @param {string} b
 * @returns {number} ソートの判定結果
 */
const getSortedItems = (a: string, b: string): number => {
  const first = [a, b].sort()[0];
  return first === a ? -1 : 1;
};

/**
 * ソートして返却（数値）
 * @param {OMPcsMeasurement} a 比較対象PCS計測値データ（一つ目）
 * @param {OMPcsMeasurement} b 比較対象PCS計測値データ（二つ目）
 * @param {string} property ソート対象の項目名
 * @returns {number} ソートの判定結果
 */
const getSortedNumberItems = (a: OMPcsMeasurement, b: OMPcsMeasurement, property: string): number => {
  const propertyA = a[property as keyof OMPcsMeasurement] as number;
  const propertyB = b[property as keyof OMPcsMeasurement] as number;

  // 値が同一の場合はソートキーを元に配置
  if (propertyA === propertyB) {
    return getSortedItemsByKey(a, b);
  }
  return propertyA - propertyB;
};

/**
 * PCS一覧画面のコンポーネント
 * @param {PcsMeasurementListProps} props プロパティ
 * @returns PCS一覧画面
 */
const PcsMeasurementEventList: React.FC<PcsMeasurementListProps> = (props: PcsMeasurementListProps) => {
  const { areaAssetId, handleDisplayAlert, displayReload } = props;

  const [pcsMeasurementList, setPcsMeasurementList] = useState<OMPcsMeasurement[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  // 一覧画面でのソート状態保持用のstate変数
  const [sortInfo, setSortInfo] = useState<SorterResult<OMPcsMeasurement> | null>(null);

  // Tableの内容
  const columns: ColumnProps<OMPcsMeasurement>[] = [
    {
      title: 'フィーダNo',
      dataIndex: 'feederNumber',
      width: 140,
      sorter: (a, b) => getSortedNumberItems(a, b, 'feeder'),
      sortOrder: sortInfo?.columnKey === 'feederNumber' && sortInfo.order,
      render: (_, item: OMPcsMeasurement) => {
        const value = item.feeder;
        const uniqueKey = `feederNumber_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: 'PCS',
      dataIndex: 'pcsName',
      width: 140,
      sorter: (a, b) => getSortedItemsByKey(a, b),
      sortOrder: sortInfo?.columnKey === 'pcsName' && sortInfo.order,
      render: (_, item: OMPcsMeasurement) => {
        const value = item.pcsName;
        const uniqueKey = `pcsName_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '発電電力[kW]',
      dataIndex: 'acPower',
      width: 200,
      sorter: (a, b) => getSortedNumberItems(a, b, 'acPower'),
      sortOrder: sortInfo?.columnKey === 'acPower' && sortInfo.order,
      render: (_, item: OMPcsMeasurement) => {
        const value = item.acPower;
        const uniqueKey = `acPower_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '出力電流[A]',
      dataIndex: 'acCurrent',
      width: 200,
      sorter: (a, b) => getSortedNumberItems(a, b, 'acCurrent'),
      sortOrder: sortInfo?.columnKey === 'acCurrent' && sortInfo.order,
      render: (_, item: OMPcsMeasurement) => {
        const value = item.acCurrent;
        const uniqueKey = `acCurrent_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '直流電圧[V]',
      dataIndex: 'dcVoltage',
      width: 200,
      sorter: (a, b) => getSortedNumberItems(a, b, 'dcVoltage'),
      sortOrder: sortInfo?.columnKey === 'dcVoltage' && sortInfo.order,
      render: (_, item: OMPcsMeasurement) => {
        const value = item.dcVoltage;
        const uniqueKey = `dcVoltage_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '直流電流[A]',
      dataIndex: 'dcCurrent',
      width: 200,
      sorter: (a, b) => getSortedNumberItems(a, b, 'dcCurrent'),
      sortOrder: sortInfo?.columnKey === 'dcCurrent' && sortInfo.order,
      render: (_, item: OMPcsMeasurement) => {
        const value = item.dcCurrent;
        const uniqueKey = `dcCurrent_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '故障情報',
      dataIndex: 'hasAlertState',
      width: 120,
      sorter: (a, b) => getSortedItems(String(a.happening), String(b.happening)),
      sortOrder: sortInfo?.columnKey === 'hasAlertState' && sortInfo.order,
      render: (_, item: OMPcsMeasurement) => {
        const value = item.happening && '発生';
        const uniqueKey = `hasAlertState_${item.id}`;
        return value && (
          <Tooltip title={value} key={uniqueKey}>
            <Button className="om-dashboard-pcs-measurement-occurrence-button" onClick={handleDisplayAlert}>{value}</Button>
          </Tooltip>
        );
      },
    },
  ];

  /*
   * イベントハンドラ
   */

  useEffect(
    () => {
      setLoading(true);

      if (!areaAssetId) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        const pcsMeasurements = await OMPcsMeasurement.loadPcsMeasurements(areaAssetId)
          .then((result) => result.sort(getSortedItemsByKey));

        if (!canceled) {
          setPcsMeasurementList(pcsMeasurements);
          setLoading(false);
        }
      })();

      return () => { canceled = true; };
    },
    [areaAssetId, displayReload],
  );

  /**
   * 「ソート解除」ボタンをクリックした時のイベントハンドラ
   * @param {React.MouseEvent} e ボタンのクリックイベント
   */
  const handleClickSortReset = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.currentTarget.blur();
    setSortInfo(null);
  };

  return (
    <div>
      <Row type="flex" justify="end" style={{ paddingBottom: '1%' }}>
        <Col span={3} className="om-dashboard-pcs-button-column">
          <Button
            className="om-dashboard-pcs-measurement-list-button"
            key="reset_sort"
            title="ソート解除"
            onClick={handleClickSortReset}
          >
            ソート解除
          </Button>
        </Col>
      </Row>
      <Table<OMPcsMeasurement>
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={pcsMeasurementList}
        onChange={(_, __, sorter) => setSortInfo(sorter)}
        pagination={false}
        rowClassName={(item) => `${item.feeder}`}
        scroll={{ y: 570 }}
      />
    </div>
  );
};

export default PcsMeasurementEventList;
