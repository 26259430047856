import React from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { OM_STATUS } from './parts/OMStatusSelect';
import PcsComparisonStatus from '../../utils/Event/PcsComparisonStatus';
import './SearchedPcsEventTable.css';

interface SearchedPcsEventListProps {
  loading: boolean;
  searchedPcsEventList: PcsComparisonStatus[];
}

/**
 * ステータスの表示名変換
 * @param {string | undefined} status ステータス番号
 * @return {string | undefined} ステータスの表示名
 */
const statusChange = (status?: string) => {
  const result = OM_STATUS.find((el) => String(el.value) === status);
  return result?.displayName;
};

/**
 * 検索済みイベント一覧テーブル
 * @param {SearchedPcsEventListProps} props プロパティ
 * @returns 検索済みイベント一覧テーブル
 */
const SearchedPcsEventTable: React.FC<SearchedPcsEventListProps> = (
  props: SearchedPcsEventListProps,
) => {
  const {
    loading,
    searchedPcsEventList,
  } = props;

  /**
   * 文字列項目のソート判定を実施する
   * @param {string | undefined} a 文字列項目A
   * @param {string | undefined} b 文字列項目B
   * @returns {number} ソートの判定結果
   */
  const getSortedItems = (a?: string, b?: string) => {
    const first = [a, b].sort()[0];
    return first === a ? -1 : 1;
  };

  const columns: ColumnProps<PcsComparisonStatus>[] = [
    {
      title: '診断日時',
      dataIndex: 'startTime',
      width: 170,
      sorter: (a, b) => getSortedItems(a.dateForDisplay, b.dateForDisplay),
      render: (_, item: PcsComparisonStatus) => item.dateForDisplay,
    },
    {
      title: 'サイト名',
      dataIndex: 'site_name_jp',
      width: 170,
      sorter: (a, b) => getSortedItems(a.siteName, b.siteName),
      render: (_, item: PcsComparisonStatus) => item.siteName,
    },
    {
      title: 'PCS番号',
      dataIndex: 'pcs_no',
      width: 170,
      sorter: (a, b) => Number(a.pcsNo) - Number(b.pcsNo),
      render: (_, item: PcsComparisonStatus) => item.pcsNo,
    },
    {
      title: '診断結果',
      dataIndex: 'status',
      width: 170,
      sorter: (a, b) => getSortedItems(a.status, b.status),
      render: (_, item: PcsComparisonStatus) => statusChange(item.status),
    },
  ];

  return (
    <>
      <Table<PcsComparisonStatus>
        loading={loading}
        dataSource={searchedPcsEventList}
        pagination={{
          className: 'searched-pcs-event-table-pagination',
          position: 'bottom',
          pageSize: 100,
        }}
        columns={columns}
        rowKey="id"
        scroll={{ y: 616 }}
      />
    </>
  );
};

export default SearchedPcsEventTable;
