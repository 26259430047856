import { deleteApiGateway, postApiGateway, putApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_GROUPS_CREATE,
  EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_GROUPS_DELETE,
  EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_GROUPS_LIST,
  EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_GROUPS_UPDATE,
} from '../AWS/EndpointPath';

export interface SendParameter {
  groupName: string,
  id: string,
  addressList: string[],
  created: number,
  lastUpdated: number,
  oldGroupName: string,
}

class EmailGroup {
  /**
   * 「email-group-list」テーブルのレコードを全て取得する
   * @returns {Promise<EmailGroup[]>} 検索結果
   */
  static async getAllGroups(): Promise<EmailGroup[]> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_GROUPS_LIST;
    const allEmailGroupList = await postApiGateway(endpointPath, {}) as EmailGroup[];
    return allEmailGroupList;
  }

  /**
   * 「email-group-list」テーブルに新規レコードを1件追加する
   * @param {SendParameter} parameter 追加する新規レコードの情報
   */
  static async createGroup(parameter: SendParameter): Promise<void> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_GROUPS_CREATE;
    await postApiGateway(endpointPath, parameter);
  }

  /**
   * 「email-group-list」テーブルの既存レコードを1件更新する
   * @param {SendParameter} parameter 更新する既存レコードの情報と更新内容
   */
  static async updateGroup(parameter: SendParameter): Promise<void> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_GROUPS_UPDATE;
    await putApiGateway(endpointPath, parameter);
  }

  /**
   * 「email-group-list」テーブルの既存レコードを1件削除する
   * @param {EmailGroup} parameter 削除する既存レコードの情報
   */
  static async deleteGroup(parameter: EmailGroup): Promise<void> {
    const endpointPath = EP_PATH_SOLAR_EGS_PROCESSES_EMAIL_SETTINGS_GROUPS_DELETE;
    await deleteApiGateway(endpointPath, parameter);
  }

  groupName: string;

  id: string;

  addressList: string[];

  created: number;

  lastUpdated: number;

  constructor() {
    this.groupName = '';
    this.id = '';
    this.addressList = [];
    this.created = 0;
    this.lastUpdated = 0;
  }
}
export default EmailGroup;
