import React from 'react';
import { Tabs } from 'antd';
import ActivityCheckIntervalSetting from './ActivityCheckIntervalSetting';
import StandardPcsSetting from './StandardPcsSetting';
import './DiagnosisSettingTabs.css';

const { TabPane } = Tabs;

interface TabInfo {
  title: string;
  key: string;
  displayScreen: JSX.Element;
}

const allTabNames: TabInfo[] = [
  { title: '死活監視判定間隔設定', key: 'activity-check-interval-tab', displayScreen: <ActivityCheckIntervalSetting /> },
  { title: 'PCS比較診断の基準PCS設定', key: 'base-pcs-setting-tab', displayScreen: <StandardPcsSetting /> },
];

/**
 * 「診断関連設定」を押した際に出てくる「死活監視判定間隔設定」タブ ※後に他の設定も追加する予定
 * @returns {React.FC} タブコンポーネント
 */
const DiagnosisSettingTabs: React.FC = () => (
  <div className="diagnosis-setting-tabs-background-color">
    <Tabs defaultActiveKey={allTabNames[0].key} className="diagnosis-setting-tabs">
      {allTabNames.map(({ title, key, displayScreen }) => (
        <TabPane tab={title} key={key}>{displayScreen}</TabPane>
      ))}
    </Tabs>
  </div>
);
export default DiagnosisSettingTabs;
