import React, { useEffect, useState } from 'react';
import {
  Col, message, Modal,
} from 'antd';

import { UnknownFile, MimeType } from '../../../utils/File/BaseFile';
import { ResourceType, getFileDownloadEndpoint } from '../../../utils/common/SDFDataType';
import { retrieveFile } from '../../../utils/AWS/AWSRequest';
import { DownloadButton } from './parts/DownloadButton';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../../utils/common/Authentication';
import {
  ERROR_FILES_RETRIEVE,
  ERROR_FILES_GET_DL_URL,
  ERROR_FILE_PREVIEW,
} from '../../../utils/messages';
import './FileDetailViewModal.css';

const MODAL_WIDTH = 1500;
const MODAL_HEIGHT = 948;
const MODAL_HEIGHT_WITH_DL = 992;

/**
 * 財務帳票viewer
 */
const FinancialReportFileDetailViewModal: React.FC<{
  visible: boolean,
  fileId?: number,
  title?: string,
  onClose: () => void
}> = (props) => {
  /*
   * 変数/定数宣言
   */
  const [content, setContent] = useState<React.ReactNode>(undefined);
  const [isFileLoaded, setIsFileLoaded] = useState<boolean>(false);
  const [downloadFileId, setDownloadFileId] = useState<number | null>(null);
  const [isModalDownloadButtonDisabled, setIsModalDownloadButtonDisabled] = useState<boolean>(true);

  const {
    visible,
    fileId,
    title,
    onClose,
  } = props;

  /*
   * イベントハンドラ
   */
  useEffect(() => {
    if (!visible) return () => { /* 何もしない */ };

    let canceled = false;
    setContent(undefined);
    setDownloadFileId(null);

    (async () => {
      const {
        SOLAR_MENU: {
          FINANCIAL_REPORT_SEARCH: {
            FINANCIAL_REPORT_FILE_DOWNLOAD,
          },
        },
      } = AUTHENTICATION_TYPE_MATRIX;

      if (!canceled) {
        setIsModalDownloadButtonDisabled(!await containsUIAuthType(FINANCIAL_REPORT_FILE_DOWNLOAD));
      }

      if (fileId === undefined) {
        return;
      }

      let file = null;

      try {
        file = await retrieveFile(ResourceType.SolarSite, fileId);
        if (!file) { throw new Error(); }
      } catch (e) {
        message.error(ERROR_FILES_RETRIEVE);
        return;
      }
      // ファイル読み込み用
      const newFile = UnknownFile.getInstance();
      newFile.id = fileId;

      let fileContent;
      let errorFileFlag = false;
      const { Pdf } = MimeType;

      try {
        // PDFではない場合、プレビュー不可メッセージを表示
        if (file.mimeType !== Pdf) {
          fileContent = (<p className="contain-modal-unknown">{ERROR_FILE_PREVIEW}</p>);
        } else {
          const endpoint = getFileDownloadEndpoint(ResourceType.SolarSite);
          const downloadUrl = await newFile.loadDownloadURLFromCDF(endpoint);
          if (!downloadUrl) { throw new Error(); }
          const response = await fetch(downloadUrl);
          if (!response.ok) {
            errorFileFlag = true;
          } else {
            const contents = await response.blob();
            const createUrl = URL.createObjectURL(contents);
            fileContent = (<object data={createUrl} aria-label={file.name} className="contain-modal-pdf" />);
          }
        }
      } catch (e) {
        errorFileFlag = true;
      } finally {
        if (!canceled) {
          if (errorFileFlag) {
            // downloadURL取得失敗時はエラーメッセージを表示
            message.error(ERROR_FILES_GET_DL_URL);
          } else {
            setDownloadFileId(fileId);
          }
          setContent(fileContent);
          setIsFileLoaded(true);
        }
      }
    })();
    return () => { canceled = true; };
  }, [fileId, visible]);

  /*
   * 画面描画
   */
  const modalHeight = isFileLoaded ? MODAL_HEIGHT_WITH_DL : MODAL_HEIGHT;
  return (
    <>
      <Modal
        title={title}
        visible={visible}
        onCancel={() => {
          onClose();
        }}
        centered
        width={MODAL_WIDTH}
        footer={null}
        style={{ margin: '0', padding: '0', textAlign: 'center' }}
        bodyStyle={{ width: `${MODAL_WIDTH}px`, height: `${modalHeight}px` }}
      >
        <Col>
          {content}
          {
            isFileLoaded && (
              <>
                <DownloadButton
                  fileId={downloadFileId}
                  resourceType={ResourceType.SolarSite}
                  isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                />
              </>
            )
          }
        </Col>
      </Modal>
    </>
  );
};

export default FinancialReportFileDetailViewModal;
