import React from 'react';
import {
  Layout, Col, Row, message, Typography,
} from 'antd';
import { ImagesListViewer, LIST_TYPE } from '../util/ImagesListViewer';
import { FileDetailView } from '../Common/File/FileDetailView';
import AssetTree from '../Common/Asset/AssetTree';
import SettingDetectParameterDialog from './SettingDetectParameterDialog';
import { getStorageParentAssetId } from '../../utils/storageCommon';
import { getResourceType, ResourceType } from '../../utils/common/SDFDataType';
import {
  VALIDATE_ERROR_NO_SELECT_IMAGE,
  SUCCESS_ADD_DETECT_TASK,
} from '../../utils/messages';
import { DETECT } from '../Common/File/FileTable';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';
import { isReadOnlyPermission } from '../../utils/common/CacheApi';

const { Content } = Layout;
const { Title } = Typography;

/**
 * 検出画面component
 * @property {object} client CogniteClient
 * @property {function} setLoadingFlag Load Flag設定関数
 */
class Detect extends React.Component {
  state = {
    parentAssetsId: null,
    selectedAssetID: null,
    selectedAssetTitle: null,
    selectedResourceType: null,
    selectedFileId: null,
    settingParameterDialogVisible: false,
    checkFileIds: [],
    isReloadDetectDisabled: true,
    isReadDetectDisabled: true,
    isModalEditButtonDisabled: true,
    isModalDownloadButtonDisabled: true,
    isReadOnly: true,
  };

  /**
   * component render前の処理
   */
  async componentDidMount() {
    this.props.setLoadingFlag(true);
    const parentAssetsId = getStorageParentAssetId();

    const { EQUIPMENT_DETAILS_EQ, AI_DETECT } = AUTHENTICATION_TYPE_MATRIX;
    const { EXPANDED_MODAL_EDIT_BUTTON } = EQUIPMENT_DETAILS_EQ;
    const {
      EXPANDED_MODAL_DOWNLOAD_BUTTON,
      EQUIPMENT_LIST_UPDATE_BUTTON,
      EQUIPMENT_LIST_EQUIPMENT_LINK,
    } = AI_DETECT;

    this.setState({
      parentAssetsId,
      isModalEditButtonDisabled: !await containsUIAuthType(EXPANDED_MODAL_EDIT_BUTTON),
      isModalDownloadButtonDisabled: !await containsUIAuthType(EXPANDED_MODAL_DOWNLOAD_BUTTON),
      isReloadDetectDisabled: !await containsUIAuthType(EQUIPMENT_LIST_UPDATE_BUTTON),
      isReadDetectDisabled: !await containsUIAuthType(EQUIPMENT_LIST_EQUIPMENT_LINK),
      isReadOnly: await isReadOnlyPermission(),
    });
    this.props.setLoadingFlag(false);
  }

  /**
   * Asset選択時のイベントハンドラ
   * @param {object} asset 選択されたAsset
   */
  onSelectAsset = async (asset) => {
    const { id, name, metadata: { assetType } } = asset;
    const resourceType = getResourceType(assetType);

    this.setState({
      selectedAssetID: id,
      selectedAssetTitle: name,
      selectedResourceType: resourceType,
      selectedFileId: null,
    });
  };

  /**
   * Imageクリック時のイベントハンドラ
   * @param {number} fileId 選択されたImageのID
   */
  onClickImage = async (fileId) => {
    this.setState({
      selectedFileId: fileId,
    });
  };

  /**
   * AI検出開始ボタンクリック時のイベントハンドラ
   * @param {array} checkFileIds 画面で選択した画像ファイルIDのリスト
   */
  handleClickSettingDetectParameter = (checkFileIds) => {
    if (checkFileIds.length === 0) {
      // 画像未選択の場合、エラーメッセージを表示して学習パラメータ設定ダイアログは表示しない
      message.error(VALIDATE_ERROR_NO_SELECT_IMAGE);
      return;
    }

    this.setState({ settingParameterDialogVisible: true, checkFileIds });
  };

  /**
   * AI検出パラメータ設定モーダルダイアログキャンセルボタンイベントハンドラ
   */
  handleModalCancel = () => {
    this.setState({ settingParameterDialogVisible: false });
  };

  /**
   * AI検出タスク登録完了イベントハンドラ
   */
  handleSuccessStartDetect = () => {
    message.success(SUCCESS_ADD_DETECT_TASK);
    this.setState({ settingParameterDialogVisible: false });
  };

  /**
   * ファイル一覧チェックボックスの活性状態判定処理
   * 読み取り専用権限の場合、チェックボックスを非活性にする。
   * @returns 判定結果(true: 非活性 false: 活性)
   */
  needsDisabledCheckbox = () => this.state.isReadOnly;

  render() {
    const {
      checkFileIds,
      parentAssetsId,
      settingParameterDialogVisible,
      selectedAssetID,
      selectedAssetTitle,
      selectedFileId,
      selectedResourceType,
      isReloadDetectDisabled,
      isReadDetectDisabled,
      isModalEditButtonDisabled,
      isModalDownloadButtonDisabled,
    } = this.state;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <div className="facility-tree">
          <AssetTree
            client={this.props.client.client}
            parentAssetsId={parentAssetsId}
            title="管理設備"
            onSelect={(facility) => {
              if (!isReadDetectDisabled) {
                this.onSelectAsset(facility);
              }
            }}
            collapsed
            menuCollapsed={this.props.menuCollapsed}
            rootResourceType={ResourceType.ManagedFacility}
            isExpandedRoot
            isReloadDisabled={isReloadDetectDisabled}
            isReadDisabled={isReadDetectDisabled}
          />
        </div>

        <Content>
          {
            selectedAssetID && (
              <>
                <Row>
                  <Title level={4} style={{ margin: '10px' }}>{selectedAssetTitle}</Title>
                </Row>
                <Row>
                  <Col span={17}>
                    <ImagesListViewer
                      client={this.props.client}
                      listType={LIST_TYPE.JPEG_ONLY}
                      assetId={selectedAssetID}
                      resourceType={selectedResourceType}
                      showDetectButton
                      onClickSettingDetectParameter={this.handleClickSettingDetectParameter}
                      inActiveCheckbox={this.needsDisabledCheckbox}
                      onClickImage={this.onClickImage}
                      customStyle={{ cardStyle: { height: '1030px', marginLeft: '10px' }, fileTableHeight: '865px', fileListHeight: '805px' }}
                      fileNameStatus={DETECT}
                      isModalEditButtonDisabled={isModalEditButtonDisabled}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                    />
                  </Col>
                  <Col span={7}>
                    <FileDetailView
                      resourceType={selectedResourceType}
                      fileId={selectedFileId}
                      isModalEditButtonDisabled={isModalEditButtonDisabled}
                      isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                      customStyle={{ cardStyle: { height: '1030px', marginRight: '10px' }, detailsListHeight: '770px' }}
                    />
                  </Col>
                </Row>

                {
                  settingParameterDialogVisible && (
                    <SettingDetectParameterDialog
                      client={this.props.client.client}
                      visible={settingParameterDialogVisible}
                      fileIds={checkFileIds}
                      detectAssetId={selectedAssetID}
                      modalCancel={this.handleModalCancel}
                      onSuccessStartDetect={this.handleSuccessStartDetect}
                    />
                  )
                }
              </>
            )
          }
        </Content>
      </Layout>
    );
  }
}

export default Detect;
