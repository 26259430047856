/* eslint-disable react/no-unstable-nested-components */
// eslint警告未対応
import React, {
  CSSProperties, useCallback, useEffect, useState,
} from 'react';
import {
  CogniteClient,
  FileInfo,
} from '@cognite/sdk';

import SwiperClass, {
  FreeMode, Navigation, Thumbs, Keyboard,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Queue from 'promise-queue';
import {
  Empty,
  Tooltip,
  Spin,
  Row,
  Col,
  Icon,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { EnlargeContent } from './EnlargeContent';
import { ImageFile, UnknownFile } from '../../../../utils/File/BaseFile';
import NoImageThumbnail from './NoImageThumbnail';
import { ResourceType, getFileDownloadEndpoint } from '../../../../utils/common/SDFDataType';

import 'swiper/swiper.min.css';
import './ImageGallery.css';

const MODAL_SPIN_HEIGHT = 920;

export const ImageGallery: React.FC<{
  client: CogniteClient;
  fileId?: number;
  resourceType: ResourceType | null;
  onChangeImage?: (fileInfo: FileInfo) => void;
  isDisplayFilename?: boolean;
  isCompare?: boolean;
  disableKeyboard?: boolean;
  sortAllFiles: FileInfo[];
  enlargeStyle?: CSSProperties;
  enlargeClassName?: string;
  zoomStyle?: CSSProperties;
}> = (props) => {
  /*
   * 変数/定数宣言
   */
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();
  const [fileList, setFileList] = useState<JSX.Element[]>([]);
  const [thumbList, setThumbList] = useState<JSX.Element[]>([]);
  const [fileInfoList, setFileInfoList] = useState<FileInfo[]>();
  const [initialSlideIndex, setInitialSlideIndex] = useState<number>();
  const [initialOpacity, setInitialOpacity] = useState<number>(0);
  const [fileName, setFileName] = useState<string>();
  const [isEnlarge, setIsEnlarge] = useState<boolean>(false);
  const [enlargeContent, setEnlargeContent] = useState<ImageFile>();

  const {
    client,
    fileId,
    resourceType,
    onChangeImage,
    isDisplayFilename,
    isCompare,
    disableKeyboard,
    sortAllFiles,
    enlargeStyle,
    enlargeClassName,
    zoomStyle,
  } = props;

  /**
   * Swiper主画像リスト作成
   * @param {FileInfo[]} imageList 画像ファイルリスト
   * @returns SwiperのJSXリスト
   */
  const createSwiperImageList = useCallback(async (
    imageList: FileInfo[],
  ): Promise<JSX.Element[]> => {
    const imgList = await Promise.all(
      imageList.map(
        (value: FileInfo): JSX.Element => (
          <SwiperSlide key={value.id}>
            <LoadingOutlined style={{ fontSize: 100, color: '#1890ff' }} />
          </SwiperSlide>
        ),
      ),
    );
    return imgList;
  }, []);

  /**
   * Swiperサムネイルリスト作成
   * @param {FileInfo[]} imageList 画像ファイルリスト
   * @returns SwiperのJSXリスト
   */
  const createSwiperThumbnailList = useCallback(async (
    imageList: FileInfo[],
  ): Promise<JSX.Element[]> => {
    const maxConcurrent = isCompare ? 7 : 14; // 並列実行するタスク数
    const queue = new Queue(maxConcurrent);
    const promises: Promise<JSX.Element>[] = [];
    const className = isCompare ? 'image-name-compare' : 'image-name';
    imageList.forEach((value) => {
      promises.push(queue.add(async () => {
        try {
          const newFile = UnknownFile.getInstance();
          newFile.id = value.id;
          const createUrl = await newFile.loadThumbnailURLFromCDF(resourceType as ResourceType);
          return (
            <SwiperSlide key={value.id}>
              <div className={className}>
                <img src={createUrl} alt=" " onError={(e) => { e.currentTarget.src = '/icon/NoImage.png'; }} />
                <p>
                  <Tooltip title={value.name} placement="topLeft">{value.name}</Tooltip>
                </p>
              </div>
            </SwiperSlide>
          );
        } catch (e) {
          return (
            <SwiperSlide key={value.id}>
              <NoImageThumbnail style={{ fontSize: 80, backgroundColor: 'white' }} />
              <div className={className}>
                <p>
                  <Tooltip title={value.name} placement="topLeft">{value.name}</Tooltip>
                </p>
              </div>
            </SwiperSlide>
          );
        }
      }));
    });

    const thmList = await Promise.all(promises);

    return thmList;
  }, [isCompare, resourceType]);

  /*
   * イベントハンドラ
   */
  useEffect(() => {
    let canceled = false;

    (async () => {
      if (sortAllFiles.length === 0) {
        const unknownImage = UnknownFile.getInstance();
        setFileInfoList([unknownImage]);
        setInitialSlideIndex(0);
        setFileList([]);
        setThumbList([]);
        return;
      }

      let selectedFileIndex;
      if (fileId) {
        selectedFileIndex = sortAllFiles.findIndex((sortFile) => sortFile.id === fileId);
      } else {
        selectedFileIndex = 0;
      }
      const imgList = await createSwiperImageList(sortAllFiles);
      const thmList = await createSwiperThumbnailList(sortAllFiles);

      if (!canceled) {
        setFileInfoList(sortAllFiles);
        setInitialSlideIndex(selectedFileIndex);
        setFileList(imgList);
        setThumbList(thmList);
        if (selectedFileIndex === 0) {
          // 初回0番スライド表示の場合、handleActiveIndexChangeが実行されないためここで画像を作成する
          setEnlargeContent(new ImageFile(sortAllFiles[selectedFileIndex]));
        }
      }
    })();

    return () => { canceled = true; };
  }, [
    fileId, sortAllFiles,
    createSwiperImageList, createSwiperThumbnailList,
  ]);

  /**
   * 主画像表示コンポーネント作成
   * @param {number} activeIdx imgタグを作成するindex
   * @returns 主要画像JSX
   */
  const mainImage = async (activeIdx: number) => {
    if (!fileInfoList) return;
    if (!fileList) return;

    // URL取得
    const newFile = UnknownFile.getInstance();
    newFile.id = fileInfoList[activeIdx].id;
    const copyList = fileList;
    try {
      const endpoint = getFileDownloadEndpoint(resourceType as ResourceType);
      const downloadUrl = await newFile.loadDownloadURLFromCDF(
        endpoint,
      );
      copyList[activeIdx] = (
        <SwiperSlide key={newFile.id}>
          <img src={downloadUrl} alt={fileInfoList[activeIdx].name} />
        </SwiperSlide>
      );
    } catch (e) {
      copyList[activeIdx] = (
        <SwiperSlide key={newFile.id}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </SwiperSlide>
      );
    }
    setFileList(copyList);
    // 主画像リスト更新だけでは表示更新されないため、リスト更新後にファイル名更新で画面更新を行う
    setFileName(fileInfoList[activeIdx].name);
  };

  /**
   * サムネイル描画開始時のイベントハンドラ
   * @param {SwiperClass} swiper サムネイルのswiper情報
   */
  const handleStartThumbnail = async (swiper: SwiperClass) => {
    // 初回0番スライド表示の場合、handleActiveIndexChangeが実行されないためここで画像を作成する
    if (initialSlideIndex === 0) await mainImage(swiper.activeIndex);

    setThumbsSwiper(swiper);
    setInitialOpacity(1.0);
  };

  /**
   * 本画像変更時のイベントハンドラ
   * @param {SwiperClass} swiper swiper情報
   */
  const handleActiveIndexChange = async (swiper: SwiperClass) => {
    if (fileInfoList) {
      await mainImage(swiper.activeIndex);
      if (onChangeImage) {
        onChangeImage(fileInfoList[swiper.activeIndex]);
      }
      setEnlargeContent(new ImageFile(fileInfoList[swiper.activeIndex]));
    }
  };

  /*
   * メソッド
   */
  /*
   * 画面描画
   */
  /** 拡大アイコン */
  const EnlargeIcon = () => (
    <div
      className="zoom-icon enlarge"
      role="button"
      tabIndex={0}
      onClick={() => setIsEnlarge(true)}
      onKeyUp={() => { /** 処理なし */ }}
    >
      <img src="/icon/Zoom.png" alt="zoom" width={40} height={40} />
    </div>
  );

  /** 縮小アイコン */
  const ShrinkIcon = () => (
    <div
      className="zoom-icon shrink"
      role="button"
      tabIndex={0}
      onClick={() => setIsEnlarge(false)}
      onKeyUp={() => { /** 処理なし */ }}
    >
      <Icon type="zoom-out" style={{ fontSize: 40 }} />
    </div>
  );

  // 画像の読み込みが完了するまでローディングを表示
  const isLoading = fileList.length === 0 || thumbList.length === 0;
  const mainClassName = isCompare ? 'mainGalleryCompare' : 'mainGallery';
  const thumbnailClassName = isCompare ? 'thumbnailGalleryCompare' : 'thumbnailGallery';
  return (
    <div>
      {
        isLoading
          ? (
            <Row style={{ height: `${MODAL_SPIN_HEIGHT}px` }} type="flex" justify="space-around" align="middle">
              <Col>
                <Spin size="large" tip="Loading..." />
              </Col>
            </Row>
          )
          : (
            <div className="image-gallery-container" style={{ opacity: initialOpacity }}>
              {isDisplayFilename && (
                <h3>
                  {fileName}
                </h3>
              )}
              <EnlargeIcon />
              <Swiper
                spaceBetween={1}
                keyboard={{
                  enabled: !disableKeyboard,
                }}
                speed={0}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs, Keyboard]}
                onActiveIndexChange={handleActiveIndexChange}
                initialSlide={initialSlideIndex}
                className={mainClassName}
              >
                {fileList}
              </Swiper>
              <Swiper
                grabCursor
                onSwiper={handleStartThumbnail}
                spaceBetween={4}
                slidesPerView={8}
                freeMode
                watchSlidesProgress
                modules={[FreeMode, Navigation, Thumbs, Keyboard]}
                className={thumbnailClassName}
              >
                {thumbList}
              </Swiper>

              {
                (isEnlarge && enlargeContent) && (
                  <>
                    <EnlargeContent
                      client={client}
                      targetClassName={enlargeClassName}
                      parentStyle={enlargeStyle}
                      target={enlargeContent}
                      zoomStyle={zoomStyle}
                      resourceType={resourceType}
                    >
                      <ShrinkIcon />
                    </EnlargeContent>
                  </>
                )
              }
            </div>
          )
      }
    </div>
  );
};
