/* eslint-disable no-promise-executor-return */
/* eslint-disable no-return-await */
import { removeAuthResultFromStorage } from '../../components/AuthWrapper/persistance';
import { removeStorageMenuKey } from '../storageCommon';
import { removeAuthFunction, removeAuthInformation } from './CacheApi';
import { sortOrder, sortId } from '../sort';
import { postApiGateway } from '../AWS/ApiGateway';

/**
 * 次の順番の取得
 * @param {Array} array 対象の配列
 */
export function nextOrder(array) {
  if (array.length === 0) {
    return String(0);
  }
  sortOrder(array);
  const item = array[array.length - 1];
  let lastOrder = 0;
  if (item.metadata && item.metadata.order) {
    lastOrder = Number(item.metadata.order);
  }

  return String(lastOrder + 1);
}

/**
 * 次のIDを取得
 * @param {Array} array 次のIDを取得する配列
 * @returns {number} 次のID
 */
export function nextItemId(array) {
  const copyArray = [...array];
  if (copyArray.length === 0) {
    return 1;
  }
  sortId(copyArray, 'desc');
  return copyArray[0].id + 1;
}

/**
 * Taskの優先度の取得
 * @param {object} endpoint エンドポイント
 * @param {number} taskId Taskを保持するAssetのID
 * @returns {number} priority
 */
export async function getTaskPriority(endpoint, taskId) {
  const untreatedEventScope = {
    filter: { assetIds: [taskId], subtype: 'untreated' },
    sort: [
      { property: ['startTime'], order: 'desc' },
    ],
    limit: 1,
  };
  const untreatedEvents = await postApiGateway(endpoint, untreatedEventScope);

  const runningEventScope = {
    filter: { assetIds: [taskId], subtype: 'running' },
  };
  const runningEvents = await postApiGateway(endpoint, runningEventScope);

  let priority = 1;
  const untreatedEvent = untreatedEvents.items[0];
  const runningEvent = runningEvents.items[0];
  if (untreatedEvent) {
    priority = untreatedEvent.startTime + 1;
  } else if (runningEvent) {
    priority += 1;
  }

  return priority;
}

/**
 * 処理の待機
 * @param {number} ms 待機ミリ秒
 */
export async function sleep(ms) {
  return await new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * 対象文字列が定義されているか判定する。
 * @param {string} str 対象文字列
 * @returns {boolean} 判定結果(true: 空文字 / false: 空文字以外)
 */
export function isEmpty(str) {
  return !str || str.trim().length === 0;
}

/**
 * SDFからログアウトするためにクリア処理を行う
 */
export function sdfLogOut() {
  removeAuthResultFromStorage();
  removeStorageMenuKey();
  sessionStorage.clear();

  removeAuthInformation();
  removeAuthFunction();
}
