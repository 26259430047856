import React, { Fragment } from 'react';

import { TreeSelect } from 'antd';

import OMEquipment from '../../../../utils/Asset/OMEquipment';
import TreeNode from '../../../../utils/common/TreeNode';

interface MeasurementSelectProps {
  rootTreeNode?: TreeNode<OMEquipment>;
  checkedItems: string[];
  onChange: (changeValue: string[]) => void;
}

/**
 * 計測ポイント選択プルダウン用TreeNodeのレンダリング
 * @param {TreeNode<OMEquipment>[]} treeNodes 計測ポイント選択プルダウン用treeNodeリスト
 * @returns {JSX.Element[]} 計測ポイント選択プルダウン用TreeNode
 */
const renderTreeNodes = (
  treeNodes: TreeNode<OMEquipment>[],
): JSX.Element[] => (
  treeNodes.map((treeNode) => {
    const { id, name, assetLevel } = treeNode.data;
    // 配下にアセットを持たないフロアのアセットはツリー上では表示しない
    if (assetLevel === 'floor' && treeNode.isLeaf) return <Fragment key="not_rendered" />;

    // サブフロア（計測グループ）と計測値の保持アセットのみ選択可能とする
    const selectable = assetLevel === 'ts_holder'
      || (assetLevel === 'floor' && treeNode.children.every((childNode) => childNode.data.assetLevel === 'ts_holder'));

    return treeNode.isLeaf
      ? <TreeSelect.TreeNode key={`${id}`} title={name} value={`${id}`} checkable={selectable} />
      : (
        <TreeSelect.TreeNode key={`${id}`} title={name} value={`${id}`} checkable={selectable}>
          {renderTreeNodes(treeNode.children)}
        </TreeSelect.TreeNode>
      );
  })
);

/**
 * 特高監視ダッシュボード計測一覧画面 計測ポイント選択プルダウンコンポーネント
 * @param {MeasurementSelectProps} props プロパティ
 * @returns 特高監視ダッシュボード計測一覧画面 計測ポイント選択プルダウン
 */
const MeasurementSelect: React.FC<MeasurementSelectProps> = (props: MeasurementSelectProps) => {
  const { rootTreeNode, checkedItems, onChange } = props;

  return (
    <TreeSelect
      style={{ width: '80%' }}
      allowClear
      dropdownStyle={{ overflow: 'scroll', maxHeight: 300 }}
      placeholder="計測ポイントを選択"
      value={checkedItems}
      onChange={(value, _, __) => onChange(value)} // eslint-disable-line @typescript-eslint/no-unused-vars
      treeDefaultExpandAll
      treeNodeFilterProp="title"
      treeCheckable
    >
      {renderTreeNodes(rootTreeNode ? [rootTreeNode] : [])}
    </TreeSelect>
  );
};

export default MeasurementSelect;
