import React, { useEffect, useState } from 'react';
import { AssetListScope } from '@cognite/sdk';
import { useHistory } from 'react-router';

import OMEquipmentTree from './parts/OMEquipmentTree';
import { FILTER_MODE_AREA } from '../../../utils/Asset/OMEquipment';
import { loadAllOMEquipmentFromCDFByScope } from '../../../utils/Asset/OMEquipmentAsset';
import { setAreaAssetId } from '../../../utils/storageCommon';

const O_M_ASSET_TYPE = process.env.REACT_APP_O_M_ROOT_ASSET ?? 'o_m_root_asset';

/**
 * 特高監視ダッシュボードサイト選択コンポーネント
 */
const OperationAndMaintenanceDashboard: React.FC = () => {
  const [parentAssetId, setParentAssetId] = useState<number>();
  const history = useHistory();

  useEffect(
    () => {
      (async () => {
        const scope: AssetListScope = {
          filter: {
            metadata: {
              assetType: O_M_ASSET_TYPE,
            },
          },
          limit: 1000,
        };
        const [omRootAsset] = await loadAllOMEquipmentFromCDFByScope(scope);
        setParentAssetId(omRootAsset.id);
      })();
    },
    [],
  );

  /**
   * エリアアセット選択時のイベントハンドラ
   * @param {number} areaAssetId エリアアセットID
   */
  const onSelectAreaList = async (areaAssetId?: number) => {
    if (areaAssetId !== parentAssetId) {
      setAreaAssetId(areaAssetId);
      history.push('/OperationAndMaintenance');
    }
  };

  /**
   * O&M特高監視ダッシュボードサイト選択画面
   */
  return (
    <OMEquipmentTree
      parentAssetId={parentAssetId}
      title="特高監視ダッシュボードサイト選択"
      onSelect={(facility) => {
        onSelectAreaList(facility?.id);
      }}
      isExpandedRoot
      filterMode={FILTER_MODE_AREA}
    />
  );
};

export default OperationAndMaintenanceDashboard;
