import {
  Asset, AssetIdEither, AssetListScope, InternalId, ItemsWrapper,
} from '@cognite/sdk';

import { getAllAssets } from '../dataAccess';
import { postApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_AI_DETECT_RESULT_LIST_BY_IDS, EP_PATH_ROOT_ASSET_AI_DETECT_RESULT,
} from '../AWS/EndpointPath';

// TODO: DetectionResult.tsのクラスメソッドとして定義するとコンパイルエラーが発生するため暫定対応

/**
 * IDに紐づくAI検出結果一覧の取得
 * @param ids IDリスト
 * @returns AI検出結果一覧
 */
const loadAllDetectionResultsFromCDFByIds = async (
  ids: InternalId[],
): Promise<ItemsWrapper<Asset[]>> => {
  const assets = await postApiGateway<
    ItemsWrapper<AssetIdEither[]>,
    ItemsWrapper<Asset[]>
  >(EP_PATH_AI_DETECT_RESULT_LIST_BY_IDS, { items: ids });

  return assets;
};

/**
 * 検索条件に一致するAI検出結果一覧の取得
 * @param scope 検索条件
 * @returns AI検出結果一覧
 */
const loadAllDetectionResultsFromCDFByScope = async (scope: AssetListScope): Promise<Asset[]> => {
  const assets = await getAllAssets(EP_PATH_ROOT_ASSET_AI_DETECT_RESULT, scope.filter);
  return assets;
};

export {
  loadAllDetectionResultsFromCDFByIds, loadAllDetectionResultsFromCDFByScope,
};
