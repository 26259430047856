/* eslint-disable react/no-unused-class-component-methods */
// eslint警告未対応
import React from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Select,
  List,
  message,
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import ListCard from './ListCard';
import OperatorSelect from './OperatorSelect';
import { ENSEMBLE_MODEL_DELETE_LIST_CARD } from '../../../../utils/messages';

const { Option } = Select;

const SelectLogicOps = ({ selectRef }) => (
  <Row style={{ textAlign: 'center' }}>
    <OperatorSelect ref={selectRef} defaultValue="+" style={{ width: '100px', margin: 0, padding: 0 }}>
      <Option value="+">OR</Option>
      <Option value="*">AND</Option>
      <Option value="-">SUB</Option>
    </OperatorSelect>
  </Row>
);

/**
 * ParamCard component
 * @property { string } id ParamCard id
 * @property { object } onClickDeleteParamCard ParamCard 削除関数
 */
class ParamCard extends React.Component {
  constructor(props) {
    super(props);
    const listCardsRef = React.createRef();
    const listCard = <ListCard key="list1" id="list1" ref={listCardsRef} onClickDeleteListCard={this.onClickDeleteListCard} />;
    this.listKey = 2;
    this.listSelectKey = 1;

    this.state = {
      stateListCards: [listCard],
      stateListCardRefs: [listCardsRef],
    };
  }

  /**
   * ParamCardのパラメータを取得する
   * @returns {object} ParamCardが含むパラメータ
   */
  getParamCardValue = () => {
    const classes = [];
    const { stateListCardRefs } = this.state;
    stateListCardRefs.forEach((listCard) => {
      if (listCard) classes.push(listCard.current.getListCardValue());
    });
    return { classes };
  };

  /**
   * ListCard追加時のイベントハンドラ
   */
  onClickAddListCard = () => {
    const { stateListCards, stateListCardRefs } = this.state;
    const listCards = [...stateListCards];
    const listSelectRef = React.createRef();
    listCards.push(<SelectLogicOps key={`select${this.listSelectKey}`} selectRef={listSelectRef} />);

    const key = `list${this.listKey}`;
    const listCardsRef = React.createRef();

    listCards.push(
      <ListCard
        key={key}
        id={key}
        ref={listCardsRef}
        onClickDeleteListCard={this.onClickDeleteListCard}
      />,
    );

    const listCardRefs = [...stateListCardRefs];
    listCardRefs.push(listSelectRef);
    listCardRefs.push(listCardsRef);

    this.listKey += 1;
    this.listSelectKey += 1;

    this.setState({
      stateListCards: listCards,
      stateListCardRefs: listCardRefs,
    });
  };

  /**
   * ListCard削除時のイベントハンドラ
   * @param {event} event
   */
  onClickDeleteListCard = (event) => {
    const { stateListCards, stateListCardRefs } = this.state;
    const listCards = [...stateListCards];
    const listCardRefs = [...stateListCardRefs];

    if (listCards.length === 1) {
      message.error(ENSEMBLE_MODEL_DELETE_LIST_CARD);
      return;
    }

    const index = listCards.findIndex((listCard) => listCard.props.id === event.target.id);
    const deleteIndex = index === 0 ? index : index - 1;

    listCards.splice(deleteIndex, 2);
    listCardRefs.splice(deleteIndex, 2);

    this.setState({
      stateListCards: listCards,
      stateListCardRefs: listCardRefs,
    });
  };

  render() {
    const { id, onClickDeleteParamCard } = this.props;
    const { stateListCards } = this.state;
    return (
      <Card style={{ height: '100%', width: '400px', background: '#ECECEC' }} bodyStyle={{ padding: '20px' }}>
        <Row>
          <Col span={8} style={{ width: '80%' }}>
            {id}
          </Col>
          <Col span={8} style={{ width: '10%' }}>
            <Button type="link" onClick={this.onClickAddListCard}>
              <PlusOutlined />
            </Button>
          </Col>
          <Col span={8} style={{ width: '10%' }}>
            <Button id={id} type="link" onClick={onClickDeleteParamCard}>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
        <List
          grid={{ gutter: 16, column: 1 }}
          dataSource={stateListCards}
          renderItem={(item) => (<List.Item>{item}</List.Item>)}
        />
      </Card>
    );
  }
}

export default ParamCard;
