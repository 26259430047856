import { CogniteEvent, ExternalEvent } from '@cognite/sdk';
import moment from 'moment';
import { getAllEvents } from '../dataAccess';
import { EP_PATH_SOLAR_EVENTS_LIST } from '../AWS/EndpointPath';
import BaseEvent from './BaseEvent';

/**
 * 逸失発電情報（年度）クラス
 */
export default class LostEnergyFiscalYearInformation extends BaseEvent {
  /** Events.type */
  static eventType = 'lost_energy_fiscal_year_information';

  /**
   * アセットIDをキーに逸失発電情報（年度）を取得する。
   * @param {number[]} assetIds アセットIDリスト
   * @returns 逸失発電情報（年度）
   */
  static async retrieveLostEnergyFiscalYearInformationByAssetIds(assetIds: number[]): Promise<CogniteEvent[]> {
    const filter = {
      type: LostEnergyFiscalYearInformation.eventType,
      assetIds,
    };
    const result = await getAllEvents(EP_PATH_SOLAR_EVENTS_LIST, filter) as CogniteEvent[];

    return result;
  }

  /**
   * 逸失発電情報（年度）インスタンスを生成する
   * @param {number | undefined} dataSetId データセットID
   * @param {number} assetId アセットID
   * @param {moment.Moment} currentTime 時刻
   * @param {number} fiscalYear 年度
   * @returns 逸失発電情報（年度）インスタンス
   */
  static new(
    dataSetId: number | undefined,
    assetId: number,
    currentTime: moment.Moment,
    fiscalYear: number,
  ): LostEnergyFiscalYearInformation {
    const lostEnergyInformation = new LostEnergyFiscalYearInformation();
    lostEnergyInformation.dataSetId = dataSetId;
    lostEnergyInformation.startTime = currentTime.valueOf();
    lostEnergyInformation.type = LostEnergyFiscalYearInformation.eventType;
    lostEnergyInformation.assetIds = [assetId];
    lostEnergyInformation.metadata = {};
    for (let i = 1; i < 13; i++) {
      lostEnergyInformation.metadata[`実績発電量${i}月`] = '';
      lostEnergyInformation.metadata[`計画発電量${i}月`] = '';
      lostEnergyInformation.metadata[`期待発電量${i}月`] = '';
      lostEnergyInformation.metadata[`天候要因損失量${i}月`] = '';
      lostEnergyInformation.metadata[`出力抑制要因損失量${i}月`] = '';
      lostEnergyInformation.metadata[`メンテナンス要因損失量${i}月`] = '';
      lostEnergyInformation.metadata[`故障要因損失量${i}月`] = '';
      lostEnergyInformation.metadata[`要因不明損失量${i}月`] = '';
    }
    lostEnergyInformation.metadata.assetId = assetId.toString();
    lostEnergyInformation.metadata.fiscalYear = fiscalYear.toString();
    lostEnergyInformation.metadata.display_period = [
      // 表示期間は指定年度から5年間
      fiscalYear.toString(),
      (fiscalYear + 1).toString(),
      (fiscalYear + 2).toString(),
      (fiscalYear + 3).toString(),
      (fiscalYear + 4).toString(),
    ].join(',');

    return lostEnergyInformation;
  }

  // コンストラクタをprivateにするため警告解除
  // eslint-disable-next-line no-useless-constructor
  private constructor() {
    super();
  }

  /**
   * CDF登録用オブジェクトに変換する
   * @returns CDF登録用オブジェクト
   */
  toExternalEvent(): ExternalEvent {
    const externalEvent: ExternalEvent = {};
    externalEvent.dataSetId = this.dataSetId;
    externalEvent.startTime = this.startTime;
    externalEvent.type = this.type;
    externalEvent.assetIds = this.assetIds;
    externalEvent.metadata = this.metadata;

    return externalEvent;
  }
}
