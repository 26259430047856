/* eslint-disable react/no-unused-class-component-methods */
// eslint警告未対応
import React from 'react';
import {
  Icon,
  Button,
  Card,
  Col,
  Row,
  Select,
  Input,
  message,
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ParamCard from './ParamCard';
import OperatorSelect from './OperatorSelect';
import { ENSEMBLE_MODEL_DELETE_PARAM_CARD } from '../../../../utils/messages';

const { Option } = Select;

const AddParamButton = ({ onClickAddParamCard }) => (
  <Card bordered={false} bodyStyle={{ paddingTop: '0' }}>
    <Button type="dashed" onClick={onClickAddParamCard} style={{ height: '150px', width: '300px' }}>
      <Icon type="plus" />
      パラメーター追加
    </Button>
  </Card>
);

const SelectLogicOps = ({ selectRef }) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    height: '320px',
    paddingLeft: '10px',
    paddingRight: '10px',
  }}
  >
    <OperatorSelect ref={selectRef} defaultValue="+" style={{ width: '80px', margin: 'auto' }}>
      <Option value="+">OR</Option>
      <Option value="*">AND</Option>
      <Option value="-">SUB</Option>
    </OperatorSelect>
  </div>
);

/**
 * RowCard component
 * @property {string} id RowCard id
 * @property {object} onClickDeleteRowCard RowCard 削除関数
 */
class RowCard extends React.Component {
  constructor(props) {
    super(props);
    const paramCardRef = React.createRef();
    const paramCards = <ParamCard key="パラメーター1" id="パラメーター1" ref={paramCardRef} onClickDeleteParamCard={this.onClickDeleteParamCard} />;
    this.paramKey = 2;
    this.paramId = 2;
    this.paramSelectKey = 1;
    this.inputRef = React.createRef();
    this.keyList = [1];

    this.state = {
      stateParamCards: [paramCards, <AddParamButton key="AddParam1" onClickAddParamCard={this.onClickAddParamCard} />],
      stateParamCardRefs: [paramCardRef],
      ensembleClassName: '',
      okButtonEnable: true,
    };
  }

  /**
   * RowCardのパラメータを取得する
   * @returns {object} RowCardが含むパラメータ
   */
  getRowCardValue = () => {
    const params = [];
    const { stateParamCardRefs, ensembleClassName, okButtonEnable } = this.state;
    stateParamCardRefs.forEach((paramCard) => {
      if (paramCard) params.push(paramCard.current.getParamCardValue());
    });
    return {
      className: ensembleClassName,
      okButtonEnable,
      params,
    };
  };

  /**
   * ParamCard追加時のイベントハンドラ
   */
  onClickAddParamCard = () => {
    const { stateParamCards } = this.state;
    const paramCards = [...stateParamCards];
    paramCards.pop();

    const paramSelectRef = React.createRef();
    paramCards.push(<SelectLogicOps key={`select${this.paramSelectKey}`} selectRef={paramSelectRef} />);

    const key = `パラメーター${this.paramKey}`;
    const id = `パラメーター${this.paramId}`;
    const paramCardRef = React.createRef();

    paramCards.push(
      <ParamCard
        key={key}
        id={id}
        ref={paramCardRef}
        onClickDeleteParamCard={this.onClickDeleteParamCard}
      />,
    );

    paramCards.push(<AddParamButton key={`AddParam${this.paramKey + 1}`} onClickAddParamCard={this.onClickAddParamCard} />);

    const { stateParamCardRefs } = this.state;
    const paramCardRefs = [...stateParamCardRefs];
    paramCardRefs.push(paramSelectRef);
    paramCardRefs.push(paramCardRef);

    this.keyList.push(this.paramKey);
    this.paramKey += 1;
    this.paramSelectKey += 1;
    this.paramId += 1;

    this.setState({
      stateParamCards: paramCards,
      stateParamCardRefs: paramCardRefs,
    });
  };

  /**
   * ParamCard削除時のイベントハンドラ
   * @param {event} event
   */
  onClickDeleteParamCard = (event) => {
    const { stateParamCards, stateParamCardRefs } = this.state;

    const paramCards = [...stateParamCards];
    const paramCardRefs = [...stateParamCardRefs];

    if (paramCards.length === 2) {
      message.error(ENSEMBLE_MODEL_DELETE_PARAM_CARD);
      return;
    }

    const index = paramCards.findIndex((paramCard) => paramCard.props.id === event.target.id);
    const deleteIndex = index === 0 ? index : index - 1;

    // 削除するパラメーターが末尾だった場合
    if (index === paramCards.length - 2) {
      paramCards.splice(deleteIndex, 2);
      paramCardRefs.splice(deleteIndex, 2);
      this.keyList.pop();
    } else {
      // 演算子を除いたパラメーターの数
      const paramCardsLength = paramCards.length / 2;
      // 削除対象のparamCardから末尾まで削除
      paramCards.splice(deleteIndex);
      paramCardRefs.splice(deleteIndex);

      // keyListから削除対象のパラメーターのkeyを削除する
      const idIndex = (index + 2) / 2;
      this.keyList.splice(idIndex - 1, 1);

      // 削除するパラメーターの次のパラメーターから末尾のパラメーターまで新しく作り直す
      for (let i = idIndex; i < paramCardsLength; i++) {
        // 最初のパラメーターではない場合、演算子を追加
        if (i !== 1) {
          const paramSelectRef = React.createRef();
          paramCards.push(<SelectLogicOps key={`select${this.keyList[i - 1] - 1}`} selectRef={paramSelectRef} />);
          paramCardRefs.push(paramSelectRef);
        }
        // 設定を引き継ぐため、keyは同じものにする
        const key = `パラメーター${this.keyList[i - 1]}`;
        const id = `パラメーター${i}`;
        const paramCardRef = React.createRef();
        paramCards.push(
          <ParamCard
            key={key}
            id={id}
            ref={paramCardRef}
            onClickDeleteParamCard={this.onClickDeleteParamCard}
          />,
        );
        paramCardRefs.push(paramCardRef);
      }

      paramCards.push(<AddParamButton key={`AddParam${this.paramKey + 1}`} onClickAddParamCard={this.onClickAddParamCard} />);
    }

    this.paramId -= 1;

    this.setState({
      stateParamCards: paramCards,
      stateParamCardRefs: paramCardRefs,
    });
  };

  /**
   * 「OK」ボタン押下時のイベントハンドラ
   */
  onClickOKButton = () => {
    this.setState({
      ensembleClassName: this.inputRef.current.state.value,
      okButtonEnable: false,
    });
  };

  /**
   * ペンアイコン押下時のイベントハンドラ
   */
  onClickPenIcon = () => {
    this.setState({ okButtonEnable: true });
  };

  /**
   * EnsembleClass名入力フォーム
   * @returns {object} EnsembleClass名の入力フォーム
   */
  inputEnsembleClassName() {
    const { okButtonEnable, ensembleClassName } = this.state;
    if (okButtonEnable) {
      return (
        <span style={{ paddingBottom: '5px', paddingRight: '50px' }}>
          <Input placeholder="アンサンブルクラス名" ref={this.inputRef} defaultValue={ensembleClassName} aria-label="アンサンブルクラス名入力" style={{ textAlign: 'left', width: '60%' }} />
          <Button type="primary" onClick={this.onClickOKButton} style={{ marginLeft: '5px' }}>
            OK
          </Button>
        </span>
      );
    }
    return (
      <span style={{ paddingBottom: '5px', paddingRight: '50px' }}>
        {ensembleClassName}
        <Button type="link" onClick={this.onClickPenIcon}>
          <EditOutlined />
        </Button>
      </span>
    );
  }

  render() {
    const { stateParamCards } = this.state;
    const { id, onClickDeleteRowCard } = this.props;
    return (
      <Card style={{
        textAlign: 'left',
        margin: '10px 0px 0px 0px',
        height: '600px',
        overflow: 'auto',
      }}
      >
        <Row>
          <Col span={12} style={{ width: '30%' }}>
            {this.inputEnsembleClassName()}
          </Col>
          <Col span={12} style={{ width: '70%', textAlign: 'right' }}>
            <Button id={id} type="link" onClick={onClickDeleteRowCard}>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
        <Row style={{ display: 'inline-flex', marginTop: '10px' }}>
          {stateParamCards}
        </Row>
      </Card>
    );
  }
}

export default RowCard;
