import {
  CogniteInternalId,
  FileInfo,
} from '@cognite/sdk';

import BaseFile, { MimeType } from './BaseFile';
import { getAllFiles } from '../dataAccess';
import { EP_PATH_SOLAR_OM_FILES_LIST } from '../AWS/EndpointPath';

class OMLayoutFile extends BaseFile {
  /**
   * メンバ変数
   */
  get imageSortKey(): number {
    if (!this.metadata || !this.metadata?.sort_key) return 0;
    return Number(this.metadata.sort_key);
  }

  /**
   * ファイル二つをソートキーの順でソート
   * @param {OMLayoutFile} a 比較対象ファイル
   * @param {OMLayoutFile} b 比較対象ファイル
   * @returns {number} 比較結果
   */
  static sortByFileSortKey(a: OMLayoutFile, b: OMLayoutFile): number {
    if (a.imageSortKey < b.imageSortKey) return -1;
    if (a.imageSortKey > b.imageSortKey) return 1;

    return 0;
  }

  /**
   * クラスメソッド
   */
  static async loadLayoutFileList(areaAssetId: CogniteInternalId): Promise<OMLayoutFile[]> {
    const layoutFilter = {
      assetIds: [areaAssetId],
      mimeType: MimeType.Jpeg,
    };
    const files = await getAllFiles(EP_PATH_SOLAR_OM_FILES_LIST, layoutFilter) as FileInfo[];
    const sortedFiles = files.map((file) => new OMLayoutFile(file));
    const layoutImageList = [...sortedFiles].sort(OMLayoutFile.sortByFileSortKey);

    return layoutImageList;
  }
}

export default OMLayoutFile;
