/* eslint-disable react/no-unstable-nested-components */
// eslint警告未対応
import React, { useEffect, useState } from 'react';
import { FileChangeUpdate } from '@cognite/sdk';
import {
  Button, Card, Empty, message, Icon, Radio, Row, Spin, Tooltip,
} from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib/radio';

import {
  ERROR_FILES_RETRIEVE,
  ERROR_FILES_GET_DL_URL,
  ERROR_FILES_UPDATE,
  ERROR_NO_AUTH_MESSAGE,
} from '../../../utils/messages';
import { generateFileMetaData, FILE_META_DEFECTED } from '../Metadata/MetadataInfo';
import { FileDetailViewModal } from './FileDetailViewModal';
import { ImageFile, UnknownFile } from '../../../utils/File/BaseFile';
import { ResourceType } from '../../../utils/common/SDFDataType';
import { retrieveFile } from '../../../utils/AWS/AWSRequest';
import NoImageThumbnail from './parts/NoImageThumbnail';
import { updateAiDetectResultFile } from '../../../utils/File/AIDetectResultFile';
import './FileDetailView.css';

const ButtonGroup = Button.Group;

interface CustomStyle {
  cardStyle: {
    height: string,
    margin?: string,
    marginTop?: string,
    marginBottom?: string,
    marginRight?: string,
    marginLeft?: string,
  },
  detailsListHeight?: string,
}

export const FileDetailView: React.FC<{
  resourceType: ResourceType | null,
  fileId: number | null,
  isSetDefect?: boolean,
  customStyle?: CustomStyle,
  isModalEditButtonDisabled: boolean,
  isModalDownloadButtonDisabled: boolean,
  isDefectChangeDisabled: boolean,
  isImageChangeDisabled: boolean,
  onClickPrevImage?: (fileId: number) => void
  onClickNextImage?: (fileId: number) => void
}> = (props) => {
  /*
   * 変数/定数宣言
   */
  const [name, setName] = useState<string | undefined>(undefined);
  const [iconUrl, setIconUrl] = useState<string | undefined>(undefined);
  const [detailsList, setDetailsList] = useState<JSX.Element[] | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [fileLoad, setFileLoad] = useState<boolean>(false);
  const [defectedDefaultValue, setDefectedDefaultValue] = useState<string>('');
  const [defectedUpdating, setDefectedUpdating] = useState<boolean>(false);

  const {
    resourceType,
    fileId,
    isSetDefect,
    customStyle,
    isModalEditButtonDisabled,
    isModalDownloadButtonDisabled,
    isDefectChangeDisabled,
    isImageChangeDisabled,
    onClickPrevImage,
    onClickNextImage,
  } = props;

  const cardStyle = (customStyle && customStyle.cardStyle)
    ? customStyle.cardStyle
    : { height: '410px', margin: '0px' };
  const detailsListHeight = (customStyle && customStyle.detailsListHeight)
    ? customStyle.detailsListHeight
    : '96px';

  /*
   * イベントハンドラ
   */
  useEffect(() => {
    let canceled = false;
    setIconUrl(undefined);
    setFileLoad(true);

    (async () => {
      if (fileId === null) return;

      let file = null;
      try {
        file = await retrieveFile(resourceType as ResourceType, fileId);
        if (!file) throw new Error(); // ファイル情報が空の場合はファイル情報取得エラー
      } catch (e) {
        if (!canceled) {
          message.error(ERROR_FILES_RETRIEVE);
          setFileLoad(false);
        }
        return;
      }

      // 詳細情報
      const metaInfo = generateFileMetaData(file);

      // アイコン画像
      let createUrl;
      if (ImageFile.validateThumbnailDisplay(file.mimeType)) {
        const newFile = UnknownFile.getInstance();
        newFile.id = file.id;
        try {
          createUrl = await newFile.loadThumbnailURLFromCDF(resourceType as ResourceType);
        } catch (e) {
          if (!canceled) {
            message.error(ERROR_FILES_GET_DL_URL);
          }
          createUrl = undefined;
        }
      }
      if (!canceled) {
        setName(file.name);
        setDetailsList(metaInfo.descriptionsItem);
        setDefectedDefaultValue(metaInfo.defectedValue);
        setIconUrl(createUrl);
        setFileLoad(false);
      }
    })();

    return () => { canceled = true; };
  }, [fileId, resourceType]);

  /**
   * Modal Close時のイベントハンドラ
   */
  const handleCancelModal = () => {
    setModalVisible(false);
  };

  /**
   * 不具合有無ラジオボタン変更時のイベントハンドラ
   * @param {Event} event イベント情報
   */
  const handleChangeDefect = async (event: RadioChangeEvent) => {
    setDefectedUpdating(true);
    let files = null;
    try {
      const changes: FileChangeUpdate = {
        id: fileId as number,
        update: {
          metadata: {
            add: {
              [FILE_META_DEFECTED]: event.target.value,
            },
            remove: [],
          },
        },
      };
      files = await updateAiDetectResultFile(changes);
    } catch (e) {
      message.error(ERROR_FILES_UPDATE);
    }
    if (files) {
      const MetaInfo = generateFileMetaData(files.items[0]);
      setDetailsList(MetaInfo.descriptionsItem);
      setDefectedDefaultValue(event.target.value);
    }
    setDefectedUpdating(false);
  };

  /**
   * 前画像送りボタンクリック時のイベントハンドラ
   */
  const handleClickPrevImageButton = () => {
    if (onClickPrevImage) {
      onClickPrevImage(fileId as number);
    }
  };

  /**
   * 次画像送りボタンクリック時のイベントハンドラ
   */
  const handleClickNextImageButton = () => {
    if (onClickNextImage) {
      onClickNextImage(fileId as number);
    }
  };

  /*
   * メソッド
   */
  // サムネイル表示
  const ThumbnailInfo = () => (<img alt="サムネイル" src={iconUrl} onError={(e) => { e.currentTarget.src = '/icon/NoImage.png'; }} className="contain-thumbnail" />);

  // 不具合有無登録表示
  const DefectInfo = () => (
    <Row style={{ marginTop: '32px' }} type="flex" justify="center">
      <Card style={{ width: '258px', textAlign: 'center', background: '#FFFFD0' }}>
        <div>
          不具合：
          <Tooltip title={isDefectChangeDisabled && ERROR_NO_AUTH_MESSAGE}>
            <Radio.Group value={defectedDefaultValue} buttonStyle="solid" onChange={handleChangeDefect} disabled={defectedUpdating || isDefectChangeDisabled}>
              <Radio.Button value="true">あり</Radio.Button>
              <Radio.Button value="false">なし</Radio.Button>
            </Radio.Group>
          </Tooltip>
        </div>
        <Row style={{ marginTop: '16px' }}>
          <ButtonGroup>
            <Tooltip title={isImageChangeDisabled && ERROR_NO_AUTH_MESSAGE}>
              <Button type="primary" onClick={handleClickPrevImageButton} disabled={isImageChangeDisabled}>
                <Icon type="left" />
                前画像
              </Button>
            </Tooltip>
            <Tooltip title={isImageChangeDisabled && ERROR_NO_AUTH_MESSAGE}>
              <Button type="primary" onClick={handleClickNextImageButton} disabled={isImageChangeDisabled}>
                次画像
                <Icon type="right" />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Row>
      </Card>
    </Row>
  );

  /*
   * 画面描画
   */
  return (
    <Card style={cardStyle}>
      {
        fileId
          ? (
            <Spin spinning={fileLoad}>
              <Row className="file-detail-view-file-name">
                <FolderOutlined />
                <Tooltip title={name} placement="topLeft">
                  <span style={{ marginLeft: '10px', background: 'whitesmoke' }}>
                    {name}
                  </span>
                </Tooltip>
              </Row>
              <Row style={{ textAlign: 'center' }}>
                {
                  !fileLoad && (
                    iconUrl
                      ? <ThumbnailInfo />
                      : <NoImageThumbnail style={{ width: '150px', height: '150px' }} />
                  )
                }
              </Row>
              <Row style={{ marginTop: '0px' }}>
                <h1 style={{
                  paddingLeft: '15px',
                  paddingBottom: '3px',
                  borderBottom: '1px solid darkgray',
                  fontSize: '16px',
                }}
                >
                  詳細情報
                </h1>
              </Row>
              <Row style={{ height: detailsListHeight, overflow: 'auto' }}>
                {
                  detailsList?.length ? (
                    detailsList
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                {
                  isSetDefect && <DefectInfo />
                }
              </Row>
              <FileDetailViewModal
                visible={modalVisible}
                resourceType={resourceType}
                fileId={fileId}
                title={name}
                isModalEditButtonDisabled={isModalEditButtonDisabled}
                isModalDownloadButtonDisabled={isModalDownloadButtonDisabled}
                onClose={handleCancelModal}
              />
            </Spin>
          ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
    </Card>
  );
};
