/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unneeded-ternary */
// eslint警告未対応
import React from 'react';
import {
  Layout, Card, Row, Col, List, Pagination, Checkbox, Empty, Button, Input, message, Tooltip, Spin,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { withRouter } from 'react-router';
import { sortCreatedTime } from '../../utils/sort';
import sg from '../../utils/storageCommon';
import da from '../../utils/dataAccess';
import AssetEditFormModal from '../Common/Asset/AssetEditFormModal';
import ManagedFacility from '../../utils/Asset/ManagedFacility';
import TeacherData from '../../utils/Asset/TeacherData';
import {
  getManagedFacilityFiles,
  getManagedFacilityFilesIcon,
} from '../../utils/File/ManagedFacilityFile';
import {
  ERROR_LOAD_MANAGED_FACILITIES,
  ERROR_LOAD_TEACHER_DATA,
  ERROR_FILES_GET_DL_URL,
  ERROR_NO_AUTH_MESSAGE,
} from '../../utils/messages';
import { MimeType } from '../../utils/File/BaseFile';
import { ResourceType } from '../../utils/common/SDFDataType';
import { EP_PATH_MANAGED_FACILITY, EP_PATH_TEACHER_DATA } from '../../utils/AWS/EndpointPath';
import './Home.css';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../utils/common/Authentication';

const { Header, Content } = Layout;
const { Search } = Input;
/**
 * 設備名の表示文字数
 */
const NAME_LENGTH_LIMIT = 33;
/**
 * 分類１、分類２、所在地の表示文字数
 */
const INFO_LENGTH_LIMIT = 14;

/**
 * 管理設備の1ページ最大表示数
 */
const COLUMN = 8;

/**
 * メイン画面component
 * @property {object} client CogniteClient
 * @property {number} parentAssetsId assetID
 * @property {number} selectedPage 選択ページ
 * @property {function} setSelectedPage ページ選択時のイベントハンドラ
 * @property {function} setLoadingFlag Load Flag設定関数
 */
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.checkboxRef = React.createRef();
    this.searchRef = React.createRef();
  }

  state = {
    listData: [],
    listDataLoad: true,
    totalData: 1,
    defaultChecked: false,
    editing: false,
    target: undefined,
    filterValues: [],
    isEditButtonDisabled: true,
    isAddButtonDisabled: true,
    isDetailsLinkDisabled: true,
  };

  /**
   * component render後の処理
   */
  async componentDidMount() {
    this.props.setLoadingFlag(true);
    const checked = sg.getStorageEquipmentChecked() ? true : false;
    this.checkboxRef.current.rcCheckbox.state.checked = checked;
    this.setState({
      defaultChecked: checked,
    });

    const filter = this.getSearchFilter(checked);
    await this.selectEquipmentAssets(filter);

    const equipmentNameConditions = sg.getStorageEquipmentNameConditions();
    this.searchEquipmentName(equipmentNameConditions, this.props.selectedPage);

    if (equipmentNameConditions) {
      this.searchRef.current.input.state.value = equipmentNameConditions;
      this.setState({ filterValues: [equipmentNameConditions] });
    }

    const { EQUIPMENT_LIST } = AUTHENTICATION_TYPE_MATRIX;

    this.setState({
      isEditButtonDisabled: !await containsUIAuthType(EQUIPMENT_LIST.EQUIPMENT_LIST_EDIT_BUTTON),
      isAddButtonDisabled: !await containsUIAuthType(EQUIPMENT_LIST.EQUIPMENT_LIST_ADD_BUTTON),
      isDetailsLinkDisabled: !await containsUIAuthType(EQUIPMENT_LIST.EQUIPMENT_LIST_DETAILS_LINK),
    });
    this.props.setLoadingFlag(false);
  }

  /**
   * コンポーネントが更新された直後に呼び出される。
   * 変更前後で管理設備リストの読み込みが完了した場合、ロードインジケータを解除する。
   * @param {Object} prevState 変更前のstate
   */
  componentDidUpdate(_, prevState) {
    const { listData } = this.state;
    if (listData.length !== prevState.listData.length) {
      this.setState({ listDataLoad: false });
    }
  }

  /**
   * ListDataクリック時のイベントハンドラ
   * @param {number} id 選択された設備のアセットID
   * @param {string} assetType アセットタイプ
   */
  onClickList = async (id, assetType) => {
    const listData = [...this.state.listData];
    this.setBorderStyle(listData, id);

    this.setState({
      listData,
      selectedAssetsId: id,
    });

    const { EQUIPMENT_LIST } = AUTHENTICATION_TYPE_MATRIX;
    if (await containsUIAuthType(EQUIPMENT_LIST.EQUIPMENT_LIST_DETAILS_LINK)) {
      sg.setStorageParentAssetId(id);
      sg.setStorageParentAssetType(assetType);
      const dataSetId = await this.getDataSetId(id, assetType);
      sg.setStorageDataSetId(dataSetId);
      this.props.setSelectedMenuKey('2');
      this.props.history.push('/3DView');
    }
  };

  /**
   * ページ変更時のイベントハンドラ
   * @param {number} page 選択されたページ数
   */
  onChangePage = async (page) => {
    this.setState({
      listDataLoad: true,
    });

    const listData = await this.getListData(page);
    this.setBorderStyle(listData, sg.getStorageParentAssetId());

    this.setState({
      listDataLoad: false,
      listData,
    });
  };

  /**
   * checkbox変更時のイベントハンドラ
   * @param {object} e checkbox変更時のイベントオブジェクト
   */
  onChangeCheckbox = async (e) => {
    this.setState({
      listDataLoad: true,
    });

    sg.setStorageEquipmentChecked(e.target.checked);

    const filter = this.getSearchFilter(e.target.checked);
    await this.selectEquipmentAssets(filter);
    this.searchEquipmentName(sg.getStorageEquipmentNameConditions(), this.props.selectedPage);

    this.setState({
      listDataLoad: false,
    });
  };

  /**
   * 設備名検索時のイベントハンドラ
   * @param {string} value 検索値
   */
  onSearchEquipmentName = async (value) => {
    this.setState({ listDataLoad: true });

    await this.searchEquipmentName(value, 1);
    sg.setStorageEquipmentNameConditions(value);

    this.setState({
      listDataLoad: false,
      filterValues: [value],
    });
  };

  /**
   * 検索条件の取得
   * @param {boolean} checked true: 管理設備以外表示 false: 管理設備のみ表示
   * @return {object} 検索条件
   */
  getSearchFilter = (checked) => {
    const metadata = {};
    metadata.topFlg = 'true';

    if (!checked) {
      metadata.assetType = 'equipment';
    }

    const filter = {};
    filter.metadata = metadata;
    return filter;
  };

  /**
   * 設備名検索処理
   * @param {string} filterValue 検索値
   * @param {number} page ページ数
   */
  searchEquipmentName = async (filterValue, page) => {
    const targetAssets = filterValue ? this.allAsset.filter((asset) => asset.name.indexOf(filterValue) > -1) : this.allAsset;
    const totalData = targetAssets.length;
    if (totalData === 0) {
      this.setState({
        listDataLoad: false,
      });
    }

    this.generateEquipmentAssets(targetAssets);
    const listData = await this.getListData(page);

    const parentAssetsId = sg.getStorageParentAssetId();
    this.setBorderStyle(listData, parentAssetsId);

    this.setState({
      totalData,
      listData,
    });
  };

  /**
   * 管理設備アセットの検索
   * @param {object} filter アセットの検索filter
   * @returns {array} 画面表示対象のListData
   */
  selectEquipmentAssets = async (filter) => {
    let managedFacilities = [];
    try {
      managedFacilities = await ManagedFacility.loadDisplayedTopFromCDF();
    } catch (e) {
      message.error(ERROR_LOAD_MANAGED_FACILITIES);
    }
    let teacherData = [];
    if (!filter.metadata.assetType) {
      // 「管理設備以外表示」が未チェックの場合
      try {
        teacherData = await TeacherData.loadDisplayedTopFromCDF();
      } catch (e) {
        message.error(ERROR_LOAD_TEACHER_DATA);
      }
    }
    const assets = managedFacilities.concat(teacherData);

    sortCreatedTime(assets, 'desc');
    this.allAsset = assets;
  };

  /**
   * 設備アセットの生成
   * @param {array} assets 対象のアセット
   */
  generateEquipmentAssets = (assets) => {
    let itemList = [];
    const itemGroupList = [];
    const { length } = assets;

    for (const [index, asset] of assets.entries()) {
      const count = index + 1;
      itemList.push({
        assetType: asset.metadata.assetType,
        assetId: asset.id,
        dataSetId: asset.dataSetId,
        name: asset.name,
        type: asset.metadata.type,
        subtype: asset.metadata.subType,
        place: asset.metadata.place,
      });

      if ((count % COLUMN) === 0) {
        itemGroupList.push(itemList);
        itemList = [];
      } else if (index === (length - 1)) {
        itemGroupList.push(itemList);
      }
    }

    const parentAssetsId = sg.getStorageParentAssetId();
    if (!parentAssetsId) {
      sg.setStorageParentAssetId(itemGroupList[0][0].assetId);
      sg.setStorageParentAssetType(itemGroupList[0][0].assetType);
      sg.setStorageDataSetId(itemGroupList[0][0].dataSetId);
    }
    this.equipmentAssets = itemGroupList;
  };

  /**
   * 画面表示対象のListDataの取得
   * @param {number} page 現在選択されているページ数
   * @returns {array} 画面表示対象のListData
   */
  getListData = async (page) => {
    const assetsList = this.equipmentAssets[page - 1];
    const assetsListData = [];
    const { Jpeg } = MimeType;
    if (assetsList) {
      for (const asset of assetsList) {
        try {
          if (asset.assetType === 'equipment') {
            const managedFacilityFileList = await getManagedFacilityFiles({ filter: { assetIds: [asset.assetId], mimeType: Jpeg, metadata: { type: 'topIcon' } }, limit: 1 });

            if (managedFacilityFileList.items.length > 0) {
              const item = managedFacilityFileList.items[0];
              const fileIcon = await getManagedFacilityFilesIcon(item.id);
              asset.url = fileIcon;
            }
          } else {
            // 教師データの場合
            const data = await TeacherData.getTopIcon(asset.assetId);
            if (data) {
              asset.url = URL.createObjectURL(new Blob([data]));
            }
          }
        } catch (e) {
          message.error(ERROR_FILES_GET_DL_URL);
        } finally {
          assetsListData.push(asset);
        }
      }
    }
    this.props.setSelectedPage(page);
    return assetsListData;
  };

  /**
   * 管理設備情報のborder styleの設定
   * @param {array} listData 画面表示対象のListData
   * @param {number} id 選択された管理設備のアセットID
   */
  setBorderStyle = (listData, id) => {
    listData.forEach((data) => {
      if (data.assetId === id) {
        data.borderStyle = { border: '2px solid #0000FF', height: '325px' };
      } else {
        data.borderStyle = { border: '1px solid #e8e8e8', height: '325px' };
      }
    });
  };

  /**
   * DataSetIdの取得
   * @param {number} assetId AssetID
   * @param {string} assetType AssetType
   * @returns {number} AssetIDから取得したAssetのdataSetId
   */
  getDataSetId = async (assetId, assetType) => {
    let endpoint = EP_PATH_TEACHER_DATA;
    if (ManagedFacility.assetType === assetType) {
      endpoint = EP_PATH_MANAGED_FACILITY;
    }
    const asset = await da.assetRetrieve({ endpoint, id: assetId });
    return asset ? asset.dataSetId : undefined;
  };

  /**
   * 設備リストの更新
   */
  reloadEquipmentList = async () => {
    this.setState({ listDataLoad: true });

    const checked = sg.getStorageEquipmentChecked() ? true : false;
    const filter = this.getSearchFilter(checked);
    await this.selectEquipmentAssets(filter);
    this.searchEquipmentName(sg.getStorageEquipmentNameConditions(), this.props.selectedPage);

    this.setState({ listDataLoad: false });
  };

  /**
   * 設備一覧画面をレンダリングする
   */
  render() {
    return (
      <Layout style={{ height: '100vh', overflow: 'auto' }}>
        <Header style={{ backgroundColor: 'white', padding: '0 20px' }}>
          <Row>
            <Col span={12}>
              <img src="./img/logotext.png" alt="logo" style={{ width: '120px', height: '100%' }} />
              <img src="./img/top_icon.png" alt="logo" style={{ width: '40px', marginLeft: '10px' }} />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <a href="https://www.nttcom.co.jp/smtech/" target="_blank" rel="noopener noreferrer">
                <img src="./img/MVSW_logo.jpg" alt="logo" style={{ width: '200px' }} />
              </a>
            </Col>
          </Row>
        </Header>
        <Content>
          <Spin spinning={this.state.listDataLoad}>
            <Row style={{ margin: '5% 10%' }}>
              <Checkbox ref={this.checkboxRef} defaultChecked={this.state.defaultChecked} onChange={this.onChangeCheckbox}>管理設備以外も表示</Checkbox>
              <Tooltip title={this.state.isAddButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                <Button
                  icon="plus"
                  onClick={async (event) => {
                    event.stopPropagation();
                    const target = new ManagedFacility();
                    this.setState({ target, editing: true });
                  }}
                  disabled={this.state.listDataLoad || this.state.isAddButtonDisabled}
                  className="add-managed-facility-button"
                >
                  管理設備を追加
                </Button>
              </Tooltip>
              <Card style={{ whiteSpace: 'nowrap' }}>
                <Row>
                  <Col span={12}>
                    <Search
                      ref={this.searchRef}
                      placeholder="設備名で検索"
                      allowClear
                      onSearch={this.onSearchEquipmentName}
                      style={{
                        width: '47%',
                        marginBottom: '20px',
                      }}
                    />
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Pagination
                      size="small"
                      total={this.state.totalData}
                      current={this.props.selectedPage}
                      defaultPageSize={COLUMN}
                      onChange={this.onChangePage}
                    />
                  </Col>
                </Row>
                <Row>
                  <List
                    grid={{ gutter: 24, column: 4 }}
                    dataSource={this.state.listData}
                    renderItem={(item) => (
                      <Tooltip title={this.state.isDetailsLinkDisabled && ERROR_NO_AUTH_MESSAGE}>
                        <List.Item>
                          <Card onClick={this.onClickList.bind(this, item.assetId, item.assetType)} style={item.borderStyle}>
                            <Row>
                              <div className="managed-facility-card-cover" aria-hidden="true">
                                {item.url ?
                                  <img src={item.url} alt="管理設備画像" style={{ width: '100%' }} />
                                  :
                                  <Empty description="No Image" image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: '10%', height: '133px' }} />
                                }
                                <div className="edit-button-group">
                                  <Tooltip title={this.state.isEditButtonDisabled && ERROR_NO_AUTH_MESSAGE}>
                                    <Button
                                      type="link"
                                      icon="edit"
                                      onClick={async (event) => {
                                        event.stopPropagation();
                                        const targetFacility = await ManagedFacility.loadOneByIdFromCDF(item.assetId, this.props.client.client);
                                        this.setState({ target: targetFacility, editing: true });
                                      }}
                                      disabled={this.state.isEditButtonDisabled}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            </Row>
                            <Row style={{ whiteSpace: 'pre-line' }}>
                              <Tooltip title={item.name} placement="bottomLeft">
                                <font size="2">設備名：<Highlighter
                                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                  searchWords={this.state.filterValues}
                                  textToHighlight={item.name.length > NAME_LENGTH_LIMIT ? `${item.name.substring(0, NAME_LENGTH_LIMIT)}...` : item.name}
                                />
                                </font>
                              </Tooltip>
                            </Row>
                            <Row style={{ whiteSpace: 'pre-line' }}>
                              <Tooltip title={item.type} placement="bottomLeft">
                                <font size="2">分類１：
                                  {item.type && item.type.length > INFO_LENGTH_LIMIT ? `${item.type.substring(0, INFO_LENGTH_LIMIT)}...` : item.type}
                                </font>
                              </Tooltip>
                            </Row>
                            <Row style={{ whiteSpace: 'pre-line' }}>
                              <Tooltip title={item.subtype} placement="bottomLeft">
                                <font size="2">分類２：
                                  {item.subtype && item.subtype.length > INFO_LENGTH_LIMIT ? `${item.subtype.substring(0, INFO_LENGTH_LIMIT)}...` : item.subtype}
                                </font>
                              </Tooltip>
                            </Row>
                            <Row style={{ whiteSpace: 'pre-line' }}>
                              <Tooltip title={item.place} placement="bottomLeft">
                                <font size="2">所在地：
                                  {item.place && item.place.length > INFO_LENGTH_LIMIT ? `${item.place.substring(0, INFO_LENGTH_LIMIT)}...` : item.place}
                                </font>
                              </Tooltip>
                            </Row>
                          </Card>
                        </List.Item>
                      </Tooltip>
                    )}
                  />
                </Row>
                {
                  this.state.editing &&
                  <AssetEditFormModal
                    visible={this.state.editing}
                    target={this.state.target}
                    parentResourceType={ResourceType.Root}
                    onAdd={() => {
                      this.setState({ target: undefined, editing: false });
                      this.reloadEquipmentList();
                    }}
                    onUpdate={() => {
                      this.setState({ target: undefined, editing: false });
                      this.reloadEquipmentList();
                    }}
                    onCancel={() => {
                      this.setState({ target: undefined, editing: false });
                    }}
                  />
                }
              </Card>
            </Row>
          </Spin>
        </Content>
      </Layout>
    );
  }
}

export default withRouter(Home);
