import React from 'react';

import { Empty } from 'antd';

export const EmptyImageGallery: React.FC<{ isCompare: boolean; }> = (props) => {
  const { isCompare } = props;
  const swiperClassName = isCompare ? 'mainGalleryCompare' : 'mainGallery';
  return (
    <div className="image-gallery-container" style={{ opacity: 1.0 }}>
      <div className={swiperClassName}>
        <div className="swiper-slide">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      </div>
    </div>
  );
};
