/* eslint-disable react-hooks/exhaustive-deps */
// eslint警告未対応
import React, { useEffect, useCallback } from 'react';
import { message } from 'antd';
import Queue from 'promise-queue';
import ManagedFacility from '../../../utils/Asset/ManagedFacility';
import { ColumnChart, CountData, CountsDataMap } from './ColumnChart';
import { ERROR_LOAD_MANAGED_FACILITY_ASSETS_COUNT } from '../../../utils/messages';
import AiProject from '../../../utils/Asset/AiProject';
import DetectTask from '../../../utils/Asset/DetectTask';
import Result from '../../../utils/Asset/Result';
import RootAsset from '../../../utils/Asset/RootAsset';
import TeacherData from '../../../utils/Asset/TeacherData';
import TrainTask from '../../../utils/Asset/TrainTask';

const LEGEND_AI = 'AI関連';
const LEGEND_FACILITY = '設備';
const SYSTEM_DATA = {
  KEY: '0',
  NAME: 'システム',
  LEGEND_ROOT: 'ルートアセット',
  LEGEND_TEACHER: '教師データ',
  LEGEND_OPERATION: '運用データ',
  LEGEND_PROJECT: '学習プロジェクト',
} as const;

const DEFAULT_COUNT_DATA = [
  { legend: LEGEND_AI },
  { legend: LEGEND_FACILITY },
  { legend: SYSTEM_DATA.LEGEND_ROOT },
  { legend: SYSTEM_DATA.LEGEND_TEACHER },
  { legend: SYSTEM_DATA.LEGEND_OPERATION },
  { legend: SYSTEM_DATA.LEGEND_PROJECT },
] as CountData[];

export const AssetColumnChart: React.FC<{
  managedFacilities: ManagedFacility[],
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [loading, setLoading] = React.useState<boolean>(false);
  const [countsData, setCountsData] = React.useState<CountData[]>(DEFAULT_COUNT_DATA);
  const [countsDataMap, setCountsDataMap] = React.useState<CountsDataMap>({});

  const { managedFacilities } = props;

  const maxConcurrent = 16; // 並列実行するタスク数
  const queue = new Queue(maxConcurrent);

  /**
   * 管理設備配下のアセット数カウント
   * @param {ManagedFacility} managedFacility 管理設備
   * @returns カウント情報群
   */
  const countAssetsOf = useCallback(async (
    managedFacility: ManagedFacility,
  ): Promise<CountsDataMap> => {
    // AI関連アセットを数える
    const countResultAi = await managedFacility.countResultAiAssets();

    // 設備数を数える
    const countFacility = await managedFacility.countFacilityAssets();

    const result: CountsDataMap = {};
    const key = String(managedFacility.id);
    result[key] = [
      {
        name: managedFacility.name,
        legend: LEGEND_AI,
        value: countResultAi,
      },
      {
        name: managedFacility.name,
        legend: LEGEND_FACILITY,
        value: countFacility,
      },
    ];

    return result;
  }, []);

  /**
   * rootアセット数取得
   * @returns カウント情報
   */
  const getRootAssetCount = useCallback(async (): Promise<CountData> => {
    const countRootAsset = (await RootAsset.loadAllFromCDF()).length;
    return {
      name: SYSTEM_DATA.NAME,
      legend: SYSTEM_DATA.LEGEND_ROOT,
      value: countRootAsset,
    };
  }, []);

  /**
   * 教師データアセット数取得
   * @returns カウント情報
   */
  const getTeacherDataCount = useCallback(async (): Promise<CountData> => {
    // 教師データアセットを抽出
    const teacherAssets = await TeacherData.loadAllFromCDF();

    // 教師データアセットと配下のアセットをカウント
    const countSubAsset = teacherAssets.map(async (asset) => {
      const count = await asset.countSubAssets();
      return count;
    });
    const results = await Promise.all(countSubAsset);

    return {
      name: SYSTEM_DATA.NAME,
      legend: SYSTEM_DATA.LEGEND_TEACHER,
      value: results.reduce((sum, i) => sum + i),
    };
  }, []);

  /**
   * 運用データアセット数取得(TrainTask,DetectTask,Result)
   * @returns カウント情報
   */
  const getOperationalDataCount = useCallback(async (): Promise<CountData> => {
    const countTrainTask = (await TrainTask.loadAllFromCDF()).length;
    const countDetectTask = (await DetectTask.loadAllFromCDF()).length;
    const countResult = (await Result.loadAllFromCDF()).length;
    return {
      name: SYSTEM_DATA.NAME,
      legend: SYSTEM_DATA.LEGEND_OPERATION,
      value: countTrainTask + countDetectTask + countResult,
    };
  }, []);

  /**
   * Projectアセット数取得
   * @returns カウント情報
   */
  const getProjectCount = useCallback(async (): Promise<CountData> => {
    // Project配下にあるアセットを抽出
    const projectAssets = await AiProject.loadAllFromCDF();

    // Project配下にあるアセットを非同期でカウント
    const countSubAssets = projectAssets.map(async (asset) => {
      const count = asset.countSubAssets();
      return count;
    });
    const results = await Promise.all(countSubAssets);

    return {
      name: SYSTEM_DATA.NAME,
      legend: SYSTEM_DATA.LEGEND_PROJECT,
      value: results.reduce((sum, i) => sum + i),
    };
  }, []);

  /*
   * イベントハンドラ
   */
  useEffect(
    () => {
      if (managedFacilities.length === 0) {
        setCountsData(DEFAULT_COUNT_DATA);
        return;
      }
      setLoading(true);
    },
    [managedFacilities],
  );

  useEffect(
    () => {
      if (!loading) return () => { /* 何もしない */ };

      let canceled = false;

      (async () => {
        /*
         * メイン処理
         */
        try {
          // 管理設備配下のアセット数を非同期でカウント
          const promises: Promise<CountsDataMap>[] = [];
          managedFacilities.forEach((managedFacility) => {
            const key = String(managedFacility.id);
            if (!countsDataMap[key]) {
              promises.push(queue.add(() => countAssetsOf(managedFacility)));
            }
          });

          const results = await Promise.all(promises);

          const newCountsDataMap = { ...countsDataMap };
          results.forEach((result) => {
            const keys = Object.keys(result);
            keys.forEach((key) => {
              newCountsDataMap[key] = result[key];
            });
          });

          const keys = managedFacilities.map(
            (managedFacility) => (String(managedFacility.id)),
          );
          const newCountsData: CountData[] = [];
          keys.sort().forEach((key) => {
            newCountsData.push(...newCountsDataMap[key]);
          });

          // システム－運用データ
          // 管理設備配下に存在しない運用アセットをカウントする非同期処理
          const promisesSystem: Promise<CountData>[] = [];
          promisesSystem.push(getRootAssetCount());
          promisesSystem.push(getTeacherDataCount());
          promisesSystem.push(getOperationalDataCount());
          promisesSystem.push(getProjectCount());

          const resultsSystem = await Promise.all(promisesSystem);

          const result: CountsDataMap = {};
          result[SYSTEM_DATA.KEY] = resultsSystem;
          newCountsDataMap[SYSTEM_DATA.KEY] = result[SYSTEM_DATA.KEY];
          newCountsData.push(...newCountsDataMap[SYSTEM_DATA.KEY]);

          // 管理設備名に重複がある場合は、名前を変更する
          for (let i = 0; i < newCountsData.length - 1; i++) {
            for (let j = i + 1; j < newCountsData.length; j++) {
              if (newCountsData[i].name === newCountsData[j].name
                && newCountsData[i].legend === newCountsData[j].legend) {
                newCountsData[j].name = newCountsData[j].name.concat(' ');
              }
            }
          }

          if (!canceled) {
            setLoading(false);
            setCountsDataMap(newCountsDataMap);
            setCountsData(newCountsData);
          }
        } catch (exception) {
          if (!canceled) {
            setLoading(false);
            message.error(ERROR_LOAD_MANAGED_FACILITY_ASSETS_COUNT);
          }
        }
      })();

      return () => { canceled = true; };
    },
    [
      managedFacilities, countsDataMap, loading, queue,
      countAssetsOf, getOperationalDataCount, getProjectCount,
      getRootAssetCount, getTeacherDataCount,
    ],
  );

  /*
   * メソッド
   */
  /*
   * 画面描画
   */
  return (
    <ColumnChart loading={loading} countsData={countsData} />
  );
};
